import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'am-polaris-select',
  templateUrl: './polaris-select.component.html',
  styleUrls: ['./polaris-select.component.scss']
})
export class PolarisSelectComponent implements OnInit {

  @Input()
  model: any;

  @Input()
  items: Array<any>;

  @Output() valueUpdated = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  valueChange($event) {
    this.valueUpdated.emit($event);
  }

}
