<div class="editor-left-sidebar-panel image-picker-pannel" [ngClass]="{'show': showImagePicker}">

  <div class="editor-left-sidebar-panel--header">
    <h2>Images</h2>
    <div (click)="closePickerBtn()" class="btn close">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path
          d="M18.263 16l10.07-10.07c.625-.625.625-1.636 0-2.26s-1.638-.627-2.263 0L16 13.737 5.933 3.667c-.626-.624-1.637-.624-2.262 0s-.624 1.64 0 2.264L13.74 16 3.67 26.07c-.626.625-.626 1.636 0 2.26.312.313.722.47 1.13.47s.82-.157 1.132-.47l10.07-10.068 10.068 10.07c.312.31.722.468 1.13.468s.82-.157 1.132-.47c.626-.625.626-1.636 0-2.26L18.262 16z">
        </path>
      </svg>
    </div>
  </div>

  <div class="editor-left-sidebar-panel--content">

    <div class="editor-left-sidebar-panel--content-wrapper">

      <div class="editor-left-sidebar-panel--stack">

        <div class="image-picker-grid-item image-picker-upload-btn">
          <div id="uploadImageBtn" (click)="fileInput.click()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
              <path
                d="M21.93 17.13c-.31.313-.72.47-1.13.47s-.82-.157-1.13-.47l-2.07-2.068V27.2c0 .883-.715 1.6-1.6 1.6s-1.6-.717-1.6-1.6V15.062l-2.07 2.07c-.625.625-1.636.625-2.26 0s-.627-1.638 0-2.263l4.8-4.8c.625-.63 1.636-.63 2.26 0l4.8 4.8c.627.623.627 1.634 0 2.26zM27.2 3.2c.885 0 1.6.717 1.6 1.6v17.6c0 .883-.715 1.6-1.6 1.6h-4.8c-.885 0-1.6-.717-1.6-1.6s.715-1.6 1.6-1.6h3.2V6.4H6.4v14.4h3.2c.885 0 1.6.717 1.6 1.6S10.485 24 9.6 24H4.8c-.885 0-1.6-.717-1.6-1.6V4.8c0-.883.715-1.6 1.6-1.6h22.4z">
              </path>
            </svg>
            <span>Upload</span>
          </div>

          <input #fileInput type="file" [id]="imgObject.id" accept="image/*" (change)="onFileChanged($event)">
        </div>

        <div class="image-picker-grid-item" *ngFor="let item of uploadedImages" (click)="selectImage(item)"
          [ngClass]="{'selected': item.selected}">
          <img [src]="item.url" />

          <div class="item-selected">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path fill="currentColor" d="M19 10c0 4.97-4.03 9-9 9s-9-4.03-9-9 4.03-9 9-9 9 4.03 9 9z"></path>
              <path
                d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm2.293-10.707L9 10.586 7.707 9.293c-.39-.39-1.023-.39-1.414 0s-.39 1.023 0 1.414l2 2c.195.195.45.293.707.293s.512-.098.707-.293l4-4c.39-.39.39-1.023 0-1.414s-1.023-.39-1.414 0z">
              </path>
            </svg>
          </div>
        </div>

      </div>

    </div>
  </div>

  <div class="editor-left-sidebar-panel--footer" [ngClass]="{'active': showFooter}">
    <button (click)="closePicker()" class="button primary">
      Select
    </button>
  </div>
</div>