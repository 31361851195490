import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../shared/shared.module';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { PwaOnboardingRoutingModule } from './pwa-onboarding-routing.module';

import { PwaOnboardingLayoutComponent } from './pwa-onboarding-layout/pwa-onboarding-layout.component';

import { PwaOnboardingWelcomeComponent } from './pwa-onboarding-welcome/pwa-onboarding-welcome.component';
import { PwaOnboardingExplanationComponent } from './pwa-onboarding-explanation/pwa-onboarding-explanation.component';
import { PwaOnboardingFinishComponent } from './pwa-onboarding-finish/pwa-onboarding-finish.component';
import { PwaOnboardingQuickConfigComponent } from './pwa-onboarding-quick-config/pwa-onboarding-quick-config.component';
import { PwaOnboardingInstallTutorialComponent } from './pwa-onboarding-install-tutorial/pwa-onboarding-install-tutorial.component';

@NgModule({
  declarations: [
    PwaOnboardingLayoutComponent,
    PwaOnboardingWelcomeComponent,
    PwaOnboardingExplanationComponent,
    PwaOnboardingFinishComponent,
    PwaOnboardingQuickConfigComponent,
    PwaOnboardingInstallTutorialComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatProgressSpinnerModule,

    PwaOnboardingRoutingModule
  ]
})
export class PwaOnboardingModule { }
