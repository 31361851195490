<div class="Polaris-EmptyState">
  <div class="Polaris-EmptyState__Section">
    <div class="Polaris-EmptyState__DetailsContainer">
      <div class="Polaris-EmptyState__Details">

        <div class="Polaris-Card">
          <div class="Polaris-Card__Section">
            <div class="Polaris-TextContainer">
              <p class="Polaris-DisplayText Polaris-DisplayText--sizeMedium">
                A small reminder 💬
              </p>
              <div class="Polaris-EmptyState__Content">
                <p>
                  Google and other search engines need about 2 weeks to discover your new pages. So
                  sit back, relax, and let the time work for you.
                </p>
              </div>
            </div>

            <div class="Polaris-EmptyState__Actions">
              <div class="Polaris-Stack Polaris-Stack--alignmentCenter">

                <div class="Polaris-Stack__Item">
                  <a [routerLink]="['/amp/onboarding/benefits/cheaper-ads']"
                    class="Polaris-Button Polaris-Button--plain">
                    <span class="Polaris-Button__Content">
                      <span class="Polaris-Button__Text">Back</span>
                    </span>
                  </a>
                </div>

                <div class="Polaris-Stack__Item">
                  <a [routerLink]="['/amp/onboarding/finish']"
                    class="Polaris-Button Polaris-Button--primary Polaris-Button--sizeLarge">
                    <span class="Polaris-Button__Content">
                      <span class="Polaris-Button__Text">
                        Continue
                      </span>
                    </span>
                  </a>
                </div>

              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
    <div class="Polaris-EmptyState__ImageContainer">
      <img src="/assets/amp/images/in-no-time.svg" role="presentation" alt="" class="Polaris-EmptyState__Image">
    </div>
  </div>
</div>