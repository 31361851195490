<div class="Polaris-FooterHelp">
  <div class="Polaris-FooterHelp__Content">
    <div class="Polaris-FooterHelp__Icon">
      <span class="Polaris-Icon Polaris-Icon--colorTeal Polaris-Icon--isColored Polaris-Icon--hasBackdrop">
        <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
          <circle cx="10" cy="10" r="9" fill="currentColor"></circle>
          <path
            d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0m0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8m0-4a1 1 0 1 0 0 2 1 1 0 1 0 0-2m0-10C8.346 4 7 5.346 7 7a1 1 0 1 0 2 0 1.001 1.001 0 1 1 1.591.808C9.58 8.548 9 9.616 9 10.737V11a1 1 0 1 0 2 0v-.263c0-.653.484-1.105.773-1.317A3.013 3.013 0 0 0 13 7c0-1.654-1.346-3-3-3">
          </path>
        </svg>
      </span>
    </div>
    <div class="Polaris-FooterHelp__Text">
      Learn more about
      <a class="Polaris-Link" href="https://help.ampifyme.com" target="_blank" data-polaris-unstyled="true">
        AMP pages
      </a>
    </div>
  </div>
</div>