<div class="Polaris-EmptyState">
  <div class="Polaris-EmptyState__Section">
    <div class="Polaris-EmptyState__DetailsContainer">
      <div class="Polaris-EmptyState__Details">

        <div class="Polaris-Card">
          <div class="Polaris-Card__Section">
            <div class="Polaris-TextContainer">
              <p class="Polaris-DisplayText Polaris-DisplayText--sizeMedium">
                Better Search Rank 📈
              </p>
              <div class="Polaris-EmptyState__Content text-left">
                <p>
                  AMP pages bring SEO benefits and provide more visibility in search engines. More organic traffic for
                  you!
                </p>
              </div>
            </div>

            <div class="Polaris-EmptyState__Actions">
              <div class="Polaris-Stack Polaris-Stack--alignmentCenter">

                <div class="Polaris-Stack__Item">
                  <a [routerLink]="['/amp/onboarding/explanation']" class="Polaris-Button Polaris-Button--plain"
                    data-polaris-unstyled="true">
                    <span class="Polaris-Button__Content">
                      <span class="Polaris-Button__Text">Back</span>
                    </span>
                  </a>
                </div>

                <div class="Polaris-Stack__Item">
                  <a [routerLink]="['/amp/onboarding/benefits/cheaper-ads']"
                    class="Polaris-Button Polaris-Button--primary Polaris-Button--sizeLarge">
                    <span class="Polaris-Button__Content">
                      <span class="Polaris-Button__Text">
                        Continue
                      </span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="Polaris-EmptyState__ImageContainer">
      <img src="/assets/amp/images/search.svg" role="presentation" alt="" class="Polaris-EmptyState__Image">
    </div>
  </div>
</div>