import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'am-amp-layout-header',
  templateUrl: './amp-layout-header.component.html',
  styleUrls: ['./amp-layout-header.component.scss']
})
export class AmpLayoutHeaderComponent implements OnInit {

  @Input()
  user: any;

  @Output() sidebarExpandedChanged = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  openSidebar(): void {
    this.sidebarExpandedChanged.emit(true);
  }

}
