<div class="Polaris-EmptyState">
  <div class="Polaris-EmptyState__Section">
    <div class="Polaris-EmptyState__DetailsContainer">
      <div class="Polaris-EmptyState__Details">

        <div class="Polaris-Card">
          <div class="Polaris-Card__Section">
            <div class="Polaris-TextContainer">

              <p class="Polaris-DisplayText Polaris-DisplayText--sizeMedium">
                Let's do your setup 💪
              </p>
              <div class="Polaris-EmptyState__Content text-left">
                <p>
                  Please add icon and name of your store.
                </p>
              </div>
              <div class="">
                <div id="preview-image-container" class="centered">

                  <am-pwa-image-picker [imageUrl]="config.preview_icon" (valueUpdated)="imageUpdated($event)">
                  </am-pwa-image-picker>

                </div>
                <!-- <div class="centered">
                  <em>Please provide PNG image that is at least 512x512 px in size.</em>
                </div> -->
              </div>
              <div class="">
                <div class="Polaris-Labelled__LabelWrapper">
                  <div class="Polaris-Label">
                    <label class="Polaris-Label__Text">
                      Store name
                    </label>
                  </div>
                </div>
                <div class="Polaris-Connected">
                  <div class="Polaris-Connected__Item Polaris-Connected__Item--primary">
                    <div class="Polaris-TextField "
                      [ngClass]="{'Polaris-TextField--error': config.short_name.length > 12}">
                      <input (change)="shortnameUpdated()" class="Polaris-TextField__Input"
                        [(ngModel)]="config.short_name" placeholder="Add name here">
                      <div class="Polaris-TextField__Backdrop"></div>
                    </div>
                  </div>
                </div>
                <div *ngIf="config.short_name.length > 12" class="Polaris-Labelled__Error">
                  <div class="Polaris-InlineError">
                    <div class="Polaris-InlineError__Icon">
                      <span class="Polaris-Icon">
                        <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                          <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm-1-8h2V6H9v4zm0 4h2v-2H9v2z"
                            fill-rule="evenodd">
                          </path>
                        </svg>
                      </span>
                    </div>Short name must be less than 12 characters
                  </div>
                </div>
              </div>

            </div>

            <div class="Polaris-EmptyState__Actions">
              <div class="Polaris-Stack Polaris-Stack--alignmentCenter">

                <div class="Polaris-Stack__Item">
                  <a [routerLink]="['/pwa/onboarding/welcome']" class="Polaris-Button Polaris-Button--plain"
                    data-polaris-unstyled="true">
                    <span class="Polaris-Button__Content">
                      <span class="Polaris-Button__Text">Back</span>
                    </span>
                  </a>
                </div>

                <div class="Polaris-Stack__Item">
                  <button [disabled]="config.short_name.length > 12 ? true : false" (click)="saveAndContinue()"
                    type="button" class="Polaris-Button Polaris-Button--primary Polaris-Button--sizeLarge">
                    <span class="Polaris-Button__Content">
                      <span class="Polaris-Button__Text">
                        Continue
                      </span>
                    </span>
                  </button>
                </div>
                <div *ngIf="error" class="Polaris-Labelled__Error">
                  <div class="Polaris-InlineError">
                    <div class="Polaris-InlineError__Icon">
                      <span class="Polaris-Icon">
                        <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                          <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm-1-8h2V6H9v4zm0 4h2v-2H9v2z"
                            fill-rule="evenodd">
                          </path>
                        </svg>
                      </span>
                    </div>Please upload your icon and add a short name
                  </div>
                </div>

              </div>

            </div>
          </div>

        </div>

      </div>
    </div>
    <div class="Polaris-EmptyState__ImageContainer">
      <img src="/assets/pwa/images/onboarding-configuration.svg" role="presentation" alt=""
        class="Polaris-EmptyState__Image">
    </div>
  </div>
</div>
<div *ngIf="loading">
  <mat-spinner [diameter]="50" class="mat-spinner-color"></mat-spinner>
</div>