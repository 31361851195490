import { Component, Input, OnInit } from '@angular/core';
import { FormDataPrepareService } from '../../services/form-data-prepare.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Constants } from '../../classes/constant';
import { Subscription } from 'rxjs';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { AmBuilderService } from '../../../am-builder.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'am-sidebar-sections-v2',
  templateUrl: './sidebar-sections-v2.component.html',
  styleUrls: ['./sidebar-sections-v2.component.scss']
})
export class SidebarSectionsV2Component implements OnInit {

  stateArray: any = []

  showForm = false;

  formArray: any = []

  routeData = [];

  returnObj = {};

  sidemenuObj = {

  }

  headerMenuObj : any = {
    // "sections": {
    // },
    // "order": [
    //   "announcement-bar",
    //   "header",

    // ]
  }

  footerMenuObj : any = {
    // "sections": {

    // },
    // "order": [
    //   "footer"
    // ]
  }

  headerName = '';

  pIndex;

  selectedSectionData = {};

  @Input() mainMenu: any = {};

  headerConfig: any = {};
  @Input() footerConfig: any = {};

  @Input()currentConfig: any = {};

  @Input() pageType = 'Home'

  subscription: Subscription;
  themeVersionSubscription : Subscription;
  themeSettingsV7: any = [

    {
      'name': 'Colors',
      'key': 'colors',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Typography',
      'key': 'typography',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Layout',
      'key': 'layout',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Buttons',
      'key': 'buttons',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Variant Pills',
      'key': 'variant_pills',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Inputs',
      'key': 'inputs',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Cards',
      'key': 'cards',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Content Container',
      'key': 'variant_pills',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Media',
      'key': 'media',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Dropdowns and Pop-ups',
      'key': 'media',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Drawers',
      'key': 'drawers',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Badges',
      'key': 'badges',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Icons',
      'key': 'styles',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Social Media',
      'key': 'social-media',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Search Behavior',
      'key': 'search_input',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Favicon',
      'key': 'favicon',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Currency Format',
      'key': 'currency_format',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Cart',
      'key': 'cart',
      'schemaSettings': [],
      'settings': this.currentConfig

    },

  ];
  themeSettingsV10: any = [

    {
      'name': 'Logo',
      'key': 'logo',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Typography',
      'key': 'typography',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Layout',
      'key': 'layout',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Buttons',
      'key': 'buttons',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Variant Pills',
      'key': 'variant_pills',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Inputs',
      'key': 'inputs',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Cards',
      'key': 'cards',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Content Container',
      'key': 'variant_pills',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Media',
      'key': 'media',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Dropdowns and Pop-ups',
      'key': 'media',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Drawers',
      'key': 'drawers',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Badges',
      'key': 'badges',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'brand information',
      'key': 'brand_information',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Icons',
      'key': 'styles',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Social Media',
      'key': 'social-media',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Search Behavior',
      'key': 'search_input',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Favicon',
      'key': 'favicon',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Currency Format',
      'key': 'currency_format',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Cart',
      'key': 'cart',
      'schemaSettings': [],
      'settings': this.currentConfig

    },

  ]
  themeSettingsEf420: any = [

    {
      'name': 'Sale Mode',
      'key': 'sale_mode',
      'schemaSettings': [],
      'settings': this.currentConfig

  },
  {
    'name': 'Discount Code',
    'key': 'discount_code',
    'schemaSettings': [],
    'settings': this.currentConfig
  },
  {
    'name': 'Global Settings',
    'key': 'global_settings',
    'schemaSettings': [],
    'settings': this.currentConfig

  },
  {
    'name': 'Colors',
    'key': 'colors',
    'schemaSettings': [],
    'settings': this.currentConfig

  },
  {
    'name': 'Typography',
    'key': 'typography',
    'schemaSettings': [],
    'settings': this.currentConfig

  },
  {
    'name': 'Layout',
    'key': 'layout',
    'schemaSettings': [],
    'settings': this.currentConfig

  },
  {
    'name': 'Buttons',
    'key': 'buttons',
    'schemaSettings': [],
    'settings': this.currentConfig

  },
  {
    'name': 'Variant Pills',
    'key': 'variant_pills',
    'schemaSettings': [],
    'settings': this.currentConfig

  },
  {
    'name': 'Inputs',
    'key': 'inputs',
    'schemaSettings': [],
    'settings': this.currentConfig

  },
  {
    'name': 'Cards',
    'key': 'cards',
    'schemaSettings': [],
    'settings': this.currentConfig

  },
  {
    'name': 'Content Container',
    'key': 'variant_pills',
    'schemaSettings': [],
    'settings': this.currentConfig

  },
  {
    'name': 'Media',
    'key': 'media',
    'schemaSettings': [],
    'settings': this.currentConfig

  },
  {
    'name': 'Dropdowns and Pop-ups',
    'key': 'media',
    'schemaSettings': [],
    'settings': this.currentConfig

  },
  {
    'name': 'Drawers',
    'key': 'drawers',
    'schemaSettings': [],
    'settings': this.currentConfig

  },
  {
    'name': 'Badges',
    'key': 'badges',
    'schemaSettings': [],
    'settings': this.currentConfig

  },
  {
    'name': 'Icons',
    'key': 'styles',
    'schemaSettings': [],
    'settings': this.currentConfig

  },
  {
    'name': 'Social Media',
    'key': 'social-media',
    'schemaSettings': [],
    'settings': this.currentConfig

  },
  {
    'name': 'Search Behavior',
    'key': 'search_input',
    'schemaSettings': [],
    'settings': this.currentConfig

  },
  {
    'name': 'Favicon',
    'key': 'favicon',
    'schemaSettings': [],
    'settings': this.currentConfig

  },
  {
    'name': 'Currency Format',
    'key': 'currency_format',
    'schemaSettings': [],
    'settings': this.currentConfig

  },
  {
    'name': 'Cart',
    'key': 'cart',
    'schemaSettings': [],
    'settings': this.currentConfig

  },

  ];


  arr = [
    {
      content: '',
      label: 'Google Analytics ID',
      required: false,
      type: 'text',
      // value : settingData.settings[keys[i]],
      validations: [],
      key: 'google_analytics_tracking_id',
      // options : [],
      // sectionName : '',
      // info : '',
      // min : null,
      // max : null,
      // step : null,
      // unit : null
    },
    {
      content: '',
      label: 'Google Tag Manager script',
      required: false,
      type: 'html',
      // value : settingData.settings[keys[i]],
      validations: [],
      key: 'google_tag_manager_script',
      // options : [],
      // sectionName : '',
      info: 'Used for Google Tag Manager tracking. Tutorial on how to setup Google Tag Manager can he found at [AmpifyMe help centar](https://help.ampifyme.com/article/21-how-to-add-google-tag-manager-to-your-amp-page).',
      //  min : null,
      //  max : null,
      //  step : null,
      //  unit : null
    },
    {
      content: 'Facebook Pixel',
      label: '',
      required: false,
      type: 'header',
      // value : settingData.settings[keys[i]],
      validations: [],
      //  key : '',
      // options : [],
      // sectionName : '',
      info: 'Used for tracking with Facebook Pixel',
      //  min : null,
      //  max : null,
      //  step : null,
      //  unit : null
    },
    {
      content: 'Facebook',
      label: '',
      required: false,
      type: 'text',
      // value : settingData.settings[keys[i]],
      validations: [],
      key: 'fb_pixel',
      // options : [],
      // sectionName : '',
      info: '',
      //  min : null,
      //  max : null,
      //  step : null,
      //  unit : null
    },
    {
      content: 'Pinterest',
      label: '',
      required: false,
      type: 'header',
      // value : settingData.settings[keys[i]],
      validations: [],
      //  key : '',
      // options : [],
      // sectionName : '',
      info: '',
      //  min : null,
      //  max : null,
      //  step : null,
      //  unit : null
    },
    {
      content: '',
      label: 'Pinterest tag',
      required: false,
      type: 'text',
      // value : settingData.settings[keys[i]],
      validations: [],
      key: 'pinterest_pixel',
      // options : [],
      // sectionName : '',
      info: 'Used for tracking with Pinterest tag. Tutorial how to find Pinterest tag can be found at [Pinterest help](https://help.pinterest.com/en-gb/business/article/shopify-and-pinterest-tag). We need YOUR_TAG_ID from Pinterest.',
      //  min : null,
      //  max : null,
      //  step : null,
      //  unit : null
    }
  ]

  themeSections: any = [
    {
      'title': "Blog Post",
      'key': 'featured_blog'
    },

    {
      'title': "Collage",
      'key': 'collage'
    },
    {
      'title' : 'Collapsible content',
      'key' : 'collapsible_content'
    },
    {
      'title' : 'Collection list',
      'key' : 'collection_list'
    },
    {
      'title' : 'Contact form',
      'key' : 'contact_form'
    },
    {
      'title' : 'Featured collection',
      'key' : 'featured_collection'
    },
    // {
    //   'title' : 'Featured product',
    //   'key' : 'featured_product'
    // },
    {
      'title' : 'Custom HTML',
      'key' : 'custom_html'
    },
    {
      'title' : 'Image with text',
      'key' : 'image_with_text'
    },

    {
      'title': "Multicolumn",
      'key': 'multicolumn'
    },

    {
      'title': "Image banner",
      'key': 'image_banner'
    },
    {
      'title': "Rich text",
      'key': 'rich_text'
    },
    {
      'title': "Slideshow",
      'key': 'slideshow'
    },
    {
      'title': "Video",
      'key': 'video'
    },
    {
      'title': "Featured blog",
      'key': 'featured-blog'
    },
    {
      'title': "Multirow",
      'key': 'multirow'
    },
    {
      'title' : 'Custom Liquid',
      'key' : 'custom_liquid'
    },
    // {
    //   'title' : 'Custom Section',
    //   'key' : 'custom_section'
    // },
    {
      'title': "LAI product Reviews",
      'key': 'integration-reviews-lai-ali-express-io'
    },
    {
      'title' : 'StampedIO Reviews',
      'key' : 'integration-reviews-stamped-io'
    },
    {
      'title' : 'Areviews Reviews',
      'key' : 'integration-reviews-areviews-aliexpress'
    },
    {
      'title' : 'Bazaarvoice Reviews',
      'key' : 'integration-reviews-bazaarvoice'
    },
    {
      'title' : 'Judge me Reviews',
      'key' : 'integration-reviews-judge-me'
    },
    {
      'title' : 'Loox Reviews',
      'key' : 'integration-reviews-loox'
    },
    {
      'title' : 'Okendo Reviews',
      'key' : 'integration-reviews-okendo'
    },
    {
      'title' : 'Opinew Product Reviews',
      'key' : 'integration-reviews-opinew'
    },
    {
      'title' : 'Ryviu Reviews',
      'key' : 'integration-reviews-ryviu'
    },
    // {
    //   'title' : 'Shopify Product Reviews',
    //   'key' : 'integration-reviews-shopify-product-reviews'
    // },
    {
      'title' : 'Shopify Approved Reviews',
      'key' : 'integration-reviews-shopperapproved'
    },
    {
      'title' : 'ReviewIO Reviews',
      'key' : 'integration-reviews-review-io'
    },
    {
      'title' : 'Yotpo Reviews',
      'key' : 'integration-reviews-yotpo'
    },
    {
      'title' : 'Growave Reviews',
      'key' : 'integration-reviews-ssw'
    },
    {
      'title' : 'Helpful Crowd Reviews',
      'key' : 'integration-reviews-helpfulcrowd'
    },
    {
      'title' : 'Ali Express',
      'key' : 'integration-reviews-alireviews'
    },
    {
      'title' : 'Proviews',
      'key' : 'integration-reviews-proviews-io'
    },
    {
      'title' : 'Amp Reviews Fallback',
      'key' : 'amp-review-fallback'
    }
  ];
  cssPages : any = [
    {
      content: '',
      label: 'Enable AMP',
      required: false,
      type: 'checkbox',
      value : false,
      validations: [],
      key: 'enabled_amp',
      // options : [],
      // sectionName : '',
      // info : '',
      // min : null,
      // max : null,
      // step : null,
      // unit : null
    },
    {
      content: '',
      label: 'Home Page',
      required: false,
      type: 'textarea',
      // value : settingData.settings[keys[i]],
      validations: [],
      key: 'home_css',
      // options : [],
      // sectionName : '',
      // info : '',
      // min : null,
      // max : null,
      // step : null,
      // unit : null
    },
    {
      content: '',
      label: 'Product Page',
      required: false,
      type: 'textarea',
      // value : settingData.settings[keys[i]],
      validations: [],
      key: 'product_css',
      // options : [],
      // sectionName : '',
      // info : '',
      // min : null,
      // max : null,
      // step : null,
      // unit : null
    },
    {
      content: '',
      label: 'Collections Page',
      required: false,
      type: 'textarea',
      // value : settingData.settings[keys[i]],
      validations: [],
      key: 'collections_css',
      // options : [],
      // sectionName : '',
      // info : '',
      // min : null,
      // max : null,
      // step : null,
      // unit : null
    },
    {
      content: '',
      label: 'Collection List Page',
      required: false,
      type: 'textarea',
      // value : settingData.settings[keys[i]],
      validations: [],
      key: 'collection_list_css',
      // options : [],
      // sectionName : '',
      // info : '',
      // min : null,
      // max : null,
      // step : null,
      // unit : null
    },
    {
      content: '',
      label: 'Blogs Page',
      required: false,
      type: 'textarea',
      // value : settingData.settings[keys[i]],
      validations: [],
      key: 'blog_css',
      // options : [],
      // sectionName : '',
      // info : '',
      // min : null,
      // max : null,
      // step : null,
      // unit : null
    },
    {
      content: '',
      label: 'Blog Post Page',
      required: false,
      type: 'textarea',
      // value : settingData.settings[keys[i]],
      validations: [],
      key: 'blog_post_css',
      // options : [],
      // sectionName : '',
      // info : '',
      // min : null,
      // max : null,
      // step : null,
      // unit : null
    },
  ]
  cssFormArray : any = [];

  analyticsData : any = {};

  cssData : any = {};

  announcementObj = {
    "type": "announcement",
    "settings": {
      "text": "Welcome to our store",
      "color_scheme": "background-1",
      "link": ""
    }
  }
  themeId;
  version : any;
  colorSchemes : any = [];

  constructor(
    public data_prepare: FormDataPrepareService,
    private pubsub: NgxPubSubService,
    public amBuilderService: AmBuilderService = null,
    public route : ActivatedRoute

  ) {

  }

  ngOnInit(): void {

    //console.log("///currentConfig......",this.currentConfig);


    this.themeId = this.route.snapshot.paramMap.get('id');

    this.getThemeVersion(this.themeId);

    let tmp = localStorage.getItem('uData');

    this.setAddSectionData();

    this.mainMenu = JSON.parse(tmp);

    this.getSectionData();

    this.showSidemenu();

    this.getThemeSettingData();

    this.getAnalyticsObj();

    this.getCssObj()

    this.pageChange();

    this.getThemeVersionSubject();

  }
ngOnChanges(){

  console.log("///headerConfig",this.currentConfig);

}

  setAddSectionData(){

    let tmp1 = JSON.parse(localStorage.getItem('ampify-user'));

    if(tmp1.shopDomain == 'the-spoiled-mama-inc.myshopify.com'){

      this.themeSections.unshift( {
        'title': "Impact text",
        'key': 'impact-text'
      });
      this.themeSections.unshift({
        'title': "Multiple images with text",
        'key': 'multiple-images-with-text'
      })

    }

    if(tmp1.shopDomain == 'ef420.myshopify.com'){


    this.themeSections.unshift( {
      'title': "Featured collection slider",
      'key': 'featured-collection-slider'
    },
)

    }
    if(this.version == '3'){
      this.themeSections.unshift({
        'title': "Multirow",
        'key': 'multirow'
      })
    }


  }

  getThemeVersion(themeId) {

    return new Promise((resolve) => {

      this.amBuilderService.getThemeVersion(themeId)
        .subscribe((response) => {

          // console.log(response);
          let tmp = JSON.parse(localStorage.getItem('ampify-user'));

          if(response['is_theme_2_0'] == 3){

            this.version = '3';
            this.getColorSchemes(this.themeId);
            resolve('3');
          } else if(tmp.shopDomain == 'ef420.myshopify.com'){

            this.version = 'ef420';
            resolve('ef420');
          } else {

            this.version = '2';
            resolve('2');

          }
        })
      })

  }

    getThemeVersionSubject() {

      this.themeVersionSubscription = this.data_prepare.getSaveData().subscribe(res => {

        // //console.log("getSaveData", res);
        this.version = res;
        // this.putThemeData();
      })
    };


  async formShow(data) {

    console.log("data.......", data);
    this.selectedSectionData = { ...data };
    let labelData = Constants.labelDataV7;
    this.formArray = await this.data_prepare.prepareJson(data, data.schemaSettings);
    this.routeData = data.route;
    this.pIndex = data.pIndex;
    this.headerName = data.headingText;
    //console.log(this.formArray, this.routeData, this.headerName);
    const message = {
      type: 'SECTION_SELECT',
      configuration: {
        id: this.routeData[0]
      }
    };
    this.pubsub.publishEvent('SEND_IFRAME_EVENT', message);
    // this.data_prepare.setScrollSection(this.routeData[0])
    this.showForm = true;
    this.showFormO();
  }

  hideForm() {

    // this.showForm = false;
    this.stateArray.pop();

  }

  pageChange(){

    this.subscription = this.data_prepare.getPageChangeEvent().subscribe(res => {

      if(this.showForm){
      this.hideForm();
    this.showForm = false;
    }
    })

  }

  submitData($event) {

    this.returnObj = $event;
    this.checkAlreadySettingsPresent();
    // this.showForm = false;
  }


  checkAlreadySettingsPresent() {

    let keys = Object.keys(this.returnObj)
    let settings = {};
    if (keys.length) {

      if (this.returnObj['route'].length == 1) {

        settings = this.returnObj['settings'];
        this.mainMenu.sections[this.returnObj['route'][0]].settings = settings;

      } else {

        settings = this.returnObj['settings'];

        this.mainMenu.sections[this.returnObj['route'][0]]['blocks'][this.returnObj['route'][1]].settings = settings;
      }
    }
    this.data_prepare.setData(this.mainMenu);

  }


  dragDropItem(value) {

    moveItemInArray(value.order, value.previousIndex, value.currentIndex);
    //console.log("dragDropItem", value)

    // this.sidemenuObj.order = value.order;
  }

  getFormFlag() {

    this.subscription = this.data_prepare.getSaveFlag().subscribe(res => {

      //console.log("getSaveData", res);
      this.showForm = false;

      if (res) {
        //  this.onSubmit(this.dynamicForm.value);
      }

    })
  };

  async showThemeSett(data) {

    if(data.schemaKey != 'colors') {
    data.settings = this.currentConfig;
    }
     console.log("showThemeSett", data);
    this.selectedSectionData = {...data};
    this.formArray = await this.data_prepare.prepareSettingJson(data, data.schemaSettings);
    this.headerName = data.name;
    this.routeData = data.route;

    this.showForm = true;
    this.showFormO();


  }

  getThemeSettingData() {

    this.subscription = this.data_prepare.getThemeSettings().subscribe(res => {

      console.log("getThemeSettingData",res);
      this.currentConfig = res;

      console.log("///////getThemeSettingData........",res['sections']);
      if(this.version == 3 || this.version == '3') {

      this.headerMenuObj['sections'] = res['sections'];
      this.headerMenuObj['order'] = res['order']


      let footObj = {
        'footer': res['sections']['footer']
      }
      this.footerMenuObj['sections'] = footObj;
      this.footerMenuObj['order'] = ['footer'];
    } else {

      this.currentConfig = res;
      let obj = {
        'announcement-bar': res['sections']['announcement-bar'],
        'header': res['sections']['header']
      }
      this.headerMenuObj['sections'] = obj;
      this.headerMenuObj['order'] = ['announcement-bar','header']

      //console.log("headerMenuObj",this.headerMenuObj);


      let footObj = {
        'footer': res['sections']['footer']
      }
      this.footerMenuObj['sections'] = footObj;
      this.footerMenuObj['order'] = ['footer'];

    }

      // let obj = {
      //   'announcement-bar': res['sections']['announcement-bar'],
      //   'header': res['sections']['header'],

      // }

      // if(!res['sections']['custom_section']){
      //   this.headerMenuObj =  {
      //     "sections": {
      //       'announcement-bar': res['sections']['announcement-bar'],
      //       'header': res['sections']['header'],
      //     },
      //     "order": [
      //       "announcement-bar",
      //       "header",
      //     ]
      //   }

      // }else{
      //   let orderArr = [];
      //   for (let i = 0; i < Object.keys(res['sections']).length; i++) {
      //     let dyanamicVar = `custom_section-${i}`
      //     if(res['sections'][dyanamicVar]){
      //       obj[dyanamicVar]  = res['sections'][dyanamicVar]
      //       // this.headerMenuObj['order'].unshift(dyanamicVar);
      //       orderArr.push(dyanamicVar);
      //     }else if (res['sections']['custom_section']){
      //       obj["custom_section"]  = res['sections']['custom_section']
      //     }
      //   }
      //   console.log("orderArr",orderArr);
      //   this.headerMenuObj['order'] = [...orderArr, ...this.headerMenuObj['order']];

      //   this.headerMenuObj['sections'] = obj;
      // }

      // console.log("///////getThemeSettingData this.headerMenuObj['sections']........",obj);



    })
  };

  getSectionData() {

    this.subscription = this.data_prepare.getSectionData().subscribe(res => {

      //console.log("$$$$getSectionData$$$$", res);
      this.mainMenu = res;
      if(this.stateArray.length != 0){
        for(let i=0; i < this.stateArray.length; i++){
          if(this.stateArray[i].type == 'sidemenu'){
            this.stateArray[i]['data'] = this.mainMenu;
          }
        }
      }
      this.stateArray.push({
        type: 'sidemenu',
        data: this.mainMenu,
        routeData: [],
        headerText: ''
      })
    })
  }

  showSidemenu() {

    //console.log("calling")
    this.stateArray.push({
      type: 'sidemenu',
      data: this.mainMenu,
      routeData: [],
      headerText: ''
    })
    //console.log("showSidemenu", this.stateArray)
  }
  showFormO() {

    this.stateArray.push({
      type: 'form',
      data: this.formArray,
      routeData: this.routeData,
      headerText: this.headerName
    })

  }
  closeForm() {

    this.showForm = false;
    this.stateArray.pop();
    if(this.stateArray[0]['type'] == 'color-schemes' && this.stateArray[0]['routeData'].length == 2){
      this.stateArray[0]['routeData'].pop();
    }
    console.log("closeform",this.stateArray);
  }

  setPanelFlag($event) {

    //console.log("setPanelFlag", $event, this.stateArray);

    if(this.stateArray.length == 1){

      for (let i = 0; i < this.stateArray[0].data.order.length; i++) {
        //console.log("in loop")
              this.stateArray[0].data.sections[this.stateArray[0].data.order[i]]['isPanelOpened'] = false;


            }

            if($event.flag){

              this.stateArray[0].data.sections[$event.item]['isPanelOpened'] = true;

            }
    } else if(this.stateArray.length > 1){

      for(let i=0; i < this.stateArray.length; i++){

        if(this.stateArray[i]['type'] == 'sidemenu'){

          for (let j = 0; j < this.stateArray[i].data.order.length; j++) {
            //console.log("in loop")
                  this.stateArray[i].data.sections[this.stateArray[i].data.order[j]]['isPanelOpened'] = false;


                }

                if($event.flag){

                  this.stateArray[i].data.sections[$event.item]['isPanelOpened'] = true;

                }

        }
      }

    }



    console.log("showSidemenu", this.stateArray)

  }
  async showThemeSettingForm(value) {

    console.log(this.version);
    // this.stateArray.pop();
    this.stateArray = [];

    if (value == 'sidemenu') {
      this.showSidemenu();

    } else if (value == 'form') {

      this.showFormO();
    }
    else if (value == 'theme-settings') {

      this.headerName = 'Theme Settings';

      let themeSettingsData : any = [];

      if(this.version == '2'){
        themeSettingsData = this.themeSettingsV7;
      } else if(this.version == '3'){
        themeSettingsData = this.themeSettingsV10;

      } else if(this.version == 'ef420'){
        themeSettingsData = this.themeSettingsEf420;
      }

      themeSettingsData = await this.getSchemaSettings(themeSettingsData);
      console.log("themeSettingsData",themeSettingsData)

      this.stateArray.push({
        type: 'theme-settings',
        data: themeSettingsData,
        routeData: ['theme-settings'],
        headerText: 'Theme Settings'
      })
    } else if (value == 'analytics') {

      this.headerName = 'Google Analytics'

      this.stateArray.push({
        type: 'form',
        data: this.arr,
        routeData: this.routeData,
        headerText: this.headerName
      })
      //console.log("ana", this.stateArray);
    } else if(value == 'pages'){

      this.headerName = 'Pages';

      //console.log(this.amBuilderService.builderConfig.currentPage)

     await this.showCssTextPageWise();

      this.stateArray.push({
        type: 'form',
        data: this.cssFormArray,
        routeData: ['pages'],
        headerText: this.headerName
      });
      //console.log("pages",this.stateArray);

    } else if(value == 'color-schemes'){

      console.log("in color-schemes");
      this.headerName = 'Color Schemes';
      this.colorSchemes = await this.getColorSchemaSettings(this.colorSchemes);

      this.stateArray.push({
        type: 'color-schemes',
        data: this.colorSchemes,
        routeData: ['color_schemes'],
        headerText: this.headerName
      });
    }
    }
  showCssTextPageWise(){
    console.log(this.amBuilderService.builderConfig.currentPage);
    return new Promise((resolve) => {

      let arr = [];
      for(let i=0; i < this.cssPages.length; i++){

        if(i == 0){
          arr.push(this.cssPages[0]);

        } else {

          if(this.amBuilderService.builderConfig.currentPage == 'templates/product.json'){

            arr.push(this.cssPages[2]);
            resolve(arr);
            break
          } else if(this.amBuilderService.builderConfig.currentPage == 'templates/index.json'){

            arr.push(this.cssPages[1]);
            resolve(arr);
            break
          } else if(this.amBuilderService.builderConfig.currentPage == 'templates/collection.json'){

            arr.push(this.cssPages[3]);
            resolve(arr);
            break
          } else if(this.amBuilderService.builderConfig.currentPage == 'templates/list-collections.json'){

            arr.push(this.cssPages[4]);
            resolve(arr);
            break
          } else if(this.amBuilderService.builderConfig.currentPage == 'templates/blog.json'){

            arr.push(this.cssPages[5]);
            resolve(arr);
            break
          }
          else if(this.amBuilderService.builderConfig.currentPage == 'templates/article.json'){

            arr.push(this.cssPages[6]);
            resolve(arr);
            break
          }

        }
      }
    this.showForm = true;

      this.cssFormArray = arr;
      resolve(this.cssFormArray);


    })
  }
  addSection(section) {

    console.log(section);
    let newSectionName = section;

    let newSection = {};

    let newObj;

    if(this.version == '2'){
      newObj = JSON.parse(JSON.stringify(Constants.addSectionDataV7.sections[section]));
    } else if(this.version == '3'){

      newObj = JSON.parse(JSON.stringify(Constants.addSectionDataV10.sections[section]));

    } else if(this.version == 'ef420'){
      newObj = JSON.parse(JSON.stringify(Constants.addSectionDataEf420.sections[section]));
    }
    console.log("new section", newObj)
    // Check Already Section Present or not

    let cnt = 0;
    for (let i = 0; i < this.mainMenu.order.length; i++) {

      if (this.mainMenu.order[i].includes(newSectionName)) {
        cnt++;

      }
    }
    newSection['cnt'] = cnt;
    if(cnt != 0){
      let tmp: any = newSectionName + "-" + (cnt - 1);
      this.mainMenu.order.push(tmp);
      this.mainMenu.sections[tmp] = newObj;
    } else {

      this.mainMenu.order.push(newSectionName)
      this.mainMenu.sections[newSectionName] = newObj;
    }
    // console.log('cnt..................',this.mainMenu);
    this.stateArray[0].data = this.mainMenu;
    localStorage.setItem('uData',JSON.stringify(this.mainMenu))
    this.pubsub.publishEvent('REFRESH_IFRAME_EVENT', this.amBuilderService.builderConfig.currentPage);

  }
  getAnalyticsObj(){

    this.subscription = this.data_prepare.getAnalyticsSettings().subscribe(res => {


      //console.log("getAnalyticsObj",res);
      this.analyticsData = res;
      this.bindAnalyticsValues();

    })

  }

  getCssObj(){

    this.subscription = this.data_prepare.getCssSettings().subscribe(res => {


      //console.log("getAnalyticsObj",res);
      this.cssData = res['customCSS'];
      this.bindCssValues();

    })

  }

  bindAnalyticsValues(){

    let keys = Object.keys(this.analyticsData);
    // //console.log(this.arr)

    for(let i=0; i < keys.length; i++){

      // if()
      for(let j=0; j < this.arr.length; j++){

        // //console.log(keys[i] , this.arr[j]['key'])

        if(keys[i] == this.arr[j]['key']){
        this.arr[j]['value'] = this.analyticsData[keys[i]];
        break;
      }

      }
    }
    // //console.log(this.arr);

  }

  bindCssValues(){

    //console.log(this.cssData)
    let keys = Object.keys(this.cssData);
    //console.log(keys)

    for(let i=0; i < keys.length; i++){

      // if()
      for(let j=0; j < this.cssPages.length; j++){

        // //console.log(keys[i] , this.arr[j]['key'])

        if(keys[i] == this.cssPages[j]['key']){
        this.cssPages[j]['value'] = this.cssData[keys[i]];
        break;
      }

      }
    }
    // //console.log(this.arr);

  }

  addSectionBlocks(value){

    console.log(value);
    if(value.field == 'buttons'){
      value.field = 'button'
    }
    let tmp = value.parentType.split("-").join("_")
    //console.log(tmp);

    let newBlock;

    if(this.version == '2'){
      newBlock = JSON.parse(JSON.stringify(Constants.addSectionDataV7.sections[tmp].blocks[value.field]));
    } else if(this.version == '3'){
      newBlock = JSON.parse(JSON.stringify(Constants.addSectionDataV10.sections[tmp].blocks[value.field]));

    } else if(this.version == 'ef420'){
      newBlock = JSON.parse(JSON.stringify(Constants.addSectionDataEf420.sections[tmp].blocks[value.field]));
    }

      //  console.log("//////new block",newBlock);

    let cnt = 0;
    for(let i=0; i < this.mainMenu.sections[value.sectionName].block_order.length; i++){

      if(this.mainMenu.sections[value.sectionName].block_order[i].includes(value.field)){
        cnt++;

      }
    }
    let tmpName = value.field + "-" + (cnt - 1);

    if(cnt != 0){
      this.mainMenu.sections[value.sectionName].block_order.push(tmpName);
      this.mainMenu.sections[value.sectionName].blocks[tmpName] = newBlock;
    } else {

      this.mainMenu.sections[value.sectionName].block_order.push(value.field);
      this.mainMenu.sections[value.sectionName].blocks[value.field] = newBlock;
    }

    this.stateArray[0].data = this.mainMenu;
    localStorage.setItem('uData',JSON.stringify(this.mainMenu))
    this.pubsub.publishEvent('REFRESH_IFRAME_EVENT', this.amBuilderService.builderConfig.currentPage);

  }

  addAnnouncementBarBlock(value){

    let newBlock = JSON.parse(JSON.stringify(this.announcementObj));

    let cnt = 0;

    for(let i=0; i < this.headerMenuObj.sections[value.sectionName].block_order.length; i++){

      if(this.headerMenuObj.sections[value.sectionName].block_order[i].includes(value.field)){

        cnt++;

      }
    }

    let tmpName = value.field + "-" + cnt;
    if(cnt != 0){
      this.headerMenuObj.sections[value.sectionName].block_order.push(tmpName);
      this.headerMenuObj.sections[value.sectionName].blocks[tmpName] = newBlock;
    } else {

      this.headerMenuObj.sections[value.sectionName].block_order.push(value.field);
      this.headerMenuObj.sections[value.sectionName].blocks[value.field] = newBlock;
    }

    this.pubsub.publishEvent('REFRESH_IFRAME_EVENT', this.amBuilderService.builderConfig.currentPage);

  }

  newHeaderBlock(value){

    this.headerMenuObj = value;

    let arr = this.headerMenuObj.order;
    let arr1 = this.footerMenuObj.order;

    let finalArr = [...arr, ...arr1];
    let obj = {}

    console.log("finalArr",finalArr);
   for(let i=0; i < finalArr.length; i++) {

    console.log("finalArr[i]",this.headerMenuObj.sections[finalArr[i]]);
    if(finalArr[i] != 'footer'){
      obj[finalArr[i]] = this.headerMenuObj.sections[finalArr[i]]
    } else {
      obj[finalArr[i]] = this.footerMenuObj.sections[finalArr[i]]

    }
   }
   console.log("value",this.headerMenuObj.sections);
   this.headerMenuObj.sections = obj;
   this.currentConfig['sections'] = this.headerMenuObj.sections;
   this.data_prepare.setheaderCustomeComponent(this.headerMenuObj.sections);

   this.pubsub.publishEvent('REFRESH_IFRAME_EVENT', this.amBuilderService.builderConfig.currentPage);

    // let newBlock = JSON.parse(JSON.stringify(value));

    // let cnt = 0;

    // for(let i=0; i < this.headerMenuObj.sections[value.sectionName].block_order.length; i++){

    //   if(this.headerMenuObj.sections[value.sectionName].block_order[i].includes(value.field)){

    //     cnt++;

    //   }
    // }

    // let tmpName = value.field + "-" + cnt;
    // console.log("//////check",tmpName);

    // if(cnt != 0){
    //   this.headerMenuObj.sections[value.sectionName].block_order.push(tmpName);
    //   this.headerMenuObj.sections[value.sectionName].blocks[tmpName] = newBlock;
    // } else {

    //   this.headerMenuObj.sections[value.sectionName].block_order.push(value.field);
    //   this.headerMenuObj.sections[value.sectionName].blocks[value.field] = newBlock;
    // }

    console.log("///////////setheaderCustomeComponent.....>>>>>",this.headerMenuObj);


    this.data_prepare.setheaderCustomeComponent(this.headerMenuObj)

  }

  addFooterBlock(value){

    //console.log(value);
    let tmp = value.parentType.split("-").join("_")


    let newBlock;

    if(this.version == '2'){
      newBlock = JSON.parse(JSON.stringify(Constants.addSectionDataV7.sections[tmp].blocks[value.field]));
    } else if(this.version == '3'){
      newBlock = JSON.parse(JSON.stringify(Constants.addSectionDataV10.sections[tmp].blocks[value.field]));

    } else if(this.version == 'ef420'){
      newBlock = JSON.parse(JSON.stringify(Constants.addSectionDataEf420.sections[tmp].blocks[value.field]));
    }


 let cnt = 0;
    for(let i=0; i < this.footerMenuObj.sections[value.sectionName].block_order.length; i++){

      if(this.footerMenuObj.sections[value.sectionName].block_order[i].includes(value.field)){
        cnt++;

      }
    }
    //console.log("cnt",cnt);
    let tmpName = value.field + "-" + cnt;
    if(cnt != 0){
      this.footerMenuObj.sections[value.sectionName].block_order.push(tmpName);
      this.footerMenuObj.sections[value.sectionName].blocks[tmpName] = newBlock;
    } else {

      this.footerMenuObj.sections[value.sectionName].block_order.push(value.field);
      this.footerMenuObj.sections[value.sectionName].blocks[value.field] = newBlock;
    }
    this.pubsub.publishEvent('REFRESH_IFRAME_EVENT', this.amBuilderService.builderConfig.currentPage);

  }

  getSchemaSettings(array){

    return new Promise((resolve) => {

    for(let i=0; i < array.length; i++) {

      array[i]['schemaSettings'] = Constants.getSchemaThemeSettObject(array[i]['key'])

    }
    resolve(array);
  })
  }

  getColorSchemaSettings(array){

    return new Promise((resolve) => {

    for(let i=0; i < array.length; i++) {

      array[i]['schemaSettings'] = Constants.getSchemaThemeSettObject(array[i]['schemaKey'])

    }
    resolve(array);
  })
  }

  getColorSchemes(themeId) {

    return new Promise((resolve) => {

      this.amBuilderService.getColorSchemesV3(themeId)
        .subscribe((response) => {

          // console.log(response);
          this.colorSchemes = response;
          this.amBuilderService.colorSchemes = response;
          resolve(response);

        })
      })
    }
}

