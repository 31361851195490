import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { textChangeRangeIsUnchanged } from 'typescript';

import { environment } from '../../../environments/environment';

import { AuthService } from '../../auth/auth.service';
import { SharedService } from '../shared.service';

@Component({
  selector: 'am-upgrade-modal',
  templateUrl: './upgrade-modal.component.html',
  styleUrls: ['./upgrade-modal.component.scss']
})
export class UpgradeModalComponent implements OnInit {

  @Input() public inputData: any;
  // @Input() public inputDiscountPercentage: any;
  // @Input() public inputFreeTrialDays : any;


  successMessage: string = "";
  upgradeUrl: string = "";
  discountCode: string;
  discountPercentage: any;
  freeTrialDays = 56;

  discountError: string = "";
  plansArray = [
    {
      "name": "Basic",
      "offerings": [
        "Unlimited AMP views and products",
        "Links to your store",
        "Powerful editor",
        "Super fast pages",
        "Sticky Add to cart",
        "Buy it now"
      ],
      "priceWithCurrency": "$9.99",
      "price": 9.99,
      "id": "basic"
    },
    {
      "name": "Hobbyist",
      "offerings": [
        "All from Basic",
        "AMP collection pages",
        "Advanced analytics",
        "3rd party apps",
        "13 review integrations",
        "Suggested products",
        "AMP Validation"
      ],
      "priceWithCurrency": "$24.99",
      "price": 24.99,
      "id": "hobbyist"
    },
    {
      "name": "Small Company",
      "offerings": [
        "All from Hobbyist",
        "Custom domain, pages on any url you want with CDN",
        "Ampified blogs & Shopify pages",
        "Home page support"
      ],
      "priceWithCurrency": "$29.99",
      "price": 29.99,
      "id": "company"
    }
  ]

  shopifyPlusPlansArray = [
    {
      "name": "Basic",
      "offerings": [
        "Unlimited AMP views and products",
        "Links to your store",
        "Powerful editor",
        "Super fast pages",
        "Sticky Add to cart",
        "Buy it now"
      ],
      "priceWithCurrency": "$9.99",
      "price": 29.99,
      "id": "basic"
    },
    {
      "name": "Hobbyist",
      "offerings": [
        "All from Basic",
        "AMP collection pages",
        "Advanced analytics",
        "3rd party apps",
        "13 review integrations",
        "Suggested products",
        "AMP Validation"
      ],
      "priceWithCurrency": "$24.99",
      "price": 54.99,
      "id": "hobbyist"
    },
    {
      "name": "Small Company",
      "offerings": [
        "All from Hobbyist",
        "Custom domain, pages on any url you want with CDN",
        "Ampified blogs & Shopify pages",
        "Home page support"
      ],
      "priceWithCurrency": "$29.99",
      "price": 59.99,
      "id": "company"
    }
  ]

  freePlanObj: any = {
    "name": "Free",
    "offerings": [
      "Unlimited AMP views and products",
      "Links to your store",
      "Powerful editor",
      "Super fast pages",
      "Sticky Add to cart",
      "Buy it now"
    ],
    "priceWithCurrency": "$0",
    "price": 0,
    "id": "free"
  }

  currentPlanName = 'basic';
  discountedPrice: string = "";
  // upgradeUrl: string;
  // discountCodeCompany : string = '';
  // discountCodeHobbyist : string = '';
  // discountError : string = '';
  // discountedPrice : string = '';
  // discountedPriceCompany : string = '';

  // hobbyistFlag = false;
  // companyFlag = false;

  // discountAppliedHobbyist = false;
  // discountAppliedCompany = false;

  // successMessageHobbyist = '';
  // successMessageCompany = '';

  newSelectedPlan: any = '';
  newSelectedPlanValue: any = '';
  newSelectedPlanValueWithoutCurrency: any = '';



  newSelectedPlanId: any = '';
  user: any = {};
  shop: any;

  // freeTrialDays = 56;


  constructor(
    private authService: AuthService,
    public activeModal: NgbActiveModal,
    public sharedService: SharedService
  ) { }

  ngOnInit() {

    // console.log(this.inputData);
    this.user = this.authService.getUser();
    this.shop = this.user.shopDomain;
    this.currentPlanName = this.user.planName;
    this.upgradeUrl = `${environment.baseUrl}/install/upgrade?shop=${this.shop}&tier=`;


    if (this.inputData.shopifyPlanName == 'shopify_plus') {

      this.plansArray = this.shopifyPlusPlansArray;
    }

    // this.currentPlanName = 'free';
    if (this.currentPlanName == 'free') {

      this.plansArray[0] = this.freePlanObj;
    }

    // console.log("ABCD",this.inputData.inputFreeTrialDays);

    if (this.inputData.inputDiscount) this.discountCode = this.inputData.inputDiscount;
    if (this.inputData.inputDiscountPercentage) this.discountPercentage = this.inputData.inputDiscountPercentage;
    if (this.inputData.inputFreeTrialDays || this.inputData.inputFreeTrialDays == 0) this.freeTrialDays = this.inputData.inputFreeTrialDays;


    // console.log(this.freeTrialDays);


    this.selectNewPlan(this.currentPlanName);

  }



  selectNewPlan(planId) {

    for (let i = 0; i < this.plansArray.length; i++) {

      if (planId == this.plansArray[i]['id']) {

        this.newSelectedPlan = planId;
        this.newSelectedPlanValue = this.plansArray[i].priceWithCurrency;
        this.newSelectedPlanValueWithoutCurrency = this.plansArray[i].price;
        this.newSelectedPlanId = this.plansArray[i]['id'];
        // this.successMessage = '';
        // this.discountError = '';

      }
    }

    if (this.discountPercentage) {

      this.discountedPrice = (parseFloat((this.newSelectedPlanValueWithoutCurrency - ((this.newSelectedPlanValueWithoutCurrency * parseFloat(this.discountPercentage)))).toString())).toFixed(2);
      this.successMessage = 'Code applied successfully.';
    }
  }

  cancel() {
    this.activeModal.close();
  }


  checkDiscountCode(code, newSelectedPlanId) {

    if (code) {

      this.sharedService.getDiscountData({ discountCode: code, shopDomain: this.shop }).subscribe(res => {

        if (res && res.discount) {

          // console.log("code",code);

          this.discountPercentage = res.discount;
          // this.discountedPrice = (parseFloat((9.99 - ((9.99 * parseFloat(this.discountPercentage)))).toString())).toFixed(2);
          this.successMessage = 'Code applied successfully.';
          this.discountCode = code;
          this.discountError = "";

          // console.log("Discount Code",this.discountCode);


          for (let i = 0; i < this.plansArray.length; i++) {

            if (newSelectedPlanId == this.plansArray[i]['id']) {

              this.discountedPrice = (parseFloat((this.plansArray[i]['price'] - ((this.plansArray[i]['price'] * parseFloat(this.discountPercentage)))).toString())).toFixed(2);
              this.plansArray[i]['discountedPrice'] = this.discountedPrice;
            }
          }
        } else {

          this.successMessage = "";
          this.discountError = "Please enter valid code.";
        }
      }, err => {

        this.discountCode = '';
        this.successMessage = "";
        this.discountError = "Please enter valid code.";
      })
    } else {

      this.successMessage = "";
      this.discountError = "Please enter valid code.";
    }

  }

  clear() {

    this.discountError = '';
    this.discountedPrice = "";
    this.successMessage = "";
    this.discountPercentage = 0;
  }



  removeDiscountCode(event) {
    this.discountedPrice = "";
  }

  goToUpgradePage() {

    let upgradeUrl = this.upgradeUrl + this.newSelectedPlanId + "&discount=" + this.discountCode;
    console.log("upgradeUrl", upgradeUrl);
    window.location.href = upgradeUrl;
  }
}
