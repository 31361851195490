import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getCountry'
})
export class GetCountryPipe implements PipeTransform {

  transform(value: any): any {
    return value == '' || value == null ? 'Unknown' : value;
  }

}
