<div class="Polaris-EmptyState">
  <div class="Polaris-EmptyState__Section">
    <div class="Polaris-EmptyState__DetailsContainer">
      <div class="Polaris-EmptyState__Details">
        <div class="Polaris-TextContainer">
          <p class="Polaris-DisplayText Polaris-DisplayText--sizeMedium">
            Upss. Something went wrong.
          </p>
          <div class="Polaris-EmptyState__Content">
            <p>Please try again. If the error is not resolved please reach out to us and we will help you.</p>
          </div>
        </div>
        <div class="Polaris-EmptyState__Actions">
          <div class="Polaris-Stack Polaris-Stack--alignmentCenter">
            <div class="Polaris-Stack__Item">
              <button (click)="tryAgain()" type="button"
                class="Polaris-Button Polaris-Button--primary Polaris-Button--sizeLarge">
                <span class="Polaris-Button__Content"><span class="Polaris-Button__Text">
                    Try again
                  </span>
                </span>
              </button>
            </div>

            <div class="Polaris-Stack__Item">
              <button (click)="contactUs()" type="button" class="Polaris-Button Polaris-Button--plain"
                data-polaris-unstyled="true">
                <span class="Polaris-Button__Content">
                  <span class="Polaris-Button__Text">Contact us</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Polaris-EmptyState__ImageContainer">
      <img src="/assets/amp/images/server-down.svg" role="presentation" alt="" class="Polaris-EmptyState__Image">
    </div>
  </div>
</div>