import { Component, OnInit } from '@angular/core';

import { OnboardingService } from '../onboarding.service';

@Component({
  selector: 'am-onboarding-layout',
  templateUrl: './onboarding-layout.component.html',
  styleUrls: ['./onboarding-layout.component.scss']
})
export class OnboardingLayoutComponent implements OnInit {

  onboardingConfiguration: any;

  constructor(
    private onboardingService: OnboardingService
  ) {
    this.onboardingConfiguration = this.onboardingService.onboardingConfiguration;
  }

  ngOnInit() {
  }

  getProgressPercentage(): number {
    return this.onboardingService.getProgressPercentage();
  }

}
