import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { NotifierService } from 'angular-notifier';

import { PwaOnboardingService } from '../pwa-onboarding.service';
import { PwaQuickConfig } from '../pwa-onboarding.model';


@Component({
  selector: 'am-pwa-onboarding-quick-config',
  templateUrl: './pwa-onboarding-quick-config.component.html',
  styleUrls: ['./pwa-onboarding-quick-config.component.scss']
})
export class PwaOnboardingQuickConfigComponent implements OnInit {

  config: PwaQuickConfig;
  error = false;
  loading = false;

  constructor(
    private pwaOnboardingService: PwaOnboardingService,
    private notifier: NotifierService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.pwaOnboardingService.setCurrentPage(3);
  }

  ngOnInit() {
    this.config = {
      icon: '',
      preview_icon: '',
      short_name: ''
    };
    this.pwaOnboardingService.getQuickConfig()
      .subscribe((conf: PwaQuickConfig) => {
        this.config = conf;
      }, err => { });

    this.pwaOnboardingService.setOnboardingStatus('DONE')
      .subscribe(res => {

      }, err => { });
  }

  imageUpdated($event): void {
    this.config.preview_icon = $event;
    this.config.icon = $event;
    this.error = false;
  }

  shortnameUpdated() {
    this.error = false;
  }

  saveAndContinue(): void {
    if (this.config.icon === '' || this.config.short_name === '') {
      this.error = true;
    } else {
      this.loading = true;
      this.pwaOnboardingService.updateQuickConfig(this.config)
        .subscribe((res: any) => {
          this.notifier.notify('default', 'Successfully updated.');
          this.loading = false;
          this.router.navigate(
            ['../finish'],
            {
              relativeTo: this.route,
              replaceUrl: false
            });
        }, err => {
          this.loading = false;
          this.notifier.notify('error', 'Failed saving your configuration, please try again.');
        });
    }
  }

}
