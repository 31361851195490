<div id="editComponent">
  <div class="edit-component-snippet-header">

    <div (click)="closeDialog()" id="closeEditComponent">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path
            d="M8 16c-.256 0-.512-.098-.707-.293-.39-.39-.39-1.023 0-1.414L11.586 10 7.293 5.707c-.39-.39-.39-1.023 0-1.414s1.023-.39 1.414 0l5 5c.39.39.39 1.023 0 1.414l-5 5c-.195.195-.45.293-.707.293z">
          </path>
        </svg>
      </div>
    </div>

    <h2>{{component.name | getLocalizedName}}</h2>

    <!-- FIELDS -->
    <div id="editComponentFieldsWrapper">
      <div id="editComponentFields">

        <div class="edit-section-card" *ngFor="let section of component.sections">

          <div class="theme-setting--header">
            <h3 class="ui-subheading">
              {{(section.header.content || 'Settings') | getLocalizedName}}
            </h3>
          </div>

          <ul class="no-list-style">
            <li *ngFor="let item of section.settings">
              <am-edit-field [component]="component" [item]="item" [values]="editData.settings"></am-edit-field>
            </li>
          </ul>
        </div>
      </div>

      <!-- BLOCK CONTENTS -->
      <div *ngIf="component.blocks && component.blocks.length > 0" class="editComponentBlocks">

        <div class="theme-setting--header">
          <h3 class="ui-subheading">
            {{('Content') | getLocalizedName}}
          </h3>
        </div>

        <div class="edit-section-card">
          <ul cdkDropList (cdkDropListDropped)="dropBlock($event)" [cdkDropListData]="editData.block_order"
            class="plain-list">
            <li *ngFor="let blockId of editData.block_order" cdkDrag cdkDragBoundary=".edit-section-card"
              [cdkDragData]="blockId" cdkDragLockAxis="y" (cdkDragStart)="dragStarted($event)" class="plain-list">

              <am-edit-section-content [blockDefinitions]="component.blocks" [blockId]="blockId" [editData]="editData"
                (removBlockContentEvent)="removeBlockContent(blockId)">

                <div cdkDragHandle class="theme-editor-action-list-item-dragable">
                  <i class="icon-drag-handle"></i>
                </div>

              </am-edit-section-content>

            </li>
          </ul>
        </div>

        <button class="button link add-content" type="button" name="button">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <title>add-section</title>
              <path
                d="M2 0H1C.448 0 0 .448 0 1v1c0 .552.448 1 1 1s1-.448 1-1c.552 0 1-.448 1-1s-.448-1-1-1zm4 2h2c.552 0 1-.448 1-1s-.448-1-1-1H6c-.552 0-1 .448-1 1s.448 1 1 1zm8-2h-2c-.552 0-1 .448-1 1s.448 1 1 1h2c.552 0 1-.448 1-1s-.448-1-1-1zM8 18H6c-.552 0-1 .448-1 1s.448 1 1 1h2c.552 0 1-.448 1-1s-.448-1-1-1zm6 0h-2c-.552 0-1 .448-1 1s.448 1 1 1h2c.552 0 1-.448 1-1s-.448-1-1-1zm5-18h-1c-.552 0-1 .448-1 1s.448 1 1 1c0 .552.448 1 1 1s1-.448 1-1V1c0-.552-.448-1-1-1zm0 17c-.552 0-1 .448-1 1-.552 0-1 .448-1 1s.448 1 1 1h1c.552 0 1-.448 1-1v-1c0-.552-.448-1-1-1zM2 18c0-.552-.448-1-1-1s-1 .448-1 1v1c0 .552.448 1 1 1h1c.552 0 1-.448 1-1s-.448-1-1-1zm-1-3c.552 0 1-.448 1-1v-2c0-.552-.448-1-1-1s-1 .448-1 1v2c0 .552.448 1 1 1zm0-6c.552 0 1-.448 1-1V6c0-.552-.448-1-1-1s-1 .448-1 1v2c0 .552.448 1 1 1zm18 2c-.552 0-1 .448-1 1v2c0 .552.448 1 1 1s1-.448 1-1v-2c0-.552-.448-1-1-1zm0-6c-.552 0-1 .448-1 1v2c0 .552.448 1 1 1s1-.448 1-1V6c0-.552-.448-1-1-1zm-5 4h-3V6c0-.552-.448-1-1-1s-1 .448-1 1v3H6c-.552 0-1 .448-1 1s.448 1 1 1h3v3c0 .552.448 1 1 1s1-.448 1-1v-3h3c.552 0 1-.448 1-1s-.448-1-1-1z">
              </path>
            </svg>
          </div>
          <div class="label" (click)="openAddContentList()">Add content</div>
        </button>

        <div *ngIf="showAddContentList">
          <ul class="theme-editor-action-list theme-editor-action-list--compact add-content-list">
            <li *ngFor="let block of component.blocks" (click)="addBlockContent(block)">
              <button class="ui-button ui-button--full-width theme-editor-action-list__item">
                {{block.name | getLocalizedName}}
              </button>
            </li>
          </ul>
        </div>

      </div>

      <!-- REMOVE SECTION -->
      <div class="remove-section">
        <button *ngIf="component.showRemoveSection" (click)="removeSection(component)"
          class="button secondary remove-section-btn">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
              d="M16 6a1 1 0 1 1 0 2h-1v9a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V8H4a1 1 0 1 1 0-2h12zM9 4a1 1 0 1 1 0-2h2a1 1 0 1 1 0 2H9zm2 12h2V8h-2v8zm-4 0h2V8H7v8z">
            </path>
          </svg>
          Remove section
        </button>
      </div>

    </div>

  </div>
</div>