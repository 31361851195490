import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { PolarisActionListComponent } from './polaris-action-list/polaris-action-list.component';
import { PolarisSelectComponent } from './polaris-select/polaris-select.component';

@NgModule({
  declarations: [
    PolarisActionListComponent,
    PolarisSelectComponent
  ],
  imports: [
    FormsModule,
    CommonModule
  ],
  exports: [
    PolarisActionListComponent,
    PolarisSelectComponent
  ]
})
export class PolarisModule { }
