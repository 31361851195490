<div id="preview-image-container" class="aspect-ratio aspect-ratio--square aspect-ratio--reset-z-index"
  [ngClass]="{'aspect-ratio aspect-ratio--square': !imageUrl}">
  <div class="aspect-ratio__content next-grid next-grid--center-both">
    <div class="text-center" *ngIf="imageUrl">
      <div id="upload-image-progress" class="ui top attached progress">
        <div class="bar"></div>
      </div>
      <img id="preview-image" [src]="imageUrl" />

      <span class="button-group">
        <button id="changeImageBtn" (click)="showPicker()" class="button secondary">Change</button>
        <button id="removeImageBtn" (click)="removeImage()" class="button secondary">Remove</button>
      </span>
    </div>

    <div *ngIf="!imageUrl" class="no-image">
      <button id="uploadImageBtn" (click)="showPicker()">Select image</button>
    </div>

  </div>
</div>