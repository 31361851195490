<div *ngIf="!isLoaded">
  <am-editor-loading></am-editor-loading>
</div>

<div *ngIf="isLoaded && isError">
  <am-editor-error-message [shopDomain]="shopDomain"></am-editor-error-message>
</div>

<div *ngIf="themeNotSupported">
  <div class="error-message">
    <h1>Theme not suported</h1>
    <p>Oops!
      This is a legacy theme, please use the old theme editor or create a new theme from our themes list.
    </p>
    <p>
      <a class="button button-primary" href="https://{{shopDomain}}/admin/apps/ampify-me?shop={{shopDomain}}">Back to
        Shopify admin</a>
    </p>
  </div>
</div>

<div *ngIf="isLoaded && !isError && !themeNotSupported" class="wrapper" [ngClass]="viewportSize">

  <am-top-navbar></am-top-navbar>

  <div id="builder-wrapper">
    <div class="wrapper-columns">

      <am-left-sidebar [themeId]="themeId"></am-left-sidebar>

      <am-builder-preview></am-builder-preview>

    </div>
  </div>

</div>