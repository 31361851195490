import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { AMPRoutingModule } from './amp-routing.module';

import { AmpLayoutComponent } from './amp-layout/amp-layout.component';
import { AmpLayoutHeaderComponent } from './amp-layout/amp-layout-header/amp-layout-header.component';
import { AmpLayoutLeftSidebarComponent } from './amp-layout/amp-layout-left-sidebar/amp-layout-left-sidebar.component';
import { AmpLayoutFooterComponent } from './amp-layout/amp-layout-footer/amp-layout-footer.component';


@NgModule({
  declarations: [
    AmpLayoutComponent,
    AmpLayoutHeaderComponent,
    AmpLayoutLeftSidebarComponent,
    AmpLayoutFooterComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,

    AMPRoutingModule
  ]
})
export class AmpModule { }
