import { Component, OnInit, Input } from '@angular/core';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';

@Component({
  selector: 'am-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss']
})
export class LeftSidebarComponent implements OnInit {

  @Input()
  themeId: string;

  changePageTypeEvent: any;

  activeTab = 'SECTIONS';

  constructor(
    private pubsub: NgxPubSubService
  ) { }

  ngOnInit() {

    // Event for changing the page type
    this.changePageTypeEvent = this.pubsub.subscribe('PAGE_TYPE_CHANGE', (newValue) => {
      this.activeTab = 'SECTIONS';
    });
  }

  changeTab(tab: string): void {
    this.activeTab = tab;
  }
}
