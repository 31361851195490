<!-- <div class="main" cdkDropList (cdkDropListDropped)="drop($any($event))">
    <div class="item-list" cdkDrag *ngFor="let item of section.order; let i = index">
        <span class="section" *ngIf="!section.sections[item].blocks">
            <span class="dropdown">
                <mat-icon *ngIf="section.sections[item].blocks">
                    arrow_right
                </mat-icon>
            </span>
            <mat-icon [ngStyle]="{'margin-left' : !section.sections[item].blocks ? '13px' : '0'}"
                class="material-symbols-outlined menu-icon">
                image
            </mat-icon>
            <p class="menu-option-title" style="width:100%;text-align: left;">{{item | replaceName}}</p>

            <div class="action-items">
                <mat-icon class="drag-drop-icon margin-right16 icon20" (click)="$event.stopPropagation();disableSection(item)">visibility</mat-icon>
                <mat-icon class="drag-drop-icon icon20">drag_indicator</mat-icon>
            </div>
        </span>

        <div class="expandable" *ngIf="section.sections[item].blocks">
            <mat-expansion-panel [hideToggle]="true">
                <mat-expansion-panel-header  #expansion (click)="$event.stopPropagation();">

                    <span class="section" *ngIf="section.sections[item].blocks" [ngStyle]="{'opacity' : section.sections[item].disabled ? '0.6' : '1' }">
                        <span class="dropdown" *ngIf="section.sections[item].blocks">
                            <mat-icon (click)="openPanel(item,true)" *ngIf="!section.sections[item].isPanelOpened">
                                arrow_right
                            </mat-icon>
                            <mat-icon (click)="closePanel(item,false)" *ngIf="section.sections[item].isPanelOpened">
                                arrow_drop_down
                            </mat-icon>
                        </span>
                        
                        <span class="Polaris-Icon">
                            <span class="Polaris-VisuallyHidden">
                            </span>
                            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M2.5 1a1.5 1.5 0 0 0-1.5 1.5v15a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-15a1.5 1.5 0 0 0-1.5-1.5h-15zm13.5 4h-12v2h12v-2zm-12 4h12v2h-12v-2zm6 4h-6v2h6v-2z"/></svg>
                        </span>
                        <p class="menu-option-title" (click)="showParentForm(item,'',section.sections[item].settings,'parent',i)">
                            {{section.sections[item].type | replaceName}}</p>

                        <span class="actions">
                            <div class="action-items">
                                <mat-icon class="drag-drop-icon margin-right16 icon20">visibility</mat-icon>
                                <mat-icon class="drag-drop-icon icon20" cdkDragHandle>drag_indicator</mat-icon>
                            </div>
                        </span>
                    </span>
                </mat-expansion-panel-header>

                <span cdkDropList (cdkDropListDropped)="dropChild($any($event),i,item)">
                    <span *ngFor="let child of section.sections[item].block_order; let ix=index" class="sub-list">

                        <div class="section child-list" [ngStyle]="{'margin-top' : ix == 0 ? '11px' : '0'}" cdkDrag>
                            <span class="dropdown">
                            </span>
                            <mat-icon class="material-symbols-outlined menu-icon">
                                crop_free
                            </mat-icon>
                           
                            <p *ngIf="section.sections[item].blocks[child].type != 'button' " class="submenu-option-title" (click)="showParentForm(item,child,section.sections[item].blocks[child].settings,'child',i)">
                            {{ section.sections[item].blocks[child].settings[section.sections[item].blocks[child].type]}}   
                            </p>
                            <p *ngIf="section.sections[item].blocks[child].type == 'button' " class="submenu-option-title"  (click)="showParentForm(item,child,section.sections[item].blocks[child].settings,'child',i)">
                                Button   
                                </p>
                            <span class="actions">

                                <div class="action-sub-items">
                                    <mat-icon class="actions drag-drop-icon margin-right23 icon20">visibility</mat-icon>
                                    <mat-icon class="actions drag-drop-icon icon20" cdkDragHandle>drag_indicator
                                    </mat-icon>
                                </div>
                            </span>
                        </div>
                    </span>
                </span>
            </mat-expansion-panel>
        </div>

    </div>
</div> -->



<div class="main" cdkDropList (cdkDropListDropped)="drop($any($event))" *ngIf="!showThemeSetting">
    <div class="item-list" cdkDrag *ngFor="let item of section && section.order; let i = index">
        <am-expandable-block *ngIf="section.sections[item].type != 'reviews-importer-product' && section.sections[item].type != 'areviews-section' && section.sections[item].type != 'apps'" [section]="section" [orders]="section.order" [item]="item" [index]="i" (showSectionForm)="showParentForm($event)" (panelFlagChange)="panelOpen($event)" (addBlocks)="emitBlock($event)">
        </am-expandable-block>
      
    </div>
  
</div>




<!-- 
<div cdkDropList class="example-list" (cdkDropListDropped)="drop($any($event))">
    <div class="example-box" *ngFor="let child of targetArray" cdkDrag>{{child.name}}</div>
  </div> -->