import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { CookieService } from 'ngx-cookie-service';

import { environment } from '../../../../../environments/environment';

import { EditorService } from '../../editor.service';

@Component({
  selector: 'am-shared-image-picker',
  templateUrl: './shared-image-picker.component.html',
  styleUrls: ['./shared-image-picker.component.scss']
})
export class SharedImagePickerComponent implements OnInit, OnDestroy {

  baseUrl = environment.baseUrl;

  uploadedImages: Array<any> = [];
  imageUrl = '';
  imgObject = {
    id: 'img',
    url: ''
  };
  originalImage;

  showFooter = false;

  showImagePicker = false;
  showImagePickerSub;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private editorService: EditorService
  ) {
    // Load all uploaded image
    this.loadImages();
  }

  ngOnInit() {
    this.showImagePickerSub = this.editorService.openImagePicker
      .subscribe(obj => {
        this.imgObject = obj;
        this.imageUrl = obj.url;
        this.originalImage = obj.url;

        // Find selected image
        this.uploadedImages.forEach(image => {
          if (image.url === this.imageUrl) {
            image.selected = true;
          }
        });
        this.showImagePicker = true;
      });
  }

  ngOnDestroy(): void {
    this.showImagePickerSub.unsubscribe();
  }

  previewImage(): void {
    // TODO: Not sure how to do this without changing configuration???
    this.editorService.imageSelected.next({
      id: this.imgObject.id,
      url: this.imageUrl
    });
    this.showFooter = true;
  }

  closePickerBtn(): void {
    this.imageUrl = this.originalImage;
    this.closePicker();
  }

  closePicker(): void {
    this.editorService.imagePickerClosed.next({
      id: this.imgObject.id,
      url: this.imageUrl
    });
    this.showImagePicker = false;
    this.showFooter = false;

    this.uploadedImages.forEach(image => {
      image.selected = false;
    });
  }

  loadImages(): void {
    let headers = new HttpHeaders();
    const auth = this.cookieService.get('ampify-x-access-token');
    headers = headers.set('x-access-token', auth);

    this.http.get(this.baseUrl + '/api/upload/list', {
      headers: headers
    })
      .subscribe((items: any) => {
        items.forEach(item => {
          item.selected = false;
        });
        this.uploadedImages = items;
      }, err => {
        console.log(err);
      });

  }

  onFileChanged(event) {
    const file = event.target.files[0];

    let headers = new HttpHeaders();
    const auth = this.cookieService.get('ampify-x-access-token');
    headers = headers.set('x-access-token', auth);

    this.http.post(this.baseUrl + '/api/upload/editor', {
      'Content-Type': file.type
    }, {
      headers: headers
    })
      .subscribe((uploadReponse: any) => {
        // Upload to S3 now
        let s3headers = new HttpHeaders();
        s3headers = s3headers.set('Content-Type', file.type);
        s3headers = s3headers.set('Expires', 'Fri, 1 Jan 2018 23:59:59 GMT');
        this.http.put(uploadReponse.uploadUrl, file, {
          headers: s3headers,
          reportProgress: true,
          observe: 'events'
        })
          .subscribe((eventData: any) => {
            console.log("**",eventData);
            if (eventData) {
              if (eventData.type === 1) {
              }
              if (eventData.type === 4) {
                // All done
                console.log("*");
                this.imageUrl = uploadReponse.url;

                this.uploadedImages.forEach(image => {
                  image.selected = false;
                });
                // Add to this array
                this.uploadedImages.unshift({
                  id: this.imgObject.id,
                  url: this.imageUrl,
                  selected: false
                });
                this.uploadedImages[0].selected = true;
                this.previewImage();
                this.showFooter = true;
              }
            }
          }, (err) => {
            console.log(err);
          });

      }, (err) => {
        console.log(err);
      });
  }

  selectImage(image): void {
    this.uploadedImages.forEach(imageItem => {
      imageItem.selected = false;
    });
    image.selected = true;
    this.imageUrl = image.url;
    this.previewImage();
  }

  removeImage() {
    this.imageUrl = '';
    this.closePicker();
  }
}
