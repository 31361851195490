<div>
  <div class="Polaris-Modal-Dialog__Container undefined" data-polaris-layer="true" data-polaris-overlay="true">
    <div>
      <div class="Polaris-Modal-Dialog__Modal" role="dialog" aria-labelledby="modal-header2" tabindex="-1">

        <div class="Polaris-Modal__BodyWrapper">
          <div class="Polaris-Modal__Body Polaris-Scrollable Polaris-Scrollable--vertical"
            data-polaris-scrollable="true">

            <div class="free-trial-banner">
              <h3>7 DAYS FREE TRIAL</h3>
              <p>Upgrade or downgrade easily at any point in time.</p>

              <div class="close" (click)="cancel()">
                <span class="Polaris-Icon Polaris-Icon--colorInkLighter Polaris-Icon--isColored">
                  <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                    <path
                      d="M11.414 10l6.293-6.293a.999.999 0 1 0-1.414-1.414L10 8.586 3.707 2.293a.999.999 0 1 0-1.414 1.414L8.586 10l-6.293 6.293a.999.999 0 1 0 1.414 1.414L10 11.414l6.293 6.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L11.414 10z"
                      fill-rule="evenodd"></path>
                  </svg>
                </span>
              </div>
            </div>


            <section class="Polaris-Modal-Section">
              <div class="row">

                <div class="col-md-4 py-2 text-center">
                  <div class="text-center">
                    <h4 class="mb-4">Free</h4>
                    <span class="h5 text-muted">$0 per month</span>
                  </div>
                  <div class="card-body">
                    <ul class="list-unstyled mb-4">
                      <li>Configure icon, name, and colors</li>
                      <li>Add the store to home screen on iOS and Android</li>
                      <li>2.000 push notifications per month</li>
                      <li>Support for Android and Desktop push notifications</li>
                    </ul>

                    <div class="text-center">
                      <a href="{{upgradeUrl}}free" class="btn btn-primary">
                        Start now
                      </a>
                    </div>

                  </div>
                </div>

                <div class="col-md-4 py-2 text-center">
                  <div class="text-center">
                    <h4 class="mb-4">Hobbyist</h4>
                    <span class="h5 text-muted">$29.99 per month</span>
                  </div>
                  <div class="card-body">
                    <ul class="list-unstyled mb-4">
                      <li>All in Lite</li>
                      <li>Custom splash screen for iPhone</li>
                      <li>Add to home trigger, explain why customers should add your store to home screen</li>
                      <li>Analytics</li>
                      <li>50.000 push notifications per month</li>
                    </ul>

                    <div class="text-center">
                      <a href="{{upgradeUrl}}hobbyist" class="btn btn-primary">
                        Start now
                      </a>
                    </div>

                  </div>
                </div>

                <div class="col-md-4 py-2 text-center">
                  <div class="text-center">
                    <h4 class="mb-4">Company</h4>
                    <span class="h5 text-muted">$79.99 per month</span>
                  </div>
                  <div class="card-body">
                    <ul class="list-unstyled mb-4">
                      <li>All from Hobbyist</li>
                      <li>250.000 push notifications per month</li>
                    </ul>

                    <div class="text-center">
                      <a href="{{upgradeUrl}}company" class="btn btn-primary">
                        Start now
                      </a>
                    </div>

                  </div>
                </div>
              </div>
            </section>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<div class="Polaris-Backdrop"></div>