import { Component, OnInit } from '@angular/core';

import { PwaOnboardingService } from '../pwa-onboarding.service';

@Component({
  selector: 'am-pwa-onboarding-install-tutorial',
  templateUrl: './pwa-onboarding-install-tutorial.component.html',
  styleUrls: ['./pwa-onboarding-install-tutorial.component.scss']
})
export class PwaOnboardingInstallTutorialComponent implements OnInit {

  selectedDevice: string;

  constructor(
    private pwaOnboardingService: PwaOnboardingService
  ) {
    this.pwaOnboardingService.setCurrentPage(4);
  }

  ngOnInit() {
    this.pwaOnboardingService.setOnboardingStatus('DONE')
      .subscribe(res => { }, err => { });

    this.selectedDevice = 'ios';
  }

}
