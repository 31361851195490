import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { PwaAccountDetails } from './pwa-settings.model';


@Injectable({
  providedIn: 'root'
})
export class PwaSettingsService {

  basePWAUrl = environment.basePWAUrl;

  constructor(
    private http: HttpClient
  ) { }

  getAccountDetails(): Observable<PwaAccountDetails> {
    return this.http.get<PwaAccountDetails>(`${this.basePWAUrl}/api/v2/settings/account`);
  }

}
