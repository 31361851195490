import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'am-back-navigation-confirmation',
  templateUrl: './back-navigation-confirmation.component.html',
  styleUrls: ['./back-navigation-confirmation.component.scss']
})
export class BackNavigationConfirmationComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,

  ) { }

  ngOnInit(): void {
  }

  cancel(): void {
    this.activeModal.close(false);
  }

  save(){

    this.activeModal.close(true);


  }

}
