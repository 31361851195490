<div *ngIf="stateArray[stateArray.length - 1]['type'] == 'sidemenu'" class="sidemenu-container">
    <div class="sidebar-page-header">
        <h1 class="page-header-title">
            {{pageType}} Page
        </h1>
    </div>

    <am-header-v2 [showIcons]="'true'" [headerObj]="headerMenuObj" [section]="headerMenuObj" (showSectionForm)="formShow($event)" (addSectionBlock)="addAnnouncementBarBlock($event)" (newHeaderBlock)="newHeaderBlock($event)"></am-header-v2>

    <am-dynamic-sidebar-v2 [parentIndex]="pIndex" [section]="stateArray[stateArray.length - 1].data"
        (showSectionForm)="formShow($event)" (showSettingForm)="showThemeSett($event)" (panelSetting)="setPanelFlag($event)" (addSectionBlock)="addSectionBlocks($event)">
    </am-dynamic-sidebar-v2>
    <div class="add-section" [matMenuTriggerFor]="menu">
        <span class="Polaris-Icon">
            <span class="Polaris-VisuallyHidden">
            </span>
            <svg viewBox="0 0 20 20" fill="#2c6ecb" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15 10a1 1 0 0 1-1 1h-3v3a1 1 0 1 1-2 0v-3h-3a1 1 0 1 1 0-2h3v-3a1 1 0 0 1 2 0v3h3a1 1 0 0 1 1 1zm-5-8a8 8 0 1 0 0 16 8 8 0 0 0 0-16z" />
            </svg>
        </span>
        <p class="add-section-title">Add Section</p>

        <mat-menu #menu="matMenu" yPosition="above" xPosition="after">
            <!-- <button mat-menu-item>Item 1</button>
            <button mat-menu-item>Item 2</button> -->
            <div class="add-section-menu">
                <!-- <form class="nosubmit">
                    <input class="nosubmit" type="search" placeholder="Search...">
                  </form> -->
                <p class="theme-sections">
                    Theme Section
                </p>

                <div class="theme-section-block" *ngFor="let sc of themeSections"  (click)="addSection(sc.key)">
                    <span class="Polaris-Icon">
                        <span class="Polaris-VisuallyHidden">
                        </span>
                        <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1 2.5v6.5h18v-6.5a1.5 1.5 0 0 0-1.5-1.5h-15a1.5 1.5 0 0 0-1.5 1.5zm1 16.5a1 1 0 0 1-1-1v-2h2v1h1v2h-2zm17-1a1 1 0 0 1-1 1h-2v-2h1v-1h2v2zm-18-4v-3h2v3h-2zm16-3v3h2v-3h-2zm-11 6h3v2h-3v-2zm8 0h-3v2h3v-2z" />
                        </svg>
                    </span>
                    <p class="theme-section-title">{{sc.title}}</p>
                </div>

            </div>
        </mat-menu>
    </div>

    <am-footer-v2 [footerObj]="footerMenuObj" (showSectionForm)="formShow($event)" (addSectionBlock)="addFooterBlock($event)"></am-footer-v2>
</div>
<am-theme-settings-v2 *ngIf="stateArray[stateArray.length - 1].type == 'theme-settings' || stateArray[stateArray.length - 1].type == 'color-schemes'" [globalSetts]="stateArray[stateArray.length - 1].data" (showSettingForm)="showThemeSett($event)" [headerName]="headerName" [routesData]="stateArray[stateArray.length - 1].routeData"></am-theme-settings-v2>


<div *ngIf="stateArray[stateArray.length - 1].type == 'form'" class="form-container">
    <div *ngIf="headerName != 'Pages' && headerName != 'Google Analytics'" (click)="closeForm()" id="closeEditComponent">
        <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                    d="M8 16c-.256 0-.512-.098-.707-.293-.39-.39-.39-1.023 0-1.414L11.586 10 7.293 5.707c-.39-.39-.39-1.023 0-1.414s1.023-.39 1.414 0l5 5c.39.39.39 1.023 0 1.414l-5 5c-.195.195-.45.293-.707.293z">
                </path>
            </svg>
        </div>
    </div>

    <h2 style="text-transform: capitalize;" *ngIf="headerName != 'Google Analytics' && stateArray[stateArray.length - 1].type != 'theme-settings' && headerName != 'Pages'">{{headerName | replaceName : selectedSectionData }}</h2>
    <h2 [ngStyle]="{'padding-left' : headerName != 'Pages' && headerName != 'Google Analytics' ? '15px' : '20px'}" *ngIf="headerName == 'Google Analytics' || stateArray[stateArray.length - 1].type == 'theme-settings' || headerName == 'Pages' || stateArray[stateArray.length - 1].type == 'color-schemes' ">{{headerName | replaceName : selectedSectionData }}</h2>

    <am-form-v2 [mainMenu]="mainMenu" [headerMenu]="headerMenuObj" [footerMenu]="footerMenuObj"
        [currentMenu]="currentConfig" [sectionData]="selectedSectionData" [formJSON]="stateArray[stateArray.length - 1].data" [route]="stateArray[stateArray.length - 1].routeData"
        [headerTitle]="stateArray[stateArray.length - 1].headerText" (submitForm)="submitData($event)" (closeForm)="hideForm()"></am-form-v2>

</div>
<!-- <am-dynamic-form-v2 [formData]="formArray" (closeForm)="hideForm()" ></am-dynamic-form-v2> -->