import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'am-pwa-layout-footer',
  templateUrl: './pwa-layout-footer.component.html',
  styleUrls: ['./pwa-layout-footer.component.scss']
})
export class PwaLayoutFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
