import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { EditorService } from '../../editor.service';

@Component({
  selector: 'am-shared-font-picker',
  templateUrl: './shared-font-picker.component.html',
  styleUrls: ['./shared-font-picker.component.scss']
})
export class SharedFontPickerComponent implements OnInit, OnDestroy {

  selectedObj = {
    id: '',
    font: ''
  };
  selectedFont = '';
  originalFont = '';

  LOADED_FONTS_DOM = [];
  fonts = ['Alegreya', 'Alegreya Sans', 'Amiri', 'Anonymous Pro', 'Arapey', 'Archivo', 'Archivo Narrow', 'Arimo', 'Arvo', 'Asap', 'Assistant', 'Asul',
  'Bitter', 'Cabin', 'Cardo', 'Catamaran', 'Chivo', 'Domine', 'Dosis', 'Fira Sans', 'Fjalla One', 'Glegoo', 'IBM Plex Sans',
  'Inconsolata', 'Josefin Sans', 'Josefin Slab', 'Kalam', 'Karla', 'Kreon', 'Lato', 'Libre Baskerville', 'Libre Franklin', 'Lobster', 'Lobster Two', 'Lora', 'Maven Pro', 'Megrim',
  'Merriweather Sans', 'Montserrat', 'Mouse Memoirs', 'Neuton', 'Nobile', 'Noticia Text', 'Noto Serif', 'Nunito', 'Nunito Sans', 'Old Standard TT', 'Open Sans', 'Oswald', 'Ovo', 'Oxygen',
  'PT Mono', 'PT Sans', 'PT Sans Narrow', 'PT Serif', 'Pacifico', 'Playfair Display', 'Poppins', 'Prata', 'Prompt', 'Quantico', 'Quattrocento', 'Quattrocento Sans', 'Questrial',
  'Quicksand', 'Rajdhani', 'Raleway', 'Righteous', 'Roboto', 'Roboto Condensed', 'Roboto Mono', 'Roboto Slab', 'Rubik', 'Shadows Into Light', 'Source Code Pro',
  'Tenor Sans', 'Tinos', 'Titillium Web', 'Ubuntu', 'Unica One', 'Unna', 'Varela', 'Varela Round', 'Volkhov', 'Vollkorn', 'Work Sans','DMSans'];
  loadedFonts = [];
  pageNumber = 1;

  showFooter = false;
  showPicker = false;
  showPickerSub: any;

  constructor(
    @Inject(DOCUMENT) private doc,
    private editorService: EditorService
  ) { }

  ngOnInit() {
    this.showPickerSub = this.editorService.openFontPicker
      .subscribe(obj => {
        this.showPicker = true;
        this.selectedFont = obj.font;
        this.originalFont = obj.font;
        this.selectedObj = obj;
      });

    this.openPage(1);
  }

  ngOnDestroy(): void {
    this.showPickerSub.unsubscribe();
  }

  getFontName(font: string): string {
    font = font || '';
    return font.split('+').join(' ');
  }

  loadGoogleFontStyles(fonts: Array<any>) {
    fonts.forEach(font => {
      if (this.LOADED_FONTS_DOM.indexOf(font) < 0) {
        this.LOADED_FONTS_DOM.push(font);
        const link: HTMLLinkElement = this.doc.createElement('link');
        link.setAttribute('rel', 'stylesheet');
        link.setAttribute('id', 'font-' + font);
        link.setAttribute('href', `https://fonts.googleapis.com/css?family=${font}`);
        this.doc.head.appendChild(link);
      }
    });
  }

  openPage(page_number) {
    const page_size = 40;
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    const newFonts = this.fonts.slice((page_number - 1) * page_size, page_number * page_size);

    // Also load this stuff in DOM???
    this.loadGoogleFontStyles(newFonts);

    // Append to previous array
    this.loadedFonts = this.loadedFonts.concat(newFonts);
  }

  nextPage() {
    this.pageNumber++;
    this.openPage(this.pageNumber);
  }

  private internalClosePicker() {
    this.showPicker = false;
    this.showFooter = false;
    this.editorService.fontPickerClosed.next({
      id: this.selectedObj.id,
      font: this.selectedFont
    });
  }

  closePicker() {
    this.selectedFont = this.originalFont;
    this.previewFont(this.selectedFont);
    this.internalClosePicker();
  }

  previewFont(font: any) {
    this.selectedFont = font;
    this.editorService.previewFont({
      id: this.selectedObj.id,
      font: font
    });
    this.showFooter = true;
  }

  selectFont() {
    this.previewFont(this.selectedFont);
    // Close the picker
    this.internalClosePicker();
  }

}
