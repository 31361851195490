<div class="Polaris-Page__Content">
  <div class="Polaris-Layout">
    <div class="Polaris-Layout__Section">
      <div class="Polaris-Card">
        <div class="Polaris-TextContainer m-5">
          <p class="Polaris-DisplayText Polaris-DisplayText--sizeLarge">
            Congratulations 🎉
          </p>
          <p class="Polaris-DisplayText Polaris-DisplayText--sizeSmall">
            Your store is PWA enabled now. Among a lot of great features, the store can even be installed as an app.
            Here is how to do that quickly.
          </p>


        </div>
        <div class="Polaris-Card__Header">
          <div class="header-block">
            <h2 class="Polaris-Heading">Pick your device: </h2>
          </div>
          <div class="header-block">
            <div class="Polaris-ButtonGroup Polaris-ButtonGroup--segmented" data-buttongroup-segmented="true">
              <div class="Polaris-ButtonGroup__Item">
                <button (click)="selectedDevice = 'ios'" type="button" class="Polaris-Button"
                  [ngClass]="{'Polaris-Button--primary': selectedDevice === 'ios'}">
                  <span class="Polaris-Button__Content">
                    <span class="Polaris-Button__Text">iOS</span>
                  </span>
                </button>
              </div>
              <div class="Polaris-ButtonGroup__Item">
                <button (click)="selectedDevice = 'android'" type="button" class="Polaris-Button"
                  [ngClass]="{'Polaris-Button--primary': selectedDevice === 'android'}">
                  <span class="Polaris-Button__Content">
                    <span class="Polaris-Button__Text">Android</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="selectedDevice == 'ios'" class="Polaris-Card__Section device-smaller-7-wrapper">
          <div class="device device-iphone-8 device-silver device-smaller-7">
            <div class="device-frame">
              <img class="device-content" src="/assets/pwa/images/how-to-install-ios.gif">

            </div>
            <div class="device-stripe"></div>
            <div class="device-header"></div>
            <div class="device-sensors"></div>
            <div class="device-btns"></div>
            <div class="device-power"></div>
          </div>
        </div>
        <div *ngIf="selectedDevice == 'android'" class="Polaris-Card__Section device-smaller-7-wrapper">
          <div class="device device-galaxy-s8 device-smaller-7">
            <div class="device-frame">
              <img class="device-content" src="/assets/pwa/images/how-to-install-android.gif">
            </div>
            <div class="device-stripe"></div>
            <div class="device-header"></div>
            <div class="device-sensors"></div>
            <div class="device-btns"></div>
            <div class="device-power"></div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="Polaris-EmptyState__Actions">
    <div class="Polaris-Stack Polaris-Stack--alignmentCenter group-buttons">

      <div class="Polaris-Stack__Item">
        <a [routerLink]="['/pwa/onboarding/quick-config']" class="Polaris-Button Polaris-Button--plain"
          data-polaris-unstyled="true">
          <span class="Polaris-Button__Content">
            <span class="Polaris-Button__Text">Back</span>
          </span>
        </a>
      </div>

      <div class="Polaris-Stack__Item">
        <a [routerLink]="['/pwa/onboarding/finish']"
          class="Polaris-Button Polaris-Button--primary Polaris-Button--sizeLarge">
          <span class="Polaris-Button__Content">
            <span class="Polaris-Button__Text">
              Continue
            </span>
          </span>
        </a>
      </div>

    </div>

  </div>
</div>