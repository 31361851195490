import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'am-pwa-layout-header',
  templateUrl: './pwa-layout-header.component.html',
  styleUrls: ['./pwa-layout-header.component.scss']
})
export class PwaLayoutHeaderComponent implements OnInit {

  @Input()
  user: any;

  @Output() sidebarExpandedChanged = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  openSidebar(): void {
    this.sidebarExpandedChanged.emit(true);
  }


}
