import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { PwaConfiguration, PwaCaching, PwaOfflinePage, PwaConnectionStatusBanner } from './pwa-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class PwaConfigurationService {

  basePWAUrl = environment.basePWAUrl;

  constructor(
    private http: HttpClient
  ) { }

  getPwaConfiguration(): Observable<PwaConfiguration> {
    return this.http.get<PwaConfiguration>(`${this.basePWAUrl}/api/v2/settings/configuration`);
  }

  updatePwaConfiguration(config: PwaConfiguration): Observable<any> {
    return this.http.put<any>(`${this.basePWAUrl}/api/v2/settings/configuration`, config);
  }

  getPwaCaching(): Observable<PwaCaching> {
    return this.http.get<PwaCaching>(`${this.basePWAUrl}/api/v2/caching/caching`);
  }

  updatePwaCaching(config: PwaCaching): Observable<any> {
    return this.http.put<any>(`${this.basePWAUrl}/api/v2/caching/caching`, config);
  }

  getPwaOfflinePage(): Observable<PwaOfflinePage> {
    return this.http.get<PwaOfflinePage>(`${this.basePWAUrl}/api/v2/caching/offline-page`);
  }

  updatePwaOfflinePage(config: PwaOfflinePage): Observable<any> {
    return this.http.put<any>(`${this.basePWAUrl}/api/v2/caching/offline-page`, config);
  }

  getPwaConnectionStatusBanner(): Observable<PwaConnectionStatusBanner> {
    return this.http.get<PwaConnectionStatusBanner>(`${this.basePWAUrl}/api/v2/settings/connection-banner`);
  }

  updatePwaConnectionStatusBanner(config: PwaConnectionStatusBanner): Observable<any> {
    return this.http.put<any>(`${this.basePWAUrl}/api/v2/settings/connection-banner`, config);
  }


  getPreCacheFilesFromPage(link: string): Observable<Array<string>> {
    return this.http.post<any>(`${this.basePWAUrl}/api/v2/caching/caching/page/get-files`, {
      link: link
    });
  }

  getShopCurrency(): Observable<any> {
    return this.http.get<any>(`${this.basePWAUrl}/api/v2/analytics/shop-currency`);
  }

  getPreCaching(): Observable<any> {
    return this.http.get<any>(`${this.basePWAUrl}/api/v2/settings/precache-files`);
  }

  updatePreCaching(config): Observable<any> {
    return this.http.put<any>(`${this.basePWAUrl}/api/v2/settings/precache-files`, config);
  }

  getAppBlockStatus(domain): Observable<any> {
    return this.http.get<any>(`${this.basePWAUrl}/api/events/themes-embed-block/is-app-block-disabled/${domain}`);
  }

  updateLoaderConfiguration(config): Observable<any> {
    return this.http.put<any>(`${this.basePWAUrl}/api/v2/settings/loader-configuration`, config);
  }


  updatePwaRedirectConfiguration(config): Observable<any> {
    return this.http.put<any>(`${this.basePWAUrl}/api/v2/settings/redirect-configuration`, config);
  }

  updateRefreshConfiguration(config): Observable<any> {
    return this.http.put<any>(`${this.basePWAUrl}/api/v2/settings/refresh-configuration`, config);
  }
  //updaet card config flag
  updaetCardConfigurationFlag(config): Observable<any> {
    return this.http.put<any>(`${this.basePWAUrl}/api/v2/settings/card-configuration`, config);
  }
}
