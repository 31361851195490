import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { CookieService } from 'ngx-cookie-service';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'am-pwa-image-picker',
  templateUrl: './pwa-image-picker.component.html',
  styleUrls: ['./pwa-image-picker.component.scss']
})
export class PwaImagePickerComponent implements OnInit {

  baseUrl = environment.basePWAUrl;

  @Input()
  imageUrl: string;

  @Output() valueUpdated = new EventEmitter<any>();

  percentageUpload = '0%';

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) { }

  ngOnInit() { }

  onFileChanged(event) {
    const file = event.target.files[0];

    let headers = new HttpHeaders();
    const auth = this.cookieService.get('ampify-x-access-token');
    headers = headers.set('x-access-token', auth);

    this.http.post(this.baseUrl + '/api/v2/helpers/upload', {
      'Content-Type': file.type
    }, {
      headers: headers
    })
      .subscribe((uploadReponse: any) => {
        // Upload to S3 now
        let s3headers = new HttpHeaders();
        s3headers = s3headers.set('Content-Type', file.type);
        s3headers = s3headers.set('Expires', 'Fri, 1 Jan 2018 23:59:59 GMT');
        this.http.put(uploadReponse.uploadUrl, file, {
          headers: s3headers,
          reportProgress: true,
          observe: 'events'
        })
          .subscribe((response: any) => {
            if (response) {
              if (response.type === 1) {
                this.percentageUpload = Math.round((response.loaded * 100) / event.total).toString() + '%';
              }
              if (response.type === 4) {
                // All done
                this.imageUrl = uploadReponse.url;

                this.valueUpdated.emit(this.imageUrl);

                this.percentageUpload = '100%';
              }
            }
          }, (err) => {
            //console.log(err);
          });

      }, (err) => {
        //console.log(err);
      });
  }

  removeImage(): void {
    this.imageUrl = '';
    this.valueUpdated.emit(this.imageUrl);
    this.percentageUpload = '0%';
  }

  getImageUrl(image: string): string {
    if (!image) {
      return '';
    }

    if (image.indexOf('s3.amazonaws.com') > -1) {
      return image;
    }
    if (environment.production) {
      return 'https://s3.amazonaws.com/pwa-cdn.ampifyme.com/' + image;
    } else {
      return 'https://s3.amazonaws.com/dev-pwa-cdn.ampifyme.com/' + image;
    }
  }
}
