<div class="Polaris-Frame Polaris-Frame--hasNav Polaris-Frame--hasTopBar" data-polaris-layer="true"
  data-has-navigation="true">

  <am-pwa-layout-header [user]="user" (sidebarExpandedChanged)="sidebarExpandedChanged($event)"
    class="Polaris-Frame__TopBar" data-polaris-layer="true" data-polaris-top-bar="true" id="AppFrameTopBar">
  </am-pwa-layout-header>

  <am-pwa-layout-left-sidebar [sidebar]="sidebar"> </am-pwa-layout-left-sidebar>

  <div class="Polaris-Frame__ContextualSaveBar Polaris-Frame-CSSAnimation--startFade"></div>
  <main class="Polaris-Frame__Main" id="AppFrameMain" data-has-global-ribbon="false">
    
    <div class="Polaris-Frame__Content">
      
      <div class="Polaris-Page main-content" *ngIf="!loading">

        <span class="router-wrapper">
          <router-outlet></router-outlet>
        </span>

        <!-- FOOTER -->
        <am-pwa-layout-footer></am-pwa-layout-footer>

      </div>

      <div class="Polaris-Page main-content" *ngIf="loading">
        <am-loading-skeleton></am-loading-skeleton>
      </div>

    </div>
  </main>
</div>