<div class="top-nav">
    <aside [ngClass]="{'hidden': selectedViewportSize === 'FULL'}">
      <a class="logo" [routerLink]="['/amp/themes']">

        <img src="https://ampifyme.com/public/img/Logo-1200x1200-blue.png" />

        <div class="clearfix"></div>
      </a>

      <span class="theme-name">{{themeName}} - by AmpifyMe</span>

    </aside>
    <nav>
      <div class="nav-section nav-section-buttons left-side">

        <am-polaris-select [items]="pageTypes" [model]="selectedPageType" (valueUpdated)="pageChange($event)">
        </am-polaris-select>

      </div>

      <div id="viewportSelector" class="nav-section center">
        <ul class="segmented te-top-bar__button te-viewport-selector desktop-only">
          <li>
            <button class="ui-button ui-button--transparent ui-button--icon-only " (click)="setViewportSize('SMALL')"
              aria-label="Small screen" [ngClass]="{'is-selected':selectedViewportSize === 'SMALL'}" type="button"
              name="button">
              <svg id="phone" width="100%" height="100%"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path fill="currentColor" d="M3 1h14v18H3V1z"></path>
                  <path
                    d="M17 0c.552 0 1 .447 1 1v18c0 .553-.448 1-1 1H3c-.552 0-1-.447-1-1V1c0-.553.448-1 1-1h14zM4 18h12V2H4v16zM9 6h2c.552 0 1-.447 1-1s-.448-1-1-1H9c-.552 0-1 .447-1 1s.448 1 1 1zm1 8c-.552 0-1 .447-1 1s.448 1 1 1 1-.447 1-1-.448-1-1-1z">
                  </path>
                </svg>
              </svg>
            </button>
          </li>
          <li>
            <button class="ui-button ui-button--transparent ui-button--icon-only" (click)="setViewportSize('LARGE')"
              aria-label="Large screen" type="button" [ngClass]="{'is-selected':selectedViewportSize === 'LARGE'}"
              name="button">
              <svg id="desktop" width="100%" height="100%">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path fill="currentColor" d="M1 1h18v10H1V1z"></path>
                  <path
                    d="M13 14H2v-2h16v2h-5zm-.606 4H7.606c.16-.522.295-1.182.357-2h4.074c.062.818.196 1.478.357 2zM2 10V2h16v8H2zM19 0H1C.448 0 0 .447 0 1v14c0 .553.448 1 1 1h4.95c-.156 1.657-.66 2.293-.658 2.293-.285.286-.37.716-.216 1.09S5.596 20 6 20h8c.39 0 .734-.242.897-.598s.09-.788-.166-1.084c-.004-.007-.52-.64-.68-2.318H19c.552 0 1-.447 1-1V1c0-.553-.448-1-1-1z">
                  </path>
                </svg>
              </svg>
            </button>
          </li>
          <li>
            <button class="ui-button ui-button--transparent ui-button--icon-only" aria-label="Fullscreen"
              (click)="setViewportSize('FULL')" type="button" [ngClass]="{'is-selected':selectedViewportSize === 'FULL'}"
              name="button">
              <svg id="viewport-wide" width="100%" height="100%">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <title>Viewport-Wide</title>
                  <path
                    d="M16.707 6.293l3 3c.39.39.39 1.023 0 1.414l-3 3c-.195.195-.45.293-.707.293s-.512-.098-.707-.293c-.39-.39-.39-1.023 0-1.414L16.586 11H12c-.552 0-1-.447-1-1s.448-1 1-1h4.586l-1.293-1.293c-.39-.39-.39-1.023 0-1.414s1.023-.39 1.414 0zm-12 0c.39.39.39 1.023 0 1.414L3.414 9H8c.552 0 1 .447 1 1s-.448 1-1 1H3.414l1.293 1.293c.39.39.39 1.023 0 1.414-.195.195-.45.293-.707.293s-.512-.098-.707-.293l-3-3c-.39-.39-.39-1.023 0-1.414l3-3c.39-.39 1.023-.39 1.414 0zM19 0c.552 0 1 .447 1 1v2c0 .553-.448 1-1 1s-1-.447-1-1V2H2v2c0 .553-.448 1-1 1s-1-.447-1-1V1c0-.553.448-1 1-1h18zm0 15c.552 0 1 .447 1 1v3c0 .553-.448 1-1 1H1c-.552 0-1-.447-1-1v-3c0-.553.448-1 1-1s1 .447 1 1v2h16v-2c0-.553.448-1 1-1z">
                  </path>
                </svg>
              </svg>
            </button>
          </li>

        </ul>
      </div>

      <div id="actionButtons" class="nav-section1 nav-section-buttons right-side">
        <button style="margin-right: 15px;" class="ui-button ui-button--transparent ui-button--icon-only" title="Preview" aria-label="Preview"
        (click)="navigate()" type="button"
        name="button">
        <a style="color:white">
      <svg style="width: 16px;" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill="#5C5F62" fill-rule="evenodd" d="M19.928 9.629C17.791 4.286 13.681 1.85 9.573 2.064c-4.06.21-7.892 3.002-9.516 7.603L-.061 10l.118.333c1.624 4.601 5.455 7.393 9.516 7.603 4.108.213 8.218-2.222 10.355-7.565l.149-.371-.149-.371zM10 15a5 5 0 100-10 5 5 0 000 10z"/><circle fill="#5C5F62" cx="10" cy="10" r="3"/>
      </svg>
      </a>

       </button>
        <button (click)="refresh()" class="button primary" style="margin-right: 16px;">Import Shopify theme settings</button>
        <a (click)="back()" class="button secondary">Back</a>
        <button (click)="saveConfig()" class="button primary">Save</button>
      </div>
    </nav>
  </div>