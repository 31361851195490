import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { EditorLoadingComponent } from './editor-loading/editor-loading.component';
import { EditorErrorMessageComponent } from './editor-error-message/editor-error-message.component';

import { GetLocalizedNamePipe } from './pipes/get-localized-name.pipe';
import { UpgradeDialogComponent } from './upgrade-dialog/upgrade-dialog.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { LoadingSkeletonComponent } from './loading-skeleton/loading-skeleton.component';
import { ErrorPagePolarisComponent } from './error-page-polaris/error-page-polaris.component';
import { UpgradeModalComponent } from './upgrade-modal/upgrade-modal.component';
import { NotAvailablePleaseUpgradeComponent } from './not-available-please-upgrade/not-available-please-upgrade.component';
import { IdToLabelPipe } from './pipes/id-to-label.pipe';
import { DefaultStateComponent } from './default-state/default-state.component';
import { PwaUpgradeModalComponent } from './pwa-upgrade-modal/pwa-upgrade-modal.component';
import { PwaImagePickerComponent } from './pwa-image-picker/pwa-image-picker.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { TrimmedStringPipe } from './pipes/trimmed-string.pipe';
import { GetPrecentagePipe } from './pipes/get-precentage.pipe';
import { GetCountryPipe } from './pipes/get-country.pipe';
import { FallBackImgDirective } from './directives/fall-back-img.directive';
import { GetShopCurrencyPipe } from './pipes/get-shop-currency.pipe';
import { ReplaceNamePipe } from './pipes/replace-name.pipe';
import { RefreshLoadingComponent } from './refresh-loading/refresh-loading.component';

@NgModule({
    declarations: [
        ErrorPageComponent,
        EditorLoadingComponent,
        EditorErrorMessageComponent,
        UpgradeDialogComponent,
        UpgradeModalComponent,
        GetLocalizedNamePipe,
        LoadingSkeletonComponent,
        ErrorPagePolarisComponent,
        NotAvailablePleaseUpgradeComponent,
        IdToLabelPipe,
        DefaultStateComponent,
        PwaUpgradeModalComponent,
        PwaImagePickerComponent,
        LoadingSpinnerComponent,
        DateRangePickerComponent,
        DateAgoPipe,
        TrimmedStringPipe,
        GetPrecentagePipe,
        GetCountryPipe,
        FallBackImgDirective,
        GetShopCurrencyPipe,
        ReplaceNamePipe,
        RefreshLoadingComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbModule
    ],
    exports: [
        FormsModule,
        NgbModule,
        EditorLoadingComponent,
        EditorErrorMessageComponent,
        UpgradeDialogComponent,
        UpgradeModalComponent,
        PwaImagePickerComponent,
        GetLocalizedNamePipe,
        LoadingSkeletonComponent,
        ErrorPagePolarisComponent,
        NotAvailablePleaseUpgradeComponent,
        IdToLabelPipe,
        LoadingSpinnerComponent,
        DateRangePickerComponent,
        DateAgoPipe,
        TrimmedStringPipe,
        GetPrecentagePipe,
        GetCountryPipe,
        FallBackImgDirective,
        GetShopCurrencyPipe,
        ReplaceNamePipe,
        RefreshLoadingComponent
    ]
})
export class SharedModule { }
