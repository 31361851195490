import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../environments/environment';

import { AuthService } from '../../auth/auth.service';
import { SharedService } from '../shared.service';
import { PwaAccountDetails } from '../../pwa/pwa-settings/pwa-settings.model';
import { PwaSettingsService } from '../../pwa/pwa-settings/pwa-settings.service';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'am-not-available-please-upgrade',
  templateUrl: './not-available-please-upgrade.component.html',
  styleUrls: ['./not-available-please-upgrade.component.scss']
})
export class NotAvailablePleaseUpgradeComponent implements OnInit {

  @Input()
  plan: string = 'company';

  @Input()
  app: string = 'ampifyme';
  accountDetails: any;

  currentPlanName: string = 'free';
  upgradeUrl: string;
  shop : any


  constructor(
    private settingsService: PwaSettingsService,
    private authService: AuthService,
    private sharedService: SharedService,
    private route : Router
  ) { }

  ngOnInit() {

    const user = this.authService.getUser();
    this.shop = user.shopDomain;
    this.currentPlanName = user.planName;
 
    this.upgradeUrl = `${environment.basePWAUrl}/install/upgrade?shop=${this.shop}&tier=`;


    if(this.app == 'progressifyme'){
        this.plan = 'paid'
    }
    // console.log("account");
    this.settingsService.getAccountDetails()
    .subscribe((account: PwaAccountDetails) => {
      // console.log("account",account)
      this.accountDetails = account;

      // this.loading = false;
    }, err => {
      // this.error = true;
      // this.loading = false;
    });
  }

  upgrade(): void {
    // const user = this.authService.getUser();
    // const shop = user.shopDomain;
    // let upgradeUrl = '';
    // if (this.app === 'progressifyme') {
    //   upgradeUrl = `${environment.basePWAUrl}/install/upgrade?shop=${shop}&tier=${this.plan}`;
    // } else {
    //   upgradeUrl = `${environment.baseUrl}/install/upgrade?shop=${shop}&tier=${this.plan}`;
    // }

    // window.location.href = upgradeUrl;
    console.log("this.accountDetails",this.accountDetails)
    this.sharedService.showAllUpgradeOptions({inputDiscount :this.accountDetails?.discount, inputDiscountPercentage :this.accountDetails?.discountPercentage, inputFreeTrialDays : this.accountDetails.freeTrialDays, shopifyPlanName : this.accountDetails.shopifyPlanName  });
  }

  // seeAllPlans(): void {
  //   if (this.app === 'progressifyme') {
  //     // this.sharedService.showAllPwaUpgradeOptions();
  //   this.upgradeUrl = `${environment.basePWAUrl}/install/upgrade?shop=${this.shop}&tier=`;

  //     // this.route.navigate([`${this.upgradeUrl}basic`])
      
      
  //   } 
  //   // else {
  //   //   this.sharedService.showAllUpgradeOptions();
  //   // }
  // }

}
