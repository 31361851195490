import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'am-default-state',
  templateUrl: './default-state.component.html',
  styleUrls: ['./default-state.component.scss']
})
export class DefaultStateComponent implements OnInit {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    const app = this.authService.getApp();

    if (app === 'pwa') {
      this.router.navigate(['/pwa'], {
        replaceUrl: true
      });
    } else if (app === 'amp') {
      this.router.navigate(['/amp'], {
        replaceUrl: true
      });
    } else {
      this.router.navigate(['/amp'], {
        replaceUrl: true
      });
    }
  }

}
