import { Component, OnInit } from '@angular/core';
import { AmBuilderService } from '../../../am-builder.service';
import { Theme, ThemeSchema } from '../../../../am-builder.model';

@Component({
  selector: 'am-theme-settings',
  templateUrl: './theme-settings.component.html',
  styleUrls: ['./theme-settings.component.scss']
})
export class ThemeSettingsComponent implements OnInit {

  themeId: string;
  theme: Theme;
  schema: ThemeSchema;

  showEditComponent = false;
  selectedEditComponent: any;

  constructor(
    private amBuilderService: AmBuilderService,
  ) {
    this.theme = this.amBuilderService.theme;
    this.schema = this.amBuilderService.theme.themeDetails.schema;
    // console.log("schema",this.schema);
  }

  ngOnInit() {
  }

  openSettingsDetails($event: any, settings: any) {
    this.showEditComponent = true;
    this.selectedEditComponent = settings;
  }

  closeEditComponentDetailsPanel($event: any) {
    this.showEditComponent = false;
    this.selectedEditComponent = {};
  }

}
