import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';

import { environment } from '../../environments/environment';
import { Theme, ThemeConfiguration, ThemeDetails, ShopDetails, PAGE_TYPE, EditorDetails, BuilderConfig, ReRenderPageResponse } from '../am-builder.model';

@Injectable()
export class AmBuilderService {

  shopDetails: ShopDetails;
  editorDetails: EditorDetails;

  originalTheme: Theme;
  theme: Theme;

  builderConfig: BuilderConfig;

  themeName = 'debut-11.0.0';

  themeId = '13237';

  baseUrl = environment.baseUrl;

  cssSettings : any = {};
  colorSchemes : any = [];

  constructor(
    private http: HttpClient
  ) {
    this.editorDetails = {
      menus: [],
      pages: {
        blog: '',
        blogPost: '',
        collection: 'all',
        product: ''
      },
      lists: {
        blogs: [],
        collections: [],
        products: []
      }
    };

    this.theme = {
      id: '',
      type: 'debut-11.0.0',
      content: {
        current: {}
      },
      themeDetails: {
        schema: {
          details: {
            name: '',
            theme_name: '',
            theme_author: '',
            theme_documentation_url: '',
            theme_support_url: '',
            theme_version: ''
          },
          items: []
        },
        sections: {}
      },
      sections: {},
      config: {},
      sectionContent: {
        home: [],
        product: [],
        integrations: []
      },
      global_settings: {}
    };
    this.builderConfig = {
      currentPage: PAGE_TYPE.PRODUCT_PAGE,
      basePage : ''
    };
  }

  loadThemeDetails(themeId: string): Observable<[EditorDetails, ThemeConfiguration]> {
    this.themeId = themeId;

    return forkJoin([
      this.getShopDetails(themeId),
      this.getThemeConfiguration(themeId)
    ]);
  }

  getSectionDetails(id: string): any {
    if (this.theme.sections[id]) {
      return this.theme.sections[id];
    }
    return {
      type: id
    };
  }

  getThemeConfiguration(themeId: string): Observable<ThemeConfiguration> {
    return this.http.get<ThemeConfiguration>(`${this.baseUrl}/api/v2/themes/${themeId}`);
  }

  getShopDetails(shopId: string): Observable<EditorDetails> {
    return this.http.get<EditorDetails>(`${this.baseUrl}/api/theme-editor/details`);
  }

  getThemeDetails(themeId: string): Observable<ThemeDetails> {
    return this.http.get<ThemeDetails>(`${this.baseUrl}/api/theme-editor/settings/${themeId}`);
  }

  updateTheme(): Observable<void> {
    const themeObj = {
      config: this.theme.config,
      sections: this.theme.sections,
      global_settings: this.theme.global_settings,
      sectionContent: this.theme.sectionContent
    };

    return this.http.put<void>(`${this.baseUrl}/api/v2/themes/${this.themeId}`,
      themeObj);
  }

  isThemeSupported(themeType: string): boolean {
    // console.log(themeType);
    if (themeType.indexOf('custom_') === 0 || themeType.indexOf('shopify_') === 0 || themeType.indexOf('Dawn') === 0 || themeType.indexOf('dawn') === 0) {
      return true;
    }

    return false;
  }
  isV2ThemeSupported(theme: any,version): boolean {
    // console.log("isV2ThemeSupported",theme);
    if (theme.type.indexOf('shopify_') === 0 || theme.type.indexOf('Dawn') === 0 || theme.type.indexOf('dawn') === 0 || version == 2) {
      return true;
    }

    return false;
  }

  reRenderPage(config: any): Observable<ReRenderPageResponse> {
    // console.log("reRenderPage",config,this.shopDetails)
    let url = '';
    switch (config.pageType) {
      case PAGE_TYPE.HOME_PAGE:
        url = `${this.baseUrl}/ampify/${this.shopDetails.domain}`;
        break;
      case PAGE_TYPE.PRODUCT_PAGE:
        url = `${this.baseUrl}/ampify/${this.shopDetails.domain}/products/${config.currentItemHandle}`;
        break;
      case PAGE_TYPE.COLLECTION:
        url = `${this.baseUrl}/ampify/${this.shopDetails.domain}/collections/${config.currentItemHandle}`;
        break;
      case PAGE_TYPE.COLLECTIONS_LIST:
        url = `${this.baseUrl}/ampify/${this.shopDetails.domain}/collections`;
        break;
      case PAGE_TYPE.BLOG_LIST_PAGE:
        url = `${this.baseUrl}/ampify/${this.shopDetails.domain}/blogs/${config.currentItemHandle}`;
        break;
      case PAGE_TYPE.BLOG_POST_PAGE:
        url = `${this.baseUrl}/ampify/${this.shopDetails.domain}/blogs/${config.currentItemHandle}`;
        break;
      default:
        break;
    }
    url += '/preview?dev=true';
    url += '&theme=' + this.themeId;
    url += '&ref=editor';

    // console.log("reRenderPage",url);
    // console.log("config",url);

    return this.http.post<ReRenderPageResponse>(url, config.configuration);
  }


  getV2ThemeConfiguration(themeId: string,type:any): Observable<ThemeConfiguration> {
    return this.http.get<ThemeConfiguration>(`${this.baseUrl}/api/v3/themes/${themeId}/settings?type=${type}`);
  }
  getV2ThemeConfigurationForTemplate(themeId: string,type:any,templateType:any,apiVersion:any): Observable<ThemeConfiguration> {
    return this.http.get<ThemeConfiguration>(`${this.baseUrl}/api/${apiVersion}/themes/${themeId}/settings?type=${type}&templateType=${templateType}`);
  }

  getV2LatestThemeConfiguration(themeId: string): Observable<ThemeConfiguration> {
    return this.http.put<ThemeConfiguration>(`${this.baseUrl}/api/v3/themes/${themeId}/latest`,{});
  }

  updateDawnTheme(themeId,id,themeObj,apiVersion): Observable<void> {


    return this.http.put<void>(`${this.baseUrl}/api/${apiVersion}/themes/${themeId}/settings/${id}`,
      themeObj);
  }
  updateSettingData(themeId,id,themeObj,apiVersion): Observable<void> {


    return this.http.put<void>(`${this.baseUrl}/api/${apiVersion}/themes/${themeId}/settings/${id}`,
      themeObj);
  }

  reRenderPageV2(config: any): Observable<ReRenderPageResponse> {
    // console.log("reRenderPage",config)
    let url = '';
    switch (config.pageType) {
      case 'index':
        url = `${this.baseUrl}/ampify/${this.shopDetails.domain}/homePage`;
        break;
      case 'product':
        url = `${this.baseUrl}/ampify/${this.shopDetails.domain}/products/${config.currentItemHandle}`;
        break;
      case 'collection':
        url = `${this.baseUrl}/ampify/${this.shopDetails.domain}/collections/${config.currentItemHandle}`;
        break;
      case 'list-collections':
        url = `${this.baseUrl}/ampify/${this.shopDetails.domain}/collectionsList`;
        break;
      case 'blog':
        url = `${this.baseUrl}/ampify/${this.shopDetails.domain}/blogsList`;
        break;
      case 'article':
        url = `${this.baseUrl}/ampify/${this.shopDetails.domain}/articlePage`;
        break;
      default:
        url = `${this.baseUrl}/ampify/${this.shopDetails.domain}/products/${config.currentItemHandle}`;
        break;
    }
    url += '/previewV2?dev=true';
    url += '&theme=' + this.themeId;
    url += '&ref=editor';
    url += '&template=' + config.template;
    url += '&pageType=' + config.pageType;



    // console.log("reRenderPage",url);
    // console.log("config",url);

    return this.http.post<ReRenderPageResponse>(url, config.configuration);
  }

  getPreviewPageHandles(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/api/v3/themes/getPageHandles`);
  }
  getV2ThemeAnalytics(themeId: string): Observable<ThemeConfiguration> {
    return this.http.get<ThemeConfiguration>(`${this.baseUrl}/api/v3/themes/${themeId}/analytics-data`);
  }
  updateV2ThemeAnalytics(themeId: string,themeObj): Observable<ThemeConfiguration> {
    return this.http.put<ThemeConfiguration>(`${this.baseUrl}/api/v3/themes/${themeId}/analytics-data`,themeObj);
  }
  updateV2ThemeCss(themeId: string,themeObj): Observable<ThemeConfiguration> {
    return this.http.put<ThemeConfiguration>(`${this.baseUrl}/api/v3/themes/${themeId}/custom-css-settings`,themeObj);
  }
  getV2ThemeCssSettings(themeId: string): Observable<ThemeConfiguration> {
    return this.http.get<ThemeConfiguration>(`${this.baseUrl}/api/v2/themes/${themeId}/css/custom-settings`);
  }

  getV2StoreConfiguration(): Observable<ThemeConfiguration> {
    return this.http.get<ThemeConfiguration>(`${this.baseUrl}/api/v3/themes/check-access-scopes`);
  }

  getPageTemplates(themeId): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/api/v3/themes/${themeId}/fetch-templates`);
  }

  getThemeVersion(themeId: string): Observable<ThemeConfiguration> {
    return this.http.get<ThemeConfiguration>(`${this.baseUrl}/api/v4/themes/${themeId}/getThemeVersion`);
  }
  getColorSchemesV3(themeId: string): Observable<ThemeConfiguration> {
    return this.http.get<ThemeConfiguration>(`${this.baseUrl}/api/v4/themes/${themeId}/getColorSchemes`);
  }
  deletePages(themeId: string): Observable<ThemeConfiguration> {
    return this.http.get<ThemeConfiguration>(`${this.baseUrl}/api/v4/themes/${themeId}/deletePages`);
  }
}
