import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PwaConfigurationCachingComponent } from './pwa-configuration/pwa-configuration-caching/pwa-configuration-caching.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pwa-dashboard/pwa-dashboard.module').then(m => m.PwaDashboardModule)
  },
  {
    path: 'configuration',
    loadChildren: () => import('./pwa-configuration/pwa-configuration.module').then(m => m.PwaConfigurationModule)
  },
  {
    path: 'install-popups',
    loadChildren: () => import('./pwa-install-popups/pwa-install-popups.module').then(m => m.PwaInstallPopupsModule)
  },
  {
    path: 'analytics',
    loadChildren: () => import('./pwa-analytics/pwa-analytics.module').then(m => m.PwaAnalyticsModule)
  },
  {
    path: 'integrations',
    loadChildren: () => import('./pwa-integrations/pwa-integrations.module').then(m => m.PwaIntegrationsModule)
  },
  {
    path: 'push-notifications',
    loadChildren: () => import('./pwa-push-notifications/pwa-push-notifications.module').then(m => m.PwaPushNotificationsModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./pwa-support/pwa-support.module').then(m => m.PwaSupportModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pwa-settings/pwa-settings.module').then(m => m.PwaSettingsModule)
  },
  {
    path: 'how-to-install',
    loadChildren: () => import('./pwa-how-to-install/pwa-how-to-install.module').then(m => m.PwaHowToInstallModule)
  },
  {
    path: '**',
    redirectTo: 'pwa'
  },
  {
    path: 'caching',
    component: PwaConfigurationCachingComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PWARoutingModule { }
