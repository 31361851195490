export interface IFrameMessage {
    type: string;
    shopDomain: string;
    pageType: string;
    currentItemHandle: string;
    configuration: any;
}

export enum PAGE_TYPE {
    HOME_PAGE = 'HOME_PAGE',
    PRODUCT_PAGE = 'PRODUCT_PAGE',
    COLLECTION = 'COLLECTION',
    COLLECTIONS_LIST = 'COLLECTIONS_LIST',
    BLOG_LIST_PAGE = 'BLOG_LIST_PAGE',
    BLOG_POST_PAGE = 'BLOG_POST_PAGE',
    STORY = 'STORY'
}
export enum RESOURCE_TYPE {
    PRODUCT = 'PRODUCT',
    COLLECTION = 'COLLECTION',
    BLOG = 'BLOG'
}

export interface ReRenderPageResponse {
    html: string;
    style: string;
}


export interface ShopDetails {
    domain: string;
    planName: string;
}

export interface ShopDefaultPages {
    collection: string;
    blog: string;
    blogPost: string;
    product: string;
    page?: string;
}

export interface ShopDefaultItemContent {
    handle: string;
    title: string;
    image: string;
}

export interface ShopDefaultItems {
    blogs: Array<ShopDefaultItemContent>;
    collections: Array<ShopDefaultItemContent>;
    products: Array<ShopDefaultItemContent>;
}

export interface MenuItem {
    handle: string;
    title: string;
}

export interface EditorDetails {
    menus: Array<MenuItem>;
    pages: ShopDefaultPages;
    lists: ShopDefaultItems;
}

export interface BuilderConfig {
    currentPage: any;
    basePage : any
}

export interface PageType {
    label: string;
    value: PAGE_TYPE;
}

export interface LocalizedField {
    en: string;
}


export interface SectionSetting {
    id: string;
    label: LocalizedField;
    type: string;
    content?: LocalizedField;
    default?: string;
    placeholder?: string;
    info?: string;
}

export interface Section {
    header: any;
    settings: Array<SectionSetting>;
}

export interface EditComponentDetails {
    id: string;
    name: LocalizedField;
    sections: Array<Section>;
    settings: Array<SectionSetting>;
    max_blocks?: number;
    blocks?: Array<any>;
}



export interface ThemeSchemaDetails {
    name: string;
    theme_name: string;
    theme_author: string;
    theme_version: string;
    theme_documentation_url: string;
    theme_support_url: string;
}

export interface ThemeSchemaItem {
    name: LocalizedField;
    settings: Array<SectionSetting>;
}

export interface ThemeSchema {
    details: ThemeSchemaDetails;
    items: Array<ThemeSchemaItem>;
}

export interface ThemeDetails {
    schema: ThemeSchema;
    sections: any;
}

export interface ThemeSectionContent {
    product: Array<any>;
    home: Array<any>;
    integrations: Array<any>;
    story?: Array<any>;
}

export interface Theme {
    id: string;
    type: string;
    content: any;
    sections: any;
    config: any;
    global_settings: any;

    themeDetails: ThemeDetails;
    sectionContent: ThemeSectionContent;
}


/** Theme configuration details */
export interface ThemeConfigurationContent {
    home: Array<string>;
    product: Array<string>;
    integrations: Array<string>;
    story?: Array<string>;
}

export interface ThemeConfiguration {
    id: string;
    theme_type: string;
    sections: any;
    global_settings: any;
    content: ThemeConfigurationContent;
}
