import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'am-editor-loading',
  templateUrl: './editor-loading.component.html',
  styleUrls: ['./editor-loading.component.scss']
})
export class EditorLoadingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
