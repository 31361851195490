import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'am-pwa-layout-left-sidebar',
  templateUrl: './pwa-layout-left-sidebar.component.html',
  styleUrls: ['./pwa-layout-left-sidebar.component.scss']
})
export class PwaLayoutLeftSidebarComponent implements OnInit {

  @Input()
  sidebar: any;

  user : any = {};

  constructor(
    public authService : AuthService
  ) { }

  ngOnInit() {

    // this.user = this.authService.getUser();
    this.authService.getUserDetails('pwa')
      .subscribe(res => {
        this.user = res;
    console.log(this.user)
      })

  }

  closeSidebar(): void {
    this.sidebar.expanded = false;
  }

}
