<div class="theme-editor-action-list-item component-item component-item-sortable">
  <div class="theme-editor-action-list-item-content" (click)="toggleDetails()">
    <div class="theme-editor-action-list-item-icon">

      <svg [ngClass]="{'rotate': detailsExpanded}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path
          d="M6.28 9.28l3.366 3.366c.196.196.512.196.708 0L13.72 9.28c.293-.293.293-.767 0-1.06-.14-.14-.332-.22-.53-.22H6.81c-.414 0-.75.336-.75.75 0 .2.08.39.22.53z">
        </path>
      </svg>

    </div>
    <div class="theme-editor-action-list-item-text">
      {{block.name | getLocalizedName}}
    </div>

    <!-- USED FOR DRAG AND DROP HANDLE -->
    <span (click)="closeDetails()">
      <ng-content></ng-content>
    </span>
    <!-- USED FOR DRAG AND DROP HANDLE -->

  </div>
</div>

<div *ngIf="detailsExpanded" class="content-editor">
  <ul class="no-list-style">
    <li *ngFor="let item of block.settings">

      <am-edit-field [component]="component" [item]="item" [values]="editBlockData"></am-edit-field>

    </li>
  </ul>

  <div *ngIf="block.type === 'layer'">
    This is layer element, show add more elements to this layer
  </div>


  <button (click)="removeBlock()" class="btn link remove-content">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        d="M16 6a1 1 0 1 1 0 2h-1v9a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V8H4a1 1 0 1 1 0-2h12zM9 4a1 1 0 1 1 0-2h2a1 1 0 1 1 0 2H9zm2 12h2V8h-2v8zm-4 0h2V8H7v8z">
      </path>
    </svg>
    Remove Content
  </button>
</div>