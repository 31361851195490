<div *ngIf="!showItems">
  <div *ngIf="!values[item.id]" class="preview ui-card">
    <div class="ui-card-section text-center">
      <button class="button" (click)="showItemsPicker()">
        {{sectionTitle}}
      </button>
    </div>
  </div>

  <div *ngIf="values[item.id]" class="preview ui-card">
    <div class="ui-card-section">
      <p>{{getItemName(values[item.id])}}</p>
    </div>

    <span class="button-group two">
      <button (click)="showItemsPicker()" class="button secondary first-button">Change</button>
      <button (click)="removeSelectedItem()" class="button secondary">Remove</button>
    </span>
  </div>
</div>

<div class="pick-items-preview" *ngIf="showItems">
  <div class="header">
    <h2>{{sectionTitle}}</h2>
    <div class="close" (click)="closeShowItemsPicker()">
      <i class="icon-close"></i>
    </div>
  </div>
  <ul class="theme-editor-action-list" id="addNewSnippetComponents">
    <li *ngFor="let item of items" class="theme-editor-action-list-item component-item component-item-sortable"
      (click)="selectResource(item.handle)">
      <span>{{item.title}}</span>
    </li>
  </ul>
</div>