import { Component, OnInit, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'am-refresh-confirmation',
  templateUrl: './refresh-confirmation.component.html',
  styleUrls: ['./refresh-confirmation.component.scss']
})
export class RefreshConfirmationComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,

  ) { }

  ngOnInit(): void {
  }

  cancel(): void {
    this.activeModal.close(false);
  }

  save(){

    this.activeModal.close(true);


  }

}
