<div>
  <button type="button" class="Polaris-Button" (click)="toggleActionListItems()">
    <span class="Polaris-Button__Content">
      <span>More actions</span>
    </span>
  </button>

  <div *ngIf="showActionListItems" class="Polaris-PositionedOverlay">
    <div class="Polaris-Popover" data-polaris-overlay="true">
      <div class="Polaris-Popover__FocusTracker" tabindex="0"></div>
      <div class="Polaris-Popover__Wrapper">
        <div id="Popover8" tabindex="-1" class="Polaris-Popover__Content">
          <div class="Polaris-Popover__Pane Polaris-Scrollable Polaris-Scrollable--vertical">
            <div class="Polaris-ActionList">
              <div class="Polaris-ActionList__Section--withoutTitle">
                <ul class="Polaris-ActionList__Actions">
                  <li>
                    <button class="Polaris-ActionList__Item" type="button">
                      <div class="Polaris-ActionList__Content">Import file</div>
                    </button>
                  </li>
                  <li>
                    <button class="Polaris-ActionList__Item" type="button">
                      <div class="Polaris-ActionList__Content">Export file</div>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="Polaris-Popover__FocusTracker" tabindex="0"></div>
    </div>
  </div>
</div>