<div [ngClass]="{'edit-component-visible': showEditComponent}">
  <div class="stack-vertical">

    <!-- Theme editor stuff for ampify me -->
    <div *ngIf="builderConfig.currentPage !== 'STORY'" class="section-list themes">

      <!-- Header -->
      <ul class="theme-editor-action-list">
        <am-builder-component-list-item draggable="false" (componentSelected)="componentSelected($event)" type="header"
          id="header"></am-builder-component-list-item>
      </ul>
      <!-- Header -->

      <!-- Announcement -->
      <ul *ngIf="sectionExists('announcement-bar')" class="theme-editor-action-list">
        <am-builder-component-list-item draggable="false" (componentSelected)="componentSelected($event)"
          type="announcement-bar" id="announcement-bar"></am-builder-component-list-item>
      </ul>
      <!-- Announcement -->

      <!-- Sidebar -->
      <ul *ngIf="sectionExists('sidebar')" class="theme-editor-action-list">
        <am-builder-component-list-item draggable="false" (componentSelected)="componentSelected($event)" type="sidebar"
          id="sidebar"></am-builder-component-list-item>
      </ul>
      <!-- Sidebar -->

      <!-- Action bar -->
      <ul *ngIf="sectionExists('action-bar')" class="theme-editor-action-list">
        <am-builder-component-list-item draggable="false" (componentSelected)="componentSelected($event)"
          type="action-bar" id="action-bar"></am-builder-component-list-item>
      </ul>
      <!-- Action bar -->

      <!-- Content -->
      <ul id="sectionList" class="theme-editor-action-list">

        <div *ngIf="builderConfig.currentPage === 'HOME_PAGE'">

          <ul class="home-page-sections" cdkDropList (cdkDropListDropped)="dropSectionItem($event, 'home')"
            [cdkDropListData]="sectionsForHome" class="plain-list">

            <li *ngFor="let section of sectionsForHome" class="plain-list" cdkDrag cdkDragBoundary=".home-page-sections"
              [cdkDragData]="section.id" cdkDragLockAxis="y">

              <am-builder-component-list-item (componentSelected)="componentSelected($event)" [type]="section.type"
                [id]="section.id" draggable="true" showRemoveSection="true">

                <div cdkDragHandle class="theme-editor-action-list-item-dragable">
                  <i class="icon-drag-handle"></i>
                </div>

              </am-builder-component-list-item>

            </li>
          </ul>

          <!-- ADD SECTION BUTTON -->
          <button (click)="addNewComponent('home')" class="button link add-content" type="button" name="button">
            <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <title>add-section</title>
                <path
                  d="M2 0H1C.448 0 0 .448 0 1v1c0 .552.448 1 1 1s1-.448 1-1c.552 0 1-.448 1-1s-.448-1-1-1zm4 2h2c.552 0 1-.448 1-1s-.448-1-1-1H6c-.552 0-1 .448-1 1s.448 1 1 1zm8-2h-2c-.552 0-1 .448-1 1s.448 1 1 1h2c.552 0 1-.448 1-1s-.448-1-1-1zM8 18H6c-.552 0-1 .448-1 1s.448 1 1 1h2c.552 0 1-.448 1-1s-.448-1-1-1zm6 0h-2c-.552 0-1 .448-1 1s.448 1 1 1h2c.552 0 1-.448 1-1s-.448-1-1-1zm5-18h-1c-.552 0-1 .448-1 1s.448 1 1 1c0 .552.448 1 1 1s1-.448 1-1V1c0-.552-.448-1-1-1zm0 17c-.552 0-1 .448-1 1-.552 0-1 .448-1 1s.448 1 1 1h1c.552 0 1-.448 1-1v-1c0-.552-.448-1-1-1zM2 18c0-.552-.448-1-1-1s-1 .448-1 1v1c0 .552.448 1 1 1h1c.552 0 1-.448 1-1s-.448-1-1-1zm-1-3c.552 0 1-.448 1-1v-2c0-.552-.448-1-1-1s-1 .448-1 1v2c0 .552.448 1 1 1zm0-6c.552 0 1-.448 1-1V6c0-.552-.448-1-1-1s-1 .448-1 1v2c0 .552.448 1 1 1zm18 2c-.552 0-1 .448-1 1v2c0 .552.448 1 1 1s1-.448 1-1v-2c0-.552-.448-1-1-1zm0-6c-.552 0-1 .448-1 1v2c0 .552.448 1 1 1s1-.448 1-1V6c0-.552-.448-1-1-1zm-5 4h-3V6c0-.552-.448-1-1-1s-1 .448-1 1v3H6c-.552 0-1 .448-1 1s.448 1 1 1h3v3c0 .552.448 1 1 1s1-.448 1-1v-3h3c.552 0 1-.448 1-1s-.448-1-1-1z">
                </path>
              </svg>
            </div>
            <div class="label">Add section</div>
          </button>

        </div>

        <div *ngIf="builderConfig.currentPage === 'PRODUCT_PAGE'">
          <am-builder-component-list-item draggable="false" (componentSelected)="componentSelected($event)"
            type="product-template" id="product-template"></am-builder-component-list-item>

          <!--SMALL DIVIDER??-->

          <ul class="home-page-sections" cdkDropList (cdkDropListDropped)="dropSectionItem($event, 'product')"
            [cdkDropListData]="sectionsForProduct" class="plain-list">

            <li *ngFor="let section of sectionsForProduct" class="plain-list" cdkDrag
              cdkDragBoundary=".home-page-sections" [cdkDragData]="section.id" cdkDragLockAxis="y">

              <am-builder-component-list-item (componentSelected)="componentSelected($event)" [type]="section.type"
                [id]="section.id" draggable="true" showRemoveSection="true">

                <div cdkDragHandle class="theme-editor-action-list-item-dragable">
                  <i class="icon-drag-handle"></i>
                </div>

              </am-builder-component-list-item>

            </li>
          </ul>

          <!-- ADD SECTION BUTTON -->
          <button (click)="addNewComponent('product')" class="button link add-content" type="button" name="button">
            <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <title>add-section</title>
                <path
                  d="M2 0H1C.448 0 0 .448 0 1v1c0 .552.448 1 1 1s1-.448 1-1c.552 0 1-.448 1-1s-.448-1-1-1zm4 2h2c.552 0 1-.448 1-1s-.448-1-1-1H6c-.552 0-1 .448-1 1s.448 1 1 1zm8-2h-2c-.552 0-1 .448-1 1s.448 1 1 1h2c.552 0 1-.448 1-1s-.448-1-1-1zM8 18H6c-.552 0-1 .448-1 1s.448 1 1 1h2c.552 0 1-.448 1-1s-.448-1-1-1zm6 0h-2c-.552 0-1 .448-1 1s.448 1 1 1h2c.552 0 1-.448 1-1s-.448-1-1-1zm5-18h-1c-.552 0-1 .448-1 1s.448 1 1 1c0 .552.448 1 1 1s1-.448 1-1V1c0-.552-.448-1-1-1zm0 17c-.552 0-1 .448-1 1-.552 0-1 .448-1 1s.448 1 1 1h1c.552 0 1-.448 1-1v-1c0-.552-.448-1-1-1zM2 18c0-.552-.448-1-1-1s-1 .448-1 1v1c0 .552.448 1 1 1h1c.552 0 1-.448 1-1s-.448-1-1-1zm-1-3c.552 0 1-.448 1-1v-2c0-.552-.448-1-1-1s-1 .448-1 1v2c0 .552.448 1 1 1zm0-6c.552 0 1-.448 1-1V6c0-.552-.448-1-1-1s-1 .448-1 1v2c0 .552.448 1 1 1zm18 2c-.552 0-1 .448-1 1v2c0 .552.448 1 1 1s1-.448 1-1v-2c0-.552-.448-1-1-1zm0-6c-.552 0-1 .448-1 1v2c0 .552.448 1 1 1s1-.448 1-1V6c0-.552-.448-1-1-1zm-5 4h-3V6c0-.552-.448-1-1-1s-1 .448-1 1v3H6c-.552 0-1 .448-1 1s.448 1 1 1h3v3c0 .552.448 1 1 1s1-.448 1-1v-3h3c.552 0 1-.448 1-1s-.448-1-1-1z">
                </path>
              </svg>
            </div>
            <div class="label">Add section</div>
          </button>

        </div>


        <div *ngIf="builderConfig.currentPage === 'COLLECTION'">
          <am-builder-component-list-item draggable="false" (componentSelected)="componentSelected($event)"
            type="collection-template" id="collection-template"></am-builder-component-list-item>
        </div>

        <div *ngIf="builderConfig.currentPage === 'COLLECTIONS_LIST'">
          <am-builder-component-list-item draggable="false" (componentSelected)="componentSelected($event)"
            type="collection-list" id="collection-list"></am-builder-component-list-item>
        </div>


        <div *ngIf="builderConfig.currentPage === 'BLOG_LIST_PAGE'">
          <am-builder-component-list-item draggable="false" (componentSelected)="componentSelected($event)" type="blog"
            id="blog"></am-builder-component-list-item>
        </div>

        <div *ngIf="builderConfig.currentPage === 'BLOG_POST_PAGE'">
          <am-builder-component-list-item draggable="false" (componentSelected)="componentSelected($event)"
            type="article" id="article"></am-builder-component-list-item>
        </div>

        <!-- Add section button -->
        <!--<am-builder-component-list-item (click)="addNewComponent()" type="ADD_SECTION_ITEM"></am-builder-component-list-item>-->
        <!-- Add section button -->
      </ul>
      <!-- Content -->

      <!-- Footer -->
      <ul class="theme-editor-action-list">
        <am-builder-component-list-item draggable="false" (componentSelected)="componentSelected($event)" type="footer"
          id="footer"></am-builder-component-list-item>
      </ul>
      <!-- Footer -->


      <!-- Integrations -->
      <!--<ul id="sectionList" class="theme-editor-action-list">

          <ul class="integrations-page-sections" cdkDropList (cdkDropListDropped)="dropSectionItem($event, 'integration')" [cdkDropListData]="sectionsForIntegrations"
            class="plain-list">

            <li *ngFor="let section of sectionsForIntegrations" class="plain-list" cdkDrag cdkDragBoundary=".integrations-page-sections"
              [cdkDragData]="section.id" cdkDragLockAxis="y">

              <am-builder-component-list-item (componentSelected)="componentSelected($event)" [type]="section.type"
                [id]="section.id" draggable="true" showRemoveSection="true">

                <div cdkDragHandle class="theme-editor-action-list-item-dragable">
                  <i class="icon-drag-handle"></i>
                </div>

              </am-builder-component-list-item>

            </li>
          </ul>

          <button (click)="addNewComponent('integration')" class="button link add-content" type="button" name="button">
            <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <title>add-section</title>
                <path d="M2 0H1C.448 0 0 .448 0 1v1c0 .552.448 1 1 1s1-.448 1-1c.552 0 1-.448 1-1s-.448-1-1-1zm4 2h2c.552 0 1-.448 1-1s-.448-1-1-1H6c-.552 0-1 .448-1 1s.448 1 1 1zm8-2h-2c-.552 0-1 .448-1 1s.448 1 1 1h2c.552 0 1-.448 1-1s-.448-1-1-1zM8 18H6c-.552 0-1 .448-1 1s.448 1 1 1h2c.552 0 1-.448 1-1s-.448-1-1-1zm6 0h-2c-.552 0-1 .448-1 1s.448 1 1 1h2c.552 0 1-.448 1-1s-.448-1-1-1zm5-18h-1c-.552 0-1 .448-1 1s.448 1 1 1c0 .552.448 1 1 1s1-.448 1-1V1c0-.552-.448-1-1-1zm0 17c-.552 0-1 .448-1 1-.552 0-1 .448-1 1s.448 1 1 1h1c.552 0 1-.448 1-1v-1c0-.552-.448-1-1-1zM2 18c0-.552-.448-1-1-1s-1 .448-1 1v1c0 .552.448 1 1 1h1c.552 0 1-.448 1-1s-.448-1-1-1zm-1-3c.552 0 1-.448 1-1v-2c0-.552-.448-1-1-1s-1 .448-1 1v2c0 .552.448 1 1 1zm0-6c.552 0 1-.448 1-1V6c0-.552-.448-1-1-1s-1 .448-1 1v2c0 .552.448 1 1 1zm18 2c-.552 0-1 .448-1 1v2c0 .552.448 1 1 1s1-.448 1-1v-2c0-.552-.448-1-1-1zm0-6c-.552 0-1 .448-1 1v2c0 .552.448 1 1 1s1-.448 1-1V6c0-.552-.448-1-1-1zm-5 4h-3V6c0-.552-.448-1-1-1s-1 .448-1 1v3H6c-.552 0-1 .448-1 1s.448 1 1 1h3v3c0 .552.448 1 1 1s1-.448 1-1v-3h3c.552 0 1-.448 1-1s-.448-1-1-1z"></path>
              </svg>
            </div>
            <div class="label">Add integration</div>
          </button>
      </ul>-->

    </div>
  </div>

  <!-- Story editor stuff for stories by ampify me -->
  <div *ngIf="builderConfig.currentPage === 'STORY'" class="section-list themes">
    <!-- Content -->
    <ul id="sectionList" class="theme-editor-action-list">

      <ul class="story-page-sections" cdkDropList (cdkDropListDropped)="dropSectionItem($event)"
        [cdkDropListData]="sectionsForStory" class="plain-list">

        <li *ngFor="let section of sectionsForStory" class="plain-list" cdkDrag cdkDragBoundary=".story-page-sections"
          [cdkDragData]="section.id" cdkDragLockAxis="y">

          <am-builder-component-list-item (componentSelected)="componentSelected($event)" [type]="section.type"
            [id]="section.id" draggable="true" showRemoveSection="true">

            <div cdkDragHandle class="theme-editor-action-list-item-dragable">
              <i class="icon-drag-handle"></i>
            </div>

          </am-builder-component-list-item>

        </li>
      </ul>

      <!-- ADD SECTION BUTTON -->
      <button (click)="addNewStoryPage('page')" class="button link add-content" type="button" name="button">
        <div class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <title>add-page</title>
            <path
              d="M2 0H1C.448 0 0 .448 0 1v1c0 .552.448 1 1 1s1-.448 1-1c.552 0 1-.448 1-1s-.448-1-1-1zm4 2h2c.552 0 1-.448 1-1s-.448-1-1-1H6c-.552 0-1 .448-1 1s.448 1 1 1zm8-2h-2c-.552 0-1 .448-1 1s.448 1 1 1h2c.552 0 1-.448 1-1s-.448-1-1-1zM8 18H6c-.552 0-1 .448-1 1s.448 1 1 1h2c.552 0 1-.448 1-1s-.448-1-1-1zm6 0h-2c-.552 0-1 .448-1 1s.448 1 1 1h2c.552 0 1-.448 1-1s-.448-1-1-1zm5-18h-1c-.552 0-1 .448-1 1s.448 1 1 1c0 .552.448 1 1 1s1-.448 1-1V1c0-.552-.448-1-1-1zm0 17c-.552 0-1 .448-1 1-.552 0-1 .448-1 1s.448 1 1 1h1c.552 0 1-.448 1-1v-1c0-.552-.448-1-1-1zM2 18c0-.552-.448-1-1-1s-1 .448-1 1v1c0 .552.448 1 1 1h1c.552 0 1-.448 1-1s-.448-1-1-1zm-1-3c.552 0 1-.448 1-1v-2c0-.552-.448-1-1-1s-1 .448-1 1v2c0 .552.448 1 1 1zm0-6c.552 0 1-.448 1-1V6c0-.552-.448-1-1-1s-1 .448-1 1v2c0 .552.448 1 1 1zm18 2c-.552 0-1 .448-1 1v2c0 .552.448 1 1 1s1-.448 1-1v-2c0-.552-.448-1-1-1zm0-6c-.552 0-1 .448-1 1v2c0 .552.448 1 1 1s1-.448 1-1V6c0-.552-.448-1-1-1zm-5 4h-3V6c0-.552-.448-1-1-1s-1 .448-1 1v3H6c-.552 0-1 .448-1 1s.448 1 1 1h3v3c0 .552.448 1 1 1s1-.448 1-1v-3h3c.552 0 1-.448 1-1s-.448-1-1-1z">
            </path>
          </svg>
        </div>
        <div class="label">Add page</div>
      </button>

    </ul>
    <!-- Content -->
  </div>

  <am-edit-component-details type="SECTIONS" *ngIf="showEditComponent" [component]="selectedEditComponent"
    (closeEditComponentDetailsPanel)="closeEditComponentDetailsPanel($event)"
    (sectionRemovedEvent)="sectionRemovedHandler($event)"></am-edit-component-details>

  <am-add-component-list *ngIf="showAddComponent" [pageType]="addComponentPageType"
    (closeAddComponentPanel)="closeAddComponentPanel($event)"></am-add-component-list>
</div>