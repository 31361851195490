import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AmBuilderService } from '../../../am-builder.service';
import { ShopDefaultItemContent, RESOURCE_TYPE } from '../../../../am-builder.model';

@Component({
  selector: 'am-resource-picker-field',
  templateUrl: './resource-picker-field.component.html',
  styleUrls: ['./resource-picker-field.component.scss']
})
export class ResourcePickerFieldComponent implements OnInit {

  @Input()
  type: RESOURCE_TYPE;

  @Input()
  item: any;

  @Input()
  values: any;

  @Output() valueUpdated = new EventEmitter<any>();

  items: Array<ShopDefaultItemContent>;
  showItems = false;
  sectionTitle = 'Select resource';

  constructor(
    private amBuilderService: AmBuilderService
  ) {
  }

  ngOnInit() {
    switch (this.type) {
      case RESOURCE_TYPE.BLOG:
        this.items = this.amBuilderService.editorDetails.lists.blogs;
        this.sectionTitle = 'Select blog';
        break;

      case RESOURCE_TYPE.PRODUCT:
        this.items = this.amBuilderService.editorDetails.lists.products;
        this.sectionTitle = 'Select product';
        break;

      case RESOURCE_TYPE.COLLECTION:
        this.items = this.amBuilderService.editorDetails.lists.collections;
        this.sectionTitle = 'Select collection';
        break;

      default:
        break;
    }
  }

  getItemName(handle: string): string {
    if (!handle) {
      return '';
    }
    return this.items.find(p => p.handle === handle).title || handle;
  }

  valueUpdatedEvent(id, value) {
    this.valueUpdated.emit({
      id: id,
      value: value
    });
  }

  removeSelectedItem() {
    delete this.values[this.item.id];
    this.valueUpdatedEvent(this.item.id, null);
    this.closeShowItemsPicker();
  }

  showItemsPicker() {
    this.showItems = true;
  }
  closeShowItemsPicker() {
    this.showItems = false;
  }

  selectResource(handle: string) {
    this.values[this.item.id] = handle;
    this.valueUpdatedEvent(this.item.id, handle);
    this.closeShowItemsPicker();
  }
}
