import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from './auth/auth-guard.service';
import { AuthRedirectComponent } from './auth/auth-redirect/auth-redirect.component';

import { ErrorPageComponent } from './shared/error-page/error-page.component';
import { DefaultStateComponent } from './shared/default-state/default-state.component';

import { AmpLayoutComponent } from './amp/amp-layout/amp-layout.component';
import { OnboardingLayoutComponent } from './amp/onboarding/onboarding-layout/onboarding-layout.component';

import { PwaLayoutComponent } from './pwa/pwa-layout/pwa-layout.component';
import { PwaOnboardingLayoutComponent } from './pwa/pwa-onboarding/pwa-onboarding-layout/pwa-onboarding-layout.component';

// All base routes
const routes: Routes = [

  // AMP app
  {
    path: 'amp',
    component: AmpLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: '', loadChildren: () => import('./amp/amp.module').then(m => m.AmpModule) }
    ]
  },
  // AMP Onboarding
  {
    path: 'amp/onboarding',
    component: OnboardingLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: '', loadChildren: () => import('./amp/onboarding/onboarding.module').then(m => m.OnboardingModule) }
    ]
  },
  // AMP Editor route
  {
    path: 'amp/editor',
    canActivate: [AuthGuardService],
    children: [
      { path: '', loadChildren: () => import('./amp/editor/editor.module').then(m => m.EditorModule) }
    ]
  },

  // PWA app
  {
    path: 'pwa',
    component: PwaLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: '', loadChildren: () => import('./pwa/pwa.module').then(m => m.PwaModule) }
    ]
  },
  // PWA Onboarding
  {
    path: 'pwa/onboarding',
    component: PwaOnboardingLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: '', loadChildren: () => import('./pwa/pwa-onboarding/pwa-onboarding.module').then(m => m.PwaOnboardingModule) }
    ]
  },
   // AMP Editor route
   {
    path: 'amp/editorv2',
    canActivate: [AuthGuardService],
    children: [
      { path: '', loadChildren: () => import('./amp/editorv2/editorv2.module').then(m => m.EditorV2Module) }
    ]
  },


  // Auth routes without app layout
  { path: 'auth/redirect', component: AuthRedirectComponent },

  // Error route
  { path: 'error', component: ErrorPageComponent },

  // Otherwise redirect to home
  {
    path: '**',
    component: DefaultStateComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes
// , { enableTracing: true }
, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
