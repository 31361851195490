<div *ngIf="version == 3" class="main" cdkDropList (cdkDropListDropped)="drop($any($event))">

    <div class="item-list" cdkDrag *ngFor="let item of headerObj.order; let i = index">

        <am-expandable-block [section]="headerObj" [item]="item" [index]="i" [showIcons]="iconShow" (panelFlagChange)="panelHeaderOpen($event)" (showSectionForm)="showParentForm($event)" (addBlocks)="emitBlock($event)">
        </am-expandable-block>

    </div>
    <div *ngIf="version == 3" class="add-section" [matMenuTriggerFor]="menuMenu">
        <span class="Polaris-Icon">
            <span class="Polaris-VisuallyHidden">
            </span>
            <svg viewBox="0 0 20 20" fill="#2c6ecb" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15 10a1 1 0 0 1-1 1h-3v3a1 1 0 1 1-2 0v-3h-3a1 1 0 1 1 0-2h3v-3a1 1 0 0 1 2 0v3h3a1 1 0 0 1 1 1zm-5-8a8 8 0 1 0 0 16 8 8 0 0 0 0-16z" />
            </svg>
        </span>
        <p class="add-section-title">Add Section</p>

        <mat-menu #menuMenu="matMenu" yPosition="above" xPosition="after">

            <div class="add-section-menu">

                <p class="theme-sections">
                    Theme Section
                </p>

                <div class="theme-section-block" *ngFor="let sc of themeSections"  (click)="addSection(sc.key)">
                    <span class="Polaris-Icon">
                        <span class="Polaris-VisuallyHidden">
                        </span>
                        <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1 2.5v6.5h18v-6.5a1.5 1.5 0 0 0-1.5-1.5h-15a1.5 1.5 0 0 0-1.5 1.5zm1 16.5a1 1 0 0 1-1-1v-2h2v1h1v2h-2zm17-1a1 1 0 0 1-1 1h-2v-2h1v-1h2v2zm-18-4v-3h2v3h-2zm16-3v3h2v-3h-2zm-11 6h3v2h-3v-2zm8 0h-3v2h3v-2z" />
                        </svg>
                    </span>
                    <p class="theme-section-title">{{sc.title}}</p>
                </div>

            </div>
        </mat-menu>
    </div>
</div>
<div *ngIf="version != 3 || version != '3'" class="main">

    <div class="item-list"  *ngFor="let item of headerObj.order; let i = index">

        <am-expandable-block [section]="headerObj" [item]="item" [index]="i" [showIcons]="false" (panelFlagChange)="panelHeaderOpen($event)" (showSectionForm)="showParentForm($event)" (addBlocks)="emitBlock($event)">
        </am-expandable-block>

    </div>

</div>
