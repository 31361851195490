import { Component, Input, OnInit,Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'am-theme-settings-v2',
  templateUrl: './theme-settings-v2.component.html',
  styleUrls: ['./theme-settings-v2.component.scss']
})
export class ThemeSettingsV2Component implements OnInit {

  showThemeSetting = false;
  @Input() globalSetts : any = [];
  @Input() headerName : any = 'Theme Settings';
  @Input() routesData : any = [];
  @Output() showSettingForm = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  showThemeSettings(){

    this.showThemeSetting = true;
   }

   showSettingsForm($event){

     //console.log("showParentForm",$event);
    //  let routeArray = [];
    //  routeArray.push('theme-settings');

     this.routesData.push($event.key);
     $event['headingText'] = $event.name;
     $event['route'] = this.routesData;

    this.showSettingForm.emit($event);
   }
}
