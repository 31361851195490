import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';

import { AmBuilderService } from '../../am-builder.service';

import { environment } from '../../../../environments/environment';
import { IFrameMessage, PAGE_TYPE } from '../../../am-builder.model';
import { SettingsService } from '../../settings/settings.service';
import { AccountDetails } from '../../settings/settings.model';
import { SharedService } from '../../../shared/shared.service';

@Component({
  selector: 'am-builder-preview',
  templateUrl: './builder-preview.component.html',
  styleUrls: ['./builder-preview.component.scss']
})
export class BuilderPreviewComponent implements OnInit, OnDestroy {

  @ViewChild('previewIframe', { static: true }) previewIframe: ElementRef;

  @Input()
  type: string;

  loading: boolean;
  previewUrl: SafeResourceUrl;
  viewportSize = 'small';

  editingAllowedInCurrentTier: boolean = true;
  neededPlanName: string = 'hobbyist';

  sendIframeEvent: any;
  refreshIframeEvent: any;
  changePageTypeEvent: any;
  changeViewportSizeEvent: any;
  changeFieldEvent: any;
  changeConfigurationEvent: any;

  currentItemHandle: string;

  pageType: string;
  shopDomain: string;

  baseUrl = environment.baseUrl;

  accountDetails: AccountDetails;


  constructor(
    private pubsub: NgxPubSubService,
    private amBuilderService: AmBuilderService,
    private sanitizer: DomSanitizer,
    private settingsService: SettingsService,
    private sharedService: SharedService

  ) {
    this.loading = true;
    this.currentItemHandle = '';
  }

  ngOnInit() {

    this.settingsService.getAccountDetails()
    .subscribe((account: AccountDetails) => {
      this.accountDetails = account;

      this.loading = false;
    }, err => {
      // this.error = true;
      this.loading = false;
    });
    this.currentItemHandle = this.amBuilderService.editorDetails.pages.product || 'any';
    this.shopDomain = this.amBuilderService.shopDetails.domain;

    this.pageType = PAGE_TYPE.PRODUCT_PAGE;
    // this.pageType = PAGE_TYPE.HOME_PAGE;

    this.listenForIframeMessage();

    // Send iframe message event
    this.sendIframeEvent = this.pubsub.subscribe('SEND_IFRAME_EVENT', (messageObj: any) => {
      const message = {
        type: messageObj.type,
        shopDomain: this.shopDomain,
        pageType: this.pageType,
        currentItemHandle: this.currentItemHandle,
        configuration: messageObj
      };
      message.type = messageObj.type;
      message.configuration = messageObj.configuration;
      // console.log("SEND_IFRAME_EVENT",message);
      const jsonMessage = JSON.stringify(message);
      this.sendIframeMessage(jsonMessage);
    });

    // Event for refreshing the iframe
    this.refreshIframeEvent = this.pubsub.subscribe('REFRESH_IFRAME_EVENT', (newValue) => {
      this.changePageType(this.pageType);
    });

    // Event for changing the page type
    this.changePageTypeEvent = this.pubsub.subscribe('PAGE_TYPE_CHANGE', (newValue) => {
      this.changePageType(newValue);
    });

    // Event for changing the preview size
    this.changeViewportSizeEvent = this.pubsub.subscribe('VIEWPORT_CHANGE', (newValue) => {
      this.setViewportSize(newValue);
    });

    // Event for changing of the configuration needed for re-rendering
    this.changeConfigurationEvent = this.pubsub.subscribe('CONFIGURATION_CHANGE', (newConfiguration: any) => {

      // Set content for home in payload
      newConfiguration.sectionContent = this.amBuilderService.theme.sectionContent;

      const message: IFrameMessage = {
        type: 'RE_RENDER_PAGE',
        shopDomain: this.shopDomain,
        pageType: this.pageType,
        currentItemHandle: this.currentItemHandle,
        configuration: newConfiguration
      };

      this.loading = true;

      // Make ajax call to get new html
      this.amBuilderService.reRenderPage(message)
        .subscribe(res => {
          // Send the new html to the iframe
          message.configuration = res;
          this.updateIframeContent(message);

          this.loading = false;
        }, err => {
          console.log(err);
          this.loading = false;
        });
    });

    // Event for changing of the configuration needed for re-rendering
    this.changeFieldEvent = this.pubsub.subscribe('FIELD_CHANGE', (value: any) => {
      const message: IFrameMessage = {
        type: 'UPDATE_FILED',
        shopDomain: this.shopDomain,
        pageType: this.pageType,
        currentItemHandle: this.currentItemHandle,
        configuration: value
      };

      this.updateIframeContent(message);
    });

    // Initial value for iframe
    let url = '';
    if(this.shopDomain == 'b-m-c-abogados.myshopify.com'){

      url = `${this.baseUrl}/ampify/${this.shopDomain}`;
      url += '?dev=true';
      url += '&theme=' + this.amBuilderService.themeId;
    } else {

      url = `${this.baseUrl}/ampify/${this.shopDomain}/products/${this.currentItemHandle}`;
      url += '?dev=true';
      url += '&theme=' + this.amBuilderService.themeId;
    }

    this.setIframeUrl(url);
  }

  ngOnDestroy() {
    this.sendIframeEvent.unsubscribe();
    this.refreshIframeEvent.unsubscribe();
    this.changePageTypeEvent.unsubscribe();
    this.changeViewportSizeEvent.unsubscribe();
    this.changeConfigurationEvent.unsubscribe();
    this.changeFieldEvent.unsubscribe();
  }

  setIframeUrl(url: string): void {
    // console.log("previewUrl",url);
    this.previewUrl = this.trustSrcUrl(url);
    setTimeout(() => {

      this.test();

    }, 800);
  }

  trustSrcUrl(url): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  changePageType(pageType: string): void {

    this.pageType = pageType;

    // Have default pages in here? Default product, default collection, default blog post
    // default blog list, default page?

    const planName = this.amBuilderService.shopDetails.planName;
    this.editingAllowedInCurrentTier = true;

    this.neededPlanName = 'hobbyist';

    let url = '';
    switch (pageType) {
      case PAGE_TYPE.HOME_PAGE:
        this.currentItemHandle = '';
        url = `${this.baseUrl}/ampify/${this.shopDomain}`;
        if (planName !== 'company' && planName !== 'enterprise') {
          this.editingAllowedInCurrentTier = false;
          this.neededPlanName = 'company';
        }
        break;
      case PAGE_TYPE.PRODUCT_PAGE:
        this.currentItemHandle = this.amBuilderService.editorDetails.pages.product || 'any';
        url = `${this.baseUrl}/ampify/${this.shopDomain}/products/${this.currentItemHandle}`;
        break;
      case PAGE_TYPE.COLLECTION:
        this.currentItemHandle = this.amBuilderService.editorDetails.pages.collection || 'all';
        url = `${this.baseUrl}/ampify/${this.shopDomain}/collections/${this.currentItemHandle}`;
        if (planName !== 'company' && planName !== 'enterprise' && planName !== 'hobbyist') {
          this.editingAllowedInCurrentTier = false;
        }
        break;
      case PAGE_TYPE.COLLECTIONS_LIST:
        this.currentItemHandle = '';
        url = `${this.baseUrl}/ampify/${this.shopDomain}/collections`;
        if (planName !== 'company' && planName !== 'enterprise' && planName !== 'hobbyist') {
          this.editingAllowedInCurrentTier = false;
        }
        break;
      case PAGE_TYPE.BLOG_LIST_PAGE:
        this.currentItemHandle = this.amBuilderService.editorDetails.pages.blog || 'news';
        url = `${this.baseUrl}/ampify/${this.shopDomain}/blogs/${this.currentItemHandle}`;
        if (planName !== 'company' && planName !== 'enterprise') {
          this.editingAllowedInCurrentTier = false;
          this.neededPlanName = 'company';
        }
        break;
      case PAGE_TYPE.BLOG_POST_PAGE:
        this.currentItemHandle = (this.amBuilderService.editorDetails.pages.blog || 'news') + '/' + (this.amBuilderService.editorDetails.pages.blogPost || 'test');
        url = `${this.baseUrl}/ampify/${this.shopDomain}/blogs/${this.currentItemHandle}`;
        if (planName !== 'company' && planName !== 'enterprise') {
          this.editingAllowedInCurrentTier = false;
          this.neededPlanName = 'company';
        }
        break;
      default:
        this.editingAllowedInCurrentTier = false;
        this.neededPlanName = 'company';
        break;
    }
    url += '?dev=true';
    url += '&theme=' + this.amBuilderService.themeId;
    url += '&ref=editor';

    // TODO: Do the post of the re-render iframe, to show the changes from previous screen
    const conf = {
      global_settings: this.amBuilderService.theme.global_settings,
      sections: this.amBuilderService.theme.sections
    };
    // console.log("conf",conf)
    this.pubsub.publishEvent('CONFIGURATION_CHANGE', conf);
  }

  upgrade(): void {
    const upgradeUrl = `${environment.baseUrl}/install/upgrade?shop=${this.shopDomain}&tier=${this.neededPlanName}&discount=${this.accountDetails.discount}`;
    window.location.href = upgradeUrl;
  }

  listenForIframeMessage(): void {
    if (window.addEventListener) {
      window.addEventListener('message', this.receiveMessage.bind(this), false);
    } else {
      (<any>window).attachEvent('onmessage', this.receiveMessage.bind(this));
    }
  }
  receiveMessage(message) {
    let data = message.data;
    try {
      data = JSON.parse(data);
    } catch (e) { }

    if (data.type === 'IFRAME_CONTENT_LOADING') {
      // Set loader to true/false
      this.loading = data.value;
    }
  }

  updateIframeContent(message: IFrameMessage): void {
    const jsonMessage = JSON.stringify(message);
    this.sendIframeMessage(jsonMessage);
  }

  sendIframeMessage(message) {
    const window = this.previewIframe.nativeElement.contentWindow;
    // console.log("window",message)
    window.postMessage(message, '*');
  }

  setViewportSize(size: string) {
    this.viewportSize = size.toLowerCase();
  }

  test() {
    const iframeElement = this.previewIframe.nativeElement;

    iframeElement.addEventListener('click', () => {
      // Your code to handle the click event goes here
      // console.log("herere");

    });
  }

  showUpgradeModal(): void {

    this.sharedService.showAllUpgradeOptions({inputDiscount :this.accountDetails.discount, inputDiscountPercentage :this.accountDetails.discountPercentage, inputFreeTrialDays : this.accountDetails.freeTrialDays, shopifyPlanName : this.accountDetails.shopifyPlanName  });
  }

}

