<div class="Polaris-Modal-Dialog__Container" data-polaris-layer="true" data-polaris-overlay="true">
    <div>
      <div class="Polaris-Modal-Dialog__Modal" role="dialog">
        <div class="Polaris-Modal-Header">
          <div class="Polaris-Modal-Header__Title">
            <h2 class="Polaris-DisplayText Polaris-DisplayText--sizeSmall">
              Confirmation
            </h2>
          </div>
          <button (click)="cancel()" class="Polaris-Modal-CloseButton" aria-label="Close">
            <span class="Polaris-Icon Polaris-Icon--colorInkLighter Polaris-Icon--isColored">
              <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                <path
                  d="M11.414 10l6.293-6.293a.999.999 0 1 0-1.414-1.414L10 8.586 3.707 2.293a.999.999 0 1 0-1.414 1.414L8.586 10l-6.293 6.293a.999.999 0 1 0 1.414 1.414L10 11.414l6.293 6.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L11.414 10z"
                  fill-rule="evenodd"></path>
              </svg>
            </span>
          </button>
        </div>
  
        <div class="Polaris-Modal__BodyWrapper">
            <div class="Polaris-Modal__Body Polaris-Scrollable Polaris-Scrollable--vertical" data-polaris-scrollable="true">
                <section class="Polaris-Modal-Section">
                  <div class="Polaris-TextContainer">
                    <p>
                      We will try to import pulls the latest settings from your current Shopify theme into your AMP theme. This takes about 20 seconds.
                    </p>
                  </div>
                </section>
              </div>
         
        </div>
  
        <div class="Polaris-Modal-Footer">
          <div class="Polaris-Modal-Footer__FooterContent">
            <div class="Polaris-Stack Polaris-Stack--alignmentCenter">
              <div class="Polaris-Stack__Item Polaris-Stack__Item--fill"></div>
              <div class="Polaris-Stack__Item">
                <div class="Polaris-ButtonGroup">
                  <div class="Polaris-ButtonGroup__Item">
                    <button (click)="cancel()" type="button" class="Polaris-Button">
                      <span class="Polaris-Button__Content">
                        <span class="Polaris-Button__Text">Cancel</span>
                      </span>
                    </button>
                  </div>
                  <div class="Polaris-ButtonGroup__Item">
                    <button (click)="save()" type="button" class="Polaris-Button Polaris-Button--primary">
                      <span class="Polaris-Button__Content">
                        <span class="Polaris-Button__Text">Yes, Refresh</span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
      </div>
    </div>
  </div>
  <div class="Polaris-Backdrop"></div>