import { Component, OnInit, Input } from '@angular/core';
import { AmBuilderService } from '../../am-builder.service';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { ShopDetails, PAGE_TYPE, PageType, Theme } from '../../../am-builder.model';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'am-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss']
})
export class TopNavbarComponent implements OnInit {

  @Input()
  type: string;

  theme: Theme;
  story: any;
  selectedPageType: string;
  pageTypes: Array<PageType>;
  selectedViewportSize = 'SMALL';
  shopDetails: ShopDetails;

  constructor(
    private pubsub: NgxPubSubService,
    private amBuilderService: AmBuilderService,
    private notifier: NotifierService,
  ) {
  }

  ngOnInit() {
    this.theme = this.amBuilderService.theme;
    this.shopDetails = this.amBuilderService.shopDetails;

    if(this.shopDetails.domain == 'b-m-c-abogados.myshopify.com'){

        this.selectedPageType = PAGE_TYPE.HOME_PAGE;

        this.pageTypes = [{
          label: 'Home page',
          value: PAGE_TYPE.HOME_PAGE
        },

        // {
        //   label: 'Product pages',
        //   value: PAGE_TYPE.PRODUCT_PAGE
        // }, {
        //   label: 'Collection pages',
        //   value: PAGE_TYPE.COLLECTION
        // }, {
        //   label: 'Collections list',
        //   value: PAGE_TYPE.COLLECTIONS_LIST
        // },

        {
          label: 'Blogs',
          value: PAGE_TYPE.BLOG_LIST_PAGE
        }, {
          label: 'Blog posts',
          value: PAGE_TYPE.BLOG_POST_PAGE
          // }, {
          //   label: 'Error page',
          //   value: 'ERROR_PAGE'
        }];
    } else {

        this.selectedPageType = PAGE_TYPE.PRODUCT_PAGE;

        this.pageTypes = [{
          label: 'Home page',
          value: PAGE_TYPE.HOME_PAGE
        }, {
          label: 'Product pages',
          value: PAGE_TYPE.PRODUCT_PAGE
        }, {
          label: 'Collection pages',
          value: PAGE_TYPE.COLLECTION
        }, {
          label: 'Collections list',
          value: PAGE_TYPE.COLLECTIONS_LIST
        }, {
          label: 'Blogs',
          value: PAGE_TYPE.BLOG_LIST_PAGE
        }, {
          label: 'Blog posts',
          value: PAGE_TYPE.BLOG_POST_PAGE
          // }, {
          //   label: 'Error page',
          //   value: 'ERROR_PAGE'
        }];
    }
  }

  pageChange(page: PAGE_TYPE): void {
    this.amBuilderService.builderConfig.currentPage = page;
    // console.log("page",page);
    this.pubsub.publishEvent('PAGE_TYPE_CHANGE', page);
  }

  setViewportSize(size: string) {
    this.selectedViewportSize = size;
    this.pubsub.publishEvent('VIEWPORT_CHANGE', size);
  }

  save() {
    // Send request to update configuration
    this.amBuilderService.updateTheme()
      .subscribe((res) => {

        // If ok, make new "state" to now
        // "state" is needed for cancelling

        // Refresh the iframe
        this.pubsub.publishEvent('REFRESH_IFRAME_EVENT', {});

        // Show notification that it was saved ok?
        this.notifier.notify('default', 'Theme configuration updated.');

      }, (err) => {
        // Show notification that it failed
        this.notifier.notify('error', 'Failed updating configuration, please try again.');
      });
  }
}
