<div class="Polaris-TextContainer">
  <p class="Polaris-DisplayText Polaris-DisplayText--sizeExtraLarge">
    Hi {{getFirstName(user.shopOwner)}}! 👋
  </p>
  <p class="Polaris-DisplayText Polaris-DisplayText--sizeMedium">
    Ready to make your store amazing?
  </p>
  <a [routerLink]="['/pwa/onboarding/quick-config']"
    class="Polaris-Button Polaris-Button--primary Polaris-Button--sizeLarge">
    <span class="Polaris-Button__Content">
      <span class="Polaris-Button__Text">
        Let's do it
      </span>
    </span>
  </a>
</div>