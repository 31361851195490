<div class="Polaris-EmptyState">
  <div class="Polaris-EmptyState__Section">
    <div class="Polaris-EmptyState__DetailsContainer">
      <div class="Polaris-EmptyState__Details">

        <div class="Polaris-Card">
          <div class="Polaris-Card__Section">
            <div class="Polaris-TextContainer">

              <p class="Polaris-DisplayText Polaris-DisplayText--sizeMedium">
                Your store will 👇
              </p>
              <div class="Polaris-EmptyState__Content text-left">

                <ul class="mt-2">
                  <li>be super fast because of caching</li>
                  <li>work offline</li>
                  <li>be installable on your users phones as an app</li>
                  <li>have perfect SEO scores</li>
                </ul>
              </div>
            </div>

            <div class="Polaris-EmptyState__Actions">
              <div class="Polaris-Stack Polaris-Stack--alignmentCenter">

                <div class="Polaris-Stack__Item">
                  <a [routerLink]="['/pwa/onboarding/welcome']" class="Polaris-Button Polaris-Button--plain"
                    data-polaris-unstyled="true">
                    <span class="Polaris-Button__Content">
                      <span class="Polaris-Button__Text">Back</span>
                    </span>
                  </a>
                </div>

                <div class="Polaris-Stack__Item">
                  <a [routerLink]="['/pwa/onboarding/quick-config']"
                    class="Polaris-Button Polaris-Button--primary Polaris-Button--sizeLarge">
                    <span class="Polaris-Button__Content">
                      <span class="Polaris-Button__Text">
                        Continue
                      </span>
                    </span>
                  </a>
                </div>

              </div>

            </div>
          </div>

        </div>

      </div>
    </div>
    <div class="Polaris-EmptyState__ImageContainer">
      <img src="/assets/pwa/images/mobile-pay.svg" role="presentation" alt="" class="Polaris-EmptyState__Image">
    </div>
  </div>
</div>