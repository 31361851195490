import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { AmBuilderService } from '../../../am-builder.service';

@Component({
  selector: 'am-menu-picker-field',
  templateUrl: './menu-picker-field.component.html',
  styleUrls: ['./menu-picker-field.component.scss']
})
export class MenuPickerFieldComponent implements OnInit {

  @Input()
  item: any;

  @Input()
  values: any;

  @Output() valueUpdated = new EventEmitter<any>();

  menus: Array<any>;

  constructor(
    private amBuilderService: AmBuilderService
  ) { }

  ngOnInit() {
    this.menus = this.amBuilderService.editorDetails.menus || [];
  }

  valueUpdatedEvent(id, value) {
    this.valueUpdated.emit({
      id: id,
      value: value
    });
  }
}
