import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '../../../environments/environment';

import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'am-upgrade-dialog',
  templateUrl: './upgrade-dialog.component.html',
  styleUrls: ['./upgrade-dialog.component.scss']
})
export class UpgradeDialogComponent implements OnInit {

  @Input()
  neededPlanName: string;

  @Output() modalClosed = new EventEmitter<any>();

  upgradeUrl: string;

  constructor(
    private authService: AuthService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    const user = this.authService.getUser();
    const shop = user.shopDomain;
    this.upgradeUrl = `${environment.baseUrl}/install/upgrade?shop=${shop}&tier=${this.neededPlanName}`;
  }

  cancel() {
    this.modalClosed.emit(false);
    this.activeModal.close();
  }

}
