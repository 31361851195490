<div class="edit-component-field">
  <label class="label-{{item.type}}">{{item.label | getLocalizedName}}</label>

  <!-- Basic input setting types -->

  <!-- IF "text" -->
  <input *ngIf="item.type === 'text'" (keyup)="valueChanged(item.id, values[item.id])"
    (blur)="valueUpdated(item.id, values[item.id])" type="text" [id]="item.id" [(ngModel)]="values[item.id]"
    [placeholder]="item.placeholder || ''" />

  <!-- IF "textarea" -->
  <textarea *ngIf="item.type === 'textarea'" (keyup)="valueChanged(item.id, values[item.id])"
    (blur)="valueUpdated(item.id, values[item.id])" [id]="item.id" [(ngModel)]="values[item.id]"
    [placeholder]="item.placeholder || ''">
  </textarea>

  <!-- IF "image_picker" -->
  <am-image-picker-field *ngIf="item.type === 'image_picker'" [item]="item" [values]="values"
    (valueUpdated)="onValueUpdatedChild($event)"></am-image-picker-field>

  <!-- https://help.shopify.com/en/themes/development/theme-editor/settings-schema -->

  <!-- IF "radio" -->
  <input *ngIf="item.type === 'radio'" type="radio" [id]="item.id" [(ngModel)]="values[item.id]" />

  <!-- IF SELECT -->
  <select [id]="item.id" *ngIf="item.type === 'select'" [(ngModel)]="values[item.id]"
    (change)="valueUpdated(item.id, values[item.id])">
    <option *ngFor="let option of item.options" [value]="option.value">
      {{option.label | getLocalizedName}}
    </option>
  </select>

  <!-- IF "checkbox" -->
  <input *ngIf="item.type === 'checkbox'" type="checkbox" class="next-checkbox" [id]="item.id"
    (change)="valueUpdated(item.id, values[item.id])" [(ngModel)]="values[item.id]" />

  <!-- IF "range" -->
  <am-range-field *ngIf="item.type === 'range'" [item]="item" [values]="values"
    (valueChanged)="onValueChangedChild($event)" (valueUpdated)="onValueUpdatedChild($event)"></am-range-field>


  <!-- IF "color" -->
  <!-- {{values[item.id]}} -->
  <input *ngIf="item.type === 'color'" class="color-picker" [(colorPicker)]="values[item.id]"
    [style.background]="values[item.id]" cpPosition="bottom"
    (colorPickerChange)="valueChanged(item.id, values[item.id])"
    (colorPickerClose)="valueUpdated(item.id, values[item.id])" />

  <!-- IF "richtext" -->
  <textarea *ngIf="item.type === 'richtext'" (keyup)="valueChanged(item.id, values[item.id])"
    (blur)="valueUpdated(item.id, values[item.id])" [id]="item.id" [(ngModel)]="values[item.id]"
    [placeholder]="item.placeholder || ''">
  </textarea>

  <!-- IF "url" -->
  <input *ngIf="item.type === 'url'" (keyup)="valueChanged(item.id, values[item.id])"
    (blur)="valueUpdated(item.id, values[item.id])" type="text" [id]="item.id" [(ngModel)]="values[item.id]"
    [placeholder]="item.placeholder || ''" />


  <!-- https://help.shopify.com/en/themes/development/fonts/library -->
  <!-- IF "font_picker" -->
  <am-font-field *ngIf="item.type === 'font_picker'" [item]="item" [values]="values"
    (valueUpdated)="onValueUpdatedChild($event)"></am-font-field>

  <!-- IF "link_list" -->
  <am-menu-picker-field *ngIf="item.type === 'link_list'" [item]="item" [values]="values"
    (valueUpdated)="onValueUpdatedChild($event)"></am-menu-picker-field>

  <!-- IF "paragraph" -->
  <markdown *ngIf="item.type === 'paragraph'" [data]="item.content | getLocalizedName"></markdown>

  <!-- IF "html" -->
  <textarea *ngIf="item.type === 'html'" (keyup)="valueChanged(item.id, values[item.id])"
    (blur)="valueUpdated(item.id, values[item.id])" [id]="item.id" [(ngModel)]="values[item.id]"
    [placeholder]="item.placeholder || ''" rows="8">
  </textarea>

  <!-- IF "video_url" -->
  <input *ngIf="item.type === 'video_url'" (blur)="valueUpdated(item.id, values[item.id])" type="text" [id]="item.id"
    [(ngModel)]="values[item.id]" [placeholder]="item.placeholder || ''" />

  <!-- IF "blog" -->
  <am-resource-picker-field *ngIf="item.type === 'blog'" type="BLOG" [item]="item" [values]="values"
    (valueUpdated)="onValueUpdatedChild($event)"></am-resource-picker-field>

  <!-- IF "collection" -->
  <am-resource-picker-field *ngIf="item.type === 'collection'" type="COLLECTION" [item]="item" [values]="values"
    (valueUpdated)="onValueUpdatedChild($event)"></am-resource-picker-field>

  <!-- IF "product" -->
  <am-resource-picker-field *ngIf="item.type === 'product'" type="PRODUCT" [item]="item" [values]="values"
    (valueUpdated)="onValueUpdatedChild($event)"></am-resource-picker-field>

  <label class="label-after label-{{item.type}}-after">{{item.label | getLocalizedName}}</label>

  <p class="info">
    <markdown [data]="item.info | getLocalizedName"></markdown>
  </p>
</div>