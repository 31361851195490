import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PwaOnboardingWelcomeComponent } from './pwa-onboarding-welcome/pwa-onboarding-welcome.component';
import { PwaOnboardingExplanationComponent } from './pwa-onboarding-explanation/pwa-onboarding-explanation.component';
import { PwaOnboardingFinishComponent } from './pwa-onboarding-finish/pwa-onboarding-finish.component';
import { PwaOnboardingQuickConfigComponent } from './pwa-onboarding-quick-config/pwa-onboarding-quick-config.component';
import { PwaOnboardingInstallTutorialComponent } from './pwa-onboarding-install-tutorial/pwa-onboarding-install-tutorial.component';

const routes: Routes = [
  {
    path: 'welcome',
    component: PwaOnboardingWelcomeComponent
  },
  // {
  //   path: 'explanation',
  //   redirectTo: 'quick-config',
  //   component: PwaOnboardingExplanationComponent
  // },
  {
    path: 'quick-config',
    component: PwaOnboardingQuickConfigComponent
  },
  // {
  //   path: 'install-tutorial',
  //   component: PwaOnboardingInstallTutorialComponent
  // },
  {
    path: 'finish',
    component: PwaOnboardingFinishComponent
  },

  // otherwise redirect to welcome page in onboarding
  {
    path: '**',
    redirectTo: 'welcome'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PwaOnboardingRoutingModule { }
