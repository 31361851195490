import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { PageVisibilityConfiguration, DomainConfiguration, AccountDetails } from './settings.model';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  baseUrl = environment.baseUrl;

  constructor(
    private http: HttpClient
  ) { }

  getAccountDetails(): Observable<AccountDetails> {
    return this.http.get<AccountDetails>(`${this.baseUrl}/api/v2/settings/account`);
  }

  getPageVisibility(): Observable<PageVisibilityConfiguration> {
    return this.http.get<PageVisibilityConfiguration>(`${this.baseUrl}/api/v2/settings/page-visibility`);
  }

  updatePageVisibility(config: PageVisibilityConfiguration): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/api/v2/settings/page-visibility`, config);
  }

  getDomainConfiguration(): Observable<DomainConfiguration> {
    return this.http.get<DomainConfiguration>(`${this.baseUrl}/api/v2/settings/domain`);
  }
  updateDomainConfiguration(config: DomainConfiguration): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/api/v2/settings/domain`, config);
  }
  createCustomDomainConfiguration(config: DomainConfiguration): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/api/v2/settings/domain/custom`, config);
  }
}
