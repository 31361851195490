import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AmBuilderService } from '../../../../am-builder.service';
import { FormDataPrepareService } from '../../../services/form-data-prepare.service';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { CookieService } from 'ngx-cookie-service';

import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'am-form-v2',
  templateUrl: './form-v2.component.html',
  styleUrls: ['./form-v2.component.scss']
})
export class FormV2Component implements OnInit {

  values: any = {};

  @Input() formJSON: any = []

  @Input() formData = {};

  @Input() mainMenu: any = {};
  @Input() headerMenu: any = {};
  @Input() footerMenu: any = {};

  @Input() currentMenu: any = {};

  changePageTypeEvent: any;

  pageType: String;

  returnObj = {};

  dynamicForm: FormGroup;
  formBuilded: boolean = false;
  @Output() closeForm = new EventEmitter();
  @Output() submitForm = new EventEmitter();


  @Input() route = [];
  @Input() headerTitle = '';

  @Input() sectionData = {};

  subscription: Subscription;
  cssSubscription: Subscription;


  formMessage = "No customizable settings available";

  uploadedImages: Array<any> = [];
  imageUrl = '';
  baseUrl = environment.baseUrl;

  isLoading = false;
  cssData : any = {};



  // get isValid() { return this.form.controls[this.question.key].valid; }
  constructor(
    private fb: FormBuilder,
    private data_prepare: FormDataPrepareService,
    private amBuilderService: AmBuilderService,
    private pubsub: NgxPubSubService,
    private http: HttpClient,
    private cookieService: CookieService,
  ) {
    // this.dynamicForm = this.createControl();

  }

  ngOnInit() {

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    console.log(this.formJSON);
    if(this.route[0] == 'announcement-bar'){
      this.formMessage = "Customize this section using blocks"
    }

    this.getCssObj();
  }

  ngOnChanges() {

    //console.log("changes");
    this.dynamicForm = this.createControl();
    // //console.log("dynamicForm", this.dynamicForm);


  }

  createControl() {
    const group = this.fb.group({});
    this.formJSON.forEach(field => {
      if (field.type === "button") return;
      const control = this.fb.control(
        field.value,
        this.bindValidations(field.validations || [])
      );
      group.addControl(field.key, control);
    });
    this.formBuilded = true;
    return group;
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach(valid => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  onSubmit(formValue: any) {

    let obj = {
      settings: formValue,
      route: this.route
    }
    this.submitForm.emit(obj);
  }

  closeDialog(): void {
    // this.closeEditComponentDetailsPanel.emit({});

    // //console.log("close", this.headerMenu, this.footerMenu)
    this.closeForm.emit();

  }

  // getFormFlag(){

  //   this.subscription = this.data_prepare.getSaveFlag().subscribe(res => {

  //    //console.log("getSaveData",res);
  //    if(res){
  //      this.onSubmit(this.dynamicForm.value);
  //    }

  //   })
  //  };

  onValueChangedChild(event) {
    this.valueChanged(event);
  }

  valueChanged(field: any) {
    // //console.log(this.returnObj, "this is changedValue");
    this.returnObj = {
      settings: this.dynamicForm.value,
      route: this.route
    }
    this.checkAlreadySettingsPresent();
    // const change = {
    //   component_id: this.component.id,
    //   value: newValue,
    //   field_id: id
    // };
    // this.pubsub.publishEvent('FIELD_CHANGE', change);
  }

  onValueUpdatedChild(event) {
    // this.valueUpdated(event.id, event.value);
  }

  valueUpdated(field) {
    field.value = this.dynamicForm.value[field.key];
    // console.log(this.dynamicForm.value, field);

    if (this.headerTitle != 'Google Analytics' && this.headerTitle != 'Pages') {
      this.returnObj = {
        settings: this.dynamicForm.value,
        route: this.route
      }
      this.checkAlreadySettingsPresent();

    } else if(this.headerTitle == 'Google Analytics') {
      // //console.log(this.dynamicForm.value);
      let newObj = this.checkForNullKey(this.dynamicForm.value)
      this.data_prepare.setAnalyticsSettings(newObj)
    } else if(this.headerTitle == 'Pages'){

      let newObj = this.checkForNullKey(this.dynamicForm.value);
      console.log("newObj",newObj);
      console.log("newObj",this.amBuilderService.cssSettings);

      let obj = {
        customCSS : this.amBuilderService.cssSettings.customCSS
      }
      obj.customCSS[field.key] = this.dynamicForm.value[field.key];
      this.data_prepare.setCssSettings(obj)

    }
    this.data_prepare.setFieldEditedEvent(true);
    // const change = {
    //   id: this.route[0]

    // };
    // this.pubsub.publishEvent('FIELD_CHANGE', change);

    // //console.log("this is the new value", this.returnObj, this.headerTitle);

    // const conf = {
    //   global_settings: this.globalSettings,
    //   sections: this.sections
    // };
    // this.pubsub.publishEvent('CONFIGURATION_CHANGE', conf);
  }

  onConfigurationChange() {
    // //console.log("this is the new value")
    // const conf = {
    //   global_settings: this.globalSettings,
    //   sections: this.sections
    // };
    // this.pubsub.publishEvent('CONFIGURATION_CHANGE', conf);
  }


  checkAlreadySettingsPresent() {

    console.log("headerMenu............", this.headerMenu);

    console.log("/////this.returnObj.......,,,,,,,,",this.returnObj);

    let keys = Object.keys(this.returnObj)

    let settings = {};
    if (keys.length) {

      if (this.returnObj['route'].length == 1) {
        settings = this.returnObj['settings'];
        if (this.returnObj['route'][0] == 'header') {

          this.headerMenu.sections[this.returnObj['route'][0]].settings = settings;

        } else if (this.returnObj['route'][0] == 'footer') {

          this.footerMenu.sections[this.returnObj['route'][0]].settings = settings;
        }  else if (this.returnObj['route'][0] == 'custom-html') {

          this.mainMenu.sections[this.returnObj['route'][0]].settings = settings;
        } else if(this.returnObj['route'][0] == 'announcement-bar'){

          settings = this.returnObj['settings'];

          this.headerMenu.sections[this.returnObj['route'][0]].settings = { ...settings };

        }else if(this.returnObj['route'][0].includes('custom_section')){
          this.headerMenu.sections[this.returnObj['route'][0]].settings = settings;
        }
        else {

          this.mainMenu.sections[this.returnObj['route'][0]].settings = { ...settings };
        }

      } else {

        settings = {...this.returnObj['settings']};

        if (this.returnObj['route'][0] == "theme-settings") {
          settings = {...this.returnObj['settings']};


          this.attachedThemeSetting(settings);

        } else if(this.returnObj['route'][0] == 'announcement-bar'){

          settings = this.returnObj['settings'];

          this.headerMenu.sections[this.returnObj['route'][0]]['blocks'][this.returnObj['route'][1]].settings = { ...settings };

        } else if (this.returnObj['route'][0] == 'footer') {

          // console.log("in footer",this.footerMenu);
          this.footerMenu.sections[this.returnObj['route'][0]]['blocks'][this.returnObj['route'][1]].settings = { ...settings };
        } else if(this.returnObj['route'][0] == "color_schemes"){

          console.log("this.currentMenu",this.currentMenu);
          console.log("this.currentMenu",this.returnObj);
          this.currentMenu[this.returnObj['route'][0]][this.returnObj['route'][1]].settings = {...this.returnObj['settings']}
        } else {

          settings = this.returnObj['settings'];
          if (this.returnObj['route'][1] == 'buttons') {

            this.mainMenu.sections[this.returnObj['route'][0]]['blocks']['button'].settings = settings;
          } else {

            //console.log("im in else",this.returnObj['route'],this.mainMenu)

            this.mainMenu.sections[this.returnObj['route'][0]]['blocks'][this.returnObj['route'][1]].settings = { ...settings };

          }

        }
      }
    }

    console.log("this.headerMenu",this.headerMenu);
    // let tmp = {
    //   'header': this.headerMenu.sections['header'],
    //   'announcement-bar': this.headerMenu.sections['announcement-bar'],
    //   'footer': this.footerMenu.sections['footer']

    // }

    // this.currentMenu['sections'] = tmp;

    // //console.log(this.currentMenu)
    this.publistPreviewEvent();


  }

  attachedThemeSetting(settings) {

    console.log("form value",this.currentMenu);
    // return new Promise((resolve) => {

      let keys = Object.keys(this.currentMenu);
      // //console.log("attachedThemeSetting", keys);
      let settingKeys = Object.keys(settings);
      // //console.log("setting keys", settingKeys);

      for (let i = 0; i < keys.length; i++) {

        for (let j = 0; j < settingKeys.length; j++) {

          if (keys[i] == settingKeys[j]) {
            this.currentMenu[keys[i]] = settings[keys[i]]
          } else {
            if(settingKeys[j]){
            this.currentMenu[settingKeys[j]] = settings[settingKeys[j]]
          }
          }
        }
      }
    console.log("form value",this.currentMenu);

      // resolve(null)

    // })

  }

  removeSection(arr) {

    console.log("removeSection",arr);
    let sectionName = arr[0]

  if(arr[0].includes("custom_section")){

    const index = this.headerMenu.order.indexOf(sectionName);
    this.headerMenu.order.splice(index, 1);


    delete this.headerMenu["sections"][sectionName]

    delete this.currentMenu['sections'][sectionName]

  }

if(!arr[0].includes("custom_section")){

  for (let i = 0; i < this.mainMenu.order.length; i++) {
    if (this.mainMenu.order[i] == arr[0]) {

      this.mainMenu.order.splice(i, 1);
      delete this.mainMenu.sections[arr[0]];
    }
  }
}

    // this.data_prepare.setFormFlag(true);
    this.closeForm.emit();


    this.publistPreviewEvent();
  }
  removeBlock(arr) {

    //console.log(arr,this.headerMenu);
    this.closeForm.emit();

    // for (let i = 0; i < this.mainMenu.order.length; i++) {
    //   if (this.mainMenu.order[i] == arr[0]) {

    if(arr[0] == 'announcement-bar'){

      for (let j = 0; j < this.headerMenu.sections[arr[0]]['block_order'].length; j++) {

        if (this.headerMenu.sections[arr[0]]['block_order'][j] == arr[1]) {
          //console.log("removing block")
          this.headerMenu.sections[arr[0]]['block_order'].splice(j, 1);
          delete this.headerMenu.sections[arr[0]]['blocks'][arr[1]]
        }
      }
    } else if(arr[0] == 'footer'){

      for (let j = 0; j < this.footerMenu.sections[arr[0]]['block_order'].length; j++) {

        if (this.footerMenu.sections[arr[0]]['block_order'][j] == arr[1]) {
          //console.log("removing block")
          this.footerMenu.sections[arr[0]]['block_order'].splice(j, 1);
          delete this.footerMenu.sections[arr[0]]['blocks'][arr[1]]
        }
      }
    }
    else {

        for (let j = 0; j < this.mainMenu.sections[arr[0]]['block_order'].length; j++) {

          if (this.mainMenu.sections[arr[0]]['block_order'][j] == arr[1]) {
            //console.log("removing block")
            this.mainMenu.sections[arr[0]]['block_order'].splice(j, 1);
            delete this.mainMenu.sections[arr[0]]['blocks'][arr[1]]
          }
        }
      }

    //   }

      // this.data_prepare.setFormFlag(true);


    // }
    this.publistPreviewEvent();


  }
  publistPreviewEvent() {

    console.log("this.mainMenu.order",this.currentMenu);
    let globSett = {
      current : this.currentMenu
    }

    let obj = {
      globalSettings: globSett,
      sections: this.mainMenu.sections,
      order: this.mainMenu.order,
      isDev: true,
      page: ''
    }
    //console.log("this.currentMenu>>>>>>>>>>vikas",obj)


    // //console.log("conf", obj)
    this.pubsub.publishEvent('CONFIGURATION_CHANGE', obj);
    // //console.log("value changed", this.mainMenu)
  }

  onFileChanged(event, field) {
    // //console.log(event);
    if (event.target.files.length != 0) {
      this.isLoading = true;
      const file = event.target.files[0];

      let headers = new HttpHeaders();
      const auth = this.cookieService.get('ampify-x-access-token');
      headers = headers.set('x-access-token', auth);

      this.http.post(this.baseUrl + '/api/upload/editor', {
        'Content-Type': file.type
      }, {
        headers: headers
      })
        .subscribe((uploadReponse: any) => {
          // Upload to S3 now
          let s3headers = new HttpHeaders();
          s3headers = s3headers.set('Content-Type', file.type);
          s3headers = s3headers.set('Expires', 'Fri, 1 Jan 2025 23:59:59 GMT');
          this.http.put(uploadReponse.uploadUrl, file, {
            headers: s3headers,
            reportProgress: true,
            observe: 'events'
          })
            .subscribe((eventData: any) => {

              if (eventData) {
                if (eventData.type === 1) {
                }
                if (eventData.type === 4) {
                  // All done
                  // console.log("*",field);
                  this.imageUrl = uploadReponse.url;
                  field.value = this.imageUrl;
                  // this.dynamicForm.value[field.key] = this.imageUrl;
                  this.dynamicForm.get(field.key).patchValue(this.imageUrl);

                  this.valueUpdated(field);
                  this.isLoading = false;

                  // this.uploadedImages.forEach(image => {
                  //   image.selected = false;
                  // });
                  // Add to this array
                  // this.uploadedImages.unshift({
                  //   id: this.imgObject.id,
                  //   url: this.imageUrl,
                  //   selected: false
                  // });
                  // this.uploadedImages[0].selected = true;
                  // this.previewImage();
                  // this.showFooter = true;
                  // console.log("form",this.dynamicForm.value)
                }
              }

              // //console.log("image uploaded", this.imageUrl)
            }, (err) => {
              //console.log(err);
      this.isLoading = false;

            });

        }, (err) => {
          //console.log(err);
      this.isLoading = false;

        });
    }
  }
  checkForNullKey(obj) {

    let keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i++) {
      if (!keys[i]) {

        delete obj[keys[i]]
      }
    }
    return obj;
  }
  removeImage(key,field) {

    this.dynamicForm.get(key).patchValue('');

    // console.log(this.dynamicForm.value);
    this.returnObj = {
      settings: this.dynamicForm.value,
      route: this.route
    }
    this.valueUpdated(field);

  }

  showRemoveSection(name){

    if(name == 'theme-settings'){

      return false;
    }  else if(name == 'main-product' || name == 'main'){
      return false;
    } else if(name == 'header'){
      return false;
    } else if(name == 'pages'){
      return false;
    } else if(name == 'footer'){
      return false;
    } else if(name == 'product-recommendations'){
      return false;
    } else if(name == 'announcement-bar'){
      return false;
    } else if(name == 'banner'){
      return false
    } else if(name == 'product-grid'){
      return false;
    }
    else {
      return true;
    }

  }

  showRemoveBlock(section,subsection){

    if(section == 'main-product' || section == 'main'){

      return true

    } else if(section == 'footer'){
       return true
    } else {
      return true
    }
  }

  openImage(i){

    let id = 'image_upload-'+i

    document.getElementById(id).click();

  }
  getCssObj(){

    this.cssSubscription = this.data_prepare.getCssSettings().subscribe(res => {


      // //console.log("cssData",res);
      this.cssData = res['customCSS'];
    })

  }

}
