<div id="editComponent">
  <div class="edit-component-snippet-header">




    <!-- FIELDS -->
    <div id="editComponentFieldsWrapper">

      <div id="editComponentFields">

        <div class="edit-section-card">
          <div class="containers" *ngIf="formBuilded">
            <p *ngIf="formJSON.length == 0" class="no-setting-msg">{{formMessage}}</p>
            <form style="height:100%;" *ngIf="formBuilded && formJSON.length != 0" class="formDiv"
              [formGroup]="dynamicForm">
              <div class="formFields" *ngFor="let field of formJSON; let ix = index">

                <h3 *ngIf="field.type === 'header'" class="section-heading">{{field.content}}</h3>

                <p class="para-heding" *ngIf="field.type == 'paragraph'">
                  <!-- {{field.content}} -->
                  <markdown [data]="field.content | getLocalizedName"></markdown>

                </p>

                <div style="display: flex;">
                  <input [disabled]="field.disabled" (change)="valueUpdated(field)" *ngIf="field.type === 'checkbox'" type="checkbox"
                    class="next-checkbox" [formControlName]="field.key" />
                  <label *ngIf="field.type == 'checkbox'" class="label-{{field.type}}">{{field.label |
                    getLocalizedName}}</label>

                </div>
                <label *ngIf="field.type !== 'checkbox'" class="label-{{field.type}}">{{field.label |
                  getLocalizedName}}</label>

                <div *ngIf="field.type === 'image_picker'" id="preview-image-container"
                  class="aspect-ratio aspect-ratio--square aspect-ratio--reset-z-index"
                  [ngClass]="{'aspect-ratio aspect-ratio--square': !field.value}">
                  <div class="aspect-ratio__content next-grid next-grid--center-both">
                    <div *ngIf="isLoading" class="loader">
                      <div class="lds-dual-ring"></div>

                  </div>
                    <div class="text-center" *ngIf="field.value" style="position: relative;">
                      <div id="upload-image-progress" class="ui top attached">
                        <div class="bar"></div>
                      </div>

                      <img id="preview-image" [src]="field.value" />
                      <span class="button-group">
                        <button id="changeImageBtn" (click)="openImage(ix)" class="button secondary">Change</button>
                        <button id="removeImageBtn" (click)="removeImage(field.key,field)" class="button secondary">Remove</button>
                      </span>
                    </div>
                <input style="display: none;" id="image_upload-{{ix}}" type="file" accept="image/*" (change)="onFileChanged($event,field)">

                    <div *ngIf="!field.value && !isLoading" class="no-image">
                      <button id="uploadImageBtn" class="select-image-btn-class"  (click)="openImage(ix)">Select
                        image</button>
                    </div>
                  </div>
                </div>
                <input [disabled]="field.disabled" (blur)="valueUpdated(field)" *ngIf="field.type === 'text' || field.type === 'collection' || field.type === 'product' || field.type === 'color' || field.type == 'video_url' || field.type == 'color_background' || field.type == 'url' || field.type == 'blog'" [placeholder]="field.label"
                  [formControlName]="field.key" type="text" />

                <textarea [disabled]="field.disabled" (blur)="valueUpdated(field)"
                  *ngIf="field.type === 'textarea' || field.type=='richtext' || field.type == 'liquid' || field.type == 'html' || field.type == 'inline_richtext'"
                  [formControlName]="field.key"></textarea>



                <select [disabled]="field.disabled" (change)="valueUpdated(field)" *ngIf="field.type === 'select' || field.type == 'link_list' || field.type == 'color_scheme'" [formControlName]="field.key">
                  <option *ngFor="let option of field.options" [value]="option.value">
                    {{option.label | getLocalizedName}}
                  </option>
                </select>
                <select (change)="valueUpdated(field)" *ngIf="field.type == 'font_picker'" [formControlName]="field.key">
                  <option *ngFor="let option of field.options" [value]="option">
                    {{option | getLocalizedName}}
                  </option>
                </select>

                <!-- IF "color" -->
                <!-- <input *ngIf="field.type === 'color'" class="color-picker" [(colorPicker)]="field.value"
                  [style.background]="field.value" cpPosition="bottom" (colorPickerChange)="valueChanged(field)"
                  (colorPickerClose)="valueUpdated(field)" /> -->


                <section class="example-section" *ngIf="field.type === 'range'">
                  <!-- <label class="example-margin">{{field.label | getLocalizedName}}</label> -->
                  <mat-slider (change)="valueUpdated(field)" class="example-margin" [formControlName]="field.key"
                    [max]="field.max" [min]="field.min" [step]="field.step"></mat-slider>
                  {{dynamicForm.value[field.key]}}{{field.unit}}
                </section>


                <p *ngIf="field.info" class="subtext">
                  <!-- {{field.info}} -->
                  <markdown [data]="field.info | getLocalizedName"></markdown>

                </p>

              </div>

              <!-- <div>
                <button class="buttonClass" mat-flat-button color="primary" (click)="onSubmit(dynamicForm.value)"
                  [disabled]="!dynamicForm.valid" type="submit">Submit</button>
              </div> -->
            </form>

            <div style="width: 100%;height: 60px;"></div>

          </div>

        </div>
      </div>

      <div *ngIf="showRemoveSection(route[0])" class="remove-section-block">
        <span class="clickable-section" *ngIf="route.length == 1" (click)="removeSection(route)">
        <!-- <mat-icon>delete</mat-icon>  -->
        <img src="./assets/amp/icons/DeleteMinor.png" width="20px" height="20px" style="margin-right: 10px;">

        Remove section
      </span>
      </div>
      <div *ngIf="route.length > 1 && showRemoveBlock(route[0],route[1])" class="remove-section-block">
      <span class="clickable-section" *ngIf="route.length > 1" (click)="removeBlock(route)">
        <!-- <mat-icon>delete</mat-icon>  -->
        <img src="./assets/amp/icons/DeleteMinor.png" width="20px" height="20px" style="margin-right: 10px;">
        Remove block
      </span>
      </div>

    </div>

  </div>
</div>