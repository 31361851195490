import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthUser } from './auth.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl = environment.baseUrl;
  basePWAUrl = environment.basePWAUrl;

  localUserData: any;
  app: string = 'amp';

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  getApp(): string {
    let app = 'amp';
    if (this.router.url.indexOf('/pwa') > -1) {
      app = 'pwa';
    }

    return app;
  }

  getToken(): string {
    // Get current app
    const app = this.getApp();

    // Return correct token
    return localStorage.getItem('ampify-x-access-token-' + app) ||
      localStorage.getItem('ampify-x-access-token') || '';
  }

  setAuth(token: string, app: string = 'amp'): void {
    localStorage.setItem('ampify-x-access-token-' + app, token);
    localStorage.setItem('ampify-x-access-token', token);
  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    return !!token;
  }

  getUser(): AuthUser {
    const userJson = localStorage.getItem('ampify-user') || '{}';
    let user = {};
    try {
      user = JSON.parse(userJson);
    } catch (e) { }
    this.localUserData = user;

    return this.localUserData;
  }

  setUser(user: AuthUser): void {
    try {
      const userJson = JSON.stringify(user);
      localStorage.setItem('ampify-user', userJson);

      this.localUserData = user;
    } catch (e) { }
  }

  getUserDetails(app: string): Observable<AuthUser> {
    app = app || this.getApp();
    if (app === 'pwa') {
      return this.http.get<AuthUser>(`${this.basePWAUrl}/api/v2/auth/me`);
    } else {
      return this.http.get<AuthUser>(`${this.baseUrl}/api/v2/auth/me`);
    }
  }

}
