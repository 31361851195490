<div class="Polaris-Frame__Navigation"
  [ngClass]="{'Polaris-Frame__Navigation--visible Polaris-Frame__Navigation--enterActive': sidebar.expanded}"
  id="AppFrameNav">

  <nav class="Polaris-Navigation">
    <div class="Polaris-Navigation__PrimaryNavigation Polaris-Scrollable Polaris-Scrollable--vertical"
      data-polaris-scrollable="true">

      <ul class="Polaris-Navigation__Section">
        <li class="Polaris-Navigation__ListItem">
          <a class="Polaris-Navigation__Item" [routerLink]="['/pwa']" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact:true}">
            <div class="Polaris-Navigation__Icon">
              <span class="Polaris-Icon">
                <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                  <path fill="currentColor" d="M7 13h6v6H7z"></path>
                  <path
                    d="M19.664 8.252l-9-8a1 1 0 0 0-1.328 0L8 1.44V1a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v5.773L.336 8.252a1.001 1.001 0 0 0 1.328 1.496L2 9.449V19a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V9.449l.336.299a.997.997 0 0 0 1.411-.083 1.001 1.001 0 0 0-.083-1.413zM16 18h-2v-5a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v5H4V7.671l6-5.333 6 5.333V18zm-8 0v-4h4v4H8zM4 2h2v1.218L4 4.996V2z"
                    fill-rule="evenodd"></path>
                </svg>
              </span>
            </div>
            <span class="Polaris-Navigation__Text">Home</span>
          </a>
        </li>

        <li class="Polaris-Navigation__SectionHeading mt-3">
          <span class="Polaris-Navigation__Text">PWA</span>
        </li>

        <li class="Polaris-Navigation__ListItem">
          <a class="Polaris-Navigation__Item" [routerLink]="['/pwa/configuration']" routerLinkActive="active">
            <div class="Polaris-Navigation__Icon">
              <span class="Polaris-Icon">
                <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                  <path d="M16 8c-1.103 0-2-.897-2-2h4c0 1.103-.897 2-2 2zm0 6H4v-4c1.193 0 2.267-.525 3-1.357C7.733
                    9.475 8.807 10 10 10s2.267-.525 3-1.357c.733.832 1.807 1.357 3 1.357v4zm-3.28
                    4H7.28c.358-.702.537-1.434.628-2h4.184c.09.566.27 1.298.627 2zM12 6c0 1.103-.897 2-2
                    2s-2-.897-2-2h4zM2 6h4c0 1.103-.897 2-2 2s-2-.897-2-2zm1.618-4h12.764l1 2H2.618l1-2zm16.277
                    2.553l-2-4C17.725.213 17.38 0 17 0H3c-.38 0-.725.214-.895.553l-2 4C.035 4.69 0 4.845 0 5v1c0 1.474.81
                    2.75 2 3.444V15c0 .552.447 1 1 1h2.87c-.156.747-.507 1.7-1.317 2.105-.415.208-.633.673-.527
                    1.125.108.45.51.77.974.77h10c.464 0
                    .866-.32.974-.77.106-.452-.112-.917-.527-1.125-.8-.4-1.153-1.356-1.313-2.105H17c.553 0 1-.448
                    1-1V9.444c1.19-.694 2-1.97 2-3.444V5c0-.155-.036-.31-.105-.447z" />

                </svg>
              </span>
            </div>
            <span class="Polaris-Navigation__Text">Configuration</span>
          </a>

          <div class="nav-item-submenu" *ngIf="sidebar.showFullApp">
            <div>
              <div class="side-nav-wrapper">

                <ul class="side-nav">
                  <!-- <li class="nav-item">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/pwa/configuration/general']" routerLinkActive="active">
                        <span class="nav-label">
                          General
                        </span>
                      </a>
                    </div>
                  </li> -->

                  <!-- <li class="nav-item" *ngIf="sidebar.showFullApp">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/pwa/configuration/caching']" routerLinkActive="active">
                        <span class="nav-label">
                          Speed booster
                        </span>
                      </a>
                    </div>
                  </li> -->

                  <li class="nav-item" *ngIf="sidebar.showFullApp">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/pwa/configuration/offline-page']" routerLinkActive="active">
                        <span class="nav-label">
                          Offline page
                        </span>
                      </a>
                    </div>
                  </li>

                  <li class="nav-item" *ngIf="sidebar.showFullApp">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/pwa/configuration/connection-status-banner']"
                        routerLinkActive="active">
                        <span class="nav-label">
                          Connection status banner
                        </span>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>

        <li class="Polaris-Navigation__ListItem">
          <a class="Polaris-Navigation__Item" [routerLink]="['/pwa/install-popups']" routerLinkActive="active">
            <div class="Polaris-Navigation__Icon">
              <span class="Polaris-Icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path fill="currentColor" d="M1 1h18v10H1V1z"></path>
                  <path
                    d="M13 14H2v-2h16v2h-5zm-.606 4H7.606c.16-.522.295-1.182.357-2h4.074c.062.818.196 1.478.357 2zM2 10V2h16v8H2zM19 0H1C.448 0 0 .447 0 1v14c0 .553.448 1 1 1h4.95c-.156 1.657-.66 2.293-.658 2.293-.285.286-.37.716-.216 1.09S5.596 20 6 20h8c.39 0 .734-.242.897-.598s.09-.788-.166-1.084c-.004-.007-.52-.64-.68-2.318H19c.552 0 1-.447 1-1V1c0-.553-.448-1-1-1z">
                  </path>
                </svg>
              </span>
            </div>
            <span class="Polaris-Navigation__Text">Install popups</span>
          </a>

          <div class="nav-item-submenu">
            <div>
              <div class="side-nav-wrapper">

                <ul class="side-nav">
                  <li class="nav-item">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/pwa/install-popups/android-settings']"
                        routerLinkActive="active">
                        <span class="nav-label">
                          Android configuration
                        </span>
                      </a>
                    </div>
                  </li>
                  <li class="nav-item">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/pwa/install-popups/ios-settings']"
                        routerLinkActive="active">
                        <span class="nav-label">
                          IOS configuration
                        </span>
                      </a>
                    </div>
                  </li>
                  <!-- <li class="nav-item">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/pwa/install-popups/mini-info-bar']"
                        routerLinkActive="active">
                        <span class="nav-label">
                          Mini-info Bar
                        </span>
                      </a>
                    </div>
                  </li>
                  <li class="nav-item">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/pwa/install-popups/install-modal']"
                        routerLinkActive="active">
                        <span class="nav-label">
                          Install Modal
                        </span>
                      </a>
                    </div>
                  </li> -->
                  <li class="nav-item">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/pwa/install-popups/triggers']" routerLinkActive="active">
                        <span class="nav-label">
                          Triggers
                        </span>
                      </a>
                    </div>
                  </li>
                  <li class="nav-item">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/pwa/install-popups/advanced-configuration']"
                        routerLinkActive="active">
                        <span class="nav-label">
                          Advanced configuration
                        </span>
                      </a>
                    </div>
                  </li>
                  <li class="nav-item">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/pwa/install-popups/loader-configuration']"
                        routerLinkActive="active">
                        <span class="nav-label">
                          Loader configuration
                        </span>
                      </a>
                    </div>
                  </li>
                  <li class="nav-item">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/pwa/install-popups/refresh-configuration']"
                        routerLinkActive="active">
                        <span class="nav-label">
                          Refresh configuration
                        </span>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>

        <!-- *ngIf="sidebar.showFullApp" -->
        <li class="Polaris-Navigation__ListItem">
          <a class="Polaris-Navigation__Item" [routerLink]="['/pwa/analytics']" routerLinkActive="active">
            <div class="Polaris-Navigation__Icon">
              <span class="Polaris-Icon">
                <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                  <path fill="currentColor" d="M7 5h6v10H7z"></path>
                  <path
                    d="M19 18a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2h18zm0-18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h5V5a1 1 0 0 1 1-1h5V1a1 1 0 0 1 1-1h6zm-5 14h4V2h-4v12zm-6 0h4V6H8v8zm-6 0h4v-4H2v4z">
                  </path>
                </svg>
              </span>
            </div>
            <span class="Polaris-Navigation__Text">Analytics</span>
          </a>

          <!-- <div class="nav-item-submenu">
            <div>
              <div class="side-nav-wrapper">

                <ul class="side-nav">
                  <li class="nav-item">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/pwa/analytics/dashboard']" routerLinkActive="active">
                        <span class="nav-label">
                          Dashboard
                        </span>
                      </a>
                    </div>
                  </li>
                  <li class="nav-item">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/pwa/analytics/settings']" routerLinkActive="active">
                        <span class="nav-label">
                          Settings
                        </span>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div> -->
        </li>

        <!-- <li class="Polaris-Navigation__ListItem">
          <a class="Polaris-Navigation__Item" [routerLink]="['/pwa/caching']" routerLinkActive="active">
            <div class="Polaris-Navigation__Icon">
              <span class="Polaris-Icon">
                <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5 5v10h10v-8h-2v-2h2.5a1.5 1.5 0 0 1 1.5 1.5v9a1.5 1.5 0 0 1-1.5 1.5h-11a1.5 1.5 0 0 1-1.5-1.5v-11a1.5 1.5 0 0 1 1.5-1.5h3.293a1.5 1.5 0 0 1 1.06.44l1.708 1.706a1.5 1.5 0 0 1 .439 1.061v3.379l.793-.793a1 1 0 1 1 1.414 1.414l-2.5 2.5a1 1 0 0 1-1.414 0l-2.5-2.5a1 1 0 0 1 1.414-1.414l.793.793v-3.172l-1.414-1.414h-2.586z" />
                </svg>
              </span>
            </div>
            <span class="Polaris-Navigation__Text">Caching</span>
          </a>
        </li> -->



        <li class="Polaris-Navigation__SectionHeading mt-3">
          <span class="Polaris-Navigation__Text">Push Notifications</span>
        </li>

        <li class="Polaris-Navigation__ListItem">
          <a class="Polaris-Navigation__Item" [routerLink]="['/pwa/push-notifications']" routerLinkActive="active">
            <div class="Polaris-Navigation__Icon">
              <span class="Polaris-Icon">
                <svg viewBox="0 0 20 20" class="v3ASA" focusable="false" aria-hidden="true">
                  <path fill="currentColor" d="M9 12H4.5C3.1 12 2 10.901 2 9.5 2 8.1 3.1 7 4.5 7H9v5z"></path>
                  <path
                    d="M18 14.6c-2-1.5-4.9-2.3-7-2.6V6.901c2.1-.2 5-1.101 7-2.601v10.3zM9 18H7.651L5.6 14H9v4zm0-6H4.5C3.1 12 2 10.901 2 9.5 2 8.1 3.1 7 4.5 7H9v5zM19 0c-.5 0-1 .5-1 1 0 2-5.1 4-8 4H4.5C2 5 0 7 0 9.5c0 2.1 1.4 3.8 3.3 4.3l2.5 5c.3.7 1 1.2 1.8 1.2H9c1.1 0 2-.9 2-2v-3.9c3 .301 7 2.101 7 3.9 0 .5.5 1 1 1s1-.5 1-1V1c0-.5-.5-1-1-1z">
                  </path>
                </svg>
              </span>
            </div>
            <span class="Polaris-Navigation__Text">Push Notifications</span>
          </a>

          <div class="nav-item-submenu">
            <div>
              <div class="side-nav-wrapper">

                <ul class="side-nav">
                  <li class="nav-item">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/pwa/push-notifications/dashboard']"
                        routerLinkActive="active">
                        <span class="nav-label">
                          Dashboard
                        </span>
                      </a>
                    </div>
                  </li>
                  <li class="nav-item">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/pwa/push-notifications/welcomeNotification']"
                        routerLinkActive="active">
                        <span class="nav-label">
                          Welcome Notifications
                        </span>
                      </a>
                    </div>
                  </li>
                  <li class="nav-item">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/pwa/push-notifications/campaigns']"
                        routerLinkActive="active">
                        <span class="nav-label">
                          Campaigns
                        </span>
                      </a>
                    </div>
                  </li>
                  <li class="nav-item" *ngIf="user && user.isSegmentActive == 1">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/pwa/push-notifications/segments']"
                        routerLinkActive="active">
                        <span class="nav-label">
                          Segments
                        </span>
                      </a>
                    </div>
                  </li>
                  <li class="nav-item">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/pwa/push-notifications/abandonedCart']"
                        routerLinkActive="active">
                        <span class="nav-label">
                          Abandoned Cart
                        </span>
                      </a>
                    </div>
                  </li>
                  <!-- <li class="nav-item">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/pwa/push-notifications/backInStock']"
                        routerLinkActive="active">
                        <span class="nav-label">
                          Back In Stock
                        </span>
                      </a>
                    </div>
                  </li>
                  <li class="nav-item">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/pwa/push-notifications/priceDrop']"
                        routerLinkActive="active">
                        <span class="nav-label">
                          Price Drop
                        </span>
                      </a>
                    </div>
                  </li> -->
                  <!-- <li class="nav-item">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/pwa/push-notifications/subscribers']"
                        routerLinkActive="active">
                        <span class="nav-label">
                          Subscribers
                        </span>
                      </a>
                    </div>
                  </li> -->
                  <li class="nav-item">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/pwa/push-notifications/settings']" routerLinkActive="active">
                        <span class="nav-label">
                          Settings
                        </span>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>


      </ul>

      <ul class="Polaris-Navigation__Section side-nav-middle">
        <li class="Polaris-Navigation__SectionHeading">
          <span class="Polaris-Navigation__Text">Support</span>
        </li>

        <li class="Polaris-Navigation__ListItem">
          <a class="Polaris-Navigation__Item" [routerLink]="['/pwa/support']" routerLinkActive="active">
            <div class="Polaris-Navigation__Icon">
              <span class="Polaris-Icon">
                <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                  <path fill="currentColor" d="M9 12H4.5C3.1 12 2 10.901 2 9.5 2 8.1 3.1 7 4.5 7H9v5z"></path>
                  <path
                    d="M18 14.6c-2-1.5-4.9-2.3-7-2.6V6.901c2.1-.2 5-1.101 7-2.601v10.3zM9 18H7.651L5.6 14H9v4zm0-6H4.5C3.1 12 2 10.901 2 9.5 2 8.1 3.1 7 4.5 7H9v5zM19 0c-.5 0-1 .5-1 1 0 2-5.1 4-8 4H4.5C2 5 0 7 0 9.5c0 2.1 1.4 3.8 3.3 4.3l2.5 5c.3.7 1 1.2 1.8 1.2H9c1.1 0 2-.9 2-2v-3.9c3 .301 7 2.101 7 3.9 0 .5.5 1 1 1s1-.5 1-1V1c0-.5-.5-1-1-1z">
                  </path>
                </svg>
              </span>
            </div>
            <span class="Polaris-Navigation__Text">Support</span>
          </a>
        </li>
        <li class="Polaris-Navigation__ListItem" *ngIf="sidebar.showFullApp">
          <a class="Polaris-Navigation__Item" [routerLink]="['/pwa/how-to-install']" routerLinkActive="active">
            <div class="Polaris-Navigation__Icon">
              <span class="Polaris-Icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path
                    d="M2 0H1a1 1 0 0 0-1 1v1a1 1 0 0 0 2 0 1 1 0 0 0 0-2zm4 2h2a1 1 0 0 0 0-2H6a1 1 0 0 0 0 2zm8-2h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2zM8 18H6a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2zm6 0h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2zm5-18h-1a1 1 0 0 0 0 2 1 1 0 0 0 2 0V1a1 1 0 0 0-1-1zm0 17a1 1 0 0 0-1 1 1 1 0 0 0 0 2h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1zM2 18a1 1 0 0 0-2 0v1a1 1 0 0 0 1 1h1a1 1 0 0 0 0-2zm-1-3a1 1 0 0 0 1-1v-2a1 1 0 0 0-2 0v2a1 1 0 0 0 1 1zm0-6a1 1 0 0 0 1-1V6a1 1 0 0 0-2 0v2a1 1 0 0 0 1 1zm18 2a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1zm0-6a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0V6a1 1 0 0 0-1-1zm-5 4h-3V6a1 1 0 0 0-2 0v3H6a1 1 0 0 0 0 2h3v3a1 1 0 0 0 2 0v-3h3a1 1 0 0 0 0-2z"
                    fill="#637381" />
                </svg>
              </span>
            </div>
            <span class="Polaris-Navigation__Text">FAQ</span>
          </a>
        </li>

        <li class="Polaris-Navigation__ListItem">
          <a class="Polaris-Navigation__Item" [routerLink]="['/pwa/integrations']" routerLinkActive="active">
            <div class="Polaris-Navigation__Icon">
              <span class="Polaris-Icon">
                <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                  <path d="M1 1h7v7H1V1zm0 11h7v7H1v-7zm11 0h7v7h-7v-7z" fill="currentColor"></path>
                  <path
                    d="M8 11H1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1zm-1 7H2v-5h5v5zM8 0H1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zM7 7H2V2h5v5zm12 4h-7a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1zm-1 7h-5v-5h5v5zM12 6h2v2a1 1 0 1 0 2 0V6h2a1 1 0 1 0 0-2h-2V2a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2z">
                  </path>
                </svg>
              </span>
            </div>
            <span class="Polaris-Navigation__Text">Integrations</span>
          </a>
        </li>

        <li class="Polaris-Navigation__ListItem">
          <a class="Polaris-Navigation__Item" [routerLink]="['/pwa/settings/account']" routerLinkActive="active">
            <div class="Polaris-Navigation__Icon">
              <span class="Polaris-Icon">
                <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                  <path d="M10 13c-4 0-7 3-7 3l1 3h12l1-3s-3-3-7-3" fill="currentColor"></path>
                  <path
                    d="M17.707 15.293a.999.999 0 0 1 .241 1.023l-1 3A.999.999 0 0 1 16 20H4a1 1 0 0 1-.949-.684l-1-3a.999.999 0 0 1 .242-1.023C2.427 15.158 5.635 12 10 12c4.364 0 7.572 3.158 7.707 3.293zM15.28 18l.562-1.687c-.92-.752-3.155-2.313-5.84-2.313-2.704 0-4.928 1.558-5.844 2.31L4.72 18h10.56zM10 2c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3zm0 8c2.757 0 5-2.243 5-5s-2.243-5-5-5-5 2.243-5 5 2.243 5 5 5">
                  </path>
                </svg>
              </span>
            </div>
            <span class="Polaris-Navigation__Text">Account</span>
          </a>
        </li>

      </ul>

      <ul class="Polaris-Navigation__Section">
        <li class="Polaris-Navigation__ListItem">
          <a class="Polaris-Navigation__Item" [routerLink]="['/pwa/settings']" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact:true}">
            <div class="Polaris-Navigation__Icon">
              <span class="Polaris-Icon">
                <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                  <path fill="currentColor"
                    d="M10 13a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm7-3c0-.53-.064-1.044-.176-1.54L19 7.23l-2.047-3.464-2.106 1.188A6.978 6.978 0 0 0 12 3.292V1H8v2.294a6.99 6.99 0 0 0-2.847 1.662L3.047 3.768 1 7.232 3.176 8.46C3.064 8.955 3 9.47 3 10s.064 1.044.176 1.54L1 12.77l2.047 3.464 2.106-1.188A6.99 6.99 0 0 0 8 16.708V19h4v-2.294a6.99 6.99 0 0 0 2.847-1.662l2.106 1.188L19 12.768l-2.176-1.227c.112-.49.176-1.01.176-1.54z">
                  </path>
                  <path
                    d="M19.492 11.897l-1.56-.88a7.63 7.63 0 0 0 .001-2.035l1.56-.88a1 1 0 0 0 .369-1.38L17.815 3.26a1 1 0 0 0-1.353-.363l-1.49.84A8.077 8.077 0 0 0 13 2.587V1a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v1.586a8.072 8.072 0 0 0-1.97 1.152l-1.492-.84a1 1 0 0 0-1.352.36L.14 6.724a1.004 1.004 0 0 0 .369 1.381l1.55.88C2.02 9.325 2 9.665 2 10s.023.675.068 1.017l-1.56.88a1 1 0 0 0-.369 1.372l2.04 3.46c.27.47.87.63 1.35.36l1.49-.844c.6.48 1.26.87 1.97 1.154V19c0 .552.443 1 1 1h4c.55 0 1-.448 1-1v-1.587c.7-.286 1.37-.675 1.97-1.152l1.49.85a.992.992 0 0 0 1.35-.36l2.047-3.46a1.006 1.006 0 0 0-.369-1.38zm-3.643-3.22c.1.45.15.894.15 1.323s-.05.873-.15 1.322c-.1.43.1.873.48 1.09l1.28.725-1.03 1.742-1.257-.71a.988.988 0 0 0-1.183.15 6.044 6.044 0 0 1-2.44 1.42.99.99 0 0 0-.714.96V18H9v-1.294c0-.443-.29-.833-.714-.96a5.985 5.985 0 0 1-2.44-1.424 1 1 0 0 0-1.184-.15l-1.252.707-1.03-1.75 1.287-.73c.385-.22.58-.66.485-1.09A5.907 5.907 0 0 1 4 10c0-.43.05-.874.152-1.322a1 1 0 0 0-.485-1.09L2.38 6.862 3.41 5.12l1.252.707a.998.998 0 0 0 1.184-.15 6.02 6.02 0 0 1 2.44-1.425A1 1 0 0 0 9 3.294V2h2v1.294c0 .442.29.832.715.958.905.27 1.75.762 2.44 1.426.317.306.8.365 1.183.15l1.253-.708 1.03 1.742-1.28.726a.999.999 0 0 0-.48 1.09zM10 6c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z">
                  </path>
                </svg>
              </span>
            </div>
            <span class="Polaris-Navigation__Text">Settings</span>
          </a>
        </li>
      </ul>

    </div>

  </nav>

  <button type="button" (click)="closeSidebar()" class="Polaris-Frame__NavigationDismiss" aria-hidden="true"
    aria-label="Close navigation" tabindex="-1">
    <span class="Polaris-Icon Polaris-Icon--colorWhite"><svg viewBox="0 0 20 20" class="Polaris-Icon__Svg"
        focusable="false" aria-hidden="true">
        <path
          d="M11.414 10l6.293-6.293a.999.999 0 1 0-1.414-1.414L10 8.586 3.707 2.293a.999.999 0 1 0-1.414 1.414L8.586 10l-6.293 6.293a.999.999 0 1 0 1.414 1.414L10 11.414l6.293 6.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L11.414 10z"
          fill-rule="evenodd"></path>
      </svg>
    </span>
  </button>
</div>

