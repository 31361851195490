import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRedirectComponent } from './auth-redirect/auth-redirect.component';

@NgModule({
  declarations: [AuthRedirectComponent],
  imports: [
    CommonModule
  ]
})
export class AuthModule { }
