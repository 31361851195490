<ng-container *ngIf="showContent">

  <div *ngIf="!error" class="Polaris-Page-Header Polaris-Page-Header--hasActionMenu">
    <div class="Polaris-Page-Header__MainContent">
      <div class="Polaris-Page-Header__TitleActionMenuWrapper">
        <div>
          <div class="Polaris-Header-Title__TitleAndSubtitleWrapper">
            <div class="Polaris-Header-Title">
              <h1 class="Polaris-DisplayText Polaris-DisplayText--sizeLarge">
                Speed booster
              </h1>
            </div>
            <div class="Polaris-Header-Title__SubTitle">
              <p>Setup files you would like to cache in order to speed up your store.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!error" class="Polaris-Page__Content">
    <div *ngIf="!loading" class="Polaris-Layout">
      {{urls | json}}
     
      <div class="Polaris-Layout__AnnotatedSection" style="background-color: white;padding: 16px;">
        <div class="Polaris-Connected spacing" *ngFor="let ul of preCacheFiles; let ix = index">
          <div class="Polaris-Connected__Item Polaris-Connected__Item--primary">
            <div class="Polaris-TextField">
              <input class="Polaris-TextField__Input" type="text" [(ngModel)]="ul.url" placeholder="Enter url">
              <button (click)="deleteIosBenefit(ix)" type="button"
                class="Polaris-TextField__ClearButton">
                <span class="Polaris-Icon Polaris-Icon--colorInkLightest Polaris-Icon--isColored">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path fill-rule="evenodd" fill="#637381"
                      d="M17 4h-3V2c0-1.103-.897-2-2-2H8C6.897 0 6 .897 6 2v2H3a1 1 0 1 0 0 2v13a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V6a1 1 0 1 0 0-2zM5 18h10V6H5v12zM8 4h4V2H8v2zm0 12a1 1 0 0 0 1-1V9a1 1 0 1 0-2 0v6a1 1 0 0 0 1 1m4 0a1 1 0 0 0 1-1V9a1 1 0 1 0-2 0v6a1 1 0 0 0 1 1" />
                  </svg>
                </span>
              </button>
              <div class="Polaris-TextField__Backdrop"></div>
            </div>
          </div>
        </div>
        <div class="add-button">
        <button (click)="addUrl()" type="button" class="Polaris-Button Polaris-Button--primary">
          <span class="Polaris-Button__Content">
            <span class="Polaris-Button__Text">Add</span>
          </span>
        </button>
      </div>
      </div>

      
      <!-- <div class="Polaris-Layout__AnnotationWrapper">
        <div class="Polaris-Layout__Annotation">
          <div class="Polaris-TextContainer">
            <h2 class="Polaris-Heading">Caching</h2>
            <div class="Polaris-Layout__AnnotationDescription">
              <p>
                Choose what files should be always available to your customers, with or without their Internet
                connection.
              </p>
            </div>
          </div>
        </div>
        <div class="Polaris-Layout__AnnotationContent">
          <div class="Polaris-Card">
            <div class="Polaris-Card__Section">

              <fieldset class="Polaris-ChoiceList" aria-invalid="false">
                <legend class="Polaris-ChoiceList__Title">
                  Choose what would you like to be cached
                </legend>

                <ul class="Polaris-ChoiceList__Choices">
                  <li>
                    <div>
                      <label class="Polaris-Choice">
                        <span class="Polaris-Choice__Control">
                          <span class="Polaris-Checkbox">
                            <input type="checkbox" class="Polaris-Checkbox__Input" aria-invalid="false"
                              role="checkbox" [(ngModel)]="config.cache_fonts">
                            <span class="Polaris-Checkbox__Backdrop"></span>
                            <span class="Polaris-Checkbox__Icon">
                              <span class="Polaris-Icon">
                                <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false"
                                  aria-hidden="true">
                                  <path
                                    d="M8.315 13.859l-3.182-3.417a.506.506 0 0 1 0-.684l.643-.683a.437.437 0 0 1 .642 0l2.22 2.393 4.942-5.327a.437.437 0 0 1 .643 0l.643.684a.504.504 0 0 1 0 .683l-5.91 6.35a.437.437 0 0 1-.642 0">
                                  </path>
                                </svg>
                              </span>
                            </span>
                          </span>
                        </span>

                        <span class="Polaris-Choice__Label">
                          Cache Google fonts
                        </span>
                      </label>
                    </div>
                  </li>

                  <li>
                    <div>
                      <label class="Polaris-Choice">
                        <span class="Polaris-Choice__Control">
                          <span class="Polaris-Checkbox">
                            <input type="checkbox" class="Polaris-Checkbox__Input" aria-invalid="false"
                              role="checkbox" [(ngModel)]="config.cache_styles">
                            <span class="Polaris-Checkbox__Backdrop"></span>
                            <span class="Polaris-Checkbox__Icon">
                              <span class="Polaris-Icon">
                                <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false"
                                  aria-hidden="true">
                                  <path
                                    d="M8.315 13.859l-3.182-3.417a.506.506 0 0 1 0-.684l.643-.683a.437.437 0 0 1 .642 0l2.22 2.393 4.942-5.327a.437.437 0 0 1 .643 0l.643.684a.504.504 0 0 1 0 .683l-5.91 6.35a.437.437 0 0 1-.642 0">
                                  </path>
                                </svg>
                              </span>
                            </span>
                          </span>
                        </span>

                        <span class="Polaris-Choice__Label">
                          Cache CSS files with styles
                        </span>
                      </label>
                    </div>
                  </li>

                  <li>
                    <div>
                      <label class="Polaris-Choice">
                        <span class="Polaris-Choice__Control">
                          <span class="Polaris-Checkbox">
                            <input type="checkbox" class="Polaris-Checkbox__Input" aria-invalid="false"
                              role="checkbox" [(ngModel)]="config.cache_images">
                            <span class="Polaris-Checkbox__Backdrop"></span>
                            <span class="Polaris-Checkbox__Icon">
                              <span class="Polaris-Icon">
                                <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false"
                                  aria-hidden="true">
                                  <path
                                    d="M8.315 13.859l-3.182-3.417a.506.506 0 0 1 0-.684l.643-.683a.437.437 0 0 1 .642 0l2.22 2.393 4.942-5.327a.437.437 0 0 1 .643 0l.643.684a.504.504 0 0 1 0 .683l-5.91 6.35a.437.437 0 0 1-.642 0">
                                  </path>
                                </svg>
                              </span>
                            </span>
                          </span>
                        </span>

                        <span class="Polaris-Choice__Label">
                          Cache images
                        </span>
                      </label>
                    </div>
                  </li>

                  <li>
                    <div class="">
                      <div class="Polaris-Labelled__LabelWrapper">
                        <div class="Polaris-Label">
                          <label class="">
                            Max number of images in cache
                          </label>
                        </div>
                      </div>
                      <div class="Polaris-Connected">
                        <div class="Polaris-Connected__Item Polaris-Connected__Item--primary">
                          <div class="Polaris-TextField">
                            <input class="Polaris-TextField__Input" [(ngModel)]="config.cache_images_count">
                            <div class="Polaris-TextField__Backdrop"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div>
                      <label class="Polaris-Choice">
                        <span class="Polaris-Choice__Control">
                          <span class="Polaris-Checkbox">
                            <input type="checkbox" class="Polaris-Checkbox__Input" aria-invalid="false"
                              role="checkbox" [(ngModel)]="config.enable_offline_google_analytics">
                            <span class="Polaris-Checkbox__Backdrop"></span>
                            <span class="Polaris-Checkbox__Icon">
                              <span class="Polaris-Icon">
                                <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false"
                                  aria-hidden="true">
                                  <path
                                    d="M8.315 13.859l-3.182-3.417a.506.506 0 0 1 0-.684l.643-.683a.437.437 0 0 1 .642 0l2.22 2.393 4.942-5.327a.437.437 0 0 1 .643 0l.643.684a.504.504 0 0 1 0 .683l-5.91 6.35a.437.437 0 0 1-.642 0">
                                  </path>
                                </svg>
                              </span>
                            </span>
                          </span>
                        </span>

                        <span class="Polaris-Choice__Label">
                          Enable offline Google Analytics
                        </span>
                      </label>
                    </div>
                  </li>
                </ul>

              </fieldset>

            </div>
          </div>
        </div>
      </div> -->

      <!-- <div class="Polaris-Layout__AnnotatedSection">
        <div class="Polaris-Layout__AnnotationWrapper">
          <div class="Polaris-Layout__Annotation">
            <div class="Polaris-TextContainer">
              <h2 class="Polaris-Heading">Pages that should be always available</h2>
              <div class="Polaris-Layout__AnnotationDescription">
                <p>
                  Here you can add the pages that your customers can view even when they go offline.
                </p>
              </div>
            </div>
          </div>
          <div class="Polaris-Layout__AnnotationContent pre-caching-pages">
            <div class="Polaris-Card">
              <div class="Polaris-Card__Section">

                <div *ngIf="loadingOfPreCachedResources" class="loading-link-resources">
                  <am-loading-spinner></am-loading-spinner>
                </div>

                <ul class="Polaris-ResourceList">
                  <li *ngFor="let page of config.precache_pages; let i = index" class="Polaris-ResourceList__ItemWrapper">
                    <div class="Polaris-ResourceItem">
                      <div class="Polaris-ResourceItem__Container">
                        <div class="Polaris-ResourceItem__Content">
                          <div>{{page.link}}</div>
                        </div>
                        <div class="Polaris-ResourceItem__Actions">
                          <div class="Polaris-ButtonGroup Polaris-ButtonGroup--segmented"
                            data-buttongroup-segmented="true">
                            <div class="Polaris-ButtonGroup__Item" (click)="removePreCachedItem(i)">
                              <div class="Polaris-Button Polaris-Button--sizeSlim" data-polaris-unstyled="true">
                                <span class="Polaris-Button__Content">
                                  <span class="Polaris-Button__Text">Remove</span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li class="add-new-pre-cached-page">
                    <div class="Polaris-ResourceItem">
                      <div class="Polaris-ResourceItem__Container">
                        <div class="Polaris-ResourceItem__Content">
                          <div class="Polaris-TextField">
                            <input class="Polaris-TextField__Input" [(ngModel)]="newPreCachedPage"
                              placeholder="Add your page url">
                            <div class="Polaris-TextField__Backdrop"></div>
                          </div>
                        </div>
                        <div  id="addPageBtn" class="Polaris-ResourceItem__Actions">
                          <div class="Polaris-ButtonGroup Polaris-ButtonGroup--segmented"
                            data-buttongroup-segmented="true">
                            <div class="Polaris-ButtonGroup__Item" (click)="addPreCachedPage(newPreCachedPage)">
                              <div class="Polaris-Button Polaris-Button--primary" data-polaris-unstyled="true">
                                <span class="Polaris-Button__Content">
                                  <span class="Polaris-Button__Text">Add page</span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="Polaris-Choice__HelpText">For example you can add a link to some blog on your store, or some product.</div>
                  </li>

                </ul>

              </div>
            </div>
          </div>
        </div>
      </div> -->

    </div>

    <div class="Polaris-PageActions mt-5">
      <div class="Polaris-Stack Polaris-Stack--spacingTight Polaris-Stack--distributionTrailing">
        <div class="Polaris-Stack__Item">
          <button (click)="save()" type="button" class="Polaris-Button Polaris-Button--primary">
            <span class="Polaris-Button__Content">
              <span class="Polaris-Button__Text">Save</span>
            </span>
          </button>
        </div>
      </div>
    </div>

  </div>

  <am-loading-skeleton *ngIf="loading"></am-loading-skeleton>

  <am-error-page-polaris *ngIf="error"></am-error-page-polaris>

</ng-container>


<am-not-available-please-upgrade *ngIf="!showContent" [plan]="'company'" [app]="'progressifyme'">
</am-not-available-please-upgrade>