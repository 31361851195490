import { Component, OnInit } from '@angular/core';

import { OnboardingService } from '../onboarding.service';

@Component({
  selector: 'am-onboarding-finish',
  templateUrl: './onboarding-finish.component.html',
  styleUrls: ['./onboarding-finish.component.scss']
})
export class OnboardingFinishComponent implements OnInit {

  constructor(
    private onboardingService: OnboardingService
  ) {
    // this.onboardingService.setCurrentPage(9);
    this.onboardingService.setCurrentPage(6);
  }

  ngOnInit() {
  }

}
