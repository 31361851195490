import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AmBuilderService } from '../am-builder.service';
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { ThemeDetails, Theme, PAGE_TYPE } from '../../am-builder.model';

import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'am-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit, OnDestroy {

  themeId: string;
  theme: Theme;
  originalTheme: Theme;

  themeNotSupported = false;
  isLoaded = false;
  isError = false;

  viewportSize = 'editor-preview-small';
  changeViewportSize: any;

  shopDomain: string;

  public route: ActivatedRoute = null;
  public amBuilderService: AmBuilderService = null;
  public cookieService: CookieService = null;
  public location: Location = null;
  public pubsub: NgxPubSubService = null;

  constructor(
    route: ActivatedRoute,
    amBuilderService: AmBuilderService,
    cookieService: CookieService,
    location: Location,
    pubsub: NgxPubSubService,
    private authService: AuthService
  ) {
    this.route = route;
    this.amBuilderService = amBuilderService;
    this.cookieService = cookieService;
    this.location = location;
    this.pubsub = pubsub;

    this.theme = amBuilderService.theme;
  }

  ngOnInit() {// Get shop and token from url
    this.shopDomain = this.route.snapshot.queryParams['shop'];
    let planName = this.route.snapshot.queryParams['plan'];
    const token = this.route.snapshot.queryParams['t'];

    // localStorage.setItem('shopDomain', shopDomain);
    // localStorage.setItem('token', token);

    // Get theme id
    this.themeId = this.route.snapshot.paramMap.get('id');

    const user = this.authService.getUser();
    this.shopDomain = user.shopDomain;
    planName = user.planName;

    this.amBuilderService.builderConfig.currentPage = PAGE_TYPE.PRODUCT_PAGE;

    // Set these values in service
    this.amBuilderService.shopDetails = {
      domain: this.shopDomain,
      planName: planName
    };

    // Load theme content
    this.amBuilderService.loadThemeDetails(this.themeId)
      .subscribe((res) => {
        const shopDetails = res[0];
        const themeDetails = res[1];

        // console.log(res)
        // TODO: Figure out better place for this
        this.amBuilderService.editorDetails = shopDetails;

        this.theme.id = themeDetails.id;
        this.theme.type = themeDetails.theme_type;

        if (!this.amBuilderService.isThemeSupported(this.theme.type)) {
          this.isLoaded = true;
          this.themeNotSupported = true;
          return;
        }

        // this.theme = res;
        this.theme.sections = themeDetails.sections;
        this.theme.sectionContent = themeDetails.content;
        this.theme.global_settings = themeDetails.global_settings;

        // console.log("theme",this.theme);
        this.originalTheme = JSON.parse(JSON.stringify(this.theme));

        // Get theme details like settings.json
        this.amBuilderService.getThemeDetails(this.theme.id)
          .subscribe((response: ThemeDetails) => {
            this.theme.themeDetails.schema = response.schema;
            this.theme.themeDetails.sections = response.sections;

            this.isError = false;
            this.isLoaded = true;
          }, (err) => {
            this.isError = true;
            this.isLoaded = true;
          });

      }, (err) => {

        // If the request fails show error message for them
        // Saying that they can go back to the admin>
        console.log(err);
        this.isError = true;
        this.isLoaded = true;
      });

    // Event for changing the preview size
    this.changeViewportSize = this.pubsub.subscribe('VIEWPORT_CHANGE', (newValue: string) => {
      this.viewportSize = 'editor-preview-' + newValue.toLowerCase();
    });
  }

  ngOnDestroy() {
    this.changeViewportSize.unsubscribe();
  }

}
