<div class="main" style="border-bottom: .0625em solid rgba(225, 227, 229, 1);" *ngIf="!showThemeSetting">
    <div class="item-list theme-settings" (click)="showThemeSettings()">
      {{headerName | replaceName : headerName}}
    </div>

    </div>
    <div class="theme-settings-container">
    <div class="main theme-settings-optins" *ngFor="let item of globalSetts; let i = index" style="border-bottom: .0625em solid rgba(225, 227, 229, 1);">
        <div class="item-list" (click)="showSettingsForm(item)" style="display: flex;align-items: center;height: 100%; font-size: 12px;color: #6d7175;">

            <span style="text-transform: uppercase;">{{item.name | replaceName : item }}</span>

            </div>
        </div>
      </div>