import { Component, OnInit } from '@angular/core';

import { NotifierService } from 'angular-notifier';

import { AuthService } from '../../../auth/auth.service';

import { PwaCaching } from '../pwa-configuration.model';
import { PwaConfigurationService } from '../pwa-configuration.service';

@Component({
  selector: 'am-pwa-configuration-caching',
  templateUrl: './pwa-configuration-caching.component.html',
  styleUrls: ['./pwa-configuration-caching.component.scss']
})
export class PwaConfigurationCachingComponent implements OnInit {

  error: boolean = false;
  loading: boolean = true;
  showContent = true;

  planName: string = 'free';
  config: any = {};

  loadingOfPreCachedResources: boolean = false;
  newPreCachedPage: string = '';

  preCacheFiles: any = [];

  constructor(
    private authService: AuthService,
    private notifier: NotifierService,
    private pwaConfigurationService: PwaConfigurationService,
  ) {
    try {
      const user = this.authService.getUser();
      // || user.planName.toString() === 'hobbyist' || user.planName.toString() === 'lite'
      if (user.planName.toString() === 'free') {
        this.showContent = false;
      }
    } catch (e) {
      console.log(e);
    }
  }

  ngOnInit() {
    if (!this.showContent) {
      return;
    }

    this.pwaConfigurationService.getPreCaching()
      .subscribe((conf) => {
        console.log(conf.precache_files);
        this.config = conf;

        // this.preCacheFiles = conf.precache_files;
        if (conf.precache_files && conf.precache_files.length) {
          for (let i = 0; i < conf.precache_files.length; i++) {

            this.preCacheFiles.push({ url: conf.precache_files[i] })

          }
        } else {
          this.addUrl();
        }
        // this.config.precache_assets = this.config.precache_assets || [];

        this.loading = false;
      }, (err) => {
        this.error = true;
        this.loading = false;
      });
  }

  save() {

    console.log("in save", this.preCacheFiles);
    let arr = [];
    for (let i = 0; i < this.preCacheFiles.length; i++) {

      arr.push(this.preCacheFiles[i]['url'])
    }
    this.pwaConfigurationService.updatePreCaching(arr)
      .subscribe(res => {
        console.log(res);
        this.notifier.notify('default', 'Successfully updated.');
      }, err => {
        this.notifier.notify('error', 'Failed saving your configuration, please try again.');
      });
  }

  addPreCachedPage(page: string): void {
    // Loading of the page resources is started
    this.loadingOfPreCachedResources = true;

    const newPage = {
      link: page,
      files: []
    };

    // Get list of resources that should be cached for this page
    this.pwaConfigurationService.getPreCacheFilesFromPage(page)
      .subscribe((links: Array<string>) => {
        // Add links to the pre cache files
        newPage.files = links;
        this.config.precache_pages.push(newPage);
        this.newPreCachedPage = '';
      }, (err) => {
        this.notifier.notify('error', 'Failed getting page details, please try again.');
        this.loadingOfPreCachedResources = false;
      }, () => {

        // Loading of the page resources is finished
        this.loadingOfPreCachedResources = false;
      });
  }

  removePreCachedItem(index: number): void {
    this.config.precache_pages.splice(index, 1);
  }

  addUrl() {

    this.preCacheFiles.push({ url: '' })
  }

  deleteIosBenefit(index) {
    this.preCacheFiles.splice(this.preCacheFiles.indexOf(index), 1);
  }

}
