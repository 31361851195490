import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { AmBuilderService } from '../../../am-builder.service';
import { Theme } from '../../../../am-builder.model';

@Component({
  selector: 'am-builder-component-list-item',
  templateUrl: './builder-component-list-item.component.html',
  styleUrls: ['./builder-component-list-item.component.scss']
})
export class BuilderComponentListItemComponent implements OnInit {

  @Input()
  type: string;

  @Input()
  id: string;

  @Input()
  name: any;

  @Input()
  showRemoveSection = false;

  @Output() componentSelected = new EventEmitter();

  itemDetails: any;

  theme: Theme;
  showEditComponent = false;

  constructor(
    private amBuilderService: AmBuilderService
  ) {
    this.theme = this.amBuilderService.theme;
  }

  getItemDetails(id: string): any {
    // Get it from the file settions-sections
    const details = this.amBuilderService.getSectionDetails(id);
    const type = details.type || this.type;

    const components = {

      'custom': {
        name: this.name,
        draggable: false
      },

      'ADD_SECTION_ITEM': {
        name: 'Add Reviews',
        draggable: false
      },

      'header': {
        name: 'Header',
        draggable: false
      },
      'footer': {
        name: 'Footer',
        draggable: false
      },
      'sidebar': {
        name: 'Sidebar',
        draggable: false
      },
      'announcement-bar': {
        name: 'Announcement',
        draggable: false
      },
      'action-bar': {
        name: 'Action bar',
        draggable: false
      },


      'article': {
        name: 'Posts',
        draggable: false
      },
      'blog': {
        name: 'Blog pages',
        draggable: false
      },
      'collection-list': {
        name: 'Collections list page',
        draggable: false
      },
      'collection-template': {
        name: 'Collection pages',
        draggable: false
      },
      'product-template': {
        name: 'Product pages',
        draggable: false
      },

      'story-page-template': {
        name: 'Page',
        draggable: true
      }
    };

    if (!components[type]) {
      // TODO: Fix this to do a better job in getting type of component
      // TODO: Fix bug when type has - in normal name, for example featured-product-123
      const realType = this.theme.sections[id].type;

      const sectionDetails = this.theme.themeDetails.sections[realType] || {};
      // console.log(sectionDetails);
      const name = sectionDetails.name || details.name || type;

      return {
        name: name,
        draggable: true
      };
    }

    return components[type];
  }

  ngOnInit() {
    // Get name
    if (this.name && this.name.en) {
      this.name = this.name.en;
    }

    this.itemDetails = this.getItemDetails(this.id);
  }

  openDetails(): void {
    this.showEditComponent = true;

    this.componentSelected.emit({
      type: this.type,
      id: this.id,
      showRemoveSection: this.showRemoveSection
    });
  }

  sectionHasContent(sectionType, sectionId): boolean {
    if (sectionType === 'custom') {
      return true;
    }

    // Try to find configuration for this sectionId
    const sectionConf = this.theme.sections[sectionId];
    // Get type of this configuration
    if (sectionConf && sectionConf.type) {
      sectionType = sectionConf.type;
    }

    if (this.theme && this.theme.themeDetails &&
      this.theme.themeDetails.sections &&
      this.theme.themeDetails.sections[sectionType] &&
      this.theme.themeDetails.sections[sectionType].settings) {
      return true;
    }
    return false;
  }

}
