import { Component, OnInit,Output,EventEmitter, Input } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { Constants } from '../../classes/constant';
import { Subscription } from 'rxjs';
import { FormDataPrepareService } from '../../services/form-data-prepare.service';
import { AmBuilderService } from '../../../am-builder.service';

@Component({
  selector: 'am-dynamic-sidebar-v2',
  templateUrl: './dynamic-sidebar-v2.component.html',
  styleUrls: ['./dynamic-sidebar-v2.component.scss']
})
export class DynamicSidebarV2Component implements OnInit {


  iconObj = {

    image_banner : `<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.5 1a1.5 1.5 0 0 0-1.5 1.5v15a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-15a1.5 1.5 0 0 0-1.5-1.5h-15zm5 3.5c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm8.999 12.5h-13.002c-.41 0-.64-.46-.4-.79l3.553-4.051c.19-.21.52-.21.72-.01l1.63 1.851 3.06-4.781a.5.5 0 0 1 .84.02l4.039 7.011c.18.34-.06.75-.44.75z"/></svg>`,
    rich_text : `<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M2.5 1a1.5 1.5 0 0 0-1.5 1.5v15a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-15a1.5 1.5 0 0 0-1.5-1.5h-15zm13.5 4h-12v2h12v-2zm-12 4h12v2h-12v-2zm6 4h-6v2h6v-2z"/></svg>`,
    featured_collection : `<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M6.948.001c.394 0 .772.159 1.052.439l1.477 1.68-3.638 4.12a3.568 3.568 0 0 0-.872 2.33v9.43h-2.48a1.48 1.48 0 0 1-1.051-.44 1.507 1.507 0 0 1-.436-1.06v-9.88a1.497 1.497 0 0 1 .377-1l3.48-4 1.04-1.18a1.48 1.48 0 0 1 1.052-.439zm7.092 2.439 4.58 5.13c.247.275.383.631.381 1v9.93c0 .399-.159.78-.441 1.062a1.51 1.51 0 0 1-1.065.439h-9.039a1.509 1.509 0 0 1-1.033-.457 1.497 1.497 0 0 1-.423-1.044v-9.88a1.487 1.487 0 0 1 .382-1l3.524-4.001 1.005-1.18a1.51 1.51 0 0 1 2.128 0zm-1.9 5.807a1.51 1.51 0 0 0 1.901-.186 1.497 1.497 0 0 0-.489-2.447 1.512 1.512 0 0 0-1.641.325 1.498 1.498 0 0 0 .228 2.308z"/></svg>`,
    collage : `<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 1a1 1 0 0 0-1 1v2h2v-1h1v-2h-2zm16 0a1 1 0 0 1 1 1v2h-2v-1h-1v-2h2zm-17 5v8h18v-8h-18zm1 13a1 1 0 0 1-1-1v-2h2v1h1v2h-2zm17-1a1 1 0 0 1-1 1h-2v-2h1v-1h2v2zm-13-1h3v2h-3v-2zm8 0h-3v2h3v-2zm0-16v2h-3v-2h3zm-5 2v-2h-3v2h3z"/></svg>`,
    video : `<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 20c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm-2-13.138v6.276a.5.5 0 0 0 .748.434l5.492-3.138a.5.5 0 0 0 0-.868l-5.492-3.139a.5.5 0 0 0-.748.435z"/></svg>`,
    multicolumn : `<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-16a2 2 0 0 0-2-2h-16Zm16 2h-4v16h4v-16Zm-10 0h4v16h-4v-16Zm-2 0h-4v16h4v-16Z"/></svg>`
  }


  @Input() section : any = {}

  @Output() showSectionForm = new EventEmitter();
  @Output() dropItem = new EventEmitter();
  @Output() showSettingForm = new EventEmitter();
  @Output() panelSetting = new EventEmitter();
  @Output() addSectionBlock = new EventEmitter();



  @Input() parentIndex = -1;

  newSidebarMenu = [
    {
      title : "Image Banner",
      giconName:'image',
      type : "image-banner",
      isPanelOpened : false,
      blocks : [
        {
          block_title : "Button",
          type : "button",
          icon:"crop_free",
          settings : {
            button_label_1: "Shop all",
            button_label_2: "",
            button_link_1: "shopify://collections/all",
            button_link_2: "",
            button_style_secondary_1: true,
            button_style_secondary_2: false
          }
        },
        {
          block_title : "Image Banner",
          type : "heading",
          icon:"crop_free",
          settings : {
            heading: "Image banner",
            heading_size: "h0"
          }
        },
        {
          block_title : "Text",
          type : "text",
          icon:"crop_free",
            settings : {
              text: "Give customers details about the banner image(s) or content on the template.",
              text_style: "body"
            }
        }
      ]
    },
    {
      title : "Featured Collection",
      giconName:'bookmarks',
      type : "featured-collection",
     
    },
    {
      title : "Rich Text",
      giconName:'article',
      type : "rich-text",
      isPanelOpened : false,
      blocks : [
        {
          block_title : "Heading",
          type : "heading",
          icon:"crop_free",
          settings : {
            heading: "Talk about your brand",
            // heading_size: "h1"
          }
        },
        {
          block_title : "Text",
          type : "text",
          icon:"crop_free",
            settings : {
              text: "Give customers details about the banner image(s) or content on the template.",
            }
        }
      ]

    },
    {
      title : "Video",
      giconName:'play_circle',
      type : "video",
    }
   
  ]

  sidebarDetails = [
    {
      name:'Rich Text',
      giconName:'article',
      child:[
              {
                name:"One",
                icon:"crop_free"
              },
              {
                name:"Two",
                icon:"crop_free"
              },
              {
                name:"Three",
                icon:"crop_free"
              },
            ]
    },
    {
      name:'Featured Collection',
      giconName:'bookmarks',
    },
    {
      name:'Image Banner',
      giconName:'image',
      child:[
        {
          name:"One",
          icon:"crop_free"
        },
        {
          name:"Two",
          icon:"crop_free"
        }
      ]
    },
    {
      name:'Collage',
      giconName:'view_compact',
      child:[
        {
          name:"One",
          icon:"crop_free"
        },
        {
          name:"Two",
          icon:"crop_free"
        },
        {
          name:"Three",
          icon:"crop_free"
        },
        {
          name:"Four",
          icon:"crop_free"
        },
      ]
    },
    {
      name:'Video',
      giconName:'play_circle',
    },
    {
      name:'Multicolumn',
      giconName:'space_dashboard',
      child:[
         {
          name:"One",
          icon:"crop_free"
        },
        {
          name:"Two",
          icon:"crop_free"
        },
        {
          name:"Three",
          icon:"crop_free"
        },
      ]

    },
    {
      name:'Add Section',
      giconName:'add_circle',
      child:[
        {
          name:"One",
          icon:"crop_free"
        }
      ]
    }, 
  ];
  isPanelOpened:boolean;
  targetArray:any;
  routerArray : any = [];

  @Input() defineObj = {};

  @Input() headerObj : any = {};

  @Input() footerObj : any  = {};

  themeSections : any = [
    'Blog Post',
    'Collage',
    'Multicolumn',
    'Video',
    'Collection',
    'Product'
  ];
  @Input() globalSetts : any =  {}
 
  subscription : Subscription;

  showThemeSetting = false;
  constructor(    private pubsub: NgxPubSubService,
    public data_prepare : FormDataPrepareService,
    public amBuilderService: AmBuilderService = null

    ) { }

  ngOnInit(): void {



    //console.log("*********reinitialising********",this.section)
    // if(!this.headerObj.sections){

    //   let tmp = {
    //     sections : {
    //       'header' : this.headerObj['header'],
    //       'announcement-bar' : this.headerObj['announcement-bar'],
          
    //     },
    //     'order' : this.headerObj['order']
    //   }
    //   this.headerObj = tmp;
    // }
    // if(!this.footerObj.sections){
    //   let tnp = {
    //     sections : {
    //       'footer' : this.footerObj['footer']
  
    //     },
    //     'order' : this.footerObj['order']
    //   }
    //   this.footerObj = tnp;
    // }
    // //console.log(this.section, this.headerObj, this.footerObj);
    // // if(this.parentIndex != -1){
    // //   this.openPanel(this.section.order[this.parentIndex],true);
    // // }
  }

  drop(event: CdkDragDrop<string[]>) {
    let obj = {
      order : this.section.order,
      prevIndex : event.previousIndex,
      currIndex : event.currentIndex
    }
    moveItemInArray(this.section.order, event.previousIndex, event.currentIndex);

    // this.pubsub.publishEvent('REFRESH_IFRAME_EVENT', {});
    localStorage.setItem("uData",JSON.stringify(this.section));

    this.pubsub.publishEvent('REFRESH_IFRAME_EVENT', this.amBuilderService.builderConfig.currentPage);

    //console.log("dynamic",this.section.order);
    // this.dropItem.emit(obj)
  }

  dropChild(event: CdkDragDrop<string[]>,index:any,title){

    let obj = {
      order : this.section.sections[title].block_order,
      prevIndex : event.previousIndex,
      currIndex : event.currentIndex
    }
    this.targetArray = this.section.sections[title].block_order
    moveItemInArray(this.section.sections[title].block_order, event.previousIndex, event.currentIndex);
    localStorage.setItem("uData",JSON.stringify(this.section));
    this.pubsub.publishEvent('REFRESH_IFRAME_EVENT', {});

    this.dropItem.emit(obj)
    // moveItemInArray(this.targetArray, event.previousIndex, event.currentIndex);
  }
  openPanel(item,flag) {

    this.section.sections[item]['isPanelOpened'] = flag;
    // //console.log(this.section.sections[item])

  }
  closePanel(item,flag) {

    this.section.sections[item]['isPanelOpened'] = flag;

  }

  //Make one function for this parent & child

  showParentForm($event){

    //console.log("showParentForm",$event);
   this.showSectionForm.emit($event);
  }

  showChildForm(section,subsection,settings) {

    this.routerArray = [];
    this.routerArray.push(section);
    this.routerArray.push(subsection);

    let obj = {
      route : this.routerArray,
      settings : settings
    }
    // //console.log(section,subsection,settings,this.routerArray);
    // this.showSectionForm.emit(obj);
  }

  disableSection(section){

    // //console.log("disableSection",section);
    this.section.sections[section]['disabled'] = true;
  }
  disableSubSection(section,subsection){

    // //console.log("disableSection",section,subsection,this.section.sections[section].blocks[subsection]);
    this.section.sections[section].blocks[subsection]['disabled'] = true;
  }

  openHeaderPanel(item,flag) {

    this.headerObj.sections[item]['isPanelOpened'] = flag;
    // //console.log(this.section.sections[item])

  }
  closeHeaderPanel(item,flag) {

    this.headerObj.sections[item]['isPanelOpened'] = flag;

  }

  openFooterPanel(item,flag) {

    this.footerObj.sections[item]['isPanelOpened'] = flag;
    // //console.log(this.section.sections[item])

  }
  closeFooterPanel(item,flag) {

    this.footerObj.sections[item]['isPanelOpened'] = flag;

  }

  panelOpen($event){

    //console.log("main",$event);
    this.panelSetting.emit($event);


  }
 
  emitBlock($event){

    this.addSectionBlock.emit($event);
  }
}
