import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';

import { AES, enc } from 'crypto-js';

import { CookieService } from 'ngx-cookie-service';

import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptService implements HttpInterceptor {

  KEY = 'test123';

  constructor(
    private cookieService: CookieService,
    private authService: AuthService
  ) { }

  isInternalApiRequest(url): boolean {
    if (url.indexOf(environment.baseUrl) > -1 || url.indexOf(environment.basePWAUrl) > -1) {
      return true;
    }
    return false;
  }

  // Intercept request and add token
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const url = request.url;
    if (this.isInternalApiRequest(url)) {

      const auth = this.authService.getToken();
      // Add auth token to the header of requests
      request = request.clone({
        setHeaders: {
          'x-access-token': auth
        }
      });

      request = request.clone({
        setHeaders: {
          'x-ampify-payload': 'normal'
        }
      });

      // Encrypt payload

      // const payload = this.cookieService.get('ampify-x-payload');
      // if (payload === 'plain_and_simple') {
      //   request = request.clone({
      //     setHeaders: {
      //       'x-ampify-payload': payload
      //     }
      //   });
      // } else {
      //   // Crypt payload
      //   var msg = AES.encrypt(JSON.stringify(request.body), this.KEY).toString();
      //   request = request.clone({
      //     setHeaders: {
      //       'x-ampify-payload': 'prod'
      //     },
      //     body: {
      //       data: msg
      //     }
      //   });
      // }
    }

    return next.handle(request)
      .pipe(
        map(event => {
          if (event instanceof HttpResponse) {

            // Decrypt payload

            // if (this.isInternalApiRequest(url)) {
            //   if (event.body && event.body.data) {
            //     var originalText = JSON.stringify(event.body.data);
            //     try {
            //       var bytes = AES.decrypt(event.body.data, this.KEY);
            //       originalText = bytes.toString(enc.Utf8);
            //     } catch (e) {
            //       console.log(e);
            //     }
            //     try {
            //       event = event.clone({
            //         body: JSON.parse(originalText)
            //       });
            //     } catch (e) {
            //       console.log(e);
            //     }
            //   }
            // }
            return event;
          }
        }, error => {
        })
      );
  }
}
