<div id="preview-image-container" class="aspect-ratio aspect-ratio--reset-z-index">
  <div class="aspect-ratio__content next-grid next-grid--center-both">
    <div *ngIf="imageUrl">
      <div id="upload-image-progress" class="ui top attached progress"
        [ngStyle]="{'height': (percentageUpload=='100%') ? '0' : '5px'}">
        <div [ngStyle]="{'width': uploadProgress}" class="bar"></div>
      </div>
      <img id="preview-image" src="{{getImageUrl(imageUrl)}}" />

      <span class="button-group">
        <button id="changeImageBtn" (click)="fileInput.click()" class="button secondary">Change</button>
        <button id="removeImageBtn" (click)="removeImage()" class="button secondary">Remove</button>
      </span>
    </div>

    <div *ngIf="!imageUrl" class="no-image">
      <button id="uploadImageBtn" (click)="fileInput.click()">Upload image</button>
    </div>

    <input #fileInput type="file" accept="image/*" (change)="onFileChanged($event)" onclick="this.value = null">

  </div>
</div>