<div class="Polaris-Frame__Navigation"
  [ngClass]="{'Polaris-Frame__Navigation--visible Polaris-Frame__Navigation--enterActive': sidebar.expanded}"
  id="AppFrameNav">

  <nav class="Polaris-Navigation">
    <div class="Polaris-Navigation__PrimaryNavigation Polaris-Scrollable Polaris-Scrollable--vertical"
      data-polaris-scrollable="true">

      <ul class="Polaris-Navigation__Section">

        <li class="Polaris-Navigation__ListItem">
          <a class="Polaris-Navigation__Item" [routerLink]="['/amp']" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact:true}">
            <div class="Polaris-Navigation__Icon">
              <span class="Polaris-Icon">
                <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                  <path fill="currentColor" d="M7 13h6v6H7z"></path>
                  <path
                    d="M19.664 8.252l-9-8a1 1 0 0 0-1.328 0L8 1.44V1a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v5.773L.336 8.252a1.001 1.001 0 0 0 1.328 1.496L2 9.449V19a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V9.449l.336.299a.997.997 0 0 0 1.411-.083 1.001 1.001 0 0 0-.083-1.413zM16 18h-2v-5a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v5H4V7.671l6-5.333 6 5.333V18zm-8 0v-4h4v4H8zM4 2h2v1.218L4 4.996V2z"
                    fill-rule="evenodd"></path>
                </svg>
              </span>
            </div>
            <span class="Polaris-Navigation__Text">Home</span>
          </a>
        </li>

        <li class="Polaris-Navigation__ListItem">
          <a class="Polaris-Navigation__Item" [routerLink]="['/amp/themes']" routerLinkActive="active">
            <div class="Polaris-Navigation__Icon">
              <span class="Polaris-Icon">
                <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                  <path d="M16 8c-1.103 0-2-.897-2-2h4c0 1.103-.897 2-2 2zm0 6H4v-4c1.193 0 2.267-.525 3-1.357C7.733
                    9.475 8.807 10 10 10s2.267-.525 3-1.357c.733.832 1.807 1.357 3 1.357v4zm-3.28
                    4H7.28c.358-.702.537-1.434.628-2h4.184c.09.566.27 1.298.627 2zM12 6c0 1.103-.897 2-2
                    2s-2-.897-2-2h4zM2 6h4c0 1.103-.897 2-2 2s-2-.897-2-2zm1.618-4h12.764l1 2H2.618l1-2zm16.277
                    2.553l-2-4C17.725.213 17.38 0 17 0H3c-.38 0-.725.214-.895.553l-2 4C.035 4.69 0 4.845 0 5v1c0 1.474.81
                    2.75 2 3.444V15c0 .552.447 1 1 1h2.87c-.156.747-.507 1.7-1.317 2.105-.415.208-.633.673-.527
                    1.125.108.45.51.77.974.77h10c.464 0
                    .866-.32.974-.77.106-.452-.112-.917-.527-1.125-.8-.4-1.153-1.356-1.313-2.105H17c.553 0 1-.448
                    1-1V9.444c1.19-.694 2-1.97 2-3.444V5c0-.155-.036-.31-.105-.447z" />
                </svg>
              </span>
            </div>
            <span class="Polaris-Navigation__Text">AMP Themes</span>
          </a>
        </li>

        <li class="Polaris-Navigation__ListItem">
          <a class="Polaris-Navigation__Item" [routerLink]="['/amp/pages']" routerLinkActive="active">
            <div class="Polaris-Navigation__Icon">
              <span class="Polaris-Icon">
                <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                  <g id="surface1">
                    <path
                      d="M 4.945312 11.671875 L 7.953125 11.671875 L 6.855469 19.988281 L 15.046875 7.835938 L 12.257812 7.835938 L 13.457031 0 Z M 4.945312 11.671875 " />
                  </g>
                </svg>
              </span>
            </div>
            <span class="Polaris-Navigation__Text">AMP Pages</span>
          </a>

          <div class="nav-item-submenu">
            <div>
              <div class="side-nav-wrapper">

                <ul class="side-nav">
                  <li class="nav-item">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/amp/pages/products']" routerLinkActive="active">
                        <span class="nav-label">
                          Products
                        </span>
                      </a>
                    </div>
                  </li>
                  <li class="nav-item">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/amp/pages/collections']" routerLinkActive="active">
                        <span class="nav-label">
                          Collections
                        </span>
                      </a>
                    </div>
                  </li>
                  <li class="nav-item">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/amp/pages/blogs']" routerLinkActive="active">
                        <span class="nav-label">
                          Blogs
                        </span>
                      </a>
                    </div>
                  </li>
                  <li class="nav-item">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/amp/pages/articles']" routerLinkActive="active">
                        <span class="nav-label">
                          Articles
                        </span>
                      </a>
                    </div>
                  </li>
                  <li class="nav-item">
                    <div class="nav-item-content">
                      <a class="nav-link" [routerLink]="['/amp/pages/pages']" routerLinkActive="active">
                        <span class="nav-label">
                          Pages
                        </span>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>

        <li class="Polaris-Navigation__ListItem">
          <a class="Polaris-Navigation__Item" [routerLink]="['/amp/settings/visibility']" routerLinkActive="active">
            <div class="Polaris-Navigation__Icon">
              <span class="Polaris-Icon">
                <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                  <path
                    d="M17.928 9.628C17.836 9.399 15.611 4 9.999 4S2.162 9.399 2.07 9.628a1.017 1.017 0 0 0 0 .744C2.162 10.601 4.387 16 9.999 16s7.837-5.399 7.929-5.628a1.017 1.017 0 0 0 0-.744zM9.999 14a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-6A2 2 0 1 0 10 12.001 2 2 0 0 0 10 8z">
                  </path>
                </svg>
              </span>
            </div>
            <span class="Polaris-Navigation__Text">Page visibility</span>
          </a>
        </li>

        <li class="Polaris-Navigation__ListItem">
          <a class="Polaris-Navigation__Item" [routerLink]="['/amp/settings/domain']" routerLinkActive="active">
            <div class="Polaris-Navigation__Icon">
              <span class="Polaris-Icon">
                <svg viewBox="0 0 20 20">
                  <path
                    d="M19.993 16.19c-.054.444-.396.8-.839.869l-2.168.338-.715 1.947a.999.999 0 0 1-.938.656l-.046-.001a1 1 0 0 1-.92-.742l-1.333-5a.999.999 0 1 1 1.349-1.181l5 2.071c.414.172.663.598.61 1.043zM8.424 6c.464-2.033 1.178-3.358 1.564-3.964.388.606 1.107 1.931 1.573 3.964H8.424zm-2.342 6H2.263A7.959 7.959 0 0 1 2 10c0-.692.097-1.36.263-2h3.82a21.39 21.39 0 0 0-.096 2.004c0 .706.037 1.367.095 1.996zm1.437 5.601A8.031 8.031 0 0 1 3.082 14h3.289a16.294 16.294 0 0 0 1.148 3.601zm.004-15.204c-.415.9-.849 2.102-1.15 3.603H3.082a8.03 8.03 0 0 1 4.441-3.603zM16.917 6h-3.305a16.127 16.127 0 0 0-1.16-3.612A8.029 8.029 0 0 1 16.917 6zM14 10c0-.708-.037-1.371-.097-2h3.834c.165.64.263 1.308.263 2 0 .3-.031.592-.066.883a1.001 1.001 0 0 0 1.987.233c.043-.367.079-.737.079-1.116 0-5.515-4.486-10-10-10S0 4.485 0 10c0 5.514 4.486 10 10 10 0 0 .979.004 1.073-.003a1 1 0 0 0 .924-1.07 1.008 1.008 0 0 0-1.07-.925c-.137.011-.926-.002-.926-.002-.383-.59-1.116-1.92-1.585-4H10a1 1 0 0 0 0-2H8.088a19.424 19.424 0 0 1 .004-4h3.803c.066.622.105 1.287.105 2a1 1 0 0 0 2 0z"
                    fill-rule="evenodd"></path>
                </svg>
              </span>
            </div>
            <span class="Polaris-Navigation__Text">Domain configuration</span>
          </a>
        </li>

        <li class="Polaris-Navigation__ListItem">
          <a class="Polaris-Navigation__Item" [routerLink]="['/amp/analytics']" routerLinkActive="active">
            <div class="Polaris-Navigation__Icon">
              <span class="Polaris-Icon">
                <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                  <path fill="currentColor" d="M7 5h6v10H7z"></path>
                  <path
                    d="M19 18a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2h18zm0-18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h5V5a1 1 0 0 1 1-1h5V1a1 1 0 0 1 1-1h6zm-5 14h4V2h-4v12zm-6 0h4V6H8v8zm-6 0h4v-4H2v4z">
                  </path>
                </svg>
              </span>
            </div>
            <span class="Polaris-Navigation__Text">Analytics</span>
          </a>
        </li>

        <li class="Polaris-Navigation__ListItem">
          <a class="Polaris-Navigation__Item" [routerLink]="['/amp/settings/account']" routerLinkActive="active">
            <div class="Polaris-Navigation__Icon">
              <span class="Polaris-Icon">
                <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                  <path d="M10 13c-4 0-7 3-7 3l1 3h12l1-3s-3-3-7-3" fill="currentColor"></path>
                  <path
                    d="M17.707 15.293a.999.999 0 0 1 .241 1.023l-1 3A.999.999 0 0 1 16 20H4a1 1 0 0 1-.949-.684l-1-3a.999.999 0 0 1 .242-1.023C2.427 15.158 5.635 12 10 12c4.364 0 7.572 3.158 7.707 3.293zM15.28 18l.562-1.687c-.92-.752-3.155-2.313-5.84-2.313-2.704 0-4.928 1.558-5.844 2.31L4.72 18h10.56zM10 2c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3zm0 8c2.757 0 5-2.243 5-5s-2.243-5-5-5-5 2.243-5 5 2.243 5 5 5">
                  </path>
                </svg>
              </span>
            </div>
            <span class="Polaris-Navigation__Text">Account</span>
          </a>
        </li>

        <li class="Polaris-Navigation__ListItem">
          <a class="Polaris-Navigation__Item" [routerLink]="['/amp/integrations']" routerLinkActive="active">
            <div class="Polaris-Navigation__Icon">
              <span class="Polaris-Icon">
                <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                  <path d="M1 1h7v7H1V1zm0 11h7v7H1v-7zm11 0h7v7h-7v-7z" fill="currentColor"></path>
                  <path
                    d="M8 11H1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1zm-1 7H2v-5h5v5zM8 0H1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zM7 7H2V2h5v5zm12 4h-7a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1zm-1 7h-5v-5h5v5zM12 6h2v2a1 1 0 1 0 2 0V6h2a1 1 0 1 0 0-2h-2V2a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2z">
                  </path>
                </svg>
              </span>
            </div>
            <span class="Polaris-Navigation__Text">Integrations</span>
          </a>
        </li>
        <li class="Polaris-Navigation__ListItem">
          <a class="Polaris-Navigation__Item" [routerLink]="['/amp/app-recommendations']" routerLinkActive="active">
            <div class="Polaris-Navigation__Icon">
              <span class="Polaris-Icon">
                <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                  <path d="M1 1h7v7H1V1zm0 11h7v7H1v-7zm11 0h7v7h-7v-7z" fill="currentColor"></path>
                  <path
                    d="M8 11H1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1zm-1 7H2v-5h5v5zM8 0H1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zM7 7H2V2h5v5zm12 4h-7a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1zm-1 7h-5v-5h5v5zM12 6h2v2a1 1 0 1 0 2 0V6h2a1 1 0 1 0 0-2h-2V2a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2z">
                  </path>
                </svg>
              </span>
            </div>
            <span class="Polaris-Navigation__Text">App Recommendations</span>
          </a>
        </li>
      </ul>

      <ul class="Polaris-Navigation__Section side-nav-middle">
        <li class="Polaris-Navigation__SectionHeading">
          <span class="Polaris-Navigation__Text">Support</span>
        </li>

        <li class="Polaris-Navigation__ListItem">
          <a class="Polaris-Navigation__Item" [routerLink]="['/amp/support']" routerLinkActive="active">
            <div class="Polaris-Navigation__Icon">
              <span class="Polaris-Icon">
                <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                  <path fill="currentColor" d="M9 12H4.5C3.1 12 2 10.901 2 9.5 2 8.1 3.1 7 4.5 7H9v5z"></path>
                  <path
                    d="M18 14.6c-2-1.5-4.9-2.3-7-2.6V6.901c2.1-.2 5-1.101 7-2.601v10.3zM9 18H7.651L5.6 14H9v4zm0-6H4.5C3.1 12 2 10.901 2 9.5 2 8.1 3.1 7 4.5 7H9v5zM19 0c-.5 0-1 .5-1 1 0 2-5.1 4-8 4H4.5C2 5 0 7 0 9.5c0 2.1 1.4 3.8 3.3 4.3l2.5 5c.3.7 1 1.2 1.8 1.2H9c1.1 0 2-.9 2-2v-3.9c3 .301 7 2.101 7 3.9 0 .5.5 1 1 1s1-.5 1-1V1c0-.5-.5-1-1-1z">
                  </path>
                </svg>
              </span>
            </div>
            <span class="Polaris-Navigation__Text">Support</span>
          </a>
        </li>
      </ul>

      <ul class="Polaris-Navigation__Section">
        <li class="Polaris-Navigation__ListItem">
          <a class="Polaris-Navigation__Item" [routerLink]="['/amp/settings']" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact:true}">
            <div class="Polaris-Navigation__Icon">
              <span class="Polaris-Icon">
                <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                  <path fill="currentColor"
                    d="M10 13a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm7-3c0-.53-.064-1.044-.176-1.54L19 7.23l-2.047-3.464-2.106 1.188A6.978 6.978 0 0 0 12 3.292V1H8v2.294a6.99 6.99 0 0 0-2.847 1.662L3.047 3.768 1 7.232 3.176 8.46C3.064 8.955 3 9.47 3 10s.064 1.044.176 1.54L1 12.77l2.047 3.464 2.106-1.188A6.99 6.99 0 0 0 8 16.708V19h4v-2.294a6.99 6.99 0 0 0 2.847-1.662l2.106 1.188L19 12.768l-2.176-1.227c.112-.49.176-1.01.176-1.54z">
                  </path>
                  <path
                    d="M19.492 11.897l-1.56-.88a7.63 7.63 0 0 0 .001-2.035l1.56-.88a1 1 0 0 0 .369-1.38L17.815 3.26a1 1 0 0 0-1.353-.363l-1.49.84A8.077 8.077 0 0 0 13 2.587V1a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v1.586a8.072 8.072 0 0 0-1.97 1.152l-1.492-.84a1 1 0 0 0-1.352.36L.14 6.724a1.004 1.004 0 0 0 .369 1.381l1.55.88C2.02 9.325 2 9.665 2 10s.023.675.068 1.017l-1.56.88a1 1 0 0 0-.369 1.372l2.04 3.46c.27.47.87.63 1.35.36l1.49-.844c.6.48 1.26.87 1.97 1.154V19c0 .552.443 1 1 1h4c.55 0 1-.448 1-1v-1.587c.7-.286 1.37-.675 1.97-1.152l1.49.85a.992.992 0 0 0 1.35-.36l2.047-3.46a1.006 1.006 0 0 0-.369-1.38zm-3.643-3.22c.1.45.15.894.15 1.323s-.05.873-.15 1.322c-.1.43.1.873.48 1.09l1.28.725-1.03 1.742-1.257-.71a.988.988 0 0 0-1.183.15 6.044 6.044 0 0 1-2.44 1.42.99.99 0 0 0-.714.96V18H9v-1.294c0-.443-.29-.833-.714-.96a5.985 5.985 0 0 1-2.44-1.424 1 1 0 0 0-1.184-.15l-1.252.707-1.03-1.75 1.287-.73c.385-.22.58-.66.485-1.09A5.907 5.907 0 0 1 4 10c0-.43.05-.874.152-1.322a1 1 0 0 0-.485-1.09L2.38 6.862 3.41 5.12l1.252.707a.998.998 0 0 0 1.184-.15 6.02 6.02 0 0 1 2.44-1.425A1 1 0 0 0 9 3.294V2h2v1.294c0 .442.29.832.715.958.905.27 1.75.762 2.44 1.426.317.306.8.365 1.183.15l1.253-.708 1.03 1.742-1.28.726a.999.999 0 0 0-.48 1.09zM10 6c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z">
                  </path>
                </svg>
              </span>
            </div>
            <span class="Polaris-Navigation__Text">Settings</span>
          </a>
        </li>
      </ul>

    </div>

  </nav>

  <button type="button" (click)="closeSidebar()" class="Polaris-Frame__NavigationDismiss" aria-hidden="true"
    aria-label="Close navigation" tabindex="-1">
    <span class="Polaris-Icon Polaris-Icon--colorWhite"><svg viewBox="0 0 20 20" class="Polaris-Icon__Svg"
        focusable="false" aria-hidden="true">
        <path
          d="M11.414 10l6.293-6.293a.999.999 0 1 0-1.414-1.414L10 8.586 3.707 2.293a.999.999 0 1 0-1.414 1.414L8.586 10l-6.293 6.293a.999.999 0 1 0 1.414 1.414L10 11.414l6.293 6.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L11.414 10z"
          fill-rule="evenodd"></path>
      </svg>
    </span>
  </button>
</div>