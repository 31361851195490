import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AmBuilderService } from '../../../am-builder.service';
import { Section } from '../../../../am-builder.model';

@Component({
  selector: 'am-add-component-list',
  templateUrl: './add-component-list.component.html',
  styleUrls: ['./add-component-list.component.scss']
})
export class AddComponentListComponent implements OnInit {

  private GLOBAL_SECTIONS = [
    'header',
    'footer',
    'sidebar',
    'article',
    'blog',
    'collection-list',
    'collection-template',
    'product-template'
  ];

  @Input()
  pageType: string;

  @Output() closeAddComponentPanel = new EventEmitter();

  sections: Array<Section>;

  constructor(
    private amBuilderService: AmBuilderService
  ) {

  }

  ngOnInit() {
    this.sections = [];

    const themeSections = this.amBuilderService.theme.themeDetails.sections;

    for (const sectionId in themeSections) {
      if (themeSections.hasOwnProperty(sectionId)) {

        if (this.pageType === 'home') {

          // Check if section id is not part of the main sections
          if (
            this.GLOBAL_SECTIONS.indexOf(sectionId) < 0 &&

            (!this.isIntegrationSection(sectionId) &&
              !this.isGlobalIntegrationSection(sectionId) &&
              !this.isHomePageIntegrationSection(sectionId))

          ) {

            // And if not add it to the list of potential sections to have in homepage
            themeSections[sectionId].type = themeSections[sectionId].type || sectionId;
            this.sections.push(themeSections[sectionId]);
          }

          // Then later on in sections we should also have all of the
          if (this.GLOBAL_SECTIONS.indexOf(sectionId) < 0 && this.isHomePageIntegrationSection(sectionId)) {
            // And if not add it to the list of potential sections to have in homepage
            themeSections[sectionId].type = themeSections[sectionId].type || sectionId;
            this.sections.push(themeSections[sectionId]);
          }
        }

        if (this.pageType === 'product') {
          // Show product page integrations
          if (this.GLOBAL_SECTIONS.indexOf(sectionId) < 0 && this.isIntegrationSection(sectionId)) {
            // And if not add it to the list of potential sections to have in homepage
            themeSections[sectionId].type = themeSections[sectionId].type || sectionId;
            this.sections.push(themeSections[sectionId]);
          }
        }

        if (this.pageType === 'integration') {
          // Show global integrations
          if (this.GLOBAL_SECTIONS.indexOf(sectionId) < 0 && this.isGlobalIntegrationSection(sectionId)) {
            // And if not add it to the list of potential sections to have in homepage
            themeSections[sectionId].type = themeSections[sectionId].type || sectionId;
            this.sections.push(themeSections[sectionId]);
          }
        }

        // Used for story page for new stories by ampify me
        if (this.pageType === 'page') {
          this.sections.push(themeSections['page']);
        }
      }
    }
  }

  closeDialog(): void {
    this.closeAddComponentPanel.emit(null);
  }

  addSection(section: any) {
    this.closeAddComponentPanel.emit(section);
  }

  private isIntegrationSection(sectionId: string): boolean {
    if (sectionId.indexOf('integration') === 0) {
      return true;
    }
    return false;
  }
  private isGlobalIntegrationSection(sectionId: string): boolean {
    if (sectionId.indexOf('global-integration') === 0) {
      return true;
    }
    return false;
  }

  private isHomePageIntegrationSection(sectionId: string): boolean {
    if (sectionId.indexOf('home-page__') === 0) {
      return true;
    }
    return false;
  }
}
