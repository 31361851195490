<div class="error-message">
  <h1>Error</h1>
  <p>Oops!
    Something wrong happened, please try refreshing the page or
    <a href="https://{{shopDomain}}/admin/apps/ampify-me?shop={{shopDomain}}">go back to admin.</a>
  </p>
  <p>
    <a class="button button-primary" href="https://{{shopDomain}}/admin/apps/ampify-me?shop={{shopDomain}}">Back to
      Shopify admin</a>
  </p>
</div>