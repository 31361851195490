
<div class="sidebar-layout-section">

<div class="sidebar-replacable-sections">

<am-sidebar-sections-v2 [pageType]="pageTitle" [mainMenu]="sidemenuData" [headerConfig]="headerConf" [footerConfig]="footerConf" [currentConfig]="currentConf"></am-sidebar-sections-v2>
</div>

<!-- <div class="theme-settings-section">
    <h1 class="page-footer-title">
        Theme settings
    </h1>
</div> -->
</div>

<!-- <aside id="builder-components">

    <ul id="builderComponentsTabs" class="tabs">
      <li (click)="changeTab('SECTIONS')" [ngClass]="{'active': activeTab === 'SECTIONS'}">
        <a>Sections</a>
      </li>
      <li (click)="changeTab('THEME_SETTINGS')" [ngClass]="{'active': activeTab === 'THEME_SETTINGS'}">
        <a>Theme settings</a>
      </li>
    </ul>
    <div id="tabSections" *ngIf="activeTab === 'SECTIONS'" class="card-section">
      <am-sections></am-sections>
    </div>
    <div id="tabThemeSettings" *ngIf="activeTab === 'THEME_SETTINGS'" class="card-section">
      <am-theme-settings></am-theme-settings>
    </div>
  
  </aside>
  
  <div class="actions">
    <div class="action-list-button theme-actions" ngbDropdown>
  
      <button class="button secondary action-list-btn" ngbDropdownToggle>
        Theme Actions
      </button>
  
      <div ngbDropdownMenu class="theme-actions-dropdown-content mt-2">
        <div tabindex="-1" class="Polaris-Popover__Content">
          <div class="Polaris-Popover__Pane Polaris-Scrollable Polaris-Scrollable--vertical"
            data-polaris-scrollable="true">
            <div class="Polaris-ActionList">
              <div class="Polaris-ActionList__Section--withoutTitle">
                <ul class="Polaris-ActionList__Actions">
                  <li>
                    <a [routerLink]="['/amp/themes', themeId, 'code']" class="Polaris-ActionList__Item">
                      <div class="Polaris-ActionList__Content">Edit code</div>
                    </a>
                  </li>
                  <li>
                    <a [routerLink]="['/amp/themes', themeId, 'language']" class="Polaris-ActionList__Item">
                      <div class="Polaris-ActionList__Content">Edit languages</div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  IMAGE PICKER
  <am-shared-image-picker></am-shared-image-picker>
  
  FONT PICKER
  <am-shared-font-picker></am-shared-font-picker> -->