import { Directive, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'img[amFallBackImg]'
})
export class FallBackImgDirective {

  @Input() amFallBackImg : string;

  constructor(private eRef : ElementRef) { }

  @HostListener('error')
  loadFallbackOnError(){
    const element : HTMLImageElement = <HTMLImageElement>this.eRef.nativeElement;
    element.src = this.amFallBackImg;
  }
}
