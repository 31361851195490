import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getPrecentage'
})
export class GetPrecentagePipe implements PipeTransform {

  transform(value: any, totalSubscribers: number): any {
    if(value && totalSubscribers){

      return ((value / totalSubscribers) * 100).toFixed(2).toString() + '%';
    }
    return value  + ' Subscribers';
  }

}
