import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'am-refresh-loading',
  templateUrl: './refresh-loading.component.html',
  styleUrls: ['./refresh-loading.component.scss']
})
export class RefreshLoadingComponent implements OnInit {
  
  loadingMessage = 'Hold tight, we are getting latest settings...';
  constructor() { }

  ngOnInit(): void {

    setTimeout(() => {
     this.loadingMessage = 'Generating AMP page for you...'; 
    }, 10000);

    setTimeout(() => {
      this.loadingMessage = 'Almost ready...'; 
     }, 20000);

  }

}
