import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../shared/shared.module';

import { OnboardingRoutingModule } from './onboarding-routing.module';
import { OnboardingWelcomeComponent } from './onboarding-welcome/onboarding-welcome.component';
import { OnboardingLayoutComponent } from './onboarding-layout/onboarding-layout.component';
import { OnboardingExplanationComponent } from './onboarding-explanation/onboarding-explanation.component';
import { OnboardingSeoBenefitComponent } from './onboarding-seo-benefit/onboarding-seo-benefit.component';
import { OnboardingFinishComponent } from './onboarding-finish/onboarding-finish.component';
import { OnboardingPatienceComponent } from './onboarding-patience/onboarding-patience.component';
import { OnboardingBenefitPerformanceComponent } from './onboarding-benefit-performance/onboarding-benefit-performance.component';
import { OnboardingBenefitSearchRankComponent } from './onboarding-benefit-search-rank/onboarding-benefit-search-rank.component';
import { OnboardingBenefitCheaperAdsComponent } from './onboarding-benefit-cheaper-ads/onboarding-benefit-cheaper-ads.component';
import { OnboardingBenefitHigherCtrComponent } from './onboarding-benefit-higher-ctr/onboarding-benefit-higher-ctr.component';

@NgModule({
  declarations: [
    OnboardingWelcomeComponent,
    OnboardingLayoutComponent,
    OnboardingExplanationComponent,
    OnboardingSeoBenefitComponent,
    OnboardingFinishComponent,
    OnboardingPatienceComponent,
    OnboardingBenefitPerformanceComponent,
    OnboardingBenefitSearchRankComponent,
    OnboardingBenefitCheaperAdsComponent,
    OnboardingBenefitHigherCtrComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    OnboardingRoutingModule
  ]
})
export class OnboardingModule { }
