import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'am-dynamic-form-v2',
  templateUrl: './dynamic-form-v2.component.html',
  styleUrls: ['./dynamic-form-v2.component.scss']
})
export class DynamicFormV2Component implements OnInit {

  //  question = [];
  // form: FormGroup;
  questions = [
    {
      controlType : 'dropdown',
      key: 'brave',
      label: 'Bravery Rating',
      options: [
        {key: 'solid',  value: 'Solid'},
        {key: 'great',  value: 'Great'},
        {key: 'good',   value: 'Good'},
        {key: 'unproven', value: 'Unproven'}
      ],
      order: 3
    },
    {
      controlType : 'textbox',
      key: 'firstName',
      label: 'First name',
      value: 'Bombasto',
      required: true,
      order: 1
    },

    {
      controlType : 'textbox',
      key: 'emailAddress',
      label: 'Email',
      type: 'email',
      order: 2
    },

    {
      controlType : 'radio',
      key: 'type',
      label: 'Job Type',
      order: 4,
      options: [
        {key: 'hero', value: 'Hero'},
        {key: 'sidekick', value: 'Sidekick'}
      ]
    }
  ];
  @Input() formData : any = [];
  @Output() closeForm = new EventEmitter();

  formArray : any = [];

  constructor() { }

  ngOnInit(): void {

    this.formArray = this.formData;

  }

  //  isValid() { return this.form.controls[this.question.key].valid; }

  hideForm(){
    //console.log("next!!!!!!!!!")
    this.closeForm.emit();
  }

}
