import { Component, OnInit } from '@angular/core';

import { OnboardingService } from '../onboarding.service';

@Component({
  selector: 'am-onboarding-benefit-search-rank',
  templateUrl: './onboarding-benefit-search-rank.component.html',
  styleUrls: ['./onboarding-benefit-search-rank.component.scss']
})
export class OnboardingBenefitSearchRankComponent implements OnInit {

  constructor(
    private onboardingService: OnboardingService
  ) {
    // this.onboardingService.setCurrentPage(4);
    this.onboardingService.setCurrentPage(3);
  }

  ngOnInit() {
  }

}
