import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { PwaQuickConfig } from './pwa-onboarding.model';

@Injectable({
  providedIn: 'root'
})
export class PwaOnboardingService {

  baseUrl = environment.basePWAUrl;

  onboardingConfiguration: any;

  constructor(
    private http: HttpClient
  ) {

    this.onboardingConfiguration = {
      currentStep: 1,
      totalSteps: 5
    };
  }

  getProgressPercentage(): number {
    return (100 - ((this.onboardingConfiguration.totalSteps - this.onboardingConfiguration.currentStep) / this.onboardingConfiguration.totalSteps) * 100);
  }

  setCurrentPage(page: number): void {
    this.onboardingConfiguration.currentStep = page;
  }

  setOnboardingStatus(status: string): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/api/v2/onboarding/status`, {
      status: status
    });
  }
  getQuickConfig(): Observable<PwaQuickConfig> {
    return this.http.get<PwaQuickConfig>(`${this.baseUrl}/api/v2/onboarding/quick-config`);
  }
  updateQuickConfig(config: PwaQuickConfig): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/api/v2/onboarding/quick-config`, config);
  }
}
