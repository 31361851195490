import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EditorService {

  openImagePicker = new Subject<any>();
  imagePickerClosed = new Subject<any>();
  imageSelected = new Subject<any>();

  openFontPicker = new Subject<any>();
  fontPickerClosed = new Subject<any>();
  fontSelected = new Subject<any>();

  constructor() { }

  showImagePicker(selected: any): void {
    // Send event to open the picker...
    this.openImagePicker.next(selected);
  }

  previewImage(selected: any): void {
    this.imageSelected.next(selected);
  }

  closeImagePicker(selected: any): void {
    this.imagePickerClosed.next(selected);
  }


  showFontPicker(selected: any): void {
    // Send event to open the picker...
    this.openFontPicker.next(selected);
  }

  previewFont(selected: any): void {
    this.fontSelected.next(selected);
  }

  closeFontPicker(selected: any): void {
    this.fontPickerClosed.next(selected);
  }

}


