import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'am-polaris-action-list',
  templateUrl: './polaris-action-list.component.html',
  styleUrls: ['./polaris-action-list.component.scss']
})
export class PolarisActionListComponent implements OnInit {

  showActionListItems = false;

  constructor() { }

  ngOnInit() {
  }

  toggleActionListItems() {
    this.showActionListItems = !this.showActionListItems;
  }

}
