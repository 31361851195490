<form class="form-inline">
    <input name="datepicker" ngbDatepicker #datepicker="ngbDatepicker" [autoClose]="'outside'"
        (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" [startDate]="internalDate.to"
        [footerTemplate]="footerTemplate" outsideDays="hidden" [maxDate]="internalMaxDate" placement="bottom-right">
    <ng-template #t let-date let-focused="focused">
        <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)" [class.disabled]="isDisabled(date)"
            (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
            {{ date.day }}
        </span>
    </ng-template>
    <ng-template #footerTemplate>
        <hr class="my-0">
        <button class="btn btn-secondary btn-xs m-2 float-left" (click)="datepicker.close()">Close</button>
        <button class="btn btn-primary btn-xs m-2 float-right" (click)="onApply(); datepicker.close()">Apply</button>
    </ng-template>
    <div class="form-group">
        <div class="input-group" (click)="datepicker.toggle()">
            <input #dpFullDate class="form-control form-control-sm bg-white" placeholder="yyyy-mm-dd - yyy-mm-dd"
                name="dpFullDate" [value]="fullValue" (blur)="onBlur(dpFullDate.value)" [readonly]="readonly">
            <div class="input-group-append">
                <button class="btn btn-secondary btn-xs" type="button">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path fill="#637381" fill-rule="evenodd"
                            d="M19 2h-3V1a1 1 0 1 0-2 0v1H6V1a1 1 0 1 0-2 0v1H1a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zM2 18h16V8H2v10zM2 6h16V4H2v2zm4 4a1 1 0 1 0 0 2 1 1 0 1 0 0-2m0 4a1 1 0 1 0 0 2 1 1 0 1 0 0-2m4 0a1 1 0 1 0 0 2 1 1 0 1 0 0-2m0-4a1 1 0 1 0 0 2 1 1 0 1 0 0-2m4 0a1 1 0 1 0 0 2 1 1 0 1 0 0-2" />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</form>