import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AmBuilderService } from '../../../am-builder.service';

@Component({
  selector: 'am-edit-section-content',
  templateUrl: './edit-section-content.component.html',
  styleUrls: ['./edit-section-content.component.scss']
})
export class EditSectionContentComponent implements OnInit {

  @Input()
  blockId: string;

  @Input()
  blockDefinitions: any;

  @Output()
  removBlockContentEvent = new EventEmitter();

  sections: any;
  detailsExpanded = false;

  @Input()
  editData: any;

  editBlockData: any;

  block: any;

  component: any;

  constructor(
    private amBuilderService: AmBuilderService
  ) {
    this.sections = amBuilderService.theme.sections;
  }

  ngOnInit() {
    const blockObj = this.editData.blocks[this.blockId];
    if (!blockObj || !blockObj.type) {
      console.error('Block not found');
      return;
    }
    this.editData.blocks[this.blockId].settings = this.editData.blocks[this.blockId].settings || {};

    // Get type of the block
    for (let i = 0; i < this.blockDefinitions.length; i++) {
      if (this.blockDefinitions[i].type === blockObj.type) {
        this.block = this.blockDefinitions[i];
      }
    }

    // Set component id as it is needed for sending update events
    this.component = {
      id: this.blockId
    };

    // Set editData for this block
    this.editBlockData = this.editData.blocks[this.blockId].settings;
  }

  toggleDetails() {
    this.detailsExpanded = !this.detailsExpanded;
  }

  removeBlock() {
    this.removBlockContentEvent.emit(this.blockId);
  }

  closeDetails() {
  }
}
