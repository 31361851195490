import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OnboardingWelcomeComponent } from './onboarding-welcome/onboarding-welcome.component';
import { OnboardingExplanationComponent } from './onboarding-explanation/onboarding-explanation.component';
import { OnboardingSeoBenefitComponent } from './onboarding-seo-benefit/onboarding-seo-benefit.component';
import { OnboardingFinishComponent } from './onboarding-finish/onboarding-finish.component';
import { OnboardingPatienceComponent } from './onboarding-patience/onboarding-patience.component';
import { OnboardingBenefitPerformanceComponent } from './onboarding-benefit-performance/onboarding-benefit-performance.component';
import { OnboardingBenefitSearchRankComponent } from './onboarding-benefit-search-rank/onboarding-benefit-search-rank.component';
import { OnboardingBenefitCheaperAdsComponent } from './onboarding-benefit-cheaper-ads/onboarding-benefit-cheaper-ads.component';
import { OnboardingBenefitHigherCtrComponent } from './onboarding-benefit-higher-ctr/onboarding-benefit-higher-ctr.component';

const routes: Routes = [
  {
    path: 'welcome',
    component: OnboardingWelcomeComponent
  },
  {
    path: 'explanation',
    component: OnboardingExplanationComponent
  },
  {
    path: 'benefits/performance',
    component: OnboardingBenefitPerformanceComponent
  },
  {
    path: 'benefits/search-rank',
    component: OnboardingBenefitSearchRankComponent
  },
  {
    path: 'benefits/cheaper-ads',
    component: OnboardingBenefitCheaperAdsComponent
  },
  {
    path: 'benefits/higher-ctr',
    component: OnboardingBenefitHigherCtrComponent
  },
  {
    path: 'benefits/seo',
    component: OnboardingSeoBenefitComponent
  },
  {
    path: 'patience',
    component: OnboardingPatienceComponent
  },

  {
    path: 'finish',
    component: OnboardingFinishComponent
  },

  // otherwise redirect to welcome page in onboarding
  {
    path: '**',
    redirectTo: 'welcome'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OnboardingRoutingModule { }
