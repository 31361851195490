import { Component, OnInit } from '@angular/core';

import { OnboardingService } from '../onboarding.service';

@Component({
  selector: 'am-onboarding-patience',
  templateUrl: './onboarding-patience.component.html',
  styleUrls: ['./onboarding-patience.component.scss']
})
export class OnboardingPatienceComponent implements OnInit {

  constructor(
    private onboardingService: OnboardingService
  ) {
    // this.onboardingService.setCurrentPage(8);
    this.onboardingService.setCurrentPage(5);
  }

  ngOnInit() {
  }

}
