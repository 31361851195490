<div class="modal-backdrop"></div>

<div class="modal">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">Try this feature</h4>
      <button type="button" class="close btn btn-light" tabindex="3" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="free-trial-banner">
      <h3>14 DAYS FREE TRIAL</h3>
      <p>Upgrade or downgrade easily at any point in time.</p>
    </div>

    <div class="modal-body">

      <p>What's included in {{neededPlanName}}:</p>

      <div *ngIf="neededPlanName === 'hobbyist'">
        <ul>
          <li>All from Free</li>
          <li>AMP collection pages</li>
          <li>Advanced analytics integration</li>
          <li>Support for 3rd party apps</li>
          <li>11 review integrations</li>
          <li>Recommended products</li>
          <li>Configure URL for your AMP pages</li>
          <li>AMP Validations</li>
        </ul>
      </div>
      <div *ngIf="neededPlanName === 'company'">
        <ul>
          <li>
            All in Hobbyist
          </li>
          <li>Unlimited number of ampified products and pages</li>
          <li>Custom domain, have pages on any url you want with CDN</li>
          <li>Ampfied blogs</li>
          <li>Ampfied Shopify pages</li>
          <li>99.9% SLA</li>
          <li>PWA integration for ultrafast setup</li>
        </ul>
      </div>
    </div>

    <div class="modal-footer">
      <div class="modal-footer-content">
        <div class="modal-footer-content-left"></div>

        <div class="modal-footer-content-right">
          <div class="modal-footer-content-buttons">
            <div class="modal-footer-content-button">
              <button class="btn btn-light" tabindex="2" (click)="cancel()">Cancel</button>
            </div>
            <div class="modal-footer-content-button">
              <a [href]="upgradeUrl" tabindex="1" class="Polaris-Button Polaris-Button--primary">
                <span class="Polaris-Button__Content">
                  <span class="Polaris-Button__Text">Try now</span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>