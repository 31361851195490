import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getShopCurrency'
})
export class GetShopCurrencyPipe implements PipeTransform {

  transform(value: any): any {

    let shopCurrency = JSON.parse(localStorage.getItem('shopCurrency'));

    if (shopCurrency) {

      if (shopCurrency.currency_format.includes('<span')) {

        return shopCurrency.currency + " " + value;
      } else {


        let currency_format = shopCurrency.currency_format;
        currency_format = currency_format.replace('{{amount_with_comma_separator}}', ' ' + value);
        currency_format = currency_format.replace('{{amount}}', ' ' + value);
        currency_format = currency_format.replace('{{amount_no_decimals}}', ' ' + value);
        currency_format = currency_format.replace('{{amount_no_decimals_with_comma_separator}}', ' ' + value);
        currency_format = currency_format.replace('{{amount_with_apostrophe_separator}}', ' ' + value);

        return currency_format;
      }
    }
    return value;
  }
}

