import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Constants } from '../../classes/constant';
import { FormDataPrepareService } from '../../services/form-data-prepare.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { AmBuilderService } from '../../../am-builder.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'am-header-v2',
  templateUrl: './header-v2.component.html',
  styleUrls: ['./header-v2.component.scss']
})
export class HeaderV2Component implements OnInit {

  @Input() headerObj: any = {};
  @Output() showSectionForm = new EventEmitter();
  @Output() addSectionBlock = new EventEmitter();
  @Output() newHeaderBlock = new EventEmitter();
  @Input() section : any = {}


 headerMenuObj = {
    "sections": {
    },
    "order": [

      "announcement-bar",
      "header",

    ]
  }
  routerArray: any = [];
/// new code add
 announcementObj = {
    // "type": "announcement",
    // "settings": {
    //   "text": "Welcome to our store",
    //   "color_scheme": "background-1",
    //   "link": ""
    // }
  }

cssFormArray : any = [];
colorSchemes : any = [];
stateArray: any = []

  showForm = false;

  formArray: any = []

  routeData = [];

  returnObj = {};

  cssData : any = {};

  sidemenuObj = {

  }
  headerName = '';

  pIndex;

  selectedSectionData = {};

  @Input() mainMenu: any = {};

  headerConfig: any = {};

  currentConfig: any = {};

  @Input() pageType = 'Home'

  subscription: Subscription;

  themeVersionSubscription : Subscription;

   cssPages : any = [
    {
      content: '',
      label: 'Enable AMP',
      required: false,
      type: 'checkbox',
      value : false,
      validations: [],
      key: 'enabled_amp',
      // options : [],
      // sectionName : '',
      // info : '',
      // min : null,
      // max : null,
      // step : null,
      // unit : null
    },
    {
      content: '',
      label: 'Home Page',
      required: false,
      type: 'textarea',
      // value : settingData.settings[keys[i]],
      validations: [],
      key: 'home_css',
      // options : [],
      // sectionName : '',
      // info : '',
      // min : null,
      // max : null,
      // step : null,
      // unit : null
    },
    {
      content: '',
      label: 'Product Page',
      required: false,
      type: 'textarea',
      // value : settingData.settings[keys[i]],
      validations: [],
      key: 'product_css',
      // options : [],
      // sectionName : '',
      // info : '',
      // min : null,
      // max : null,
      // step : null,
      // unit : null
    },
    {
      content: '',
      label: 'Collections Page',
      required: false,
      type: 'textarea',
      // value : settingData.settings[keys[i]],
      validations: [],
      key: 'collections_css',
      // options : [],
      // sectionName : '',
      // info : '',
      // min : null,
      // max : null,
      // step : null,
      // unit : null
    },
    {
      content: '',
      label: 'Collection List Page',
      required: false,
      type: 'textarea',
      // value : settingData.settings[keys[i]],
      validations: [],
      key: 'collection_list_css',
      // options : [],
      // sectionName : '',
      // info : '',
      // min : null,
      // max : null,
      // step : null,
      // unit : null
    },
    {
      content: '',
      label: 'Blogs Page',
      required: false,
      type: 'textarea',
      // value : settingData.settings[keys[i]],
      validations: [],
      key: 'blog_css',
      // options : [],
      // sectionName : '',
      // info : '',
      // min : null,
      // max : null,
      // step : null,
      // unit : null
    },
    {
      content: '',
      label: 'Blog Post Page',
      required: false,
      type: 'textarea',
      // value : settingData.settings[keys[i]],
      validations: [],
      key: 'blog_post_css',
      // options : [],
      // sectionName : '',
      // info : '',
      // min : null,
      // max : null,
      // step : null,
      // unit : null
    },
  ]

analyticsData : any = {};

 arr = [
    {
      content: '',
      label: 'Google Analytics ID',
      required: false,
      type: 'text',
      // value : settingData.settings[keys[i]],
      validations: [],
      key: 'google_analytics_tracking_id',
      // options : [],
      // sectionName : '',
      // info : '',
      // min : null,
      // max : null,
      // step : null,
      // unit : null
    },
    {
      content: '',
      label: 'Google Tag Manager script',
      required: false,
      type: 'html',
      // value : settingData.settings[keys[i]],
      validations: [],
      key: 'google_tag_manager_script',
      // options : [],
      // sectionName : '',
      info: 'Used for Google Tag Manager tracking. Tutorial on how to setup Google Tag Manager can he found at [AmpifyMe help centar](https://help.ampifyme.com/article/21-how-to-add-google-tag-manager-to-your-amp-page).',
      //  min : null,
      //  max : null,
      //  step : null,
      //  unit : null
    },
    {
      content: 'Facebook Pixel',
      label: '',
      required: false,
      type: 'header',
      // value : settingData.settings[keys[i]],
      validations: [],
      //  key : '',
      // options : [],
      // sectionName : '',
      info: 'Used for tracking with Facebook Pixel',
      //  min : null,
      //  max : null,
      //  step : null,
      //  unit : null
    },
    {
      content: 'Facebook',
      label: '',
      required: false,
      type: 'text',
      // value : settingData.settings[keys[i]],
      validations: [],
      key: 'fb_pixel',
      // options : [],
      // sectionName : '',
      info: '',
      //  min : null,
      //  max : null,
      //  step : null,
      //  unit : null
    },
    {
      content: 'Pinterest',
      label: '',
      required: false,
      type: 'header',
      // value : settingData.settings[keys[i]],
      validations: [],
      //  key : '',
      // options : [],
      // sectionName : '',
      info: '',
      //  min : null,
      //  max : null,
      //  step : null,
      //  unit : null
    },
    {
      content: '',
      label: 'Pinterest tag',
      required: false,
      type: 'text',
      // value : settingData.settings[keys[i]],
      validations: [],
      key: 'pinterest_pixel',
      // options : [],
      // sectionName : '',
      info: 'Used for tracking with Pinterest tag. Tutorial how to find Pinterest tag can be found at [Pinterest help](https://help.pinterest.com/en-gb/business/article/shopify-and-pinterest-tag). We need YOUR_TAG_ID from Pinterest.',
      //  min : null,
      //  max : null,
      //  step : null,
      //  unit : null
    }
  ]
version : any;
 themeId;
 themeSections: any = [
    {
      'title' : 'Custom Section',
      'key' : 'custom_section'
    },

  ];

themeSettingsV10: any = [

    {
      'name': 'Logo',
      'key': 'logo',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Typography',
      'key': 'typography',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Layout',
      'key': 'layout',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Buttons',
      'key': 'buttons',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Variant Pills',
      'key': 'variant_pills',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Inputs',
      'key': 'inputs',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Cards',
      'key': 'cards',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Content Container',
      'key': 'variant_pills',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Media',
      'key': 'media',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Dropdowns and Pop-ups',
      'key': 'media',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Drawers',
      'key': 'drawers',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Badges',
      'key': 'badges',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'brand information',
      'key': 'brand_information',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Icons',
      'key': 'styles',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Social Media',
      'key': 'social-media',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Search Behavior',
      'key': 'search_input',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Favicon',
      'key': 'favicon',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Currency Format',
      'key': 'currency_format',
      'schemaSettings': [],
      'settings': this.currentConfig

    },
    {
      'name': 'Cart',
      'key': 'cart',
      'schemaSettings': [],
      'settings': this.currentConfig

    },

  ]


  iconShow : any = false;


///new code add
  constructor(
    public data_prepare: FormDataPrepareService,
    private pubsub: NgxPubSubService,
    public amBuilderService: AmBuilderService = null,
    public route : ActivatedRoute) {

   }

  ngOnInit(): void {
     this.themeId = this.route.snapshot.paramMap.get('id');

    this.getThemeVersion(this.themeId);
    let tmp = localStorage.getItem('uData');

   // if user not add then show custome header
   console.log("////header oreder check......",this.headerObj);

    if(this.headerObj["sections"] == "custom_section"){
      this.headerMenuObj = {
        "sections": {
        },
        "order": [

         "custom_section",
          "announcement-bar",
          "header",
        ]
      }
    }
    // let newbb = JSON.parse(this.headerObj)

    // this.setAddSectionData();
    this.mainMenu = JSON.parse(tmp);
    this.getSectionData();
    //console.log(this.mainMenu)

    this.showSidemenu();
    this.getThemeSettingData();
    this.getAnalyticsObj();
    this.getCssObj()
    this.pageChange();
    this.getThemeVersionSubject();

  }

  showParentForm($event) {

    this.showSectionForm.emit($event);

  }

  panelHeaderOpen($event) {

    // this.headerObj.sections[$event.item]['isPanelOpened'] = $event.flag;
    for (let i = 0; i < this.headerObj.order.length; i++) {
      if ($event.flag) {
        if ($event.item == this.headerObj.sections[$event.item].type) {

          this.headerObj.sections[$event.item]['isPanelOpened'] = $event.flag;
          break;
        }
      } else {

        this.headerObj.sections[$event.item]['isPanelOpened'] = false;

      }
    }
  }

  emitBlock($event){

    this.addSectionBlock.emit($event);

  }

  //new code add

//   setAddSectionData(){

//     let tmp1 = JSON.parse(localStorage.getItem('ampify-user'));

//     if(tmp1.shopDomain == 'the-spoiled-mama-inc.myshopify.com'){

//       this.themeSections.unshift( {
//         'title': "Impact text",
//         'key': 'impact-text'
//       });
//       this.themeSections.unshift({
//         'title': "Multiple images with text",
//         'key': 'multiple-images-with-text'
//       })

//     }

//     if(tmp1.shopDomain == 'ef420.myshopify.com'){


//     this.themeSections.unshift( {
//       'title': "Featured collection slider",
//       'key': 'featured-collection-slider'
//     },
// )

//     }
//     if(this.version == '3'){
//       this.themeSections.unshift({
//         'title': "Multirow",
//         'key': 'multirow'
//       })
//     }


//   }

  getThemeVersion(themeId) {

    return new Promise((resolve) => {

      this.amBuilderService.getThemeVersion(themeId)
        .subscribe((response) => {

          let tmp = JSON.parse(localStorage.getItem('ampify-user'));

          if(response['is_theme_2_0'] == 3){

            this.version = '3';
            this.iconShow = true;
            this.getColorSchemes(this.themeId);
            resolve('3');
          } else if(tmp.shopDomain == 'ef420.myshopify.com'){

            this.version = 'ef420';
            this.iconShow = false;
            resolve('ef420');
          } else {

            this.version = '2';
            this.iconShow = false;
            resolve('2');

          }
        })
      })

    }
    getThemeVersionSubject() {

      this.themeVersionSubscription = this.data_prepare.getSaveData().subscribe(res => {

        // //console.log("getSaveData", res);
        this.version = res;
        // this.putThemeData();
      })
    };


  async formShow(data) {

    console.log("data", data);
    this.selectedSectionData = { ...data };
    let labelData = Constants.labelDataV7;
    this.formArray = await this.data_prepare.prepareJson(data, data.schemaSettings);
    this.routeData = data.route;
    this.pIndex = data.pIndex;
    this.headerName = data.headingText;
    //console.log(this.formArray, this.routeData, this.headerName);
    const message = {
      type: 'SECTION_SELECT',
      configuration: {
        id: this.routeData[0]
      }
    };
    this.pubsub.publishEvent('SEND_IFRAME_EVENT', message);
    // this.data_prepare.setScrollSection(this.routeData[0])
    this.showForm = true;
    this.showFormO();
  }

  hideForm() {

    // this.showForm = false;
    this.stateArray.pop();

  }

  pageChange(){

    this.subscription = this.data_prepare.getPageChangeEvent().subscribe(res => {

      if(this.showForm){
      this.hideForm();
    this.showForm = false;
    }
    })

  }

  submitData($event) {

    this.returnObj = $event;
    this.checkAlreadySettingsPresent();
    // this.showForm = false;
  }


  checkAlreadySettingsPresent() {

    //console.log(this.returnObj, "return obj");
    let keys = Object.keys(this.returnObj)
    let settings = {};
    if (keys.length) {

      if (this.returnObj['route'].length == 1) {

        settings = this.returnObj['settings'];
        this.mainMenu.sections[this.returnObj['route'][0]].settings = settings;

      } else {
        //console.log(this.mainMenu.sections[this.returnObj['route'][0]]);
        // this.sidemenuObj.sections[this.returnObj['route'][0]]['isPanelOpened'] = true;

        settings = this.returnObj['settings'];

        this.mainMenu.sections[this.returnObj['route'][0]]['blocks'][this.returnObj['route'][1]].settings = settings;
      }
    }
    this.data_prepare.setData(this.mainMenu);
    //console.log("final result!!!!!!!!!!!!!!!!", this.mainMenu)

  }


  dragDropItem(value) {

    moveItemInArray(value.order, value.previousIndex, value.currentIndex);
    //console.log("dragDropItem", value)

    // this.sidemenuObj.order = value.order;
  }
  getFormFlag() {

    this.subscription = this.data_prepare.getSaveFlag().subscribe(res => {

      this.showForm = false;

      if (res) {
        //  this.onSubmit(this.dynamicForm.value);
      }

    })
  };

  async showThemeSett(data) {

    if(data.schemaKey != 'colors') {
    data.settings = this.currentConfig;
    }
     console.log("showThemeSett", data);
    this.selectedSectionData = {...data};
    this.formArray = await this.data_prepare.prepareSettingJson(data, data.schemaSettings);
    this.headerName = data.name;
    this.routeData = data.route;

    this.showForm = true;
    this.showFormO();


  }

  getThemeSettingData() {

    this.subscription = this.data_prepare.getThemeSettings().subscribe(res => {


            this.currentConfig = res;
          let  obj = {}

          obj = {
            "custom_section" : res['sections']['custom_section'],
            'announcement-bar': res['sections']['announcement-bar'],
            'header': res['sections']['header']
          }

      if(!res['sections']['custom_section']){

        obj = {
          'announcement-bar': res['sections']['announcement-bar'],
          'header': res['sections']['header']
        }

      } else{
       let orderArr = [];
        for (let i = 0; i < Object.keys(res['sections']).length; i++) {
          let dyanamicVar = `custom_section-${i}`
          if(res['sections'][dyanamicVar]){
            obj[dyanamicVar]  = res['sections'][dyanamicVar]
            // this.headerMenuObj['order'].unshift(dyanamicVar);
            orderArr.push(dyanamicVar);
          }else if (res['sections']['custom_section']){
            obj["custom_section"]  = res['sections']['custom_section']

          }
        }
        console.log("header order",orderArr);
        this.headerMenuObj['order'] = [...orderArr, ...this.headerMenuObj['order']]

      }
      this.headerMenuObj['sections'] = obj;

      let footObj = {
        'footer': res['sections']['footer']
      }
      // this.footerMenuObj['sections'] = footObj;

      //console.log("footerMenuObj",this.footerMenuObj);

    })
  };
  getSectionData() {

    // this.subscription = this.data_prepare.getSectionData().subscribe(res => {

    //   console.log("$$$$getSectionData$$$$", res);
    //   this.mainMenu = res;
    //   if(this.stateArray.length != 0){
    //     for(let i=0; i < this.stateArray.length; i++){
    //       if(this.stateArray[i].type == 'sidemenu'){
    //         this.stateArray[i]['data'] = this.mainMenu;
    //       }
    //     }
    //   }
    //   this.stateArray.push({
    //     type: 'sidemenu',
    //     data: this.mainMenu,
    //     routeData: [],
    //     headerText: ''
    //   })
    // })
  }

  showSidemenu() {

    //console.log("calling")
    this.stateArray.push({
      type: 'sidemenu',
      data: this.mainMenu,
      routeData: [],
      headerText: ''
    })
    //console.log("showSidemenu", this.stateArray)
  }
  showFormO() {

    this.stateArray.push({
      type: 'form',
      data: this.formArray,
      routeData: this.routeData,
      headerText: this.headerName
    })

  }
  closeForm() {

    this.showForm = false;
    this.stateArray.pop();
    if(this.stateArray[0]['type'] == 'color-schemes' && this.stateArray[0]['routeData'].length == 2){
      this.stateArray[0]['routeData'].pop();
    }
    console.log("closeform",this.stateArray);
  }

  setPanelFlag($event) {

    //console.log("setPanelFlag", $event, this.stateArray);

    if(this.stateArray.length == 1){

      for (let i = 0; i < this.stateArray[0].data.order.length; i++) {
        //console.log("in loop")
              this.stateArray[0].data.sections[this.stateArray[0].data.order[i]]['isPanelOpened'] = false;


            }

            if($event.flag){

              this.stateArray[0].data.sections[$event.item]['isPanelOpened'] = true;

            }
    } else if(this.stateArray.length > 1){

      for(let i=0; i < this.stateArray.length; i++){

        if(this.stateArray[i]['type'] == 'sidemenu'){

          for (let j = 0; j < this.stateArray[i].data.order.length; j++) {
            //console.log("in loop")
                  this.stateArray[i].data.sections[this.stateArray[i].data.order[j]]['isPanelOpened'] = false;


                }

                if($event.flag){

                  this.stateArray[i].data.sections[$event.item]['isPanelOpened'] = true;

                }

        }
      }

    }



    //console.log("showSidemenu", this.stateArray)

  }

  async showThemeSettingForm(value) {

    console.log(this.version);
    // this.stateArray.pop();
    this.stateArray = [];

    if (value == 'sidemenu') {
      this.showSidemenu();

    } else if (value == 'form') {

      this.showFormO();
    }
    else if (value == 'theme-settings') {

      this.headerName = 'Theme Settings';

      let themeSettingsData : any = [];

       if(this.version == '3'){
        themeSettingsData = this.themeSettingsV10;

      }

      themeSettingsData = await this.getSchemaSettings(themeSettingsData);
      console.log("themeSettingsData",themeSettingsData)

      this.stateArray.push({
        type: 'theme-settings',
        data: themeSettingsData,
        routeData: ['theme-settings'],
        headerText: 'Theme Settings'
      })
    } else if (value == 'analytics') {

      this.headerName = 'Google Analytics'

      this.stateArray.push({
        type: 'form',
        data: this.arr,
        routeData: this.routeData,
        headerText: this.headerName
      })
      //console.log("ana", this.stateArray);
    } else if(value == 'pages'){

      this.headerName = 'Pages';

      //console.log(this.amBuilderService.builderConfig.currentPage)

    //  await this.showCssTextPageWise();

      this.stateArray.push({
        type: 'form',
        data: this.cssFormArray,
        routeData: ['pages'],
        headerText: this.headerName
      });
      //console.log("pages",this.stateArray);

    } else if(value == 'color-schemes'){

      console.log("in color-schemes");
      this.headerName = 'Color Schemes';
      this.colorSchemes = await this.getColorSchemaSettings(this.colorSchemes);

      this.stateArray.push({
        type: 'color-schemes',
        data: this.colorSchemes,
        routeData: ['color_schemes'],
        headerText: this.headerName
      });
    }
  }

  // showCssTextPageWise(){
  //   console.log(this.amBuilderService.builderConfig.currentPage);
  //   return new Promise((resolve) => {

  //     let arr = [];
  //     for(let i=0; i < this.cssPages.length; i++){

  //       if(i == 0){
  //         arr.push(this.cssPages[0]);

  //       } else {

  //         if(this.amBuilderService.builderConfig.currentPage == 'templates/product.json'){

  //           arr.push(this.cssPages[2]);
  //           resolve(arr);
  //           break
  //         } else if(this.amBuilderService.builderConfig.currentPage == 'templates/index.json'){

  //           arr.push(this.cssPages[1]);
  //           resolve(arr);
  //           break
  //         } else if(this.amBuilderService.builderConfig.currentPage == 'templates/collection.json'){

  //           arr.push(this.cssPages[3]);
  //           resolve(arr);
  //           break
  //         } else if(this.amBuilderService.builderConfig.currentPage == 'templates/list-collections.json'){

  //           arr.push(this.cssPages[4]);
  //           resolve(arr);
  //           break
  //         } else if(this.amBuilderService.builderConfig.currentPage == 'templates/blog.json'){

  //           arr.push(this.cssPages[5]);
  //           resolve(arr);
  //           break
  //         }
  //         else if(this.amBuilderService.builderConfig.currentPage == 'templates/article.json'){

  //           arr.push(this.cssPages[6]);
  //           resolve(arr);
  //           break
  //         }

  //       }
  //     }
  //   this.showForm = true;

  //     this.cssFormArray = arr;
  //     resolve(this.cssFormArray);


  //   })
  // }

  addSection(section) {


    let newSectionName = section;

    let newSection = {};

    let newObj;

     if(this.version == '3'){

      newObj = JSON.parse(JSON.stringify(Constants.addSectionDataV10.sections[section]));

    }

    // console.log("new section this.headerObj.......", this.headerObj)
    // Check Already Section Present or not

    let cnt = 0;

    for (let i = 0; i < this.headerObj.order.length; i++) {

      if (this.headerObj.order[i].includes(newSectionName)) {
        cnt++;

      }
    }
    newSection['cnt'] = cnt;

    let orderArr = [];
    if(cnt != 0){
      let tmp: any = newSectionName + "-" + (cnt - 1);

      this.headerObj.order.unshift(tmp);
      // orderArr.push(tmp);
      this.headerObj.sections[tmp] = newObj;

    } else {

      this.headerObj.order.unshift(newSectionName)
      // orderArr.push(newSectionName);

      this.headerObj.sections[newSectionName] = newObj;

    }
    // console.log("orderArr",orderArr);
    // this.headerObj.order = [...orderArr, ...this.headerObj.order];
    console.log('cnt.........',this.headerObj);

    // this.stateArray[0].data = this.headerObj;
    //console.log('cnt.........his.stateArray[0].data',this.stateArray[0].data);
    this.newHeaderBlock.emit(this.headerObj);

    localStorage.setItem('uData',JSON.stringify(this.headerObj))

    this.pubsub.publishEvent('REFRESH_IFRAME_EVENT', this.amBuilderService.builderConfig.currentPage);

  }

  getAnalyticsObj(){

    this.subscription = this.data_prepare.getAnalyticsSettings().subscribe(res => {

      this.analyticsData = res;

      this.bindAnalyticsValues();

    })

  }

  getCssObj(){

    this.subscription = this.data_prepare.getCssSettings().subscribe(res => {

      this.cssData = res['customCSS'];
      this.bindCssValues();

    })

  }

  bindAnalyticsValues(){

    let keys = Object.keys(this.analyticsData);

    for(let i=0; i < keys.length; i++){


      for(let j=0; j < this.arr.length; j++){


        if(keys[i] == this.arr[j]['key']){
        this.arr[j]['value'] = this.analyticsData[keys[i]];
        break;
      }

      }
    }

  }
  bindCssValues(){

    let keys = Object.keys(this.cssData);

    for(let i=0; i < keys.length; i++){

      for(let j=0; j < this.cssPages.length; j++){

        if(keys[i] == this.cssPages[j]['key']){

        this.cssPages[j]['value'] = this.cssData[keys[i]];

        break;
      }

      }
    }

  }
  addSectionBlocks(value){

    //console.log("addSectionBlocks........",value);
    if(value.field == 'buttons'){
      value.field = 'button'
    }
    let tmp = value.parentType.split("-").join("_")
    //console.log(tmp);

    let newBlock;

    if(this.version == '2'){

      } else if(this.version == '3'){

      newBlock = JSON.parse(JSON.stringify(Constants.addSectionDataV10.sections[tmp].blocks[value.field]));

    } else if(this.version == 'ef420'){

    }

    let cnt = 0;


    let tmpName = value.field + "-" + (cnt - 1);

    if(cnt != 0){
      this.headerMenuObj.sections[value.sectionName].block_order.push(tmpName);
      this.headerMenuObj.sections[value.sectionName].blocks[tmpName] = newBlock;
    } else {

      this.headerMenuObj.sections[value.sectionName].block_order.push(value.field);
      this.headerMenuObj.sections[value.sectionName].blocks[value.field] = newBlock;
    }

    this.stateArray[0].data = this.mainMenu;
    localStorage.setItem('uData',JSON.stringify(this.mainMenu))
    this.pubsub.publishEvent('REFRESH_IFRAME_EVENT', this.amBuilderService.builderConfig.currentPage);

  }

//   addAnnouncementBarBlock(value){

//     console.log("addAnnouncementBarBlock???>>>>>>>>",value);

//     let newBlock = JSON.parse(JSON.stringify(this.announcementObj));

//  let cnt = 0;
//     for(let i=0; i < this.headerMenuObj.sections[value.sectionName].block_order.length; i++){

//       if(this.headerMenuObj.sections[value.sectionName].block_order[i].includes(value.field)){
//         cnt++;

//       }
//     }
//     //console.log("cnt",cnt);
//     let tmpName = value.field + "-" + cnt;
//     if(cnt != 0){
//       this.headerMenuObj.sections[value.sectionName].block_order.push(tmpName);
//       this.headerMenuObj.sections[value.sectionName].blocks[tmpName] = newBlock;
//     } else {

//       this.headerMenuObj.sections[value.sectionName].block_order.push(value.field);
//       this.headerMenuObj.sections[value.sectionName].blocks[value.field] = newBlock;
//     }
//     this.pubsub.publishEvent('REFRESH_IFRAME_EVENT', this.amBuilderService.builderConfig.currentPage);

//   }


  getSchemaSettings(array){

    return new Promise((resolve) => {

    for(let i=0; i < array.length; i++) {

      array[i]['schemaSettings'] = Constants.getSchemaThemeSettObject(array[i]['key'])

    }
    resolve(array);
  })
  }

  getColorSchemaSettings(array){

    return new Promise((resolve) => {

    for(let i=0; i < array.length; i++) {

      array[i]['schemaSettings'] = Constants.getSchemaThemeSettObject(array[i]['schemaKey'])

    }
    resolve(array);
  })
  }

  getColorSchemes(themeId) {

    return new Promise((resolve) => {

      this.amBuilderService.getColorSchemesV3(themeId)
        .subscribe((response) => {

          console.log(response);
          this.colorSchemes = response;
          this.amBuilderService.colorSchemes = response;
          resolve(response);

        })
      })
  }
  //new code add

  drop(event: CdkDragDrop<string[]>) {
    console.log("event",event,this.section.order);
    let obj = {
      order : this.section.order,
      prevIndex : event.previousIndex,
      currIndex : event.currentIndex
    }
    moveItemInArray(this.section.order, event.previousIndex, event.currentIndex);

    // this.pubsub.publishEvent('REFRESH_IFRAME_EVENT', {});
    // localStorage.setItem("uData",JSON.stringify(this.section));

    console.log("dynamic",this.section);
    this.newHeaderBlock.emit(this.section);


    // this.dropItem.emit(obj)
  }
}
