import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../auth/auth.service';
import { AuthUser } from '../../auth/auth.model';

// declare global {
//   interface Window {
//     Beacon:any;
//   }
// }


@Component({
  selector: 'am-amp-layout',
  templateUrl: './amp-layout.component.html',
  styleUrls: ['./amp-layout.component.scss']
})
export class AmpLayoutComponent implements OnInit {

  loading = true;

  sidebar = {
    expanded: false
  };
  user: AuthUser;
  //  FB = window.Beacon; // ok now


  constructor(

    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {

    // window.Beacon('identify', {
    //   name: 'Mahesh',
    //   email: 'md16893@gmail.com',
    //   signature: '4Wx47U12LGnyQH29S7qw30BioDBHXjIBnAJnIUYnVb8=',
    // })

    sessionStorage.removeItem('handles')
    // TODO: Get user data
    this.authService.getUserDetails('amp')
      .subscribe(res => {
        this.authService.setUser(res);
        this.user = res;

        this.loading = false;

        try {
          window['Beacon']('identify', {
            name: this.user.name,
            email: this.user.supportEmail,
            'tier': this.user.planName,
            'shopDomain': this.user.shopDomain,
            'install date': this.user.installDate
          });
        } catch (e) {

        }
      }, err => {
        // TODO: Redirect to error page
        console.log(err);

        // Send to error page
        this.router.navigate(['/error']);
        return;
      });
  }

  sidebarExpandedChanged(sidebarExpanded): void {
    this.sidebar.expanded = sidebarExpanded;
  }

}
