import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';
import { AmBuilderService } from '../../am-builder.service';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { ShopDetails, PAGE_TYPE, PageType, Theme } from '../../../am-builder.model';
import { NotifierService } from 'angular-notifier';
import { FormDataPrepareService } from '../services/form-data-prepare.service';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SaveConfirmationComponent } from './save-confirmation/save-confirmation.component';
import { RefreshConfirmationComponent } from './refresh-confirmation/refresh-confirmation.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BackNavigationConfirmationComponent } from './back-navigation-confirmation/back-navigation-confirmation.component';

@Component({
  selector: 'am-top-navbar-v2',
  templateUrl: './top-navbar-v2.component.html',
  styleUrls: ['./top-navbar-v2.component.scss']
})
export class TopNavbarV2Component implements OnInit {

  @Input()
  type: string;

  theme: Theme;
  story: any;
  selectedPageType: string;
  pageTypes: Array<any>;
  selectedViewportSize = 'SMALL';
  shopDetails: ShopDetails;

  @Output() pageChangeEvent = new EventEmitter();
  @Output() pageRefreshEvent = new EventEmitter();


  subscription : Subscription;
  themeName = '';
  isFieldEdited = false;
  previewUrl = '';
  pageHandles = [];
  themeId;

  selectedTemplate : any = ''
  constructor(
    private pubsub: NgxPubSubService,
    private amBuilderService: AmBuilderService,
    private notifier: NotifierService,
    private data_prepare : FormDataPrepareService,
    private modalService: NgbModal,
    private route : Router,
    private activeRoute : ActivatedRoute

  ) {
  }

  ngOnInit() {
     this.pageHandles = JSON.parse(sessionStorage.getItem('handles')) || [] ;
     this.themeId = this.activeRoute.snapshot.paramMap.get('id');

     this.getPagesTemplates();

    this.theme = this.amBuilderService.theme;
    this.themeName = localStorage.getItem('themeName');
    //console.log(this.theme);
    this.fieldEdited();
    this.shopDetails = this.amBuilderService.shopDetails;

    this.selectedPageType = this.amBuilderService.builderConfig.currentPage;
    if(this.amBuilderService.builderConfig.currentPage == 'PRODUCT_PAGE') {

      this.selectedPageType = 'templates/product.json';
    }

    // console.log("this.selectedPageType",this.selectedPageType)

    this.pageTypes = [{
      label: 'Home page',
      value: 'templates/index.json',
      base : `index`
    }, {
      label: 'Products',
      value: 'templates/product.json',
      base : `product`
    }, {
      label: 'Collections',
      value: 'templates/collection.json',
      base : `collection`
    }, {
      label: 'Collections list',
      value: 'templates/list-collections.json',
      base : `list-collections`
    }, {
      label: 'Blogs',
      value: 'templates/blog.json',
      base : `blog`
    }, {
      label: 'Blog posts',
      value: 'templates/article.json',
      base : `article`
      // }, {
      //   label: 'Error page',
      //   value: 'ERROR_PAGE'
    },
    {
      label: 'Pages',
      value: 'templates/page.json',
      base : `pages`
    }];

    this.pageChange(this.selectedPageType)

    // this.getSaveData();
  }

  getPagesTemplates() {

    this.amBuilderService.getPageTemplates(this.themeId).subscribe((res) => {

      // console.log(res);
      this.addTemplatesInNavigation(res)
    })

  }
  addTemplatesInNavigation(arr) {

    for(let i=0; i < arr.length; i++) {

      if(arr[i]['is_template_working']){
      let obj = {
        label : arr[i].type + "-" + arr[i].template,
        value : `templates/${arr[i].type}.${arr[i].template}.json`,
        base : arr[i].type
      }
      if(obj.base == 'pages'){
        obj.value = `templates/page.${arr[i].template}.json`

      }
      this.pageTypes.push(obj)
    }
    }

  }

 async pageChange(page) {
    // console.log(page);
    this.amBuilderService.builderConfig.currentPage = page;
    await this.setBasePage(page);
    this.preview();
    this.pageChangeEvent.emit(page);
    this.data_prepare.setPageChangeEvent(true);
  }

  setViewportSize(size: string) {
    this.selectedViewportSize = size;
    this.pubsub.publishEvent('VIEWPORT_CHANGE', size);
  }

  // getSaveData(){

  //  this.subscription = this.data_prepare.getSaveData().subscribe(res => {

  //   //console.log("getSaveData",res)
  //  })
  // }

  saveConfig() {
    
    // Send request to update configuration
    // this.amBuilderService.updateTheme()
    //   .subscribe((res) => {

    //     // If ok, make new "state" to now
    //     // "state" is needed for cancelling

    //     // Refresh the iframe

    //     // Show notification that it was saved ok?
    //     this.notifier.notify('default', 'Theme configuration updated.');

    //   }, (err) => {
    //     // Show notification that it failed
    //     this.notifier.notify('error', 'Failed updating configuration, please try again.');
    //   });

    // const modalRef = this.modalService.open(SaveConfirmationComponent);
    // // modalRef.componentInstance.theme = theme;
    // modalRef.result.then((result) => {
    //   // theme.name = result;
    // }, (reason) => { });

    this.data_prepare.setFormFlag(true);


  }

  refresh(){
    const modalRef = this.modalService.open(RefreshConfirmationComponent);
    // modalRef.componentInstance.theme = theme;
    modalRef.result.then((result) => {
      // theme.name = result;
      //console.log(result);
      if(result){
    this.pageRefreshEvent.emit(this.amBuilderService.builderConfig.currentPage)

      }
    }, (reason) => { });

  }

  fieldEdited(){

    this.subscription = this.data_prepare.getFieldEditedEvent().subscribe(res => {

      this.isFieldEdited = res;

    })
  }

  back(){

    // [routerLink]="['/amp/themes']"

    if(this.isFieldEdited){

      const modalRef = this.modalService.open(BackNavigationConfirmationComponent);
      // modalRef.componentInstance.theme = theme;
      modalRef.result.then((result) => {
        // theme.name = result;
        //console.log(result);
        if(result){
          this.data_prepare.setFieldEditedEvent(false);
          this.route.navigate(['/amp/themes'])

        }
      }, (reason) => { });

    } else {
      this.route.navigate(['/amp/themes'])
    }
  }

  preview(){

    let userDetails = JSON.parse(localStorage.getItem('ampify-user'));

    let proxy = '/a/amp';
    if(userDetails.domain == 'regency-chess-sets.myshopify.com'){
      proxy = '/apps/fireamp'
    }
    let tmp = this.amBuilderService.builderConfig.currentPage.split(".");
    console.log(tmp);

    // console.log(this.pageHandles);
    if(this.amBuilderService.builderConfig.currentPage.includes('templates/product')){

      this.previewUrl = `https://${userDetails.domain}${proxy}/products/${this.pageHandles[0]['handle']}?theme=${this.themeId}&dev=true`

      if(tmp && tmp.length > 2){

        this.previewUrl = `https://${userDetails.domain}${proxy}/products/${this.pageHandles[0]['handle']}?theme=${this.themeId}&dev=true&template=${tmp[1]}`
      }

    } else if(this.amBuilderService.builderConfig.currentPage.includes('templates/collection')){

      this.previewUrl = `https://${userDetails.domain}${proxy}/collections/${this.pageHandles[1]['handle']}?theme=${this.themeId}&dev=true`

      if(tmp && tmp.length > 2){

        this.previewUrl = `https://${userDetails.domain}${proxy}/collections/${this.pageHandles[1]['handle']}?theme=${this.themeId}&dev=true&template=${tmp[1]}`

      }

    }
    else if(this.amBuilderService.builderConfig.currentPage.includes('templates/list-collections')){

      this.previewUrl = `https://${userDetails.domain}${proxy}/collections?theme=${this.themeId}&dev=true`


    }
    else if(this.amBuilderService.builderConfig.currentPage.includes('templates/blog')){

      this.previewUrl = `https://${userDetails.domain}${proxy}/blogs/${this.pageHandles[2]['handle']}?theme=${this.themeId}&dev=true`

      if(tmp && tmp.length > 2){

        this.previewUrl = `https://${userDetails.domain}${proxy}/blogs/${this.pageHandles[2]['handle']}?theme=${this.themeId}&dev=true&template=${tmp[1]}`

      }
    }
    else if(this.amBuilderService.builderConfig.currentPage.includes('templates/article')){

      this.previewUrl = `https://${userDetails.domain}${proxy}/blogs/${this.pageHandles[2]['handle']}/${this.pageHandles[3]['handle']}?theme=${this.themeId}&dev=true`

      if(tmp && tmp.length > 2){

        this.previewUrl = `https://${userDetails.domain}${proxy}/blogs/${this.pageHandles[2]['handle']}/${this.pageHandles[3]['handle']}?theme=${this.themeId}&dev=true&template=${tmp[1]}`

      }
    }
    else if(this.amBuilderService.builderConfig.currentPage.includes('templates/index')){

      this.previewUrl = `https://${userDetails.domain}${proxy}?theme=${this.themeId}&dev=true`

    }

    else if(this.amBuilderService.builderConfig.currentPage.includes('templates/page')){

      this.previewUrl = `https://${userDetails.domain}${proxy}/pages/${this.pageHandles[4]['handle']}?theme=${this.themeId}&dev=true`

    }



  }
  navigate(){

    window.open(this.previewUrl, '_blank').focus();

  }

  setBasePage(page){

    console.log("setBasePage",page);
    return new Promise((resolve) => {

      for(let i=0; i < this.pageTypes.length; i++) {

        if(this.pageTypes[i]['value'] == page){

          this.amBuilderService.builderConfig.basePage = this.pageTypes[i]['base'];
          resolve('')
        }
      }

    })
  }
}
