<div>
  
      <div class="form-row">
        <!-- <am-form-v2 [formJSON]="formArray" (closeForm)="hideForm()"></am-form-v2> -->
      </div>
  
      <!-- <div class="form-row">
        <button mat-stroked-button type="submit" [disabled]="!form.valid">Save</button>
        <button *ngIf="isDialog" mat-stroked-button type="button" (click)="onCancel()">Cancel</button>
      </div> -->
  
    <!-- <div *ngIf="payLoad" class="form-row">
      <strong>Saved the following values</strong><br>{{payLoad}}
    </div> -->
  </div>