import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { AmBuilderService } from '../../../am-builder.service';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'am-edit-component-details',
  templateUrl: './edit-component-details.component.html',
  styleUrls: ['./edit-component-details.component.scss']
})
export class EditComponentDetailsComponent implements OnInit {

  @Input()
  component: any;

  @Input()
  type: string;

  globalSettings: any;
  sections: any;

  editData: any;

  @Output() closeEditComponentDetailsPanel = new EventEmitter();
  @Output() sectionRemovedEvent = new EventEmitter();

  showAddContentList = false;

  constructor(
    private amBuilderService: AmBuilderService,
    private pubsub: NgxPubSubService
  ) {
    this.globalSettings = amBuilderService.theme.global_settings;
    this.sections = amBuilderService.theme.sections;
  }

  ngOnInit() {

    if (this.type === 'THEME_SETTINGS') {
      this.editData = {
        type: this.component.id,
        settings: this.globalSettings,
        blocks: {},
        block_order: []
      };
    } else {
      // Data being edited is specific section

      // Check if this section exist?
      if (!this.sections[this.component.id]) {
        this.sections[this.component.id] = {
          type: this.component.id,
          settings: {},
          block_order: [],
          blocks: {}
        };
      }
      this.sections[this.component.id].settings = this.sections[this.component.id].settings || {};
      this.sections[this.component.id].blocks = this.sections[this.component.id].blocks || {};
      this.sections[this.component.id].block_order = this.sections[this.component.id].block_order || [];

      this.editData = this.sections[this.component.id] || {};
    }

    this.component.sections = [];
    this.component.settings = this.component.settings || {};

    let currentSectionIndex = -1;

    // Change the array to have sections and in there components
    for (let i = 0; i < this.component.settings.length; i++) {
      const setting = this.component.settings[i];
      if (setting.type === 'header') {
        this.component.sections.push({
          header: setting,
          settings: []
        });
        currentSectionIndex++;
      } else {
        if (currentSectionIndex < 0) {
          currentSectionIndex = 0;
        }
        if (!this.component.sections[currentSectionIndex]) {
          this.component.sections[currentSectionIndex] = {
            header: {},
            settings: []
          };
        }
        this.component.sections[currentSectionIndex].settings.push(setting);
      }
    }
    // console.log("section",this.sections);
    // console.log("component",this.component);

  }

  closeDialog(): void {
    this.closeEditComponentDetailsPanel.emit({});
  }

  getValueForConfig(id: string, defaultValue: string) {
    defaultValue = defaultValue || '';
    return this.globalSettings[id] || defaultValue;
  }

  removeSection(component) {
    const removedComponentId = component.id;

    if (removedComponentId.indexOf('integration') === 0) {
      // Find this block by ID and remove it from array
      const index = this.amBuilderService.theme.sectionContent.product.indexOf(component.id);
      if (index > -1) {
        // Remove item from array at this position
        this.amBuilderService.theme.sectionContent.product.splice(index, 1);

        // Remove data as well for this block so it's no longer saved on backend
        delete this.sections[component.id];

        this.configurationChanged();

        this.sectionRemovedEvent.emit(removedComponentId);
      }
    } else {
      // Find this block by ID and remove it from array
      const index = this.amBuilderService.theme.sectionContent.home.indexOf(component.id);
      if (index > -1) {
        // Remove item from array at this position
        this.amBuilderService.theme.sectionContent.home.splice(index, 1);

        // Remove data as well for this block so it's no longer saved on backend
        delete this.sections[component.id];

        this.configurationChanged();

        this.sectionRemovedEvent.emit(removedComponentId);
      }
    }

    this.closeEditComponentDetailsPanel.emit({});
  }

  addBlockContent(block) {
    // Hide list for adding of content
    this.showAddContentList = false;

    // If there is n
    if (this.component.max_blocks && this.component.max_blocks <= this.sections[this.component.id].block_order.length) {
      // Already at the limit for this content
      return;
    }

    // TODO: First check if it is ok to add this block

    const blockName = block.type + '-' + (Math.floor(Math.random() * 10000) + 1000);
    this.sections[this.component.id].block_order.push(blockName);
    // Get values from settings from this block and create object

    const blockDetails = {
      type: block.type,
      settings: {}
    };

    const getValue = function (value) {
      if (value.default) {
        if (value.default.en) {
          return value.default.en;
        }
        return value.default;
      }
      return '';
    };

    // Get block settings
    for (let i = 0; i < block.settings.length; i++) {
      if (block.settings[i].id) {
        blockDetails.settings[block.settings[i].id] = getValue(block.settings[i]) || '';
      }
    }

    this.sections[this.component.id].blocks[blockName] = blockDetails;

    this.configurationChanged();
  }

  removeBlockContent(blockId: string) {
    // Find this block by ID and remove it from array
    const index = this.sections[this.component.id].block_order.indexOf(blockId);
    if (index > -1) {
      // Remove item from array at this position
      this.sections[this.component.id].block_order.splice(index, 1);

      // Remove data as well for this block so it's no longer saved on backend
      delete this.sections[this.component.id].blocks[blockId];

      this.configurationChanged();
    }
  }

  openAddContentList() {
    this.showAddContentList = true;
  }

  configurationChanged() {
    const conf = {
      global_settings: this.globalSettings,
      sections: this.sections
    };
    this.pubsub.publishEvent('CONFIGURATION_CHANGE', conf);
  }

  dropBlock($event: CdkDragDrop<{ data: any }[]>) {
    // Find this item and move it to the position
    this.moveInArray(this.sections[this.component.id].block_order, $event.previousIndex, $event.currentIndex);

    // Re draw everything
    this.configurationChanged();
  }

  dragStarted($event) {
    // console.log('make the element collapsed');
  }

  private moveInArray(arr, fromIndex, toIndex) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }
}
