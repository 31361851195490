import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges,ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Constants } from '../../../classes/constant';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';

import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { AmBuilderService } from '../../../../am-builder.service';

@Component({
  selector: 'am-expandable-block',
  templateUrl: './expandable-block.component.html',
  styleUrls: ['./expandable-block.component.scss']
})
export class ExpandableBlockComponent implements OnInit, OnChanges {


  @Input() section: any = {};
  @Input() item: any;
  @Input() index;
  @Input() showIcons = true;
  @Input() orders: any = [];

  @Output() showSectionForm = new EventEmitter();
  @Output() panelFlagChange = new EventEmitter();
  @Output() addBlocks = new EventEmitter();

  @ViewChild(MatExpansionPanel, {static: true}) panelH: MatExpansionPanel;


  targetArray: any = [];
  routerArray: any = [];

  blocks : any = [

  ];
  version : any;

  constructor(
    public pubsub: NgxPubSubService = null,
    public amBuilderService: AmBuilderService = null


  ) { }

  ngOnInit() {

    this.version = localStorage.getItem('dawnVersion')

    // //console.log(this.section, this.item);
    // this.checkPanelOpen();
    this.panelH.open();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // //console.log("data changes...",this.section)
  }

  drop(event: CdkDragDrop<string[]>) {
    let obj = {
      order: this.section.order,
      prevIndex: event.previousIndex,
      currIndex: event.currentIndex
    }
    this.targetArray = this.section.order;
    moveItemInArray(this.orders, event.previousIndex, event.currentIndex);

    this.section.order = this.orders;
    // //console.log(this.orders);
    // this.dropItem.emit(obj)

  }

  dropChild(event: CdkDragDrop<string[]>, index: any, title) {

    // //console.log("**dropChild**")

    let obj = {
      order: this.section.sections[title].block_order,
      prevIndex: event.previousIndex,
      currIndex: event.currentIndex
    }
    this.targetArray = this.section.sections[title].block_order
    moveItemInArray(this.section.sections[title].block_order, event.previousIndex, event.currentIndex);
    localStorage.setItem("uData",JSON.stringify(this.section));
    this.pubsub.publishEvent('REFRESH_IFRAME_EVENT', this.amBuilderService.builderConfig.currentPage);

    // //console.log(this.targetArray);

    // this.dropItem.emit(obj)
    // moveItemInArray(this.targetArray, event.previousIndex, event.currentIndex);
  }
  openPanel(item, flag) {

    // this.section.sections[item]['isPanelOpened'] = flag;
    let obj = {
      'item' : item,
      'flag' : flag
    }
    // //console.log(obj)

    this.panelFlagChange.emit(obj)
  }

  closePanel(item, flag) {

    this.section.sections[item]['isPanelOpened'] = flag;
  }

  showParentForm(section, subsection, settings, type, index, fieldType, parent) {

    // //console.log(section, subsection, settings, type, index, fieldType, parent);
    // //console.log(Constants.getBlockSchemaObject(parent,subsection));

    this.routerArray = [];
    let obj = {};
    if (type == 'parent') {
      this.routerArray.push(section);
      obj = {
        route: this.routerArray,
        settings: settings,
        type: type,
        pIndex: -1,
        headingText: section,
        parent: parent,
        schemaSettings: Constants.getSchemaObject(parent) || []

      }
      // //console.log("showParentForm", obj);

      this.showSectionForm.emit(obj);

    } else {

      this.routerArray = [];
      this.routerArray.push(section);
      this.routerArray.push(subsection);
      let obj = {};
      obj = {
        route: this.routerArray,
        settings: settings,
        type: type,
        // pIndex : index,
        parent: parent,

      }
      if(section == 'announcement-bar') {

        obj['schemaSettings'] = Constants.getBlockSchemaObject(parent, 'announcement') || []
        obj['headingText'] = 'Announcement'

      } else {
        obj['schemaSettings'] = Constants.getBlockSchemaObject(parent, fieldType) || []
        obj['headingText'] = fieldType

      }
      // //console.log("showParentForm", obj);
      this.showSectionForm.emit(obj);

    }
    //  //console.log("showParentForm",obj);
    //   if(type == 'child'){
    // }
  }

  disableSection(section) {

    // //console.log("disableSection",section);
    this.section.sections[section]['disabled'] = true;
    localStorage.setItem("uData",JSON.stringify(this.section));
    // this.amBuilderService.theme.sectionContent = this.section;
        this.pubsub.publishEvent('REFRESH_IFRAME_EVENT', this.amBuilderService.builderConfig.currentPage);

  }
  enableSection(section) {

    this.section.sections[section]['disabled'] = false;
    localStorage.setItem("uData",JSON.stringify(this.section));
    // this.amBuilderService.theme.sectionContent = this.section;
        this.pubsub.publishEvent('REFRESH_IFRAME_EVENT', this.amBuilderService.builderConfig.currentPage);


  }
  disableSubSection(section, subsection) {

    // //console.log("disableSection",this.amBuilderService.builderConfig.currentPage);
    this.section.sections[section].blocks[subsection]['disabled'] = true;
    localStorage.setItem("uData",JSON.stringify(this.section));
    // this.amBuilderService.theme.sectionContent = this.section;
        this.pubsub.publishEvent('REFRESH_IFRAME_EVENT', this.amBuilderService.builderConfig.currentPage);

  }
  enableSubSection(section, subsection) {

    // //console.log("disableSection",section,subsection,this.section.sections[section].blocks[subsection]);
    this.section.sections[section].blocks[subsection]['disabled'] = false;
    localStorage.setItem("uData",JSON.stringify(this.section));
    this.pubsub.publishEvent('REFRESH_IFRAME_EVENT', this.amBuilderService.builderConfig.currentPage);

  }

  showBlockOptions(section,subsection){

    console.log(section);
    let presets;
    if(this.version == '2'){
      presets = [...Constants.schemasV7[section].presets[0].blocks];
    } else if(this.version == '3'){
      presets = [...Constants.schemasV10[section].presets[0].blocks];

    } else if(this.version == 'ef420'){
      presets = [...Constants.schemasEf420[section].presets[0].blocks];
    }


    this.blocks = presets
    // //console.log(section,presets)

  }

  addBlock(section,field,parentType,sectionName){

    //console.log("section",section,field,parentType,sectionName)
    let obj = {
      field : field,
      sectionName : sectionName,
      parentType : parentType
    }
    this.addBlocks.emit(obj);
    // let tmp = parentType.split("-").join("_")
    // //console.log("presets", tmp, field)
    // let block = {...Constants.addSectionData.sections[tmp].blocks[field]}

    // //console.log("block",block);
    // let newfield = field;

    // let cnt = 0;

    //     for(let j=0; j < section.block_order.length; j++){

          // if(section.block_order[j].includes(newfield)){
          //   cnt++;
          //   break
          // }
    //     }

    // //console.log("cnt",cnt,sectionName);
    // // this.section.sections[sectionName] = {...this.section.sections[sectionName]}
    // let tmpName = newfield + "-" + (cnt - 1);

    // let newBlock = {
    //   settings : {...block.settings},
    //   type : block.type
    // }
    // if(cnt != 0){
    //   section.block_order.push(tmpName);
    //   section.blocks[tmpName] = newBlock;
    // } else {

    //   section.block_order.push(newfield);
    //   section.blocks[newfield] = newBlock;
    // }

    // //console.log("section",this.section)
  }

  getIcon(section){

    // //console.log(section);
    if(section?.type == 'text'){

      return 'format_align_left'
    } else if(section?.type == 'heading' || section?.type == 'title'){
      return 'title'
    } else {
      return 'crop_free'
    }
  }

  getSchemaObjects(parent){

   let tmp = Constants.getSchemaObject(parent)
   if(tmp){
     return false;
   } else {
     return true;
   }
  }

}
