import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ColorPickerModule } from 'ngx-color-picker';
import { MarkdownModule } from 'ngx-markdown';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { SharedModule } from '../../shared/shared.module';
import { PolarisModule } from '../../polaris/polaris.module';



import { EditorV2RoutingModule } from './editorv2-routing.module';


import { Editorv2Component } from './editorv2.component';
import { BuilderPreviewV2Component } from './builder-preview-v2/builder-preview-v2.component';
import { EditFieldV2Component } from './edit-field-v2/edit-field-v2.component';
import { LeftSidebarV2Component } from './left-sidebar-v2/left-sidebar-v2.component';
import { TopNavbarV2Component } from './top-navbar-v2/top-navbar-v2.component';
import { DynamicSidebarV2Component } from './left-sidebar-v2/dynamic-sidebar-v2/dynamic-sidebar-v2.component';
import { DynamicFormV2Component } from './left-sidebar-v2/dynamic-form-v2/dynamic-form-v2.component';
import { FormV2Component } from './left-sidebar-v2/dynamic-form-v2/form-v2/form-v2.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MaterialModule } from './material.module';
import { SidebarSectionsV2Component } from './left-sidebar-v2/sidebar-sections-v2/sidebar-sections-v2.component';
import { FormDataPrepareService } from './services/form-data-prepare.service';
import { ExpandableBlockComponent } from './left-sidebar-v2/dynamic-sidebar-v2/expandable-block/expandable-block.component';
import { HeaderV2Component } from './left-sidebar-v2/header-v2/header-v2.component';
import { FooterV2Component } from './left-sidebar-v2/footer-v2/footer-v2.component';
import { ThemeSettingsV2Component } from './left-sidebar-v2/theme-settings-v2/theme-settings-v2.component'
import { SaveConfirmationComponent } from './top-navbar-v2/save-confirmation/save-confirmation.component'
import { RefreshConfirmationComponent } from './top-navbar-v2/refresh-confirmation/refresh-confirmation.component'
import { BackNavigationConfirmationComponent } from './top-navbar-v2/back-navigation-confirmation/back-navigation-confirmation.component';

@NgModule({
  declarations: [
  
    Editorv2Component,
       BuilderPreviewV2Component,
       EditFieldV2Component,
       LeftSidebarV2Component,
       TopNavbarV2Component,
       DynamicSidebarV2Component,
       DynamicFormV2Component,
       FormV2Component,
       SidebarSectionsV2Component,
       ExpandableBlockComponent,
       HeaderV2Component,
       FooterV2Component,
       ThemeSettingsV2Component,
       SaveConfirmationComponent,
       RefreshConfirmationComponent,
       BackNavigationConfirmationComponent
       
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,

    EditorV2RoutingModule,

    PolarisModule,
    ColorPickerModule,
    MarkdownModule.forRoot(),
    DragDropModule,
    MatExpansionModule,
    MatIconModule,
    MaterialModule
  ],
  exports: [
  ],
  providers:[FormDataPrepareService],
  schemas : [CUSTOM_ELEMENTS_SCHEMA]
})
export class EditorV2Module { }
