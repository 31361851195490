<div class="onboarding-nav">
  <div class="logo">
    <img alt="PWA by AmpifyMe logo" src="/assets/pwa/images/logo-full.png">
  </div>

  <!-- <div class="actions">
    <a [routerLink]="['/pwa/configuration']" class="nav-link pl-2">
      <span>Skip onboarding</span>
    </a>
  </div> -->
</div>

<main class="onboarding-main">
  <router-outlet></router-outlet>
</main>

<footer>
  <div class="Polaris-ProgressBar Polaris-ProgressBar--sizeMedium">
    <progress class="Polaris-ProgressBar__Progress" [value]="getProgressPercentage()" max="100"></progress>
    <div class="Polaris-ProgressBar__Indicator" [style.width.%]="getProgressPercentage()">
      <span class="Polaris-ProgressBar__Label"></span>
    </div>
  </div>
</footer>