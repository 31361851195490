import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { PWARoutingModule } from './pwa-routing.module';
import { PwaLayoutComponent } from './pwa-layout/pwa-layout.component';
import { PwaLayoutFooterComponent } from './pwa-layout/pwa-layout-footer/pwa-layout-footer.component';
import { PwaLayoutHeaderComponent } from './pwa-layout/pwa-layout-header/pwa-layout-header.component';
import { PwaLayoutLeftSidebarComponent } from './pwa-layout/pwa-layout-left-sidebar/pwa-layout-left-sidebar.component';

@NgModule({
  declarations: [
    PwaLayoutComponent,
    PwaLayoutFooterComponent,
    PwaLayoutHeaderComponent,
    PwaLayoutLeftSidebarComponent
  ],
  imports: [
    CommonModule,
    SharedModule,

    PWARoutingModule
  ]
})
export class PwaModule { }
