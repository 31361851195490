import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'am-editor-error-message',
  templateUrl: './editor-error-message.component.html',
  styleUrls: ['./editor-error-message.component.scss']
})
export class EditorErrorMessageComponent implements OnInit {

  @Input()
  shopDomain: string;

  constructor() { }

  ngOnInit() {
  }

}
