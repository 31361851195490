import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { environment } from '../environments/environment';
import { SharedService } from './shared/shared.service';

// Hack for google analytics
declare let ga: any;

@Component({
  selector: 'am-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  isProduction = environment.production;

  constructor(
    public router: Router,
    public sharedService: SharedService
  ) {

    // this.clearConsoleLog();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');

        window.scrollTo(0, 0);
      }
    });
  }

  private clearConsoleLog(): void {
    // Get all feature toggles
    const featureToggles = this.sharedService.getFeaturesToggle() || '';
    const featureTogglesArray = featureToggles.split(',');

    // Kepp console logs if feature toggle enabled
    if (featureTogglesArray.indexOf('logging') > -1) {
      return;
    }

    // Remove console logs if in production
    if (this.isProduction && window) {
      window.console.clear();
      window.console.log(
        '%cIn case you are seeing this, we at AmpifyMe are hiring :) 🚀 ',
        'color: rgb(40, 152, 251); font-size: 16pt'
      );
      window.console.log = () => { };
      window.console.info = () => { };
      window.console.warn = () => { };
      window.console.error = () => { };
    }
  }
}
