<div class="Polaris-TextContainer">
  <p class="Polaris-DisplayText Polaris-DisplayText--sizeExtraLarge">
    You're up and running 🚀
  </p>
  <div class="Polaris-EmptyState__Content">
    <p>
      Go ahead and enable even more amazing features, like for example Push Notifications, in just a few clicks.
    </p>
  </div>
  <div class="Polaris-EmptyState__Actions">
    <div class="Polaris-Stack Polaris-Stack--alignmentCenter">

      <div class="Polaris-Stack__Item">
        <a [routerLink]="['/pwa/onboarding/quick-config']" class="Polaris-Button Polaris-Button--plain"
          data-polaris-unstyled="true">
          <span class="Polaris-Button__Content">
            <span class="Polaris-Button__Text">Back</span>
          </span>
        </a>
      </div>

      <div class="Polaris-Stack__Item">
        <a [routerLink]="['/pwa']" class="Polaris-Button Polaris-Button--primary Polaris-Button--sizeLarge">
          <span class="Polaris-Button__Content">
            <span class="Polaris-Button__Text">
              Unlock additional power
            </span>
          </span>
        </a>
      </div>

    </div>
  </div>
</div>