import { Component, OnInit, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { FormDataPrepareService } from '../../services/form-data-prepare.service';

@Component({
  selector: 'am-save-confirmation',
  templateUrl: './save-confirmation.component.html',
  styleUrls: ['./save-confirmation.component.scss']
})
export class SaveConfirmationComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private data_prepare : FormDataPrepareService,

  ) { }

  ngOnInit(): void {
  }

  cancel(): void {
    this.activeModal.dismiss();
  }

  save(){

    this.data_prepare.setFormFlag(true);
    this.activeModal.dismiss();


  }

}
