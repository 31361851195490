import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '../auth.service';

@Component({
  selector: 'am-auth-redirect',
  templateUrl: './auth-redirect.component.html',
  styleUrls: ['./auth-redirect.component.scss']
})
export class AuthRedirectComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    const token = this.route.snapshot.queryParams['token'];
    const app = this.route.snapshot.queryParams['app'] || 'amp';
    const status = this.route.snapshot.queryParams['status'];
    const action = this.route.snapshot.queryParams['action'];

    if (!token) {
      // Send to error page
      this.router.navigate(['/error']);
      return;
    }

    // Add this in the localstorage
    this.authService.setAuth(token, app);
    if (app === 'pwa') {
      if (status && status === 'start_onboarding') {
        this.router.navigate(
          ['/pwa/onboarding/welcome'],
          {
            relativeTo: this.route,
            replaceUrl: true
          }
        );
      } else {
        if (status && status === 'feature_native_android_charge_accepted') {
          this.router.navigate(
            ['/pwa/native'],
            {
              relativeTo: this.route,
              replaceUrl: true
            }
          );
        } else {
          // Redirect so we can remove token from url
          this.router.navigate(
            ['/pwa'],
            {
              relativeTo: this.route,
              replaceUrl: true
            }
          );
        }
      }
    } else {
      if (status && status === 'start_onboarding') {
        this.router.navigate(
          ['/amp/onboarding/welcome'],
          {
            relativeTo: this.route,
            replaceUrl: true
          }
        );
      } else {

        // Redirect so we can remove token from url
        this.router.navigate(
          ['/amp'],
          {
            relativeTo: this.route,
            replaceUrl: true
          }
        );
      }
    }
  }

}
