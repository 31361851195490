<div id="addNewSnippet" class="pick-items-preview">
  <div class="add-new-snippet-header header">
    <h2>Add feature</h2>
    <div (click)="closeDialog()" id="closeAddNewSection" class="close">
      <i class="icon-close"></i>
    </div>

    <!-- components -->
    <ul id="addNewSnippetComponents" class="theme-editor-action-list">
      <ng-container *ngFor="let section of sections">
       
        <li *ngIf="section.type != 'integration-reviews-shopify-product-reviews'" class="theme-editor-action-list-item component-item component-item-sortable">
          <span>{{section.name | getLocalizedName}}</span>
          <button (click)="addSection(section)" class="button button-primary btn-mini btn-add">Add</button>
        </li>
      </ng-container>
     
    </ul>

  </div>
</div>