import { Component, OnInit } from '@angular/core';
import { AmBuilderService } from '../../../am-builder.service';
import { Theme, BuilderConfig } from '../../../../am-builder.model';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { CdkDragDrop } from '@angular/cdk/drag-drop';


@Component({
  selector: 'am-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.scss']
})
export class SectionsComponent implements OnInit {

  changePageTypeEvent: any;

  showEditComponent: boolean;
  showAddComponent: boolean;
  addComponentPageType: string;

  selectedEditComponent: any;

  theme: Theme;
  builderConfig: BuilderConfig;

  contentForIntegrations: Array<string>;
  sectionsForIntegrations: Array<any>;

  contentForHome: Array<string>;
  sectionsForHome: Array<any>;

  contentForProduct: Array<string>;
  sectionsForProduct: Array<any>;

  contentForStory: Array<string>;
  sectionsForStory: Array<any>;

  constructor(
    private amBuilderService: AmBuilderService,
    private pubsub: NgxPubSubService
  ) {
    this.theme = this.amBuilderService.theme;
    this.builderConfig = this.amBuilderService.builderConfig;
    this.contentForIntegrations = this.amBuilderService.theme.sectionContent.integrations;
    this.contentForHome = this.amBuilderService.theme.sectionContent.home;
    this.contentForProduct = this.amBuilderService.theme.sectionContent.product;

    this.contentForStory = this.amBuilderService.theme.sectionContent.story || [];

    // Get all the sections from section ids in contentForIntegrations
    this.sectionsForIntegrations = [];
    this.contentForIntegrations.forEach(id => {
      // Get this section
      const section = this.amBuilderService.theme.sections[id];
      if (section) {
        section.id = id;
        this.sectionsForIntegrations.push(section);
      }
    });

    // Get all the sections from section ids in contentForHome
    this.sectionsForHome = [];
    this.contentForHome.forEach(id => {
      // Get this section
      const section = this.amBuilderService.theme.sections[id];
      if (section) {
        section.id = id;
        this.sectionsForHome.push(section);
      }
    });

    // Get all the sections from section ids in contentForProduct
    this.sectionsForProduct = [];
    this.contentForProduct.forEach(id => {
      // Get this section
      const section = this.amBuilderService.theme.sections[id];
      if (section) {
        section.id = id;
        this.sectionsForProduct.push(section);
      }
    });

    // Get all the sections from section ids in contentForStory
    this.sectionsForStory = [];
    this.contentForStory.forEach(id => {
      // Get this section
      const section = this.amBuilderService.theme.sections[id];
      if (section) {
        section.id = id;
        this.sectionsForStory.push(section);
      }
    });

    this.selectedEditComponent = {};
  }

  ngOnInit() {
    this.showEditComponent = false;

    // Event for changing the page type
    this.changePageTypeEvent = this.pubsub.subscribe('PAGE_TYPE_CHANGE', (newValue) => {
      this.closeEditComponentDetailsPanel();
      this.closeAddComponentPanel(null);
    });
  }

  componentSelected(component: any) {

    // Try to get component details from sections
    const section = this.theme.sections[component.type];

    component.sections = section || {};

    // Set id
    const componentId = component.id;
    const showRemoveSection = component.showRemoveSection;

    // Try to find this section definition in the file
    component = this.theme.themeDetails.sections[component.type];

    this.selectedEditComponent = component;
    this.selectedEditComponent.id = componentId;
    this.selectedEditComponent.showRemoveSection = showRemoveSection;

    this.selectedEditComponent.sections = [this.selectedEditComponent.settings];
    this.showEditComponent = true;

    // console.log("id",component)
    const message = {
      type: 'SECTION_SELECT',
      configuration: {
        id: component.id
      }
    };
    this.pubsub.publishEvent('SEND_IFRAME_EVENT', message);
  }

  closeEditComponentDetailsPanel(): void {
    this.showEditComponent = false;
  }

  sectionRemovedHandler(sectionId: string) {

    if (sectionId.indexOf('integration') === 0) {
      const index = this.sectionsForProduct.findIndex(p => p.id === sectionId);
      if (index > -1) {
        this.sectionsForProduct.splice(index, 1);
      }
    } else {
      const index = this.sectionsForHome.findIndex(p => p.id === sectionId);
      if (index > -1) {
        this.sectionsForHome.splice(index, 1);
      }
    }
  }

  addNewComponent(pageType: string): void {
    // WILL DEPEND ON THE TYPE OF PAGE SELECTED
    this.addComponentPageType = pageType;
    this.showAddComponent = true;
  }

  closeAddComponentPanel(section): void {
    this.showAddComponent = false;

    if (section) {
      // Add this section to the home page?
      const id = section.type + '-' + (Math.floor(Math.random() * 10000) + 1000);

      // Set settings of this section to be the fields
      const sectionObj = {
        id: id,
        header: section.header,
        type: section.type,
        block_order: [],
        blocks: {},
        settings: {}
      };

      const getValue = function (value) {
        if (value.default) {
          if (value.default.en) {
            return value.default.en;
          }
          return value.default;
        }
        return '';
      };

      // Set section settings
      section.settings = section.settings || [];
      for (let i = 0; i < section.settings.length; i++) {
        if (section.settings[i].id) {
          sectionObj.settings[section.settings[i].id] = getValue(section.settings[i]) || '';
        }
      }

      // Push this section in all sections
      this.theme.sections[id] = sectionObj;

      if (this.addComponentPageType === 'integration') {
        // Push this section details in array of sections for home
        this.sectionsForIntegrations.push(sectionObj);

        // Push this section id to the array of sections for home
        this.contentForIntegrations.push(id);
      }

      if (this.addComponentPageType === 'home') {
        // Push this section details in array of sections for home
        this.sectionsForHome.push(sectionObj);

        // Push this section id to the array of sections for home
        this.contentForHome.push(id);
      }

      if (this.addComponentPageType === 'product') {
        // Push this section details in array of sections for home
        this.sectionsForProduct.push(sectionObj);

        // Push this section id to the array of sections for home
        this.contentForProduct.push(id);
      }

      // Re-render stuff
      this.configurationChanged();
    }
  }

  addNewStoryPage(): void {
    // Add this section to the home page?
    const id = 'story-page' + '-' + (Math.floor(Math.random() * 10000) + 1000);

    // Set settings of this section to be the fields
    const sectionObj = {
      id: id,
      header: 'Page',
      type: 'story-page',
      block_order: [],
      blocks: {},
      settings: {}
    };

    const getValue = function (value) {
      if (value.default) {
        if (value.default.en) {
          return value.default.en;
        }
        return value.default;
      }
      return '';
    };

    let section = this.amBuilderService.theme.themeDetails.sections['story-page'];
    section = section || {
      settings: {}
    };

    // Set section settings
    section.settings = section.settings || [];
    for (let i = 0; i < section.settings.length; i++) {
      if (section.settings[i].id) {
        sectionObj.settings[section.settings[i].id] = getValue(section.settings[i]) || '';
      }
    }

    // Push this section in all sections
    this.theme.sections[id] = sectionObj;

    // Push this section details in array of sections for home
    this.sectionsForStory.push(sectionObj);

    // Push this section id to the array of sections for home
    this.contentForStory.push(id);
  }

  sectionExists(sectionType): boolean {
    if (sectionType === 'custom') {
      return true;
    }
    if (this.theme && this.theme.themeDetails &&
      this.theme.themeDetails.sections &&
      this.theme.themeDetails.sections[sectionType] &&
      this.theme.themeDetails.sections[sectionType].settings) {
      return true;
    }
    return false;
  }

  dropSectionItem($event: CdkDragDrop<{ data: any }[]>, addComponentPageType? : string) {

    // Move this item to the new position
    this.addComponentPageType = addComponentPageType;

    if (this.addComponentPageType === 'integration') {
      this.moveInArray(this.contentForIntegrations, $event.previousIndex, $event.currentIndex);
      this.moveInArray(this.sectionsForIntegrations, $event.previousIndex, $event.currentIndex);
    }

    if (this.addComponentPageType === 'home') {
      this.moveInArray(this.contentForHome, $event.previousIndex, $event.currentIndex);
      this.moveInArray(this.sectionsForHome, $event.previousIndex, $event.currentIndex);
    }

    if (this.addComponentPageType === 'product') {
      this.moveInArray(this.contentForProduct, $event.previousIndex, $event.currentIndex);
      this.moveInArray(this.sectionsForProduct, $event.previousIndex, $event.currentIndex);
    }

    // Re draw everything
    this.configurationChanged();
  }

  configurationChanged() {
    const conf = {
      global_settings: this.theme.global_settings,
      sections: this.amBuilderService.theme.sections
    };
    this.pubsub.publishEvent('CONFIGURATION_CHANGE', conf);
  }

  private moveInArray(arr, fromIndex, toIndex) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

}
