<!-- AMP Upgrade Model -->

<div id="mainContainer">
  <div class="Polaris-Modal-Dialog__Container undefined" data-polaris-layer="true" data-polaris-overlay="true">
    <div>
      <div class="Polaris-Modal-Dialog__Modal" role="dialog" aria-labelledby="modal-header2" tabindex="-1">

        <div class="Polaris-Modal__BodyWrapper">
          <div class="Polaris-Modal__Body Polaris-Scrollable Polaris-Scrollable--vertical"
            data-polaris-scrollable="true">

            <div class="free-trial-banner">

              <h3 *ngIf="freeTrialDays > 0">
                {{freeTrialDays}} DAYS FREE TRIAL
              </h3>

              <h3 *ngIf="freeTrialDays <= 0">
                Select Your Plan
              </h3>


              <p> You can easily upgrade or downgrade at any point in time. </p>

              <div class="close" (click)="cancel()">
                <span class="Polaris-Icon Polaris-Icon--colorInkLighter Polaris-Icon--isColored">
                  <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                    <path
                      d="M11.414 10l6.293-6.293a.999.999 0 1 0-1.414-1.414L10 8.586 3.707 2.293a.999.999 0 1 0-1.414 1.414L8.586 10l-6.293 6.293a.999.999 0 1 0 1.414 1.414L10 11.414l6.293 6.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L11.414 10z"
                      fill-rule="evenodd"></path>
                  </svg>
                </span>
              </div>
            </div>


            <section style="
            flex-direction: column;justify-content: space-between;" class="Polaris-Modal-Section">

              <div style="margin-bottom: 0px;" class="row">

                <div *ngFor="let plan of plansArray; let i = index"
                  class="col-md-4 py-2 text-start {{ i < 2 ? 'margin40' : 'margin'}}"
                  [ngStyle]="{'border' : newSelectedPlan == plan.id ? '2px solid #2C74FF' : 'none'}"
                  (click)="selectNewPlan(plan.id)">

                  <div class="text-start">
                    <div class="flex-items">
                      <div style="display: flex;align-items: baseline;">
                        <h5 class="mb-3"> {{plan.name}} </h5>

                        <div *ngIf="currentPlanName == plan.id" class="current-plan-chip">Current Plan</div>
                      </div>

                      <!-- (click)="selectNewPlan(plan)" -->
                      <!-- <div *ngIf="newSelectedPlan != plan.id" class="selectplan-checkbox" >
                    </div> -->

                      <img *ngIf="newSelectedPlan != plan.id" src="../../../assets/amp/images/unmark.svg" width="24px"
                        height="24px" style="cursor: pointer;">
                      <img *ngIf="newSelectedPlan == plan.id" src="../../../assets/amp/images/mark.svg" width="24px"
                        height="24px">

                    </div>

                    <span class="h2 text-muted"> {{plan.priceWithCurrency}} per month </span>

                  </div>
                  <div class="card-body">
                    <ul class="list-unstyled mb-4">
                      <li *ngFor="let offering of plan.offerings">
                        {{offering}}
                      </li>
                    </ul>
                  </div>

                  <div style="margin-bottom: 1.5em;">
                  </div>



                </div>


              </div>
              <hr class="price-divider">
              <div style="width: 100%;display: flex;justify-content: space-between;" *ngIf="newSelectedPlan">
                <div style="width:67%"></div>
                <div class="price-discount-section">

                  <p class="plan-name">Plan Selected: <span class="plan-value">{{newSelectedPlan}}</span> </p>
                  <p class="payable-amount">Payable Amount: <span
                      class="pay-value {{discountedPrice ? 'strike' : 'default'}}">{{newSelectedPlanValue}}</span> <span
                      *ngIf="discountedPrice" class="discount-value">${{discountedPrice}} per month</span> </p>

                  <div style="display: flex;">
                    <div class="Polaris-TextField" style="width: 60%;">
                      <input (input)="clear()" class="Polaris-TextField__Input" [(ngModel)]="discountCode"
                        [disabled]="currentPlanName == newSelectedPlan" placeholder="Discount code (optional)">
                      <div class="Polaris-TextField__Backdrop"></div>
                    </div>
                    <button [disabled]="currentPlanName == newSelectedPlan || !discountCode"
                      (click)="checkDiscountCode(discountCode, newSelectedPlanId)" class=" discount-now-btn">
                      Apply
                    </button>
                  </div>

                  <p class="successMessage">{{successMessage}}</p>
                  <p *ngIf="discountError" class="errorMessage">{{discountError}}</p>

                  <button [disabled]="currentPlanName == newSelectedPlan" class="start-now-btn"
                    (click)="goToUpgradePage()">
                    Start Now
                  </button>

                </div>
              </div>

            </section>


          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<div class="Polaris-Backdrop"></div>