import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'am-error-page-polaris',
  templateUrl: './error-page-polaris.component.html',
  styleUrls: ['./error-page-polaris.component.scss']
})
export class ErrorPagePolarisComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  tryAgain(): void {
    window.location.reload();
  }

  contactUs(): void {
    try {
      if (!window['Beacon']) {
        return;
      } else {
        window['Beacon']('open');
      }

    } catch (e) { }
  }

}
