<!-- [ngClass]="{'line-through' : getSchemaObjects(section.sections[item]?.type) }" -->
<div class="expandable">
    <mat-expansion-panel [hideToggle]="true">
        <mat-expansion-panel-header   #panelH>
            <!-- [ngStyle]="{'opacity' : section.sections[item].disabled ? '0.6' : '1' }" -->
            <span class="dropdown" *ngIf="section.sections[item] && section.sections[item].blocks">
                <mat-icon (click)="panelH._toggle();openPanel(item,true)"
                    *ngIf="!section.sections[item].isPanelOpened">
                    arrow_right
                </mat-icon>
                <mat-icon (click)="panelH._toggle();openPanel(item,false)"
                    *ngIf="section.sections[item].isPanelOpened">
                    arrow_drop_down
                </mat-icon>
            </span>
            <span class="section" (click)="showParentForm(item,'',section.sections[item].settings,'parent',index,'',section.sections[item].type)">


                <span class="Polaris-Icon {{section.sections[item] && section.sections[item].disabled ? 'opacity-disable' : 'opacity-enable'}}"
                    [ngStyle]="{'margin-left' : section.sections[item] && !section.sections[item].blocks ? '24px' : '0'}">
                    <span class="Polaris-VisuallyHidden">
                    </span>
                    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M2.5 1a1.5 1.5 0 0 0-1.5 1.5v15a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-15a1.5 1.5 0 0 0-1.5-1.5h-15zm13.5 4h-12v2h12v-2zm-12 4h12v2h-12v-2zm6 4h-6v2h6v-2z" />
                    </svg>
                </span>
                <p [ngStyle]="{'opacity' : section.sections[item] && section.sections[item].disabled ? '0.6' : '1' }"
                class="menu-option-title"
                    (click)="showParentForm(item,'',section.sections[item].settings,'parent',index,'',section.sections[item].type)">
                    {{section.sections[item] && section.sections[item].type | replaceName : {"type" : "parent", "headingText" : item, "parent" : section.sections[item] && section.sections[item].type} }}</p>

                <span class="actions" *ngIf="showIcons">
                    <div class="action-items">
                        <!-- [ngStyle]="{'margin-right': section.sections[item]['disabled'] ? '28px' :'10px'}" -->
                        <mat-icon *ngIf="section.sections[item] && section.sections[item]['disabled']" class="visibility-off margin-right16 icon20"
                            (click)="$event.stopPropagation();enableSection(item)">visibility_off</mat-icon>

                            <mat-icon *ngIf="section.sections[item] && !section.sections[item]['disabled']" class="drag-drop-icon margin-right16 icon20"
                            (click)="$event.stopPropagation();disableSection(item)">visibility</mat-icon>
                        <mat-icon class="drag-drop-icon icon20" cdkDragHandle>drag_indicator</mat-icon>
                    </div>
                </span>
            </span>
        </mat-expansion-panel-header>

        <span *ngIf="section.sections[item] && section.sections[item].isPanelOpened" cdkDropList (cdkDropListDropped)="dropChild($any($event),index,item)">
            <span *ngFor="let child of section.sections[item].block_order; let ix=index" class="sub-list">

                <!-- [ngStyle]="{'opacity' : section.sections[item].blocks[child].disabled ? '0.6' : '1' }" -->
                <div
                    class="section child-list" [ngStyle]="{'margin-top' : ix == 0 ? '11px' : '0'}" cdkDrag>
                    <span class="dropdown">
                    </span>

                    <mat-icon [ngStyle]="{'opacity' : section.sections[item].blocks[child] && section.sections[item].blocks[child].disabled ? '0.6' : '1' }" class="material-symbols-outlined menu-icon">
                        {{getIcon(section.sections[item].blocks[child])}}
                    </mat-icon>
                    <p [ngStyle]="{'opacity' : section.sections[item].blocks[child] && section.sections[item].blocks[child].disabled ? '0.6' : '1' }"
                    *ngIf="section.sections[item]?.blocks[child]?.type != 'button' && item != 'announcement-bar' "
                        class="submenu-option-title"
                        (click)="showParentForm(item,child,section.sections[item].blocks[child].settings,'child',ix,section.sections[item].blocks[child].type,section.sections[item].type)">
                        {{ section.sections[item].blocks[child].type | replaceName : {"type" : "child", "headingText" : section.sections[item].blocks[child].type, "parent" : item} }}
                    </p>
                    <p [ngStyle]="{'opacity' : section.sections[item].blocks[child] && section.sections[item].blocks[child].disabled ? '0.6' : '1' }"
                    *ngIf="section.sections[item]?.blocks[child]?.type != 'button' && item == 'announcement-bar' "
                        class="submenu-option-title"
                        (click)="showParentForm(item,child,section.sections[item].blocks[child].settings,'child',ix,child,section.sections[item].type)">
                        {{ section.sections[item].blocks[child].type | replaceName : {"type" : "child", "headingText" : section.sections[item].blocks[child].type, "parent" : item} }}
                    </p>
                    <p *ngIf="section.sections[item].blocks[child].type == 'button' "
                        class="submenu-option-title"
                        (click)="showParentForm(item,child,section.sections[item].blocks[child].settings,'child',ix,section.sections[item].blocks[child].type,section.sections[item].type)">
                        Button
                    </p>
                    <span class="actions">

                        <div class="action-sub-items">
                            <mat-icon *ngIf="section.sections[item].blocks[child] && !section.sections[item].blocks[child]['disabled']" class="actions drag-drop-icon margin-right23 icon20"
                                (click)="$event.stopPropagation();disableSubSection(item,child)">visibility
                            </mat-icon>
                            <mat-icon *ngIf=" section.sections[item].blocks[child] && section.sections[item].blocks[child]['disabled']" class="actions visibility-off margin-right23 icon20"
                            (click)="$event.stopPropagation();enableSubSection(item,child)">visibility_off
                        </mat-icon>
                            <mat-icon class="actions drag-drop-icon icon20" cdkDragHandle>drag_indicator
                            </mat-icon>
                        </div>
                    </span>
                </div>
                <div class="add-section"  [matMenuTriggerFor]="menu" *ngIf="ix == section.sections[item].block_order.length - 1 && item != 'announcement-bar' && section.sections[item].type != 'collapsible-content'">
                    <span class="Polaris-Icon">
                        <span class="Polaris-VisuallyHidden">
                        </span>
                        <svg viewBox="0 0 20 20" fill="#2c6ecb" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15 10a1 1 0 0 1-1 1h-3v3a1 1 0 1 1-2 0v-3h-3a1 1 0 1 1 0-2h3v-3a1 1 0 0 1 2 0v3h3a1 1 0 0 1 1 1zm-5-8a8 8 0 1 0 0 16 8 8 0 0 0 0-16z" />
                        </svg>
                    </span>
                    <p *ngIf="item != 'announcement-bar'" class="add-section-title" (click)="showBlockOptions(section.sections[item].type,child)">Add block</p>
                    <mat-menu #menu="matMenu" yPosition="above" xPosition="after">
                        <!-- <button mat-menu-item>Item 1</button>
                        <button mat-menu-item>Item 2</button> -->
                        <div class="add-section-menu">
                            <!-- <form class="nosubmit">
                                <input class="nosubmit" type="search" placeholder="Search...">
                              </form> -->
                            <p class="theme-sections">
                                Theme Blocks
                            </p>

                            <div class="theme-section-block" *ngFor="let sc of blocks; let ib = index"  (click)="addBlock(section.sections[item],sc.type,section.sections[item].type,item)">
                                <span class="Polaris-Icon">
                                    <span class="Polaris-VisuallyHidden">
                                    </span>
                                    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M1 2.5v6.5h18v-6.5a1.5 1.5 0 0 0-1.5-1.5h-15a1.5 1.5 0 0 0-1.5 1.5zm1 16.5a1 1 0 0 1-1-1v-2h2v1h1v2h-2zm17-1a1 1 0 0 1-1 1h-2v-2h1v-1h2v2zm-18-4v-3h2v3h-2zm16-3v3h2v-3h-2zm-11 6h3v2h-3v-2zm8 0h-3v2h3v-2z" />
                                    </svg>
                                </span>
                                <p class="theme-section-title">{{sc.type}}</p>
                            </div>

                        </div>
                    </mat-menu>
                    </div>
                    <div class="add-section" *ngIf="ix == section.sections[item].block_order.length - 1 && (item == 'announcement-bar' || section.sections[item].type == 'collapsible-content')">
                        <span class="Polaris-Icon">
                            <span class="Polaris-VisuallyHidden">
                            </span>
                            <svg viewBox="0 0 20 20" fill="#2c6ecb" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M15 10a1 1 0 0 1-1 1h-3v3a1 1 0 1 1-2 0v-3h-3a1 1 0 1 1 0-2h3v-3a1 1 0 0 1 2 0v3h3a1 1 0 0 1 1 1zm-5-8a8 8 0 1 0 0 16 8 8 0 0 0 0-16z" />
                            </svg>
                        </span>
                    <p *ngIf="item == 'announcement-bar'" class="add-section-title" (click)="addBlock('','announcement-bar','announcement-bar','announcement-bar')">Add Announcement</p>
                    <p *ngIf="section.sections[item].type == 'collapsible-content'" class="add-section-title" (click)="addBlock('','collapsible_row','collapsible-content',item)">Add Collapsible row</p>
                    </div>
            </span>

        </span>
    </mat-expansion-panel>
</div>