import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { EditorService } from '../../editor.service';

@Component({
  selector: 'am-image-picker-field',
  templateUrl: './image-picker-field.component.html',
  styleUrls: ['./image-picker-field.component.scss']
})
export class ImagePickerFieldComponent implements OnInit {

  @Input()
  item: any;

  @Input()
  values: any;

  imageUrl: string;

  @Output() valueUpdated = new EventEmitter<any>();

  constructor(
    private editorService: EditorService
  ) { }

  ngOnInit() {
    this.imageUrl = this.values[this.item.id] || '';
  }

  valueUpdatedEvent(id, value) {
    this.valueUpdated.emit({
      id: id,
      value: value
    });
  }

  showPicker(): void {
    // Show picker
    this.editorService.showImagePicker({
      id: this.item.id,
      url: this.imageUrl
    });

    // Subscribe to preview updates
    const subscription = this.editorService.imageSelected.subscribe(res => {
      if (res.id === this.item.id) {
        this.imageUrl = res.url;
        this.values[this.item.id] = res.url;
        this.valueUpdatedEvent(res.id, res.url);
      }
    });

    // Subscribe to close picker updates
    const subscriptionClosed = this.editorService.imagePickerClosed.subscribe(res => {
      if (res.id === this.item.id) {
        this.imageUrl = res.url;
        this.values[this.item.id] = res.url;
        this.valueUpdatedEvent(res.id, res.url);
      }
      subscription.unsubscribe();
      subscriptionClosed.unsubscribe();
    }, err => {
      console.log(err);
      subscription.unsubscribe();
      subscriptionClosed.unsubscribe();
    });
  }

  removeImage() {
    this.imageUrl = '';
    this.values[this.item.id] = this.imageUrl;
    // Send refresh request?
    this.valueUpdatedEvent(this.item.id, this.values[this.item.id]);
  }

}
