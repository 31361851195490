<div class="stack-vertical">
  <div class="section-list">

    <ul class="theme-editor-action-list">

      <li *ngFor="let item of theme.themeDetails.schema.items">
        <am-builder-component-list-item *ngIf="item.name !== 'theme_info'"
          (componentSelected)="openSettingsDetails($event, item)" type="custom" id="custom" [name]="item.name">
        </am-builder-component-list-item>
      </li>
    </ul>

  </div>
</div>

<div>
  AMP version of {{theme.themeDetails.schema.details.theme_name}} by {{theme.themeDetails.schema.details.theme_author}}
  <p>Version: {{theme.themeDetails.schema.details.theme_version}}</p>
</div>


<am-edit-component-details type="THEME_SETTINGS" *ngIf="showEditComponent" [component]="selectedEditComponent"
  (closeEditComponentDetailsPanel)="closeEditComponentDetailsPanel($event)"></am-edit-component-details>