import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'am-footer-v2',
  templateUrl: './footer-v2.component.html',
  styleUrls: ['./footer-v2.component.scss']
})
export class FooterV2Component implements OnInit {

  @Input() footerObj: any = {};
  @Output() showSectionForm = new EventEmitter();
  @Output() addSectionBlock = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  showParentForm($event) {

    //console.log($event);
    // //console.log(Constants.getBlockSchemaObject(parent,subsection));

    this.showSectionForm.emit($event);

  }
  panelFooterOpen($event) {

    //console.log("panelFooterOpen", $event);
    // this.footerObj.sections[$event.item]['isPanelOpened'] = $event.flag;
    for (let i = 0; i < this.footerObj.order.length; i++) {
      if ($event.flag) {
        if ($event.item == this.footerObj.sections[$event.item].type) {

          this.footerObj.sections[$event.item]['isPanelOpened'] = $event.flag;
          break;
        }
      } else {

        this.footerObj.sections[$event.item]['isPanelOpened'] = false;

      }
    }

  }
  emitBlock($event){

    this.addSectionBlock.emit($event);
  }
}
