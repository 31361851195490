import { Component, OnInit, Input } from '@angular/core';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';

@Component({
  selector: 'am-left-sidebar-v2',
  templateUrl: './left-sidebar-v2.component.html',
  styleUrls: ['./left-sidebar-v2.component.scss']
})
export class LeftSidebarV2Component implements OnInit {

  @Input()
  themeId: string;

  @Input() sidemenuData = {};

  @Input() headerConf : any = {};
  @Input() footerConf : any = {};

  @Input() currentConf : any = {};
  @Input() pageTitle = 'Home'

  changePageTypeEvent: any;

  activeTab = 'SECTIONS';

  constructor(
    private pubsub: NgxPubSubService
  ) { }

  ngOnInit() {

    //console.log("headerConf in left-side-v2.....",this.headerConf)
    // Event for changing the page type
    this.changePageTypeEvent = this.pubsub.subscribe('PAGE_TYPE_CHANGE', (newValue) => {
      this.activeTab = 'SECTIONS';
    });
  }
  ngOnChanges(){
    //console.log("currentConf",this.currentConf)

  }

  changeTab(tab: string): void {
    this.activeTab = tab;
  }

}
