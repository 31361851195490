import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ColorPickerModule } from 'ngx-color-picker';
import { MarkdownModule } from 'ngx-markdown';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { SharedModule } from '../../shared/shared.module';
import { PolarisModule } from '../../polaris/polaris.module';

import { TopNavbarComponent } from './top-navbar/top-navbar.component';

import { BuilderPreviewComponent } from './builder-preview/builder-preview.component';

import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';
import { ThemeSettingsComponent } from './left-sidebar/theme-settings/theme-settings.component';
import { SectionsComponent } from './left-sidebar/sections/sections.component';
import { BuilderComponentListItemComponent } from './left-sidebar/builder-component-list-item/builder-component-list-item.component';

import { EditorRoutingModule } from './editor-routing.module';

import { EditComponentDetailsComponent } from './left-sidebar/edit-component-details/edit-component-details.component';
import { AddComponentListComponent } from './left-sidebar/add-component-list/add-component-list.component';
import { EditSectionContentComponent } from './left-sidebar/edit-section-content/edit-section-content.component';
import { EditFieldComponent } from './edit-field/edit-field.component';
import { FontFieldComponent } from './edit-field/font-field/font-field.component';
import { RangeFieldComponent } from './edit-field/range-field/range-field.component';
import { ImagePickerFieldComponent } from './edit-field/image-picker-field/image-picker-field.component';
import { MenuPickerFieldComponent } from './edit-field/menu-picker-field/menu-picker-field.component';
import { ResourcePickerFieldComponent } from './edit-field/resource-picker-field/resource-picker-field.component';
import { EditorComponent } from './editor.component';
import { SharedImagePickerComponent } from './left-sidebar/shared-image-picker/shared-image-picker.component';
import { SharedFontPickerComponent } from './left-sidebar/shared-font-picker/shared-font-picker.component';

@NgModule({
  declarations: [
    LeftSidebarComponent,
    BuilderPreviewComponent,
    BuilderComponentListItemComponent,
    EditComponentDetailsComponent,
    AddComponentListComponent,
    ThemeSettingsComponent,
    TopNavbarComponent,
    SectionsComponent,
    EditSectionContentComponent,
    EditFieldComponent,
    FontFieldComponent,
    RangeFieldComponent,
    ImagePickerFieldComponent,
    MenuPickerFieldComponent,
    ResourcePickerFieldComponent,

    EditorComponent,

    SharedImagePickerComponent,

    SharedFontPickerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,

    EditorRoutingModule,

    PolarisModule,
    ColorPickerModule,
    MarkdownModule.forRoot(),
    DragDropModule
  ],
  exports: [
    LeftSidebarComponent,
    BuilderPreviewComponent,
    BuilderComponentListItemComponent,
    EditComponentDetailsComponent,
    AddComponentListComponent,
    ThemeSettingsComponent,
    TopNavbarComponent,
    SectionsComponent,
    EditSectionContentComponent,
    EditFieldComponent,
    FontFieldComponent,
    RangeFieldComponent,
    ImagePickerFieldComponent,
    MenuPickerFieldComponent,
    ResourcePickerFieldComponent,
  ]
})
export class EditorModule { }
