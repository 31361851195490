import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Editorv2Component } from './editorv2.component';
import { FormV2Component } from './left-sidebar-v2/dynamic-form-v2/form-v2/form-v2.component';

const routes: Routes = [
  {
    path: 'v2/theme/:id',
    component: Editorv2Component
  },
  {
    path: 'theme/form',
    component: FormV2Component
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EditorV2RoutingModule { }
