import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '../../../environments/environment';

import { AuthService } from '../../auth/auth.service';


@Component({
  selector: 'am-pwa-upgrade-modal',
  templateUrl: './pwa-upgrade-modal.component.html',
  styleUrls: ['./pwa-upgrade-modal.component.scss']
})
export class PwaUpgradeModalComponent implements OnInit {

  currentPlanName: string = 'free';
  upgradeUrl: string;

  constructor(
    private authService: AuthService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    const user = this.authService.getUser();
    const shop = user.shopDomain;
    this.currentPlanName = user.planName;
    this.upgradeUrl = `${environment.basePWAUrl}/install/upgrade?shop=${shop}&tier=`;
  }

  cancel() {
    this.activeModal.close();
  }

}
