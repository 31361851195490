export class Constants {

  version : any;
// Dawn 7.0 metadata
  public static readonly labelDataV7 = {
    "sections": {
      "all": {
        "padding": {
          "section_padding_heading": "Section padding",
          "padding_top": "Top padding",
          "padding_bottom": "Bottom padding"
        },
        "spacing": "Spacing",
        "colors": {
          "accent_1": {
            "label": "Accent 1"
          },
          "accent_2": {
            "label": "Accent 2"
          },
          "background_1": {
            "label": "Background 1"
          },
          "background_2": {
            "label": "Background 2"
          },
          "inverse": {
            "label": "Inverse"
          },
          "label": "Color scheme",
          "has_cards_info": "To change the card color scheme, update your theme settings."
        },
        "heading_size": {
          "label": "Heading size",
          "options__1": {
            "label": "Small"
          },
          "options__2": {
            "label": "Medium"
          },
          "options__3": {
            "label": "Large"
          },
          "options__4": {
            "label": "Extra large"
          }
        }
      },
      "announcement-bar": {
        "name": "Announcement bar",
        "blocks": {
          "announcement": {
            "name": "Announcement",
            "settings": {
              "text": {
                "label": "Text"
              },
              "text_alignment": {
                "label": "Text alignment",
                "options__1": {
                  "label": "Left"
                },
                "options__2": {
                  "label": "Center"
                },
                "options__3": {
                  "label": "Right"
                }
              },
              "link": {
                "label": "Link"
              }
            }
          }
        }
      },
      "apps": {
        "name": "Apps",
        "settings": {
          "include_margins": {
            "label": "Make section margins the same as theme"
          }
        },
        "presets": {
          "name": "Apps"
        }
      },
      "collage": {
        "name": "Collage",
        "settings": {
          "heading": {
            "label": "Heading"
          },
          "desktop_layout": {
            "label": "Desktop layout",
            "options__1": {
              "label": "Left large block"
            },
            "options__2": {
              "label": "Right large block"
            }
          },
          "mobile_layout": {
            "label": "Mobile layout",
            "options__1": {
              "label": "Collage"
            },
            "options__2": {
              "label": "Column"
            }
          },
          "card_styles": {
            "label": "Card style",
            "info": "Product, collection, and blog card styles can be updated in theme settings.",
            "options__1": {
              "label": "Use individual card styles"
            },
            "options__2": {
              "label": "Style all as product cards"
            }
          }
        },
        "blocks": {
          "image": {
            "name": "Image",
            "settings": {
              "image": {
                "label": "Image"
              },
              "image_padding": {
                "label": "Use original image ratio",
                "info": "Select if you don't want your image to be cropped."
              }
            }
          },
          "product": {
            "name": "Product",
            "settings": {
              "product": {
                "label": "Product"
              },
              "secondary_background": {
                "label": "Show secondary background"
              },
              "second_image": {
                "label": "Show second image on hover"
              }
            }
          },
          "collection": {
            "name": "Collection",
            "settings": {
              "collection": {
                "label": "Collection"
              }
            }
          },
          "video": {
            "name": "Video",
            "settings": {
              "cover_image": {
                "label": "Cover image"
              },
              "video_url": {
                "label": "URL",
                "info": "Video plays in a pop-up if the section contains other blocks.",
                "placeholder": "Use a YouTube or Vimeo URL"
              },
              "image_padding": {
                "label": "Use original image ratio",
                "info": "Select if you don't want your image to be cropped."
              },
              "description": {
                "label": "Video alt text",
                "info": "Describe the video for customers using screen readers. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/theme-features#video-block)"
              }
            }
          }
        },
        "presets": {
          "name": "Collage"
        }
      },
      "collection-list": {
        "name": "Collection list",
        "settings": {
          "title": {
            "label": "Heading"
          },
          "image_ratio": {
            "label": "Image ratio",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Portrait"
            },
            "options__3": {
              "label": "Square"
            },
            "options__4": {
              "label": "Landscape"
            },
            "info": "Add images by editing your collections. [Learn more](https://help.shopify.com/manual/products/collections)"
          },
          "columns_desktop": {
            "label": "Number of columns on desktop"
          },
          "show_view_all": {
            "label": "Enable \"View all\" button if list includes more collections than shown"
          },
          "header_mobile": {
            "content": "Mobile Layout"
          },
          "columns_mobile": {
            "label": "Number of columns on mobile",
            "options__1": {
              "label": "1 column"
            },
            "options__2": {
              "label": "2 columns"
            }
          },
          "swipe_on_mobile": {
            "label": "Enable swipe on mobile"
          }
        },
        "blocks": {
          "featured_collection": {
            "name": "Collection",
            "settings": {
              "collection": {
                "label": "Collection"
              },
              "image_picker": {
                "label": "Collection Image"
              },
              "title": {
                "label": "Title"
              }
            }
          }
        },
        "presets": {
          "name": "Collection list"
        }
      },
      "contact-form": {
        "name": "Contact Form",
        "presets": {
          "name": "Contact form"
        }
      },
      "custom-liquid": {
        "name": "Custom Liquid",
        "settings": {
          "custom_liquid": {
            "label": "Custom Liquid",
            "info": "Add app snippets or other Liquid code to create advanced customizations."
          }
        },
        "presets": {
          "name": "Custom Liquid"
        }
      },
      "featured-blog": {
        "name": "Blog posts",
        "settings": {
          "heading": {
            "label": "Heading"
          },
          "blog": {
            "label": "Blog"
          },
          "post_limit": {
            "label": "Number of blog posts to show"
          },
          "columns_desktop": {
            "label": "Number of columns on desktop"
          },
          "show_view_all": {
            "label": "Enable \"View all\" button if blog includes more blog posts than shown"
          },
          "show_image": {
            "label": "Show featured image",
            "info": "For best results, use an image with a 3:2 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
          },
          "show_date": {
            "label": "Show date"
          },
          "show_author": {
            "label": "Show author"
          }
        },
        "presets": {
          "name": "Blog posts"
        }
      },
      "featured-collection": {
        "name": "Featured collection",
        "settings": {
          "title": {
            "label": "Heading"
          },
          "description": {
            "label": "Description"
          },
          "show_description": {
            "label": "Show collection description from the admin"
          },
          "description_style": {
            "label": "Description style",
            "options__1": {
              "label": "Body"
            },
            "options__2": {
              "label": "Subtitle"
            },
            "options__3": {
              "label": "Uppercase"
            }
          },
          "collection": {
            "label": "Collection"
          },
          "products_to_show": {
            "label": "Maximum products to show"
          },
          "columns_desktop": {
            "label": "Number of columns on desktop"
          },
          "show_view_all": {
            "label": "Enable \"View all\" if collection has more products than shown"
          },
          "view_all_style": {
            "label": "\"View all\" style",
            "options__1": {
              "label": "Link"
            },
            "options__2": {
              "label": "Outline button"
            },
            "options__3": {
              "label": "Solid button"
            }
          },
          "enable_desktop_slider": {
            "label": "Enable carousel on desktop"
          },
          "full_width": {
            "label": "Make products full width"
          },
          "header": {
            "content": "Product card"
          },
          "image_ratio": {
            "label": "Image ratio",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Portrait"
            },
            "options__3": {
              "label": "Square"
            }
          },
          "show_secondary_image": {
            "label": "Show second image on hover"
          },
          "show_vendor": {
            "label": "Show vendor"
          },
          "show_rating": {
            "label": "Show product rating",
            "info": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/theme-features#featured-collection-show-product-rating)"
          },
          "enable_quick_buy": {
            "label": "Enable quick add button",
            "info": "Optimal with popup or drawer cart type."
          },
          "header_mobile": {
            "content": "Mobile Layout"
          },
          "columns_mobile": {
            "label": "Number of columns on mobile",
            "options__1": {
              "label": "1 column"
            },
            "options__2": {
              "label": "2 columns"
            }
          },
          "swipe_on_mobile": {
            "label": "Enable swipe on mobile"
          }
        },
        "presets": {
          "name": "Featured collection"
        }
      },
      "featured-product": {
        "name": "Featured product",
        "blocks": {
          "text": {
            "name": "Text",
            "settings": {
              "text": {
                "label": "Text"
              },
              "text_style": {
                "label": "Text style",
                "options__1": {
                  "label": "Body"
                },
                "options__2": {
                  "label": "Subtitle"
                },
                "options__3": {
                  "label": "Uppercase"
                }
              }
            }
          },
          "title": {
            "name": "Title"
          },
          "price": {
            "name": "Price"
          },
          "quantity_selector": {
            "name": "Quantity selector"
          },
          "variant_picker": {
            "name": "Variant picker",
            "settings": {
              "picker_type": {
                "label": "Type",
                "options__1": {
                  "label": "Dropdown"
                },
                "options__2": {
                  "label": "Pills"
                }
              }
            }
          },
          "buy_buttons": {
            "name": "Buy buttons",
            "settings": {
              "show_dynamic_checkout": {
                "label": "Show dynamic checkout buttons",
                "info": "Using the payment methods available on your store, customers see their preferred option, like PayPal or Apple Pay. [Learn more](https://help.shopify.com/manual/using-themes/change-the-layout/dynamic-checkout)"
              }
            }
          },
          "description": {
            "name": "Description"
          },
          "share": {
            "name": "Share",
            "settings": {
              "text": {
                "label": "Text"
              },
              "featured_image_info": {
                "content": "If you include a link in social media posts, the page’s featured image will be shown as the preview image. [Learn more](https://help.shopify.com/manual/online-store/images/showing-social-media-thumbnail-images)"
              },
              "title_info": {
                "content": "A store title and description are included with the preview image. [Learn more](https://help.shopify.com/manual/promoting-marketing/seo/adding-keywords#set-a-title-and-description-for-your-online-store)"
              }
            }
          },
          "custom_liquid": {
            "name": "Custom liquid",
            "settings": {
              "custom_liquid": {
                "label": "Custom liquid"
              }
            }
          },
          "rating": {
            "name": "Product rating",
            "settings": {
              "paragraph": {
                "content": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/theme-features#featured-product-rating)"
              }
            }
          }
        },
        "settings": {
          "product": {
            "label": "Product"
          },
          "secondary_background": {
            "label": "Show secondary background"
          },
          "header": {
            "content": "Media",
            "info": "Learn more about [media types](https://help.shopify.com/manual/products/product-media)"
          },
          "media_position": {
            "label": "Desktop media position",
            "info": "Position is automatically optimized for mobile.",
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Right"
            }
          },
          "hide_variants": {
            "label": "Hide unselected variants’ media on desktop"
          },
          "enable_video_looping": {
            "label": "Enable video looping"
          }
        },
        "presets": {
          "name": "Featured product"
        }
      },
      "footer": {
        "name": "Footer",
        "blocks": {
          "link_list": {
            "name": "Menu",
            "settings": {
              "heading": {
                "label": "Heading"
              },
              "menu": {
                "label": "Menu",
                "info": "Displays only top-level menu items."
              }
            }
          },
          "text": {
            "name": "Text",
            "settings": {
              "heading": {
                "label": "Heading"
              },
              "subtext": {
                "label": "Subtext"
              }
            }
          }
        },
        "settings": {
          "newsletter_enable": {
            "label": "Show email signup"
          },
          "newsletter_heading": {
            "label": "Heading"
          },
          "header__1": {
            "content": "Email Signup",
            "info": "Subscribers added automatically to your “accepted marketing” customer list. [Learn more](https://help.shopify.com/manual/customers/manage-customers)"
          },
          "header__2": {
            "content": "Social media icons",
            "info": "To display your social media accounts, link them in your theme settings."
          },
          "show_social": {
            "label": "Show social media icons"
          },
          "header__3": {
            "content": "Country/region selector"
          },
          "header__4": {
            "info": "To add a country/region, go to your [payment settings.](/admin/settings/payments)"
          },
          "enable_country_selector": {
            "label": "Enable country/region selector"
          },
          "header__5": {
            "content": "Language selector"
          },
          "header__6": {
            "info": "To add a language, go to your [language settings.](/admin/settings/languages)"
          },
          "enable_language_selector": {
            "label": "Enable language selector"
          },
          "header__7": {
            "content": "Payment methods"
          },
          "payment_enable": {
            "label": "Show payment icons"
          },
          "margin_top": {
            "label": "Top margin"
          }
        }
      },
      "header": {
        "name": "Header",
        "settings": {
          "logo": {
            "label": "Logo image"
          },
          "logo_width": {
            "unit": "px",
            "label": "Custom logo width"
          },
          "logo_position": {
            "label": "Desktop logo position",
            "options__1": {
              "label": "Middle left"
            },
            "options__2": {
              "label": "Top left"
            },
            "options__3": {
              "label": "Top center"
            },
            "options__4": {
              "label": "Middle center"
            }
          },
          "menu": {
            "label": "Menu"
          },
          "menu_type_desktop": {
            "label": "Desktop menu type",
            "info": "Menu type is automatically optimized for mobile.",
            "options__1": {
              "label": "Dropdown"
            },
            "options__2": {
              "label": "Mega menu"
            }
          },
          "show_line_separator": {
            "label": "Show separator line"
          },
          "enable_sticky_header": {
            "label": "Enable sticky header",
            "info": "Header shows on the screen as customers scroll up."
          },
          "margin_bottom": {
            "label": "Bottom margin"
          },
          "mobile_layout": {
            "content": "Mobile layout"
          },
          "mobile_logo_position": {
            "label": "Mobile logo position",
            "options__1": {
              "label": "Center"
            },
            "options__2": {
              "label": "Left"
            }
          }
        }
      },
      "image-banner": {
        "name": "Image banner",
        "settings": {
          "image": {
            "label": "First image"
          },
          "image_2": {
            "label": "Second image"
          },
          "image_overlay_opacity": {
            "label": "Image overlay opacity"
          },
          "image_height": {
            "label": "Banner height",
            "options__1": {
              "label": "Small"
            },
            "options__2": {
              "label": "Medium"
            },
            "options__3": {
              "label": "Large"
            },
            "info": "For best results, use an image with a 3:2 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
          },
          "desktop_content_position": {
            "options__1": {
              "label": "Top Left"
            },
            "options__2": {
              "label": "Top Center"
            },
            "options__3": {
              "label": "Top Right"
            },
            "options__4": {
              "label": "Middle Left"
            },
            "options__5": {
              "label": "Middle Center"
            },
            "options__6": {
              "label": "Middle Right"
            },
            "options__7": {
              "label": "Bottom Left"
            },
            "options__8": {
              "label": "Bottom Center"
            },
            "options__9": {
              "label": "Bottom Right"
            },
            "label": "Desktop content position"
          },
          "show_text_box": {
            "label": "Show container on desktop"
          },
          "desktop_content_alignment": {
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            },
            "label": "Desktop content alignment"
          },
          "color_scheme": {
            "info": "Visible when container displayed."
          },
          "header": {
            "content": "Mobile Layout"
          },
          "mobile_content_alignment": {
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            },
            "label": "Mobile content alignment"
          },
          "stack_images_on_mobile": {
            "label": "Stack images on mobile"
          },
          "show_text_below": {
            "label": "Show container on mobile"
          },
          "adapt_height_first_image": {
            "label": "Adapt section height to first image size",
            "info": "Overwrites image banner height setting when checked."
          }
        },
        "blocks": {
          "heading": {
            "name": "Heading",
            "settings": {
              "heading": {
                "label": "Heading"
              }
            }
          },
          "text": {
            "name": "Text",
            "settings": {
              "text": {
                "label": "Description"
              },
              "text_style": {
                "options__1": {
                  "label": "Body"
                },
                "options__2": {
                  "label": "Subtitle"
                },
                "options__3": {
                  "label": "Uppercase"
                },
                "label": "Text style"
              }
            }
          },
          "buttons": {
            "name": "Buttons",
            "settings": {
              "button_label_1": {
                "label": "First button label",
                "info": "Leave the label blank to hide the button."
              },
              "button_link_1": {
                "label": "First button link"
              },
              "button_style_secondary_1": {
                "label": "Use outline button style"
              },
              "button_label_2": {
                "label": "Second button label",
                "info": "Leave the label blank to hide the button."
              },
              "button_link_2": {
                "label": "Second button link"
              },
              "button_style_secondary_2": {
                "label": "Use outline button style"
              }
            }
          }
        },
        "presets": {
          "name": "Image banner"
        }
      },
      "image-with-text": {
        "name": "Image with text",
        "settings": {
          "image": {
            "label": "Image"
          },
          "height": {
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Small"
            },
            "options__3": {
              "label": "Large"
            },
            "label": "Image height"
          },
          "desktop_image_width": {
            "options__1": {
              "label": "Small"
            },
            "options__2": {
              "label": "Medium"
            },
            "options__3": {
              "label": "Large"
            },
            "label": "Desktop image width",
            "info": "Image is automatically optimized for mobile."
          },
          "layout": {
            "options__1": {
              "label": "Image first"
            },
            "options__2": {
              "label": "Image second"
            },
            "label": "Desktop image placement",
            "info": "Image first is the default mobile layout."
          },
          "desktop_content_alignment": {
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            },
            "label": "Desktop content alignment"
          },
          "desktop_content_position": {
            "options__1": {
              "label": "Top"
            },
            "options__2": {
              "label": "Middle"
            },
            "options__3": {
              "label": "Bottom"
            },
            "label": "Desktop content position"
          },
          "content_layout": {
            "options__1": {
              "label": "No overlap"
            },
            "options__2": {
              "label": "Overlap"
            },
            "label": "Content layout"
          },
          "mobile_content_alignment": {
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            },
            "label": "Mobile content alignment"
          }
        },
        "blocks": {
          "heading": {
            "name": "Heading",
            "settings": {
              "heading": {
                "label": "Heading"
              }
            }
          },
          "caption": {
            "name": "Caption",
            "settings": {
              "text": {
                "label": "Text"
              },
              "text_style": {
                "label": "Text style",
                "options__1": {
                  "label": "Subtitle"
                },
                "options__2": {
                  "label": "Uppercase"
                }
              },
              "caption_size": {
                "label": "Text size",
                "options__1": {
                  "label": "Small"
                },
                "options__2": {
                  "label": "Medium"
                },
                "options__3": {
                  "label": "Large"
                }
              }
            }
          },
          "text": {
            "name": "Text",
            "settings": {
              "text": {
                "label": "Content"
              },
              "text_style": {
                "label": "Text style",
                "options__1": {
                  "label": "Body"
                },
                "options__2": {
                  "label": "Subtitle"
                }
              }
            }
          },
          "button": {
            "name": "Button",
            "settings": {
              "button_label": {
                "label": "Button label",
                "info": "Leave the label blank to hide the button."
              },
              "button_link": {
                "label": "Button link"
              }
            }
          }
        },
        "presets": {
          "name": "Image with text"
        }
      },
      "main-account": {
        "name": "Account"
      },
      "main-activate-account": {
        "name": "Account activation"
      },
      "main-addresses": {
        "name": "Addresses"
      },
      "main-article": {
        "name": "Blog post",
        "blocks": {
          "featured_image": {
            "name": "Featured image",
            "settings": {
              "image_height": {
                "label": "Featured image height",
                "options__1": {
                  "label": "Adapt to image"
                },
                "options__2": {
                  "label": "Small"
                },
                "options__3": {
                  "label": "Medium"
                },
                "options__4": {
                  "label": "Large"
                },
                "info": "For best results, use an image with a 16:9 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
              }
            }
          },
          "title": {
            "name": "Title",
            "settings": {
              "blog_show_date": {
                "label": "Show date"
              },
              "blog_show_author": {
                "label": "Show author"
              }
            }
          },
          "content": {
            "name": "Content"
          },
          "share": {
            "name": "Share",
            "settings": {
              "text": {
                "label": "Text"
              },
              "featured_image_info": {
                "content": "If you include a link in social media posts, the page’s featured image will be shown as the preview image. [Learn more](https://help.shopify.com/manual/online-store/images/showing-social-media-thumbnail-images)."
              },
              "title_info": {
                "content": "A store title and description are included with the preview image. [Learn more](https://help.shopify.com/manual/promoting-marketing/seo/adding-keywords#set-a-title-and-description-for-your-online-store)."
              }
            }
          }
        }
      },
      "main-blog": {
        "name": "Blog posts",
        "settings": {
          "header": {
            "content": "Blog post card"
          },
          "show_image": {
            "label": "Show featured image"
          },
          "show_date": {
            "label": "Show date"
          },
          "show_author": {
            "label": "Show author"
          },
          "paragraph": {
            "content": "Change excerpts by editing your blog posts. [Learn more](https://help.shopify.com/manual/online-store/blogs/writing-blogs#display-an-excerpt-from-a-blog-post)"
          },
          "layout": {
            "label": "Desktop layout",
            "options__1": {
              "label": "Grid"
            },
            "options__2": {
              "label": "Collage"
            },
            "info": "Posts are stacked on mobile."
          },
          "image_height": {
            "label": "Featured image height",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Small"
            },
            "options__3": {
              "label": "Medium"
            },
            "options__4": {
              "label": "Large"
            },
            "info": "For best results, use an image with a 3:2 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
          }
        }
      },
      "main-cart-footer": {
        "name": "Subtotal",
        "blocks": {
          "subtotal": {
            "name": "Subtotal price"
          },
          "buttons": {
            "name": "Checkout button"
          }
        }
      },
      "main-cart-items": {
        "name": "Items"
      },
      "main-collection-banner": {
        "name": "Collection banner",
        "settings": {
          "paragraph": {
            "content": "Add a description or image by editing your collection. [Learn more](https://help.shopify.com/manual/products/collections/collection-layout)"
          },
          "show_collection_description": {
            "label": "Show collection description"
          },
          "show_collection_image": {
            "label": "Show collection image",
            "info": "For best results, use an image with a 16:9 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
          }
        }
      },
      "main-collection-product-grid": {
        "name": "Product grid",
        "settings": {
          "products_per_page": {
            "label": "Products per page"
          },
          "columns_desktop": {
            "label": "Number of columns on desktop"
          },
          "enable_filtering": {
            "label": "Enable filtering",
            "info": "Customize [filters](/admin/menus)"
          },
          "filter_type": {
            "label": "Desktop filter layout",
            "options__1": {
              "label": "Horizontal"
            },
            "options__2": {
              "label": "Vertical"
            },
            "options__3": {
              "label": "Drawer"
            },
            "info": "Drawer is the default mobile layout."
          },
          "enable_sorting": {
            "label": "Enable sorting"
          },
          "image_ratio": {
            "label": "Image ratio",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Portrait"
            },
            "options__3": {
              "label": "Square"
            }
          },
          "show_secondary_image": {
            "label": "Show second image on hover"
          },
          "show_vendor": {
            "label": "Show vendor"
          },
          "show_rating": {
            "label": "Show product rating",
            "info": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/page-types#product-grid-section-settings)"
          },
          "header__1": {
            "content": "Filtering and sorting"
          },
          "header__3": {
            "content": "Product card"
          },
          "enable_tags": {
            "label": "Enable filtering",
            "info": "[Customize filters](/admin/menus)"
          },
          "enable_quick_buy": {
            "label": "Enable quick add button",
            "info": "Optimal with popup or drawer cart type."
          },
          "header_mobile": {
            "content": "Mobile Layout"
          },
          "columns_mobile": {
            "label": "Number of columns on mobile",
            "options__1": {
              "label": "1 column"
            },
            "options__2": {
              "label": "2 columns"
            }
          }
        }
      },
      "main-list-collections": {
        "name": "Collections list page",
        "settings": {
          "title": {
            "label": "Heading"
          },
          "sort": {
            "label": "Sort collections by:",
            "options__1": {
              "label": "Alphabetically, A-Z"
            },
            "options__2": {
              "label": "Alphabetically, Z-A"
            },
            "options__3": {
              "label": "Date, new to old"
            },
            "options__4": {
              "label": "Date, old to new"
            },
            "options__5": {
              "label": "Product count, high to low"
            },
            "options__6": {
              "label": "Product count, low to high"
            }
          },
          "image_ratio": {
            "label": "Image ratio",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Portrait"
            },
            "options__3": {
              "label": "Square"
            },
            "info": "Add images by editing your collections. [Learn more](https://help.shopify.com/manual/products/collections)"
          },
          "columns_desktop": {
            "label": "Number of columns on desktop"
          },
          "header_mobile": {
            "content": "Mobile Layout"
          },
          "columns_mobile": {
            "label": "Number of columns on mobile",
            "options__1": {
              "label": "1 column"
            },
            "options__2": {
              "label": "2 columns"
            }
          }
        }
      },
      "main-login": {
        "name": "Login"
      },
      "main-order": {
        "name": "Order"
      },
      "main-page": {
        "name": "Page"
      },
      "main-password-footer": {
        "name": "Password footer"
      },
      "main-password-header": {
        "name": "Password header",
        "settings": {
          "logo": {
            "label": "Logo image"
          },
          "logo_max_width": {
            "label": "Custom logo width",
            "unit": "px"
          }
        }
      },
      "main-product": {
        "name": "Product information",
        "blocks": {
          "text": {
            "name": "Text",
            "settings": {
              "text": {
                "label": "Text"
              },
              "text_style": {
                "label": "Text style",
                "options__1": {
                  "label": "Body"
                },
                "options__2": {
                  "label": "Subtitle"
                },
                "options__3": {
                  "label": "Uppercase"
                }
              }
            }
          },
          "title": {
            "name": "Title"
          },
          "price": {
            "name": "Price"
          },
          "quantity_selector": {
            "name": "Quantity selector"
          },
          "variant_picker": {
            "name": "Variant picker",
            "settings": {
              "picker_type": {
                "label": "Type",
                "options__1": {
                  "label": "Dropdown"
                },
                "options__2": {
                  "label": "Pills"
                },
                "options__3": {
                  "label": "Swatches"
                }
              }
            }
          },
          "buy_buttons": {
            "name": "Buy buttons",
            "settings": {
              "show_dynamic_checkout": {
                "label": "Show dynamic checkout buttons",
                "info": "Using the payment methods available on your store, customers see their preferred option, like PayPal or Apple Pay. [Learn more](https:\/\/help.shopify.com\/manual\/using-themes\/change-the-layout\/dynamic-checkout)"
              }
            }
          },
          "pickup_availability": {
            "name": "Pickup availability"
          },
          "description": {
            "name": "Description"
          },
          "share": {
            "name": "Share",
            "settings": {
              "text": {
                "label": "Text"
              },
              "featured_image_info": {
                "content": "If you include a link in social media posts, the page’s featured image will be shown as the preview image. [Learn more](https:\/\/help.shopify.com\/en\/manual\/online-store\/images\/showing-social-media-thumbnail-images)."
              },
              "title_info": {
                "content": "A store title and description are included with the preview image. [Learn more](https:\/\/help.shopify.com\/en\/manual\/promoting-marketing\/seo\/adding-keywords#set-a-title-and-description-for-your-online-store)."
              }
            }
          },
          "custom_liquid": {
            "name": "Custom liquid",
            "settings": {
              "custom_liquid": {
                "label": "Custom liquid",
                "info": "Add app snippets or other Liquid code to create advanced customizations."
              }
            }
          },
          "collapsible_tab": {
            "name": "Collapsible row",
            "settings": {
              "heading": {
                "info": "Include a heading that explains the content.",
                "label": "Heading"
              },
              "content": {
                "label": "Row content"
              },
              "page": {
                "label": "Row content from page"
              },
              "icon": {
                "label": "Icon",
                "options__1": {
                  "label": "None"
                },
                "options__2": {
                  "label": "Apple"
                },
                "options__3": {
                  "label": "Banana"
                },
                "options__4": {
                  "label": "Bottle"
                },
                "options__5": {
                  "label": "Box"
                },
                "options__6": {
                  "label": "Carrot"
                },
                "options__7": {
                  "label": "Chat bubble"
                },
                "options__8": {
                  "label": "Check mark"
                },
                "options__9": {
                  "label": "Clipboard"
                },
                "options__10": {
                  "label": "Dairy"
                },
                "options__11": {
                  "label": "Dairy free"
                },
                "options__12": {
                  "label": "Dryer"
                },
                "options__13": {
                  "label": "Eye"
                },
                "options__14": {
                  "label": "Fire"
                },
                "options__15": {
                  "label": "Gluten free"
                },
                "options__16": {
                  "label": "Heart"
                },
                "options__17": {
                  "label": "Iron"
                },
                "options__18": {
                  "label": "Leaf"
                },
                "options__19": {
                  "label": "Leather"
                },
                "options__20": {
                  "label": "Lightning bolt"
                },
                "options__21": {
                  "label": "Lipstick"
                },
                "options__22": {
                  "label": "Lock"
                },
                "options__23": {
                  "label": "Map pin"
                },
                "options__24": {
                  "label": "Nut free"
                },
                "options__25": {
                  "label": "Pants"
                },
                "options__26": {
                  "label": "Paw print"
                },
                "options__27": {
                  "label": "Pepper"
                },
                "options__28": {
                  "label": "Perfume"
                },
                "options__29": {
                  "label": "Plane"
                },
                "options__30": {
                  "label": "Plant"
                },
                "options__31": {
                  "label": "Price tag"
                },
                "options__32": {
                  "label": "Question mark"
                },
                "options__33": {
                  "label": "Recycle"
                },
                "options__34": {
                  "label": "Return"
                },
                "options__35": {
                  "label": "Ruler"
                },
                "options__36": {
                  "label": "Serving dish"
                },
                "options__37": {
                  "label": "Shirt"
                },
                "options__38": {
                  "label": "Shoe"
                },
                "options__39": {
                  "label": "Silhouette"
                },
                "options__40": {
                  "label": "Snowflake"
                },
                "options__41": {
                  "label": "Star"
                },
                "options__42": {
                  "label": "Stopwatch"
                },
                "options__43": {
                  "label": "Truck"
                },
                "options__44": {
                  "label": "Washing"
                }
              }
            }
          },
          "popup": {
            "name": "Pop-up",
            "settings": {
              "link_label": {
                "label": "Link label"
              },
              "page": {
                "label": "Page"
              }
            }
          },
          "rating": {
            "name": "Product rating",
            "settings": {
              "paragraph": {
                "content": "To display a rating, add a product rating app. [Learn more](https:\/\/help.shopify.com\/manual\/online-store\/themes\/os20\/themes-by-shopify\/dawn\/page-types#product-rating-block)"
              }
            }
          }
        },
        "settings": {
          "header": {
            "content": "Media",
            "info": "Learn more about [media types.](https:\/\/help.shopify.com\/manual\/products\/product-media)"
          },
          "enable_sticky_info": {
            "label": "Enable sticky content on desktop"
          },
          "enable_sticky_bottom_button": {
            "label": "Enable sticky button on bottom"
          },
          "gallery_layout": {
            "label": "Desktop layout",
            "options__1": {
              "label": "Stacked"
            },
            "options__2": {
              "label": "Thumbnails"
            },
            "options__3": {
              "label": "Thumbnail carousel"
            }
          },
          "media_size": {
            "label": "Desktop media size",
            "info": "Media is automatically optimized for mobile.",
            "options__1": {
              "label": "Small"
            },
            "options__2": {
              "label": "Medium"
            },
            "options__3": {
              "label": "Large"
            }
          },
          "mobile_thumbnails": {
            "label": "Mobile layout",
            "options__1": {
              "label": "Show thumbnails"
            },
            "options__2": {
              "label": "Hide thumbnails"
            }
          },
          "hide_variants": {
            "label": "Hide other variants’ media after selecting a variant"
          },
          "enable_video_looping": {
            "label": "Enable video looping"
          }
        }
      },
      "main-register": {
        "name": "Registration"
      },
      "main-reset-password": {
        "name": "Password reset"
      },
      "main-search": {
        "name": "Search results",
        "settings": {
          "columns_desktop": {
            "label": "Number of columns on desktop"
          },
          "image_ratio": {
            "label": "Image ratio",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Portrait"
            },
            "options__3": {
              "label": "Square"
            }
          },
          "show_secondary_image": {
            "label": "Show second image on hover"
          },
          "show_vendor": {
            "label": "Show vendor"
          },
          "show_rating": {
            "label": "Show product rating",
            "info": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/page-types#search-results-section-settings)"
          },
          "header__1": {
            "content": "Product card"
          },
          "header__2": {
            "content": "Blog card",
            "info": "Blog card styles also apply to page cards in search results. To change card styles update your theme settings."
          },
          "article_show_date": {
            "label": "Show date"
          },
          "article_show_author": {
            "label": "Show author"
          },
          "header_mobile": {
            "content": "Mobile Layout"
          },
          "columns_mobile": {
            "label": "Number of columns on mobile",
            "options__1": {
              "label": "1 column"
            },
            "options__2": {
              "label": "2 columns"
            }
          }
        }
      },
      "multicolumn": {
        "name": "Multicolumn",
        "settings": {
          "title": {
            "label": "Heading"
          },
          "image_width": {
            "label": "Image width",
            "options__1": {
              "label": "One-third width of column"
            },
            "options__2": {
              "label": "Half width of column"
            },
            "options__3": {
              "label": "Full width of column"
            }
          },
          "image_ratio": {
            "label": "Image ratio",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Portrait"
            },
            "options__3": {
              "label": "Square"
            },
            "options__4": {
              "label": "Circle"
            }
          },
          "columns_desktop": {
            "label": "Number of columns on desktop"
          },
          "column_alignment": {
            "label": "Column alignment",
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            }
          },
          "background_style": {
            "label": "Secondary background",
            "options__1": {
              "label": "None"
            },
            "options__2": {
              "label": "Show as column background"
            }
          },
          "button_label": {
            "label": "Button label"
          },
          "button_link": {
            "label": "Button link"
          },
          "header_mobile": {
            "content": "Mobile Layout"
          },
          "columns_mobile": {
            "label": "Number of columns on mobile",
            "options__1": {
              "label": "1 column"
            },
            "options__2": {
              "label": "2 columns"
            }
          },
          "swipe_on_mobile": {
            "label": "Enable swipe on mobile"
          }
        },
        "blocks": {
          "column": {
            "name": "Column",
            "settings": {
              "image": {
                "label": "Image"
              },
              "title": {
                "label": "Heading"
              },
              "text": {
                "label": "Description"
              },
              "link_label": {
                "label": "Link label"
              },
              "link": {
                "label": "Link"
              }
            }
          }
        },
        "presets": {
          "name": "Multicolumn"
        }
      },
      "newsletter": {
        "name": "Email signup",
        "settings": {
          "full_width": {
            "label": "Make section full width"
          },
          "paragraph": {
            "content": "Each email subscription creates a customer account. [Learn more](https://help.shopify.com/manual/customers)"
          }
        },
        "blocks": {
          "heading": {
            "name": "Heading",
            "settings": {
              "heading": {
                "label": "Heading"
              }
            }
          },
          "paragraph": {
            "name": "Subheading",
            "settings": {
              "paragraph": {
                "label": "Description"
              }
            }
          },
          "email_form": {
            "name": "Email form"
          }
        },
        "presets": {
          "name": "Email signup"
        }
      },
      "email-signup-banner": {
        "name": "Email signup banner",
        "settings": {
          "paragraph": {
            "content": "Each email subscription creates a customer account. [Learn more](https://help.shopify.com/manual/customers)"
          },
          "image": {
            "label": "Background image"
          },
          "image_overlay_opacity": {
            "label": "Image overlay opacity"
          },
          "show_background_image": {
            "label": "Show background image"
          },
          "show_text_box": {
            "label": "Show container on desktop"
          },
          "desktop_content_position": {
            "options__1": {
              "label": "Top Left"
            },
            "options__2": {
              "label": "Top Center"
            },
            "options__3": {
              "label": "Top Right"
            },
            "options__4": {
              "label": "Middle Left"
            },
            "options__5": {
              "label": "Middle Center"
            },
            "options__6": {
              "label": "Middle Right"
            },
            "options__7": {
              "label": "Bottom Left"
            },
            "options__8": {
              "label": "Bottom Center"
            },
            "options__9": {
              "label": "Bottom Right"
            },
            "label": "Desktop content position"
          },
          "color_scheme": {
            "info": "Visible when container displayed."
          },
          "image_height": {
            "label": "Banner height",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Small"
            },
            "options__3": {
              "label": "Medium"
            },
            "options__4": {
              "label": "Large"
            },
            "info": "For best results, use an image with a 16:9 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
          },
          "desktop_content_alignment": {
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            },
            "label": "Desktop content alignment"
          },
          "header": {
            "content": "Mobile Layout"
          },
          "mobile_content_alignment": {
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            },
            "label": "Mobile content alignment"
          },
          "show_text_below": {
            "label": "Show content below image on mobile",
            "info": "For best results, use an image with a 16:9 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
          }
        },
        "blocks": {
          "heading": {
            "name": "Heading",
            "settings": {
              "heading": {
                "label": "Heading"
              }
            }
          },
          "paragraph": {
            "name": "Paragraph",
            "settings": {
              "paragraph": {
                "label": "Description"
              },
              "text_style": {
                "options__1": {
                  "label": "Body"
                },
                "options__2": {
                  "label": "Subtitle"
                },
                "label": "Text style"
              }
            }
          },
          "email_form": {
            "name": "Email form"
          }
        },
        "presets": {
          "name": "Email signup banner"
        }
      },
      "page": {
        "name": "Page",
        "settings": {
          "page": {
            "label": "Page"
          }
        },
        "presets": {
          "name": "Page"
        }
      },
      "product-recommendations": {
        "name": "Product recommendations",
        "settings": {
          "heading": {
            "label": "Heading"
          },
          "products_to_show": {
            "label": "Maximum products to show"
          },
          "columns_desktop": {
            "label": "Number of columns on desktop"
          },
          "paragraph__1": {
            "content": "Dynamic recommendations use order and product information to change and improve over time. [Learn more](https://help.shopify.com/themes/development/recommended-products)"
          },
          "header__2": {
            "content": "Product card"
          },
          "image_ratio": {
            "label": "Image ratio",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Portrait"
            },
            "options__3": {
              "label": "Square"
            }
          },
          "show_secondary_image": {
            "label": "Show second image on hover"
          },
          "show_vendor": {
            "label": "Show vendor"
          },
          "show_rating": {
            "label": "Show product rating",
            "info": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/page-types#product-recommendations-section-settings)"
          },
          "header_mobile": {
            "content": "Mobile Layout"
          },
          "columns_mobile": {
            "label": "Number of columns on mobile",
            "options__1": {
              "label": "1 column"
            },
            "options__2": {
              "label": "2 columns"
            }
          }
        }
      },
      "rich-text": {
        "name": "Rich text",
        "settings": {
          "desktop_content_position": {
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            },
            "label": "Desktop content position",
            "info": "Position is automatically optimized for mobile."
          },
          "content_alignment": {
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            },
            "label": "Content alignment"
          },
          "full_width": {
            "label": "Make section full width"
          }
        },
        "blocks": {
          "heading": {
            "name": "Heading",
            "settings": {
              "heading": {
                "label": "Heading"
              }
            }
          },
          "caption": {
            "name": "Caption",
            "settings": {
              "text": {
                "label": "Text"
              },
              "text_style": {
                "label": "Text style",
                "options__1": {
                  "label": "Subtitle"
                },
                "options__2": {
                  "label": "Uppercase"
                }
              },
              "caption_size": {
                "label": "Text size",
                "options__1": {
                  "label": "Small"
                },
                "options__2": {
                  "label": "Medium"
                },
                "options__3": {
                  "label": "Large"
                }
              }
            }
          },
          "text": {
            "name": "Text",
            "settings": {
              "text": {
                "label": "Description"
              }
            }
          },
          "buttons": {
            "name": "Buttons",
            "settings": {
              "button_label_1": {
                "label": "First button label",
                "info": "Leave the label blank to hide the button."
              },
              "button_link_1": {
                "label": "First button link"
              },
              "button_style_secondary_1": {
                "label": "Use outline button style"
              },
              "button_label_2": {
                "label": "Second button label",
                "info": "Leave the label blank to hide the button."
              },
              "button_link_2": {
                "label": "Second button link"
              },
              "button_style_secondary_2": {
                "label": "Use outline button style"
              }
            }
          }
        },
        "presets": {
          "name": "Rich text"
        }
      },
      "video": {
        "name": "Video",
        "settings": {
          "heading": {
            "label": "Heading"
          },
          "cover_image": {
            "label": "Cover image"
          },
          "video_url": {
            "label": "URL",
            "placeholder": "Use a YouTube or Vimeo URL",
            "info": "Video plays in the page."
          },
          "description": {
            "label": "Video alt text",
            "info": "Describe the video for customers using screen readers. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/theme-features#video)"
          },
          "image_padding": {
            "label": "Add image padding",
            "info": "Select image padding if you don't want your cover image to be cropped."
          },
          "full_width": {
            "label": "Make section full width"
          }
        },
        "presets": {
          "name": "Video"
        }
      },
      "slideshow": {
        "name": "Slideshow",
        "settings": {
          "layout": {
            "label": "Layout",
            "options__1": {
              "label": "Full width"
            },
            "options__2": {
              "label": "Grid"
            }
          },
          "slide_height": {
            "label": "Slide height",
            "options__1": {
              "label": "Adapt to first image"
            },
            "options__2": {
              "label": "Small"
            },
            "options__3": {
              "label": "Medium"
            },
            "options__4": {
              "label": "Large"
            }
          },
          "slider_visual": {
            "label": "Pagination style",
            "options__1": {
              "label": "Counter"
            },
            "options__2": {
              "label": "Dots"
            },
            "options__3": {
              "label": "Numbers"
            }
          },
          "auto_rotate": {
            "label": "Auto-rotate slides"
          },
          "change_slides_speed": {
            "label": "Change slides every"
          },
          "mobile": {
            "content": "Mobile layout"
          },
          "show_text_below": {
            "label": "Show content below images on mobile"
          },
          "accessibility": {
            "content": "Accessibility",
            "label": "Slideshow description",
            "info": "Describe the slideshow for customers using screen readers."
          }
        },
        "blocks": {
          "slide": {
            "name": "Slide",
            "settings": {
              "image": {
                "label": "Image"
              },
              "heading": {
                "label": "Heading"
              },
              "subheading": {
                "label": "Subheading"
              },
              "button_label": {
                "label": "Button label",
                "info": "Leave the label blank to hide the button."
              },
              "link": {
                "label": "Button link"
              },
              "secondary_style": {
                "label": "Use outline button style"
              },
              "box_align": {
                "label": "Desktop content position",
                "info": "Position is automatically optimized for mobile.",
                "options__1": {
                  "label": "Top left"
                },
                "options__2": {
                  "label": "Top center"
                },
                "options__3": {
                  "label": "Top right"
                },
                "options__4": {
                  "label": "Middle left"
                },
                "options__5": {
                  "label": "Middle center"
                },
                "options__6": {
                  "label": "Middle right"
                },
                "options__7": {
                  "label": "Bottom left"
                },
                "options__8": {
                  "label": "Bottom center"
                },
                "options__9": {
                  "label": "Bottom right"
                }
              },
              "show_text_box": {
                "label": "Show container on desktop"
              },
              "text_alignment": {
                "label": "Desktop content alignment",
                "option_1": {
                  "label": "Left"
                },
                "option_2": {
                  "label": "Center"
                },
                "option_3": {
                  "label": "Right"
                }
              },
              "image_overlay_opacity": {
                "label": "Image overlay opacity"
              },
              "color_scheme": {
                "info": "Visible when container displayed."
              },
              "text_alignment_mobile": {
                "label": "Mobile content alignment",
                "options__1": {
                  "label": "Left"
                },
                "options__2": {
                  "label": "Center"
                },
                "options__3": {
                  "label": "Right"
                }
              }
            }
          }
        },
        "presets": {
          "name": "Slideshow"
        }
      },
      "collapsible_content": {
        "name": "Collapsible content",
        "settings": {
          "caption": {
            "label": "Caption"
          },
          "heading": {
            "label": "Heading"
          },
          "heading_alignment": {
            "label": "Heading alignment",
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            }
          },
          "layout": {
            "label": "Layout",
            "options__1": {
              "label": "No container"
            },
            "options__2": {
              "label": "Row container"
            },
            "options__3": {
              "label": "Section container"
            }
          },
          "container_color_scheme": {
            "label": "Container color scheme",
            "info": "Visible when Layout is set to Row or Section container."
          },
          "open_first_collapsible_row": {
            "label": "Open first collapsible row"
          },
          "header": {
            "content": "Image layout"
          },
          "image": {
            "label": "Image"
          },
          "image_ratio": {
            "label": "Image ratio",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Small"
            },
            "options__3": {
              "label": "Large"
            }
          },
          "desktop_layout": {
            "label": "Desktop layout",
            "options__1": {
              "label": "Image first"
            },
            "options__2": {
              "label": "Image second"
            },
            "info": "Image is always first on mobile."
          }
        },
        "blocks": {
          "collapsible_row": {
            "name": "Collapsible row",
            "settings": {
              "heading": {
                "info": "Include a heading that explains the content.",
                "label": "Heading"
              },
              "row_content": {
                "label": "Row content"
              },
              "page": {
                "label": "Row content from page"
              },
              "icon": {
                "label": "Icon",
                "options__1": {
                  "label": "None"
                },
                "options__2": {
                  "label": "Apple"
                },
                "options__3": {
                  "label": "Banana"
                },
                "options__4": {
                  "label": "Bottle"
                },
                "options__5": {
                  "label": "Box"
                },
                "options__6": {
                  "label": "Carrot"
                },
                "options__7": {
                  "label": "Chat bubble"
                },
                "options__8": {
                  "label": "Check mark"
                },
                "options__9": {
                  "label": "Clipboard"
                },
                "options__10": {
                  "label": "Dairy"
                },
                "options__11": {
                  "label": "Dairy free"
                },
                "options__12": {
                  "label": "Dryer"
                },
                "options__13": {
                  "label": "Eye"
                },
                "options__14": {
                  "label": "Fire"
                },
                "options__15": {
                  "label": "Gluten free"
                },
                "options__16": {
                  "label": "Heart"
                },
                "options__17": {
                  "label": "Iron"
                },
                "options__18": {
                  "label": "Leaf"
                },
                "options__19": {
                  "label": "Leather"
                },
                "options__20": {
                  "label": "Lightning bolt"
                },
                "options__21": {
                  "label": "Lipstick"
                },
                "options__22": {
                  "label": "Lock"
                },
                "options__23": {
                  "label": "Map pin"
                },
                "options__24": {
                  "label": "Nut free"
                },
                "options__25": {
                  "label": "Pants"
                },
                "options__26": {
                  "label": "Paw print"
                },
                "options__27": {
                  "label": "Pepper"
                },
                "options__28": {
                  "label": "Perfume"
                },
                "options__29": {
                  "label": "Plane"
                },
                "options__30": {
                  "label": "Plant"
                },
                "options__31": {
                  "label": "Price tag"
                },
                "options__32": {
                  "label": "Question mark"
                },
                "options__33": {
                  "label": "Recycle"
                },
                "options__34": {
                  "label": "Return"
                },
                "options__35": {
                  "label": "Ruler"
                },
                "options__36": {
                  "label": "Serving dish"
                },
                "options__37": {
                  "label": "Shirt"
                },
                "options__38": {
                  "label": "Shoe"
                },
                "options__39": {
                  "label": "Silhouette"
                },
                "options__40": {
                  "label": "Snowflake"
                },
                "options__41": {
                  "label": "Star"
                },
                "options__42": {
                  "label": "Stopwatch"
                },
                "options__43": {
                  "label": "Truck"
                },
                "options__44": {
                  "label": "Washing"
                }
              }
            }
          }
        },
        "presets": {
          "name": "Collapsible content"
        }
      },
      "integration-reviews-lai-ali-express-io": {
        "name": "LAI product reviews",

      },
      "featured-collection-slider": {
        "name": "Featured collection slider",
        "settings": {
          "title": {
            "label": "Heading"
          },
          "full_width": {
            "label": "Full Width Section"
          },
          "subtitle": {
            "label": "Subtitle"
          },
          "collection": {
            "label": "Collection"
          },
          "products_to_show": {
            "label": "Maximum products to show"
          },
          "columns_desktop": {
            "label": "Number of items on desktop"
          },
          "header": {
            "content": "Product card"
          },
          "image_ratio": {
            "label": "Image ratio",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Portrait"
            },
            "options__3": {
              "label": "Square"
            }
          },
          "header_mobile": {
            "content": "Mobile Layout"
          },
          "show_secondary_image": {
            "label": "Show second image on hover"
          },
          "columns_mobile": {
            "label": "Number of items on mobile",
            "options__1": {
              "label": "1 column"
            },
            "options__2": {
              "label": "2 columns"
            }
          }
        },
        "presets": {
          "name": "Featured collection slider"
        }
      },


    }
  }
  public static readonly schemasV7 = {
    "announcement-bar": {
      "name": "t:sections.announcement-bar.name",
      "max_blocks": 12,
      "blocks": [
        {
          "type": "announcement",
          "name": "t:sections.announcement-bar.blocks.announcement.name",
          "settings": [
            {
              "type": "text",
              "id": "text",
              "default": "Welcome to our store",
              "label": "t:sections.announcement-bar.blocks.announcement.settings.text.label"
            },
            {
              "type": "select",
              "id": "color_scheme",
              "options": [
                {
                  "value": "accent-1",
                  "label": "t:sections.all.colors.accent_1.label"
                },
                {
                  "value": "accent-2",
                  "label": "t:sections.all.colors.accent_2.label"
                },
                {
                  "value": "background-1",
                  "label": "t:sections.all.colors.background_1.label"
                },
                {
                  "value": "background-2",
                  "label": "t:sections.all.colors.background_2.label"
                },
                {
                  "value": "inverse",
                  "label": "t:sections.all.colors.inverse.label"
                }
              ],
              "default": "accent-1",
              "label": "t:sections.all.colors.label"
            },
            {
              "type": "url",
              "id": "link",
              "label": "t:sections.announcement-bar.blocks.announcement.settings.link.label"
            }
          ]
        }
      ],
      "settings" : [],
      "default": {
        "blocks": [
          {
            "type": "announcement"
          }
        ]
      }
    },

    "apps": {
      "name": "t:sections.apps.name",
      "tag": "section",
      "class": "section",
      "settings": [
        {
          "type": "checkbox",
          "id": "include_margins",
          "default": true,
          "label": "t:sections.apps.settings.include_margins.label"
        }
      ],
      "blocks": [
        {
          "type": "@app"
        }
      ],
      "presets": [
        {
          "name": "t:sections.apps.presets.name"
        }
      ]
    },

    "areviews-section": {
      "name": "Areviews Section",
      "presets": [
        {
          "name": "areviews section"
        }
      ]
    },

    "collage": {
      "name": "t:sections.collage.name",
      "tag": "section",
      "class": "section",
      "settings": [
        {
          "type": "text",
          "id": "heading",
          "default": "Multimedia collage",
          "label": "t:sections.collage.settings.heading.label"
        },
        {
          "type": "select",
          "id": "heading_size",
          "options": [
            {
              "value": "h2",
              "label": "t:sections.all.heading_size.options__1.label"
            },
            {
              "value": "h1",
              "label": "t:sections.all.heading_size.options__2.label"
            },
            {
              "value": "h0",
              "label": "t:sections.all.heading_size.options__3.label"
            }
          ],
          "default": "h1",
          "label": "t:sections.all.heading_size.label"
        },
        {
          "type": "select",
          "id": "desktop_layout",
          "options": [
            {
              "value": "left",
              "label": "t:sections.collage.settings.desktop_layout.options__1.label"
            },
            {
              "value": "right",
              "label": "t:sections.collage.settings.desktop_layout.options__2.label"
            }
          ],
          "default": "left",
          "label": "t:sections.collage.settings.desktop_layout.label"
        },
        {
          "type": "select",
          "id": "mobile_layout",
          "options": [
            {
              "value": "collage",
              "label": "t:sections.collage.settings.mobile_layout.options__1.label"
            },
            {
              "value": "column",
              "label": "t:sections.collage.settings.mobile_layout.options__2.label"
            }
          ],
          "default": "collage",
          "label": "t:sections.collage.settings.mobile_layout.label"
        },
        {
          "type": "select",
          "id": "color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-1",
          "label": "t:sections.all.colors.label",
          "info": "t:sections.all.colors.has_cards_info"
        },
        {
          "type": "header",
          "content": "t:sections.all.padding.section_padding_heading"
        },
        {
          "type": "range",
          "id": "padding_top",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_top",
          "default": 36
        },
        {
          "type": "range",
          "id": "padding_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_bottom",
          "default": 36
        }
      ],
      "blocks": [
        {
          "type": "image",
          "name": "t:sections.collage.blocks.image.name",
          "settings": [
            {
              "type": "image_picker",
              "id": "image",
              "label": "t:sections.collage.blocks.image.settings.image.label"
            },
            {
              "type": "checkbox",
              "id": "image_padding",
              "default": false,
              "label": "t:sections.collage.blocks.image.settings.image_padding.label",
              "info": "t:sections.collage.blocks.image.settings.image_padding.info"
            }
          ]
        },
        {
          "type": "product",
          "name": "t:sections.collage.blocks.product.name",
          "settings": [
            {
              "type": "product",
              "id": "product",
              "label": "t:sections.collage.blocks.product.settings.product.label"
            },
            {
              "type": "checkbox",
              "id": "second_image",
              "default": false,
              "label": "t:sections.collage.blocks.product.settings.second_image.label"
            }
          ]
        },
        {
          "type": "collection",
          "name": "t:sections.collage.blocks.collection.name",
          "settings": [
            {
              "type": "collection",
              "id": "collection",
              "label": "t:sections.collage.blocks.collection.settings.collection.label"
            }
          ]
        },
        {
          "type": "video",
          "name": "t:sections.collage.blocks.video.name",
          "settings": [
            {
              "type": "image_picker",
              "id": "cover_image",
              "label": "t:sections.collage.blocks.video.settings.cover_image.label"
            },
            {
              "type": "video_url",
              "id": "video_url",
              "accept": ["youtube", "vimeo"],
              "default": "https://www.youtube.com/watch?v=_9VUPq3SxOc",
              "label": "t:sections.collage.blocks.video.settings.video_url.label",
              "placeholder": "t:sections.collage.blocks.video.settings.video_url.placeholder",
              "info": "t:sections.collage.blocks.video.settings.video_url.info"
            },
            {
              "type": "text",
              "id": "description",
              "default": "Describe the video",
              "label": "t:sections.collage.blocks.video.settings.description.label",
              "info": "t:sections.collage.blocks.video.settings.description.info"
            },
            {
              "type": "checkbox",
              "id": "image_padding",
              "default": false,
              "label": "t:sections.collage.blocks.video.settings.image_padding.label",
              "info": "t:sections.collage.blocks.video.settings.image_padding.info"
            }
          ]
        }
      ],
      "max_blocks": 3,
      "presets": [
        {
          "name": "t:sections.collage.presets.name",
          "blocks": [
            {
              "type": "image"
            },
            {
              "type": "video"
            },
            {
              "type": "product"
            },
            {
              "type": "collection"
            }
          ]
        }
      ]
    },

    "collapsible-content": {
      "name": "t:sections.collapsible_content.name",
      "tag": "section",
      "class": "section",
      "settings": [
        {
          "type": "text",
          "id": "caption",
          "label": "t:sections.collapsible_content.settings.caption.label"
        },
        {
          "type": "text",
          "id": "heading",
          "label": "t:sections.collapsible_content.settings.heading.label",
          "default": "Collapsible content"
        },
        {
          "type": "select",
          "id": "heading_size",
          "options": [
            {
              "value": "h2",
              "label": "t:sections.all.heading_size.options__1.label"
            },
            {
              "value": "h1",
              "label": "t:sections.all.heading_size.options__2.label"
            },
            {
              "value": "h0",
              "label": "t:sections.all.heading_size.options__3.label"
            }
          ],
          "default": "h1",
          "label": "t:sections.all.heading_size.label"
        },
        {
          "type": "select",
          "id": "heading_alignment",
          "label": "t:sections.collapsible_content.settings.heading_alignment.label",
          "options": [
            {
              "value": "left",
              "label": "t:sections.collapsible_content.settings.heading_alignment.options__1.label"
            },
            {
              "value": "center",
              "label": "t:sections.collapsible_content.settings.heading_alignment.options__2.label"
            },
            {
              "value": "right",
              "label": "t:sections.collapsible_content.settings.heading_alignment.options__3.label"
            }
          ],
          "default": "center"
        },
        {
          "type": "select",
          "id": "layout",
          "label": "t:sections.collapsible_content.settings.layout.label",
          "options": [
            {
              "value": "none",
              "label": "t:sections.collapsible_content.settings.layout.options__1.label"
            },
            {
              "value": "row",
              "label": "t:sections.collapsible_content.settings.layout.options__2.label"
            },
            {
              "value": "section",
              "label": "t:sections.collapsible_content.settings.layout.options__3.label"
            }
          ],
          "default": "none"
        },
        {
          "type": "select",
          "id": "color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-1",
          "label": "t:sections.all.colors.label"
        },
        {
          "type": "select",
          "id": "container_color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-2",
          "label": "t:sections.collapsible_content.settings.container_color_scheme.label",
          "info": "t:sections.collapsible_content.settings.container_color_scheme.info"
        },
        {
          "type": "checkbox",
          "id": "open_first_collapsible_row",
          "default": false,
          "label": "t:sections.collapsible_content.settings.open_first_collapsible_row.label"
        },
        {
          "type": "header",
          "content": "t:sections.collapsible_content.settings.header.content"
        },
        {
          "type": "image_picker",
          "id": "image",
          "label": "t:sections.collapsible_content.settings.image.label"
        },
        {
          "type": "select",
          "id": "image_ratio",
          "options": [
            {
              "value": "adapt",
              "label": "t:sections.collapsible_content.settings.image_ratio.options__1.label"
            },
            {
              "value": "small",
              "label": "t:sections.collapsible_content.settings.image_ratio.options__2.label"
            },
            {
              "value": "large",
              "label": "t:sections.collapsible_content.settings.image_ratio.options__3.label"
            }
          ],
          "default": "adapt",
          "label": "t:sections.collapsible_content.settings.image_ratio.label"
        },
        {
          "type": "select",
          "id": "desktop_layout",
          "options": [
            {
              "value": "image_first",
              "label": "t:sections.collapsible_content.settings.desktop_layout.options__1.label"
            },
            {
              "value": "image_second",
              "label": "t:sections.collapsible_content.settings.desktop_layout.options__2.label"
            }
          ],
          "default": "image_second",
          "label": "t:sections.collapsible_content.settings.desktop_layout.label",
          "info": "t:sections.collapsible_content.settings.desktop_layout.info"
        },
        {
          "type": "header",
          "content": "t:sections.all.padding.section_padding_heading"
        },
        {
          "type": "range",
          "id": "padding_top",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_top",
          "default": 36
        },
        {
          "type": "range",
          "id": "padding_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_bottom",
          "default": 36
        }
      ],
      "blocks": [
        {
          "type": "collapsible_row",
          "name": "t:sections.collapsible_content.blocks.collapsible_row.name",
          "settings": [
            {
              "type": "text",
              "id": "heading",
              "default": "Collapsible row",
              "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.heading.label",
              "info": "t:sections.collapsible_content.blocks.collapsible_row.settings.heading.info"
            },
            {
              "type": "select",
              "id": "icon",
              "options": [
                {
                  "value": "none",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__1.label"
                },
                {
                  "value": "apple",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__2.label"
                },
                {
                  "value": "banana",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__3.label"
                },
                {
                  "value": "bottle",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__4.label"
                },
                {
                  "value": "box",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__5.label"
                },
                {
                  "value": "carrot",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__6.label"
                },
                {
                  "value": "chat_bubble",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__7.label"
                },
                {
                  "value": "check_mark",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__8.label"
                },
                {
                  "value": "clipboard",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__9.label"
                },
                {
                  "value": "dairy",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__10.label"
                },
                {
                  "value": "dairy_free",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__11.label"
                },
                {
                  "value": "dryer",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__12.label"
                },
                {
                  "value": "eye",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__13.label"
                },
                {
                  "value": "fire",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__14.label"
                },
                {
                  "value": "gluten_free",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__15.label"
                },
                {
                  "value": "heart",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__16.label"
                },
                {
                  "value": "iron",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__17.label"
                },
                {
                  "value": "leaf",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__18.label"
                },
                {
                  "value": "leather",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__19.label"
                },
                {
                  "value": "lightning_bolt",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__20.label"
                },
                {
                  "value": "lipstick",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__21.label"
                },
                {
                  "value": "lock",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__22.label"
                },
                {
                  "value": "map_pin",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__23.label"
                },
                {
                  "value": "nut_free",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__24.label"
                },
                {
                  "value": "pants",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__25.label"
                },
                {
                  "value": "paw_print",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__26.label"
                },
                {
                  "value": "pepper",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__27.label"
                },
                {
                  "value": "perfume",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__28.label"
                },
                {
                  "value": "plane",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__29.label"
                },
                {
                  "value": "plant",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__30.label"
                },
                {
                  "value": "price_tag",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__31.label"
                },
                {
                  "value": "question_mark",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__32.label"
                },
                {
                  "value": "recycle",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__33.label"
                },
                {
                  "value": "return",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__34.label"
                },
                {
                  "value": "ruler",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__35.label"
                },
                {
                  "value": "serving_dish",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__36.label"
                },
                {
                  "value": "shirt",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__37.label"
                },
                {
                  "value": "shoe",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__38.label"
                },
                {
                  "value": "silhouette",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__39.label"
                },
                {
                  "value": "snowflake",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__40.label"
                },
                {
                  "value": "star",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__41.label"
                },
                {
                  "value": "stopwatch",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__42.label"
                },
                {
                  "value": "truck",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__43.label"
                },
                {
                  "value": "washing",
                  "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__44.label"
                }
              ],
              "default": "check_mark",
              "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.label"
            },
            {
              "type": "richtext",
              "id": "row_content",
              "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.row_content.label"
            },
            {
              "type": "page",
              "id": "page",
              "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.page.label"
            }
          ]
        }
      ],
      "presets": [
        {
          "name": "t:sections.collapsible_content.presets.name",
          "blocks": [
            {
              "type": "collapsible_row"
            },
            {
              "type": "collapsible_row"
            },
            {
              "type": "collapsible_row"
            },
            {
              "type": "collapsible_row"
            }
          ]
        }
      ]
    },

    "collection-list": {
      "name": "t:sections.collection-list.name",
      "tag": "section",
      "class": "section section-collection-list",
      "max_blocks": 15,
      "settings": [
        {
          "type": "text",
          "id": "title",
          "default": "Collections",
          "label": "t:sections.collection-list.settings.title.label"
        },
        {
          "type": "select",
          "id": "heading_size",
          "options": [
            {
              "value": "h2",
              "label": "t:sections.all.heading_size.options__1.label"
            },
            {
              "value": "h1",
              "label": "t:sections.all.heading_size.options__2.label"
            },
            {
              "value": "h0",
              "label": "t:sections.all.heading_size.options__3.label"
            }
          ],
          "default": "h1",
          "label": "t:sections.all.heading_size.label"
        },
        {
          "type": "select",
          "id": "image_ratio",
          "options": [
            {
              "value": "adapt",
              "label": "t:sections.collection-list.settings.image_ratio.options__1.label"
            },
            {
              "value": "portrait",
              "label": "t:sections.collection-list.settings.image_ratio.options__2.label"
            },
            {
              "value": "square",
              "label": "t:sections.collection-list.settings.image_ratio.options__3.label"
            },
            {
              "value": "landscape",
              "label": "t:sections.collection-list.settings.image_ratio.options__4.label"
            }
          ],
          "default": "square",
          "label": "t:sections.collection-list.settings.image_ratio.label",
          "info": "t:sections.collection-list.settings.image_ratio.info"
        },
        {
          "type": "range",
          "id": "columns_desktop",
          "min": 1,
          "max": 5,
          "step": 1,
          "default": 3,
          "label": "t:sections.collection-list.settings.columns_desktop.label"
        },
        {
          "type": "select",
          "id": "color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-1",
          "label": "t:sections.all.colors.label",
          "info": "t:sections.all.colors.has_cards_info"
        },
        {
          "type": "checkbox",
          "id": "show_view_all",
          "default": false,
          "label": "t:sections.collection-list.settings.show_view_all.label"
        },
        {
          "type": "header",
          "content": "t:sections.collection-list.settings.header_mobile.content"
        },
        {
          "type": "select",
          "id": "columns_mobile",
          "options": [
            {
              "value": "1",
              "label": "t:sections.collection-list.settings.columns_mobile.options__1.label"
            },
            {
              "value": "2",
              "label": "t:sections.collection-list.settings.columns_mobile.options__2.label"
            }
          ],
          "default": "1",
          "label": "t:sections.collection-list.settings.columns_mobile.label"
        },
        {
          "type": "checkbox",
          "id": "swipe_on_mobile",
          "default": false,
          "label": "t:sections.collection-list.settings.swipe_on_mobile.label"
        },
        {
          "type": "header",
          "content": "t:sections.all.padding.section_padding_heading"
        },
        {
          "type": "range",
          "id": "padding_top",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_top",
          "default": 36
        },
        {
          "type": "range",
          "id": "padding_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_bottom",
          "default": 36
        }
      ],
      "blocks": [
        {
          "type": "featured_collection",
          "name": "t:sections.collection-list.blocks.featured_collection.name",
          "settings": [
            {
              "type": "collection",
              "id": "collection",
              "label": "t:sections.collection-list.blocks.featured_collection.settings.collection.label"
            }
          ]
        }
      ],
      "presets": [
        {
          "name": "t:sections.collection-list.presets.name",
          "blocks": [
            {
              "type": "featured_collection"
            },
          ]
        }
      ]
    },

    "contact-form": {
      "name": "t:sections.contact-form.name",
      "tag": "section",
      "class": "section",
      "settings": [
        {
          "type": "text",
          "id": "heading",
          "default": "Contact form",
          "label": "Heading"
        },
        {
          "type": "select",
          "id": "heading_size",
          "options": [
            {
              "value": "h2",
              "label": "t:sections.all.heading_size.options__1.label"
            },
            {
              "value": "h1",
              "label": "t:sections.all.heading_size.options__2.label"
            },
            {
              "value": "h0",
              "label": "t:sections.all.heading_size.options__3.label"
            }
          ],
          "default": "h1",
          "label": "t:sections.all.heading_size.label"
        },
        {
          "type": "select",
          "id": "color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-1",
          "label": "t:sections.all.colors.label"
        },
        {
          "type": "header",
          "content": "t:sections.all.padding.section_padding_heading"
        },
        {
          "type": "range",
          "id": "padding_top",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_top",
          "default": 36
        },
        {
          "type": "range",
          "id": "padding_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_bottom",
          "default": 36
        }
      ],
      "presets": [
        {
          "name": "t:sections.contact-form.presets.name"
        }
      ]
    },

    "custom-liquid": {
      "name": "t:sections.custom-liquid.name",
      "tag": "section",
      "class": "section",
      "settings": [
        {
          "type": "liquid",
          "id": "custom_liquid",
          "label": "t:sections.custom-liquid.settings.custom_liquid.label",
          "info": "t:sections.custom-liquid.settings.custom_liquid.info"
        },
        {
          "type": "select",
          "id": "color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-1",
          "label": "t:sections.all.colors.label"
        },
        {
          "type": "header",
          "content": "t:sections.all.padding.section_padding_heading"
        },
        {
          "type": "range",
          "id": "padding_top",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_top",
          "default": 40
        },
        {
          "type": "range",
          "id": "padding_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_bottom",
          "default": 52
        }
      ],
      "presets": [
        {
          "name": "t:sections.custom-liquid.presets.name"
        }
      ]
    },

    "email-signup-banner": {
      "name": "t:sections.email-signup-banner.name",
      "tag": "section",
      "class": "section",
      "settings": [
        {
          "type": "paragraph",
          "content": "t:sections.email-signup-banner.settings.paragraph.content"
        },
        {
          "type": "image_picker",
          "id": "image",
          "label": "t:sections.email-signup-banner.settings.image.label"
        },
        {
          "type": "range",
          "id": "image_overlay_opacity",
          "min": 0,
          "max": 100,
          "step": 10,
          "unit": "%",
          "label": "t:sections.email-signup-banner.settings.image_overlay_opacity.label",
          "default": 0
        },
        {
          "type": "checkbox",
          "id": "show_background_image",
          "label": "t:sections.email-signup-banner.settings.show_background_image.label",
          "default": true
        },
        {
          "type": "select",
          "id": "image_height",
          "options": [
            {
              "value": "adapt-image",
              "label": "t:sections.email-signup-banner.settings.image_height.options__1.label"
            },
            {
              "value": "small",
              "label": "t:sections.email-signup-banner.settings.image_height.options__2.label"
            },
            {
              "value": "medium",
              "label": "t:sections.email-signup-banner.settings.image_height.options__3.label"
            },
            {
              "value": "large",
              "label": "t:sections.email-signup-banner.settings.image_height.options__4.label"
            }
          ],
          "default": "medium",
          "label": "t:sections.email-signup-banner.settings.image_height.label",
          "info": "t:sections.email-signup-banner.settings.image_height.info"
        },
        {
          "type": "select",
          "id": "desktop_content_position",
          "options": [
            {
              "value": "top-left",
              "label": "t:sections.email-signup-banner.settings.desktop_content_position.options__1.label"
            },
            {
              "value": "top-center",
              "label": "t:sections.email-signup-banner.settings.desktop_content_position.options__2.label"
            },
            {
              "value": "top-right",
              "label": "t:sections.email-signup-banner.settings.desktop_content_position.options__3.label"
            },
            {
              "value": "middle-left",
              "label": "t:sections.email-signup-banner.settings.desktop_content_position.options__4.label"
            },
            {
              "value": "middle-center",
              "label": "t:sections.email-signup-banner.settings.desktop_content_position.options__5.label"
            },
            {
              "value": "middle-right",
              "label": "t:sections.email-signup-banner.settings.desktop_content_position.options__6.label"
            },
            {
              "value": "bottom-left",
              "label": "t:sections.email-signup-banner.settings.desktop_content_position.options__7.label"
            },
            {
              "value": "bottom-center",
              "label": "t:sections.email-signup-banner.settings.desktop_content_position.options__8.label"
            },
            {
              "value": "bottom-right",
              "label": "t:sections.email-signup-banner.settings.desktop_content_position.options__9.label"
            }
          ],
          "default": "middle-center",
          "label": "t:sections.email-signup-banner.settings.desktop_content_position.label"
        },
        {
          "type": "checkbox",
          "id": "show_text_box",
          "default": true,
          "label": "t:sections.email-signup-banner.settings.show_text_box.label"
        },
        {
          "type": "select",
          "id": "desktop_content_alignment",
          "options": [
            {
              "value": "left",
              "label": "t:sections.email-signup-banner.settings.desktop_content_alignment.options__1.label"
            },
            {
              "value": "center",
              "label": "t:sections.email-signup-banner.settings.desktop_content_alignment.options__2.label"
            },
            {
              "value": "right",
              "label": "t:sections.email-signup-banner.settings.desktop_content_alignment.options__3.label"
            }
          ],
          "default": "center",
          "label": "t:sections.email-signup-banner.settings.desktop_content_alignment.label"
        },
        {
          "type": "select",
          "id": "color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-1",
          "label": "t:sections.all.colors.label",
          "info": "t:sections.email-signup-banner.settings.color_scheme.info"
        },
        {
          "type": "header",
          "content": "t:sections.email-signup-banner.settings.header.content"
        },
        {
          "type": "select",
          "id": "mobile_content_alignment",
          "options": [
            {
              "value": "left",
              "label": "t:sections.email-signup-banner.settings.mobile_content_alignment.options__1.label"
            },
            {
              "value": "center",
              "label": "t:sections.email-signup-banner.settings.mobile_content_alignment.options__2.label"
            },
            {
              "value": "right",
              "label": "t:sections.email-signup-banner.settings.mobile_content_alignment.options__3.label"
            }
          ],
          "default": "center",
          "label": "t:sections.email-signup-banner.settings.mobile_content_alignment.label"
        },
        {
          "type": "checkbox",
          "id": "show_text_below",
          "default": true,
          "label": "t:sections.email-signup-banner.settings.show_text_below.label"
        }
      ],
      "blocks": [
        {
          "type": "heading",
          "name": "t:sections.email-signup-banner.blocks.heading.name",
          "limit": 1,
          "settings": [
            {
              "type": "text",
              "id": "heading",
              "default": "Opening soon",
              "label": "t:sections.email-signup-banner.blocks.heading.settings.heading.label"
            },
            {
              "type": "select",
              "id": "heading_size",
              "options": [
                {
                  "value": "h2",
                  "label": "t:sections.all.heading_size.options__1.label"
                },
                {
                  "value": "h1",
                  "label": "t:sections.all.heading_size.options__2.label"
                },
                {
                  "value": "h0",
                  "label": "t:sections.all.heading_size.options__3.label"
                }
              ],
              "default": "h1",
              "label": "t:sections.all.heading_size.label"
            }
          ]
        },
        {
          "type": "paragraph",
          "name": "t:sections.email-signup-banner.blocks.paragraph.name",
          "limit": 1,
          "settings": [
            {
              "type": "richtext",
              "id": "text",
              "default": "<p>Be the first to know when we launch.</p>",
              "label": "t:sections.email-signup-banner.blocks.paragraph.settings.paragraph.label"
            },
            {
              "type": "select",
              "id": "text_style",
              "options": [
                {
                  "value": "body",
                  "label": "t:sections.email-signup-banner.blocks.paragraph.settings.text_style.options__1.label"
                },
                {
                  "value": "subtitle",
                  "label": "t:sections.email-signup-banner.blocks.paragraph.settings.text_style.options__2.label"
                }
              ],
              "default": "body",
              "label": "t:sections.email-signup-banner.blocks.paragraph.settings.text_style.label"
            }
          ]
        },
        {
          "type": "email_form",
          "name": "t:sections.email-signup-banner.blocks.email_form.name",
          "limit": 1
        }
      ],
      "presets": [
        {
          "name": "t:sections.email-signup-banner.presets.name",
          "blocks": [
            {
              "type": "heading"
            },
            {
              "type": "paragraph"
            },
            {
              "type": "email_form"
            }
          ]
        }
      ],
      "templates": ["password"]
    },

    "featured-blog": {
      "name": "t:sections.featured-blog.name",
      "tag": "section",
      "class": "section",
      "settings": [
        {
          "type": "text",
          "id": "heading",
          "default": "Blog posts",
          "label": "t:sections.featured-blog.settings.heading.label"
        },
        {
          "type": "select",
          "id": "heading_size",
          "options": [
            {
              "value": "h2",
              "label": "t:sections.all.heading_size.options__1.label"
            },
            {
              "value": "h1",
              "label": "t:sections.all.heading_size.options__2.label"
            },
            {
              "value": "h0",
              "label": "t:sections.all.heading_size.options__3.label"
            }
          ],
          "default": "h1",
          "label": "t:sections.all.heading_size.label"
        },
        {
          "type": "blog",
          "id": "blog",
          "label": "t:sections.featured-blog.settings.blog.label"
        },
        {
          "type": "range",
          "id": "post_limit",
          "min": 2,
          "max": 4,
          "step": 1,
          "default": 3,
          "label": "t:sections.featured-blog.settings.post_limit.label"
        },
        {
          "type": "range",
          "id": "columns_desktop",
          "min": 1,
          "max": 4,
          "step": 1,
          "default": 3,
          "label": "t:sections.featured-blog.settings.columns_desktop.label"
        },
        {
          "type": "select",
          "id": "color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-1",
          "label": "t:sections.all.colors.label",
          "info": "t:sections.all.colors.has_cards_info"
        },
        {
          "type": "checkbox",
          "id": "show_image",
          "default": true,
          "label": "t:sections.featured-blog.settings.show_image.label",
          "info": "t:sections.featured-blog.settings.show_image.info"
        },
        {
          "type": "checkbox",
          "id": "show_date",
          "default": true,
          "label": "t:sections.featured-blog.settings.show_date.label"
        },
        {
          "type": "checkbox",
          "id": "show_author",
          "default": false,
          "label": "t:sections.featured-blog.settings.show_author.label"
        },
        {
          "type": "checkbox",
          "id": "show_view_all",
          "default": true,
          "label": "t:sections.featured-blog.settings.show_view_all.label"
        },
        {
          "type": "header",
          "content": "t:sections.all.padding.section_padding_heading"
        },
        {
          "type": "range",
          "id": "padding_top",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_top",
          "default": 36
        },
        {
          "type": "range",
          "id": "padding_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_bottom",
          "default": 36
        }
      ],
      "presets": [
        {
          "name": "t:sections.featured-blog.presets.name",
          "settings": {
            "blog": "News"
          }
        }
      ]
    },

    "featured-collection": {
      "name": "t:sections.featured-collection.name",
      "tag": "section",
      "class": "section",
      "settings": [
        {
          "type": "text",
          "id": "title",
          "default": "Featured collection",
          "label": "t:sections.featured-collection.settings.title.label"
        },
        {
          "type": "select",
          "id": "heading_size",
          "options": [
            {
              "value": "h2",
              "label": "t:sections.all.heading_size.options__1.label"
            },
            {
              "value": "h1",
              "label": "t:sections.all.heading_size.options__2.label"
            },
            {
              "value": "h0",
              "label": "t:sections.all.heading_size.options__3.label"
            }
          ],
          "default": "h1",
          "label": "t:sections.all.heading_size.label"
        },
        {
          "type": "richtext",
          "id": "description",
          "label": "t:sections.featured-collection.settings.description.label"
        },
        {
          "type": "checkbox",
          "id": "show_description",
          "label": "t:sections.featured-collection.settings.show_description.label",
          "default": false
        },
        {
          "type": "select",
          "id": "description_style",
          "label": "t:sections.featured-collection.settings.description_style.label",
          "options": [
            {
              "value": "body",
              "label": "t:sections.featured-collection.settings.description_style.options__1.label"
            },
            {
              "value": "subtitle",
              "label": "t:sections.featured-collection.settings.description_style.options__2.label"
            },
            {
              "value": "uppercase",
              "label": "t:sections.featured-collection.settings.description_style.options__3.label"
            }
          ],
          "default": "body"
        },
        {
          "type": "collection",
          "id": "collection",
          "label": "t:sections.featured-collection.settings.collection.label"
        },
        {
          "type": "range",
          "id": "products_to_show",
          "min": 2,
          "max": 12,
          "step": 1,
          "default": 4,
          "label": "t:sections.featured-collection.settings.products_to_show.label"
        },
        {
          "type": "range",
          "id": "columns_desktop",
          "min": 1,
          "max": 5,
          "step": 1,
          "default": 4,
          "label": "t:sections.featured-collection.settings.columns_desktop.label"
        },
        {
          "type": "checkbox",
          "id": "full_width",
          "label": "t:sections.featured-collection.settings.full_width.label",
          "default": false
        },
        {
          "type": "checkbox",
          "id": "show_view_all",
          "default": true,
          "label": "t:sections.featured-collection.settings.show_view_all.label"
        },
        {
          "type": "select",
          "id": "view_all_style",
          "label": "t:sections.featured-collection.settings.view_all_style.label",
          "options": [
            {
              "value": "link",
              "label": "t:sections.featured-collection.settings.view_all_style.options__1.label"
            },
            {
              "value": "outline",
              "label": "t:sections.featured-collection.settings.view_all_style.options__2.label"
            },
            {
              "value": "solid",
              "label": "t:sections.featured-collection.settings.view_all_style.options__3.label"
            }
          ],
          "default": "solid"
        },
        {
          "type": "checkbox",
          "id": "enable_desktop_slider",
          "label": "t:sections.featured-collection.settings.enable_desktop_slider.label",
          "default": false
        },
        {
          "type": "select",
          "id": "color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-1",
          "label": "t:sections.all.colors.label",
          "info": "t:sections.all.colors.has_cards_info"
        },
        {
          "type": "header",
          "content": "t:sections.featured-collection.settings.header.content"
        },
        {
          "type": "select",
          "id": "image_ratio",
          "options": [
            {
              "value": "adapt",
              "label": "t:sections.featured-collection.settings.image_ratio.options__1.label"
            },
            {
              "value": "portrait",
              "label": "t:sections.featured-collection.settings.image_ratio.options__2.label"
            },
            {
              "value": "square",
              "label": "t:sections.featured-collection.settings.image_ratio.options__3.label"
            }
          ],
          "default": "adapt",
          "label": "t:sections.featured-collection.settings.image_ratio.label"
        },
        {
          "type": "checkbox",
          "id": "show_secondary_image",
          "default": false,
          "label": "t:sections.featured-collection.settings.show_secondary_image.label"
        },
        {
          "type": "checkbox",
          "id": "show_vendor",
          "default": false,
          "label": "t:sections.featured-collection.settings.show_vendor.label"
        },
        {
          "type": "checkbox",
          "id": "show_rating",
          "default": false,
          "label": "t:sections.featured-collection.settings.show_rating.label",
          "info": "t:sections.featured-collection.settings.show_rating.info"
        },
        {
          "type": "checkbox",
          "id": "enable_quick_add",
          "default": false,
          "label": "t:sections.featured-collection.settings.enable_quick_buy.label"
        },
        {
          "type": "header",
          "content": "t:sections.featured-collection.settings.header_mobile.content"
        },
        {
          "type": "select",
          "id": "columns_mobile",
          "default": "2",
          "label": "t:sections.featured-collection.settings.columns_mobile.label",
          "options": [
            {
              "value": "1",
              "label": "t:sections.featured-collection.settings.columns_mobile.options__1.label"
            },
            {
              "value": "2",
              "label": "t:sections.featured-collection.settings.columns_mobile.options__2.label"
            }
          ]
        },
        {
          "type": "checkbox",
          "id": "swipe_on_mobile",
          "default": false,
          "label": "t:sections.featured-collection.settings.swipe_on_mobile.label"
        },
        {
          "type": "header",
          "content": "t:sections.all.padding.section_padding_heading"
        },
        {
          "type": "range",
          "id": "padding_top",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_top",
          "default": 36
        },
        {
          "type": "range",
          "id": "padding_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_bottom",
          "default": 36
        }
      ],
      "presets": [
        {
          "name": "t:sections.featured-collection.presets.name"
        }
      ]
    },
    "custom-html": {
      "name": "t:sections.featured-collection.name",
      "tag": "section",
      "class": "section",
      "settings": [
        {
          "type": "html",
          "id": "html",
          "default": "Enter Html",
          "label": "Enter HTML"
        }
      ]

    },

    "featured-product": {
      "name": "t:sections.featured-product.name",
      "tag": "section",
      "class": "section section-featured-product",
      "blocks": [
        {
          "type": "@app"
        },
        {
          "type": "text",
          "name": "t:sections.featured-product.blocks.text.name",
          "settings": [
            {
              "type": "text",
              "id": "text",
              "default": "Text block",
              "label": "t:sections.featured-product.blocks.text.settings.text.label"
            },
            {
              "type": "select",
              "id": "text_style",
              "options": [
                {
                  "value": "body",
                  "label": "t:sections.featured-product.blocks.text.settings.text_style.options__1.label"
                },
                {
                  "value": "subtitle",
                  "label": "t:sections.featured-product.blocks.text.settings.text_style.options__2.label"
                },
                {
                  "value": "uppercase",
                  "label": "t:sections.featured-product.blocks.text.settings.text_style.options__3.label"
                }
              ],
              "default": "body",
              "label": "t:sections.featured-product.blocks.text.settings.text_style.label"
            }
          ]
        },
        {
          "type": "title",
          "name": "t:sections.featured-product.blocks.title.name",
          "limit": 1,
          "settings": [
            {
              "type": "select",
              "id": "heading_size",
              "options": [
                {
                  "value": "h2",
                  "label": "t:sections.all.heading_size.options__1.label"
                },
                {
                  "value": "h1",
                  "label": "t:sections.all.heading_size.options__2.label"
                },
                {
                  "value": "h0",
                  "label": "t:sections.all.heading_size.options__3.label"
                }
              ],
              "default": "h1",
              "label": "t:sections.all.heading_size.label"
            }
          ]
        },
        {
          "type": "price",
          "name": "t:sections.featured-product.blocks.price.name",
          "limit": 1
        },
        {
          "type": "quantity_selector",
          "name": "t:sections.featured-product.blocks.quantity_selector.name",
          "limit": 1
        },
        {
          "type": "variant_picker",
          "name": "t:sections.featured-product.blocks.variant_picker.name",
          "limit": 1,
          "settings": [
            {
              "type": "select",
              "id": "picker_type",
              "options": [
                {
                  "value": "dropdown",
                  "label": "t:sections.featured-product.blocks.variant_picker.settings.picker_type.options__1.label"
                },
                {
                  "value": "button",
                  "label": "t:sections.featured-product.blocks.variant_picker.settings.picker_type.options__2.label"
                }
              ],
              "default": "button",
              "label": "t:sections.featured-product.blocks.variant_picker.settings.picker_type.label"
            }
          ]
        },
        {
          "type": "buy_buttons",
          "name": "t:sections.featured-product.blocks.buy_buttons.name",
          "limit": 1,
          "settings": [
            {
              "type": "checkbox",
              "id": "show_dynamic_checkout",
              "default": true,
              "label": "t:sections.featured-product.blocks.buy_buttons.settings.show_dynamic_checkout.label",
              "info": "t:sections.featured-product.blocks.buy_buttons.settings.show_dynamic_checkout.info"
            }
          ]
        },
        {
          "type": "share",
          "name": "t:sections.featured-product.blocks.share.name",
          "limit": 1,
          "settings": [
            {
              "type": "text",
              "id": "share_label",
              "label": "t:sections.featured-product.blocks.share.settings.text.label",
              "default": "Share"
            },
            {
              "type": "paragraph",
              "content": "t:sections.featured-product.blocks.share.settings.featured_image_info.content"
            },
            {
              "type": "paragraph",
              "content": "t:sections.featured-product.blocks.share.settings.title_info.content"
            }
          ]
        },
        {
          "type": "custom_liquid",
          "name": "t:sections.featured-product.blocks.custom_liquid.name",
          "settings": [
            {
              "type": "liquid",
              "id": "custom_liquid",
              "label": "t:sections.featured-product.blocks.custom_liquid.settings.custom_liquid.label"
            }
          ]
        },
        {
          "type": "rating",
          "name": "t:sections.featured-product.blocks.rating.name",
          "limit": 1,
          "settings": [
            {
              "type": "paragraph",
              "content": "t:sections.featured-product.blocks.rating.settings.paragraph.content"
            }
          ]
        }
      ],
      "settings": [
        {
          "type": "product",
          "id": "product",
          "label": "t:sections.featured-product.settings.product.label"
        },
        {
          "type": "select",
          "id": "color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-1",
          "label": "t:sections.all.colors.label"
        },
        {
          "type": "checkbox",
          "id": "secondary_background",
          "default": false,
          "label": "t:sections.featured-product.settings.secondary_background.label"
        },
        {
          "type": "header",
          "content": "t:sections.featured-product.settings.header.content",
          "info": "t:sections.featured-product.settings.header.info"
        },
        {
          "type": "checkbox",
          "id": "hide_variants",
          "default": false,
          "label": "t:sections.main-product.settings.hide_variants.label"
        },
        // {
        //   "type": "checkbox",
        //   "id": "enable_video_looping",
        //   "default": false,
        //   "label": "t:sections.featured-product.settings.enable_video_looping.label"
        // },
        {
          "type": "header",
          "content": "t:sections.all.padding.section_padding_heading"
        },
        {
          "type": "range",
          "id": "padding_top",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_top",
          "default": 36
        },
        {
          "type": "range",
          "id": "padding_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_bottom",
          "default": 36
        }
      ],
      "presets": [
        {
          "name": "t:sections.featured-product.presets.name",
          "blocks": [
            {
              "type": "text",
              "settings": {
                "text": "{{ section.settings.product.vendor }}",
                "text_style": "uppercase"
              }
            },
            {
              "type": "title"
            },
            {
              "type": "text",
              "settings": {
                "text": "{{ section.settings.product.metafields.descriptors.subtitle.value }}",
                "text_style": "subtitle"
              }
            },
            {
              "type": "price"
            },
            {
              "type": "variant_picker"
            },
            {
              "type": "quantity_selector"
            },
            {
              "type": "buy_buttons"
            },
            {
              "type": "share"
            }
          ]
        }
      ]
    },

    "footer": {
      "name": "t:sections.footer.name",
      "blocks": [
        {
          "type": "link_list",
          "name": "t:sections.footer.blocks.link_list.name",
          "settings": [
            {
              "type": "text",
              "id": "heading",
              "default": "Quick links",
              "label": "t:sections.footer.blocks.link_list.settings.heading.label",
              "info": "t:sections.footer.blocks.link_list.settings.heading.info"
            },
            {
              "type": "link_list",
              "id": "menu",
              "default": "footer",
              "label": "t:sections.footer.blocks.link_list.settings.menu.label",
              "info": "t:sections.footer.blocks.link_list.settings.menu.info"
            }
          ]
        },
        {
          "type": "text",
          "name": "t:sections.footer.blocks.text.name",
          "settings": [
            {
              "type": "text",
              "id": "heading",
              "default": "Heading",
              "label": "t:sections.footer.blocks.text.settings.heading.label"
            },
            {
              "type": "richtext",
              "id": "subtext",
              "default": "<p>Share contact information, store details, and brand content with your customers.</p>",
              "label": "t:sections.footer.blocks.text.settings.subtext.label"
            }
          ]
        },
        {
          "type": "image",
          "name": "Image",
          "settings": [
            {
              "type": "image_picker",
              "id": "image",
              "label": "Image"
            },
            {
              "type": "range",
              "id": "image_width",
              "min": 50,
              "max": 200,
              "step": 5,
              "unit": "px",
              "label": "Image width",
              "default": 100
            },
            {
              "type": "select",
              "id": "alignment",
              "label": "Image alignment on large screen",
              "options": [
                {
                  "value": "",
                  "label": "Left"
                },
                {
                  "value": "center",
                  "label": "Center"
                },
                {
                  "value": "right",
                  "label": "Right"
                }
              ],
              "default": "center"
            }
          ]
        }
      ],
      "settings": [
        {
          "type": "select",
          "id": "color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-1",
          "label": "t:sections.all.colors.label"
        },
        {
          "type": "header",
          "content": "t:sections.footer.settings.header__1.content",
          "info": "t:sections.footer.settings.header__1.info"
        },
        {
          "type": "checkbox",
          "id": "newsletter_enable",
          "default": true,
          "label": "t:sections.footer.settings.newsletter_enable.label"
        },
        {
          "type": "text",
          "id": "newsletter_heading",
          "default": "Subscribe to our emails",
          "label": "t:sections.footer.settings.newsletter_heading.label"
        },
        {
          "type": "header",
          "content": "t:sections.footer.settings.header__2.content",
          "info": "t:sections.footer.settings.header__2.info"
        },
        {
          "type": "checkbox",
          "id": "show_social",
          "default": false,
          "label": "t:sections.footer.settings.show_social.label"
        },
        {
          "type": "header",
          "content": "t:sections.footer.settings.header__3.content",
          "info": "t:sections.footer.settings.header__4.info"
        },
        {
          "type": "checkbox",
          "id": "enable_country_selector",
          "default": true,
          "label": "t:sections.footer.settings.enable_country_selector.label"
        },
        {
          "type": "header",
          "content": "t:sections.footer.settings.header__5.content",
          "info": "t:sections.footer.settings.header__6.info"
        },
        {
          "type": "checkbox",
          "id": "enable_language_selector",
          "default": true,
          "label": "t:sections.footer.settings.enable_language_selector.label"
        },
        {
          "type": "header",
          "content": "t:sections.footer.settings.header__7.content"
        },
        {
          "type": "checkbox",
          "id": "payment_enable",
          "default": true,
          "label": "t:sections.footer.settings.payment_enable.label"
        },
        {
          "type": "header",
          "content": "t:sections.all.spacing"
        },
        {
          "type": "range",
          "id": "margin_top",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.footer.settings.margin_top.label",
          "default": 0
        },
        {
          "type": "header",
          "content": "t:sections.all.padding.section_padding_heading"
        },
        {
          "type": "range",
          "id": "padding_top",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_top",
          "default": 36
        },
        {
          "type": "range",
          "id": "padding_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_bottom",
          "default": 36
        }
      ],
      "presets": [
        {
        "blocks": [
          {
            "type": "link_list"
          },
          {
            "type": "text"
          }
        ]
      }
      ]
    },

    "header": {
      "name": "t:sections.header.name",
      "class": "section-header",
      "settings": [
        {
          "type": "select",
          "id": "color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-1",
          "label": "t:sections.all.colors.label"
        },
        {
          "type": "image_picker",
          "id": "logo",
          "label": "t:sections.header.settings.logo.label"
        },
        {
          "type": "range",
          "id": "logo_width",
          "min": 50,
          "max": 250,
          "step": 10,
          "default": 100,
          "unit": "t:sections.header.settings.logo_width.unit",
          "label": "t:sections.header.settings.logo_width.label"
        },
        {
          "type": "select",
          "id": "logo_position",
          "options": [
            {
              "value": "middle-left",
              "label": "t:sections.header.settings.logo_position.options__1.label"
            },
            {
              "value": "top-left",
              "label": "t:sections.header.settings.logo_position.options__2.label"
            },
            {
              "value": "top-center",
              "label": "t:sections.header.settings.logo_position.options__3.label"
            }
          ],
          "default": "middle-left",
          "label": "t:sections.header.settings.logo_position.label",
          "info": "t:sections.header.settings.logo_position.info"
        },
        {
          "type": "link_list",
          "id": "menu",
          "default": "main-menu",
          "label": "t:sections.header.settings.menu.label"
        },
        // {
        //   "type": "select",
        //   "id": "menu_type_desktop",
        //   "options": [
        //     {
        //       "value": "dropdown",
        //       "label": "t:sections.header.settings.menu_type_desktop.options__1.label"
        //     },
        //     {
        //       "value": "mega",
        //       "label": "t:sections.header.settings.menu_type_desktop.options__2.label"
        //     }
        //   ],
        //   "default": "dropdown",
        //   "label": "t:sections.header.settings.menu_type_desktop.label",
        //   "info": "t:sections.header.settings.menu_type_desktop.info"
        // },
        // {
        //   "type": "checkbox",
        //   "id": "show_line_separator",
        //   "default": true,
        //   "label": "t:sections.header.settings.show_line_separator.label"
        // },
        // {
        //   "type": "checkbox",
        //   "id": "enable_sticky_header",
        //   "default": true,
        //   "label": "t:sections.header.settings.enable_sticky_header.label",
        //   "info": "t:sections.header.settings.enable_sticky_header.info"
        // },
        {
          "type": "header",
          "content": "t:sections.all.spacing"
        },
        {
          "type": "range",
          "id": "margin_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.header.settings.margin_bottom.label",
          "default": 0
        },
        {
          "type": "header",
          "content": "t:sections.all.padding.section_padding_heading"
        },
        {
          "type": "range",
          "id": "padding_top",
          "min": 0,
          "max": 36,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_top",
          "default": 20
        },
        {
          "type": "range",
          "id": "padding_bottom",
          "min": 0,
          "max": 36,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_bottom",
          "default": 20
        }
      ]
    },

    "image-banner": {
      "name": "t:sections.image-banner.name",
      "tag": "section",
      "class": "section",
      "settings": [
        {
          "type": "image_picker",
          "id": "image",
          "label": "t:sections.image-banner.settings.image.label"
        },
        {
          "type": "image_picker",
          "id": "image_2",
          "label": "t:sections.image-banner.settings.image_2.label"
        },
        {
          "type": "range",
          "id": "image_overlay_opacity",
          "min": 0,
          "max": 100,
          "step": 10,
          "unit": "%",
          "label": "t:sections.image-banner.settings.image_overlay_opacity.label",
          "default": 0
        },
        {
          "type": "select",
          "id": "image_height",
          "options": [
            {
              "value": "small",
              "label": "t:sections.image-banner.settings.image_height.options__1.label"
            },
            {
              "value": "medium",
              "label": "t:sections.image-banner.settings.image_height.options__2.label"
            },
            {
              "value": "large",
              "label": "t:sections.image-banner.settings.image_height.options__3.label"
            }
          ],
          "default": "medium",
          "label": "t:sections.image-banner.settings.image_height.label",
          "info": "t:sections.image-banner.settings.image_height.info"
        },
        {
          "type": "checkbox",
          "id": "adapt_height_first_image",
          "default": false,
          "label": "t:sections.image-banner.settings.adapt_height_first_image.label",
          "info": "t:sections.image-banner.settings.adapt_height_first_image.info"
        },
        {
          "type": "select",
          "id": "desktop_content_position",
          "options": [
            {
              "value": "top-left",
              "label": "t:sections.image-banner.settings.desktop_content_position.options__1.label"
            },
            {
              "value": "top-center",
              "label": "t:sections.image-banner.settings.desktop_content_position.options__2.label"
            },
            {
              "value": "top-right",
              "label": "t:sections.image-banner.settings.desktop_content_position.options__3.label"
            },
            {
              "value": "middle-left",
              "label": "t:sections.image-banner.settings.desktop_content_position.options__4.label"
            },
            {
              "value": "middle-center",
              "label": "t:sections.image-banner.settings.desktop_content_position.options__5.label"
            },
            {
              "value": "middle-right",
              "label": "t:sections.image-banner.settings.desktop_content_position.options__6.label"
            },
            {
              "value": "bottom-left",
              "label": "t:sections.image-banner.settings.desktop_content_position.options__7.label"
            },
            {
              "value": "bottom-center",
              "label": "t:sections.image-banner.settings.desktop_content_position.options__8.label"
            },
            {
              "value": "bottom-right",
              "label": "t:sections.image-banner.settings.desktop_content_position.options__9.label"
            }
          ],
          "default": "middle-center",
          "label": "t:sections.image-banner.settings.desktop_content_position.label"
        },
        {
          "type": "checkbox",
          "id": "show_text_box",
          "default": true,
          "label": "t:sections.image-banner.settings.show_text_box.label"
        },
        {
          "type": "select",
          "id": "desktop_content_alignment",
          "options": [
            {
              "value": "left",
              "label": "t:sections.image-banner.settings.desktop_content_alignment.options__1.label"
            },
            {
              "value": "center",
              "label": "t:sections.image-banner.settings.desktop_content_alignment.options__2.label"
            },
            {
              "value": "right",
              "label": "t:sections.image-banner.settings.desktop_content_alignment.options__3.label"
            }
          ],
          "default": "center",
          "label": "t:sections.image-banner.settings.desktop_content_alignment.label"
        },
        {
          "type": "select",
          "id": "color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-1",
          "label": "t:sections.all.colors.label",
          "info": "t:sections.image-banner.settings.color_scheme.info"
        },
        {
          "type": "header",
          "content": "t:sections.image-banner.settings.header.content"
        },
        {
          "type": "select",
          "id": "mobile_content_alignment",
          "options": [
            {
              "value": "left",
              "label": "t:sections.image-banner.settings.mobile_content_alignment.options__1.label"
            },
            {
              "value": "center",
              "label": "t:sections.image-banner.settings.mobile_content_alignment.options__2.label"
            },
            {
              "value": "right",
              "label": "t:sections.image-banner.settings.mobile_content_alignment.options__3.label"
            }
          ],
          "default": "center",
          "label": "t:sections.image-banner.settings.mobile_content_alignment.label"
        },
        {
          "type": "checkbox",
          "id": "stack_images_on_mobile",
          "default": true,
          "label": "t:sections.image-banner.settings.stack_images_on_mobile.label"
        },
        {
          "type": "checkbox",
          "id": "show_text_below",
          "default": true,
          "label": "t:sections.image-banner.settings.show_text_below.label"
        }
      ],
      "blocks": [
        {
          "type": "heading",
          "name": "t:sections.image-banner.blocks.heading.name",
          "limit": 1,
          "settings": [
            {
              "type": "text",
              "id": "heading",
              "default": "Image banner",
              "label": "t:sections.image-banner.blocks.heading.settings.heading.label"
            },
            {
              "type": "select",
              "id": "heading_size",
              "options": [
                {
                  "value": "h2",
                  "label": "t:sections.all.heading_size.options__1.label"
                },
                {
                  "value": "h1",
                  "label": "t:sections.all.heading_size.options__2.label"
                },
                {
                  "value": "h0",
                  "label": "t:sections.all.heading_size.options__3.label"
                }
              ],
              "default": "h1",
              "label": "t:sections.all.heading_size.label"
            }
          ]
        },
        {
          "type": "text",
          "name": "t:sections.image-banner.blocks.text.name",
          "limit": 1,
          "settings": [
            {
              "type": "text",
              "id": "text",
              "default": "Give customers details about the banner image(s) or content on the template.",
              "label": "t:sections.image-banner.blocks.text.settings.text.label"
            },
            {
              "type": "select",
              "id": "text_style",
              "options": [
                {
                  "value": "body",
                  "label": "t:sections.image-banner.blocks.text.settings.text_style.options__1.label"
                },
                {
                  "value": "subtitle",
                  "label": "t:sections.image-banner.blocks.text.settings.text_style.options__2.label"
                },
                {
                  "value": "caption-with-letter-spacing",
                  "label": "t:sections.image-banner.blocks.text.settings.text_style.options__3.label"
                }
              ],
              "default": "body",
              "label": "t:sections.image-banner.blocks.text.settings.text_style.label"
            }
          ]
        },
        {
          "type": "buttons",
          "name": "t:sections.image-banner.blocks.buttons.name",
          "limit": 1,
          "settings": [
            {
              "type": "text",
              "id": "button_label_1",
              "default": "Button label",
              "label": "t:sections.image-banner.blocks.buttons.settings.button_label_1.label",
              "info": "t:sections.image-banner.blocks.buttons.settings.button_label_1.info"
            },
            {
              "type": "url",
              "id": "button_link_1",
              "label": "t:sections.image-banner.blocks.buttons.settings.button_link_1.label"
            },
            {
              "type": "checkbox",
              "id": "button_style_secondary_1",
              "default": false,
              "label": "t:sections.image-banner.blocks.buttons.settings.button_style_secondary_1.label"
            },
            {
              "type": "text",
              "id": "button_label_2",
              "default": "Button label",
              "label": "t:sections.image-banner.blocks.buttons.settings.button_label_2.label",
              "info": "t:sections.image-banner.blocks.buttons.settings.button_label_2.info"
            },
            {
              "type": "url",
              "id": "button_link_2",
              "label": "t:sections.image-banner.blocks.buttons.settings.button_link_2.label"
            },
            {
              "type": "checkbox",
              "id": "button_style_secondary_2",
              "default": false,
              "label": "t:sections.image-banner.blocks.buttons.settings.button_style_secondary_2.label"
            }
          ]
        }
      ],
      "presets": [
        {
          "name": "t:sections.image-banner.presets.name",
          "blocks": [
            {
              "type": "heading"
            },
            {
              "type": "text"
            },
            {
              "type": "buttons"
            }
          ]
        }
      ]
    },

    "image-with-text": {
      "name": "t:sections.image-with-text.name",
      "class": "section",
      "settings": [
        {
          "type": "image_picker",
          "id": "image",
          "label": "t:sections.image-with-text.settings.image.label"
        },
        {
          "type": "select",
          "id": "height",
          "options": [
            {
              "value": "adapt",
              "label": "t:sections.image-with-text.settings.height.options__1.label"
            },
            {
              "value": "small",
              "label": "t:sections.image-with-text.settings.height.options__2.label"
            },
            {
              "value": "large",
              "label": "t:sections.image-with-text.settings.height.options__3.label"
            }
          ],
          "default": "adapt",
          "label": "t:sections.image-with-text.settings.height.label"
        },
        {
          "type": "select",
          "id": "desktop_image_width",
          "options": [
            {
              "value": "small",
              "label": "t:sections.image-with-text.settings.desktop_image_width.options__1.label"
            },
            {
              "value": "medium",
              "label": "t:sections.image-with-text.settings.desktop_image_width.options__2.label"
            },
            {
              "value": "large",
              "label": "t:sections.image-with-text.settings.desktop_image_width.options__3.label"
            }
          ],
          "default": "medium",
          "label": "t:sections.image-with-text.settings.desktop_image_width.label",
          "info": "t:sections.image-with-text.settings.desktop_image_width.info"
        },
        {
          "type": "select",
          "id": "layout",
          "options": [
            {
              "value": "image_first",
              "label": "t:sections.image-with-text.settings.layout.options__1.label"
            },
            {
              "value": "text_first",
              "label": "t:sections.image-with-text.settings.layout.options__2.label"
            }
          ],
          "default": "image_first",
          "label": "t:sections.image-with-text.settings.layout.label",
          "info": "t:sections.image-with-text.settings.layout.info"
        },
        {
          "type": "select",
          "id": "desktop_content_position",
          "options": [
            {
              "value": "top",
              "label": "t:sections.image-with-text.settings.desktop_content_position.options__1.label"
            },
            {
              "value": "middle",
              "label": "t:sections.image-with-text.settings.desktop_content_position.options__2.label"
            },
            {
              "value": "bottom",
              "label": "t:sections.image-with-text.settings.desktop_content_position.options__3.label"
            }
          ],
          "default": "top",
          "label": "t:sections.image-with-text.settings.desktop_content_position.label"
        },
        {
          "type": "select",
          "id": "desktop_content_alignment",
          "options": [
            {
              "value": "left",
              "label": "t:sections.image-with-text.settings.desktop_content_alignment.options__1.label"
            },
            {
              "value": "center",
              "label": "t:sections.image-with-text.settings.desktop_content_alignment.options__2.label"
            },
            {
              "value": "right",
              "label": "t:sections.image-with-text.settings.desktop_content_alignment.options__3.label"
            }
          ],
          "default": "left",
          "label": "t:sections.image-with-text.settings.desktop_content_alignment.label"
        },
        {
          "type": "select",
          "id": "content_layout",
          "options": [
            {
              "value": "no-overlap",
              "label": "t:sections.image-with-text.settings.content_layout.options__1.label"
            },
            {
              "value": "overlap",
              "label": "t:sections.image-with-text.settings.content_layout.options__2.label"
            }
          ],
          "default": "no-overlap",
          "label": "t:sections.image-with-text.settings.content_layout.label"
        },
        {
          "type": "select",
          "id": "color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-1",
          "label": "t:sections.all.colors.label"
        },
        {
          "type": "header",
          "content": "Mobile layout"
        },
        {
          "type": "select",
          "id": "mobile_content_alignment",
          "options": [
            {
              "value": "left",
              "label": "t:sections.image-with-text.settings.mobile_content_alignment.options__1.label"
            },
            {
              "value": "center",
              "label": "t:sections.image-with-text.settings.mobile_content_alignment.options__2.label"
            },
            {
              "value": "right",
              "label": "t:sections.image-with-text.settings.mobile_content_alignment.options__3.label"
            }
          ],
          "default": "left",
          "label": "t:sections.image-with-text.settings.mobile_content_alignment.label"
        },
        {
          "type": "header",
          "content": "t:sections.all.padding.section_padding_heading"
        },
        {
          "type": "range",
          "id": "padding_top",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_top",
          "default": 36
        },
        {
          "type": "range",
          "id": "padding_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_bottom",
          "default": 36
        }
      ],
      "blocks": [
        {
          "type": "heading",
          "name": "t:sections.image-with-text.blocks.heading.name",
          "limit": 1,
          "settings": [
            {
              "type": "text",
              "id": "heading",
              "default": "Image with text",
              "label": "t:sections.image-with-text.blocks.heading.settings.heading.label"
            },
            {
              "type": "select",
              "id": "heading_size",
              "options": [
                {
                  "value": "h2",
                  "label": "t:sections.all.heading_size.options__1.label"
                },
                {
                  "value": "h1",
                  "label": "t:sections.all.heading_size.options__2.label"
                },
                {
                  "value": "h0",
                  "label": "t:sections.all.heading_size.options__3.label"
                }
              ],
              "default": "h1",
              "label": "t:sections.all.heading_size.label"
            }
          ]
        },
        {
          "type": "caption",
          "name": "t:sections.image-with-text.blocks.caption.name",
          "limit": 1,
          "settings": [
            {
              "type": "text",
              "id": "caption",
              "default": "Add a tagline",
              "label": "t:sections.image-with-text.blocks.caption.settings.text.label"
            },
            {
              "type": "select",
              "id": "text_style",
              "options": [
                {
                  "value": "subtitle",
                  "label": "t:sections.image-with-text.blocks.caption.settings.text_style.options__1.label"
                },
                {
                  "value": "caption-with-letter-spacing",
                  "label": "t:sections.image-with-text.blocks.caption.settings.text_style.options__2.label"
                }
              ],
              "default": "caption-with-letter-spacing",
              "label": "t:sections.image-with-text.blocks.caption.settings.text_style.label"
            },
            {
              "type": "select",
              "id": "text_size",
              "options": [
                {
                  "value": "small",
                  "label": "t:sections.image-with-text.blocks.caption.settings.caption_size.options__1.label"
                },
                {
                  "value": "medium",
                  "label": "t:sections.image-with-text.blocks.caption.settings.caption_size.options__2.label"
                },
                {
                  "value": "large",
                  "label": "t:sections.image-with-text.blocks.caption.settings.caption_size.options__3.label"
                }
              ],
              "default": "medium",
              "label": "t:sections.image-with-text.blocks.caption.settings.caption_size.label"
            }
          ]
        },
        {
          "type": "text",
          "name": "t:sections.image-with-text.blocks.text.name",
          "limit": 1,
          "settings": [
            {
              "type": "richtext",
              "id": "text",
              "default": "<p>Pair text with an image to focus on your chosen product, collection, or blog post. Add details on availability, style, or even provide a review.</p>",
              "label": "t:sections.image-with-text.blocks.text.settings.text.label"
            },
            {
              "type": "select",
              "id": "text_style",
              "options": [
                {
                  "value": "body",
                  "label": "t:sections.image-with-text.blocks.text.settings.text_style.options__1.label"
                },
                {
                  "value": "subtitle",
                  "label": "t:sections.image-with-text.blocks.text.settings.text_style.options__2.label"
                }
              ],
              "default": "body",
              "label": "t:sections.image-with-text.blocks.text.settings.text_style.label"
            }
          ]
        },
        {
          "type": "button",
          "name": "t:sections.image-with-text.blocks.button.name",
          "limit": 1,
          "settings": [
            {
              "type": "text",
              "id": "button_label",
              "default": "Button label",
              "label": "t:sections.image-with-text.blocks.button.settings.button_label.label",
              "info": "t:sections.image-with-text.blocks.button.settings.button_label.info"
            },
            {
              "type": "url",
              "id": "button_link",
              "label": "t:sections.image-with-text.blocks.button.settings.button_link.label"
            }
          ]
        }
      ],
      "presets": [
        {
          "name": "t:sections.image-with-text.presets.name",
          "blocks": [
            {
              "type": "heading"
            },
            {
              "type": "text"
            },
            {
              "type": "button"
            }
          ]
        }
      ]
    },

    "main-article": {
      "name": "t:sections.main-article.name",
      "tag": "section",
      "class": "section",
      "blocks": [
        {
          "type": "@app"
        },
        {
          "type": "featured_image",
          "name": "t:sections.main-article.blocks.featured_image.name",
          "limit": 1,
          "settings": [
            {
              "type": "select",
              "id": "image_height",
              "options": [
                {
                  "value": "adapt",
                  "label": "t:sections.main-article.blocks.featured_image.settings.image_height.options__1.label"
                },
                {
                  "value": "small",
                  "label": "t:sections.main-article.blocks.featured_image.settings.image_height.options__2.label"
                },
                {
                  "value": "medium",
                  "label": "t:sections.main-article.blocks.featured_image.settings.image_height.options__3.label"
                },
                {
                  "value": "large",
                  "label": "t:sections.main-article.blocks.featured_image.settings.image_height.options__4.label"
                }
              ],
              "default": "adapt",
              "label": "t:sections.main-article.blocks.featured_image.settings.image_height.label",
              "info": "t:sections.main-article.blocks.featured_image.settings.image_height.info"
            }
          ]
        },
        {
          "type": "title",
          "name": "t:sections.main-article.blocks.title.name",
          "limit": 1,
          "settings": [
            {
              "type": "checkbox",
              "id": "blog_show_date",
              "default": true,
              "label": "t:sections.main-article.blocks.title.settings.blog_show_date.label"
            },
            {
              "type": "checkbox",
              "id": "blog_show_author",
              "default": false,
              "label": "t:sections.main-article.blocks.title.settings.blog_show_author.label"
            }
          ]
        },
        {
          "type": "content",
          "name": "t:sections.main-article.blocks.content.name",
          "limit": 1
        },
        {
          "type": "share",
          "name": "t:sections.main-article.blocks.share.name",
          "limit": 2,
          "settings": [
            {
              "type": "text",
              "id": "share_label",
              "label": "t:sections.main-article.blocks.share.settings.text.label",
              "default": "Share"
            },
            {
              "type": "paragraph",
              "content": "t:sections.main-article.blocks.share.settings.featured_image_info.content"
            },
            {
              "type": "paragraph",
              "content": "t:sections.main-article.blocks.share.settings.title_info.content"
            }
          ]
        }
      ],
      "settings": []
    },

    "main-blog": {
      "name": "t:sections.main-blog.name",
      "tag": "section",
      "class": "section",
      "settings": [
        {
          "type": "header",
          "content": "t:sections.main-blog.settings.header.content"
        },
        {
          "type": "select",
          "id": "layout",
          "options": [
            {
              "value": "grid",
              "label": "t:sections.main-blog.settings.layout.options__1.label"
            },
            {
              "value": "collage",
              "label": "t:sections.main-blog.settings.layout.options__2.label"
            }
          ],
          "default": "collage",
          "label": "t:sections.main-blog.settings.layout.label",
          "info": "t:sections.main-blog.settings.layout.info"
        },
        {
          "type": "checkbox",
          "id": "show_image",
          "default": true,
          "label": "t:sections.main-blog.settings.show_image.label"
        },
        {
          "type": "select",
          "id": "image_height",
          "options": [
            {
              "value": "adapt",
              "label": "t:sections.main-blog.settings.image_height.options__1.label"
            },
            {
              "value": "small",
              "label": "t:sections.main-blog.settings.image_height.options__2.label"
            },
            {
              "value": "medium",
              "label": "t:sections.main-blog.settings.image_height.options__3.label"
            },
            {
              "value": "large",
              "label": "t:sections.main-blog.settings.image_height.options__4.label"
            }
          ],
          "default": "medium",
          "label": "t:sections.main-blog.settings.image_height.label",
          "info": "t:sections.main-blog.settings.image_height.info"
        },
        {
          "type": "checkbox",
          "id": "show_date",
          "default": true,
          "label": "t:sections.main-blog.settings.show_date.label"
        },
        {
          "type": "checkbox",
          "id": "show_author",
          "default": false,
          "label": "t:sections.main-blog.settings.show_author.label"
        },
        {
          "type": "paragraph",
          "content": "t:sections.main-blog.settings.paragraph.content"
        },
        {
          "type": "header",
          "content": "t:sections.all.padding.section_padding_heading"
        },
        {
          "type": "range",
          "id": "padding_top",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_top",
          "default": 36
        },
        {
          "type": "range",
          "id": "padding_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_bottom",
          "default": 36
        }
      ]
    },

    "main-cart-footer": {
      "name": "t:sections.main-cart-footer.name",
      "class": "cart__footer-wrapper",
      "settings": [
        {
          "type": "checkbox",
          "id": "show_cart_note",
          "default": false,
          "label": "t:sections.main-cart-footer.settings.show_cart_note.label"
        }
      ],
      "blocks": [
        {
          "type": "subtotal",
          "name": "t:sections.main-cart-footer.blocks.subtotal.name",
          "limit": 1
        },
        {
          "type": "buttons",
          "name": "t:sections.main-cart-footer.blocks.buttons.name",
          "limit": 1
        },
        {
          "type": "@app"
        }
      ]
    },

    "main-cart-items": {
      "name": "t:sections.main-cart-items.name",
      "settings": [
        {
          "type": "checkbox",
          "id": "show_vendor",
          "default": false,
          "label": "t:sections.main-cart-items.settings.show_vendor.label"
        },
        {
          "type": "header",
          "content": "t:sections.all.padding.section_padding_heading"
        },
        {
          "type": "range",
          "id": "padding_top",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_top",
          "default": 36
        },
        {
          "type": "range",
          "id": "padding_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_bottom",
          "default": 36
        }
      ]
    },

    "main-collection-banner": {
      "name": "t:sections.main-collection-banner.name",
      "class": "section",
      "settings": [
        {
          "type": "paragraph",
          "content": "t:sections.main-collection-banner.settings.paragraph.content"
        },
        {
          "type": "checkbox",
          "id": "show_collection_description",
          "default": true,
          "label": "t:sections.main-collection-banner.settings.show_collection_description.label"
        },
        {
          "type": "checkbox",
          "id": "show_collection_image",
          "default": false,
          "label": "t:sections.main-collection-banner.settings.show_collection_image.label",
          "info": "t:sections.main-collection-banner.settings.show_collection_image.info"
        },
        {
          "type": "select",
          "id": "color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-1",
          "label": "t:sections.all.colors.label"
        }
      ]
    },

    "main-collection-product-grid": {
      "name": "t:sections.main-collection-product-grid.name",
      "class": "section",
      "settings": [
        {
          "type": "range",
          "id": "products_per_page",
          "min": 8,
          "max": 24,
          "step": 1,
          "default": 16,
          "label": "t:sections.main-collection-product-grid.settings.products_per_page.label"
        },
        {
          "type": "range",
          "id": "columns_desktop",
          "min": 1,
          "max": 5,
          "step": 1,
          "default": 4,
          "label": "t:sections.main-collection-product-grid.settings.columns_desktop.label"
        },
        {
          "type": "header",
          "content": "t:sections.main-collection-product-grid.settings.header__3.content"
        },
        {
          "type": "select",
          "id": "image_ratio",
          "options": [
            {
              "value": "adapt",
              "label": "t:sections.main-collection-product-grid.settings.image_ratio.options__1.label"
            },
            {
              "value": "portrait",
              "label": "t:sections.main-collection-product-grid.settings.image_ratio.options__2.label"
            },
            {
              "value": "square",
              "label": "t:sections.main-collection-product-grid.settings.image_ratio.options__3.label"
            }
          ],
          "default": "adapt",
          "label": "t:sections.main-collection-product-grid.settings.image_ratio.label"
        },
        {
          "type": "checkbox",
          "id": "show_secondary_image",
          "default": false,
          "label": "t:sections.main-collection-product-grid.settings.show_secondary_image.label"
        },
        {
          "type": "checkbox",
          "id": "show_vendor",
          "default": false,
          "label": "t:sections.main-collection-product-grid.settings.show_vendor.label"
        },
        // {
        //   "type": "checkbox",
        //   "id": "show_rating",
        //   "default": false,
        //   "label": "t:sections.main-collection-product-grid.settings.show_rating.label",
        //   "info": "t:sections.main-collection-product-grid.settings.show_rating.info"
        // },
        {
          "type": "checkbox",
          "id": "enable_quick_add",
          "default": false,
          "label": "t:sections.main-collection-product-grid.settings.enable_quick_buy.label"
        },
        // {
        //   "type": "header",
        //   "content": "t:sections.main-collection-product-grid.settings.header__1.content"
        // },
        // {
        //   "type": "checkbox",
        //   "id": "enable_filtering",
        //   "default": true,
        //   "label": "t:sections.main-collection-product-grid.settings.enable_filtering.label",
        //   "info": "t:sections.main-collection-product-grid.settings.enable_filtering.info"
        // },
        // {
        //   "type": "select",
        //   "id": "filter_type",
        //   "options": [
        //     {
        //       "value": "horizontal",
        //       "label": "t:sections.main-collection-product-grid.settings.filter_type.options__1.label"
        //     },
        //     {
        //       "value": "vertical",
        //       "label": "t:sections.main-collection-product-grid.settings.filter_type.options__2.label"
        //     },
        //     {
        //       "value": "drawer",
        //       "label": "t:sections.main-collection-product-grid.settings.filter_type.options__3.label"
        //     }
        //   ],
        //   "default": "horizontal",
        //   "label": "t:sections.main-collection-product-grid.settings.filter_type.label",
        //   "info": "t:sections.main-collection-product-grid.settings.filter_type.info"
        // },
        // {
        //   "type": "checkbox",
        //   "id": "enable_sorting",
        //   "default": true,
        //   "label": "t:sections.main-collection-product-grid.settings.enable_sorting.label"
        // },
        {
          "type": "header",
          "content": "t:sections.main-collection-product-grid.settings.header_mobile.content"
        },
        {
          "type": "select",
          "id": "columns_mobile",
          "default": "2",
          "label": "t:sections.main-collection-product-grid.settings.columns_mobile.label",
          "options": [
            {
              "value": "1",
              "label": "t:sections.main-collection-product-grid.settings.columns_mobile.options__1.label"
            },
            {
              "value": "2",
              "label": "t:sections.main-collection-product-grid.settings.columns_mobile.options__2.label"
            }
          ]
        },
        {
          "type": "header",
          "content": "t:sections.all.padding.section_padding_heading"
        },
        {
          "type": "range",
          "id": "padding_top",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_top",
          "default": 36
        },
        {
          "type": "range",
          "id": "padding_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_bottom",
          "default": 36
        }
      ]
    },

    "main-list-collections": {
      "name": "t:sections.main-list-collections.name",
      "class": "section",
      "settings": [
        {
          "type": "text",
          "id": "title",
          "label": "t:sections.main-list-collections.settings.title.label",
          "default": "Collections"
        },
        // {
        //   "type": "select",
        //   "id": "sort",
        //   "options": [
        //     {
        //       "value": "alphabetical",
        //       "label": "t:sections.main-list-collections.settings.sort.options__1.label"
        //     },
        //     {
        //       "value": "alphabetical_reversed",
        //       "label": "t:sections.main-list-collections.settings.sort.options__2.label"
        //     },
        //     {
        //       "value": "date_reversed",
        //       "label": "t:sections.main-list-collections.settings.sort.options__3.label"
        //     },
        //     {
        //       "value": "date",
        //       "label": "t:sections.main-list-collections.settings.sort.options__4.label"
        //     },
        //     {
        //       "value": "products_high",
        //       "label": "t:sections.main-list-collections.settings.sort.options__5.label"
        //     },
        //     {
        //       "value": "products_low",
        //       "label": "t:sections.main-list-collections.settings.sort.options__6.label"
        //     }
        //   ],
        //   "default": "alphabetical",
        //   "label": "t:sections.main-list-collections.settings.sort.label"
        // },
        {
          "type": "select",
          "id": "image_ratio",
          "options": [
            {
              "value": "adapt",
              "label": "t:sections.main-list-collections.settings.image_ratio.options__1.label"
            },
            {
              "value": "portrait",
              "label": "t:sections.main-list-collections.settings.image_ratio.options__2.label"
            },
            {
              "value": "square",
              "label": "t:sections.main-list-collections.settings.image_ratio.options__3.label"
            }
          ],
          "default": "adapt",
          "label": "t:sections.main-list-collections.settings.image_ratio.label",
          "info": "t:sections.main-list-collections.settings.image_ratio.info"
        },
        {
          "type": "range",
          "id": "columns_desktop",
          "min": 1,
          "max": 5,
          "step": 1,
          "default": 3,
          "label": "t:sections.main-list-collections.settings.columns_desktop.label"
        },
        {
          "type": "header",
          "content": "t:sections.main-list-collections.settings.header_mobile.content"
        },
        {
          "type": "select",
          "id": "columns_mobile",
          "options": [
            {
              "value": "1",
              "label": "t:sections.main-list-collections.settings.columns_mobile.options__1.label"
            },
            {
              "value": "2",
              "label": "t:sections.main-list-collections.settings.columns_mobile.options__2.label"
            }
          ],
          "default": "2",
          "label": "t:sections.main-list-collections.settings.columns_mobile.label"
        }
      ]
    },

    "main-page": {
      "name": "t:sections.main-page.name",
      "tag": "section",
      "class": "section",
      "settings": [
        {
          "type": "header",
          "content": "t:sections.all.padding.section_padding_heading"
        },
        {
          "type": "range",
          "id": "padding_top",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_top",
          "default": 36
        },
        {
          "type": "range",
          "id": "padding_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_bottom",
          "default": 36
        }
      ]
    },

    "main-password-footer": {
      "name": "t:sections.main-password-footer.name",
      "settings": [
        {
          "type": "select",
          "id": "color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-1",
          "label": "t:sections.all.colors.label"
        }
      ]
    },

    "main-password-header": {
      "name": "t:sections.main-password-header.name",
      "settings": [
        {
          "type": "image_picker",
          "id": "logo",
          "label": "t:sections.main-password-header.settings.logo.label"
        },
        {
          "type": "range",
          "id": "logo_max_width",
          "min": 50,
          "max": 250,
          "step": 10,
          "default": 100,
          "unit": "t:sections.main-password-header.settings.logo_max_width.unit",
          "label": "t:sections.main-password-header.settings.logo_max_width.label"
        },
        {
          "type": "select",
          "id": "color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-1",
          "label": "t:sections.all.colors.label"
        }
      ]
    },

    "main-product": {
      "name": "t:sections.main-product.name",
      "tag": "section",
      "class": "section",
      "blocks": [
        {
          "type": "@app"
        },
        {
          "type": "text",
          "name": "t:sections.main-product.blocks.text.name",
          "settings": [
            {
              "type": "text",
              "id": "text",
              "default": "Text block",
              "label": "t:sections.main-product.blocks.text.settings.text.label"
            },
            {
              "type": "select",
              "id": "text_style",
              "options": [
                {
                  "value": "body",
                  "label": "t:sections.main-product.blocks.text.settings.text_style.options__1.label"
                },
                {
                  "value": "subtitle",
                  "label": "t:sections.main-product.blocks.text.settings.text_style.options__2.label"
                },
                {
                  "value": "uppercase",
                  "label": "t:sections.main-product.blocks.text.settings.text_style.options__3.label"
                }
              ],
              "default": "body",
              "label": "t:sections.main-product.blocks.text.settings.text_style.label"
            }
          ]
        },
        {
          "type": "title",
          "name": "t:sections.main-product.blocks.title.name",
          "limit": 1
        },
        {
          "type": "price",
          "name": "t:sections.main-product.blocks.price.name",
          "limit": 1
        },
        {
          "type": "quantity_selector",
          "name": "t:sections.main-product.blocks.quantity_selector.name",
          "limit": 1
        },
        {
          "type": "variant_picker",
          "name": "t:sections.main-product.blocks.variant_picker.name",
          "limit": 1,
          "settings": [
            {
              "type": "select",
              "id": "picker_type",
              "options": [
                // {
                //   "value": "dropdown",
                //   "label": "t:sections.main-product.blocks.variant_picker.settings.picker_type.options__1.label"
                // },
                {
                  "value": "button",
                  "label": "t:sections.main-product.blocks.variant_picker.settings.picker_type.options__2.label"
                }
              ],
              "default": "button",
              "label": "t:sections.main-product.blocks.variant_picker.settings.picker_type.label"
            }
          ]
        },
        {
          "type": "buy_buttons",
          "name": "t:sections.main-product.blocks.buy_buttons.name",
          "limit": 1,
          "settings": [
            {
              "type": "checkbox",
              "id": "show_dynamic_checkout",
              "default": true,
              "label": "t:sections.main-product.blocks.buy_buttons.settings.show_dynamic_checkout.label",
              "info": "t:sections.main-product.blocks.buy_buttons.settings.show_dynamic_checkout.info"
            }
          ]
        },
        {
          "type": "description",
          "name": "t:sections.main-product.blocks.description.name",
          "limit": 1
        },
        {
          "type": "share",
          "name": "t:sections.main-product.blocks.share.name",
          "limit": 1,
          "settings": [
            {
              "type": "text",
              "id": "share_label",
              "label": "t:sections.main-product.blocks.share.settings.text.label",
              "default": "Share"
            },
            {
              "type": "paragraph",
              "content": "t:sections.main-product.blocks.share.settings.featured_image_info.content"
            },
            {
              "type": "paragraph",
              "content": "t:sections.main-product.blocks.share.settings.title_info.content"
            }
          ]
        },
        {
          "type": "custom_liquid",
          "name": "t:sections.main-product.blocks.custom_liquid.name",
          "settings": [
            {
              "type": "liquid",
              "id": "custom_liquid",
              "label": "t:sections.main-product.blocks.custom_liquid.settings.custom_liquid.label",
              "info": "t:sections.main-product.blocks.custom_liquid.settings.custom_liquid.info"
            }
          ]
        },
        {
          "type": "collapsible_tab",
          "name": "t:sections.main-product.blocks.collapsible_tab.name",
          "settings": [
            {
              "type": "text",
              "id": "heading",
              "default": "Collapsible row",
              "info": "t:sections.main-product.blocks.collapsible_tab.settings.heading.info",
              "label": "t:sections.main-product.blocks.collapsible_tab.settings.heading.label"
            },
            {
              "type": "select",
              "id": "icon",
              "options": [
                {
                  "value": "none",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__1.label"
                },
                {
                  "value": "apple",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__2.label"
                },
                {
                  "value": "banana",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__3.label"
                },
                {
                  "value": "bottle",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__4.label"
                },
                {
                  "value": "box",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__5.label"
                },
                {
                  "value": "carrot",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__6.label"
                },
                {
                  "value": "chat_bubble",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__7.label"
                },
                {
                  "value": "check_mark",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__8.label"
                },
                {
                  "value": "clipboard",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__9.label"
                },
                {
                  "value": "dairy",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__10.label"
                },
                {
                  "value": "dairy_free",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__11.label"
                },
                {
                  "value": "dryer",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__12.label"
                },
                {
                  "value": "eye",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__13.label"
                },
                {
                  "value": "fire",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__14.label"
                },
                {
                  "value": "gluten_free",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__15.label"
                },
                {
                  "value": "heart",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__16.label"
                },
                {
                  "value": "iron",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__17.label"
                },
                {
                  "value": "leaf",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__18.label"
                },
                {
                  "value": "leather",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__19.label"
                },
                {
                  "value": "lightning_bolt",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__20.label"
                },
                {
                  "value": "lipstick",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__21.label"
                },
                {
                  "value": "lock",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__22.label"
                },
                {
                  "value": "map_pin",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__23.label"
                },
                {
                  "value": "nut_free",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__24.label"
                },
                {
                  "value": "pants",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__25.label"
                },
                {
                  "value": "paw_print",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__26.label"
                },
                {
                  "value": "pepper",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__27.label"
                },
                {
                  "value": "perfume",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__28.label"
                },
                {
                  "value": "plane",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__29.label"
                },
                {
                  "value": "plant",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__30.label"
                },
                {
                  "value": "price_tag",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__31.label"
                },
                {
                  "value": "question_mark",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__32.label"
                },
                {
                  "value": "recycle",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__33.label"
                },
                {
                  "value": "return",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__34.label"
                },
                {
                  "value": "ruler",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__35.label"
                },
                {
                  "value": "serving_dish",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__36.label"
                },
                {
                  "value": "shirt",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__37.label"
                },
                {
                  "value": "shoe",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__38.label"
                },
                {
                  "value": "silhouette",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__39.label"
                },
                {
                  "value": "snowflake",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__40.label"
                },
                {
                  "value": "star",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__41.label"
                },
                {
                  "value": "stopwatch",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__42.label"
                },
                {
                  "value": "truck",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__43.label"
                },
                {
                  "value": "washing",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__44.label"
                }
              ],
              "default": "check_mark",
              "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.label"
            },
            {
              "type": "richtext",
              "id": "content",
              "label": "t:sections.main-product.blocks.collapsible_tab.settings.content.label"
            },
            {
              "type": "page",
              "id": "page",
              "label": "t:sections.main-product.blocks.collapsible_tab.settings.page.label"
            }
          ]
        },
        {
          "type": "popup",
          "name": "t:sections.main-product.blocks.popup.name",
          "settings": [
            {
              "type": "text",
              "id": "text",
              "default": "Pop-up link text",
              "label": "t:sections.main-product.blocks.popup.settings.link_label.label"
            },
            {
              "id": "page",
              "type": "page",
              "label": "t:sections.main-product.blocks.popup.settings.page.label"
            }
          ]
        },
        {
          "type": "rating",
          "name": "t:sections.main-product.blocks.rating.name",
          "limit": 1,
          "settings": [
            {
              "type": "paragraph",
              "content": "t:sections.main-product.blocks.rating.settings.paragraph.content"
            }
          ]
        }
      ],
      "settings": [
        {
          "type": "checkbox",
          "id": "enable_sticky_info",
          "default": true,
          "label": "t:sections.main-product.settings.enable_sticky_info.label"
        },
        {
          "type": "header",
          "content": "t:sections.main-product.settings.header.content",
          "info": "t:sections.main-product.settings.header.info"
        },
        {
          "type": "select",
          "id": "gallery_layout",
          "options": [
            {
              "value": "stacked",
              "label": "t:sections.main-product.settings.gallery_layout.options__1.label"
            },
            {
              "value": "thumbnail",
              "label": "t:sections.main-product.settings.gallery_layout.options__2.label"
            },
            {
              "value": "thumbnail_slider",
              "label": "t:sections.main-product.settings.gallery_layout.options__3.label"
            }
          ],
          "default": "stacked",
          "label": "t:sections.main-product.settings.gallery_layout.label"
        },
        {
          "type": "select",
          "id": "media_size",
          "options": [
            {
              "value": "small",
              "label": "t:sections.main-product.settings.media_size.options__1.label"
            },
            {
              "value": "medium",
              "label": "t:sections.main-product.settings.media_size.options__2.label"
            },
            {
              "value": "large",
              "label": "t:sections.main-product.settings.media_size.options__3.label"
            }
          ],
          "default": "large",
          "label": "t:sections.main-product.settings.media_size.label",
          "info": "t:sections.main-product.settings.media_size.info"
        },
        {
          "type": "select",
          "id": "mobile_thumbnails",
          "options": [
            {
              "value": "show",
              "label": "t:sections.main-product.settings.mobile_thumbnails.options__1.label"
            },
            {
              "value": "hide",
              "label": "t:sections.main-product.settings.mobile_thumbnails.options__2.label"
            }
          ],
          "default": "hide",
          "label": "t:sections.main-product.settings.mobile_thumbnails.label"
        },
        {
          "type": "checkbox",
          "id": "hide_variants",
          "default": false,
          "label": "t:sections.main-product.settings.hide_variants.label"
        },
        // {
        //   "type": "checkbox",
        //   "id": "enable_video_looping",
        //   "default": false,
        //   "label": "t:sections.main-product.settings.enable_video_looping.label"
        // },
        {
          "type": "header",
          "content": "t:sections.all.padding.section_padding_heading"
        },
        {
          "type": "range",
          "id": "padding_top",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_top",
          "default": 36
        },
        {
          "type": "range",
          "id": "padding_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_bottom",
          "default": 36
        }
      ],
      "presets": [
        {
          "name": "t:sections.main-product.presets.name",
          "blocks": [
            {
              "type": "text"
            },
            {
              "type": "collapsible-row"
            },
            {
              "type": "star-ratings"
            }
          ]
        }
      ]
    },

    "main-search": {
      "name": "t:sections.main-search.name",
      "tag": "section",
      "class": "section",
      "settings": [
        {
          "type": "range",
          "id": "columns_desktop",
          "min": 1,
          "max": 5,
          "step": 1,
          "default": 4,
          "label": "t:sections.main-search.settings.columns_desktop.label"
        },
        {
          "type": "header",
          "content": "t:sections.main-search.settings.header__1.content"
        },
        {
          "type": "select",
          "id": "image_ratio",
          "options": [
            {
              "value": "adapt",
              "label": "t:sections.main-search.settings.image_ratio.options__1.label"
            },
            {
              "value": "portrait",
              "label": "t:sections.main-search.settings.image_ratio.options__2.label"
            },
            {
              "value": "square",
              "label": "t:sections.main-search.settings.image_ratio.options__3.label"
            }
          ],
          "default": "adapt",
          "label": "t:sections.main-search.settings.image_ratio.label"
        },
        {
          "type": "checkbox",
          "id": "show_secondary_image",
          "default": false,
          "label": "t:sections.main-search.settings.show_secondary_image.label"
        },
        {
          "type": "checkbox",
          "id": "show_vendor",
          "default": false,
          "label": "t:sections.main-search.settings.show_vendor.label"
        },
        {
          "type": "checkbox",
          "id": "show_rating",
          "default": false,
          "label": "t:sections.main-search.settings.show_rating.label",
          "info": "t:sections.main-search.settings.show_rating.info"
        },
        // {
        //   "type": "header",
        //   "content": "t:sections.main-collection-product-grid.settings.header__1.content"
        // },
        // {
        //   "type": "checkbox",
        //   "id": "enable_filtering",
        //   "default": true,
        //   "label": "t:sections.main-collection-product-grid.settings.enable_filtering.label",
        //   "info": "t:sections.main-collection-product-grid.settings.enable_filtering.info"
        // },
        // {
        //   "type": "select",
        //   "id": "filter_type",
        //   "options": [
        //     {
        //       "value": "horizontal",
        //       "label": "t:sections.main-collection-product-grid.settings.filter_type.options__1.label"
        //     },
        //     {
        //       "value": "vertical",
        //       "label": "t:sections.main-collection-product-grid.settings.filter_type.options__2.label"
        //     },
        //     {
        //       "value": "drawer",
        //       "label": "t:sections.main-collection-product-grid.settings.filter_type.options__3.label"
        //     }
        //   ],
        //   "default": "horizontal",
        //   "label": "t:sections.main-collection-product-grid.settings.filter_type.label",
        //   "info": "t:sections.main-collection-product-grid.settings.filter_type.info"
        // },
        // {
        //   "type": "checkbox",
        //   "id": "enable_sorting",
        //   "default": true,
        //   "label": "t:sections.main-collection-product-grid.settings.enable_sorting.label"
        // },
        {
          "type": "header",
          "content": "t:sections.main-search.settings.header__2.content"
        },
        {
          "type": "checkbox",
          "id": "article_show_date",
          "default": true,
          "label": "t:sections.main-search.settings.article_show_date.label"
        },
        {
          "type": "checkbox",
          "id": "article_show_author",
          "default": false,
          "label": "t:sections.main-search.settings.article_show_author.label"
        },
        {
          "type": "header",
          "content": "t:sections.main-search.settings.header_mobile.content"
        },
        {
          "type": "select",
          "id": "columns_mobile",
          "default": "2",
          "label": "t:sections.main-search.settings.columns_mobile.label",
          "options": [
            {
              "value": "1",
              "label": "t:sections.main-search.settings.columns_mobile.options__1.label"
            },
            {
              "value": "2",
              "label": "t:sections.main-search.settings.columns_mobile.options__2.label"
            }
          ]
        },
        {
          "type": "header",
          "content": "t:sections.all.padding.section_padding_heading"
        },
        {
          "type": "range",
          "id": "padding_top",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_top",
          "default": 36
        },
        {
          "type": "range",
          "id": "padding_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_bottom",
          "default": 36
        }
      ]
    },

    "multicolumn": {
      "name": "t:sections.multicolumn.name",
      "class": "section",
      "tag": "section",
      "settings": [
        {
          "type": "text",
          "id": "title",
          "default": "Multicolumn",
          "label": "t:sections.multicolumn.settings.title.label"
        },
        {
          "type": "select",
          "id": "heading_size",
          "options": [
            {
              "value": "h2",
              "label": "t:sections.all.heading_size.options__1.label"
            },
            {
              "value": "h1",
              "label": "t:sections.all.heading_size.options__2.label"
            },
            {
              "value": "h0",
              "label": "t:sections.all.heading_size.options__3.label"
            }
          ],
          "default": "h1",
          "label": "t:sections.all.heading_size.label"
        },
        {
          "type": "select",
          "id": "image_width",
          "options": [
            {
              "value": "third",
              "label": "t:sections.multicolumn.settings.image_width.options__1.label"
            },
            {
              "value": "half",
              "label": "t:sections.multicolumn.settings.image_width.options__2.label"
            },
            {
              "value": "full",
              "label": "t:sections.multicolumn.settings.image_width.options__3.label"
            }
          ],
          "default": "full",
          "label": "t:sections.multicolumn.settings.image_width.label"
        },
        {
          "type": "select",
          "id": "image_ratio",
          "options": [
            {
              "value": "adapt",
              "label": "t:sections.multicolumn.settings.image_ratio.options__1.label"
            },
            {
              "value": "portrait",
              "label": "t:sections.multicolumn.settings.image_ratio.options__2.label"
            },
            {
              "value": "square",
              "label": "t:sections.multicolumn.settings.image_ratio.options__3.label"
            },
            {
              "value": "circle",
              "label": "t:sections.multicolumn.settings.image_ratio.options__4.label"
            }
          ],
          "default": "adapt",
          "label": "t:sections.multicolumn.settings.image_ratio.label"
        },
        {
          "type": "range",
          "id": "columns_desktop",
          "min": 1,
          "max": 6,
          "step": 1,
          "default": 3,
          "label": "t:sections.multicolumn.settings.columns_desktop.label"
        },
        {
          "type": "select",
          "id": "column_alignment",
          "options": [
            {
              "value": "left",
              "label": "t:sections.multicolumn.settings.column_alignment.options__1.label"
            },
            {
              "value": "center",
              "label": "t:sections.multicolumn.settings.column_alignment.options__2.label"
            }
          ],
          "default": "left",
          "label": "t:sections.multicolumn.settings.column_alignment.label"
        },
        {
          "type": "select",
          "id": "background_style",
          "options": [
            {
              "value": "none",
              "label": "t:sections.multicolumn.settings.background_style.options__1.label"
            },
            {
              "value": "primary",
              "label": "t:sections.multicolumn.settings.background_style.options__2.label"
            }
          ],
          "default": "primary",
          "label": "t:sections.multicolumn.settings.background_style.label"
        },
        {
          "type": "text",
          "id": "button_label",
          "default": "Button label",
          "label": "t:sections.multicolumn.settings.button_label.label"
        },
        {
          "type": "url",
          "id": "button_link",
          "label": "t:sections.multicolumn.settings.button_link.label"
        },
        {
          "type": "select",
          "id": "color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-1",
          "label": "t:sections.all.colors.label"
        },
        {
          "type": "header",
          "content": "t:sections.multicolumn.settings.header_mobile.content"
        },
        {
          "type": "select",
          "id": "columns_mobile",
          "options": [
            {
              "value": "1",
              "label": "t:sections.multicolumn.settings.columns_mobile.options__1.label"
            },
            {
              "value": "2",
              "label": "t:sections.multicolumn.settings.columns_mobile.options__2.label"
            }
          ],
          "default": "1",
          "label": "t:sections.multicolumn.settings.columns_mobile.label"
        },
        {
          "type": "checkbox",
          "id": "swipe_on_mobile",
          "default": false,
          "label": "t:sections.multicolumn.settings.swipe_on_mobile.label"
        },
        {
          "type": "header",
          "content": "t:sections.all.padding.section_padding_heading"
        },
        {
          "type": "range",
          "id": "padding_top",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_top",
          "default": 36
        },
        {
          "type": "range",
          "id": "padding_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_bottom",
          "default": 36
        }
      ],
      "blocks": [
        {
          "type": "column",
          "name": "t:sections.multicolumn.blocks.column.name",
          "settings": [
            {
              "type": "image_picker",
              "id": "image",
              "label": "t:sections.multicolumn.blocks.column.settings.image.label"
            },
            {
              "type": "text",
              "id": "title",
              "default": "Column",
              "label": "t:sections.multicolumn.blocks.column.settings.title.label"
            },
            {
              "type": "richtext",
              "id": "text",
              "default": "<p>Pair text with an image to focus on your chosen product, collection, or blog post. Add details on availability, style, or even provide a review.</p>",
              "label": "t:sections.multicolumn.blocks.column.settings.text.label"
            },
            {
              "type": "text",
              "id": "link_label",
              "label": "t:sections.multicolumn.blocks.column.settings.link_label.label"
            },
            {
              "type": "url",
              "id": "link",
              "label": "t:sections.multicolumn.blocks.column.settings.link.label"
            }
          ]
        }
      ],
      "presets": [
        {
          "name": "t:sections.multicolumn.presets.name",
          "blocks": [
            {
              "type": "column"
            },
          ]
        }
      ]
    },

    "newsletter": {
      "name": "t:sections.newsletter.name",
      "tag": "section",
      "class": "section",
      "settings": [
        {
          "type": "select",
          "id": "color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-1",
          "label": "t:sections.all.colors.label"
        },
        {
          "type": "checkbox",
          "id": "full_width",
          "default": true,
          "label": "t:sections.newsletter.settings.full_width.label"
        },
        {
          "type": "paragraph",
          "content": "t:sections.newsletter.settings.paragraph.content"
        },
        {
          "type": "header",
          "content": "t:sections.all.padding.section_padding_heading"
        },
        {
          "type": "range",
          "id": "padding_top",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_top",
          "default": 40
        },
        {
          "type": "range",
          "id": "padding_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_bottom",
          "default": 52
        }
      ],
      "blocks": [
        {
          "type": "heading",
          "name": "t:sections.newsletter.blocks.heading.name",
          "limit": 1,
          "settings": [
            {
              "type": "text",
              "id": "heading",
              "default": "Subscribe to our emails",
              "label": "t:sections.newsletter.blocks.heading.settings.heading.label"
            },
            {
              "type": "select",
              "id": "heading_size",
              "options": [
                {
                  "value": "h2",
                  "label": "t:sections.all.heading_size.options__1.label"
                },
                {
                  "value": "h1",
                  "label": "t:sections.all.heading_size.options__2.label"
                },
                {
                  "value": "h0",
                  "label": "t:sections.all.heading_size.options__3.label"
                }
              ],
              "default": "h1",
              "label": "t:sections.all.heading_size.label"
            }
          ]
        },
        {
          "type": "paragraph",
          "name": "t:sections.newsletter.blocks.paragraph.name",
          "limit": 1,
          "settings": [
            {
              "type": "richtext",
              "id": "text",
              "default": "<p>Be the first to know about new collections and exclusive offers.</p>",
              "label": "t:sections.newsletter.blocks.paragraph.settings.paragraph.label"
            }
          ]
        },
        {
          "type": "email_form",
          "name": "t:sections.newsletter.blocks.email_form.name",
          "limit": 1
        },
        {
          "type": "@app"
        }
      ],
      "presets": [
        {
          "name": "t:sections.newsletter.presets.name",
          "blocks": [
            {
              "type": "heading"
            },
            {
              "type": "paragraph"
            },
            {
              "type": "email_form"
            }
          ]
        }
      ]
    },

    "page": {
      "name": "t:sections.page.name",
      "tag": "section",
      "class": "section",
      "settings": [
        {
          "type": "page",
          "id": "page",
          "label": "t:sections.page.settings.page.label"
        },
        {
          "type": "select",
          "id": "heading_size",
          "options": [
            {
              "value": "h2",
              "label": "t:sections.all.heading_size.options__1.label"
            },
            {
              "value": "h1",
              "label": "t:sections.all.heading_size.options__2.label"
            },
            {
              "value": "h0",
              "label": "t:sections.all.heading_size.options__3.label"
            }
          ],
          "default": "h1",
          "label": "t:sections.all.heading_size.label"
        },
        {
          "type": "select",
          "id": "color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-1",
          "label": "t:sections.all.colors.label"
        },
        {
          "type": "header",
          "content": "t:sections.all.padding.section_padding_heading"
        },
        {
          "type": "range",
          "id": "padding_top",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_top",
          "default": 36
        },
        {
          "type": "range",
          "id": "padding_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_bottom",
          "default": 36
        }
      ],
      "presets": [
        {
          "name": "t:sections.page.presets.name"
        }
      ]
    },

    "product-recommendations": {
      "name": "t:sections.product-recommendations.name",
      "tag": "section",
      "class": "section",
      "settings": [
        {
          "type": "paragraph",
          "content": "t:sections.product-recommendations.settings.paragraph__1.content"
        },
        {
          "type": "text",
          "id": "heading",
          "default": "You may also like",
          "label": "t:sections.product-recommendations.settings.heading.label"
        },
        {
          "type": "select",
          "id": "heading_size",
          "options": [
            {
              "value": "h2",
              "label": "t:sections.all.heading_size.options__1.label"
            },
            {
              "value": "h1",
              "label": "t:sections.all.heading_size.options__2.label"
            },
            {
              "value": "h0",
              "label": "t:sections.all.heading_size.options__3.label"
            }
          ],
          "default": "h1",
          "label": "t:sections.all.heading_size.label"
        },
        {
          "type": "range",
          "id": "products_to_show",
          "min": 2,
          "max": 10,
          "step": 1,
          "default": 4,
          "label": "t:sections.product-recommendations.settings.products_to_show.label"
        },
        {
          "type": "range",
          "id": "columns_desktop",
          "min": 1,
          "max": 5,
          "step": 1,
          "default": 4,
          "label": "t:sections.product-recommendations.settings.columns_desktop.label"
        },
        {
          "type": "select",
          "id": "color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-1",
          "label": "t:sections.all.colors.label",
          "info": "t:sections.all.colors.has_cards_info"
        },
        {
          "type": "header",
          "content": "t:sections.product-recommendations.settings.header__2.content"
        },
        {
          "type": "select",
          "id": "image_ratio",
          "options": [
            {
              "value": "adapt",
              "label": "t:sections.product-recommendations.settings.image_ratio.options__1.label"
            },
            {
              "value": "portrait",
              "label": "t:sections.product-recommendations.settings.image_ratio.options__2.label"
            },
            {
              "value": "square",
              "label": "t:sections.product-recommendations.settings.image_ratio.options__3.label"
            }
          ],
          "default": "adapt",
          "label": "t:sections.product-recommendations.settings.image_ratio.label"
        },
        {
          "type": "checkbox",
          "id": "show_secondary_image",
          "default": false,
          "label": "t:sections.product-recommendations.settings.show_secondary_image.label"
        },
        {
          "type": "checkbox",
          "id": "show_vendor",
          "default": false,
          "label": "t:sections.product-recommendations.settings.show_vendor.label"
        },
        {
          "type": "checkbox",
          "id": "show_rating",
          "default": false,
          "label": "t:sections.product-recommendations.settings.show_rating.label",
          "info": "t:sections.product-recommendations.settings.show_rating.info"
        },
        {
          "type": "header",
          "content": "t:sections.product-recommendations.settings.header_mobile.content"
        },
        {
          "type": "select",
          "id": "columns_mobile",
          "default": "2",
          "label": "t:sections.product-recommendations.settings.columns_mobile.label",
          "options": [
            {
              "value": "1",
              "label": "t:sections.product-recommendations.settings.columns_mobile.options__1.label"
            },
            {
              "value": "2",
              "label": "t:sections.product-recommendations.settings.columns_mobile.options__2.label"
            }
          ]
        },
        {
          "type": "header",
          "content": "t:sections.all.padding.section_padding_heading"
        },
        {
          "type": "range",
          "id": "padding_top",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_top",
          "default": 36
        },
        {
          "type": "range",
          "id": "padding_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_bottom",
          "default": 36
        }
      ]
    },

    "reviews-importer-product": {
      "name": "LAI product reviews",
      "class": "index-section",
      "settings": [
        {
          "type": "product",
          "id": "lai_product",
          "label": "Choose Product"
        }
      ],
      "presets": [
        {
          "name": "LAI product reviews",
          "category": "LAI Reviews"
        }
      ]
    },

    "review-importer -section": {
      "name": "LAI homepage reviews",
      "class": "index-section",
      "settings": [
        {
          "type": "text",
          "id": "title",
          "label": "Lai title home page reviews"
        },
        {
          "type": "text",
          "id": "class_title",
          "label": "Class to style the title tag",
          "info": "Example: title for theme Dawn"
        },
        {
          "type": "text",
          "id": "class_wrap_title",
          "label": "Class to style the wrap title tag",
          "info": "Example: page-width for theme Dawn"
        }
      ],
      "presets": [
        {
          "name": "LAI homepage reviews",
          "category": "LAI Reviews"
        }
      ]
    },

    "reviews-importer-section-happyPage": {
      "name": "LAI happy page reviews",
      "class": "index-section",
      "settings": [],
      "presets": [
        {
          "name": "LAI happy page reviews",
          "category": "LAI Reviews"
        }
      ]
    },

    "rich-text": {
      "name": "t:sections.rich-text.name",
      "tag": "section",
      "class": "section",
      "settings": [
        {
          "type": "select",
          "id": "desktop_content_position",
          "options": [
            {
              "value": "left",
              "label": "t:sections.rich-text.settings.desktop_content_position.options__1.label"
            },
            {
              "value": "center",
              "label": "t:sections.rich-text.settings.desktop_content_position.options__2.label"
            },
            {
              "value": "right",
              "label": "t:sections.rich-text.settings.desktop_content_position.options__3.label"
            }
          ],
          "default": "center",
          "label": "t:sections.rich-text.settings.desktop_content_position.label",
          "info": "t:sections.rich-text.settings.desktop_content_position.info"
        },
        {
          "type": "select",
          "id": "content_alignment",
          "options": [
            {
              "value": "left",
              "label": "t:sections.rich-text.settings.content_alignment.options__1.label"
            },
            {
              "value": "center",
              "label": "t:sections.rich-text.settings.content_alignment.options__2.label"
            },
            {
              "value": "right",
              "label": "t:sections.rich-text.settings.content_alignment.options__3.label"
            }
          ],
          "default": "center",
          "label": "t:sections.rich-text.settings.content_alignment.label"
        },
        {
          "type": "select",
          "id": "color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-1",
          "label": "t:sections.all.colors.label"
        },
        {
          "type": "checkbox",
          "id": "full_width",
          "default": true,
          "label": "t:sections.rich-text.settings.full_width.label"
        },
        {
          "type": "header",
          "content": "t:sections.all.padding.section_padding_heading"
        },
        {
          "type": "range",
          "id": "padding_top",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_top",
          "default": 40
        },
        {
          "type": "range",
          "id": "padding_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_bottom",
          "default": 52
        }
      ],
      "blocks": [
        {
          "type": "heading",
          "name": "t:sections.rich-text.blocks.heading.name",
          "limit": 3,
          "settings": [
            {
              "type": "richtext",
              "id": "heading",
              "default": "<p>Talk about your brand</p>",
              "label": "t:sections.rich-text.blocks.heading.settings.heading.label"
            },
            {
              "type": "select",
              "id": "heading_size",
              "options": [
                {
                  "value": "h2",
                  "label": "t:sections.all.heading_size.options__1.label"
                },
                {
                  "value": "h1",
                  "label": "t:sections.all.heading_size.options__2.label"
                },
                {
                  "value": "h0",
                  "label": "t:sections.all.heading_size.options__3.label"
                },
                {
                  "value": "hxl",
                  "label": "t:sections.all.heading_size.options__4.label"
                }
              ],
              "default": "h1",
              "label": "t:sections.all.heading_size.label"
            }
          ]
        },
        {
          "type": "caption",
          "name": "t:sections.rich-text.blocks.caption.name",
          "limit": 3,
          "settings": [
            {
              "type": "text",
              "id": "caption",
              "default": "Add a tagline",
              "label": "t:sections.rich-text.blocks.caption.settings.text.label"
            },
            {
              "type": "select",
              "id": "text_style",
              "options": [
                {
                  "value": "subtitle",
                  "label": "t:sections.rich-text.blocks.caption.settings.text_style.options__1.label"
                },
                {
                  "value": "caption-with-letter-spacing",
                  "label": "t:sections.rich-text.blocks.caption.settings.text_style.options__2.label"
                }
              ],
              "default": "caption-with-letter-spacing",
              "label": "t:sections.rich-text.blocks.caption.settings.text_style.label"
            },
            {
              "type": "select",
              "id": "text_size",
              "options": [
                {
                  "value": "small",
                  "label": "t:sections.rich-text.blocks.caption.settings.caption_size.options__1.label"
                },
                {
                  "value": "medium",
                  "label": "t:sections.rich-text.blocks.caption.settings.caption_size.options__2.label"
                },
                {
                  "value": "large",
                  "label": "t:sections.rich-text.blocks.caption.settings.caption_size.options__3.label"
                }
              ],
              "default": "medium",
              "label": "t:sections.rich-text.blocks.caption.settings.caption_size.label"
            }
          ]
        },
        {
          "type": "text",
          "name": "t:sections.rich-text.blocks.text.name",
          "limit": 3,
          "settings": [
            {
              "type": "richtext",
              "id": "text",
              "default": "<p>Share information about your brand with your customers. Describe a product, make announcements, or welcome customers to your store.</p>",
              "label": "t:sections.rich-text.blocks.text.settings.text.label"
            }
          ]
        },
        {
          "type": "button",
          "name": "t:sections.rich-text.blocks.buttons.name",
          "limit": 2,
          "settings": [
            {
              "type": "text",
              "id": "button_label",
              "default": "Button label",
              "label": "t:sections.rich-text.blocks.buttons.settings.button_label_1.label",
              "info": "t:sections.rich-text.blocks.buttons.settings.button_label_1.info"
            },
            {
              "type": "url",
              "id": "button_link",
              "label": "t:sections.rich-text.blocks.buttons.settings.button_link_1.label"
            },
            {
              "type": "checkbox",
              "id": "button_style_secondary",
              "default": false,
              "label": "t:sections.rich-text.blocks.buttons.settings.button_style_secondary_1.label"
            },
            {
              "type": "text",
              "id": "button_label_2",
              "label": "t:sections.rich-text.blocks.buttons.settings.button_label_2.label",
              "info": "t:sections.rich-text.blocks.buttons.settings.button_label_2.info"
            },
            {
              "type": "url",
              "id": "button_link_2",
              "label": "t:sections.rich-text.blocks.buttons.settings.button_link_2.label"
            },
            {
              "type": "checkbox",
              "id": "button_style_secondary_2",
              "default": false,
              "label": "t:sections.rich-text.blocks.buttons.settings.button_style_secondary_2.label"
            }
          ]
        }
      ],
      "presets": [
        {
          "name": "t:sections.rich-text.presets.name",
          "blocks": [
            {
              "type": "heading"
            },
            {
              "type": "text"
            },
            {
              "type": "button"
            }
          ]
        }
      ]
    },

    "slideshow": {
      "name": "t:sections.slideshow.name",
      "tag": "section",
      "class": "section",
      "settings": [
        {
          "type": "select",
          "id": "layout",
          "options": [
            {
              "value": "full_bleed",
              "label": "t:sections.slideshow.settings.layout.options__1.label"
            },
            {
              "value": "grid",
              "label": "t:sections.slideshow.settings.layout.options__2.label"
            }
          ],
          "default": "full_bleed",
          "label": "t:sections.slideshow.settings.layout.label"
        },
        {
          "type": "select",
          "id": "slide_height",
          "options": [
            {
              "value": "adapt_image",
              "label": "t:sections.slideshow.settings.slide_height.options__1.label"
            },
            {
              "value": "small",
              "label": "t:sections.slideshow.settings.slide_height.options__2.label"
            },
            {
              "value": "medium",
              "label": "t:sections.slideshow.settings.slide_height.options__3.label"
            },
            {
              "value": "large",
              "label": "t:sections.slideshow.settings.slide_height.options__4.label"
            }
          ],
          "default": "adapt_image",
          "label": "t:sections.slideshow.settings.slide_height.label"
        },
        {
          "type": "select",
          "id": "slider_visual",
          "options": [
            {
              "value": "dots",
              "label": "t:sections.slideshow.settings.slider_visual.options__2.label"
            },
            {
              "value": "counter",
              "label": "t:sections.slideshow.settings.slider_visual.options__1.label"
            },
            {
              "value": "numbers",
              "label": "t:sections.slideshow.settings.slider_visual.options__3.label"
            }
          ],
          "default": "counter",
          "label": "t:sections.slideshow.settings.slider_visual.label"
        },
        {
          "type": "checkbox",
          "id": "auto_rotate",
          "label": "t:sections.slideshow.settings.auto_rotate.label",
          "default": false
        },
        {
          "type": "range",
          "id": "change_slides_speed",
          "min": 3,
          "max": 9,
          "step": 2,
          "unit": "s",
          "label": "t:sections.slideshow.settings.change_slides_speed.label",
          "default": 5
        },
        {
          "type": "header",
          "content": "t:sections.slideshow.settings.mobile.content"
        },
        {
          "type": "checkbox",
          "id": "show_text_below",
          "label": "t:sections.slideshow.settings.show_text_below.label",
          "default": true
        },
        {
          "type": "header",
          "content": "t:sections.slideshow.settings.accessibility.content"
        },
        {
          "type": "text",
          "id": "accessibility_info",
          "label": "t:sections.slideshow.settings.accessibility.label",
          "info": "t:sections.slideshow.settings.accessibility.info",
          "default": "Slideshow about our brand"
        }
      ],
      "blocks": [
        {
          "type": "slide",
          "name": "t:sections.slideshow.blocks.slide.name",
          "limit": 5,
          "settings": [
            {
              "type": "image_picker",
              "id": "image",
              "label": "t:sections.slideshow.blocks.slide.settings.image.label"
            },
            {
              "type": "text",
              "id": "heading",
              "default": "Image slide",
              "label": "t:sections.slideshow.blocks.slide.settings.heading.label"
            },
            {
              "type": "select",
              "id": "heading_size",
              "options": [
                {
                  "value": "h2",
                  "label": "t:sections.all.heading_size.options__1.label"
                },
                {
                  "value": "h1",
                  "label": "t:sections.all.heading_size.options__2.label"
                },
                {
                  "value": "h0",
                  "label": "t:sections.all.heading_size.options__3.label"
                }
              ],
              "default": "h1",
              "label": "t:sections.all.heading_size.label"
            },
            {
              "type": "text",
              "id": "subheading",
              "default": "Tell your brand's story through images",
              "label": "t:sections.slideshow.blocks.slide.settings.subheading.label"
            },
            {
              "type": "text",
              "id": "button_label",
              "default": "Button label",
              "label": "t:sections.slideshow.blocks.slide.settings.button_label.label",
              "info": "t:sections.slideshow.blocks.slide.settings.button_label.info"
            },
            {
              "type": "url",
              "id": "link",
              "label": "t:sections.slideshow.blocks.slide.settings.link.label"
            },
            {
              "type": "checkbox",
              "id": "button_style_secondary",
              "label": "t:sections.slideshow.blocks.slide.settings.secondary_style.label",
              "default": false
            },
            {
              "type": "select",
              "id": "box_align",
              "options": [
                {
                  "value": "top-left",
                  "label": "t:sections.slideshow.blocks.slide.settings.box_align.options__1.label"
                },
                {
                  "value": "top-center",
                  "label": "t:sections.slideshow.blocks.slide.settings.box_align.options__2.label"
                },
                {
                  "value": "top-right",
                  "label": "t:sections.slideshow.blocks.slide.settings.box_align.options__3.label"
                },
                {
                  "value": "middle-left",
                  "label": "t:sections.slideshow.blocks.slide.settings.box_align.options__4.label"
                },
                {
                  "value": "middle-center",
                  "label": "t:sections.slideshow.blocks.slide.settings.box_align.options__5.label"
                },
                {
                  "value": "middle-right",
                  "label": "t:sections.slideshow.blocks.slide.settings.box_align.options__6.label"
                },
                {
                  "value": "bottom-left",
                  "label": "t:sections.slideshow.blocks.slide.settings.box_align.options__7.label"
                },
                {
                  "value": "bottom-center",
                  "label": "t:sections.slideshow.blocks.slide.settings.box_align.options__8.label"
                },
                {
                  "value": "bottom-right",
                  "label": "t:sections.slideshow.blocks.slide.settings.box_align.options__9.label"
                }
              ],
              "default": "middle-center",
              "label": "t:sections.slideshow.blocks.slide.settings.box_align.label",
              "info": "t:sections.slideshow.blocks.slide.settings.box_align.info"
            },
            {
              "type": "checkbox",
              "id": "show_text_box",
              "label": "t:sections.slideshow.blocks.slide.settings.show_text_box.label",
              "default": true
            },
            {
              "type": "select",
              "id": "text_alignment",
              "options": [
                {
                  "value": "left",
                  "label": "t:sections.slideshow.blocks.slide.settings.text_alignment.option_1.label"
                },
                {
                  "value": "center",
                  "label": "t:sections.slideshow.blocks.slide.settings.text_alignment.option_2.label"
                },
                {
                  "value": "right",
                  "label": "t:sections.slideshow.blocks.slide.settings.text_alignment.option_3.label"
                }
              ],
              "default": "center",
              "label": "t:sections.slideshow.blocks.slide.settings.text_alignment.label"
            },
            {
              "type": "range",
              "id": "image_overlay_opacity",
              "min": 0,
              "max": 100,
              "step": 10,
              "unit": "%",
              "label": "t:sections.slideshow.blocks.slide.settings.image_overlay_opacity.label",
              "default": 0
            },
            {
              "type": "select",
              "id": "color_scheme",
              "options": [
                {
                  "value": "accent-1",
                  "label": "t:sections.all.colors.accent_1.label"
                },
                {
                  "value": "accent-2",
                  "label": "t:sections.all.colors.accent_2.label"
                },
                {
                  "value": "background-1",
                  "label": "t:sections.all.colors.background_1.label"
                },
                {
                  "value": "background-2",
                  "label": "t:sections.all.colors.background_2.label"
                },
                {
                  "value": "inverse",
                  "label": "t:sections.all.colors.inverse.label"
                }
              ],
              "default": "background-1",
              "label": "t:sections.all.colors.label",
              "info": "t:sections.slideshow.blocks.slide.settings.color_scheme.info"
            },
            {
              "type": "header",
              "content": "t:sections.slideshow.settings.mobile.content"
            },
            {
              "type": "select",
              "id": "text_alignment_mobile",
              "options": [
                {
                  "value": "left",
                  "label": "t:sections.slideshow.blocks.slide.settings.text_alignment_mobile.options__1.label"
                },
                {
                  "value": "center",
                  "label": "t:sections.slideshow.blocks.slide.settings.text_alignment_mobile.options__2.label"
                },
                {
                  "value": "right",
                  "label": "t:sections.slideshow.blocks.slide.settings.text_alignment_mobile.options__3.label"
                }
              ],
              "default": "center",
              "label": "t:sections.slideshow.blocks.slide.settings.text_alignment_mobile.label"
            }
          ]
        }
      ],
      "presets": [
        {
          "name": "t:sections.slideshow.presets.name",
          "blocks": [
            {
              "type": "slide"
            }
          ]
        }
      ]
    },

    "video": {
      "name": "t:sections.video.name",
      "tag": "section",
      "class": "section",
      "settings": [
        {
          "type": "text",
          "id": "heading",
          "default": "Video",
          "label": "t:sections.video.settings.heading.label"
        },
        {
          "type": "select",
          "id": "heading_size",
          "options": [
            {
              "value": "h2",
              "label": "t:sections.all.heading_size.options__1.label"
            },
            {
              "value": "h1",
              "label": "t:sections.all.heading_size.options__2.label"
            },
            {
              "value": "h0",
              "label": "t:sections.all.heading_size.options__3.label"
            }
          ],
          "default": "h1",
          "label": "t:sections.all.heading_size.label"
        },
        {
          "type": "image_picker",
          "id": "cover_image",
          "label": "t:sections.video.settings.cover_image.label"
        },
        {
          "type": "video_url",
          "id": "video_url",
          "accept": [
            "youtube",
            "vimeo"
          ],
          "default": "https://www.youtube.com/watch?v=_9VUPq3SxOc",
          "label": "t:sections.video.settings.video_url.label",
          "placeholder": "t:sections.video.settings.video_url.placeholder",
          "info": "t:sections.video.settings.video_url.info"
        },
        {
          "type": "text",
          "id": "description",
          "label": "t:sections.video.settings.description.label",
          "info": "t:sections.video.settings.description.info"
        },
        {
          "type": "checkbox",
          "id": "full_width",
          "label": "t:sections.video.settings.full_width.label",
          "default": false
        },
        {
          "type": "select",
          "id": "color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-1",
          "label": "t:sections.all.colors.label"
        },
        {
          "type": "header",
          "content": "t:sections.all.padding.section_padding_heading"
        },
        {
          "type": "range",
          "id": "padding_top",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_top",
          "default": 36
        },
        {
          "type": "range",
          "id": "padding_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_bottom",
          "default": 36
        }
      ],
      "presets": [
        {
          "name": "t:sections.video.presets.name"
        }
      ]
    },

    "integration-reviews-lai-ali-express-io": {
      "name": "LAI homepage reviews",
      "settings": [
        {
          "type": "checkbox",
          "id": "enabled",
          "label": "Enabled LAI Review"
        },

      ],
    },

    "integration-reviews-stamped-io": {
      "name": "LAI homepage reviews",
      "settings": [
        {
          "type": "checkbox",
          "id": "enabled",
          "label": "Enabled StampedIO Review"
        },

      ],
    },

    "integration-reviews-areviews-aliexpress": {
      "name": "LAI homepage reviews",
      "settings": [
        {
          "type": "checkbox",
          "id": "enabled",
          "label": "Enable Areviews ‑ Reviews"
        },

      ],
    },

    "integration-reviews-bazaarvoice": {
      "name": "Bazaarvoice - Reviews",
      "settings": [
        {
          "type": "checkbox",
          "id": "enabled",
          "label": "Enable Bazaarvoice"
        },
        {
          "type": "text",
          "id": "shop_id",
          "label": "Bazaarvoice Shop ID"
        },

      ]
    },

    "integration-reviews-judge-me": {
      "name": "Judge me reviews",
      "settings": [
        {
          "type": "checkbox",
          "id": "enabled",
          "label": "Enable Judge.me Reviews"
        },

      ],
    },

    "integration-reviews-loox": {
      "name": "Judge me reviews",
      "settings": [
        {
          "type": "checkbox",
          "id": "enabled",
          "label": "Enable Loox Reviews"
        },

      ],
    },

    "integration-reviews-okendo": {
      "name": "Judge me reviews",
      "settings": [
        {
          "type": "checkbox",
          "id": "enabled",
          "label": "Enable Okendo Reviews"
        },

      ],
    },

    "integration-reviews-opinew": {
      "name": "Judge me reviews",
      "settings": [
        {
          "type": "checkbox",
          "id": "enabled",
          "label": "Enable Opinew Product Reviews"
        },

      ],
    },

    "integration-reviews-ryviu": {
      "name": "Judge me reviews",
      "settings": [
        {
          "type": "checkbox",
          "id": "enabled",
          "label": "Enable Ryviu Reviews"
        },

      ],
    },

    "integration-reviews-shopify-product-reviews": {
      "name": "Judge me reviews",
      "settings": [
        {
          "type": "checkbox",
          "id": "enabled",
          "label": "Enable Shopify Product Reviews"
        },

      ],
    },

    "integration-reviews-shopperapproved": {
      "name": "Judge me reviews",
      "settings": [
        {
          "type": "checkbox",
          "id": "enabled",
          "label": "Enable Shopper Approved Reviews"
        },

      ],
    },

    "integration-reviews-review-io": {
      "name": "Bazaarvoice - Reviews",
      "settings": [
        {
          "type": "checkbox",
          "id": "enabled",
          "label": "Enable ReviewIO Reviews"
        },
        {
          "type": "text",
          "id": "shop_id",
          "label": "ReviewIO Shop ID"
        },

      ]
    },

    "integration-reviews-yotpo": {
      "name": "Bazaarvoice - Reviews",
      "settings": [
        {
          "type": "checkbox",
          "id": "enabled",
          "label": "Enable Yotpo Reviews"
        },
        {
          "type": "text",
          "id": "api_key",
          "label": "Yotpo API Key"
        },

      ]
    },

    "integration-reviews-ssw": {
      "name": "Judge me reviews",
      "settings": [
        {
          "type": "checkbox",
          "id": "enabled",
          "label": "Enable Growave Reviews"
        },

      ],
    },

    "integration-reviews-helpfulcrowd": {
      "name": "Bazaarvoice - Reviews",
      "settings": [
        {
          "type": "checkbox",
          "id": "enabled",
          "label": "Enable Helpful Crowd Reviews"
        },
        {
          "type": "text",
          "id": "client_id",
          "label": "Helpful Crowd Client ID"
        },

      ]
    },

    "integration-reviews-alireviews": {
      "name": "Judge me reviews",
      "settings": [
        {
          "type": "checkbox",
          "id": "enabled",
          "label": "Enable Ali Express"
        },

      ],
    },
    "divider" :{
    "name": "Divider",
    "class": "shopify-section--divider",
    "settings": [
      {
        "type": "paragraph",
        "content": "Add spacing in between your sections by using a divider."
      },
      {
        "type": "range",
        "id": "divider_spacing",
        "min": 0,
        "max": 30,
        "step": 5,
        "unit": "px",
        "label": "Space",
        "default": 20
      },
      {
        "type": "checkbox",
        "id": "divider_border",
        "label": "Show border",
        "default": false
      },
      {
        "type": "header",
        "content": "Advanced"
      },
      {
        "type": "paragraph",
        "content": "[Learn more](https://help.outofthesandbox.com/hc/en-us/articles/360022329373)"
      },
      {
        "type": "text",
        "id": "css_class",
        "label": "CSS Class"
      },
      {
        "type": "textarea",
        "id": "custom_css",
        "label": "Custom CSS"
      }
    ],
    "presets": [
      {
        "name": "Divider",
        "category": "Layout"
      }
    ]
    },
    "integration-reviews-proviews-io": {
      "name": "Proviews",
      "settings": [
        {
          "type": "checkbox",
          "id": "enabled",
          "label": "Enable Proviews"
        },

      ],
    },
    "multiple-images-with-text": {
      "name": "Multiple images with text",
      "class": "multiple-images-with-text",
      "tag": "section",
      "disabled_on": {
        "templates": ["password"],
        "groups": ["header", "custom.overlay"]
      },
      "max_blocks": 6,
      "settings": [
        {
          "type": "checkbox",
          "id": "full_width",
          "label": "Full width",
          "default": true
        },
        {
          "type": "select",
          "id": "images_layout",
          "label": "Images layout",
          "options": [
            {
              "value": "stacked",
              "label": "Stacked"
            },
            {
              "value": "collage",
              "label": "Collage"
            },
            {
              "value": "scattered",
              "label": "Scattered"
            }
          ],
          "default": "stacked"
        },
        {
          "type": "select",
          "id": "collage_images_alignment",
          "label": "Collage images alignment",
          "options": [
            {
              "value": "top",
              "label": "Top"
            },
            {
              "value": "middle",
              "label": "Middle"
            },
            {
              "value": "shifted",
              "label": "Shifted"
            }
          ],
          "default": "middle"
        },
        {
          "type": "header",
          "content": "Colors",
          "info": "Gradient replaces solid colors when set."
        },
        {
          "type": "color",
          "id": "background",
          "label": "Background"
        },
        {
          "type": "color_background",
          "id": "background_gradient",
          "label": "Background gradient"
        },
        {
          "type": "color",
          "id": "text_color",
          "label": "Text"
        },
        {
          "type": "color",
          "id": "button_background",
          "label": "Button background"
        },
        {
          "type": "color",
          "id": "button_text_color",
          "label": "Button text"
        }
      ],
      "blocks": [
        {
          "type": "item",
          "name": "Item",
          "settings": [
            {
              "type": "image_picker",
              "id": "image",
              "label": "Image",
              "info": "1000 x 1200px .jpg recommended"
            },
            {
              "type": "range",
              "id": "image_rotation_degrees",
              "min": -15,
              "max": 15,
              "step": 0.5,
              "unit": "deg",
              "label": "Image rotation",
              "info": "Only applicable when images layout is set to \"Scattered\".",
              "default": 0
            },
            {
              "type": "text",
              "id": "subheading",
              "label": "Subheading"
            },
            {
              "type": "text",
              "id": "title",
              "label": "Heading",
              "default": "Image with text"
            },
            {
              "type": "html",
              "id": "content",
              "label": "Content",
              "default": "<p>Use this text to share information about your brand with your customers. Describe a product, share announcements, or welcome customers to your store.</p>"
            },
            {
              "type": "url",
              "id": "button_url",
              "label": "Button URL"
            },
            {
              "type": "text",
              "id": "button_text",
              "label": "Button text"
            }
          ]
        }
      ],
      "presets": [
        {
          "name": "Multiple images with text",
          "blocks": [
            {
              "type": "item",
              "settings": {
                "title": "Heading 1",
                "image_rotation_degrees": -2
              }
            },
            {
              "type": "item",
              "settings": {
                "title": "Heading 2",
                "image_rotation_degrees": 1.5
              }
            }
          ]
        }
      ]
    },
    "impact-text":{
      "name": "Impact text",
      "class": "impact-text",
      "tag": "section",
      "disabled_on": {
        "templates": ["password"],
        "groups": ["header", "custom.overlay"]
      },
      "max_blocks": 3,
      "blocks": [
        {
          "type": "item",
          "name": "Item",
          "settings": [
            {
              "type": "text",
              "id": "title",
              "label": "Impact text",
              "info": "For best results, keep this text short and impactful.",
              "default": "100%"
            },
            {
              "type": "text",
              "id": "subheading",
              "label": "Subheading",
              "default": "Showcase a benefit of your product"
            },
            {
              "type": "richtext",
              "id": "content",
              "label": "Content"
            },
            {
              "type": "text",
              "id": "button_text",
              "label": "Button text"
            },
            {
              "type": "url",
              "id": "button_url",
              "label": "Button link"
            },
            {
              "type": "header",
              "content": "Number animation"
            },
            {
              "type": "paragraph",
              "content": "Only number can be animated. Dots, commas and spaces can be used as separators."
            },
            {
              "type": "checkbox",
              "id": "animate_impact_text",
              "label": "Show count up animation",
              "default": false
            },
            {
              "type": "range",
              "id": "animate_impact_text_duration",
              "label": "Count up duration",
              "min": 0.5,
              "max": 5,
              "step": 0.1,
              "unit": "s",
              "default": 2
            }
          ]
        }
      ],
      "settings": [
        {
          "type": "checkbox",
          "id": "full_width",
          "label": "Full width",
          "default": true
        },
        {
          "type": "checkbox",
          "id": "stack_mobile",
          "label": "Stack on mobile",
          "default": true
        },
        {
          "type": "select",
          "id": "text_alignment",
          "label": "Text alignment",
          "options": [
            {
              "value": "start",
              "label": "Left"
            },
            {
              "value": "center",
              "label": "Center"
            },
            {
              "value": "end",
              "label": "Right"
            }
          ],
          "default": "center"
        },
        {
          "type": "select",
          "id": "impact_text_style",
          "label": "Impact text style",
          "options": [
            {
              "value": "outline",
              "label": "Outline"
            },
            {
              "value": "fill",
              "label": "Fill"
            }
          ],
          "default": "fill"
        },
        {
          "type": "select",
          "id": "text_divider",
          "label": "Multiple texts divider",
          "options": [
            {
              "value": "none",
              "label": "None"
            },
            {
              "value": "square",
              "label": "Square"
            },
            {
              "value": "circle",
              "label": "Circle"
            },
            {
              "value": "diamond",
              "label": "Diamond"
            },
            {
              "value": "line",
              "label": "Line"
            }
          ],
          "default": "none"
        },
        {
          "type": "range",
          "id": "impact_text_size_ratio",
          "label": "Impact text size ratio",
          "min": 0.5,
          "max": 1.5,
          "step": 0.1,
          "default": 1
        },
        {
          "type": "header",
          "content": "Colors",
          "info": "Gradient replaces solid colors when set. Gradient text outline and gradient background cannot be combined."
        },
        {
          "type": "color",
          "id": "background",
          "label": "Background"
        },
        {
          "type": "color_background",
          "id": "background_gradient",
          "label": "Background gradient"
        },
        {
          "type": "color",
          "id": "heading_text_color",
          "label": "Impact text"
        },
        {
          "type": "color_background",
          "id": "heading_gradient",
          "label": "Impact text gradient"
        },
        {
          "type": "color",
          "id": "text_color",
          "label": "Text"
        },
        {
          "type": "color",
          "id": "button_background",
          "label": "Button background"
        },
        {
          "type": "color",
          "id": "button_text_color",
          "label": "Button text"
        }
      ],
      "presets": [
        {
          "name": "Impact text",
          "blocks": [
            {
              "type": "item"
            }
          ]
        }
      ]
    },
    "amp-review-fallback": {
      "name": "Amp Reviews Fallback",
      "settings": [
        {
          "type": "checkbox",
          "id": "enabled",
          "label": "Enable Amp Reviews Fallback"
        },

      ],
    },
  }
  public static readonly addSectionDataV7 = {
    "sections": {
      "areviews_section": {
        "type": "areviews-section",
        "settings":  {
          "type": "checkbox",
          "id": "enabled",
          "label": "Enable Areviews ‑ Reviews"
        },
      },
      "image_banner": {
        "type": "image-banner",
        "blocks": {
          "heading": {
            "type": "heading",
            "settings": {
              "heading": "Image banner",
              "heading_size": "h0"
            }
          },
          "text": {
            "type": "text",
            "settings": {
              "text": "Give customers details about the banner image(s) or content on the template.",
              "text_style": "body"
            }
          },
          "button": {
            "type": "buttons",
            "settings": {
              "button_label_1": "Shop all",
              "button_link_1": "shopify:\/\/collections\/all",
              "button_style_secondary_1": true,
              "button_label_2": "",
              "button_link_2": "",
              "button_style_secondary_2": false
            }
          }
        },
        "block_order": [
          "heading",
          "text",
          "button"
        ],
        "settings": {
          "image_overlay_opacity": 40,
          "image_height": "large",
          "adapt_height_first_image": false,
          "desktop_content_position": "bottom-center",
          "show_text_box": false,
          "desktop_content_alignment": "center",
          "color_scheme": "background-1",
          "mobile_content_alignment": "center",
          "stack_images_on_mobile": false,
          "show_text_below": false,
          "image" : "",
          "image2" : ""
        }
      },
      "rich_text": {
        "type": "rich-text",
        "blocks": {
          "heading": {
            "type": "heading",
            "settings": {
              "heading": "Talk about your brand",
              "heading_size": "h1"
            }
          },
          "text": {
            "type": "text",
            "settings": {
              "text": "Share information about your brand with your customers. Describe a product, make announcements, or welcome customers to your store."
            }
          },
          "button": {
            "type": "button",
            "settings": {
              "button_label": "Button label",
              "button_link": "",
              "button_style_secondary": false,
              "button_label_2": "",
              "button_link_2": "",
              "button_style_secondary_2": false
            }
          }
        },
        "block_order": [
          "heading",
          "text",
          "button"
        ],
        "settings": {
          "desktop_content_position": "center",
          "content_alignment": "center",
          "color_scheme": "background-1",
          "full_width": true,
          "padding_top": 40,
          "padding_bottom": 52
        }
      },
      "impact-text": {
        "type": "impact-text",
        "blocks": {
            "1664424181b38582d5-0": {
                "type": "item",
                "settings": {
                    "title": "97%",
                    "subheading": "with zero new stretchmarks",
                    "content": "<p>when using our OBGYN-approved, cocoa & shea Tummy Butter with as directed throughout pregnancy.<\/p>",
                    "button_text": "SHOP TUMMY BUTTER",
                    "button_url": "shopify:\/\/products\/tummy-butter-for-pregnancy-stretch-marks",
                    "animate_impact_text": true,
                    "animate_impact_text_duration": 2.5
                }
            },
            "48b7a481-1eb2-41ef-81b4-a1799d6020a5": {
                "type": "item",
                "settings": {
                    "title": "100%",
                    "subheading": "said they felt instant itch relief",
                    "content": "<p>and softer, more hydrated skin after using our non-greasy, “Perfect 10 Blend” Bump Gloss oil.<\/p>",
                    "button_text": "SHOP BUMP GLOSS",
                    "button_url": "shopify:\/\/products\/bump-gloss-pregnancy-stretch-mark-oil",
                    "animate_impact_text": true,
                    "animate_impact_text_duration": 3.7
                }
            }
        },
        "block_order": [
            "1664424181b38582d5-0",
            "48b7a481-1eb2-41ef-81b4-a1799d6020a5"
        ],

        "settings": {
            "full_width": true,
            "stack_mobile": true,
            "text_alignment": "center",
            "impact_text_style": "fill",
            "text_divider": "none",
            "impact_text_size_ratio": 1,
            "background": "",
            "background_gradient": "",
            "heading_text_color": "rgba(0,0,0,0)",
            "heading_gradient": "linear-gradient(180deg, rgba(244, 153, 167, 1), rgba(244, 158, 158, 1) 50%, rgba(244, 153, 167, 1) 80%, rgba(26, 26, 26, 0) 100%)",
            "text_color": "",
            "button_background": "#fafe54",
            "button_text_color": "#000000"
        }
    },
      "featured_collection": {
        "type": "featured-collection",
        "settings": {
          "title": "Featured products",
          "heading_size": "h2",
          "description": "",
          "show_description": false,
          "description_style": "body",
          "collection": "all",
          "products_to_show": 8,
          "columns_desktop": 4,
          "full_width": false,
          "show_view_all": false,
          "view_all_style": "solid",
          "enable_desktop_slider": false,
          "color_scheme": "background-1",
          "image_ratio": "adapt",
          "show_secondary_image": true,
          "show_vendor": false,
          "show_rating": false,
          "enable_quick_add": false,
          "columns_mobile": "2",
          "swipe_on_mobile": false,
          "padding_top": 28,
          "padding_bottom": 36
        }
      },
      "collage": {
        "type": "collage",
        "blocks": {
          "collection": {
            "type": "collection",
            "settings": {
              "collection": ""
            }
          },
          "product": {
            "type": "product",
            "settings": {
              "product": "",
              "second_image": false
            }
          },
          "image": {
            "type": "image",
            "settings": {
              "image": "",
              "image_padding": true
            }
          },
          "video": {
            "type": "video",
            "settings": {
              "video_url": "https:\/\/www.youtube.com\/watch?v=_9VUPq3SxOc",
              "description": "Describe the video",
              "image_padding": false
            }
          }
        },
        "block_order": [
          "collection",
          "product",
          "image",
          "video"
        ],
        "settings": {
          "heading": "Multimedia collage",
          "heading_size": "h2",
          "desktop_layout": "left",
          "mobile_layout": "collage",
          "color_scheme": "background-1",
          "padding_top": 36,
          "padding_bottom": 36
        }
      },
      "collapsible_content": {
        "type": "collapsible-content",
        "blocks": {
          "collapsible_row": {
            "type": "collapsible_row",
            "settings": {
              "heading": "Collapsible row",
              "icon": "check_mark",
              "row_content": "",
              "page": ""
            }
          },
        },
        "block_order": [
          "collapsible_row"
        ],
        "settings": {
          "caption": "",
          "heading": "Collapsible content",
          "heading_size": "h1",
          "heading_alignment": "center",
          "layout": "none",
          "color_scheme": "background-1",
          "container_color_scheme": "background-2",
          "open_first_collapsible_row": false,
          "image" : "",
          "image_ratio": "adapt",
          "desktop_layout": "image_second",
          "padding_top": 36,
          "padding_bottom": 36
        }
      },
      "video": {
        "type": "video",
        "settings": {
          "heading": "",
          "heading_size": "h1",
          "video_url": "https:\/\/www.youtube.com\/watch?v=_9VUPq3SxOc",
          "description": "",
          "full_width": false,
          "color_scheme": "background-1",
          "padding_top": 36,
          "padding_bottom": 36
        }
      },
      "multiple-images-with-text": {
        "type": "multiple-images-with-text",
        "blocks": {
            "template--15181414957117__7b312a7f-b338-42a1-bc95-1a90ecee3b71-16815074515b8bd574-1": {
                "type": "item",
                "settings": {
                    "image": "shopify:\/\/shop_images\/3.jpg",
                    "image_rotation_degrees": 1.5,
                    "subheading": "",
                    "title": "YOUR SAFETY IS OUR PRIORITY",
                    "content": "<p>Our products are safe for use during pregnancy and and while breastfeeding. They have been tested extensively without animal testing.<\/p><p> We understand that it is difficult for moms-to-be to find time to spoil themselves but thanks to the organic and non-toxic pregnancy skincare by The Spoiled Mama it is easy.\n<\/p><p> Our entire collection of products and pregnancy gifts is made to help soothe your mind and body.<\/p>",
                    "button_url": "",
                    "button_text": ""
                }
            },
            "3c7e4c2f-2879-446d-8ee2-82ff35d7a94f": {
                "type": "item",
                "settings": {
                    "image": "shopify:\/\/shop_images\/1.jpg",
                    "image_rotation_degrees": 0,
                    "subheading": "",
                    "title": "WHY CHOOSE US?",
                    "content": "<p> Our ingredients are so gentle that you won't need to worry about toxic ingredients or having dry, itchy skin and you can focus on that “glowing” pregnancy skin tone you always imagined! <\/p><p> From seabuckthorn oil to hyaluronic acid, the effective, non-toxic ingredients in each of our products are proven to improve the appearance of oily, hormonal acne, stretch marks, c-section scars, postpartum skin and help fade dark spots, caused by melasma (mask of pregnancy). <\/p>",
                    "button_url": "",
                    "button_text": ""
                }
            },
            "template--15181414957117__7b312a7f-b338-42a1-bc95-1a90ecee3b71-16815074515b8bd574-0": {
                "type": "item",
                "settings": {
                    "image": "shopify:\/\/shop_images\/4.jpg",
                    "image_rotation_degrees": -2,
                    "subheading": "",
                    "title": "NON-TOXIC, VEGAN & SAFE",
                    "content": "<p>Safe pregnancy skincare means clean beauty products for moms. Always safe for baby and you. <\/p><p> Let's face it. Growing a human isn't easy, and The Spoiled Mama is here for you so you can enjoy these precious months. Our products ensure you receive the safest pregnancy skincare available.<\/p>",
                    "button_url": "",
                    "button_text": ""
                }
            }
        },
        "block_order": [
            "template--15181414957117__7b312a7f-b338-42a1-bc95-1a90ecee3b71-16815074515b8bd574-1",
            "3c7e4c2f-2879-446d-8ee2-82ff35d7a94f",
            "template--15181414957117__7b312a7f-b338-42a1-bc95-1a90ecee3b71-16815074515b8bd574-0"
        ],
        "settings": {
            "full_width": true,
            "images_layout": "scattered",
            "collage_images_alignment": "shifted",
            "background": "",
            "background_gradient": "",
            "text_color": "",
            "button_background": "rgba(0,0,0,0)",
            "button_text_color": ""
        }
    },
      "multicolumn": {
        "type": "multicolumn",
        "blocks": {
          "column": {
            "type": "column",
            "settings": {
              "title": "Column",
              "text": "<p>Pair text with an image to focus on your chosen product, collection, or blog post. Add details on availability, style, or even provide a review.<\/p>",
              "link_label": "",
              "link": ""
            }
          }
        },
        "block_order": [
          "column"
        ],
        "settings": {
          "title": "",
          "heading_size": "h1",
          "image_width": "third",
          "image_ratio": "adapt",
          "columns_desktop": 3,
          "column_alignment": "center",
          "background_style": "none",
          "button_label": "",
          "button_link": "",
          "color_scheme": "background-1",
          "columns_mobile": "1",
          "swipe_on_mobile": false,
          "padding_top": 36,
          "padding_bottom": 36
        }
      },
      "reviews_importer_section_happyPage": {
        "type": "reviews-importer-section-happyPage",
        "settings": {
        }
      },
      "reviews_importer_section": {
        "type": "reviews-importer-section",
        "settings": {
          "title": "",
          "class_title": "",
          "class_wrap_title": ""
        }
      },
      "reviews_importer_product": {
        "type": "reviews-importer-product",
        "settings": {
          "lai_product": ""
        }
      },
      "featured_blog": {
        "type": "featured-blog",
        "settings": {
          "heading": "Blog posts",
          "heading_size": "h1",
          "blog": "news",
          "post_limit": 3,
          "columns_desktop": 3,
          "color_scheme": "background-1",
          "show_image": true,
          "show_date": true,
          "show_author": false,
          "show_view_all": true,
          "padding_top": 36,
          "padding_bottom": 36
        }
      },
      "collection_list": {
        "type": "collection-list",
        "blocks": {
          "featured_collection": {
            "type": "featured_collection",
            "settings": {
              "collection": ""
            }
          },
        },
        "block_order": [
          "featured_collection"
        ],
        "settings": {
          "title": "Collections",
          "heading_size": "h1",
          "image_ratio": "square",
          "columns_desktop": 3,
          "color_scheme": "background-1",
          "show_view_all": false,
          "columns_mobile": "1",
          "swipe_on_mobile": false,
          "padding_top": 36,
          "padding_bottom": 36
        }
      },
      "newsletter": {
        "type": "newsletter",
        "disabled": true,
        "blocks": {
          "heading": {
            "type": "heading",
            "settings": {
              "heading": "Subscribe to our emails",
              "heading_size": "h1"
            }
          },
          "paragraph": {
            "type": "paragraph",
            "settings": {
              "text": "<p>Be the first to know about new collections and exclusive offers.<\/p>"
            }
          },
          "email_form": {
            "type": "email_form",
            "settings": {
            }
          }
        },
        "block_order": [
          "template--14450283446336__e92418fe-37f6-4b72-9f13-d96ad6921f82-166600278861b7091c-0",
          "template--14450283446336__e92418fe-37f6-4b72-9f13-d96ad6921f82-166600278861b7091c-1",
          "template--14450283446336__e92418fe-37f6-4b72-9f13-d96ad6921f82-166600278861b7091c-2"
        ],
        "settings": {
          "color_scheme": "background-1",
          "full_width": true,
          "padding_top": 40,
          "padding_bottom": 52
        }
      },
      "featured_product": {
        "type": "featured-product",
        "blocks": {
          "vendor": {
            "type": "text",
            "settings": {
              "text": "{{ product.vendor }}",
              "text_style": "uppercase"
            }
          },
          "text": {
            "type": "text",
            "settings": {
              "text": "{{ section.settings.product.vendor }}",
              "text_style": "uppercase"
            }
          },

          "title": {
            "type": "title",
            "settings": {
            }
          },
          "price": {
            "type": "price",
            "settings": {
            }
          },
          "variant_picker": {
            "type": "variant_picker",
            "settings": {
              "picker_type": "button"
            }
          },
          "quantity_selector": {
            "type": "quantity_selector",
            "settings": {
            }
          },
          "buy_buttons": {
            "type": "buy_buttons",
            "settings": {
              "show_dynamic_checkout": true
            }
          },
          "share": {
            "type": "share",
            "settings": {
              "share_label": "Share"
            }
          }
        },
        "block_order": [
          "vendor",
          "title",
         "buy_buttons",
         "price",
         "quantity_selector",
         "share",
         "text",
         "variant_picker"
        ],
        "settings": {
          "product": "",
          "color_scheme": "background-1",
          "secondary_background": false,
          "hide_variants": false,
          "enable_video_looping": false,
          "padding_top": 36,
          "padding_bottom": 36
        }
      },
      "custom_html": {
        "type": "custom-html",
        "settings": {
          "html": ""
        }
      },
      "image_with_text": {
        "type": "image-with-text",
        "blocks": {
          "heading": {
            "type": "heading",
            "settings": {
              "heading": "Image with text",
              "heading_size": "h1"
            }
          },
          "text": {
            "type": "text",
            "settings": {
              "text": "<p>Pair text with an image to focus on your chosen product, collection, or blog post. Add details on availability, style, or even provide a review.<\/p>",
              "text_style": "body"
            }
          },
          "button": {
            "type": "button",
            "settings": {
              "button_label": "Button label",
              "button_link": ""
            }
          }
        },
        "block_order": [
          "heading",
          "text",
          "button"
        ],
        "settings": {
          "height": "adapt",
          "desktop_image_width": "medium",
          "layout": "image_first",
          "desktop_content_position": "top",
          "desktop_content_alignment": "left",
          "content_layout": "no-overlap",
          "color_scheme": "background-1",
          "mobile_content_alignment": "left",
          "padding_top": 36,
          "padding_bottom": 36
        }
      },
      "slideshow": {
        "type": "slideshow",
        "blocks": {
          "slide": {
            "type": "slide",
            "settings": {
              "heading": "Image slide",
              "heading_size": "h1",
              "subheading": "Tell your brand's story through images",
              "button_label": "Button label",
              "link": "",
              "button_style_secondary": false,
              "box_align": "middle-center",
              "show_text_box": true,
              "text_alignment": "center",
              "image_overlay_opacity": 0,
              "color_scheme": "background-1",
              "text_alignment_mobile": "center"
            }
          },

        },
        "block_order": [
          "slide",
        ],
        "settings": {
          "layout": "full_bleed",
          "slide_height": "adapt_image",
          "slider_visual": "counter",
          "auto_rotate": false,
          "change_slides_speed": 5,
          "show_text_below": true,
          "accessibility_info": "Slideshow about our brand"
        }
      },
      "main_product": {
        "type": "main-product",
        "blocks": {
          "vendor": {
            "type": "text",
            "settings": {
              "text": "{{ product.vendor }}",
              "text_style": "uppercase"
            }
          },
          "title": {
            "type": "title",
            "settings": {
            }
          },
          "text": {
            "type": "text",
            "settings": {
              "text": "{{ product.metafields.descriptors.subtitle.value }}",
              "text_style": "subtitle"
            }
          },
          "price": {
            "type": "price",
            "settings": {
            }
          },
          "variant_picker": {
            "type": "variant_picker",
            "settings": {
              "picker_type": "button"
            }
          },
          "quantity_selector": {
            "type": "quantity_selector",
            "settings": {
            }
          },
          "buy_buttons": {
            "type": "buy_buttons",
            "settings": {
              "show_dynamic_checkout": true
            }
          },
          "description": {
            "type": "description",
            "settings": {
            }
          },
          "collapsible-row": {
            "type": "collapsible_tab",
            "settings": {
              "heading": "Materials",
              "icon": "leather",
              "content": "",
              "page": ""
            }
          },
          "share": {
            "type": "share",
            "settings": {
              "share_label": "Share"
            }
          },
          "star-ratings": {
            "type": "star_ratings",
            "settings": {
            }
          }
        },
        "block_order": [
          "vendor",
          "title",
          "caption",
          "price",
          "variant_picker",
          "quantity_selector",
          "buy_buttons",
          "description",
          "collapsible-row",
          "share",
          "star-ratings"
        ],
        "settings": {
          "enable_sticky_info": true,
          "gallery_layout": "stacked",
          "media_size": "large",
          "mobile_thumbnails": "hide",
          "hide_variants": true,
          "enable_video_looping": false,
          "padding_top": 36,
          "padding_bottom": 12
        }
      },
      "product_recommendations": {
        "type": "product-recommendations",
        "settings": {
          "heading": "You may also like",
          "heading_size": "h2",
          "products_to_show": 4,
          "columns_desktop": 4,
          "color_scheme": "background-1",
          "image_ratio": "square",
          "show_secondary_image": true,
          "show_vendor": false,
          "show_rating": false,
          "columns_mobile": "2",
          "padding_top": 36,
          "padding_bottom": 28
        }
      },
      "main_collection_banner": {
        "type": "main-collection-banner",
        "settings": {
          "show_collection_description": true,
          "show_collection_image": false,
          "color_scheme": "background-1"
        }
      },
      "main_collection_product_grid": {
        "type": "main-collection-product-grid",
        "settings": {
          "products_per_page": 16,
          "columns_desktop": 4,
          "image_ratio": "adapt",
          "show_secondary_image": false,
          "show_vendor": false,
          "show_rating": false,
          "enable_filtering": true,
          "enable_sorting": true,
          "columns_mobile": "2",
          "padding_top": 36,
          "padding_bottom": 36
        }
      },
      "main_list_collections": {
        "type": "main-list-collections",
        "settings": {
          "title": "Collections",
          "sort": "alphabetical",
          "image_ratio": "square"
        }
      },

      "main_cart_items": {
        "type": "main-cart-items",
        "settings": {
          "padding_top": 36,
          "padding_bottom": 36
        }
      },
      "main_cart_footer": {
        "type": "main-cart-footer",
        "blocks": {
          "subtotal": {
            "type": "subtotal",
            "settings": {
            }
          },
          "buttons": {
            "type": "buttons",
            "settings": {
            }
          }
        },
        "block_order": [
          "subtotal",
          "buttons"
        ],
        "settings": {
        }
      },
      "integration-reviews-lai-ali-express-io": {
        "type": "integration-reviews-lai-ali-express-io",
        "settings":
          {
           'enabled' : true
          },


      },
      "integration-reviews-stamped-io": {
        "type": "integration-reviews-stamped-io",
        "settings":   {
          'enabled' : true
         },
      },
      "integration-reviews-areviews-aliexpress": {
        "type": "integration-reviews-areviews-aliexpress",
        "settings":   {
          'enabled' : true
         },
      },
      "integration-reviews-bazaarvoice" : {
        "type" : "integration-reviews-bazaarvoice",
        "settings": {
          'enabled' : true,
          'shop_id' : '',

        }
      },
      "integration-reviews-judge-me": {
        "type": "integration-reviews-judge-me",
        "settings":   {
          'enabled' : true
         },
      },
      "integration-reviews-loox": {
        "type": "integration-reviews-loox",
        "settings":   {
          'enabled' : true
         },
      },
      "integration-reviews-okendo": {
        "type": "integration-reviews-okendo",
        "settings":   {
          'enabled' : true
         },
      },
      "integration-reviews-opinew": {
        "type": "integration-reviews-opinew",
        "settings":   {
          'enabled' : true
         },
      },
      "integration-reviews-ryviu": {
        "type": "integration-reviews-ryviu",
        "settings":   {
          'enabled' : true
         },
      },
      "integration-reviews-shopify-product-reviews": {
        "type": "integration-reviews-shopify-product-reviews",
        "settings":   {
          'enabled' : true
         },
      },
      "integration-reviews-shopperapproved": {
        "type": "integration-reviews-shopperapproved",
        "settings":   {
          'enabled' : true
         },
      },
      "integration-reviews-review-io" : {
        "type" : "integration-reviews-review-io",
        "settings": {
          'enabled' : true,
          'shop_id' : '',

        }
      },
      "integration-reviews-yotpo" : {
        "type" : "integration-reviews-yotpo",
        "settings": {
          'enabled' : true,
          'api_key' : '',

        }
      },
      "integration-reviews-ssw": {
        "type": "integration-reviews-ssw",
        "settings":   {
          'enabled' : true
         },
      },
      "integration-reviews-helpfulcrowd" : {
        "type" : "integration-reviews-helpfulcrowd",
        "settings": {
          'enabled' : true,
          'client_id' : '',

        }
      },
      "integration-reviews-alireviews": {
        "type": "integration-reviews-alireviews",
        "settings":   {
          'enabled' : true
         },
      },
      "footer": {
        "name": "Footer",
        "blocks": {
          "link_list": {
            "type": "link_list",
            "settings": {
              "heading": "Quick links",
              "menu": "footer"
            }
          },

          "text": {
            "type": "text",
            "settings": {
              "heading": "Our mission",
              "subtext": "<p>Share contact information, store details, and brand content with your customers.<\/p>"
            }
          }
        },
        "block_order": [
          "link_list",
          "text"
        ],
        "settings": {
          "newsletter_enable": {
            "label": "Show email signup"
          },
          "newsletter_heading": {
            "label": "Heading"
          },
          "header__1": {
            "content": "Email Signup",
            "info": "Subscribers added automatically to your “accepted marketing” customer list. [Learn more](https://help.shopify.com/manual/customers/manage-customers)"
          },
          "header__2": {
            "content": "Social media icons",
            "info": "To display your social media accounts, link them in your theme settings."
          },
          "show_social": {
            "label": "Show social media icons"
          },
          "header__3": {
            "content": "Country/region selector"
          },
          "header__4": {
            "info": "To add a country/region, go to your [payment settings.](/admin/settings/payments)"
          },
          "enable_country_selector": {
            "label": "Enable country/region selector"
          },
          "header__5": {
            "content": "Language selector"
          },
          "header__6": {
            "info": "To add a language, go to your [language settings.](/admin/settings/languages)"
          },
          "enable_language_selector": {
            "label": "Enable language selector"
          },
          "header__7": {
            "content": "Payment methods"
          },
          "payment_enable": {
            "label": "Show payment icons"
          },
          "margin_top": {
            "label": "Top margin"
          }
        }
      },
      "integration-reviews-proviews-io": {
        "type": "integration-reviews-proviews-io",
        "settings":   {
          'enabled' : true
         },
      },
      "featured-collection-slider": {
        "type": "featured-collection-slider",
        "settings": {
            "title": "",
            "subtitle": "",
            "heading_size": "h1",
            "collection": "best-sellers",
            "products_to_show": 20,
            "columns_desktop": 5,
            "image_ratio": "adapt",
            "show_secondary_image": false,
            "columns_mobile": 3,
            "padding_top": 0
        }
    },
    "amp-review-fallback": {
      "type": "amp-review-fallback",
      "settings":   {
        'enabled' : true
       },
    },


    }

  }
  public static readonly globeSettingSchemaV7 = {

    "layout": {
      "name": "t:settings_schema.layout.name",
      "settings": [
        {
          "type": "range",
          "id": "page_width",
          "min": 1000,
          "max": 1600,
          "step": 100,
          "default": 1200,
          "unit": "px",
          "label": "t:settings_schema.layout.settings.page_width.label"
        },
        {
          "type": "range",
          "id": "spacing_sections",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:settings_schema.layout.settings.spacing_sections.label",
          "default": 0
        },
        {
          "type": "header",
          "content": "t:settings_schema.layout.settings.header__grid.content"
        },
        {
          "type": "paragraph",
          "content": "t:settings_schema.layout.settings.paragraph__grid.content"
        },
        {
          "type": "range",
          "id": "spacing_grid_horizontal",
          "min": 4,
          "max": 40,
          "step": 4,
          "default": 8,
          "unit": "px",
          "label": "t:settings_schema.layout.settings.spacing_grid_horizontal.label"
        },
        {
          "type": "range",
          "id": "spacing_grid_vertical",
          "min": 4,
          "max": 40,
          "step": 4,
          "default": 8,
          "unit": "px",
          "label": "t:settings_schema.layout.settings.spacing_grid_vertical.label"
        }
      ]
    },
    "colors":
    {
      "name": "t:settings_schema.colors.name",
      "settings": [
        {
          "type": "header",
          "content": "t:settings_schema.colors.settings.header__1.content"
        },
        {
          "type": "color",
          "id": "colors_solid_button_labels",
          "default": "#FFFFFF",
          "label": "t:settings_schema.colors.settings.colors_solid_button_labels.label",
          "info": "t:settings_schema.colors.settings.colors_solid_button_labels.info"
        },
        {
          "type": "color",
          "id": "colors_accent_1",
          "default": "#121212",
          "label": "t:settings_schema.colors.settings.colors_accent_1.label",
          "info": "t:settings_schema.colors.settings.colors_accent_1.info"
        },
        {
          "id": "gradient_accent_1",
          "type": "color_background",
          "label": "t:settings_schema.colors.settings.gradient_accent_1.label"
        },
        {
          "type": "color",
          "id": "colors_accent_2",
          "default": "#334FB4",
          "label": "t:settings_schema.colors.settings.colors_accent_2.label"
        },
        {
          "id": "gradient_accent_2",
          "type": "color_background",
          "label": "t:settings_schema.colors.settings.gradient_accent_2.label"
        },
        {
          "type": "header",
          "content": "t:settings_schema.colors.settings.header__2.content"
        },
        {
          "type": "color",
          "id": "colors_text",
          "default": "#121212",
          "label": "t:settings_schema.colors.settings.colors_text.label",
          "info": "t:settings_schema.colors.settings.colors_text.info"
        },
        {
          "type": "color",
          "id": "colors_outline_button_labels",
          "default": "#121212",
          "label": "t:settings_schema.colors.settings.colors_outline_button_labels.label",
          "info": "t:settings_schema.colors.settings.colors_outline_button_labels.info"
        },
        {
          "type": "color",
          "id": "colors_background_1",
          "default": "#FFFFFF",
          "label": "t:settings_schema.colors.settings.colors_background_1.label"
        },
        {
          "id": "gradient_background_1",
          "type": "color_background",
          "label": "t:settings_schema.colors.settings.gradient_background_1.label"
        },
        {
          "type": "color",
          "id": "colors_background_2",
          "default": "#F3F3F3",
          "label": "t:settings_schema.colors.settings.colors_background_2.label"
        },
        {
          "id": "gradient_background_2",
          "type": "color_background",
          "label": "t:settings_schema.colors.settings.gradient_background_2.label"
        }
      ]
    },
    "typography": {
      "name": "t:settings_schema.typography.name",
      "settings": [
        {
          "type": "checkbox",
          "id": "no_font",
          "default": false,
          "label": "t:settings_schema.typography.settings.nofont.label"
        },
        {
          "type": "header",
          "content": "t:settings_schema.typography.settings.header__1.content"
        },
        {
          "type": "font_picker",
          "id": "type_header_font",
          "default": "assistant_n4",
          "label": "t:settings_schema.typography.settings.type_header_font.label",
          "info": "t:settings_schema.typography.settings.type_header_font.info"
        },
        {
          "type": "range",
          "id": "heading_scale",
          "min": 100,
          "max": 150,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.typography.settings.heading_scale.label",
          "default": 100
        },
        {
          "type": "header",
          "content": "t:settings_schema.typography.settings.header__2.content"
        },
        {
          "type": "font_picker",
          "id": "type_body_font",
          "default": "assistant_n4",
          "label": "t:settings_schema.typography.settings.type_body_font.label",
          "info": "t:settings_schema.typography.settings.type_body_font.info"
        },
        {
          "type": "range",
          "id": "body_scale",
          "min": 100,
          "max": 130,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.typography.settings.body_scale.label",
          "default": 100
        }
      ]
    },
    "buttons": {
      "name": "t:settings_schema.buttons.name",
      "settings": [
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__border.content"
        },
        {
          "type": "range",
          "id": "buttons_border_thickness",
          "min": 0,
          "max": 12,
          "step": 1,
          "unit": "px",
          "label": "t:settings_schema.global.settings.thickness.label",
          "default": 1
        },
        {
          "type": "range",
          "id": "buttons_border_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 100
        },
        {
          "type": "range",
          "id": "buttons_radius",
          "min": 0,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.corner_radius.label",
          "default": 0
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__shadow.content"
        },
        {
          "type": "range",
          "id": "buttons_shadow_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "buttons_shadow_horizontal_offset",
          "min": -12,
          "max": 12,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.horizontal_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "buttons_shadow_vertical_offset",
          "min": -12,
          "max": 12,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.vertical_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "buttons_shadow_blur",
          "min": 0,
          "max": 20,
          "step": 5,
          "unit": "px",
          "label": "t:settings_schema.global.settings.blur.label",
          "default": 0
        }
      ]
    },
    "variant_pills": {
      "name": "t:settings_schema.variant_pills.name",
      "settings": [
        {
          "type": "paragraph",
          "content": "t:settings_schema.variant_pills.paragraph"
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__border.content"
        },
        {
          "type": "range",
          "id": "variant_pills_border_thickness",
          "min": 0,
          "max": 12,
          "step": 1,
          "unit": "px",
          "label": "t:settings_schema.global.settings.thickness.label",
          "default": 1
        },
        {
          "type": "range",
          "id": "variant_pills_border_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 55
        },
        {
          "type": "range",
          "id": "variant_pills_radius",
          "min": 0,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.corner_radius.label",
          "default": 40
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__shadow.content"
        },
        {
          "type": "range",
          "id": "variant_pills_shadow_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "variant_pills_shadow_horizontal_offset",
          "min": -12,
          "max": 12,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.horizontal_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "variant_pills_shadow_vertical_offset",
          "min": -12,
          "max": 12,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.vertical_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "variant_pills_shadow_blur",
          "min": 0,
          "max": 20,
          "step": 5,
          "unit": "px",
          "label": "t:settings_schema.global.settings.blur.label",
          "default": 0
        }
      ]
    },
    "inputs": {
      "name": "t:settings_schema.inputs.name",
      "settings": [
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__border.content"
        },
        {
          "type": "range",
          "id": "inputs_border_thickness",
          "min": 0,
          "max": 12,
          "step": 1,
          "unit": "px",
          "label": "t:settings_schema.global.settings.thickness.label",
          "default": 1
        },
        {
          "type": "range",
          "id": "inputs_border_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 55
        },
        {
          "type": "range",
          "id": "inputs_radius",
          "min": 0,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.corner_radius.label",
          "default": 0
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__shadow.content"
        },
        {
          "type": "range",
          "id": "inputs_shadow_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "inputs_shadow_horizontal_offset",
          "min": -12,
          "max": 12,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.horizontal_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "inputs_shadow_vertical_offset",
          "min": -12,
          "max": 12,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.vertical_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "inputs_shadow_blur",
          "min": 0,
          "max": 20,
          "step": 5,
          "unit": "px",
          "label": "t:settings_schema.global.settings.blur.label",
          "default": 0
        }
      ]
    },
    "cards": {
      "name": "t:settings_schema.cards.name",
      "settings": [
        {
          "type": "select",
          "id": "card_style",
          "options": [
            {
              "value": "standard",
              "label": "t:settings_schema.cards.settings.style.options__1.label"
            },
            {
              "value": "card",
              "label": "t:settings_schema.cards.settings.style.options__2.label"
            }
          ],
          "default": "standard",
          "label": "t:settings_schema.cards.settings.style.label"
        },
        {
          "type": "range",
          "id": "card_image_padding",
          "min": 0,
          "max": 20,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.image_padding.label",
          "default": 0
        },
        {
          "type": "select",
          "id": "card_text_alignment",
          "options": [
            {
              "value": "left",
              "label": "t:settings_schema.global.settings.text_alignment.options__1.label"
            },
            {
              "value": "center",
              "label": "t:settings_schema.global.settings.text_alignment.options__2.label"
            },
            {
              "value": "right",
              "label": "t:settings_schema.global.settings.text_alignment.options__3.label"
            }
          ],
          "default": "left",
          "label": "t:settings_schema.global.settings.text_alignment.label"
        },
        {
          "type": "select",
          "id": "card_color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-2",
          "label": "t:sections.all.colors.label"
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__border.content"
        },
        {
          "type": "range",
          "id": "card_border_thickness",
          "min": 0,
          "max": 24,
          "step": 1,
          "unit": "px",
          "label": "t:settings_schema.global.settings.thickness.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "card_border_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "card_corner_radius",
          "min": 0,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.corner_radius.label",
          "default": 0
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__shadow.content"
        },
        {
          "type": "range",
          "id": "card_shadow_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 10
        },
        {
          "type": "range",
          "id": "card_shadow_horizontal_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.horizontal_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "card_shadow_vertical_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.vertical_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "card_shadow_blur",
          "min": 0,
          "max": 40,
          "step": 5,
          "unit": "px",
          "label": "t:settings_schema.global.settings.blur.label",
          "default": 0
        }
      ]
    },
    "collection_cards": {
      "name": "t:settings_schema.collection_cards.name",
      "settings": [
        {
          "type": "select",
          "id": "collection_card_style",
          "options": [
            {
              "value": "standard",
              "label": "t:settings_schema.collection_cards.settings.style.options__1.label"
            },
            {
              "value": "card",
              "label": "t:settings_schema.collection_cards.settings.style.options__2.label"
            }
          ],
          "default": "standard",
          "label": "t:settings_schema.collection_cards.settings.style.label"
        },
        {
          "type": "range",
          "id": "collection_card_image_padding",
          "min": 0,
          "max": 20,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.image_padding.label",
          "default": 0
        },
        {
          "type": "select",
          "id": "collection_card_text_alignment",
          "options": [
            {
              "value": "left",
              "label": "t:settings_schema.global.settings.text_alignment.options__1.label"
            },
            {
              "value": "center",
              "label": "t:settings_schema.global.settings.text_alignment.options__2.label"
            },
            {
              "value": "right",
              "label": "t:settings_schema.global.settings.text_alignment.options__3.label"
            }
          ],
          "default": "left",
          "label": "t:settings_schema.global.settings.text_alignment.label"
        },
        {
          "type": "select",
          "id": "collection_card_color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-2",
          "label": "t:sections.all.colors.label"
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__border.content"
        },
        {
          "type": "range",
          "id": "collection_card_border_thickness",
          "min": 0,
          "max": 24,
          "step": 1,
          "unit": "px",
          "label": "t:settings_schema.global.settings.thickness.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "collection_card_border_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "collection_card_corner_radius",
          "min": 0,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.corner_radius.label",
          "default": 0
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__shadow.content"
        },
        {
          "type": "range",
          "id": "collection_card_shadow_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 10
        },
        {
          "type": "range",
          "id": "collection_card_shadow_horizontal_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.horizontal_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "collection_card_shadow_vertical_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.vertical_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "collection_card_shadow_blur",
          "min": 0,
          "max": 40,
          "step": 5,
          "unit": "px",
          "label": "t:settings_schema.global.settings.blur.label",
          "default": 0
        }
      ]
    },
    "blog_cards": {
      "name": "t:settings_schema.blog_cards.name",
      "settings": [
        {
          "type": "select",
          "id": "blog_card_style",
          "options": [
            {
              "value": "standard",
              "label": "t:settings_schema.blog_cards.settings.style.options__1.label"
            },
            {
              "value": "card",
              "label": "t:settings_schema.blog_cards.settings.style.options__2.label"
            }
          ],
          "default": "standard",
          "label": "t:settings_schema.blog_cards.settings.style.label"
        },
        {
          "type": "range",
          "id": "blog_card_image_padding",
          "min": 0,
          "max": 20,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.image_padding.label",
          "default": 0
        },
        {
          "type": "select",
          "id": "blog_card_text_alignment",
          "options": [
            {
              "value": "left",
              "label": "t:settings_schema.global.settings.text_alignment.options__1.label"
            },
            {
              "value": "center",
              "label": "t:settings_schema.global.settings.text_alignment.options__2.label"
            },
            {
              "value": "right",
              "label": "t:settings_schema.global.settings.text_alignment.options__3.label"
            }
          ],
          "default": "left",
          "label": "t:settings_schema.global.settings.text_alignment.label"
        },
        {
          "type": "select",
          "id": "blog_card_color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-2",
          "label": "t:sections.all.colors.label"
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__border.content"
        },
        {
          "type": "range",
          "id": "blog_card_border_thickness",
          "min": 0,
          "max": 24,
          "step": 1,
          "unit": "px",
          "label": "t:settings_schema.global.settings.thickness.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "blog_card_border_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "blog_card_corner_radius",
          "min": 0,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.corner_radius.label",
          "default": 0
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__shadow.content"
        },
        {
          "type": "range",
          "id": "blog_card_shadow_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 10
        },
        {
          "type": "range",
          "id": "blog_card_shadow_horizontal_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.horizontal_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "blog_card_shadow_vertical_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.vertical_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "blog_card_shadow_blur",
          "min": 0,
          "max": 40,
          "step": 5,
          "unit": "px",
          "label": "t:settings_schema.global.settings.blur.label",
          "default": 0
        }
      ]
    },
    "content_containers": {
      "name": "t:settings_schema.content_containers.name",
      "settings": [
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__border.content"
        },
        {
          "type": "range",
          "id": "text_boxes_border_thickness",
          "min": 0,
          "max": 24,
          "step": 1,
          "unit": "px",
          "label": "t:settings_schema.global.settings.thickness.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "text_boxes_border_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "text_boxes_radius",
          "min": 0,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.corner_radius.label",
          "default": 0
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__shadow.content"
        },
        {
          "type": "range",
          "id": "text_boxes_shadow_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "text_boxes_shadow_horizontal_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.horizontal_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "text_boxes_shadow_vertical_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.vertical_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "text_boxes_shadow_blur",
          "min": 0,
          "max": 40,
          "step": 5,
          "unit": "px",
          "label": "t:settings_schema.global.settings.blur.label",
          "default": 0
        }
      ]
    },
    "media": {
      "name": "t:settings_schema.media.name",
      "settings": [
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__border.content"
        },
        {
          "type": "range",
          "id": "media_border_thickness",
          "min": 0,
          "max": 24,
          "step": 1,
          "unit": "px",
          "label": "t:settings_schema.global.settings.thickness.label",
          "default": 1
        },
        {
          "type": "range",
          "id": "media_border_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 5
        },
        {
          "type": "range",
          "id": "media_radius",
          "min": 0,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.corner_radius.label",
          "default": 0
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__shadow.content"
        },
        {
          "type": "range",
          "id": "media_shadow_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "media_shadow_horizontal_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.horizontal_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "media_shadow_vertical_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.vertical_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "media_shadow_blur",
          "min": 0,
          "max": 40,
          "step": 5,
          "unit": "px",
          "label": "t:settings_schema.global.settings.blur.label",
          "default": 0
        }
      ]
    },
    "popups": {
      "name": "t:settings_schema.popups.name",
      "settings": [
        {
          "type": "paragraph",
          "content": "t:settings_schema.popups.paragraph"
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__border.content"
        },
        {
          "type": "range",
          "id": "popup_border_thickness",
          "min": 0,
          "max": 24,
          "step": 1,
          "unit": "px",
          "label": "t:settings_schema.global.settings.thickness.label",
          "default": 1
        },
        {
          "type": "range",
          "id": "popup_border_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 10
        },
        {
          "type": "range",
          "id": "popup_corner_radius",
          "min": 0,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.corner_radius.label",
          "default": 0
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__shadow.content"
        },
        {
          "type": "range",
          "id": "popup_shadow_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "popup_shadow_horizontal_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.horizontal_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "popup_shadow_vertical_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.vertical_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "popup_shadow_blur",
          "min": 0,
          "max": 40,
          "step": 5,
          "unit": "px",
          "label": "t:settings_schema.global.settings.blur.label",
          "default": 0
        }
      ]
    },
    "drawers": {
      "name": "t:settings_schema.drawers.name",
      "settings": [
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__border.content"
        },
        {
          "type": "range",
          "id": "drawer_border_thickness",
          "min": 0,
          "max": 24,
          "step": 1,
          "unit": "px",
          "label": "t:settings_schema.global.settings.thickness.label",
          "default": 1
        },
        {
          "type": "range",
          "id": "drawer_border_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 10
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__shadow.content"
        },
        {
          "type": "range",
          "id": "drawer_shadow_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "drawer_shadow_horizontal_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.horizontal_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "drawer_shadow_vertical_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.vertical_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "drawer_shadow_blur",
          "min": 0,
          "max": 40,
          "step": 5,
          "unit": "px",
          "label": "t:settings_schema.global.settings.blur.label",
          "default": 0
        }
      ]
    },
    "badges": {
      "name": "t:settings_schema.badges.name",
      "settings": [
        {
          "type": "select",
          "id": "badge_position",
          "options": [
            {
              "value": "bottom left",
              "label": "t:settings_schema.badges.settings.position.options__1.label"
            },
            {
              "value": "bottom right",
              "label": "t:settings_schema.badges.settings.position.options__2.label"
            },
            {
              "value": "top left",
              "label": "t:settings_schema.badges.settings.position.options__3.label"
            },
            {
              "value": "top right",
              "label": "t:settings_schema.badges.settings.position.options__4.label"
            }
          ],
          "default": "bottom left",
          "label": "t:settings_schema.badges.settings.position.label"
        },
        {
          "type": "range",
          "id": "badge_corner_radius",
          "min": 0,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.corner_radius.label",
          "default": 40
        },
        {
          "type": "select",
          "id": "sale_badge_color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            }
          ],
          "default": "accent-2",
          "label": "t:settings_schema.badges.settings.sale_badge_color_scheme.label"
        },
        {
          "type": "select",
          "id": "sold_out_badge_color_scheme",
          "options": [
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "inverse",
          "label": "t:settings_schema.badges.settings.sold_out_badge_color_scheme.label"
        }
      ]
    },
    "styles": {
      "name": "t:settings_schema.styles.name",
      "settings": [
        {
          "type": "select",
          "id": "accent_icons",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "outline-button",
              "label": "t:settings_schema.styles.settings.accent_icons.options__3.label"
            },
            {
              "value": "text",
              "label": "t:settings_schema.styles.settings.accent_icons.options__4.label"
            }
          ],
          "default": "text",
          "label": "t:settings_schema.styles.settings.accent_icons.label"
        }
      ]
    },
    "social-media": {
      "name": "t:settings_schema.social-media.name",
      "settings": [
        {
          "type": "header",
          "content": "t:settings_schema.social-media.settings.header.content"
        },
        {
          "type": "text",
          "id": "social_twitter_link",
          "label": "t:settings_schema.social-media.settings.social_twitter_link.label",
          "info": "t:settings_schema.social-media.settings.social_twitter_link.info"
        },
        {
          "type": "text",
          "id": "social_facebook_link",
          "label": "t:settings_schema.social-media.settings.social_facebook_link.label",
          "info": "t:settings_schema.social-media.settings.social_facebook_link.info"
        },
        {
          "type": "text",
          "id": "social_pinterest_link",
          "label": "t:settings_schema.social-media.settings.social_pinterest_link.label",
          "info": "t:settings_schema.social-media.settings.social_pinterest_link.info"
        },
        {
          "type": "text",
          "id": "social_instagram_link",
          "label": "t:settings_schema.social-media.settings.social_instagram_link.label",
          "info": "t:settings_schema.social-media.settings.social_instagram_link.info"
        },
        {
          "type": "text",
          "id": "social_tiktok_link",
          "label": "t:settings_schema.social-media.settings.social_tiktok_link.label",
          "info": "t:settings_schema.social-media.settings.social_tiktok_link.info"
        },
        {
          "type": "text",
          "id": "social_tumblr_link",
          "label": "t:settings_schema.social-media.settings.social_tumblr_link.label",
          "info": "t:settings_schema.social-media.settings.social_tumblr_link.info"
        },
        {
          "type": "text",
          "id": "social_snapchat_link",
          "label": "t:settings_schema.social-media.settings.social_snapchat_link.label",
          "info": "t:settings_schema.social-media.settings.social_snapchat_link.info"
        },
        {
          "type": "text",
          "id": "social_youtube_link",
          "label": "t:settings_schema.social-media.settings.social_youtube_link.label",
          "info": "t:settings_schema.social-media.settings.social_youtube_link.info"
        },
        {
          "type": "text",
          "id": "social_vimeo_link",
          "label": "t:settings_schema.social-media.settings.social_vimeo_link.label",
          "info": "t:settings_schema.social-media.settings.social_vimeo_link.info"
        }
      ]
    },
    "search_input": {
      "name": "t:settings_schema.search_input.name",
      "settings": [
        {
          "type": "header",
          "content": "t:settings_schema.search_input.settings.header.content"
        },
        {
          "type": "checkbox",
          "id": "predictive_search_enabled",
          "default": true,
          "label": "t:settings_schema.search_input.settings.predictive_search_enabled.label"
        },
        {
          "type": "checkbox",
          "id": "predictive_search_show_vendor",
          "default": false,
          "label": "t:settings_schema.search_input.settings.predictive_search_show_vendor.label",
          "info": "t:settings_schema.search_input.settings.predictive_search_show_vendor.info"
        },
        {
          "type": "checkbox",
          "id": "predictive_search_show_price",
          "default": false,
          "label": "t:settings_schema.search_input.settings.predictive_search_show_price.label",
          "info": "t:settings_schema.search_input.settings.predictive_search_show_price.info"
        }
      ]
    },
    "favicon": {
      "name": "t:settings_schema.favicon.name",
      "settings": [
        {
          "type": "image_picker",
          "id": "favicon",
          "label": "t:settings_schema.favicon.settings.favicon.label",
          "info": "t:settings_schema.favicon.settings.favicon.info"
        }
      ]
    },
    "currency_format": {
      "name": "t:settings_schema.currency_format.name",
      "settings": [
        {
          "type": "header",
          "content": "t:settings_schema.currency_format.settings.content"
        },
        {
          "type": "paragraph",
          "content": "t:settings_schema.currency_format.settings.paragraph"
        },
        {
          "type": "checkbox",
          "id": "currency_code_enabled",
          "label": "t:settings_schema.currency_format.settings.currency_code_enabled.label",
          "default": true
        }
      ]
    },
    "cart": {
      "name": "t:settings_schema.cart.name",
      "settings": [
        {
          "type": "select",
          "id": "cart_type",
          "options": [
            {
              "value": "drawer",
              "label": "t:settings_schema.cart.settings.cart_type.drawer.label"
            },
            {
              "value": "page",
              "label": "t:settings_schema.cart.settings.cart_type.page.label"
            },
            {
              "value": "notification",
              "label": "t:settings_schema.cart.settings.cart_type.notification.label"
            }
          ],
          "default": "notification",
          "label": "t:settings_schema.cart.settings.cart_type.label"
        },
        {
          "type": "checkbox",
          "id": "show_vendor",
          "label": "t:settings_schema.cart.settings.show_vendor.label",
          "default": false
        },
        {
          "type": "checkbox",
          "id": "show_cart_note",
          "label": "t:settings_schema.cart.settings.show_cart_note.label",
          "default": false
        },
        {
          "type": "header",
          "content": "t:settings_schema.cart.settings.cart_drawer.header"
        },
        {
          "type": "collection",
          "id": "cart_drawer_collection",
          "label": "t:settings_schema.cart.settings.cart_drawer.collection.label",
          "info": "t:settings_schema.cart.settings.cart_drawer.collection.info"
        }
      ]
    }

  }
  public static readonly globeSettingLabelV7 = {
    "settings_schema": {
      "global": {
        "settings": {
          "header__border": {
            "content": "Border"
          },
          "header__shadow": {
            "content": "Shadow"
          },
          "blur": {
            "label": "Blur"
          },
          "corner_radius": {
            "label": "Corner radius"
          },
          "horizontal_offset": {
            "label": "Horizontal offset"
          },
          "vertical_offset": {
            "label": "Vertical offset"
          },
          "thickness": {
            "label": "Thickness"
          },
          "opacity": {
            "label": "Opacity"
          },
          "image_padding": {
            "label": "Image padding"
          },
          "text_alignment": {
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            },
            "label": "Text alignment"
          }
        }
      },
      "cards": {
        "name": "Product cards",
        "settings": {
          "style": {
            "options__1": {
              "label": "Standard"
            },
            "options__2": {
              "label": "Card"
            },
            "label": "Style"
          }
        }
      },
      "collection_cards": {
        "name": "Collection cards",
        "settings": {
          "style": {
            "options__1": {
              "label": "Standard"
            },
            "options__2": {
              "label": "Card"
            },
            "label": "Style"
          }
        }
      },
      "blog_cards": {
        "name": "Blog cards",
        "settings": {
          "style": {
            "options__1": {
              "label": "Standard"
            },
            "options__2": {
              "label": "Card"
            },
            "label": "Style"
          }
        }
      },
      "badges": {
        "name": "Badges",
        "settings": {
          "position": {
            "options__1": {
              "label": "Bottom left"
            },
            "options__2": {
              "label": "Bottom right"
            },
            "options__3": {
              "label": "Top left"
            },
            "options__4": {
              "label": "Top right"
            },
            "label": "Position on cards"
          },
          "sale_badge_color_scheme": {
            "label": "Sale badge color scheme"
          },
          "sold_out_badge_color_scheme": {
            "label": "Sold out badge color scheme"
          }
        }
      },
      "colors": {
        "name": "Colors",
        "settings": {
          "colors_solid_button_labels": {
            "label": "Solid button label",
            "info": "Used as foreground color on accent colors."
          },
          "colors_accent_1": {
            "label": "Accent 1",
            "info": "Used for solid button background."
          },
          "gradient_accent_1": {
            "label": "Accent 1 gradient"
          },
          "colors_accent_2": {
            "label": "Accent 2"
          },
          "gradient_accent_2": {
            "label": "Accent 2 gradient"
          },
          "header__1": {
            "content": "Primary colors"
          },
          "header__2": {
            "content": "Secondary colors"
          },
          "colors_text": {
            "label": "Text",
            "info": "Used as foreground color on background colors."
          },
          "colors_outline_button_labels": {
            "label": "Outline button",
            "info": "Also used for text links."
          },
          "colors_background_1": {
            "label": "Background 1"
          },
          "gradient_background_1": {
            "label": "Background 1 gradient"
          },
          "colors_background_2": {
            "label": "Background 2"
          },
          "gradient_background_2": {
            "label": "Background 2 gradient"
          }
        }
      },
      "typography": {
        "name": "Typography",
        "settings": {
          "type_header_font": {
            "label": "Font",
            "info": "Selecting a different font can affect the speed of your store. [Learn more about system fonts.](https://help.shopify.com/manual/online-store/os/store-speed/improving-speed#fonts)"
          },
          "heading_scale": {
            "label": "Font size scale"
          },
          "header__1": {
            "content": "Headings"
          },
          "header__2": {
            "content": "Body"
          },
          "type_body_font": {
            "label": "Font",
            "info": "Selecting a different font can affect the speed of your store. [Learn more about system fonts.](https://help.shopify.com/manual/online-store/os/store-speed/improving-speed#fonts)"
          },
          "body_scale": {
            "label": "Font size scale"
          },
          "nofont": {
            "label": "Use system fonts"
          }
        }
      },
      "buttons": {
        "name": "Buttons"
      },
      "variant_pills": {
        "name": "Variant pills",
        "paragraph": "Variant pills are one way of displaying your product variants. [Learn more](https://help.shopify.com/en/manual/online-store/themes/theme-structure/page-types#variant-picker-block)"
      },
      "inputs": {
        "name": "Inputs"
      },
      "content_containers": {
        "name": "Content containers"
      },
      "popups": {
        "name": "Dropdowns and pop-ups",
        "paragraph": "Affects areas like navigation dropdowns, pop-up modals, and cart pop-ups."
      },
      "media": {
        "name": "Media"
      },
      "drawers": {
        "name": "Drawers"
      },
      "styles": {
        "name": "Icons",
        "settings": {
          "accent_icons": {
            "options__3": {
              "label": "Outline button"
            },
            "options__4": {
              "label": "Text"
            },
            "label": "Color"
          }
        }
      },
      "social-media": {
        "name": "Social media",
        "settings": {
          "header": {
            "content": "Social accounts"
          },
          "social_twitter_link": {
            "label": "Twitter",
            "info": "https://twitter.com/shopify"
          },
          "social_facebook_link": {
            "label": "Facebook",
            "info": "https://facebook.com/shopify"
          },
          "social_pinterest_link": {
            "label": "Pinterest",
            "info": "https://pinterest.com/shopify"
          },
          "social_instagram_link": {
            "label": "Instagram",
            "info": "http://instagram.com/shopify"
          },
          "social_tiktok_link": {
            "label": "TikTok",
            "info": "https://tiktok.com/@shopify"
          },
          "social_tumblr_link": {
            "label": "Tumblr",
            "info": "https://shopify.tumblr.com"
          },
          "social_snapchat_link": {
            "label": "Snapchat",
            "info": "https://www.snapchat.com/add/shopify"
          },
          "social_youtube_link": {
            "label": "YouTube",
            "info": "https://www.youtube.com/shopify"
          },
          "social_vimeo_link": {
            "label": "Vimeo",
            "info": "https://vimeo.com/shopify"
          }
        }
      },
      "search_input": {
        "name": "Search behavior",
        "settings": {
          "header": {
            "content": "Product suggestions"
          },
          "predictive_search_enabled": {
            "label": "Enable product suggestions"
          },
          "predictive_search_show_vendor": {
            "label": "Show vendor",
            "info": "Visible when product suggestions enabled."
          },
          "predictive_search_show_price": {
            "label": "Show price",
            "info": "Visible when product suggestions enabled."
          }
        }
      },
      "favicon": {
        "name": "Favicon",
        "settings": {
          "favicon": {
            "label": "Favicon image",
            "info": "Will be scaled down to 32 x 32px"
          }
        }
      },
      "currency_format": {
        "name": "Currency format",
        "settings": {
          "content": "Currency codes",
          "paragraph": "Cart and checkout prices always show currency codes. Example: $1.00 USD.",
          "currency_code_enabled": {
            "label": "Show currency codes"
          }
        }
      },
      "cart": {
        "name": "Cart",
        "settings": {
          "cart_type": {
            "label": "Cart type",
            "drawer": {
              "label": "Drawer"
            },
            "page": {
              "label": "Page"
            },
            "notification": {
              "label": "Popup notification"
            }
          },
          "show_vendor": {
            "label": "Show vendor"
          },
          "show_cart_note": {
            "label": "Enable cart note"
          },
          "cart_drawer": {
            "header": "Cart drawer",
            "collection": {
              "label": "Collection",
              "info": "Visible when cart drawer is empty."
            }
          }
        }
      },
      "layout": {
        "name": "Layout",
        "settings": {
          "page_width": {
            "label": "Page width"
          },
          "spacing_sections": {
            "label": "Vertical space between sections"
          },
          "header__grid": {
            "content": "Grid"
          },
          "paragraph__grid": {
            "content": "Affects areas with a multicolumn layout."
          },
          "spacing_grid_horizontal": {
            "label": "Horizontal space"
          },
          "spacing_grid_vertical": {
            "label": "Vertical space"
          }
        }
      }
    },
    "sections": {
      "all": {
        "padding": {
          "section_padding_heading": "Section padding",
          "padding_top": "Top padding",
          "padding_bottom": "Bottom padding"
        },
        "spacing": "Spacing",
        "colors": {
          "accent_1": {
            "label": "Accent 1"
          },
          "accent_2": {
            "label": "Accent 2"
          },
          "background_1": {
            "label": "Background 1"
          },
          "background_2": {
            "label": "Background 2"
          },
          "inverse": {
            "label": "Inverse"
          },
          "label": "Color scheme",
          "has_cards_info": "To change the card color scheme, update your theme settings."
        },
        "heading_size": {
          "label": "Heading size",
          "options__1": {
            "label": "Small"
          },
          "options__2": {
            "label": "Medium"
          },
          "options__3": {
            "label": "Large"
          },
          "options__4": {
            "label": "Extra large"
          }
        }
      },
      "announcement-bar": {
        "name": "Announcement bar",
        "blocks": {
          "announcement": {
            "name": "Announcement",
            "settings": {
              "text": {
                "label": "Text"
              },
              "text_alignment": {
                "label": "Text alignment",
                "options__1": {
                  "label": "Left"
                },
                "options__2": {
                  "label": "Center"
                },
                "options__3": {
                  "label": "Right"
                }
              },
              "link": {
                "label": "Link"
              }
            }
          }
        }
      },
      "apps": {
        "name": "Apps",
        "settings": {
          "include_margins": {
            "label": "Make section margins the same as theme"
          }
        },
        "presets": {
          "name": "Apps"
        }
      },
      "collage": {
        "name": "Collage",
        "settings": {
          "heading": {
            "label": "Heading"
          },
          "desktop_layout": {
            "label": "Desktop layout",
            "options__1": {
              "label": "Left large block"
            },
            "options__2": {
              "label": "Right large block"
            }
          },
          "mobile_layout": {
            "label": "Mobile layout",
            "options__1": {
              "label": "Collage"
            },
            "options__2": {
              "label": "Column"
            }
          },
          "card_styles": {
            "label": "Card style",
            "info": "Product, collection, and blog card styles can be updated in theme settings.",
            "options__1": {
              "label": "Use individual card styles"
            },
            "options__2": {
              "label": "Style all as product cards"
            }
          }
        },
        "blocks": {
          "image": {
            "name": "Image",
            "settings": {
              "image": {
                "label": "Image"
              },
              "image_padding": {
                "label": "Use original image ratio",
                "info": "Select if you don't want your image to be cropped."
              }
            }
          },
          "product": {
            "name": "Product",
            "settings": {
              "product": {
                "label": "Product"
              },
              "secondary_background": {
                "label": "Show secondary background"
              },
              "second_image": {
                "label": "Show second image on hover"
              }
            }
          },
          "collection": {
            "name": "Collection",
            "settings": {
              "collection": {
                "label": "Collection"
              }
            }
          },
          "video": {
            "name": "Video",
            "settings": {
              "cover_image": {
                "label": "Cover image"
              },
              "video_url": {
                "label": "URL",
                "info": "Video plays in a pop-up if the section contains other blocks.",
                "placeholder": "Use a YouTube or Vimeo URL"
              },
              "image_padding": {
                "label": "Use original image ratio",
                "info": "Select if you don't want your image to be cropped."
              },
              "description": {
                "label": "Video alt text",
                "info": "Describe the video for customers using screen readers. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/theme-features#video-block)"
              }
            }
          }
        },
        "presets": {
          "name": "Collage"
        }
      },
      "collection-list": {
        "name": "Collection list",
        "settings": {
          "title": {
            "label": "Heading"
          },
          "image_ratio": {
            "label": "Image ratio",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Portrait"
            },
            "options__3": {
              "label": "Square"
            },
            "info": "Add images by editing your collections. [Learn more](https://help.shopify.com/manual/products/collections)"
          },
          "columns_desktop": {
            "label": "Number of columns on desktop"
          },
          "show_view_all": {
            "label": "Enable \"View all\" button if list includes more collections than shown"
          },
          "header_mobile": {
            "content": "Mobile Layout"
          },
          "columns_mobile": {
            "label": "Number of columns on mobile",
            "options__1": {
              "label": "1 column"
            },
            "options__2": {
              "label": "2 columns"
            }
          },
          "swipe_on_mobile": {
            "label": "Enable swipe on mobile"
          }
        },
        "blocks": {
          "featured_collection": {
            "name": "Collection",
            "settings": {
              "collection": {
                "label": "Collection"
              }
            }
          }
        },
        "presets": {
          "name": "Collection list"
        }
      },
      "contact-form": {
        "name": "Contact Form",
        "presets": {
          "name": "Contact form"
        }
      },
      "custom-liquid": {
        "name": "Custom Liquid",
        "settings": {
          "custom_liquid": {
            "label": "Custom Liquid",
            "info": "Add app snippets or other Liquid code to create advanced customizations."
          }
        },
        "presets": {
          "name": "Custom Liquid"
        }
      },
      "featured-blog": {
        "name": "Blog posts",
        "settings": {
          "heading": {
            "label": "Heading"
          },
          "blog": {
            "label": "Blog"
          },
          "post_limit": {
            "label": "Number of blog posts to show"
          },
          "columns_desktop": {
            "label": "Number of columns on desktop"
          },
          "show_view_all": {
            "label": "Enable \"View all\" button if blog includes more blog posts than shown"
          },
          "show_image": {
            "label": "Show featured image",
            "info": "For best results, use an image with a 3:2 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
          },
          "show_date": {
            "label": "Show date"
          },
          "show_author": {
            "label": "Show author"
          }
        },
        "presets": {
          "name": "Blog posts"
        }
      },
      "featured-collection": {
        "name": "Featured collection",
        "settings": {
          "title": {
            "label": "Heading"
          },
          "description": {
            "label": "Description"
          },
          "show_description": {
            "label": "Show collection description from the admin"
          },
          "description_style": {
            "label": "Description style",
            "options__1": {
              "label": "Body"
            },
            "options__2": {
              "label": "Subtitle"
            },
            "options__3": {
              "label": "Uppercase"
            }
          },
          "collection": {
            "label": "Collection"
          },
          "products_to_show": {
            "label": "Maximum products to show"
          },
          "columns_desktop": {
            "label": "Number of columns on desktop"
          },
          "show_view_all": {
            "label": "Enable \"View all\" if collection has more products than shown"
          },
          "view_all_style": {
            "label": "\"View all\" style",
            "options__1": {
              "label": "Link"
            },
            "options__2": {
              "label": "Outline button"
            },
            "options__3": {
              "label": "Solid button"
            }
          },
          "enable_desktop_slider": {
            "label": "Enable carousel on desktop"
          },
          "full_width": {
            "label": "Make products full width"
          },
          "header": {
            "content": "Product card"
          },
          "image_ratio": {
            "label": "Image ratio",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Portrait"
            },
            "options__3": {
              "label": "Square"
            }
          },
          "show_secondary_image": {
            "label": "Show second image on hover"
          },
          "show_vendor": {
            "label": "Show vendor"
          },
          "show_rating": {
            "label": "Show product rating",
            "info": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/theme-features#featured-collection-show-product-rating)"
          },
          "enable_quick_buy": {
            "label": "Enable quick add button",
            "info": "Optimal with popup or drawer cart type."
          },
          "header_mobile": {
            "content": "Mobile Layout"
          },
          "columns_mobile": {
            "label": "Number of columns on mobile",
            "options__1": {
              "label": "1 column"
            },
            "options__2": {
              "label": "2 columns"
            }
          },
          "swipe_on_mobile": {
            "label": "Enable swipe on mobile"
          }
        },
        "presets": {
          "name": "Featured collection"
        }
      },
      "featured-product": {
        "name": "Featured product",
        "blocks": {
          "text": {
            "name": "Text",
            "settings": {
              "text": {
                "label": "Text"
              },
              "text_style": {
                "label": "Text style",
                "options__1": {
                  "label": "Body"
                },
                "options__2": {
                  "label": "Subtitle"
                },
                "options__3": {
                  "label": "Uppercase"
                }
              }
            }
          },
          "title": {
            "name": "Title"
          },
          "price": {
            "name": "Price"
          },
          "quantity_selector": {
            "name": "Quantity selector"
          },
          "variant_picker": {
            "name": "Variant picker",
            "settings": {
              "picker_type": {
                "label": "Type",
                "options__1": {
                  "label": "Dropdown"
                },
                "options__2": {
                  "label": "Pills"
                }
              }
            }
          },
          "buy_buttons": {
            "name": "Buy buttons",
            "settings": {
              "show_dynamic_checkout": {
                "label": "Show dynamic checkout buttons",
                "info": "Using the payment methods available on your store, customers see their preferred option, like PayPal or Apple Pay. [Learn more](https://help.shopify.com/manual/using-themes/change-the-layout/dynamic-checkout)"
              }
            }
          },
          "description": {
            "name": "Description"
          },
          "share": {
            "name": "Share",
            "settings": {
              "text": {
                "label": "Text"
              },
              "featured_image_info": {
                "content": "If you include a link in social media posts, the page’s featured image will be shown as the preview image. [Learn more](https://help.shopify.com/manual/online-store/images/showing-social-media-thumbnail-images)"
              },
              "title_info": {
                "content": "A store title and description are included with the preview image. [Learn more](https://help.shopify.com/manual/promoting-marketing/seo/adding-keywords#set-a-title-and-description-for-your-online-store)"
              }
            }
          },
          "custom_liquid": {
            "name": "Custom liquid",
            "settings": {
              "custom_liquid": {
                "label": "Custom liquid"
              }
            }
          },
          "rating": {
            "name": "Product rating",
            "settings": {
              "paragraph": {
                "content": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/theme-features#featured-product-rating)"
              }
            }
          }
        },
        "settings": {
          "product": {
            "label": "Product"
          },
          "secondary_background": {
            "label": "Show secondary background"
          },
          "header": {
            "content": "Media",
            "info": "Learn more about [media types](https://help.shopify.com/manual/products/product-media)"
          },
          "media_position": {
            "label": "Desktop media position",
            "info": "Position is automatically optimized for mobile.",
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Right"
            }
          },
          "hide_variants": {
            "label": "Hide unselected variants’ media on desktop"
          },
          "enable_video_looping": {
            "label": "Enable video looping"
          }
        },
        "presets": {
          "name": "Featured product"
        }
      },
      "footer": {
        "name": "Footer",
        "blocks": {
          "link_list": {
            "name": "Menu",
            "settings": {
              "heading": {
                "label": "Heading"
              },
              "menu": {
                "label": "Menu",
                "info": "Displays only top-level menu items."
              }
            }
          },
          "text": {
            "name": "Text",
            "settings": {
              "heading": {
                "label": "Heading"
              },
              "subtext": {
                "label": "Subtext"
              }
            }
          }
        },
        "settings": {
          "newsletter_enable": {
            "label": "Show email signup"
          },
          "newsletter_heading": {
            "label": "Heading"
          },
          "header__1": {
            "content": "Email Signup",
            "info": "Subscribers added automatically to your “accepted marketing” customer list. [Learn more](https://help.shopify.com/manual/customers/manage-customers)"
          },
          "header__2": {
            "content": "Social media icons",
            "info": "To display your social media accounts, link them in your theme settings."
          },
          "show_social": {
            "label": "Show social media icons"
          },
          "header__3": {
            "content": "Country/region selector"
          },
          "header__4": {
            "info": "To add a country/region, go to your [payment settings.](/admin/settings/payments)"
          },
          "enable_country_selector": {
            "label": "Enable country/region selector"
          },
          "header__5": {
            "content": "Language selector"
          },
          "header__6": {
            "info": "To add a language, go to your [language settings.](/admin/settings/languages)"
          },
          "enable_language_selector": {
            "label": "Enable language selector"
          },
          "header__7": {
            "content": "Payment methods"
          },
          "payment_enable": {
            "label": "Show payment icons"
          },
          "margin_top": {
            "label": "Top margin"
          }
        }
      },
      "header": {
        "name": "Header",
        "settings": {
          "logo": {
            "label": "Logo image"
          },
          "logo_width": {
            "unit": "px",
            "label": "Custom logo width"
          },
          "logo_position": {
            "label": "Desktop logo position",
            "options__1": {
              "label": "Middle left"
            },
            "options__2": {
              "label": "Top left"
            },
            "options__3": {
              "label": "Top center"
            },
            "options__4": {
              "label": "Middle center"
            }
          },
          "menu": {
            "label": "Menu"
          },
          "menu_type_desktop": {
            "label": "Desktop menu type",
            "info": "Menu type is automatically optimized for mobile.",
            "options__1": {
              "label": "Dropdown"
            },
            "options__2": {
              "label": "Mega menu"
            }
          },
          "show_line_separator": {
            "label": "Show separator line"
          },
          "enable_sticky_header": {
            "label": "Enable sticky header",
            "info": "Header shows on the screen as customers scroll up."
          },
          "margin_bottom": {
            "label": "Bottom margin"
          },
          "mobile_layout": {
            "content": "Mobile layout"
          },
          "mobile_logo_position": {
            "label": "Mobile logo position",
            "options__1": {
              "label": "Center"
            },
            "options__2": {
              "label": "Left"
            }
          }
        }
      },
      "image-banner": {
        "name": "Image banner",
        "settings": {
          "image": {
            "label": "First image"
          },
          "image_2": {
            "label": "Second image"
          },
          "image_overlay_opacity": {
            "label": "Image overlay opacity"
          },
          "image_height": {
            "label": "Banner height",
            "options__1": {
              "label": "Small"
            },
            "options__2": {
              "label": "Medium"
            },
            "options__3": {
              "label": "Large"
            },
            "info": "For best results, use an image with a 3:2 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
          },
          "desktop_content_position": {
            "options__1": {
              "label": "Top Left"
            },
            "options__2": {
              "label": "Top Center"
            },
            "options__3": {
              "label": "Top Right"
            },
            "options__4": {
              "label": "Middle Left"
            },
            "options__5": {
              "label": "Middle Center"
            },
            "options__6": {
              "label": "Middle Right"
            },
            "options__7": {
              "label": "Bottom Left"
            },
            "options__8": {
              "label": "Bottom Center"
            },
            "options__9": {
              "label": "Bottom Right"
            },
            "label": "Desktop content position"
          },
          "show_text_box": {
            "label": "Show container on desktop"
          },
          "desktop_content_alignment": {
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            },
            "label": "Desktop content alignment"
          },
          "color_scheme": {
            "info": "Visible when container displayed."
          },
          "header": {
            "content": "Mobile Layout"
          },
          "mobile_content_alignment": {
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            },
            "label": "Mobile content alignment"
          },
          "stack_images_on_mobile": {
            "label": "Stack images on mobile"
          },
          "show_text_below": {
            "label": "Show container on mobile"
          },
          "adapt_height_first_image": {
            "label": "Adapt section height to first image size",
            "info": "Overwrites image banner height setting when checked."
          }
        },
        "blocks": {
          "heading": {
            "name": "Heading",
            "settings": {
              "heading": {
                "label": "Heading"
              }
            }
          },
          "text": {
            "name": "Text",
            "settings": {
              "text": {
                "label": "Description"
              },
              "text_style": {
                "options__1": {
                  "label": "Body"
                },
                "options__2": {
                  "label": "Subtitle"
                },
                "options__3": {
                  "label": "Uppercase"
                },
                "label": "Text style"
              }
            }
          },
          "buttons": {
            "name": "Buttons",
            "settings": {
              "button_label_1": {
                "label": "First button label",
                "info": "Leave the label blank to hide the button."
              },
              "button_link_1": {
                "label": "First button link"
              },
              "button_style_secondary_1": {
                "label": "Use outline button style"
              },
              "button_label_2": {
                "label": "Second button label",
                "info": "Leave the label blank to hide the button."
              },
              "button_link_2": {
                "label": "Second button link"
              },
              "button_style_secondary_2": {
                "label": "Use outline button style"
              }
            }
          }
        },
        "presets": {
          "name": "Image banner"
        }
      },
      "image-with-text": {
        "name": "Image with text",
        "settings": {
          "image": {
            "label": "Image"
          },
          "height": {
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Small"
            },
            "options__3": {
              "label": "Large"
            },
            "label": "Image height"
          },
          "desktop_image_width": {
            "options__1": {
              "label": "Small"
            },
            "options__2": {
              "label": "Medium"
            },
            "options__3": {
              "label": "Large"
            },
            "label": "Desktop image width",
            "info": "Image is automatically optimized for mobile."
          },
          "layout": {
            "options__1": {
              "label": "Image first"
            },
            "options__2": {
              "label": "Image second"
            },
            "label": "Desktop image placement",
            "info": "Image first is the default mobile layout."
          },
          "desktop_content_alignment": {
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            },
            "label": "Desktop content alignment"
          },
          "desktop_content_position": {
            "options__1": {
              "label": "Top"
            },
            "options__2": {
              "label": "Middle"
            },
            "options__3": {
              "label": "Bottom"
            },
            "label": "Desktop content position"
          },
          "content_layout": {
            "options__1": {
              "label": "No overlap"
            },
            "options__2": {
              "label": "Overlap"
            },
            "label": "Content layout"
          },
          "mobile_content_alignment": {
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            },
            "label": "Mobile content alignment"
          }
        },
        "blocks": {
          "heading": {
            "name": "Heading",
            "settings": {
              "heading": {
                "label": "Heading"
              }
            }
          },
          "caption": {
            "name": "Caption",
            "settings": {
              "text": {
                "label": "Text"
              },
              "text_style": {
                "label": "Text style",
                "options__1": {
                  "label": "Subtitle"
                },
                "options__2": {
                  "label": "Uppercase"
                }
              },
              "caption_size": {
                "label": "Text size",
                "options__1": {
                  "label": "Small"
                },
                "options__2": {
                  "label": "Medium"
                },
                "options__3": {
                  "label": "Large"
                }
              }
            }
          },
          "text": {
            "name": "Text",
            "settings": {
              "text": {
                "label": "Content"
              },
              "text_style": {
                "label": "Text style",
                "options__1": {
                  "label": "Body"
                },
                "options__2": {
                  "label": "Subtitle"
                }
              }
            }
          },
          "button": {
            "name": "Button",
            "settings": {
              "button_label": {
                "label": "Button label",
                "info": "Leave the label blank to hide the button."
              },
              "button_link": {
                "label": "Button link"
              }
            }
          }
        },
        "presets": {
          "name": "Image with text"
        }
      },
      "main-account": {
        "name": "Account"
      },
      "main-activate-account": {
        "name": "Account activation"
      },
      "main-addresses": {
        "name": "Addresses"
      },
      "main-article": {
        "name": "Blog post",
        "blocks": {
          "featured_image": {
            "name": "Featured image",
            "settings": {
              "image_height": {
                "label": "Featured image height",
                "options__1": {
                  "label": "Adapt to image"
                },
                "options__2": {
                  "label": "Small"
                },
                "options__3": {
                  "label": "Medium"
                },
                "options__4": {
                  "label": "Large"
                },
                "info": "For best results, use an image with a 16:9 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
              }
            }
          },
          "title": {
            "name": "Title",
            "settings": {
              "blog_show_date": {
                "label": "Show date"
              },
              "blog_show_author": {
                "label": "Show author"
              }
            }
          },
          "content": {
            "name": "Content"
          },
          "share": {
            "name": "Share",
            "settings": {
              "text": {
                "label": "Text"
              },
              "featured_image_info": {
                "content": "If you include a link in social media posts, the page’s featured image will be shown as the preview image. [Learn more](https://help.shopify.com/manual/online-store/images/showing-social-media-thumbnail-images)."
              },
              "title_info": {
                "content": "A store title and description are included with the preview image. [Learn more](https://help.shopify.com/manual/promoting-marketing/seo/adding-keywords#set-a-title-and-description-for-your-online-store)."
              }
            }
          }
        }
      },
      "main-blog": {
        "name": "Blog posts",
        "settings": {
          "header": {
            "content": "Blog post card"
          },
          "show_image": {
            "label": "Show featured image"
          },
          "show_date": {
            "label": "Show date"
          },
          "show_author": {
            "label": "Show author"
          },
          "paragraph": {
            "content": "Change excerpts by editing your blog posts. [Learn more](https://help.shopify.com/manual/online-store/blogs/writing-blogs#display-an-excerpt-from-a-blog-post)"
          },
          "layout": {
            "label": "Desktop layout",
            "options__1": {
              "label": "Grid"
            },
            "options__2": {
              "label": "Collage"
            },
            "info": "Posts are stacked on mobile."
          },
          "image_height": {
            "label": "Featured image height",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Small"
            },
            "options__3": {
              "label": "Medium"
            },
            "options__4": {
              "label": "Large"
            },
            "info": "For best results, use an image with a 3:2 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
          }
        }
      },
      "main-cart-footer": {
        "name": "Subtotal",
        "blocks": {
          "subtotal": {
            "name": "Subtotal price"
          },
          "buttons": {
            "name": "Checkout button"
          }
        }
      },
      "main-cart-items": {
        "name": "Items"
      },
      "main-collection-banner": {
        "name": "Collection banner",
        "settings": {
          "paragraph": {
            "content": "Add a description or image by editing your collection. [Learn more](https://help.shopify.com/manual/products/collections/collection-layout)"
          },
          "show_collection_description": {
            "label": "Show collection description"
          },
          "show_collection_image": {
            "label": "Show collection image",
            "info": "For best results, use an image with a 16:9 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
          }
        }
      },
      "main-collection-product-grid": {
        "name": "Product grid",
        "settings": {
          "products_per_page": {
            "label": "Products per page"
          },
          "columns_desktop": {
            "label": "Number of columns on desktop"
          },
          "enable_filtering": {
            "label": "Enable filtering",
            "info": "Customize [filters](/admin/menus)"
          },
          "filter_type": {
            "label": "Desktop filter layout",
            "options__1": {
              "label": "Horizontal"
            },
            "options__2": {
              "label": "Vertical"
            },
            "options__3": {
              "label": "Drawer"
            },
            "info": "Drawer is the default mobile layout."
          },
          "enable_sorting": {
            "label": "Enable sorting"
          },
          "image_ratio": {
            "label": "Image ratio",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Portrait"
            },
            "options__3": {
              "label": "Square"
            }
          },
          "show_secondary_image": {
            "label": "Show second image on hover"
          },
          "show_vendor": {
            "label": "Show vendor"
          },
          "show_rating": {
            "label": "Show product rating",
            "info": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/page-types#product-grid-section-settings)"
          },
          "header__1": {
            "content": "Filtering and sorting"
          },
          "header__3": {
            "content": "Product card"
          },
          "enable_tags": {
            "label": "Enable filtering",
            "info": "[Customize filters](/admin/menus)"
          },
          "enable_quick_buy": {
            "label": "Enable quick add button",
            "info": "Optimal with popup or drawer cart type."
          },
          "header_mobile": {
            "content": "Mobile Layout"
          },
          "columns_mobile": {
            "label": "Number of columns on mobile",
            "options__1": {
              "label": "1 column"
            },
            "options__2": {
              "label": "2 columns"
            }
          }
        }
      },
      "main-list-collections": {
        "name": "Collections list page",
        "settings": {
          "title": {
            "label": "Heading"
          },
          "sort": {
            "label": "Sort collections by:",
            "options__1": {
              "label": "Alphabetically, A-Z"
            },
            "options__2": {
              "label": "Alphabetically, Z-A"
            },
            "options__3": {
              "label": "Date, new to old"
            },
            "options__4": {
              "label": "Date, old to new"
            },
            "options__5": {
              "label": "Product count, high to low"
            },
            "options__6": {
              "label": "Product count, low to high"
            }
          },
          "image_ratio": {
            "label": "Image ratio",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Portrait"
            },
            "options__3": {
              "label": "Square"
            },
            "info": "Add images by editing your collections. [Learn more](https://help.shopify.com/manual/products/collections)"
          },
          "columns_desktop": {
            "label": "Number of columns on desktop"
          },
          "header_mobile": {
            "content": "Mobile Layout"
          },
          "columns_mobile": {
            "label": "Number of columns on mobile",
            "options__1": {
              "label": "1 column"
            },
            "options__2": {
              "label": "2 columns"
            }
          }
        }
      },
      "main-login": {
        "name": "Login"
      },
      "main-order": {
        "name": "Order"
      },
      "main-page": {
        "name": "Page"
      },
      "main-password-footer": {
        "name": "Password footer"
      },
      "main-password-header": {
        "name": "Password header",
        "settings": {
          "logo": {
            "label": "Logo image"
          },
          "logo_max_width": {
            "label": "Custom logo width",
            "unit": "px"
          }
        }
      },
      "main-product": {
        "name": "Product information",
        "blocks": {
          "text": {
            "name": "Text",
            "settings": {
              "text": {
                "label": "Text"
              },
              "text_style": {
                "label": "Text style",
                "options__1": {
                  "label": "Body"
                },
                "options__2": {
                  "label": "Subtitle"
                },
                "options__3": {
                  "label": "Uppercase"
                }
              }
            }
          },
          "title": {
            "name": "Title"
          },
          "price": {
            "name": "Price"
          },
          "quantity_selector": {
            "name": "Quantity selector"
          },
          "variant_picker": {
            "name": "Variant picker",
            "settings": {
              "picker_type": {
                "label": "Type",
                "options__1": {
                  "label": "Dropdown"
                },
                "options__2": {
                  "label": "Pills"
                }
              }
            }
          },
          "buy_buttons": {
            "name": "Buy buttons",
            "settings": {
              "show_dynamic_checkout": {
                "label": "Show dynamic checkout buttons",
                "info": "Using the payment methods available on your store, customers see their preferred option, like PayPal or Apple Pay. [Learn more](https://help.shopify.com/manual/using-themes/change-the-layout/dynamic-checkout)"
              }
            }
          },
          "pickup_availability": {
            "name": "Pickup availability"
          },
          "description": {
            "name": "Description"
          },
          "share": {
            "name": "Share",
            "settings": {
              "text": {
                "label": "Text"
              },
              "featured_image_info": {
                "content": "If you include a link in social media posts, the page’s featured image will be shown as the preview image. [Learn more](https://help.shopify.com/manual/online-store/images/showing-social-media-thumbnail-images)."
              },
              "title_info": {
                "content": "A store title and description are included with the preview image. [Learn more](https://help.shopify.com/manual/promoting-marketing/seo/adding-keywords#set-a-title-and-description-for-your-online-store)."
              }
            }
          },
          "custom_liquid": {
            "name": "Custom liquid",
            "settings": {
              "custom_liquid": {
                "label": "Custom liquid",
                "info": "Add app snippets or other Liquid code to create advanced customizations."
              }
            }
          },
          "collapsible_tab": {
            "name": "Collapsible row",
            "settings": {
              "heading": {
                "info": "Include a heading that explains the content.",
                "label": "Heading"
              },
              "content": {
                "label": "Row content"
              },
              "page": {
                "label": "Row content from page"
              },
              "icon": {
                "label": "Icon",
                "options__1": {
                  "label": "None"
                },
                "options__2": {
                  "label": "Apple"
                },
                "options__3": {
                  "label": "Banana"
                },
                "options__4": {
                  "label": "Bottle"
                },
                "options__5": {
                  "label": "Box"
                },
                "options__6": {
                  "label": "Carrot"
                },
                "options__7": {
                  "label": "Chat bubble"
                },
                "options__8": {
                  "label": "Check mark"
                },
                "options__9": {
                  "label": "Clipboard"
                },
                "options__10": {
                  "label": "Dairy"
                },
                "options__11": {
                  "label": "Dairy free"
                },
                "options__12": {
                  "label": "Dryer"
                },
                "options__13": {
                  "label": "Eye"
                },
                "options__14": {
                  "label": "Fire"
                },
                "options__15": {
                  "label": "Gluten free"
                },
                "options__16": {
                  "label": "Heart"
                },
                "options__17": {
                  "label": "Iron"
                },
                "options__18": {
                  "label": "Leaf"
                },
                "options__19": {
                  "label": "Leather"
                },
                "options__20": {
                  "label": "Lightning bolt"
                },
                "options__21": {
                  "label": "Lipstick"
                },
                "options__22": {
                  "label": "Lock"
                },
                "options__23": {
                  "label": "Map pin"
                },
                "options__24": {
                  "label": "Nut free"
                },
                "options__25": {
                  "label": "Pants"
                },
                "options__26": {
                  "label": "Paw print"
                },
                "options__27": {
                  "label": "Pepper"
                },
                "options__28": {
                  "label": "Perfume"
                },
                "options__29": {
                  "label": "Plane"
                },
                "options__30": {
                  "label": "Plant"
                },
                "options__31": {
                  "label": "Price tag"
                },
                "options__32": {
                  "label": "Question mark"
                },
                "options__33": {
                  "label": "Recycle"
                },
                "options__34": {
                  "label": "Return"
                },
                "options__35": {
                  "label": "Ruler"
                },
                "options__36": {
                  "label": "Serving dish"
                },
                "options__37": {
                  "label": "Shirt"
                },
                "options__38": {
                  "label": "Shoe"
                },
                "options__39": {
                  "label": "Silhouette"
                },
                "options__40": {
                  "label": "Snowflake"
                },
                "options__41": {
                  "label": "Star"
                },
                "options__42": {
                  "label": "Stopwatch"
                },
                "options__43": {
                  "label": "Truck"
                },
                "options__44": {
                  "label": "Washing"
                }
              }
            }
          },
          "popup": {
            "name": "Pop-up",
            "settings": {
              "link_label": {
                "label": "Link label"
              },
              "page": {
                "label": "Page"
              }
            }
          },
          "rating": {
            "name": "Product rating",
            "settings": {
              "paragraph": {
                "content": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/page-types#product-rating-block)"
              }
            }
          },
          "complementary_products": {
            "name": "Complementary products",
            "settings": {
              "paragraph": {
                "content": "To select complementary products, add the Search & Discovery app. [Learn more](https://shopify.dev/themes/product-merchandising/recommendations)"
              },
              "heading": {
                "label": "Heading"
              },
              "make_collapsible_row": {
                "label": "Show as collapsible row"
              },
              "icon": {
                "info": "Visible when collapsible row is displayed."
              },
              "product_list_limit": {
                "label": "Maximum products to show"
              },
              "products_per_page": {
                "label": "Number of products per page"
              },
              "pagination_style": {
                "label": "Pagination style",
                "options": {
                  "option_1": "Dots",
                  "option_2": "Counter",
                  "option_3": "Numbers"
                }
              },
              "product_card": {
                "heading": "Product card"
              },
              "image_ratio": {
                "label": "Image ratio",
                "options": {
                  "option_1": "Portrait",
                  "option_2": "Square"
                }
              },
              "enable_quick_add": {
                "label": "Enable quick add button"
              }
            }
          }
        },
        "settings": {
          "header": {
            "content": "Media",
            "info": "Learn more about [media types.](https://help.shopify.com/manual/products/product-media)"
          },
          "enable_sticky_info": {
            "label": "Enable sticky content on desktop"
          },
          "gallery_layout": {
            "label": "Desktop layout",
            "options__1": {
              "label": "Stacked"
            },
            "options__2": {
              "label": "2 columns"
            },
            "options__3": {
              "label": "Thumbnails"
            },
            "options__4": {
              "label": "Thumbnail carousel"
            }
          },
          "media_size": {
            "label": "Desktop media size",
            "info": "Media is automatically optimized for mobile.",
            "options__1": {
              "label": "Small"
            },
            "options__2": {
              "label": "Medium"
            },
            "options__3": {
              "label": "Large"
            }
          },
          "media_position": {
            "label": "Desktop media position",
            "info": "Position is automatically optimized for mobile.",
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Right"
            }
          },
          "mobile_thumbnails": {
            "label": "Mobile layout",
            "options__1": {
              "label": "2 columns"
            },
            "options__2": {
              "label": "Show thumbnails"
            },
            "options__3": {
              "label": "Hide thumbnails"
            }
          },
          "hide_variants": {
            "label": "Hide other variants’ media after selecting a variant"
          },
          "enable_video_looping": {
            "label": "Enable video looping"
          }
        }
      },
      "main-register": {
        "name": "Registration"
      },
      "main-reset-password": {
        "name": "Password reset"
      },
      "main-search": {
        "name": "Search results",
        "settings": {
          "columns_desktop": {
            "label": "Number of columns on desktop"
          },
          "image_ratio": {
            "label": "Image ratio",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Portrait"
            },
            "options__3": {
              "label": "Square"
            }
          },
          "show_secondary_image": {
            "label": "Show second image on hover"
          },
          "show_vendor": {
            "label": "Show vendor"
          },
          "show_rating": {
            "label": "Show product rating",
            "info": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/page-types#search-results-section-settings)"
          },
          "header__1": {
            "content": "Product card"
          },
          "header__2": {
            "content": "Blog card",
            "info": "Blog card styles also apply to page cards in search results. To change card styles update your theme settings."
          },
          "article_show_date": {
            "label": "Show date"
          },
          "article_show_author": {
            "label": "Show author"
          },
          "header_mobile": {
            "content": "Mobile Layout"
          },
          "columns_mobile": {
            "label": "Number of columns on mobile",
            "options__1": {
              "label": "1 column"
            },
            "options__2": {
              "label": "2 columns"
            }
          }
        }
      },
      "multicolumn": {
        "name": "Multicolumn",
        "settings": {
          "title": {
            "label": "Heading"
          },
          "image_width": {
            "label": "Image width",
            "options__1": {
              "label": "One-third width of column"
            },
            "options__2": {
              "label": "Half width of column"
            },
            "options__3": {
              "label": "Full width of column"
            }
          },
          "image_ratio": {
            "label": "Image ratio",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Portrait"
            },
            "options__3": {
              "label": "Square"
            },
            "options__4": {
              "label": "Circle"
            }
          },
          "columns_desktop": {
            "label": "Number of columns on desktop"
          },
          "column_alignment": {
            "label": "Column alignment",
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            }
          },
          "background_style": {
            "label": "Secondary background",
            "options__1": {
              "label": "None"
            },
            "options__2": {
              "label": "Show as column background"
            }
          },
          "button_label": {
            "label": "Button label"
          },
          "button_link": {
            "label": "Button link"
          },
          "header_mobile": {
            "content": "Mobile Layout"
          },
          "columns_mobile": {
            "label": "Number of columns on mobile",
            "options__1": {
              "label": "1 column"
            },
            "options__2": {
              "label": "2 columns"
            }
          },
          "swipe_on_mobile": {
            "label": "Enable swipe on mobile"
          }
        },
        "blocks": {
          "column": {
            "name": "Column",
            "settings": {
              "image": {
                "label": "Image"
              },
              "title": {
                "label": "Heading"
              },
              "text": {
                "label": "Description"
              },
              "link_label": {
                "label": "Link label"
              },
              "link": {
                "label": "Link"
              }
            }
          }
        },
        "presets": {
          "name": "Multicolumn"
        }
      },
      "newsletter": {
        "name": "Email signup",
        "settings": {
          "full_width": {
            "label": "Make section full width"
          },
          "paragraph": {
            "content": "Each email subscription creates a customer account. [Learn more](https://help.shopify.com/manual/customers)"
          }
        },
        "blocks": {
          "heading": {
            "name": "Heading",
            "settings": {
              "heading": {
                "label": "Heading"
              }
            }
          },
          "paragraph": {
            "name": "Subheading",
            "settings": {
              "paragraph": {
                "label": "Description"
              }
            }
          },
          "email_form": {
            "name": "Email form"
          }
        },
        "presets": {
          "name": "Email signup"
        }
      },
      "email-signup-banner": {
        "name": "Email signup banner",
        "settings": {
          "paragraph": {
            "content": "Each email subscription creates a customer account. [Learn more](https://help.shopify.com/manual/customers)"
          },
          "image": {
            "label": "Background image"
          },
          "image_overlay_opacity": {
            "label": "Image overlay opacity"
          },
          "show_background_image": {
            "label": "Show background image"
          },
          "show_text_box": {
            "label": "Show container on desktop"
          },
          "desktop_content_position": {
            "options__1": {
              "label": "Top Left"
            },
            "options__2": {
              "label": "Top Center"
            },
            "options__3": {
              "label": "Top Right"
            },
            "options__4": {
              "label": "Middle Left"
            },
            "options__5": {
              "label": "Middle Center"
            },
            "options__6": {
              "label": "Middle Right"
            },
            "options__7": {
              "label": "Bottom Left"
            },
            "options__8": {
              "label": "Bottom Center"
            },
            "options__9": {
              "label": "Bottom Right"
            },
            "label": "Desktop content position"
          },
          "color_scheme": {
            "info": "Visible when container displayed."
          },
          "image_height": {
            "label": "Banner height",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Small"
            },
            "options__3": {
              "label": "Medium"
            },
            "options__4": {
              "label": "Large"
            },
            "info": "For best results, use an image with a 16:9 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
          },
          "desktop_content_alignment": {
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            },
            "label": "Desktop content alignment"
          },
          "header": {
            "content": "Mobile Layout"
          },
          "mobile_content_alignment": {
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            },
            "label": "Mobile content alignment"
          },
          "show_text_below": {
            "label": "Show content below image on mobile",
            "info": "For best results, use an image with a 16:9 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
          }
        },
        "blocks": {
          "heading": {
            "name": "Heading",
            "settings": {
              "heading": {
                "label": "Heading"
              }
            }
          },
          "paragraph": {
            "name": "Paragraph",
            "settings": {
              "paragraph": {
                "label": "Description"
              },
              "text_style": {
                "options__1": {
                  "label": "Body"
                },
                "options__2": {
                  "label": "Subtitle"
                },
                "label": "Text style"
              }
            }
          },
          "email_form": {
            "name": "Email form"
          }
        },
        "presets": {
          "name": "Email signup banner"
        }
      },
      "page": {
        "name": "Page",
        "settings": {
          "page": {
            "label": "Page"
          }
        },
        "presets": {
          "name": "Page"
        }
      },
      "product-recommendations": {
        "name": "Product recommendations",
        "settings": {
          "heading": {
            "label": "Heading"
          },
          "products_to_show": {
            "label": "Maximum products to show"
          },
          "columns_desktop": {
            "label": "Number of columns on desktop"
          },
          "paragraph__1": {
            "content": "Dynamic recommendations use order and product information to change and improve over time. [Learn more](https://help.shopify.com/themes/development/recommended-products)"
          },
          "header__2": {
            "content": "Product card"
          },
          "image_ratio": {
            "label": "Image ratio",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Portrait"
            },
            "options__3": {
              "label": "Square"
            }
          },
          "show_secondary_image": {
            "label": "Show second image on hover"
          },
          "show_vendor": {
            "label": "Show vendor"
          },
          "show_rating": {
            "label": "Show product rating",
            "info": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/page-types#product-recommendations-section-settings)"
          },
          "header_mobile": {
            "content": "Mobile Layout"
          },
          "columns_mobile": {
            "label": "Number of columns on mobile",
            "options__1": {
              "label": "1 column"
            },
            "options__2": {
              "label": "2 columns"
            }
          }
        }
      },
      "rich-text": {
        "name": "Rich text",
        "settings": {
          "desktop_content_position": {
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            },
            "label": "Desktop content position",
            "info": "Position is automatically optimized for mobile."
          },
          "content_alignment": {
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            },
            "label": "Content alignment"
          },
          "full_width": {
            "label": "Make section full width"
          }
        },
        "blocks": {
          "heading": {
            "name": "Heading",
            "settings": {
              "heading": {
                "label": "Heading"
              }
            }
          },
          "caption": {
            "name": "Caption",
            "settings": {
              "text": {
                "label": "Text"
              },
              "text_style": {
                "label": "Text style",
                "options__1": {
                  "label": "Subtitle"
                },
                "options__2": {
                  "label": "Uppercase"
                }
              },
              "caption_size": {
                "label": "Text size",
                "options__1": {
                  "label": "Small"
                },
                "options__2": {
                  "label": "Medium"
                },
                "options__3": {
                  "label": "Large"
                }
              }
            }
          },
          "text": {
            "name": "Text",
            "settings": {
              "text": {
                "label": "Description"
              }
            }
          },
          "buttons": {
            "name": "Buttons",
            "settings": {
              "button_label_1": {
                "label": "First button label",
                "info": "Leave the label blank to hide the button."
              },
              "button_link_1": {
                "label": "First button link"
              },
              "button_style_secondary_1": {
                "label": "Use outline button style"
              },
              "button_label_2": {
                "label": "Second button label",
                "info": "Leave the label blank to hide the button."
              },
              "button_link_2": {
                "label": "Second button link"
              },
              "button_style_secondary_2": {
                "label": "Use outline button style"
              }
            }
          }
        },
        "presets": {
          "name": "Rich text"
        }
      },
      "video": {
        "name": "Video",
        "settings": {
          "heading": {
            "label": "Heading"
          },
          "cover_image": {
            "label": "Cover image"
          },
          "video_url": {
            "label": "URL",
            "placeholder": "Use a YouTube or Vimeo URL",
            "info": "Video plays in the page."
          },
          "description": {
            "label": "Video alt text",
            "info": "Describe the video for customers using screen readers. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/theme-features#video)"
          },
          "image_padding": {
            "label": "Add image padding",
            "info": "Select image padding if you don't want your cover image to be cropped."
          },
          "full_width": {
            "label": "Make section full width"
          }
        },
        "presets": {
          "name": "Video"
        }
      },
      "slideshow": {
        "name": "Slideshow",
        "settings": {
          "layout": {
            "label": "Layout",
            "options__1": {
              "label": "Full width"
            },
            "options__2": {
              "label": "Grid"
            }
          },
          "slide_height": {
            "label": "Slide height",
            "options__1": {
              "label": "Adapt to first image"
            },
            "options__2": {
              "label": "Small"
            },
            "options__3": {
              "label": "Medium"
            },
            "options__4": {
              "label": "Large"
            }
          },
          "slider_visual": {
            "label": "Pagination style",
            "options__1": {
              "label": "Counter"
            },
            "options__2": {
              "label": "Dots"
            },
            "options__3": {
              "label": "Numbers"
            }
          },
          "auto_rotate": {
            "label": "Auto-rotate slides"
          },
          "change_slides_speed": {
            "label": "Change slides every"
          },
          "mobile": {
            "content": "Mobile layout"
          },
          "show_text_below": {
            "label": "Show content below images on mobile"
          },
          "accessibility": {
            "content": "Accessibility",
            "label": "Slideshow description",
            "info": "Describe the slideshow for customers using screen readers."
          }
        },
        "blocks": {
          "slide": {
            "name": "Slide",
            "settings": {
              "image": {
                "label": "Image"
              },
              "heading": {
                "label": "Heading"
              },
              "subheading": {
                "label": "Subheading"
              },
              "button_label": {
                "label": "Button label",
                "info": "Leave the label blank to hide the button."
              },
              "link": {
                "label": "Button link"
              },
              "secondary_style": {
                "label": "Use outline button style"
              },
              "box_align": {
                "label": "Desktop content position",
                "info": "Position is automatically optimized for mobile.",
                "options__1": {
                  "label": "Top left"
                },
                "options__2": {
                  "label": "Top center"
                },
                "options__3": {
                  "label": "Top right"
                },
                "options__4": {
                  "label": "Middle left"
                },
                "options__5": {
                  "label": "Middle center"
                },
                "options__6": {
                  "label": "Middle right"
                },
                "options__7": {
                  "label": "Bottom left"
                },
                "options__8": {
                  "label": "Bottom center"
                },
                "options__9": {
                  "label": "Bottom right"
                }
              },
              "show_text_box": {
                "label": "Show container on desktop"
              },
              "text_alignment": {
                "label": "Desktop content alignment",
                "option_1": {
                  "label": "Left"
                },
                "option_2": {
                  "label": "Center"
                },
                "option_3": {
                  "label": "Right"
                }
              },
              "image_overlay_opacity": {
                "label": "Image overlay opacity"
              },
              "color_scheme": {
                "info": "Visible when container displayed."
              },
              "text_alignment_mobile": {
                "label": "Mobile content alignment",
                "options__1": {
                  "label": "Left"
                },
                "options__2": {
                  "label": "Center"
                },
                "options__3": {
                  "label": "Right"
                }
              }
            }
          }
        },
        "presets": {
          "name": "Slideshow"
        }
      },
      "collapsible_content": {
        "name": "Collapsible content",
        "settings": {
          "caption": {
            "label": "Caption"
          },
          "heading": {
            "label": "Heading"
          },
          "heading_alignment": {
            "label": "Heading alignment",
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            }
          },
          "layout": {
            "label": "Layout",
            "options__1": {
              "label": "No container"
            },
            "options__2": {
              "label": "Row container"
            },
            "options__3": {
              "label": "Section container"
            }
          },
          "container_color_scheme": {
            "label": "Container color scheme",
            "info": "Visible when Layout is set to Row or Section container."
          },
          "open_first_collapsible_row": {
            "label": "Open first collapsible row"
          },
          "header": {
            "content": "Image layout"
          },
          "image": {
            "label": "Image"
          },
          "image_ratio": {
            "label": "Image ratio",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Small"
            },
            "options__3": {
              "label": "Large"
            }
          },
          "desktop_layout": {
            "label": "Desktop layout",
            "options__1": {
              "label": "Image first"
            },
            "options__2": {
              "label": "Image second"
            },
            "info": "Image is always first on mobile."
          }
        },
        "blocks": {
          "collapsible_row": {
            "name": "Collapsible row",
            "settings": {
              "heading": {
                "info": "Include a heading that explains the content.",
                "label": "Heading"
              },
              "row_content": {
                "label": "Row content"
              },
              "page": {
                "label": "Row content from page"
              },
              "icon": {
                "label": "Icon",
                "options__1": {
                  "label": "None"
                },
                "options__2": {
                  "label": "Apple"
                },
                "options__3": {
                  "label": "Banana"
                },
                "options__4": {
                  "label": "Bottle"
                },
                "options__5": {
                  "label": "Box"
                },
                "options__6": {
                  "label": "Carrot"
                },
                "options__7": {
                  "label": "Chat bubble"
                },
                "options__8": {
                  "label": "Check mark"
                },
                "options__9": {
                  "label": "Clipboard"
                },
                "options__10": {
                  "label": "Dairy"
                },
                "options__11": {
                  "label": "Dairy free"
                },
                "options__12": {
                  "label": "Dryer"
                },
                "options__13": {
                  "label": "Eye"
                },
                "options__14": {
                  "label": "Fire"
                },
                "options__15": {
                  "label": "Gluten free"
                },
                "options__16": {
                  "label": "Heart"
                },
                "options__17": {
                  "label": "Iron"
                },
                "options__18": {
                  "label": "Leaf"
                },
                "options__19": {
                  "label": "Leather"
                },
                "options__20": {
                  "label": "Lightning bolt"
                },
                "options__21": {
                  "label": "Lipstick"
                },
                "options__22": {
                  "label": "Lock"
                },
                "options__23": {
                  "label": "Map pin"
                },
                "options__24": {
                  "label": "Nut free"
                },
                "options__25": {
                  "label": "Pants"
                },
                "options__26": {
                  "label": "Paw print"
                },
                "options__27": {
                  "label": "Pepper"
                },
                "options__28": {
                  "label": "Perfume"
                },
                "options__29": {
                  "label": "Plane"
                },
                "options__30": {
                  "label": "Plant"
                },
                "options__31": {
                  "label": "Price tag"
                },
                "options__32": {
                  "label": "Question mark"
                },
                "options__33": {
                  "label": "Recycle"
                },
                "options__34": {
                  "label": "Return"
                },
                "options__35": {
                  "label": "Ruler"
                },
                "options__36": {
                  "label": "Serving dish"
                },
                "options__37": {
                  "label": "Shirt"
                },
                "options__38": {
                  "label": "Shoe"
                },
                "options__39": {
                  "label": "Silhouette"
                },
                "options__40": {
                  "label": "Snowflake"
                },
                "options__41": {
                  "label": "Star"
                },
                "options__42": {
                  "label": "Stopwatch"
                },
                "options__43": {
                  "label": "Truck"
                },
                "options__44": {
                  "label": "Washing"
                }
              }
            }
          }
        },
        "presets": {
          "name": "Collapsible content"
        }
      }
    }
  }



// Ef420 metadata
public static readonly labelDataEf420 = {
  "sections": {
    "all": {
      "padding": {
        "section_padding_heading": "Section padding",
        "padding_top": "Top padding",
        "padding_bottom": "Bottom padding"
      },
      "spacing": "Spacing",
      "colors": {
        "accent_1": {
          "label": "Accent 1"
        },
        "accent_2": {
          "label": "Accent 2"
        },
        "background_1": {
          "label": "Background 1"
        },
        "background_2": {
          "label": "Background 2"
        },
        "inverse": {
          "label": "Inverse"
        },
        "label": "Color scheme",
        "has_cards_info": "To change the card color scheme, update your theme settings."
      },
      "heading_size": {
        "label": "Heading size",
        "options__1": {
          "label": "Small"
        },
        "options__2": {
          "label": "Medium"
        },
        "options__3": {
          "label": "Large"
        },
        "options__4": {
          "label": "Extra large"
        }
      }
    },
    "announcement-bar": {
      "name": "Announcement bar",
      "blocks": {
        "announcement": {
          "name": "Announcement",
          "settings": {
            "text": {
              "label": "Text"
            },
            "text_alignment": {
              "label": "Text alignment",
              "options__1": {
                "label": "Left"
              },
              "options__2": {
                "label": "Center"
              },
              "options__3": {
                "label": "Right"
              }
            },
            "link": {
              "label": "Link"
            }
          }
        }
      }
    },
    "apps": {
      "name": "Apps",
      "settings": {
        "include_margins": {
          "label": "Make section margins the same as theme"
        }
      },
      "presets": {
        "name": "Apps"
      }
    },
    "collage": {
      "name": "Collage",
      "settings": {
        "heading": {
          "label": "Heading"
        },
        "desktop_layout": {
          "label": "Desktop layout",
          "options__1": {
            "label": "Left large block"
          },
          "options__2": {
            "label": "Right large block"
          }
        },
        "mobile_layout": {
          "label": "Mobile layout",
          "options__1": {
            "label": "Collage"
          },
          "options__2": {
            "label": "Column"
          }
        },
        "card_styles": {
          "label": "Card style",
          "info": "Product, collection, and blog card styles can be updated in theme settings.",
          "options__1": {
            "label": "Use individual card styles"
          },
          "options__2": {
            "label": "Style all as product cards"
          }
        }
      },
      "blocks": {
        "image": {
          "name": "Image",
          "settings": {
            "image": {
              "label": "Image"
            },
            "image_padding": {
              "label": "Use original image ratio",
              "info": "Select if you don't want your image to be cropped."
            }
          }
        },
        "product": {
          "name": "Product",
          "settings": {
            "product": {
              "label": "Product"
            },
            "secondary_background": {
              "label": "Show secondary background"
            },
            "second_image": {
              "label": "Show second image on hover"
            }
          }
        },
        "collection": {
          "name": "Collection",
          "settings": {
            "collection": {
              "label": "Collection"
            }
          }
        },
        "video": {
          "name": "Video",
          "settings": {
            "cover_image": {
              "label": "Cover image"
            },
            "video_url": {
              "label": "URL",
              "info": "Video plays in a pop-up if the section contains other blocks.",
              "placeholder": "Use a YouTube or Vimeo URL"
            },
            "image_padding": {
              "label": "Use original image ratio",
              "info": "Select if you don't want your image to be cropped."
            },
            "description": {
              "label": "Video alt text",
              "info": "Describe the video for customers using screen readers. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/theme-features#video-block)"
            }
          }
        }
      },
      "presets": {
        "name": "Collage"
      }
    },
    "collection-list": {
      "name": "Collection list",
      "settings": {
        "title": {
          "label": "Heading"
        },
        "image_ratio": {
          "label": "Image ratio",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Portrait"
          },
          "options__3": {
            "label": "Square"
          },
          "info": "Add images by editing your collections. [Learn more](https://help.shopify.com/manual/products/collections)"
        },
        "columns_desktop": {
          "label": "Number of columns on desktop"
        },
        "show_view_all": {
          "label": "Enable \"View all\" button if list includes more collections than shown"
        },
        "header_mobile": {
          "content": "Mobile Layout"
        },
        "columns_mobile": {
          "label": "Number of columns on mobile",
          "options__1": {
            "label": "1 column"
          },
          "options__2": {
            "label": "2 columns"
          }
        },
        "swipe_on_mobile": {
          "label": "Enable swipe on mobile"
        }
      },
      "blocks": {
        "featured_collection": {
          "name": "Collection",
          "settings": {
            "collection": {
              "label": "Collection"
            },
            "image_picker": {
              "label": "Collection Image"
            },
            "title": {
              "label": "Title"
            }
          }
        }
      },
      "presets": {
        "name": "Collection list"
      }
    },
    "contact-form": {
      "name": "Contact Form",
      "presets": {
        "name": "Contact form"
      }
    },
    "custom-liquid": {
      "name": "Custom Liquid",
      "settings": {
        "custom_liquid": {
          "label": "Custom Liquid",
          "info": "Add app snippets or other Liquid code to create advanced customizations."
        }
      },
      "presets": {
        "name": "Custom Liquid"
      }
    },
    "featured-blog": {
      "name": "Blog posts",
      "settings": {
        "heading": {
          "label": "Heading"
        },
        "blog": {
          "label": "Blog"
        },
        "post_limit": {
          "label": "Number of blog posts to show"
        },
        "columns_desktop": {
          "label": "Number of columns on desktop"
        },
        "show_view_all": {
          "label": "Enable \"View all\" button if blog includes more blog posts than shown"
        },
        "show_image": {
          "label": "Show featured image",
          "info": "For best results, use an image with a 3:2 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
        },
        "show_date": {
          "label": "Show date"
        },
        "show_author": {
          "label": "Show author"
        }
      },
      "presets": {
        "name": "Blog posts"
      }
    },
    "featured-collection": {
      "name": "Featured collection",
      "settings": {
        "title": {
          "label": "Heading"
        },
        "description": {
          "label": "Description"
        },
        "show_description": {
          "label": "Show collection description from the admin"
        },
        "description_style": {
          "label": "Description style",
          "options__1": {
            "label": "Body"
          },
          "options__2": {
            "label": "Subtitle"
          },
          "options__3": {
            "label": "Uppercase"
          }
        },
        "collection": {
          "label": "Collection"
        },
        "products_to_show": {
          "label": "Maximum products to show"
        },
        "columns_desktop": {
          "label": "Number of columns on desktop"
        },
        "show_view_all": {
          "label": "Enable \"View all\" if collection has more products than shown"
        },
        "view_all_style": {
          "label": "\"View all\" style",
          "options__1": {
            "label": "Link"
          },
          "options__2": {
            "label": "Outline button"
          },
          "options__3": {
            "label": "Solid button"
          }
        },
        "enable_desktop_slider": {
          "label": "Enable carousel on desktop"
        },
        "full_width": {
          "label": "Make products full width"
        },
        "header": {
          "content": "Product card"
        },
        "image_ratio": {
          "label": "Image ratio",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Portrait"
          },
          "options__3": {
            "label": "Square"
          }
        },
        "show_secondary_image": {
          "label": "Show second image on hover"
        },
        "show_vendor": {
          "label": "Show vendor"
        },
        "show_rating": {
          "label": "Show product rating",
          "info": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/theme-features#featured-collection-show-product-rating)"
        },
        "enable_quick_buy": {
          "label": "Enable quick add button",
          "info": "Optimal with popup or drawer cart type."
        },
        "header_mobile": {
          "content": "Mobile Layout"
        },
        "columns_mobile": {
          "label": "Number of columns on mobile",
          "options__1": {
            "label": "1 column"
          },
          "options__2": {
            "label": "2 columns"
          }
        },
        "swipe_on_mobile": {
          "label": "Enable swipe on mobile"
        }
      },
      "presets": {
        "name": "Featured collection"
      }
    },
    "featured-product": {
      "name": "Featured product",
      "blocks": {
        "text": {
          "name": "Text",
          "settings": {
            "text": {
              "label": "Text"
            },
            "text_style": {
              "label": "Text style",
              "options__1": {
                "label": "Body"
              },
              "options__2": {
                "label": "Subtitle"
              },
              "options__3": {
                "label": "Uppercase"
              }
            }
          }
        },
        "title": {
          "name": "Title"
        },
        "price": {
          "name": "Price"
        },
        "quantity_selector": {
          "name": "Quantity selector"
        },
        "variant_picker": {
          "name": "Variant picker",
          "settings": {
            "picker_type": {
              "label": "Type",
              "options__1": {
                "label": "Dropdown"
              },
              "options__2": {
                "label": "Pills"
              }
            }
          }
        },
        "buy_buttons": {
          "name": "Buy buttons",
          "settings": {
            "show_dynamic_checkout": {
              "label": "Show dynamic checkout buttons",
              "info": "Using the payment methods available on your store, customers see their preferred option, like PayPal or Apple Pay. [Learn more](https://help.shopify.com/manual/using-themes/change-the-layout/dynamic-checkout)"
            }
          }
        },
        "description": {
          "name": "Description"
        },
        "share": {
          "name": "Share",
          "settings": {
            "text": {
              "label": "Text"
            },
            "featured_image_info": {
              "content": "If you include a link in social media posts, the page’s featured image will be shown as the preview image. [Learn more](https://help.shopify.com/manual/online-store/images/showing-social-media-thumbnail-images)"
            },
            "title_info": {
              "content": "A store title and description are included with the preview image. [Learn more](https://help.shopify.com/manual/promoting-marketing/seo/adding-keywords#set-a-title-and-description-for-your-online-store)"
            }
          }
        },
        "custom_liquid": {
          "name": "Custom liquid",
          "settings": {
            "custom_liquid": {
              "label": "Custom liquid"
            }
          }
        },
        "rating": {
          "name": "Product rating",
          "settings": {
            "paragraph": {
              "content": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/theme-features#featured-product-rating)"
            }
          }
        }
      },
      "settings": {
        "product": {
          "label": "Product"
        },
        "secondary_background": {
          "label": "Show secondary background"
        },
        "header": {
          "content": "Media",
          "info": "Learn more about [media types](https://help.shopify.com/manual/products/product-media)"
        },
        "media_position": {
          "label": "Desktop media position",
          "info": "Position is automatically optimized for mobile.",
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Right"
          }
        },
        "hide_variants": {
          "label": "Hide unselected variants’ media on desktop"
        },
        "enable_video_looping": {
          "label": "Enable video looping"
        }
      },
      "presets": {
        "name": "Featured product"
      }
    },
    "footer": {
      "name": "Footer",
      "blocks": {
        "link_list": {
          "name": "Menu",
          "settings": {
            "heading": {
              "label": "Heading"
            },
            "menu": {
              "label": "Menu",
              "info": "Displays only top-level menu items."
            }
          }
        },
        "text": {
          "name": "Text",
          "settings": {
            "heading": {
              "label": "Heading"
            },
            "subtext": {
              "label": "Subtext"
            }
          }
        }
      },
      "settings": {
        "newsletter_enable": {
          "label": "Show email signup"
        },
        "newsletter_heading": {
          "label": "Heading"
        },
        "header__1": {
          "content": "Email Signup",
          "info": "Subscribers added automatically to your “accepted marketing” customer list. [Learn more](https://help.shopify.com/manual/customers/manage-customers)"
        },
        "header__2": {
          "content": "Social media icons",
          "info": "To display your social media accounts, link them in your theme settings."
        },
        "show_social": {
          "label": "Show social media icons"
        },
        "header__3": {
          "content": "Country/region selector"
        },
        "header__4": {
          "info": "To add a country/region, go to your [payment settings.](/admin/settings/payments)"
        },
        "enable_country_selector": {
          "label": "Enable country/region selector"
        },
        "header__5": {
          "content": "Language selector"
        },
        "header__6": {
          "info": "To add a language, go to your [language settings.](/admin/settings/languages)"
        },
        "enable_language_selector": {
          "label": "Enable language selector"
        },
        "header__7": {
          "content": "Payment methods"
        },
        "payment_enable": {
          "label": "Show payment icons"
        },
        "margin_top": {
          "label": "Top margin"
        }
      }
    },
    "header": {
      "name": "Header",
      "settings": {
        "logo": {
          "label": "Logo image"
        },
        "logo_width": {
          "unit": "px",
          "label": "Custom logo width"
        },
        "logo_position": {
          "label": "Desktop logo position",
          "options__1": {
            "label": "Middle left"
          },
          "options__2": {
            "label": "Top left"
          },
          "options__3": {
            "label": "Top center"
          },
          "options__4": {
            "label": "Middle center"
          }
        },
        "menu": {
          "label": "Menu"
        },
        "menu_type_desktop": {
          "label": "Desktop menu type",
          "info": "Menu type is automatically optimized for mobile.",
          "options__1": {
            "label": "Dropdown"
          },
          "options__2": {
            "label": "Mega menu"
          }
        },
        "show_line_separator": {
          "label": "Show separator line"
        },
        "enable_sticky_header": {
          "label": "Enable sticky header",
          "info": "Header shows on the screen as customers scroll up."
        },
        "margin_bottom": {
          "label": "Bottom margin"
        },
        "mobile_layout": {
          "content": "Mobile layout"
        },
        "mobile_logo_position": {
          "label": "Mobile logo position",
          "options__1": {
            "label": "Center"
          },
          "options__2": {
            "label": "Left"
          }
        }
      }
    },
    "image-banner": {
      "name": "Image banner",
      "settings": {
        "image": {
          "label": "First image"
        },
        "image_2": {
          "label": "Second image"
        },
        "image_overlay_opacity": {
          "label": "Image overlay opacity"
        },
        "image_height": {
          "label": "Banner height",
          "options__1": {
            "label": "Small"
          },
          "options__2": {
            "label": "Medium"
          },
          "options__3": {
            "label": "Large"
          },
          "info": "For best results, use an image with a 3:2 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
        },
        "desktop_content_position": {
          "options__1": {
            "label": "Top Left"
          },
          "options__2": {
            "label": "Top Center"
          },
          "options__3": {
            "label": "Top Right"
          },
          "options__4": {
            "label": "Middle Left"
          },
          "options__5": {
            "label": "Middle Center"
          },
          "options__6": {
            "label": "Middle Right"
          },
          "options__7": {
            "label": "Bottom Left"
          },
          "options__8": {
            "label": "Bottom Center"
          },
          "options__9": {
            "label": "Bottom Right"
          },
          "label": "Desktop content position"
        },
        "show_text_box": {
          "label": "Show container on desktop"
        },
        "desktop_content_alignment": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Desktop content alignment"
        },
        "color_scheme": {
          "info": "Visible when container displayed."
        },
        "header": {
          "content": "Mobile Layout"
        },
        "mobile_content_alignment": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Mobile content alignment"
        },
        "stack_images_on_mobile": {
          "label": "Stack images on mobile"
        },
        "show_text_below": {
          "label": "Show container on mobile"
        },
        "adapt_height_first_image": {
          "label": "Adapt section height to first image size",
          "info": "Overwrites image banner height setting when checked."
        }
      },
      "blocks": {
        "heading": {
          "name": "Heading",
          "settings": {
            "heading": {
              "label": "Heading"
            }
          }
        },
        "text": {
          "name": "Text",
          "settings": {
            "text": {
              "label": "Description"
            },
            "text_style": {
              "options__1": {
                "label": "Body"
              },
              "options__2": {
                "label": "Subtitle"
              },
              "options__3": {
                "label": "Uppercase"
              },
              "label": "Text style"
            }
          }
        },
        "buttons": {
          "name": "Buttons",
          "settings": {
            "button_label_1": {
              "label": "First button label",
              "info": "Leave the label blank to hide the button."
            },
            "button_link_1": {
              "label": "First button link"
            },
            "button_style_secondary_1": {
              "label": "Use outline button style"
            },
            "button_label_2": {
              "label": "Second button label",
              "info": "Leave the label blank to hide the button."
            },
            "button_link_2": {
              "label": "Second button link"
            },
            "button_style_secondary_2": {
              "label": "Use outline button style"
            }
          }
        }
      },
      "presets": {
        "name": "Image banner"
      }
    },
    "image-with-text": {
      "name": "Image with text",
      "settings": {
        "image": {
          "label": "Image"
        },
        "height": {
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Small"
          },
          "options__3": {
            "label": "Large"
          },
          "label": "Image height"
        },
        "desktop_image_width": {
          "options__1": {
            "label": "Small"
          },
          "options__2": {
            "label": "Medium"
          },
          "options__3": {
            "label": "Large"
          },
          "label": "Desktop image width",
          "info": "Image is automatically optimized for mobile."
        },
        "layout": {
          "options__1": {
            "label": "Image first"
          },
          "options__2": {
            "label": "Image second"
          },
          "label": "Desktop image placement",
          "info": "Image first is the default mobile layout."
        },
        "desktop_content_alignment": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Desktop content alignment"
        },
        "desktop_content_position": {
          "options__1": {
            "label": "Top"
          },
          "options__2": {
            "label": "Middle"
          },
          "options__3": {
            "label": "Bottom"
          },
          "label": "Desktop content position"
        },
        "content_layout": {
          "options__1": {
            "label": "No overlap"
          },
          "options__2": {
            "label": "Overlap"
          },
          "label": "Content layout"
        },
        "mobile_content_alignment": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Mobile content alignment"
        }
      },
      "blocks": {
        "heading": {
          "name": "Heading",
          "settings": {
            "heading": {
              "label": "Heading"
            }
          }
        },
        "caption": {
          "name": "Caption",
          "settings": {
            "text": {
              "label": "Text"
            },
            "text_style": {
              "label": "Text style",
              "options__1": {
                "label": "Subtitle"
              },
              "options__2": {
                "label": "Uppercase"
              }
            },
            "caption_size": {
              "label": "Text size",
              "options__1": {
                "label": "Small"
              },
              "options__2": {
                "label": "Medium"
              },
              "options__3": {
                "label": "Large"
              }
            }
          }
        },
        "text": {
          "name": "Text",
          "settings": {
            "text": {
              "label": "Content"
            },
            "text_style": {
              "label": "Text style",
              "options__1": {
                "label": "Body"
              },
              "options__2": {
                "label": "Subtitle"
              }
            }
          }
        },
        "button": {
          "name": "Button",
          "settings": {
            "button_label": {
              "label": "Button label",
              "info": "Leave the label blank to hide the button."
            },
            "button_link": {
              "label": "Button link"
            }
          }
        }
      },
      "presets": {
        "name": "Image with text"
      }
    },
    "main-account": {
      "name": "Account"
    },
    "main-activate-account": {
      "name": "Account activation"
    },
    "main-addresses": {
      "name": "Addresses"
    },
    "main-article": {
      "name": "Blog post",
      "blocks": {
        "featured_image": {
          "name": "Featured image",
          "settings": {
            "image_height": {
              "label": "Featured image height",
              "options__1": {
                "label": "Adapt to image"
              },
              "options__2": {
                "label": "Small"
              },
              "options__3": {
                "label": "Medium"
              },
              "options__4": {
                "label": "Large"
              },
              "info": "For best results, use an image with a 16:9 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
            }
          }
        },
        "title": {
          "name": "Title",
          "settings": {
            "blog_show_date": {
              "label": "Show date"
            },
            "blog_show_author": {
              "label": "Show author"
            }
          }
        },
        "content": {
          "name": "Content"
        },
        "share": {
          "name": "Share",
          "settings": {
            "text": {
              "label": "Text"
            },
            "featured_image_info": {
              "content": "If you include a link in social media posts, the page’s featured image will be shown as the preview image. [Learn more](https://help.shopify.com/manual/online-store/images/showing-social-media-thumbnail-images)."
            },
            "title_info": {
              "content": "A store title and description are included with the preview image. [Learn more](https://help.shopify.com/manual/promoting-marketing/seo/adding-keywords#set-a-title-and-description-for-your-online-store)."
            }
          }
        }
      }
    },
    "main-blog": {
      "name": "Blog posts",
      "settings": {
        "header": {
          "content": "Blog post card"
        },
        "show_image": {
          "label": "Show featured image"
        },
        "show_date": {
          "label": "Show date"
        },
        "show_author": {
          "label": "Show author"
        },
        "paragraph": {
          "content": "Change excerpts by editing your blog posts. [Learn more](https://help.shopify.com/manual/online-store/blogs/writing-blogs#display-an-excerpt-from-a-blog-post)"
        },
        "layout": {
          "label": "Desktop layout",
          "options__1": {
            "label": "Grid"
          },
          "options__2": {
            "label": "Collage"
          },
          "info": "Posts are stacked on mobile."
        },
        "image_height": {
          "label": "Featured image height",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Small"
          },
          "options__3": {
            "label": "Medium"
          },
          "options__4": {
            "label": "Large"
          },
          "info": "For best results, use an image with a 3:2 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
        }
      }
    },
    "main-cart-footer": {
      "name": "Subtotal",
      "blocks": {
        "subtotal": {
          "name": "Subtotal price"
        },
        "buttons": {
          "name": "Checkout button"
        }
      }
    },
    "main-cart-items": {
      "name": "Items"
    },
    "main-collection-banner": {
      "name": "Collection banner",
      "settings": {
        "paragraph": {
          "content": "Add a description or image by editing your collection. [Learn more](https://help.shopify.com/manual/products/collections/collection-layout)"
        },
        "show_collection_description": {
          "label": "Show collection description"
        },
        "show_collection_image": {
          "label": "Show collection image",
          "info": "For best results, use an image with a 16:9 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
        }
      }
    },
    "main-collection-product-grid": {
      "name": "Product grid",
      "settings": {
        "products_per_page": {
          "label": "Products per page"
        },
        "columns_desktop": {
          "label": "Number of columns on desktop"
        },
        "enable_filtering": {
          "label": "Enable filtering",
          "info": "Customize [filters](/admin/menus)"
        },
        "filter_type": {
          "label": "Desktop filter layout",
          "options__1": {
            "label": "Horizontal"
          },
          "options__2": {
            "label": "Vertical"
          },
          "options__3": {
            "label": "Drawer"
          },
          "info": "Drawer is the default mobile layout."
        },
        "enable_sorting": {
          "label": "Enable sorting"
        },
        "image_ratio": {
          "label": "Image ratio",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Portrait"
          },
          "options__3": {
            "label": "Square"
          }
        },
        "show_secondary_image": {
          "label": "Show second image on hover"
        },
        "show_vendor": {
          "label": "Show vendor"
        },
        "show_rating": {
          "label": "Show product rating",
          "info": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/page-types#product-grid-section-settings)"
        },
        "header__1": {
          "content": "Filtering and sorting"
        },
        "header__3": {
          "content": "Product card"
        },
        "enable_tags": {
          "label": "Enable filtering",
          "info": "[Customize filters](/admin/menus)"
        },
        "enable_quick_buy": {
          "label": "Enable quick add button",
          "info": "Optimal with popup or drawer cart type."
        },
        "header_mobile": {
          "content": "Mobile Layout"
        },
        "columns_mobile": {
          "label": "Number of columns on mobile",
          "options__1": {
            "label": "1 column"
          },
          "options__2": {
            "label": "2 columns"
          }
        }
      }
    },
    "main-list-collections": {
      "name": "Collections list page",
      "settings": {
        "title": {
          "label": "Heading"
        },
        "sort": {
          "label": "Sort collections by:",
          "options__1": {
            "label": "Alphabetically, A-Z"
          },
          "options__2": {
            "label": "Alphabetically, Z-A"
          },
          "options__3": {
            "label": "Date, new to old"
          },
          "options__4": {
            "label": "Date, old to new"
          },
          "options__5": {
            "label": "Product count, high to low"
          },
          "options__6": {
            "label": "Product count, low to high"
          }
        },
        "image_ratio": {
          "label": "Image ratio",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Portrait"
          },
          "options__3": {
            "label": "Square"
          },
          "info": "Add images by editing your collections. [Learn more](https://help.shopify.com/manual/products/collections)"
        },
        "columns_desktop": {
          "label": "Number of columns on desktop"
        },
        "header_mobile": {
          "content": "Mobile Layout"
        },
        "columns_mobile": {
          "label": "Number of columns on mobile",
          "options__1": {
            "label": "1 column"
          },
          "options__2": {
            "label": "2 columns"
          }
        }
      }
    },
    "main-login": {
      "name": "Login"
    },
    "main-order": {
      "name": "Order"
    },
    "main-page": {
      "name": "Page"
    },
    "main-password-footer": {
      "name": "Password footer"
    },
    "main-password-header": {
      "name": "Password header",
      "settings": {
        "logo": {
          "label": "Logo image"
        },
        "logo_max_width": {
          "label": "Custom logo width",
          "unit": "px"
        }
      }
    },
    "main-product": {
      "name": "Product information",
      "blocks": {
        "text": {
          "name": "Text",
          "settings": {
            "text": {
              "label": "Text"
            },
            "text_style": {
              "label": "Text style",
              "options__1": {
                "label": "Body"
              },
              "options__2": {
                "label": "Subtitle"
              },
              "options__3": {
                "label": "Uppercase"
              }
            }
          }
        },
        "title": {
          "name": "Title"
        },
        "price": {
          "name": "Price"
        },
        "quantity_selector": {
          "name": "Quantity selector"
        },
        "variant_picker": {
          "name": "Variant picker",
          "settings": {
            "picker_type": {
              "label": "Type",
              "options__1": {
                "label": "Dropdown"
              },
              "options__2": {
                "label": "Pills"
              },
              "options__3": {
                "label": "Swatches"
              }
            }
          }
        },
        "buy_buttons": {
          "name": "Buy buttons",
          "settings": {
            "show_dynamic_checkout": {
              "label": "Show dynamic checkout buttons",
              "info": "Using the payment methods available on your store, customers see their preferred option, like PayPal or Apple Pay. [Learn more](https:\/\/help.shopify.com\/manual\/using-themes\/change-the-layout\/dynamic-checkout)"
            }
          }
        },
        "pickup_availability": {
          "name": "Pickup availability"
        },
        "description": {
          "name": "Description"
        },
        "share": {
          "name": "Share",
          "settings": {
            "text": {
              "label": "Text"
            },
            "featured_image_info": {
              "content": "If you include a link in social media posts, the page’s featured image will be shown as the preview image. [Learn more](https:\/\/help.shopify.com\/en\/manual\/online-store\/images\/showing-social-media-thumbnail-images)."
            },
            "title_info": {
              "content": "A store title and description are included with the preview image. [Learn more](https:\/\/help.shopify.com\/en\/manual\/promoting-marketing\/seo\/adding-keywords#set-a-title-and-description-for-your-online-store)."
            }
          }
        },
        "custom_liquid": {
          "name": "Custom liquid",
          "settings": {
            "custom_liquid": {
              "label": "Custom liquid",
              "info": "Add app snippets or other Liquid code to create advanced customizations."
            }
          }
        },
        "collapsible_tab": {
          "name": "Collapsible row",
          "settings": {
            "heading": {
              "info": "Include a heading that explains the content.",
              "label": "Heading"
            },
            "content": {
              "label": "Row content"
            },
            "page": {
              "label": "Row content from page"
            },
            "icon": {
              "label": "Icon",
              "options__1": {
                "label": "None"
              },
              "options__2": {
                "label": "Apple"
              },
              "options__3": {
                "label": "Banana"
              },
              "options__4": {
                "label": "Bottle"
              },
              "options__5": {
                "label": "Box"
              },
              "options__6": {
                "label": "Carrot"
              },
              "options__7": {
                "label": "Chat bubble"
              },
              "options__8": {
                "label": "Check mark"
              },
              "options__9": {
                "label": "Clipboard"
              },
              "options__10": {
                "label": "Dairy"
              },
              "options__11": {
                "label": "Dairy free"
              },
              "options__12": {
                "label": "Dryer"
              },
              "options__13": {
                "label": "Eye"
              },
              "options__14": {
                "label": "Fire"
              },
              "options__15": {
                "label": "Gluten free"
              },
              "options__16": {
                "label": "Heart"
              },
              "options__17": {
                "label": "Iron"
              },
              "options__18": {
                "label": "Leaf"
              },
              "options__19": {
                "label": "Leather"
              },
              "options__20": {
                "label": "Lightning bolt"
              },
              "options__21": {
                "label": "Lipstick"
              },
              "options__22": {
                "label": "Lock"
              },
              "options__23": {
                "label": "Map pin"
              },
              "options__24": {
                "label": "Nut free"
              },
              "options__25": {
                "label": "Pants"
              },
              "options__26": {
                "label": "Paw print"
              },
              "options__27": {
                "label": "Pepper"
              },
              "options__28": {
                "label": "Perfume"
              },
              "options__29": {
                "label": "Plane"
              },
              "options__30": {
                "label": "Plant"
              },
              "options__31": {
                "label": "Price tag"
              },
              "options__32": {
                "label": "Question mark"
              },
              "options__33": {
                "label": "Recycle"
              },
              "options__34": {
                "label": "Return"
              },
              "options__35": {
                "label": "Ruler"
              },
              "options__36": {
                "label": "Serving dish"
              },
              "options__37": {
                "label": "Shirt"
              },
              "options__38": {
                "label": "Shoe"
              },
              "options__39": {
                "label": "Silhouette"
              },
              "options__40": {
                "label": "Snowflake"
              },
              "options__41": {
                "label": "Star"
              },
              "options__42": {
                "label": "Stopwatch"
              },
              "options__43": {
                "label": "Truck"
              },
              "options__44": {
                "label": "Washing"
              }
            }
          }
        },
        "popup": {
          "name": "Pop-up",
          "settings": {
            "link_label": {
              "label": "Link label"
            },
            "page": {
              "label": "Page"
            }
          }
        },
        "rating": {
          "name": "Product rating",
          "settings": {
            "paragraph": {
              "content": "To display a rating, add a product rating app. [Learn more](https:\/\/help.shopify.com\/manual\/online-store\/themes\/os20\/themes-by-shopify\/dawn\/page-types#product-rating-block)"
            }
          }
        }
      },
      "settings": {
        "header": {
          "content": "Media",
          "info": "Learn more about [media types.](https:\/\/help.shopify.com\/manual\/products\/product-media)"
        },
        "enable_sticky_info": {
          "label": "Enable sticky content on desktop"
        },
        "enable_sticky_bottom_button": {
          "label": "Enable sticky button on bottom"
        },
        "gallery_layout": {
          "label": "Desktop layout",
          "options__1": {
            "label": "Stacked"
          },
          "options__2": {
            "label": "Thumbnails"
          },
          "options__3": {
            "label": "Thumbnail carousel"
          }
        },
        "media_size": {
          "label": "Desktop media size",
          "info": "Media is automatically optimized for mobile.",
          "options__1": {
            "label": "Small"
          },
          "options__2": {
            "label": "Medium"
          },
          "options__3": {
            "label": "Large"
          }
        },
        "mobile_thumbnails": {
          "label": "Mobile layout",
          "options__1": {
            "label": "Show thumbnails"
          },
          "options__2": {
            "label": "Hide thumbnails"
          }
        },
        "hide_variants": {
          "label": "Hide other variants’ media after selecting a variant"
        },
        "enable_video_looping": {
          "label": "Enable video looping"
        }
      }
    },
    "main-register": {
      "name": "Registration"
    },
    "main-reset-password": {
      "name": "Password reset"
    },
    "main-search": {
      "name": "Search results",
      "settings": {
        "columns_desktop": {
          "label": "Number of columns on desktop"
        },
        "image_ratio": {
          "label": "Image ratio",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Portrait"
          },
          "options__3": {
            "label": "Square"
          }
        },
        "show_secondary_image": {
          "label": "Show second image on hover"
        },
        "show_vendor": {
          "label": "Show vendor"
        },
        "show_rating": {
          "label": "Show product rating",
          "info": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/page-types#search-results-section-settings)"
        },
        "header__1": {
          "content": "Product card"
        },
        "header__2": {
          "content": "Blog card",
          "info": "Blog card styles also apply to page cards in search results. To change card styles update your theme settings."
        },
        "article_show_date": {
          "label": "Show date"
        },
        "article_show_author": {
          "label": "Show author"
        },
        "header_mobile": {
          "content": "Mobile Layout"
        },
        "columns_mobile": {
          "label": "Number of columns on mobile",
          "options__1": {
            "label": "1 column"
          },
          "options__2": {
            "label": "2 columns"
          }
        }
      }
    },
    "multicolumn": {
      "name": "Multicolumn",
      "settings": {
        "title": {
          "label": "Heading"
        },
        "image_width": {
          "label": "Image width",
          "options__1": {
            "label": "One-third width of column"
          },
          "options__2": {
            "label": "Half width of column"
          },
          "options__3": {
            "label": "Full width of column"
          }
        },
        "image_ratio": {
          "label": "Image ratio",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Portrait"
          },
          "options__3": {
            "label": "Square"
          },
          "options__4": {
            "label": "Circle"
          }
        },
        "columns_desktop": {
          "label": "Number of columns on desktop"
        },
        "column_alignment": {
          "label": "Column alignment",
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          }
        },
        "background_style": {
          "label": "Secondary background",
          "options__1": {
            "label": "None"
          },
          "options__2": {
            "label": "Show as column background"
          }
        },
        "button_label": {
          "label": "Button label"
        },
        "button_link": {
          "label": "Button link"
        },
        "header_mobile": {
          "content": "Mobile Layout"
        },
        "columns_mobile": {
          "label": "Number of columns on mobile",
          "options__1": {
            "label": "1 column"
          },
          "options__2": {
            "label": "2 columns"
          }
        },
        "swipe_on_mobile": {
          "label": "Enable swipe on mobile"
        }
      },
      "blocks": {
        "column": {
          "name": "Column",
          "settings": {
            "image": {
              "label": "Image"
            },
            "title": {
              "label": "Heading"
            },
            "text": {
              "label": "Description"
            },
            "link_label": {
              "label": "Link label"
            },
            "link": {
              "label": "Link"
            }
          }
        }
      },
      "presets": {
        "name": "Multicolumn"
      }
    },
    "newsletter": {
      "name": "Email signup",
      "settings": {
        "full_width": {
          "label": "Make section full width"
        },
        "paragraph": {
          "content": "Each email subscription creates a customer account. [Learn more](https://help.shopify.com/manual/customers)"
        }
      },
      "blocks": {
        "heading": {
          "name": "Heading",
          "settings": {
            "heading": {
              "label": "Heading"
            }
          }
        },
        "paragraph": {
          "name": "Subheading",
          "settings": {
            "paragraph": {
              "label": "Description"
            }
          }
        },
        "email_form": {
          "name": "Email form"
        }
      },
      "presets": {
        "name": "Email signup"
      }
    },
    "email-signup-banner": {
      "name": "Email signup banner",
      "settings": {
        "paragraph": {
          "content": "Each email subscription creates a customer account. [Learn more](https://help.shopify.com/manual/customers)"
        },
        "image": {
          "label": "Background image"
        },
        "image_overlay_opacity": {
          "label": "Image overlay opacity"
        },
        "show_background_image": {
          "label": "Show background image"
        },
        "show_text_box": {
          "label": "Show container on desktop"
        },
        "desktop_content_position": {
          "options__1": {
            "label": "Top Left"
          },
          "options__2": {
            "label": "Top Center"
          },
          "options__3": {
            "label": "Top Right"
          },
          "options__4": {
            "label": "Middle Left"
          },
          "options__5": {
            "label": "Middle Center"
          },
          "options__6": {
            "label": "Middle Right"
          },
          "options__7": {
            "label": "Bottom Left"
          },
          "options__8": {
            "label": "Bottom Center"
          },
          "options__9": {
            "label": "Bottom Right"
          },
          "label": "Desktop content position"
        },
        "color_scheme": {
          "info": "Visible when container displayed."
        },
        "image_height": {
          "label": "Banner height",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Small"
          },
          "options__3": {
            "label": "Medium"
          },
          "options__4": {
            "label": "Large"
          },
          "info": "For best results, use an image with a 16:9 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
        },
        "desktop_content_alignment": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Desktop content alignment"
        },
        "header": {
          "content": "Mobile Layout"
        },
        "mobile_content_alignment": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Mobile content alignment"
        },
        "show_text_below": {
          "label": "Show content below image on mobile",
          "info": "For best results, use an image with a 16:9 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
        }
      },
      "blocks": {
        "heading": {
          "name": "Heading",
          "settings": {
            "heading": {
              "label": "Heading"
            }
          }
        },
        "paragraph": {
          "name": "Paragraph",
          "settings": {
            "paragraph": {
              "label": "Description"
            },
            "text_style": {
              "options__1": {
                "label": "Body"
              },
              "options__2": {
                "label": "Subtitle"
              },
              "label": "Text style"
            }
          }
        },
        "email_form": {
          "name": "Email form"
        }
      },
      "presets": {
        "name": "Email signup banner"
      }
    },
    "page": {
      "name": "Page",
      "settings": {
        "page": {
          "label": "Page"
        }
      },
      "presets": {
        "name": "Page"
      }
    },
    "product-recommendations": {
      "name": "Product recommendations",
      "settings": {
        "heading": {
          "label": "Heading"
        },
        "products_to_show": {
          "label": "Maximum products to show"
        },
        "columns_desktop": {
          "label": "Number of columns on desktop"
        },
        "paragraph__1": {
          "content": "Dynamic recommendations use order and product information to change and improve over time. [Learn more](https://help.shopify.com/themes/development/recommended-products)"
        },
        "header__2": {
          "content": "Product card"
        },
        "image_ratio": {
          "label": "Image ratio",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Portrait"
          },
          "options__3": {
            "label": "Square"
          }
        },
        "show_secondary_image": {
          "label": "Show second image on hover"
        },
        "show_vendor": {
          "label": "Show vendor"
        },
        "show_rating": {
          "label": "Show product rating",
          "info": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/page-types#product-recommendations-section-settings)"
        },
        "header_mobile": {
          "content": "Mobile Layout"
        },
        "columns_mobile": {
          "label": "Number of columns on mobile",
          "options__1": {
            "label": "1 column"
          },
          "options__2": {
            "label": "2 columns"
          }
        }
      }
    },
    "rich-text": {
      "name": "Rich text",
      "settings": {
        "desktop_content_position": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Desktop content position",
          "info": "Position is automatically optimized for mobile."
        },
        "content_alignment": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Content alignment"
        },
        "full_width": {
          "label": "Make section full width"
        }
      },
      "blocks": {
        "heading": {
          "name": "Heading",
          "settings": {
            "heading": {
              "label": "Heading"
            }
          }
        },
        "caption": {
          "name": "Caption",
          "settings": {
            "text": {
              "label": "Text"
            },
            "text_style": {
              "label": "Text style",
              "options__1": {
                "label": "Subtitle"
              },
              "options__2": {
                "label": "Uppercase"
              }
            },
            "caption_size": {
              "label": "Text size",
              "options__1": {
                "label": "Small"
              },
              "options__2": {
                "label": "Medium"
              },
              "options__3": {
                "label": "Large"
              }
            }
          }
        },
        "text": {
          "name": "Text",
          "settings": {
            "text": {
              "label": "Description"
            }
          }
        },
        "buttons": {
          "name": "Buttons",
          "settings": {
            "button_label_1": {
              "label": "First button label",
              "info": "Leave the label blank to hide the button."
            },
            "button_link_1": {
              "label": "First button link"
            },
            "button_style_secondary_1": {
              "label": "Use outline button style"
            },
            "button_label_2": {
              "label": "Second button label",
              "info": "Leave the label blank to hide the button."
            },
            "button_link_2": {
              "label": "Second button link"
            },
            "button_style_secondary_2": {
              "label": "Use outline button style"
            }
          }
        }
      },
      "presets": {
        "name": "Rich text"
      }
    },
    "video": {
      "name": "Video",
      "settings": {
        "heading": {
          "label": "Heading"
        },
        "cover_image": {
          "label": "Cover image"
        },
        "video_url": {
          "label": "URL",
          "placeholder": "Use a YouTube or Vimeo URL",
          "info": "Video plays in the page."
        },
        "description": {
          "label": "Video alt text",
          "info": "Describe the video for customers using screen readers. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/theme-features#video)"
        },
        "image_padding": {
          "label": "Add image padding",
          "info": "Select image padding if you don't want your cover image to be cropped."
        },
        "full_width": {
          "label": "Make section full width"
        }
      },
      "presets": {
        "name": "Video"
      }
    },
    "slideshow": {
      "name": "Slideshow",
      "settings": {
        "layout": {
          "label": "Layout",
          "options__1": {
            "label": "Full width"
          },
          "options__2": {
            "label": "Grid"
          }
        },
        "slide_height": {
          "label": "Slide height",
          "options__1": {
            "label": "Adapt to first image"
          },
          "options__2": {
            "label": "Small"
          },
          "options__3": {
            "label": "Medium"
          },
          "options__4": {
            "label": "Large"
          }
        },
        "slider_visual": {
          "label": "Pagination style",
          "options__1": {
            "label": "Counter"
          },
          "options__2": {
            "label": "Dots"
          },
          "options__3": {
            "label": "Numbers"
          }
        },
        "auto_rotate": {
          "label": "Auto-rotate slides"
        },
        "change_slides_speed": {
          "label": "Change slides every"
        },
        "mobile": {
          "content": "Mobile layout"
        },
        "show_text_below": {
          "label": "Show content below images on mobile"
        },
        "accessibility": {
          "content": "Accessibility",
          "label": "Slideshow description",
          "info": "Describe the slideshow for customers using screen readers."
        }
      },
      "blocks": {
        "slide": {
          "name": "Slide",
          "settings": {
            "image": {
              "label": "Image"
            },
            "heading": {
              "label": "Heading"
            },
            "subheading": {
              "label": "Subheading"
            },
            "button_label": {
              "label": "Button label",
              "info": "Leave the label blank to hide the button."
            },
            "link": {
              "label": "Button link"
            },
            "secondary_style": {
              "label": "Use outline button style"
            },
            "box_align": {
              "label": "Desktop content position",
              "info": "Position is automatically optimized for mobile.",
              "options__1": {
                "label": "Top left"
              },
              "options__2": {
                "label": "Top center"
              },
              "options__3": {
                "label": "Top right"
              },
              "options__4": {
                "label": "Middle left"
              },
              "options__5": {
                "label": "Middle center"
              },
              "options__6": {
                "label": "Middle right"
              },
              "options__7": {
                "label": "Bottom left"
              },
              "options__8": {
                "label": "Bottom center"
              },
              "options__9": {
                "label": "Bottom right"
              }
            },
            "show_text_box": {
              "label": "Show container on desktop"
            },
            "text_alignment": {
              "label": "Desktop content alignment",
              "option_1": {
                "label": "Left"
              },
              "option_2": {
                "label": "Center"
              },
              "option_3": {
                "label": "Right"
              }
            },
            "image_overlay_opacity": {
              "label": "Image overlay opacity"
            },
            "color_scheme": {
              "info": "Visible when container displayed."
            },
            "text_alignment_mobile": {
              "label": "Mobile content alignment",
              "options__1": {
                "label": "Left"
              },
              "options__2": {
                "label": "Center"
              },
              "options__3": {
                "label": "Right"
              }
            }
          }
        }
      },
      "presets": {
        "name": "Slideshow"
      }
    },
    "collapsible_content": {
      "name": "Collapsible content",
      "settings": {
        "caption": {
          "label": "Caption"
        },
        "heading": {
          "label": "Heading"
        },
        "heading_alignment": {
          "label": "Heading alignment",
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          }
        },
        "layout": {
          "label": "Layout",
          "options__1": {
            "label": "No container"
          },
          "options__2": {
            "label": "Row container"
          },
          "options__3": {
            "label": "Section container"
          }
        },
        "container_color_scheme": {
          "label": "Container color scheme",
          "info": "Visible when Layout is set to Row or Section container."
        },
        "open_first_collapsible_row": {
          "label": "Open first collapsible row"
        },
        "header": {
          "content": "Image layout"
        },
        "image": {
          "label": "Image"
        },
        "image_ratio": {
          "label": "Image ratio",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Small"
          },
          "options__3": {
            "label": "Large"
          }
        },
        "desktop_layout": {
          "label": "Desktop layout",
          "options__1": {
            "label": "Image first"
          },
          "options__2": {
            "label": "Image second"
          },
          "info": "Image is always first on mobile."
        }
      },
      "blocks": {
        "collapsible_row": {
          "name": "Collapsible row",
          "settings": {
            "heading": {
              "info": "Include a heading that explains the content.",
              "label": "Heading"
            },
            "row_content": {
              "label": "Row content"
            },
            "page": {
              "label": "Row content from page"
            },
            "icon": {
              "label": "Icon",
              "options__1": {
                "label": "None"
              },
              "options__2": {
                "label": "Apple"
              },
              "options__3": {
                "label": "Banana"
              },
              "options__4": {
                "label": "Bottle"
              },
              "options__5": {
                "label": "Box"
              },
              "options__6": {
                "label": "Carrot"
              },
              "options__7": {
                "label": "Chat bubble"
              },
              "options__8": {
                "label": "Check mark"
              },
              "options__9": {
                "label": "Clipboard"
              },
              "options__10": {
                "label": "Dairy"
              },
              "options__11": {
                "label": "Dairy free"
              },
              "options__12": {
                "label": "Dryer"
              },
              "options__13": {
                "label": "Eye"
              },
              "options__14": {
                "label": "Fire"
              },
              "options__15": {
                "label": "Gluten free"
              },
              "options__16": {
                "label": "Heart"
              },
              "options__17": {
                "label": "Iron"
              },
              "options__18": {
                "label": "Leaf"
              },
              "options__19": {
                "label": "Leather"
              },
              "options__20": {
                "label": "Lightning bolt"
              },
              "options__21": {
                "label": "Lipstick"
              },
              "options__22": {
                "label": "Lock"
              },
              "options__23": {
                "label": "Map pin"
              },
              "options__24": {
                "label": "Nut free"
              },
              "options__25": {
                "label": "Pants"
              },
              "options__26": {
                "label": "Paw print"
              },
              "options__27": {
                "label": "Pepper"
              },
              "options__28": {
                "label": "Perfume"
              },
              "options__29": {
                "label": "Plane"
              },
              "options__30": {
                "label": "Plant"
              },
              "options__31": {
                "label": "Price tag"
              },
              "options__32": {
                "label": "Question mark"
              },
              "options__33": {
                "label": "Recycle"
              },
              "options__34": {
                "label": "Return"
              },
              "options__35": {
                "label": "Ruler"
              },
              "options__36": {
                "label": "Serving dish"
              },
              "options__37": {
                "label": "Shirt"
              },
              "options__38": {
                "label": "Shoe"
              },
              "options__39": {
                "label": "Silhouette"
              },
              "options__40": {
                "label": "Snowflake"
              },
              "options__41": {
                "label": "Star"
              },
              "options__42": {
                "label": "Stopwatch"
              },
              "options__43": {
                "label": "Truck"
              },
              "options__44": {
                "label": "Washing"
              }
            }
          }
        }
      },
      "presets": {
        "name": "Collapsible content"
      }
    },
    "integration-reviews-lai-ali-express-io": {
      "name": "LAI product reviews",

    },
    "featured-collection-slider": {
      "name": "Featured collection slider",
      "settings": {
        "title": {
          "label": "Heading"
        },
        "full_width": {
          "label": "Full Width Section"
        },
        "subtitle": {
          "label": "Subtitle"
        },
        "collection": {
          "label": "Collection"
        },
        "products_to_show": {
          "label": "Maximum products to show"
        },
        "columns_desktop": {
          "label": "Number of items on desktop"
        },
        "header": {
          "content": "Product card"
        },
        "image_ratio": {
          "label": "Image ratio",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Portrait"
          },
          "options__3": {
            "label": "Square"
          }
        },
        "header_mobile": {
          "content": "Mobile Layout"
        },
        "show_secondary_image": {
          "label": "Show second image on hover"
        },
        "columns_mobile": {
          "label": "Number of items on mobile",
          "options__1": {
            "label": "1 column"
          },
          "options__2": {
            "label": "2 columns"
          }
        }
      },
      "presets": {
        "name": "Featured collection slider"
      }
    },


  }
}
  public static readonly schemasEf420 = {
    "header" : {
      "name": "t:sections.header.name",
      "class": "section-header",
      "blocks": [
         {
            "type": "Image",
            "name": "NavDrawer-ImageWithLink",
            "settings": [
              {
                "type": "image_picker",
                "id": "image",
                "label": "Image"
              },
              {
                "type": "text",
                "id": "text",
                "label": "Text"
              },
              {
                "type": "url",
                "id": "link",
                "label": "Link"
              }
            ]
        }
       ],
      "settings": [
        {
          "type": "select",
          "id": "color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            }, {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            }, {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            }, {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            }, {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-1",
          "label": "t:sections.all.colors.label"
        }, {
          "type": "image_picker",
          "id": "logo",
          "label": "t:sections.header.settings.logo.label"
        }, {
          "type": "image_picker",
          "id": "logo_mobile",
          "label": "Header mobile logo"
        }, {
          "type": "range",
          "id": "logo_width",
          "min": 50,
          "max": 250,
          "step": 5,
          "default": 100,
          "unit": "t:sections.header.settings.logo_width.unit",
          "label": "t:sections.header.settings.logo_width.label"
        }, {
          "type": "select",
          "id": "logo_position",
          "options": [
            {
              "value": "middle-left",
              "label": "t:sections.header.settings.logo_position.options__1.label"
            }, {
              "value": "top-left",
              "label": "t:sections.header.settings.logo_position.options__2.label"
            }, {
              "value": "top-center",
              "label": "t:sections.header.settings.logo_position.options__3.label"
            }
          ],
          "default": "middle-left",
          "label": "t:sections.header.settings.logo_position.label",
          "info": "t:sections.header.settings.logo_position.info"
        }, {
          "type": "link_list",
          "id": "menu",
          "default": "main-menu",
          "label": "t:sections.header.settings.menu.label"
        },
        {
          "type": "link_list",
          "id": "secondary_menu",
          "label": "Secondary menu"
        },
        {
          "type": "select",
          "id": "menu_type_desktop",
          "options": [
            {
              "value": "dropdown",
              "label": "t:sections.header.settings.menu_type_desktop.options__1.label"
            }, {
              "value": "mega",
              "label": "t:sections.header.settings.menu_type_desktop.options__2.label"
            }
          ],
          "default": "dropdown",
          "label": "t:sections.header.settings.menu_type_desktop.label",
          "info": "t:sections.header.settings.menu_type_desktop.info"
        }, {
          "type": "link_list",
          "id": "top_menu",
          "label": "t:sections.header.settings.top_menu.label"
        },
        {
          "type": "link_list",
          "id": "scroll_menu",
          "default": "main-menu",
          "label": "Scrolling Main menu"
        },
        {
          "type": "link_list",
          "id": "mobile_menu",
          "label": "Mobile menu"
        },
        {
          "type": "link_list",
          "id": "mobile_footer_menu",
          "label": "Mobile footer menu"
        },
        {
          "type": "checkbox",
          "id": "show_line_separator",
          "default": true,
          "label": "t:sections.header.settings.show_line_separator.label"
        }, {
          "type": "checkbox",
          "id": "enable_sticky_header",
          "default": true,
          "label": "t:sections.header.settings.enable_sticky_header.label",
          "info": "t:sections.header.settings.enable_sticky_header.info"
        }, {
          "type": "header",
          "content": "t:sections.all.spacing"
        }, {
          "type": "range",
          "id": "margin_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.header.settings.margin_bottom.label",
          "default": 0
        }, {
         "type": "header",
         "content": "Blocks title"
        },
        {
          "type": "text",
          "id": "blocks_title",
          "label": "Type title",
          "default": "420 Specialty shop",
          "info": "This is will be show in drawer navbar."
        },
        {
          "type": "text",
          "id": "dallas_text",
          "label": "Dallas text",
          "default": "Dallas, Tx 🇺🇸"
        },
        {
         "type": "url",
         "id": "dallas_url",
         "label": "Dallas URL"
        },
        {
          "type": "checkbox",
          "id": "dallas_show",
          "label": "Dallas show",
          "default": true
        }
      ]
    },
    "main-product" : {
      "name": "t:sections.main-product.name",
      "tag": "section",
      "class": "section",
      "blocks": [
        {
          "type": "@app"
        },
        {
          "type": "text",
          "name": "t:sections.main-product.blocks.text.name",
          "settings": [
            {
              "type": "text",
              "id": "text",
              "default": "Text block",
              "label": "t:sections.main-product.blocks.text.settings.text.label"
            },
            {
              "type": "select",
              "id": "text_style",
              "options": [
                {
                  "value": "body",
                  "label": "t:sections.main-product.blocks.text.settings.text_style.options__1.label"
                },
                {
                  "value": "subtitle",
                  "label": "t:sections.main-product.blocks.text.settings.text_style.options__2.label"
                },
                {
                  "value": "uppercase",
                  "label": "t:sections.main-product.blocks.text.settings.text_style.options__3.label"
                }
              ],
              "default": "body",
              "label": "t:sections.main-product.blocks.text.settings.text_style.label"
            }
          ]
        },
        {
          "type": "title",
          "name": "t:sections.main-product.blocks.title.name",
          "limit": 1
        },
        {
          "type": "price",
          "name": "t:sections.main-product.blocks.price.name",
          "limit": 1,
          "settings": [
            {
              "type": "checkbox",
              "id": "discount",
              "label": "Discount",
              "default": true
            },
            {
              "type": "range",
              "id": "discount-percentage",
              "label": "Discount percentage",
              "unit": "%",
              "min": 5,
              "max": 90,
              "step": 5,
              "default": 15
            }
          ]
        },
        {
          "type": "short_description",
          "name": "Short Description",
          "limit": 1
        },
        {
          "type": "product_review_stars",
          "name": "Review Stars",
          "limit": 1
        },
        {
          "type": "ticket",
          "name": "Tickets",
          "settings": [
            {
              "type": "image_picker",
              "id": "image",
              "label": "Ticket Logo"
            }
          ],
          "limit": 1
        },
        {
          "type": "shipping",
          "name": "Shipping",
          "limit": 1,
          "settings": [
            {
              "type": "checkbox",
              "id": "wide_display",
              "label": "Wide display",
              "default": true
            },
            {
              "type": "color",
              "id": "title_color",
              "label": "Title color",
              "default": "#0138cc"
            },
            {
              "type": "text",
              "id": "shipping_title",
              "label": "Shipping Title",
              "default": "Discreet shipping",
              "info": "Doesn't include 'Free' word, for this word writen logic"
            },
            {
              "type": "color",
              "id": "bg_color",
              "label": "Background color",
              "default": "#d3fde2"
            },
            {
              "type": "text",
              "id": "delta_8_disclaimer_icon",
              "label": "Delta Image"
            },
            {
              "type": "richtext",
              "id": "delta_8_disclaimer_message",
              "label": "Delta 8 disclaimer message"
            }
          ]
        },
        {
          "type": "quantity_selector",
          "name": "t:sections.main-product.blocks.quantity_selector.name",
          "settings": [
            {
              "type": "select",
              "id": "type",
              "options": [
                {
                  "value": "dropdown",
                  "label": "Dropdown field"
                },
                {
                  "value": "input",
                  "label": "Input field"
                }
              ],
              "default": "dropdown",
              "label": "Field Type"
            }
          ],
          "limit": 1
        },
        {
          "type": "hurry-up",
          "name": "Items left bar",
          "limit": 1,
          "settings": [
            {
              "type": "collection",
              "id": "include_collection",
              "label": "Include Collection"
            },
            {
              "type": "text",
              "id": "before_text",
              "label": "Before Text"
            },
            {
              "type": "text",
              "id": "after_text",
              "label": "After Text"
            }
          ]
        },
        {
          "type": "variant_picker",
          "name": "t:sections.main-product.blocks.variant_picker.name",
          "limit": 1,
          "settings": [
            {
              "type": "select",
              "id": "picker_type",
              "options": [
                {
                  "value": "dropdown",
                  "label": "t:sections.main-product.blocks.variant_picker.settings.picker_type.options__1.label"
                },
                {
                  "value": "button",
                  "label": "t:sections.main-product.blocks.variant_picker.settings.picker_type.options__2.label"
                },
                {
                  "value": "swatches",
                  "label": "t:sections.main-product.blocks.variant_picker.settings.picker_type.options__3.label"
                }
              ],
              "default": "button",
              "label": "t:sections.main-product.blocks.variant_picker.settings.picker_type.label"
            }
          ]
        },
        {
          "type": "buy_buttons",
          "name": "t:sections.main-product.blocks.buy_buttons.name",
          "limit": 1,
          "settings": [
            {
              "type": "checkbox",
              "id": "show_dynamic_checkout",
              "default": true,
              "label": "t:sections.main-product.blocks.buy_buttons.settings.show_dynamic_checkout.label",
              "info": "t:sections.main-product.blocks.buy_buttons.settings.show_dynamic_checkout.info"
            }
          ]
        },
        {
          "type": "description",
          "name": "t:sections.main-product.blocks.description.name",
          "limit": 1
        },
        {
          "type": "share",
          "name": "t:sections.main-product.blocks.share.name",
          "limit": 1,
          "settings": [
            {
              "type": "text",
              "id": "share_label",
              "label": "t:sections.main-product.blocks.share.settings.text.label",
              "default": "Share"
            },
            {
              "type": "paragraph",
              "content": "t:sections.main-product.blocks.share.settings.featured_image_info.content"
            },
            {
              "type": "paragraph",
              "content": "t:sections.main-product.blocks.share.settings.title_info.content"
            }
          ]
        },
        {
          "type": "custom_liquid",
          "name": "t:sections.main-product.blocks.custom_liquid.name",
          "settings": [
            {
              "type": "liquid",
              "id": "custom_liquid",
              "label": "t:sections.main-product.blocks.custom_liquid.settings.custom_liquid.label",
              "info": "t:sections.main-product.blocks.custom_liquid.settings.custom_liquid.info"
            }
          ]
        },
        {
          "type": "collapsible_tab",
          "name": "t:sections.main-product.blocks.collapsible_tab.name",
          "settings": [
            {
              "type": "text",
              "id": "heading",
              "default": "Collapsible row",
              "info": "t:sections.main-product.blocks.collapsible_tab.settings.heading.info",
              "label": "t:sections.main-product.blocks.collapsible_tab.settings.heading.label"
            },
            {
              "type": "select",
              "id": "icon",
              "options": [
                {
                  "value": "none",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__1.label"
                },
                {
                  "value": "apple",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__2.label"
                },
                {
                  "value": "banana",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__3.label"
                },
                {
                  "value": "bottle",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__4.label"
                },
                {
                  "value": "box",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__5.label"
                },
                {
                  "value": "carrot",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__6.label"
                },
                {
                  "value": "chat_bubble",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__7.label"
                },
                {
                  "value": "check_mark",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__8.label"
                },
                {
                  "value": "clipboard",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__9.label"
                },
                {
                  "value": "dairy",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__10.label"
                },
                {
                  "value": "dairy_free",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__11.label"
                },
                {
                  "value": "dryer",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__12.label"
                },
                {
                  "value": "eye",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__13.label"
                },
                {
                  "value": "fire",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__14.label"
                },
                {
                  "value": "gluten_free",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__15.label"
                },
                {
                  "value": "heart",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__16.label"
                },
                {
                  "value": "iron",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__17.label"
                },
                {
                  "value": "leaf",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__18.label"
                },
                {
                  "value": "leather",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__19.label"
                },
                {
                  "value": "lightning_bolt",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__20.label"
                },
                {
                  "value": "lipstick",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__21.label"
                },
                {
                  "value": "lock",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__22.label"
                },
                {
                  "value": "map_pin",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__23.label"
                },
                {
                  "value": "nut_free",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__24.label"
                },
                {
                  "value": "pants",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__25.label"
                },
                {
                  "value": "paw_print",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__26.label"
                },
                {
                  "value": "pepper",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__27.label"
                },
                {
                  "value": "perfume",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__28.label"
                },
                {
                  "value": "plane",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__29.label"
                },
                {
                  "value": "plant",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__30.label"
                },
                {
                  "value": "price_tag",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__31.label"
                },
                {
                  "value": "question_mark",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__32.label"
                },
                {
                  "value": "recycle",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__33.label"
                },
                {
                  "value": "return",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__34.label"
                },
                {
                  "value": "ruler",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__35.label"
                },
                {
                  "value": "serving_dish",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__36.label"
                },
                {
                  "value": "shirt",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__37.label"
                },
                {
                  "value": "shoe",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__38.label"
                },
                {
                  "value": "silhouette",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__39.label"
                },
                {
                  "value": "snowflake",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__40.label"
                },
                {
                  "value": "star",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__41.label"
                },
                {
                  "value": "stopwatch",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__42.label"
                },
                {
                  "value": "truck",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__43.label"
                },
                {
                  "value": "washing",
                  "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__44.label"
                }
              ],
              "default": "check_mark",
              "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.label"
            },
            {
              "type": "richtext",
              "id": "content",
              "label": "t:sections.main-product.blocks.collapsible_tab.settings.content.label"
            },
            {
              "type": "page",
              "id": "page",
              "label": "t:sections.main-product.blocks.collapsible_tab.settings.page.label"
            }
          ]
        },
        {
          "type": "popup",
          "name": "t:sections.main-product.blocks.popup.name",
          "settings": [
            {
              "type": "text",
              "id": "text",
              "default": "Pop-up link text",
              "label": "t:sections.main-product.blocks.popup.settings.link_label.label"
            },
            {
              "id": "page",
              "type": "page",
              "label": "t:sections.main-product.blocks.popup.settings.page.label"
            }
          ]
        },
        {
          "type": "rating",
          "name": "t:sections.main-product.blocks.rating.name",
          "limit": 1,
          "settings": [
            {
              "type": "paragraph",
              "content": "t:sections.main-product.blocks.rating.settings.paragraph.content"
            }
          ]
        },
        {
          "type": "copy-to-clipboard",
          "name": "Discount Section",
          "settings": [
            {
              "type": "header",
              "content": "Settings",
              "info": "For changing discount settings please check 'heme settings discount'"
            }
          ],
          "limit": 1
        }
      ],
      "settings": [
        {
          "type": "checkbox",
          "id": "product_breadcrumb",
          "default": true,
          "label": "Show Breadcrumbs"
        },
        {
          "type": "checkbox",
          "id": "enable_sticky_info",
          "default": true,
          "label": "t:sections.main-product.settings.enable_sticky_info.label"
        },
        {
          "type": "checkbox",
          "id": "enable_sticky_bottom_button",
          "default": true,
          "label": "t:sections.main-product.settings.enable_sticky_bottom_button.label"
        },
        {
          "type": "header",
          "content": "t:sections.main-product.settings.header.content",
          "info": "t:sections.main-product.settings.header.info"
        },
        {
          "type": "select",
          "id": "gallery_layout",
          "options": [
            {
              "value": "stacked",
              "label": "t:sections.main-product.settings.gallery_layout.options__1.label"
            },
            {
              "value": "thumbnail",
              "label": "t:sections.main-product.settings.gallery_layout.options__2.label"
            },
            {
              "value": "thumbnail_slider",
              "label": "t:sections.main-product.settings.gallery_layout.options__3.label"
            }
          ],
          "default": "stacked",
          "label": "t:sections.main-product.settings.gallery_layout.label"
        },
        {
          "type": "select",
          "id": "media_size",
          "options": [
            {
              "value": "small",
              "label": "t:sections.main-product.settings.media_size.options__1.label"
            },
            {
              "value": "medium",
              "label": "t:sections.main-product.settings.media_size.options__2.label"
            },
            {
              "value": "large",
              "label": "t:sections.main-product.settings.media_size.options__3.label"
            }
          ],
          "default": "large",
          "label": "t:sections.main-product.settings.media_size.label",
          "info": "t:sections.main-product.settings.media_size.info"
        },
        {
          "type": "range",
          "id": "thumbnails_count",
          "label": "Thumbnalils Count",
          "min": 2,
          "max": 8,
          "step": 1,
          "default": 4
        },
        {
          "type": "select",
          "id": "mobile_thumbnails",
          "options": [
            {
              "value": "show",
              "label": "t:sections.main-product.settings.mobile_thumbnails.options__1.label"
            },
            {
              "value": "hide",
              "label": "t:sections.main-product.settings.mobile_thumbnails.options__2.label"
            }
          ],
          "default": "hide",
          "label": "t:sections.main-product.settings.mobile_thumbnails.label"
        },
        {
          "type": "checkbox",
          "id": "hide_variants",
          "default": false,
          "label": "t:sections.main-product.settings.hide_variants.label"
        },
        {
          "type": "checkbox",
          "id": "enable_video_looping",
          "default": false,
          "label": "t:sections.main-product.settings.enable_video_looping.label"
        },
        {
          "type": "header",
          "content": "Guarante Image Section"
        },
        {
          "type": "image_picker",
          "id": "flag",
          "label": "Flag"
        },
        {
          "type": "text",
          "id": "guarante_text",
          "label": "Shiiping Address",
          "default": "All Products Are Shipped Discreetly From USA"
        },
        {
          "type": "image_picker",
          "id": "guarante_image",
          "label": "Guarante Image"
        },
        {
          "type": "header",
          "content": "t:sections.all.padding.section_padding_heading"
        },
        {
          "type": "range",
          "id": "padding_top",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_top",
          "default": 36
        },
        {
          "type": "range",
          "id": "padding_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_bottom",
          "default": 36
        }
      ]
    },
    "collection-list": {
      "name": "t:sections.collection-list.name",
      "tag": "section",
      "class": "section section-collection-list",
      "max_blocks": 15,
      "settings": [
        {
          "type": "text",
          "id": "title",
          "default": "Collections",
          "label": "t:sections.collection-list.settings.title.label"
        },
        {
          "type": "select",
          "id": "heading_size",
          "options": [
            {
              "value": "h2",
              "label": "t:sections.all.heading_size.options__1.label"
            },
            {
              "value": "h1",
              "label": "t:sections.all.heading_size.options__2.label"
            },
            {
              "value": "h0",
              "label": "t:sections.all.heading_size.options__3.label"
            }
          ],
          "default": "h1",
          "label": "t:sections.all.heading_size.label"
        },
        {
          "type": "select",
          "id": "image_ratio",
          "options": [
            {
              "value": "adapt",
              "label": "t:sections.collection-list.settings.image_ratio.options__1.label"
            },
            {
              "value": "portrait",
              "label": "t:sections.collection-list.settings.image_ratio.options__2.label"
            },
            {
              "value": "square",
              "label": "t:sections.collection-list.settings.image_ratio.options__3.label"
            }
          ],
          "default": "square",
          "label": "t:sections.collection-list.settings.image_ratio.label",
          "info": "t:sections.collection-list.settings.image_ratio.info"
        },
        {
          "type": "range",
          "id": "columns_desktop",
          "min": 1,
          "max": 5,
          "step": 1,
          "default": 3,
          "label": "t:sections.collection-list.settings.columns_desktop.label"
        },
        {
          "type": "select",
          "id": "color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-1",
          "label": "t:sections.all.colors.label",
          "info": "t:sections.all.colors.has_cards_info"
        },
        {
          "type": "checkbox",
          "id": "show_view_all",
          "default": false,
          "label": "t:sections.collection-list.settings.show_view_all.label"
        },
        {
          "type": "header",
          "content": "t:sections.collection-list.settings.header_mobile.content"
        },
        {
          "type": "select",
          "id": "columns_mobile",
          "options": [
            {
              "value": "1",
              "label": "t:sections.collection-list.settings.columns_mobile.options__1.label"
            },
            {
              "value": "2",
              "label": "t:sections.collection-list.settings.columns_mobile.options__2.label"
            }
          ],
          "default": "1",
          "label": "t:sections.collection-list.settings.columns_mobile.label"
        },
        {
          "type": "checkbox",
          "id": "swipe_on_mobile",
          "default": false,
          "label": "t:sections.collection-list.settings.swipe_on_mobile.label"
        },
        {
          "type": "header",
          "content": "t:sections.all.padding.section_padding_heading"
        },
        {
          "type": "range",
          "id": "padding_top",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_top",
          "default": 36
        },
        {
          "type": "range",
          "id": "padding_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_bottom",
          "default": 36
        }
      ],
      "blocks": [
        {
          "type": "featured_collection",
          "name": "t:sections.collection-list.blocks.featured_collection.name",
          "settings": [
            {
              "type": "collection",
              "id": "collection",
              "label": "t:sections.collection-list.blocks.featured_collection.settings.collection.label"
            },
            {
              "type": "image_picker",
              "id": "collection_img",
              "label": "t:sections.collection-list.blocks.featured_collection.settings.collection_img.label"
            },
            {
              "type": "text",
              "id": "title",
              "label": "t:sections.collection-list.blocks.featured_collection.settings.title.label"
            },
          ]
        }
      ],
      "presets": [
        {
          "name": "t:sections.collection-list.presets.name",
          "blocks": [
            {
              "type": "featured_collection"
            },
          ]
        }
      ]
    },
    "image-banner": {
      "name": "t:sections.image-banner.name",
      "tag": "section",
      "class": "section",
      "settings": [
        {
          "type": "checkbox",
          "id": "wide_display",
          "label": "Wide display",
          "default": false
        },
        {
          "type": "image_picker",
          "id": "image",
          "label": "t:sections.image-banner.settings.image.label"
        },
        {
          "type": "image_picker",
          "id": "image_2",
          "label": "t:sections.image-banner.settings.image_2.label"
        },
        {
          "type": "image_picker",
          "id": "image_mobile",
          "label": "Mobile Image"
        },
        {
          "type": "url",
          "id": "image_url",
          "label": "Image URL"
        },
        {
          "type": "range",
          "id": "image_overlay_opacity",
          "min": 0,
          "max": 100,
          "step": 10,
          "unit": "%",
          "label": "t:sections.image-banner.settings.image_overlay_opacity.label",
          "default": 0
        },
        {
          "type": "select",
          "id": "image_height",
          "options": [
            {
              "value": "small",
              "label": "t:sections.image-banner.settings.image_height.options__1.label"
            },
            {
              "value": "medium",
              "label": "t:sections.image-banner.settings.image_height.options__2.label"
            },
            {
              "value": "large",
              "label": "t:sections.image-banner.settings.image_height.options__3.label"
            }
          ],
          "default": "medium",
          "label": "t:sections.image-banner.settings.image_height.label",
          "info": "t:sections.image-banner.settings.image_height.info"
        },
        {
          "type": "checkbox",
          "id": "adapt_height_first_image",
          "default": false,
          "label": "t:sections.image-banner.settings.adapt_height_first_image.label",
          "info": "t:sections.image-banner.settings.adapt_height_first_image.info"
        },
        {
          "type": "select",
          "id": "desktop_content_position",
          "options": [
            {
              "value": "top-left",
              "label": "t:sections.image-banner.settings.desktop_content_position.options__1.label"
            },
            {
              "value": "top-center",
              "label": "t:sections.image-banner.settings.desktop_content_position.options__2.label"
            },
            {
              "value": "top-right",
              "label": "t:sections.image-banner.settings.desktop_content_position.options__3.label"
            },
            {
              "value": "middle-left",
              "label": "t:sections.image-banner.settings.desktop_content_position.options__4.label"
            },
            {
              "value": "middle-center",
              "label": "t:sections.image-banner.settings.desktop_content_position.options__5.label"
            },
            {
              "value": "middle-right",
              "label": "t:sections.image-banner.settings.desktop_content_position.options__6.label"
            },
            {
              "value": "bottom-left",
              "label": "t:sections.image-banner.settings.desktop_content_position.options__7.label"
            },
            {
              "value": "bottom-center",
              "label": "t:sections.image-banner.settings.desktop_content_position.options__8.label"
            },
            {
              "value": "bottom-right",
              "label": "t:sections.image-banner.settings.desktop_content_position.options__9.label"
            }
          ],
          "default": "middle-center",
          "label": "t:sections.image-banner.settings.desktop_content_position.label"
        },
        {
          "type": "checkbox",
          "id": "show_text_box",
          "default": true,
          "label": "t:sections.image-banner.settings.show_text_box.label"
        },
        {
          "type": "select",
          "id": "desktop_content_alignment",
          "options": [
            {
              "value": "left",
              "label": "t:sections.image-banner.settings.desktop_content_alignment.options__1.label"
            },
            {
              "value": "center",
              "label": "t:sections.image-banner.settings.desktop_content_alignment.options__2.label"
            },
            {
              "value": "right",
              "label": "t:sections.image-banner.settings.desktop_content_alignment.options__3.label"
            }
          ],
          "default": "center",
          "label": "t:sections.image-banner.settings.desktop_content_alignment.label"
        },
        {
          "type": "select",
          "id": "color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-1",
          "label": "t:sections.all.colors.label",
          "info": "t:sections.image-banner.settings.color_scheme.info"
        },
        {
          "type": "header",
          "content": "t:sections.image-banner.settings.header.content"
        },
        {
          "type": "select",
          "id": "mobile_content_alignment",
          "options": [
            {
              "value": "left",
              "label": "t:sections.image-banner.settings.mobile_content_alignment.options__1.label"
            },
            {
              "value": "center",
              "label": "t:sections.image-banner.settings.mobile_content_alignment.options__2.label"
            },
            {
              "value": "right",
              "label": "t:sections.image-banner.settings.mobile_content_alignment.options__3.label"
            }
          ],
          "default": "center",
          "label": "t:sections.image-banner.settings.mobile_content_alignment.label"
        },
        {
          "type": "checkbox",
          "id": "stack_images_on_mobile",
          "default": true,
          "label": "t:sections.image-banner.settings.stack_images_on_mobile.label"
        },
        {
          "type": "checkbox",
          "id": "show_text_below",
          "default": true,
          "label": "t:sections.image-banner.settings.show_text_below.label"
        }
      ],
      "blocks": [
        {
          "type": "heading",
          "name": "t:sections.image-banner.blocks.heading.name",
          "limit": 1,
          "settings": [
            {
              "type": "text",
              "id": "heading",
              "default": "Image banner",
              "label": "t:sections.image-banner.blocks.heading.settings.heading.label"
            },
            {
              "type": "select",
              "id": "heading_size",
              "options": [
                {
                  "value": "h2",
                  "label": "t:sections.all.heading_size.options__1.label"
                },
                {
                  "value": "h1",
                  "label": "t:sections.all.heading_size.options__2.label"
                },
                {
                  "value": "h0",
                  "label": "t:sections.all.heading_size.options__3.label"
                }
              ],
              "default": "h1",
              "label": "t:sections.all.heading_size.label"
            }
          ]
        },
        {
          "type": "text",
          "name": "t:sections.image-banner.blocks.text.name",
          "limit": 1,
          "settings": [
            {
              "type": "text",
              "id": "text",
              "default": "Give customers details about the banner image(s) or content on the template.",
              "label": "t:sections.image-banner.blocks.text.settings.text.label"
            },
            {
              "type": "select",
              "id": "text_style",
              "options": [
                {
                  "value": "body",
                  "label": "t:sections.image-banner.blocks.text.settings.text_style.options__1.label"
                },
                {
                  "value": "subtitle",
                  "label": "t:sections.image-banner.blocks.text.settings.text_style.options__2.label"
                },
                {
                  "value": "caption-with-letter-spacing",
                  "label": "t:sections.image-banner.blocks.text.settings.text_style.options__3.label"
                }
              ],
              "default": "body",
              "label": "t:sections.image-banner.blocks.text.settings.text_style.label"
            }
          ]
        },
        {
          "type": "buttons",
          "name": "t:sections.image-banner.blocks.buttons.name",
          "limit": 1,
          "settings": [
            {
              "type": "text",
              "id": "button_label_1",
              "default": "Button label",
              "label": "t:sections.image-banner.blocks.buttons.settings.button_label_1.label",
              "info": "t:sections.image-banner.blocks.buttons.settings.button_label_1.info"
            },
            {
              "type": "url",
              "id": "button_link_1",
              "label": "t:sections.image-banner.blocks.buttons.settings.button_link_1.label"
            },
            {
              "type": "checkbox",
              "id": "button_style_secondary_1",
              "default": false,
              "label": "t:sections.image-banner.blocks.buttons.settings.button_style_secondary_1.label"
            },
            {
              "type": "text",
              "id": "button_label_2",
              "default": "Button label",
              "label": "t:sections.image-banner.blocks.buttons.settings.button_label_2.label",
              "info": "t:sections.image-banner.blocks.buttons.settings.button_label_2.info"
            },
            {
              "type": "url",
              "id": "button_link_2",
              "label": "t:sections.image-banner.blocks.buttons.settings.button_link_2.label"
            },
            {
              "type": "checkbox",
              "id": "button_style_secondary_2",
              "default": false,
              "label": "t:sections.image-banner.blocks.buttons.settings.button_style_secondary_2.label"
            }
          ]
        }
      ],
      "presets": [
        {
          "name": "t:sections.image-banner.presets.name",
          "blocks": [
            {
              "type": "heading"
            },
            {
              "type": "text"
            },
            {
              "type": "buttons"
            }
          ]
        }
      ]
    },
    "featured-collection-slider": {
      "name": "t:sections.featured-collection-slider.name",
      "tag": "section",
      "class": "section",
      "settings": [
        {
          "type": "text",
          "id": "title",
          "default": "Best Sellers",
          "label": "t:sections.featured-collection-slider.settings.title.label"
        },
        {
          "type": "text",
          "id": "subtitle",
          "default": "Subtitle",
          "label": "t:sections.featured-collection-slider.settings.subtitle.label"
        },
        {
          "type": "select",
          "id": "heading_size",
          "options": [
            {
              "value": "h2",
              "label": "t:sections.all.heading_size.options__1.label"
            },
            {
              "value": "h1",
              "label": "t:sections.all.heading_size.options__2.label"
            },
            {
              "value": "h0",
              "label": "t:sections.all.heading_size.options__3.label"
            }
          ],
          "default": "h1",
          "label": "t:sections.all.heading_size.label"
        },
        {
          "type": "collection",
          "id": "collection",
          "label": "t:sections.featured-collection-slider.settings.collection.label"
        },
        {
          "type": "range",
          "id": "products_to_show",
          "min": 2,
          "max": 22,
          "step": 1,
          "default": 4,
          "label": "t:sections.featured-collection-slider.settings.products_to_show.label"
        },
        {
          "type": "range",
          "id": "columns_desktop",
          "min": 1,
          "max": 8,
          "step": 1,
          "default": 2,
          "label": "t:sections.featured-collection-slider.settings.columns_desktop.label"
        },
        {
          "type": "header",
          "content": "t:sections.featured-collection-slider.settings.header.content"
        },
        {
          "type": "select",
          "id": "image_ratio",
          "options": [
            {
              "value": "adapt",
              "label": "t:sections.featured-collection-slider.settings.image_ratio.options__1.label"
            },
            {
              "value": "portrait",
              "label": "t:sections.featured-collection-slider.settings.image_ratio.options__2.label"
            },
            {
              "value": "square",
              "label": "t:sections.featured-collection-slider.settings.image_ratio.options__3.label"
            }
          ],
          "default": "adapt",
          "label": "t:sections.featured-collection-slider.settings.image_ratio.label"
        },
        {
          "type": "checkbox",
          "id": "show_secondary_image",
          "default": false,
          "label": "t:sections.featured-collection-slider.settings.show_secondary_image.label"
        },
        {
          "type": "range",
          "id": "columns_mobile",
          "min": 1,
          "max": 5,
          "step": 1,
          "default": 1,
          "label": "t:sections.featured-collection-slider.settings.columns_mobile.label"
        },
      {
        "type": "range",
        "id": "padding_top",
        "label": "Top Space",
        "min": 0,
        "max": 100,
        "default": 0,
        "step": 1,
        "unit": "px"
      }
      ],
      "presets": [
      {
        "name": "t:sections.featured-collection-slider.presets.name"
      }
    ]
    },
    "featured-collection-text" : {
      "name": "Featured collection text",
      "settings": [
       {
         "type": "text",
         "id": "title",
         "default": "Best Sellers",
         "label": "t:sections.featured-collection-slider.settings.title.label"
       },
       {
         "type": "text",
         "id": "subtitle",
         "default": "Subtitle",
         "label": "t:sections.featured-collection-slider.settings.subtitle.label"
       }
       ],
       "presets": [
         {
           "name": "Featured collection text"
         }
       ]
     },
     "online-smoke-shop" : {
      "name": "Smoke shop",
      "settings": [
          {
            "type": "richtext",
            "id": "online_smoke_shop",
            "label": "text"
          }
      ],
      "presets": [
          {
          "name": "Online snoke shop"
          }
      ]
    },
    "guarante_image" : {
      "name": "Guarante Section",
      "settings": [
          {
              "type": "select",
              "id": "media_size",
              "options": [
                {
                  "value": "small",
                  "label": "t:sections.main-product.settings.media_size.options__1.label"
                },
                {
                  "value": "medium",
                  "label": "t:sections.main-product.settings.media_size.options__2.label"
                },
                {
                  "value": "large",
                  "label": "t:sections.main-product.settings.media_size.options__3.label"
                }
              ],
              "default": "large",
              "label": "t:sections.main-product.settings.media_size.label",
              "info": "t:sections.main-product.settings.media_size.info"
          },
          {
              "type": "image_picker",
              "id": "flag",
              "label": "Flag"
          },
          {
              "type": "text",
              "id": "address",
              "label": "Shiiping Address",
              "default": "All Products Are Shipped Discreetly From USA"
          },
          {
              "type": "image_picker",
              "id": "image",
              "label": "Image"
          }
      ]
    },
    "breadcrumbs" :{
      "name": "Breadcrumbs",
      "settings": [
         {
             "type": "checkbox",
             "id": "hide_breadcrumbs",
             "label": "Hide",
             "default": false
         }
      ]
    },
    "product-information" :{
      "name": "Product description",
      "settings": [
       {
         "type": "color",
         "id": "product_information_back",
         "label": "Background color"
       },
       {
         "type": "color",
         "id": "product_specifications_back",
         "label": "Background color"
       }
      ],
      "blocks": [
         {
           "type": "product_description",
           "name": "Product description",
           "settings": [
             {
               "type": "text",
               "id": "block_name",
               "label": "Block name",
               "default": "Product description"
             },
             {
               "type": "header",
               "content": "Disclaimer Under Description"
             },
             {
               "type": "header",
               "content": "Disclaimer Type 1"
             },
             {
               "type": "text",
               "id": "disclaimer_tags_1",
               "label": "Tags To Trigger Disclaimer 1",
               "info": "Disclaimer will be shown when product tags contains any of these keywords"
             },
             {
               "type": "html",
               "id": "disclaimer_html_1",
               "label": "Disclaimer 1"
             },
             {
               "type": "header",
               "content": "Disclaimer Type 2"
             },
             {
               "type": "text",
               "id": "disclaimer_tags_2",
               "label": "Tags To Trigger Disclaimer 2",
               "info": "Disclaimer will be shown when product tags contains any of these keywords"
             },
             {
               "type": "html",
               "id": "disclaimer_html_2",
               "label": "Disclaimer 2"
             },
             {
               "type": "header",
               "content": "Disclaimer Type 3"
             },
             {
               "type": "text",
               "id": "disclaimer_tags_3",
               "label": "Tags To Trigger Disclaimer 3",
               "info": "Disclaimer will be shown when product tags contains any of these keywords"
             },
             {
               "type": "html",
               "id": "disclaimer_html_3",
               "label": "Disclaimer 3"
             },
             {
               "type": "header",
               "content": "Disclaimer Type 4"
             },
             {
               "type": "text",
               "id": "disclaimer_tags_4",
               "label": "Tags To Trigger Disclaimer 4",
               "info": "Disclaimer will be shown when product tags contains any of these keywords"
             },
             {
               "type": "html",
               "id": "disclaimer_html_4",
               "label": "Disclaimer 4"
             },
             {
               "type": "header",
               "content": "Disclaimer Type 5"
             },
             {
               "type": "text",
               "id": "disclaimer_tags_5",
               "label": "Tags To Trigger Disclaimer 5",
               "info": "Disclaimer will be shown when product tags contains any of these keywords"
             },
             {
               "type": "html",
               "id": "disclaimer_html_5",
               "label": "Disclaimer 5"
             },
             {
               "type": "header",
               "content": "Disclaimer Type 6"
             },
             {
               "type": "text",
               "id": "disclaimer_tags_6",
               "label": "Tags To Trigger Disclaimer 6",
               "info": "Disclaimer will be shown when product tags contains any of these keywords"
             },
             {
               "type": "html",
               "id": "disclaimer_html_6",
               "label": "Disclaimer 6"
             }
           ]
         }
       ]
        },

       "collection-partial-description" : {
      "name": "Partial description",
      "templates": ["collection"],
      "settings": [
        {
          "type": "select",
          "id": "pruning_strategy",
          "label": "Pruning Strategy",
          "options": [
            {
              "value": "trim",
              "label": "Trim Content"
            },
            {
              "value": "leave",
              "label": "Leave Content"
            }
          ]
        },
        {
          "type": "header",
          "content": "Trim Content"
        },
        {
          "type": "select",
          "label": "Trim Paragraphs",
          "id": "trim_direction",
          "options": [
            {
              "value": "from_top",
              "label": "From Top"
            },
            {
              "value": "from_bottom",
              "label": "From Bottom"
            }
          ]
        },
        {
          "type": "range",
          "id": "trim_number",
          "label": "Paragraphs to Trim",
          "step": 1,
          "min": 1,
          "max": 20,
          "default": 1
        },
        {
          "type": "header",
          "content": "Leave Content"
        },
        {
          "type": "select",
          "label": "Leave Starting From",
          "id": "leave_direction",
          "options": [
            {
              "value": "from_top",
              "label": "From Top"
            },
            {
              "value": "from_bottom",
              "label": "From Bottom"
            }
          ]
        },
        {
          "type": "range",
          "id": "leave_number",
          "label": "Paragraphs to Leave",
          "step": 1,
          "min": 1,
          "max": 20,
          "default": 1
        }
      ],
      "presets": [
        {
          "name": "Partial description",
          "category": "Custom"
        }
      ]
        },

  }
  public static readonly globalSettingSchemaEf420 = {
    "global_settings":{
      "name": "Global Settings",
      "settings": [
        // {
        //   "type": "header",
        //   "content": "Largest Contentful Paint"
        // },
        // {
        //   "type": "checkbox",
        //   "id": "display_inventory_left",
        //   "label": "Show left inventory",
        //   "default": true
        // },
        {
          "type": "header",
          "content": "Ticket image"
        },
        {
          "type": "image_picker",
          "id": "ticket_image",
          "label": "Ticket image"
        },
        // {
        //   "type": "header",
        //   "content": "Last Chance Collection"
        // },
        // {
        //   "type": "collection",
        //   "id": "selected_collection",
        //   "label": "Collection"
        // },
        // {
        //   "type": "paragraph",
        //   "content": "Display custom icon for Delta 8 products"
        // },
        // {
        //   "type": "richtext",
        //   "id": "delta_8_disclaimer_message",
        //   "label": "Delta 8 Disclaimer Message"
        // },
        // {
        //   "type": "text",
        //   "id": "delta_8_disclaimer_icon",
        //   "label": "Delta 8 Disclaimer Icon",
        //   "info": "File name of the icon in the theme assets folder"
        // },
        // {
        //   "type": "url",
        //   "id": "google_link",
        //   "label": "t:sections.footer.blocks.link_list.settings.show_app.google_label"
        // },
        // {
        //   "type": "url",
        //   "id": "apple_link",
        //   "label": "t:sections.footer.blocks.link_list.settings.show_app.apple_label"
        // }
      ]
    },
    "discount_code":{
      "name": "Discount Code",
      "settings": [
        {
          "type": "header",
          "content": "Discount Code Settings"
        },



        {
          "type": "text",
          "id": "apply_discount_only_on_collection",
          "label": "Apply Discounts only on collections"
        },


        {
          "type": "header",
          "content": "Discount Percentage"
        },


       {
          "type": "text",
          "id": "discount_code",
          "label": "Discount Code"
        },

        {
          "type": "range",
          "id": "discount_percantage",
          "label": "Discount percentage",
          "unit": "%",
          "min": 5,
          "max": 90,
          "step": 5,
          "default": 30
        },



        {
          "type": "header",
          "content": "Clearance Discount"
        },



        {
          "type": "checkbox",
          "id": "enable_clearance_discount",
          "label": "Enable clearance discount",
          "default": true
        },


        {
          "type": "text",
          "id": "clearance_discount_code",
          "label": "Clearance Discount code"
        },
        {
          "type": "range",
          "id": "clearance_discount_percantage",
          "label": "Clearance discount percentage",
          "unit": "%",
          "min": 5,
          "max": 90,
          "step": 5,
          "default": 30
        },

        // {
        //   "type": "text",
        //   "id": "code_text_before",
        //   "label": "Discount Text First Part",
        //   "default": "Use code"
        // },




        // {
        //   "type": "text",
        //   "id": "code_text_after",
        //   "label": "Clearance text after code",
        //   "default": "and take"
        // },
        // {
        //   "type": "header",
        //   "content": "Regular Sale text"
        // },
        // {
        //   "type": "text",
        //   "id": "clearance_code",
        //   "label": "Regular sale code"
        // },
        // {
        //   "type": "range",
        //   "id": "clearance_precent_off_text",
        //   "label": "Regular sale % off",
        //   "unit": "%",
        //   "min": 5,
        //   "max": 90,
        //   "step": 5,
        //   "default": 10
        // },
        // {
        //   "type": "text",
        //   "id": "clearance_text_code_before",
        //   "label": "Regular text before code",
        //   "default": "Use code"
        // },
        // {
        //   "type": "text",
        //   "id": "clearance_text_code_after",
        //   "label": "Regular text after code",
        //   "default": "and take"
        // }
      ]
    },
    "sale_mode": {
      "name": "Sale Mode",
      "settings": [
        {
          "type": "checkbox",
          "id": "enable_sale_mode",
          "label": "Enable Sale Mode",
          "default": false
        },
        {
          "type": "header",
          "content": "1st Sale Product"
        },
        // {
        //   "type": "text",
        //   "id": "sale_mode_product_tag_1",
        //   "label": "Product Tag",
        //   "default": "BFCM2022"
        // },
        {
          "type": "text",
          "id": "sale_mode_product_coupon_code_1",
          "label": "Coupon Code",
          "default": "BLACKFRIDAY22"
        },
        {
          "type": "range",
          "id": "sale_mode_product_discount_1",
          "label": "Discount Percantage",
          "min": 1,
          "max": 100,
          "step": 1,
          "default": 35
        },
        // {
        //   "type": "text",
        //   "id": "sale_mode_collection_page_badge_text_1",
        //   "label": "Collection Page Badge Text",
        //   "default": "BFCM -35%"
        // },
        // {
        //   "type": "text",
        //   "id": "sale_mode_product_page_badge_text_1",
        //   "label": "Product Page Badge Text",
        //   "default": "BFCM -35%"
        // },
        // {
        //   "type": "liquid",
        //   "id": "sale_mode_product_page_text_1",
        //   "label": "Product Page Text",
        //   "default": "Use code {{ sale_mode_product_coupon_code_1 }} and take {{ sale_mode_product_discount_1 }}% off"
        // },
        // {
        //   "type": "text",
        //   "id": "sale_mode_success_message_1",
        //   "label": "Success Message",
        //   "default": "COUPON COPIED"
        // },
        // {
        //   "type": "color",
        //   "id": "sale_mode_background_color_1",
        //   "label": "Background Color",
        //   "default": "#9245D8"
        // },
        // {
        //   "type": "color",
        //   "id": "sale_mode_text_color_1",
        //   "label": "Text Color",
        //   "default": "#FFFFFF"
        // },
        {
          "type": "header",
          "content": "2nd Sale Product"
        },
        // {
        //   "type": "text",
        //   "id": "sale_mode_product_tag_2",
        //   "label": "Product Tag",
        //   "default": "off"
        // },
        {
          "type": "text",
          "id": "sale_mode_product_coupon_code_2",
          "label": "Coupon Code",
          "default": "BLACKFRIDAY22"
        },
        {
          "type": "range",
          "id": "sale_mode_product_discount_2",
          "label": "Discount Percantage",
          "min": 1,
          "max": 100,
          "step": 1,
          "default": 35
        },
        // {
        //   "type": "text",
        //   "id": "sale_mode_collection_page_badge_text_2",
        //   "label": "Collection Page Badge Text",
        //   "default": "BFCM -35%"
        // },
        // {
        //   "type": "text",
        //   "id": "sale_mode_product_page_badge_text_2",
        //   "label": "Product Page Badge Text",
        //   "default": "BFCM -35%"
        // },
        // {
        //   "type": "liquid",
        //   "id": "sale_mode_product_page_text_2",
        //   "label": "Product Page Text",
        //   "default": "Use code {{ sale_mode_product_coupon_code_2 }} and take {{ sale_mode_product_discount_2 }}% off"
        // },
        // {
        //   "type": "text",
        //   "id": "sale_mode_success_message_2",
        //   "label": "Success Message",
        //   "default": "COUPON COPIED"
        // },
        // {
        //   "type": "color",
        //   "id": "sale_mode_background_color_2",
        //   "label": "Background Color",
        //   "default": "transparent"
        // },
        // {
        //   "type": "color",
        //   "id": "sale_mode_text_color_2",
        //   "label": "Text Color",
        //   "default": "transparent"
        // }
      ]
    },
    "layout": {
      "name": "t:settings_schema.layout.name",
      "settings": [
        {
          "type": "range",
          "id": "page_width",
          "min": 1000,
          "max": 1600,
          "step": 100,
          "default": 1200,
          "unit": "px",
          "label": "t:settings_schema.layout.settings.page_width.label"
        },
        {
          "type": "range",
          "id": "spacing_sections",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:settings_schema.layout.settings.spacing_sections.label",
          "default": 0
        },
        {
          "type": "header",
          "content": "t:settings_schema.layout.settings.header__grid.content"
        },
        {
          "type": "paragraph",
          "content": "t:settings_schema.layout.settings.paragraph__grid.content"
        },
        {
          "type": "range",
          "id": "spacing_grid_horizontal",
          "min": 4,
          "max": 40,
          "step": 4,
          "default": 8,
          "unit": "px",
          "label": "t:settings_schema.layout.settings.spacing_grid_horizontal.label"
        },
        {
          "type": "range",
          "id": "spacing_grid_vertical",
          "min": 4,
          "max": 40,
          "step": 4,
          "default": 8,
          "unit": "px",
          "label": "t:settings_schema.layout.settings.spacing_grid_vertical.label"
        }
      ]
    },
    "colors":
    {
      "name": "t:settings_schema.colors.name",
      "settings": [
        {
          "type": "header",
          "content": "t:settings_schema.colors.settings.header__1.content"
        },
        {
          "type": "color",
          "id": "colors_solid_button_labels",
          "default": "#FFFFFF",
          "label": "t:settings_schema.colors.settings.colors_solid_button_labels.label",
          "info": "t:settings_schema.colors.settings.colors_solid_button_labels.info"
        },
        {
          "type": "color",
          "id": "colors_accent_1",
          "default": "#121212",
          "label": "t:settings_schema.colors.settings.colors_accent_1.label",
          "info": "t:settings_schema.colors.settings.colors_accent_1.info"
        },
        {
          "id": "gradient_accent_1",
          "type": "color_background",
          "label": "t:settings_schema.colors.settings.gradient_accent_1.label"
        },
        {
          "type": "color",
          "id": "colors_accent_2",
          "default": "#334FB4",
          "label": "t:settings_schema.colors.settings.colors_accent_2.label"
        },
        {
          "id": "gradient_accent_2",
          "type": "color_background",
          "label": "t:settings_schema.colors.settings.gradient_accent_2.label"
        },
        {
          "type": "header",
          "content": "t:settings_schema.colors.settings.header__2.content"
        },
        {
          "type": "color",
          "id": "colors_text",
          "default": "#121212",
          "label": "t:settings_schema.colors.settings.colors_text.label",
          "info": "t:settings_schema.colors.settings.colors_text.info"
        },
        {
          "type": "color",
          "id": "colors_outline_button_labels",
          "default": "#121212",
          "label": "t:settings_schema.colors.settings.colors_outline_button_labels.label",
          "info": "t:settings_schema.colors.settings.colors_outline_button_labels.info"
        },
        {
          "type": "color",
          "id": "colors_background_1",
          "default": "#FFFFFF",
          "label": "t:settings_schema.colors.settings.colors_background_1.label"
        },
        {
          "id": "gradient_background_1",
          "type": "color_background",
          "label": "t:settings_schema.colors.settings.gradient_background_1.label"
        },
        {
          "type": "color",
          "id": "colors_background_2",
          "default": "#F3F3F3",
          "label": "t:settings_schema.colors.settings.colors_background_2.label"
        },
        {
          "id": "gradient_background_2",
          "type": "color_background",
          "label": "t:settings_schema.colors.settings.gradient_background_2.label"
        }
      ]
    },
    "typography": {
      "name": "t:settings_schema.typography.name",
      "settings": [
        {
          "type": "header",
          "content": "t:settings_schema.typography.settings.header__1.content"
        },
        {
          "type": "font_picker",
          "id": "type_header_font",
          "default": "assistant_n4",
          "label": "t:settings_schema.typography.settings.type_header_font.label",
          "info": "t:settings_schema.typography.settings.type_header_font.info"
        },
        {
          "type": "range",
          "id": "heading_scale",
          "min": 100,
          "max": 150,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.typography.settings.heading_scale.label",
          "default": 100
        },
        {
          "type": "header",
          "content": "t:settings_schema.typography.settings.header__2.content"
        },
        {
          "type": "font_picker",
          "id": "type_body_font",
          "default": "assistant_n4",
          "label": "t:settings_schema.typography.settings.type_body_font.label",
          "info": "t:settings_schema.typography.settings.type_body_font.info"
        },
        {
          "type": "range",
          "id": "body_scale",
          "min": 100,
          "max": 130,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.typography.settings.body_scale.label",
          "default": 100
        }
      ]
    },
    "buttons": {
      "name": "t:settings_schema.buttons.name",
      "settings": [
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__border.content"
        },
        {
          "type": "range",
          "id": "buttons_border_thickness",
          "min": 0,
          "max": 12,
          "step": 1,
          "unit": "px",
          "label": "t:settings_schema.global.settings.thickness.label",
          "default": 1
        },
        {
          "type": "range",
          "id": "buttons_border_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 100
        },
        {
          "type": "range",
          "id": "buttons_radius",
          "min": 0,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.corner_radius.label",
          "default": 0
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__shadow.content"
        },
        {
          "type": "range",
          "id": "buttons_shadow_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "buttons_shadow_horizontal_offset",
          "min": -12,
          "max": 12,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.horizontal_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "buttons_shadow_vertical_offset",
          "min": -12,
          "max": 12,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.vertical_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "buttons_shadow_blur",
          "min": 0,
          "max": 20,
          "step": 5,
          "unit": "px",
          "label": "t:settings_schema.global.settings.blur.label",
          "default": 0
        }
      ]
    },
    "variant_pills": {
      "name": "t:settings_schema.variant_pills.name",
      "settings": [
        {
          "type": "paragraph",
          "content": "t:settings_schema.variant_pills.paragraph"
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__border.content"
        },
        {
          "type": "range",
          "id": "variant_pills_border_thickness",
          "min": 0,
          "max": 12,
          "step": 1,
          "unit": "px",
          "label": "t:settings_schema.global.settings.thickness.label",
          "default": 1
        },
        {
          "type": "range",
          "id": "variant_pills_border_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 55
        },
        {
          "type": "range",
          "id": "variant_pills_radius",
          "min": 0,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.corner_radius.label",
          "default": 40
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__shadow.content"
        },
        {
          "type": "range",
          "id": "variant_pills_shadow_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "variant_pills_shadow_horizontal_offset",
          "min": -12,
          "max": 12,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.horizontal_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "variant_pills_shadow_vertical_offset",
          "min": -12,
          "max": 12,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.vertical_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "variant_pills_shadow_blur",
          "min": 0,
          "max": 20,
          "step": 5,
          "unit": "px",
          "label": "t:settings_schema.global.settings.blur.label",
          "default": 0
        }
      ]
    },
    "inputs": {
      "name": "t:settings_schema.inputs.name",
      "settings": [
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__border.content"
        },
        {
          "type": "range",
          "id": "inputs_border_thickness",
          "min": 0,
          "max": 12,
          "step": 1,
          "unit": "px",
          "label": "t:settings_schema.global.settings.thickness.label",
          "default": 1
        },
        {
          "type": "range",
          "id": "inputs_border_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 55
        },
        {
          "type": "range",
          "id": "inputs_radius",
          "min": 0,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.corner_radius.label",
          "default": 0
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__shadow.content"
        },
        {
          "type": "range",
          "id": "inputs_shadow_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "inputs_shadow_horizontal_offset",
          "min": -12,
          "max": 12,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.horizontal_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "inputs_shadow_vertical_offset",
          "min": -12,
          "max": 12,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.vertical_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "inputs_shadow_blur",
          "min": 0,
          "max": 20,
          "step": 5,
          "unit": "px",
          "label": "t:settings_schema.global.settings.blur.label",
          "default": 0
        }
      ]
    },
    "cards": {
      "name": "t:settings_schema.cards.name",
      "settings": [
        {
          "type": "select",
          "id": "card_style",
          "options": [
            {
              "value": "standard",
              "label": "t:settings_schema.cards.settings.style.options__1.label"
            },
            {
              "value": "card",
              "label": "t:settings_schema.cards.settings.style.options__2.label"
            }
          ],
          "default": "standard",
          "label": "t:settings_schema.cards.settings.style.label"
        },
        {
          "type": "range",
          "id": "card_image_padding",
          "min": 0,
          "max": 20,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.image_padding.label",
          "default": 0
        },
        {
          "type": "select",
          "id": "card_text_alignment",
          "options": [
            {
              "value": "left",
              "label": "t:settings_schema.global.settings.text_alignment.options__1.label"
            },
            {
              "value": "center",
              "label": "t:settings_schema.global.settings.text_alignment.options__2.label"
            },
            {
              "value": "right",
              "label": "t:settings_schema.global.settings.text_alignment.options__3.label"
            }
          ],
          "default": "left",
          "label": "t:settings_schema.global.settings.text_alignment.label"
        },
        {
          "type": "select",
          "id": "card_color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-2",
          "label": "t:sections.all.colors.label"
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__border.content"
        },
        {
          "type": "range",
          "id": "card_border_thickness",
          "min": 0,
          "max": 24,
          "step": 1,
          "unit": "px",
          "label": "t:settings_schema.global.settings.thickness.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "card_border_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "card_corner_radius",
          "min": 0,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.corner_radius.label",
          "default": 0
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__shadow.content"
        },
        {
          "type": "range",
          "id": "card_shadow_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 10
        },
        {
          "type": "range",
          "id": "card_shadow_horizontal_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.horizontal_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "card_shadow_vertical_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.vertical_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "card_shadow_blur",
          "min": 0,
          "max": 40,
          "step": 5,
          "unit": "px",
          "label": "t:settings_schema.global.settings.blur.label",
          "default": 0
        }
      ]
    },
    "collection_cards": {
      "name": "t:settings_schema.collection_cards.name",
      "settings": [
        {
          "type": "select",
          "id": "collection_card_style",
          "options": [
            {
              "value": "standard",
              "label": "t:settings_schema.collection_cards.settings.style.options__1.label"
            },
            {
              "value": "card",
              "label": "t:settings_schema.collection_cards.settings.style.options__2.label"
            }
          ],
          "default": "standard",
          "label": "t:settings_schema.collection_cards.settings.style.label"
        },
        {
          "type": "range",
          "id": "collection_card_image_padding",
          "min": 0,
          "max": 20,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.image_padding.label",
          "default": 0
        },
        {
          "type": "select",
          "id": "collection_card_text_alignment",
          "options": [
            {
              "value": "left",
              "label": "t:settings_schema.global.settings.text_alignment.options__1.label"
            },
            {
              "value": "center",
              "label": "t:settings_schema.global.settings.text_alignment.options__2.label"
            },
            {
              "value": "right",
              "label": "t:settings_schema.global.settings.text_alignment.options__3.label"
            }
          ],
          "default": "left",
          "label": "t:settings_schema.global.settings.text_alignment.label"
        },
        {
          "type": "select",
          "id": "collection_card_color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-2",
          "label": "t:sections.all.colors.label"
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__border.content"
        },
        {
          "type": "range",
          "id": "collection_card_border_thickness",
          "min": 0,
          "max": 24,
          "step": 1,
          "unit": "px",
          "label": "t:settings_schema.global.settings.thickness.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "collection_card_border_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "collection_card_corner_radius",
          "min": 0,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.corner_radius.label",
          "default": 0
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__shadow.content"
        },
        {
          "type": "range",
          "id": "collection_card_shadow_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 10
        },
        {
          "type": "range",
          "id": "collection_card_shadow_horizontal_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.horizontal_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "collection_card_shadow_vertical_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.vertical_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "collection_card_shadow_blur",
          "min": 0,
          "max": 40,
          "step": 5,
          "unit": "px",
          "label": "t:settings_schema.global.settings.blur.label",
          "default": 0
        }
      ]
    },
    "blog_cards": {
      "name": "t:settings_schema.blog_cards.name",
      "settings": [
        {
          "type": "select",
          "id": "blog_card_style",
          "options": [
            {
              "value": "standard",
              "label": "t:settings_schema.blog_cards.settings.style.options__1.label"
            },
            {
              "value": "card",
              "label": "t:settings_schema.blog_cards.settings.style.options__2.label"
            }
          ],
          "default": "standard",
          "label": "t:settings_schema.blog_cards.settings.style.label"
        },
        {
          "type": "range",
          "id": "blog_card_image_padding",
          "min": 0,
          "max": 20,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.image_padding.label",
          "default": 0
        },
        {
          "type": "select",
          "id": "blog_card_text_alignment",
          "options": [
            {
              "value": "left",
              "label": "t:settings_schema.global.settings.text_alignment.options__1.label"
            },
            {
              "value": "center",
              "label": "t:settings_schema.global.settings.text_alignment.options__2.label"
            },
            {
              "value": "right",
              "label": "t:settings_schema.global.settings.text_alignment.options__3.label"
            }
          ],
          "default": "left",
          "label": "t:settings_schema.global.settings.text_alignment.label"
        },
        {
          "type": "select",
          "id": "blog_card_color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "background-2",
          "label": "t:sections.all.colors.label"
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__border.content"
        },
        {
          "type": "range",
          "id": "blog_card_border_thickness",
          "min": 0,
          "max": 24,
          "step": 1,
          "unit": "px",
          "label": "t:settings_schema.global.settings.thickness.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "blog_card_border_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "blog_card_corner_radius",
          "min": 0,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.corner_radius.label",
          "default": 0
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__shadow.content"
        },
        {
          "type": "range",
          "id": "blog_card_shadow_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 10
        },
        {
          "type": "range",
          "id": "blog_card_shadow_horizontal_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.horizontal_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "blog_card_shadow_vertical_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.vertical_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "blog_card_shadow_blur",
          "min": 0,
          "max": 40,
          "step": 5,
          "unit": "px",
          "label": "t:settings_schema.global.settings.blur.label",
          "default": 0
        }
      ]
    },
    "content_containers": {
      "name": "t:settings_schema.content_containers.name",
      "settings": [
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__border.content"
        },
        {
          "type": "range",
          "id": "text_boxes_border_thickness",
          "min": 0,
          "max": 24,
          "step": 1,
          "unit": "px",
          "label": "t:settings_schema.global.settings.thickness.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "text_boxes_border_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "text_boxes_radius",
          "min": 0,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.corner_radius.label",
          "default": 0
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__shadow.content"
        },
        {
          "type": "range",
          "id": "text_boxes_shadow_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "text_boxes_shadow_horizontal_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.horizontal_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "text_boxes_shadow_vertical_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.vertical_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "text_boxes_shadow_blur",
          "min": 0,
          "max": 40,
          "step": 5,
          "unit": "px",
          "label": "t:settings_schema.global.settings.blur.label",
          "default": 0
        }
      ]
    },
    "media": {
      "name": "t:settings_schema.media.name",
      "settings": [
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__border.content"
        },
        {
          "type": "range",
          "id": "media_border_thickness",
          "min": 0,
          "max": 24,
          "step": 1,
          "unit": "px",
          "label": "t:settings_schema.global.settings.thickness.label",
          "default": 1
        },
        {
          "type": "range",
          "id": "media_border_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 5
        },
        {
          "type": "range",
          "id": "media_radius",
          "min": 0,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.corner_radius.label",
          "default": 0
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__shadow.content"
        },
        {
          "type": "range",
          "id": "media_shadow_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "media_shadow_horizontal_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.horizontal_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "media_shadow_vertical_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.vertical_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "media_shadow_blur",
          "min": 0,
          "max": 40,
          "step": 5,
          "unit": "px",
          "label": "t:settings_schema.global.settings.blur.label",
          "default": 0
        }
      ]
    },
    "popups": {
      "name": "t:settings_schema.popups.name",
      "settings": [
        {
          "type": "paragraph",
          "content": "t:settings_schema.popups.paragraph"
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__border.content"
        },
        {
          "type": "range",
          "id": "popup_border_thickness",
          "min": 0,
          "max": 24,
          "step": 1,
          "unit": "px",
          "label": "t:settings_schema.global.settings.thickness.label",
          "default": 1
        },
        {
          "type": "range",
          "id": "popup_border_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 10
        },
        {
          "type": "range",
          "id": "popup_corner_radius",
          "min": 0,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.corner_radius.label",
          "default": 0
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__shadow.content"
        },
        {
          "type": "range",
          "id": "popup_shadow_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "popup_shadow_horizontal_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.horizontal_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "popup_shadow_vertical_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.vertical_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "popup_shadow_blur",
          "min": 0,
          "max": 40,
          "step": 5,
          "unit": "px",
          "label": "t:settings_schema.global.settings.blur.label",
          "default": 0
        }
      ]
    },
    "drawers": {
      "name": "t:settings_schema.drawers.name",
      "settings": [
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__border.content"
        },
        {
          "type": "range",
          "id": "drawer_border_thickness",
          "min": 0,
          "max": 24,
          "step": 1,
          "unit": "px",
          "label": "t:settings_schema.global.settings.thickness.label",
          "default": 1
        },
        {
          "type": "range",
          "id": "drawer_border_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 10
        },
        {
          "type": "header",
          "content": "t:settings_schema.global.settings.header__shadow.content"
        },
        {
          "type": "range",
          "id": "drawer_shadow_opacity",
          "min": 0,
          "max": 100,
          "step": 5,
          "unit": "%",
          "label": "t:settings_schema.global.settings.opacity.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "drawer_shadow_horizontal_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.horizontal_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "drawer_shadow_vertical_offset",
          "min": -40,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.vertical_offset.label",
          "default": 0
        },
        {
          "type": "range",
          "id": "drawer_shadow_blur",
          "min": 0,
          "max": 40,
          "step": 5,
          "unit": "px",
          "label": "t:settings_schema.global.settings.blur.label",
          "default": 0
        }
      ]
    },
    "badges": {
      "name": "t:settings_schema.badges.name",
      "settings": [
        {
          "type": "select",
          "id": "badge_position",
          "options": [
            {
              "value": "bottom left",
              "label": "t:settings_schema.badges.settings.position.options__1.label"
            },
            {
              "value": "bottom right",
              "label": "t:settings_schema.badges.settings.position.options__2.label"
            },
            {
              "value": "top left",
              "label": "t:settings_schema.badges.settings.position.options__3.label"
            },
            {
              "value": "top right",
              "label": "t:settings_schema.badges.settings.position.options__4.label"
            }
          ],
          "default": "bottom left",
          "label": "t:settings_schema.badges.settings.position.label"
        },
        {
          "type": "range",
          "id": "badge_corner_radius",
          "min": 0,
          "max": 40,
          "step": 2,
          "unit": "px",
          "label": "t:settings_schema.global.settings.corner_radius.label",
          "default": 40
        },
        {
          "type": "select",
          "id": "sale_badge_color_scheme",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "background-2",
              "label": "t:sections.all.colors.background_2.label"
            }
          ],
          "default": "accent-2",
          "label": "t:settings_schema.badges.settings.sale_badge_color_scheme.label"
        },
        {
          "type": "select",
          "id": "sold_out_badge_color_scheme",
          "options": [
            {
              "value": "background-1",
              "label": "t:sections.all.colors.background_1.label"
            },
            {
              "value": "inverse",
              "label": "t:sections.all.colors.inverse.label"
            }
          ],
          "default": "inverse",
          "label": "t:settings_schema.badges.settings.sold_out_badge_color_scheme.label"
        }
      ]
    },
    "styles": {
      "name": "t:settings_schema.styles.name",
      "settings": [
        {
          "type": "select",
          "id": "accent_icons",
          "options": [
            {
              "value": "accent-1",
              "label": "t:sections.all.colors.accent_1.label"
            },
            {
              "value": "accent-2",
              "label": "t:sections.all.colors.accent_2.label"
            },
            {
              "value": "outline-button",
              "label": "t:settings_schema.styles.settings.accent_icons.options__3.label"
            },
            {
              "value": "text",
              "label": "t:settings_schema.styles.settings.accent_icons.options__4.label"
            }
          ],
          "default": "text",
          "label": "t:settings_schema.styles.settings.accent_icons.label"
        }
      ]
    },
    "social-media": {
      "name": "t:settings_schema.social-media.name",
      "settings": [
        {
          "type": "header",
          "content": "t:settings_schema.social-media.settings.header.content"
        },
        {
          "type": "text",
          "id": "social_twitter_link",
          "label": "t:settings_schema.social-media.settings.social_twitter_link.label",
          "info": "t:settings_schema.social-media.settings.social_twitter_link.info"
        },
        {
          "type": "text",
          "id": "social_facebook_link",
          "label": "t:settings_schema.social-media.settings.social_facebook_link.label",
          "info": "t:settings_schema.social-media.settings.social_facebook_link.info"
        },
        {
          "type": "text",
          "id": "social_pinterest_link",
          "label": "t:settings_schema.social-media.settings.social_pinterest_link.label",
          "info": "t:settings_schema.social-media.settings.social_pinterest_link.info"
        },
        {
          "type": "text",
          "id": "social_instagram_link",
          "label": "t:settings_schema.social-media.settings.social_instagram_link.label",
          "info": "t:settings_schema.social-media.settings.social_instagram_link.info"
        },
        {
          "type": "text",
          "id": "social_tiktok_link",
          "label": "t:settings_schema.social-media.settings.social_tiktok_link.label",
          "info": "t:settings_schema.social-media.settings.social_tiktok_link.info"
        },
        {
          "type": "text",
          "id": "social_tumblr_link",
          "label": "t:settings_schema.social-media.settings.social_tumblr_link.label",
          "info": "t:settings_schema.social-media.settings.social_tumblr_link.info"
        },
        {
          "type": "text",
          "id": "social_snapchat_link",
          "label": "t:settings_schema.social-media.settings.social_snapchat_link.label",
          "info": "t:settings_schema.social-media.settings.social_snapchat_link.info"
        },
        {
          "type": "text",
          "id": "social_youtube_link",
          "label": "t:settings_schema.social-media.settings.social_youtube_link.label",
          "info": "t:settings_schema.social-media.settings.social_youtube_link.info"
        },
        {
          "type": "text",
          "id": "social_vimeo_link",
          "label": "t:settings_schema.social-media.settings.social_vimeo_link.label",
          "info": "t:settings_schema.social-media.settings.social_vimeo_link.info"
        }
      ]
    },
    "search_input": {
      "name": "t:settings_schema.search_input.name",
      "settings": [
        {
          "type": "header",
          "content": "t:settings_schema.search_input.settings.header.content"
        },
        {
          "type": "checkbox",
          "id": "predictive_search_enabled",
          "default": true,
          "label": "t:settings_schema.search_input.settings.predictive_search_enabled.label"
        },
        {
          "type": "checkbox",
          "id": "predictive_search_show_vendor",
          "default": false,
          "label": "t:settings_schema.search_input.settings.predictive_search_show_vendor.label",
          "info": "t:settings_schema.search_input.settings.predictive_search_show_vendor.info"
        },
        {
          "type": "checkbox",
          "id": "predictive_search_show_price",
          "default": false,
          "label": "t:settings_schema.search_input.settings.predictive_search_show_price.label",
          "info": "t:settings_schema.search_input.settings.predictive_search_show_price.info"
        }
      ]
    },
    "favicon": {
      "name": "t:settings_schema.favicon.name",
      "settings": [
        {
          "type": "image_picker",
          "id": "favicon",
          "label": "t:settings_schema.favicon.settings.favicon.label",
          "info": "t:settings_schema.favicon.settings.favicon.info"
        }
      ]
    },
    "currency_format": {
      "name": "t:settings_schema.currency_format.name",
      "settings": [
        {
          "type": "header",
          "content": "t:settings_schema.currency_format.settings.content"
        },
        {
          "type": "paragraph",
          "content": "t:settings_schema.currency_format.settings.paragraph"
        },
        {
          "type": "checkbox",
          "id": "currency_code_enabled",
          "label": "t:settings_schema.currency_format.settings.currency_code_enabled.label",
          "default": true
        }
      ]
    },
    "cart": {
      "name": "t:settings_schema.cart.name",
      "settings": [
        {
          "type": "select",
          "id": "cart_type",
          "options": [
            {
              "value": "drawer",
              "label": "t:settings_schema.cart.settings.cart_type.drawer.label"
            },
            {
              "value": "page",
              "label": "t:settings_schema.cart.settings.cart_type.page.label"
            },
            {
              "value": "notification",
              "label": "t:settings_schema.cart.settings.cart_type.notification.label"
            }
          ],
          "default": "notification",
          "label": "t:settings_schema.cart.settings.cart_type.label"
        },
        {
          "type": "checkbox",
          "id": "show_vendor",
          "label": "t:settings_schema.cart.settings.show_vendor.label",
          "default": false
        },
        {
          "type": "checkbox",
          "id": "show_cart_note",
          "label": "t:settings_schema.cart.settings.show_cart_note.label",
          "default": false
        },
        {
          "type": "header",
          "content": "t:settings_schema.cart.settings.cart_drawer.header"
        },
        {
          "type": "collection",
          "id": "cart_drawer_collection",
          "label": "t:settings_schema.cart.settings.cart_drawer.collection.label",
          "info": "t:settings_schema.cart.settings.cart_drawer.collection.info"
        }
      ]
    }
  }
  public static readonly globeSettingLabelEf420 = {
    "settings_schema": {
      "global": {
        "settings": {
          "header__border": {
            "content": "Border"
          },
          "header__shadow": {
            "content": "Shadow"
          },
          "blur": {
            "label": "Blur"
          },
          "corner_radius": {
            "label": "Corner radius"
          },
          "horizontal_offset": {
            "label": "Horizontal offset"
          },
          "vertical_offset": {
            "label": "Vertical offset"
          },
          "thickness": {
            "label": "Thickness"
          },
          "opacity": {
            "label": "Opacity"
          },
          "image_padding": {
            "label": "Image padding"
          },
          "text_alignment": {
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            },
            "label": "Text alignment"
          }
        }
      },
      "cards": {
        "name": "Product cards",
        "settings": {
          "style": {
            "options__1": {
              "label": "Standard"
            },
            "options__2": {
              "label": "Card"
            },
            "label": "Style"
          }
        }
      },
      "collection_cards": {
        "name": "Collection cards",
        "settings": {
          "style": {
            "options__1": {
              "label": "Standard"
            },
            "options__2": {
              "label": "Card"
            },
            "label": "Style"
          }
        }
      },
      "blog_cards": {
        "name": "Blog cards",
        "settings": {
          "style": {
            "options__1": {
              "label": "Standard"
            },
            "options__2": {
              "label": "Card"
            },
            "label": "Style"
          }
        }
      },
      "badges": {
        "name": "Badges",
        "settings": {
          "position": {
            "options__1": {
              "label": "Bottom left"
            },
            "options__2": {
              "label": "Bottom right"
            },
            "options__3": {
              "label": "Top left"
            },
            "options__4": {
              "label": "Top right"
            },
            "label": "Position on cards"
          },
          "sale_badge_color_scheme": {
            "label": "Sale badge color scheme"
          },
          "sold_out_badge_color_scheme": {
            "label": "Sold out badge color scheme"
          }
        }
      },
      "colors": {
        "name": "Colors",
        "settings": {
          "colors_solid_button_labels": {
            "label": "Solid button label",
            "info": "Used as foreground color on accent colors."
          },
          "colors_accent_1": {
            "label": "Accent 1",
            "info": "Used for solid button background."
          },
          "gradient_accent_1": {
            "label": "Accent 1 gradient"
          },
          "colors_accent_2": {
            "label": "Accent 2"
          },
          "gradient_accent_2": {
            "label": "Accent 2 gradient"
          },
          "header__1": {
            "content": "Primary colors"
          },
          "header__2": {
            "content": "Secondary colors"
          },
          "colors_text": {
            "label": "Text",
            "info": "Used as foreground color on background colors."
          },
          "colors_outline_button_labels": {
            "label": "Outline button",
            "info": "Also used for text links."
          },
          "colors_background_1": {
            "label": "Background 1"
          },
          "gradient_background_1": {
            "label": "Background 1 gradient"
          },
          "colors_background_2": {
            "label": "Background 2"
          },
          "gradient_background_2": {
            "label": "Background 2 gradient"
          }
        }
      },
      "typography": {
        "name": "Typography",
        "settings": {
          "type_header_font": {
            "label": "Font",
            "info": "Selecting a different font can affect the speed of your store. [Learn more about system fonts.](https://help.shopify.com/manual/online-store/os/store-speed/improving-speed#fonts)"
          },
          "heading_scale": {
            "label": "Font size scale"
          },
          "header__1": {
            "content": "Headings"
          },
          "header__2": {
            "content": "Body"
          },
          "type_body_font": {
            "label": "Font",
            "info": "Selecting a different font can affect the speed of your store. [Learn more about system fonts.](https://help.shopify.com/manual/online-store/os/store-speed/improving-speed#fonts)"
          },
          "body_scale": {
            "label": "Font size scale"
          }
        }
      },
      "buttons": {
        "name": "Buttons"
      },
      "variant_pills": {
        "name": "Variant pills",
        "paragraph": "Variant pills are one way of displaying your product variants. [Learn more](https://help.shopify.com/en/manual/online-store/themes/theme-structure/page-types#variant-picker-block)"
      },
      "inputs": {
        "name": "Inputs"
      },
      "content_containers": {
        "name": "Content containers"
      },
      "popups": {
        "name": "Dropdowns and pop-ups",
        "paragraph": "Affects areas like navigation dropdowns, pop-up modals, and cart pop-ups."
      },
      "media": {
        "name": "Media"
      },
      "drawers": {
        "name": "Drawers"
      },
      "styles": {
        "name": "Icons",
        "settings": {
          "accent_icons": {
            "options__3": {
              "label": "Outline button"
            },
            "options__4": {
              "label": "Text"
            },
            "label": "Color"
          }
        }
      },
      "social-media": {
        "name": "Social media",
        "settings": {
          "header": {
            "content": "Social accounts"
          },
          "social_twitter_link": {
            "label": "Twitter",
            "info": "https://twitter.com/shopify"
          },
          "social_facebook_link": {
            "label": "Facebook",
            "info": "https://facebook.com/shopify"
          },
          "social_pinterest_link": {
            "label": "Pinterest",
            "info": "https://pinterest.com/shopify"
          },
          "social_instagram_link": {
            "label": "Instagram",
            "info": "http://instagram.com/shopify"
          },
          "social_tiktok_link": {
            "label": "TikTok",
            "info": "https://tiktok.com/@shopify"
          },
          "social_tumblr_link": {
            "label": "Tumblr",
            "info": "https://shopify.tumblr.com"
          },
          "social_snapchat_link": {
            "label": "Snapchat",
            "info": "https://www.snapchat.com/add/shopify"
          },
          "social_youtube_link": {
            "label": "YouTube",
            "info": "https://www.youtube.com/shopify"
          },
          "social_vimeo_link": {
            "label": "Vimeo",
            "info": "https://vimeo.com/shopify"
          }
        }
      },
      "search_input": {
        "name": "Search behavior",
        "settings": {
          "header": {
            "content": "Product suggestions"
          },
          "predictive_search_enabled": {
            "label": "Enable product suggestions"
          },
          "predictive_search_show_vendor": {
            "label": "Show vendor",
            "info": "Visible when product suggestions enabled."
          },
          "predictive_search_show_price": {
            "label": "Show price",
            "info": "Visible when product suggestions enabled."
          }
        }
      },
      "favicon": {
        "name": "Favicon",
        "settings": {
          "favicon": {
            "label": "Favicon image",
            "info": "Will be scaled down to 32 x 32px"
          }
        }
      },
      "currency_format": {
        "name": "Currency format",
        "settings": {
          "content": "Currency codes",
          "paragraph": "Cart and checkout prices always show currency codes. Example: $1.00 USD.",
          "currency_code_enabled": {
            "label": "Show currency codes"
          }
        }
      },
      "cart": {
        "name": "Cart",
        "settings": {
          "cart_type": {
            "label": "Cart type",
            "drawer": {
              "label": "Drawer"
            },
            "page": {
              "label": "Page"
            },
            "notification": {
              "label": "Popup notification"
            }
          },
          "show_vendor": {
            "label": "Show vendor"
          },
          "show_cart_note": {
            "label": "Enable cart note"
          },
          "cart_drawer": {
            "header": "Cart drawer",
            "collection": {
              "label": "Collection",
              "info": "Visible when cart drawer is empty."
            }
          }
        }
      },
      "layout": {
        "name": "Layout",
        "settings": {
          "page_width": {
            "label": "Page width"
          },
          "spacing_sections": {
            "label": "Vertical space between sections"
          },
          "header__grid": {
            "content": "Grid"
          },
          "paragraph__grid": {
            "content": "Affects areas with a multicolumn layout."
          },
          "spacing_grid_horizontal": {
            "label": "Horizontal space"
          },
          "spacing_grid_vertical": {
            "label": "Vertical space"
          }
        }
      }
    },
    "sections": {
      "all": {
        "padding": {
          "section_padding_heading": "Section padding",
          "padding_top": "Top padding",
          "padding_bottom": "Bottom padding"
        },
        "spacing": "Spacing",
        "colors": {
          "accent_1": {
            "label": "Accent 1"
          },
          "accent_2": {
            "label": "Accent 2"
          },
          "background_1": {
            "label": "Background 1"
          },
          "background_2": {
            "label": "Background 2"
          },
          "inverse": {
            "label": "Inverse"
          },
          "label": "Color scheme",
          "has_cards_info": "To change the card color scheme, update your theme settings."
        },
        "heading_size": {
          "label": "Heading size",
          "options__1": {
            "label": "Small"
          },
          "options__2": {
            "label": "Medium"
          },
          "options__3": {
            "label": "Large"
          },
          "options__4": {
            "label": "Extra large"
          }
        }
      },
      "announcement-bar": {
        "name": "Announcement bar",
        "blocks": {
          "announcement": {
            "name": "Announcement",
            "settings": {
              "text": {
                "label": "Text"
              },
              "text_alignment": {
                "label": "Text alignment",
                "options__1": {
                  "label": "Left"
                },
                "options__2": {
                  "label": "Center"
                },
                "options__3": {
                  "label": "Right"
                }
              },
              "link": {
                "label": "Link"
              }
            }
          }
        }
      },
      "apps": {
        "name": "Apps",
        "settings": {
          "include_margins": {
            "label": "Make section margins the same as theme"
          }
        },
        "presets": {
          "name": "Apps"
        }
      },
      "collage": {
        "name": "Collage",
        "settings": {
          "heading": {
            "label": "Heading"
          },
          "desktop_layout": {
            "label": "Desktop layout",
            "options__1": {
              "label": "Left large block"
            },
            "options__2": {
              "label": "Right large block"
            }
          },
          "mobile_layout": {
            "label": "Mobile layout",
            "options__1": {
              "label": "Collage"
            },
            "options__2": {
              "label": "Column"
            }
          },
          "card_styles": {
            "label": "Card style",
            "info": "Product, collection, and blog card styles can be updated in theme settings.",
            "options__1": {
              "label": "Use individual card styles"
            },
            "options__2": {
              "label": "Style all as product cards"
            }
          }
        },
        "blocks": {
          "image": {
            "name": "Image",
            "settings": {
              "image": {
                "label": "Image"
              },
              "image_padding": {
                "label": "Use original image ratio",
                "info": "Select if you don't want your image to be cropped."
              }
            }
          },
          "product": {
            "name": "Product",
            "settings": {
              "product": {
                "label": "Product"
              },
              "secondary_background": {
                "label": "Show secondary background"
              },
              "second_image": {
                "label": "Show second image on hover"
              }
            }
          },
          "collection": {
            "name": "Collection",
            "settings": {
              "collection": {
                "label": "Collection"
              }
            }
          },
          "video": {
            "name": "Video",
            "settings": {
              "cover_image": {
                "label": "Cover image"
              },
              "video_url": {
                "label": "URL",
                "info": "Video plays in a pop-up if the section contains other blocks.",
                "placeholder": "Use a YouTube or Vimeo URL"
              },
              "image_padding": {
                "label": "Use original image ratio",
                "info": "Select if you don't want your image to be cropped."
              },
              "description": {
                "label": "Video alt text",
                "info": "Describe the video for customers using screen readers. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/theme-features#video-block)"
              }
            }
          }
        },
        "presets": {
          "name": "Collage"
        }
      },
      "collection-list": {
        "name": "Collection list",
        "settings": {
          "title": {
            "label": "Heading"
          },
          "image_ratio": {
            "label": "Image ratio",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Portrait"
            },
            "options__3": {
              "label": "Square"
            },
            "info": "Add images by editing your collections. [Learn more](https://help.shopify.com/manual/products/collections)"
          },
          "columns_desktop": {
            "label": "Number of columns on desktop"
          },
          "show_view_all": {
            "label": "Enable \"View all\" button if list includes more collections than shown"
          },
          "header_mobile": {
            "content": "Mobile Layout"
          },
          "columns_mobile": {
            "label": "Number of columns on mobile",
            "options__1": {
              "label": "1 column"
            },
            "options__2": {
              "label": "2 columns"
            }
          },
          "swipe_on_mobile": {
            "label": "Enable swipe on mobile"
          }
        },
        "blocks": {
          "featured_collection": {
            "name": "Collection",
            "settings": {
              "collection": {
                "label": "Collection"
              }
            }
          }
        },
        "presets": {
          "name": "Collection list"
        }
      },
      "contact-form": {
        "name": "Contact Form",
        "presets": {
          "name": "Contact form"
        }
      },
      "custom-liquid": {
        "name": "Custom Liquid",
        "settings": {
          "custom_liquid": {
            "label": "Custom Liquid",
            "info": "Add app snippets or other Liquid code to create advanced customizations."
          }
        },
        "presets": {
          "name": "Custom Liquid"
        }
      },
      "featured-blog": {
        "name": "Blog posts",
        "settings": {
          "heading": {
            "label": "Heading"
          },
          "blog": {
            "label": "Blog"
          },
          "post_limit": {
            "label": "Number of blog posts to show"
          },
          "columns_desktop": {
            "label": "Number of columns on desktop"
          },
          "show_view_all": {
            "label": "Enable \"View all\" button if blog includes more blog posts than shown"
          },
          "show_image": {
            "label": "Show featured image",
            "info": "For best results, use an image with a 3:2 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
          },
          "show_date": {
            "label": "Show date"
          },
          "show_author": {
            "label": "Show author"
          }
        },
        "presets": {
          "name": "Blog posts"
        }
      },
      "featured-collection": {
        "name": "Featured collection",
        "settings": {
          "title": {
            "label": "Heading"
          },
          "description": {
            "label": "Description"
          },
          "show_description": {
            "label": "Show collection description from the admin"
          },
          "description_style": {
            "label": "Description style",
            "options__1": {
              "label": "Body"
            },
            "options__2": {
              "label": "Subtitle"
            },
            "options__3": {
              "label": "Uppercase"
            }
          },
          "collection": {
            "label": "Collection"
          },
          "products_to_show": {
            "label": "Maximum products to show"
          },
          "columns_desktop": {
            "label": "Number of columns on desktop"
          },
          "show_view_all": {
            "label": "Enable \"View all\" if collection has more products than shown"
          },
          "view_all_style": {
            "label": "\"View all\" style",
            "options__1": {
              "label": "Link"
            },
            "options__2": {
              "label": "Outline button"
            },
            "options__3": {
              "label": "Solid button"
            }
          },
          "enable_desktop_slider": {
            "label": "Enable carousel on desktop"
          },
          "full_width": {
            "label": "Make products full width"
          },
          "header": {
            "content": "Product card"
          },
          "image_ratio": {
            "label": "Image ratio",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Portrait"
            },
            "options__3": {
              "label": "Square"
            }
          },
          "show_secondary_image": {
            "label": "Show second image on hover"
          },
          "show_vendor": {
            "label": "Show vendor"
          },
          "show_rating": {
            "label": "Show product rating",
            "info": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/theme-features#featured-collection-show-product-rating)"
          },
          "enable_quick_buy": {
            "label": "Enable quick add button",
            "info": "Optimal with popup or drawer cart type."
          },
          "header_mobile": {
            "content": "Mobile Layout"
          },
          "columns_mobile": {
            "label": "Number of columns on mobile",
            "options__1": {
              "label": "1 column"
            },
            "options__2": {
              "label": "2 columns"
            }
          },
          "swipe_on_mobile": {
            "label": "Enable swipe on mobile"
          }
        },
        "presets": {
          "name": "Featured collection"
        }
      },
      "featured-product": {
        "name": "Featured product",
        "blocks": {
          "text": {
            "name": "Text",
            "settings": {
              "text": {
                "label": "Text"
              },
              "text_style": {
                "label": "Text style",
                "options__1": {
                  "label": "Body"
                },
                "options__2": {
                  "label": "Subtitle"
                },
                "options__3": {
                  "label": "Uppercase"
                }
              }
            }
          },
          "title": {
            "name": "Title"
          },
          "price": {
            "name": "Price"
          },
          "quantity_selector": {
            "name": "Quantity selector"
          },
          "variant_picker": {
            "name": "Variant picker",
            "settings": {
              "picker_type": {
                "label": "Type",
                "options__1": {
                  "label": "Dropdown"
                },
                "options__2": {
                  "label": "Pills"
                }
              }
            }
          },
          "buy_buttons": {
            "name": "Buy buttons",
            "settings": {
              "show_dynamic_checkout": {
                "label": "Show dynamic checkout buttons",
                "info": "Using the payment methods available on your store, customers see their preferred option, like PayPal or Apple Pay. [Learn more](https://help.shopify.com/manual/using-themes/change-the-layout/dynamic-checkout)"
              }
            }
          },
          "description": {
            "name": "Description"
          },
          "share": {
            "name": "Share",
            "settings": {
              "text": {
                "label": "Text"
              },
              "featured_image_info": {
                "content": "If you include a link in social media posts, the page’s featured image will be shown as the preview image. [Learn more](https://help.shopify.com/manual/online-store/images/showing-social-media-thumbnail-images)"
              },
              "title_info": {
                "content": "A store title and description are included with the preview image. [Learn more](https://help.shopify.com/manual/promoting-marketing/seo/adding-keywords#set-a-title-and-description-for-your-online-store)"
              }
            }
          },
          "custom_liquid": {
            "name": "Custom liquid",
            "settings": {
              "custom_liquid": {
                "label": "Custom liquid"
              }
            }
          },
          "rating": {
            "name": "Product rating",
            "settings": {
              "paragraph": {
                "content": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/theme-features#featured-product-rating)"
              }
            }
          }
        },
        "settings": {
          "product": {
            "label": "Product"
          },
          "secondary_background": {
            "label": "Show secondary background"
          },
          "header": {
            "content": "Media",
            "info": "Learn more about [media types](https://help.shopify.com/manual/products/product-media)"
          },
          "media_position": {
            "label": "Desktop media position",
            "info": "Position is automatically optimized for mobile.",
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Right"
            }
          },
          "hide_variants": {
            "label": "Hide unselected variants’ media on desktop"
          },
          "enable_video_looping": {
            "label": "Enable video looping"
          }
        },
        "presets": {
          "name": "Featured product"
        }
      },
      "footer": {
        "name": "Footer",
        "blocks": {
          "link_list": {
            "name": "Menu",
            "settings": {
              "heading": {
                "label": "Heading"
              },
              "menu": {
                "label": "Menu",
                "info": "Displays only top-level menu items."
              }
            }
          },
          "text": {
            "name": "Text",
            "settings": {
              "heading": {
                "label": "Heading"
              },
              "subtext": {
                "label": "Subtext"
              }
            }
          }
        },
        "settings": {
          "newsletter_enable": {
            "label": "Show email signup"
          },
          "newsletter_heading": {
            "label": "Heading"
          },
          "header__1": {
            "content": "Email Signup",
            "info": "Subscribers added automatically to your “accepted marketing” customer list. [Learn more](https://help.shopify.com/manual/customers/manage-customers)"
          },
          "header__2": {
            "content": "Social media icons",
            "info": "To display your social media accounts, link them in your theme settings."
          },
          "show_social": {
            "label": "Show social media icons"
          },
          "header__3": {
            "content": "Country/region selector"
          },
          "header__4": {
            "info": "To add a country/region, go to your [payment settings.](/admin/settings/payments)"
          },
          "enable_country_selector": {
            "label": "Enable country/region selector"
          },
          "header__5": {
            "content": "Language selector"
          },
          "header__6": {
            "info": "To add a language, go to your [language settings.](/admin/settings/languages)"
          },
          "enable_language_selector": {
            "label": "Enable language selector"
          },
          "header__7": {
            "content": "Payment methods"
          },
          "payment_enable": {
            "label": "Show payment icons"
          },
          "margin_top": {
            "label": "Top margin"
          }
        }
      },
      "header": {
        "name": "Header",
        "settings": {
          "logo": {
            "label": "Logo image"
          },
          "logo_width": {
            "unit": "px",
            "label": "Custom logo width"
          },
          "logo_position": {
            "label": "Desktop logo position",
            "options__1": {
              "label": "Middle left"
            },
            "options__2": {
              "label": "Top left"
            },
            "options__3": {
              "label": "Top center"
            },
            "options__4": {
              "label": "Middle center"
            }
          },
          "menu": {
            "label": "Menu"
          },
          "menu_type_desktop": {
            "label": "Desktop menu type",
            "info": "Menu type is automatically optimized for mobile.",
            "options__1": {
              "label": "Dropdown"
            },
            "options__2": {
              "label": "Mega menu"
            }
          },
          "show_line_separator": {
            "label": "Show separator line"
          },
          "enable_sticky_header": {
            "label": "Enable sticky header",
            "info": "Header shows on the screen as customers scroll up."
          },
          "margin_bottom": {
            "label": "Bottom margin"
          },
          "mobile_layout": {
            "content": "Mobile layout"
          },
          "mobile_logo_position": {
            "label": "Mobile logo position",
            "options__1": {
              "label": "Center"
            },
            "options__2": {
              "label": "Left"
            }
          }
        }
      },
      "image-banner": {
        "name": "Image banner",
        "settings": {
          "image": {
            "label": "First image"
          },
          "image_2": {
            "label": "Second image"
          },
          "image_overlay_opacity": {
            "label": "Image overlay opacity"
          },
          "image_height": {
            "label": "Banner height",
            "options__1": {
              "label": "Small"
            },
            "options__2": {
              "label": "Medium"
            },
            "options__3": {
              "label": "Large"
            },
            "info": "For best results, use an image with a 3:2 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
          },
          "desktop_content_position": {
            "options__1": {
              "label": "Top Left"
            },
            "options__2": {
              "label": "Top Center"
            },
            "options__3": {
              "label": "Top Right"
            },
            "options__4": {
              "label": "Middle Left"
            },
            "options__5": {
              "label": "Middle Center"
            },
            "options__6": {
              "label": "Middle Right"
            },
            "options__7": {
              "label": "Bottom Left"
            },
            "options__8": {
              "label": "Bottom Center"
            },
            "options__9": {
              "label": "Bottom Right"
            },
            "label": "Desktop content position"
          },
          "show_text_box": {
            "label": "Show container on desktop"
          },
          "desktop_content_alignment": {
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            },
            "label": "Desktop content alignment"
          },
          "color_scheme": {
            "info": "Visible when container displayed."
          },
          "header": {
            "content": "Mobile Layout"
          },
          "mobile_content_alignment": {
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            },
            "label": "Mobile content alignment"
          },
          "stack_images_on_mobile": {
            "label": "Stack images on mobile"
          },
          "show_text_below": {
            "label": "Show container on mobile"
          },
          "adapt_height_first_image": {
            "label": "Adapt section height to first image size",
            "info": "Overwrites image banner height setting when checked."
          }
        },
        "blocks": {
          "heading": {
            "name": "Heading",
            "settings": {
              "heading": {
                "label": "Heading"
              }
            }
          },
          "text": {
            "name": "Text",
            "settings": {
              "text": {
                "label": "Description"
              },
              "text_style": {
                "options__1": {
                  "label": "Body"
                },
                "options__2": {
                  "label": "Subtitle"
                },
                "options__3": {
                  "label": "Uppercase"
                },
                "label": "Text style"
              }
            }
          },
          "buttons": {
            "name": "Buttons",
            "settings": {
              "button_label_1": {
                "label": "First button label",
                "info": "Leave the label blank to hide the button."
              },
              "button_link_1": {
                "label": "First button link"
              },
              "button_style_secondary_1": {
                "label": "Use outline button style"
              },
              "button_label_2": {
                "label": "Second button label",
                "info": "Leave the label blank to hide the button."
              },
              "button_link_2": {
                "label": "Second button link"
              },
              "button_style_secondary_2": {
                "label": "Use outline button style"
              }
            }
          }
        },
        "presets": {
          "name": "Image banner"
        }
      },
      "image-with-text": {
        "name": "Image with text",
        "settings": {
          "image": {
            "label": "Image"
          },
          "height": {
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Small"
            },
            "options__3": {
              "label": "Large"
            },
            "label": "Image height"
          },
          "desktop_image_width": {
            "options__1": {
              "label": "Small"
            },
            "options__2": {
              "label": "Medium"
            },
            "options__3": {
              "label": "Large"
            },
            "label": "Desktop image width",
            "info": "Image is automatically optimized for mobile."
          },
          "layout": {
            "options__1": {
              "label": "Image first"
            },
            "options__2": {
              "label": "Image second"
            },
            "label": "Desktop image placement",
            "info": "Image first is the default mobile layout."
          },
          "desktop_content_alignment": {
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            },
            "label": "Desktop content alignment"
          },
          "desktop_content_position": {
            "options__1": {
              "label": "Top"
            },
            "options__2": {
              "label": "Middle"
            },
            "options__3": {
              "label": "Bottom"
            },
            "label": "Desktop content position"
          },
          "content_layout": {
            "options__1": {
              "label": "No overlap"
            },
            "options__2": {
              "label": "Overlap"
            },
            "label": "Content layout"
          },
          "mobile_content_alignment": {
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            },
            "label": "Mobile content alignment"
          }
        },
        "blocks": {
          "heading": {
            "name": "Heading",
            "settings": {
              "heading": {
                "label": "Heading"
              }
            }
          },
          "caption": {
            "name": "Caption",
            "settings": {
              "text": {
                "label": "Text"
              },
              "text_style": {
                "label": "Text style",
                "options__1": {
                  "label": "Subtitle"
                },
                "options__2": {
                  "label": "Uppercase"
                }
              },
              "caption_size": {
                "label": "Text size",
                "options__1": {
                  "label": "Small"
                },
                "options__2": {
                  "label": "Medium"
                },
                "options__3": {
                  "label": "Large"
                }
              }
            }
          },
          "text": {
            "name": "Text",
            "settings": {
              "text": {
                "label": "Content"
              },
              "text_style": {
                "label": "Text style",
                "options__1": {
                  "label": "Body"
                },
                "options__2": {
                  "label": "Subtitle"
                }
              }
            }
          },
          "button": {
            "name": "Button",
            "settings": {
              "button_label": {
                "label": "Button label",
                "info": "Leave the label blank to hide the button."
              },
              "button_link": {
                "label": "Button link"
              }
            }
          }
        },
        "presets": {
          "name": "Image with text"
        }
      },
      "main-account": {
        "name": "Account"
      },
      "main-activate-account": {
        "name": "Account activation"
      },
      "main-addresses": {
        "name": "Addresses"
      },
      "main-article": {
        "name": "Blog post",
        "blocks": {
          "featured_image": {
            "name": "Featured image",
            "settings": {
              "image_height": {
                "label": "Featured image height",
                "options__1": {
                  "label": "Adapt to image"
                },
                "options__2": {
                  "label": "Small"
                },
                "options__3": {
                  "label": "Medium"
                },
                "options__4": {
                  "label": "Large"
                },
                "info": "For best results, use an image with a 16:9 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
              }
            }
          },
          "title": {
            "name": "Title",
            "settings": {
              "blog_show_date": {
                "label": "Show date"
              },
              "blog_show_author": {
                "label": "Show author"
              }
            }
          },
          "content": {
            "name": "Content"
          },
          "share": {
            "name": "Share",
            "settings": {
              "text": {
                "label": "Text"
              },
              "featured_image_info": {
                "content": "If you include a link in social media posts, the page’s featured image will be shown as the preview image. [Learn more](https://help.shopify.com/manual/online-store/images/showing-social-media-thumbnail-images)."
              },
              "title_info": {
                "content": "A store title and description are included with the preview image. [Learn more](https://help.shopify.com/manual/promoting-marketing/seo/adding-keywords#set-a-title-and-description-for-your-online-store)."
              }
            }
          }
        }
      },
      "main-blog": {
        "name": "Blog posts",
        "settings": {
          "header": {
            "content": "Blog post card"
          },
          "show_image": {
            "label": "Show featured image"
          },
          "show_date": {
            "label": "Show date"
          },
          "show_author": {
            "label": "Show author"
          },
          "paragraph": {
            "content": "Change excerpts by editing your blog posts. [Learn more](https://help.shopify.com/manual/online-store/blogs/writing-blogs#display-an-excerpt-from-a-blog-post)"
          },
          "layout": {
            "label": "Desktop layout",
            "options__1": {
              "label": "Grid"
            },
            "options__2": {
              "label": "Collage"
            },
            "info": "Posts are stacked on mobile."
          },
          "image_height": {
            "label": "Featured image height",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Small"
            },
            "options__3": {
              "label": "Medium"
            },
            "options__4": {
              "label": "Large"
            },
            "info": "For best results, use an image with a 3:2 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
          }
        }
      },
      "main-cart-footer": {
        "name": "Subtotal",
        "blocks": {
          "subtotal": {
            "name": "Subtotal price"
          },
          "buttons": {
            "name": "Checkout button"
          }
        }
      },
      "main-cart-items": {
        "name": "Items"
      },
      "main-collection-banner": {
        "name": "Collection banner",
        "settings": {
          "paragraph": {
            "content": "Add a description or image by editing your collection. [Learn more](https://help.shopify.com/manual/products/collections/collection-layout)"
          },
          "show_collection_description": {
            "label": "Show collection description"
          },
          "show_collection_image": {
            "label": "Show collection image",
            "info": "For best results, use an image with a 16:9 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
          }
        }
      },
      "main-collection-product-grid": {
        "name": "Product grid",
        "settings": {
          "products_per_page": {
            "label": "Products per page"
          },
          "columns_desktop": {
            "label": "Number of columns on desktop"
          },
          "enable_filtering": {
            "label": "Enable filtering",
            "info": "Customize [filters](/admin/menus)"
          },
          "filter_type": {
            "label": "Desktop filter layout",
            "options__1": {
              "label": "Horizontal"
            },
            "options__2": {
              "label": "Vertical"
            },
            "options__3": {
              "label": "Drawer"
            },
            "info": "Drawer is the default mobile layout."
          },
          "enable_sorting": {
            "label": "Enable sorting"
          },
          "image_ratio": {
            "label": "Image ratio",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Portrait"
            },
            "options__3": {
              "label": "Square"
            }
          },
          "show_secondary_image": {
            "label": "Show second image on hover"
          },
          "show_vendor": {
            "label": "Show vendor"
          },
          "show_rating": {
            "label": "Show product rating",
            "info": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/page-types#product-grid-section-settings)"
          },
          "header__1": {
            "content": "Filtering and sorting"
          },
          "header__3": {
            "content": "Product card"
          },
          "enable_tags": {
            "label": "Enable filtering",
            "info": "[Customize filters](/admin/menus)"
          },
          "enable_quick_buy": {
            "label": "Enable quick add button",
            "info": "Optimal with popup or drawer cart type."
          },
          "header_mobile": {
            "content": "Mobile Layout"
          },
          "columns_mobile": {
            "label": "Number of columns on mobile",
            "options__1": {
              "label": "1 column"
            },
            "options__2": {
              "label": "2 columns"
            }
          }
        }
      },
      "main-list-collections": {
        "name": "Collections list page",
        "settings": {
          "title": {
            "label": "Heading"
          },
          "sort": {
            "label": "Sort collections by:",
            "options__1": {
              "label": "Alphabetically, A-Z"
            },
            "options__2": {
              "label": "Alphabetically, Z-A"
            },
            "options__3": {
              "label": "Date, new to old"
            },
            "options__4": {
              "label": "Date, old to new"
            },
            "options__5": {
              "label": "Product count, high to low"
            },
            "options__6": {
              "label": "Product count, low to high"
            }
          },
          "image_ratio": {
            "label": "Image ratio",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Portrait"
            },
            "options__3": {
              "label": "Square"
            },
            "info": "Add images by editing your collections. [Learn more](https://help.shopify.com/manual/products/collections)"
          },
          "columns_desktop": {
            "label": "Number of columns on desktop"
          },
          "header_mobile": {
            "content": "Mobile Layout"
          },
          "columns_mobile": {
            "label": "Number of columns on mobile",
            "options__1": {
              "label": "1 column"
            },
            "options__2": {
              "label": "2 columns"
            }
          }
        }
      },
      "main-login": {
        "name": "Login"
      },
      "main-order": {
        "name": "Order"
      },
      "main-page": {
        "name": "Page"
      },
      "main-password-footer": {
        "name": "Password footer"
      },
      "main-password-header": {
        "name": "Password header",
        "settings": {
          "logo": {
            "label": "Logo image"
          },
          "logo_max_width": {
            "label": "Custom logo width",
            "unit": "px"
          }
        }
      },
      "main-product": {
        "name": "Product information",
        "blocks": {
          "text": {
            "name": "Text",
            "settings": {
              "text": {
                "label": "Text"
              },
              "text_style": {
                "label": "Text style",
                "options__1": {
                  "label": "Body"
                },
                "options__2": {
                  "label": "Subtitle"
                },
                "options__3": {
                  "label": "Uppercase"
                }
              }
            }
          },
          "title": {
            "name": "Title"
          },
          "price": {
            "name": "Price"
          },
          "quantity_selector": {
            "name": "Quantity selector"
          },
          "variant_picker": {
            "name": "Variant picker",
            "settings": {
              "picker_type": {
                "label": "Type",
                "options__1": {
                  "label": "Dropdown"
                },
                "options__2": {
                  "label": "Pills"
                }
              }
            }
          },
          "buy_buttons": {
            "name": "Buy buttons",
            "settings": {
              "show_dynamic_checkout": {
                "label": "Show dynamic checkout buttons",
                "info": "Using the payment methods available on your store, customers see their preferred option, like PayPal or Apple Pay. [Learn more](https://help.shopify.com/manual/using-themes/change-the-layout/dynamic-checkout)"
              }
            }
          },
          "pickup_availability": {
            "name": "Pickup availability"
          },
          "description": {
            "name": "Description"
          },
          "share": {
            "name": "Share",
            "settings": {
              "text": {
                "label": "Text"
              },
              "featured_image_info": {
                "content": "If you include a link in social media posts, the page’s featured image will be shown as the preview image. [Learn more](https://help.shopify.com/manual/online-store/images/showing-social-media-thumbnail-images)."
              },
              "title_info": {
                "content": "A store title and description are included with the preview image. [Learn more](https://help.shopify.com/manual/promoting-marketing/seo/adding-keywords#set-a-title-and-description-for-your-online-store)."
              }
            }
          },
          "custom_liquid": {
            "name": "Custom liquid",
            "settings": {
              "custom_liquid": {
                "label": "Custom liquid",
                "info": "Add app snippets or other Liquid code to create advanced customizations."
              }
            }
          },
          "collapsible_tab": {
            "name": "Collapsible row",
            "settings": {
              "heading": {
                "info": "Include a heading that explains the content.",
                "label": "Heading"
              },
              "content": {
                "label": "Row content"
              },
              "page": {
                "label": "Row content from page"
              },
              "icon": {
                "label": "Icon",
                "options__1": {
                  "label": "None"
                },
                "options__2": {
                  "label": "Apple"
                },
                "options__3": {
                  "label": "Banana"
                },
                "options__4": {
                  "label": "Bottle"
                },
                "options__5": {
                  "label": "Box"
                },
                "options__6": {
                  "label": "Carrot"
                },
                "options__7": {
                  "label": "Chat bubble"
                },
                "options__8": {
                  "label": "Check mark"
                },
                "options__9": {
                  "label": "Clipboard"
                },
                "options__10": {
                  "label": "Dairy"
                },
                "options__11": {
                  "label": "Dairy free"
                },
                "options__12": {
                  "label": "Dryer"
                },
                "options__13": {
                  "label": "Eye"
                },
                "options__14": {
                  "label": "Fire"
                },
                "options__15": {
                  "label": "Gluten free"
                },
                "options__16": {
                  "label": "Heart"
                },
                "options__17": {
                  "label": "Iron"
                },
                "options__18": {
                  "label": "Leaf"
                },
                "options__19": {
                  "label": "Leather"
                },
                "options__20": {
                  "label": "Lightning bolt"
                },
                "options__21": {
                  "label": "Lipstick"
                },
                "options__22": {
                  "label": "Lock"
                },
                "options__23": {
                  "label": "Map pin"
                },
                "options__24": {
                  "label": "Nut free"
                },
                "options__25": {
                  "label": "Pants"
                },
                "options__26": {
                  "label": "Paw print"
                },
                "options__27": {
                  "label": "Pepper"
                },
                "options__28": {
                  "label": "Perfume"
                },
                "options__29": {
                  "label": "Plane"
                },
                "options__30": {
                  "label": "Plant"
                },
                "options__31": {
                  "label": "Price tag"
                },
                "options__32": {
                  "label": "Question mark"
                },
                "options__33": {
                  "label": "Recycle"
                },
                "options__34": {
                  "label": "Return"
                },
                "options__35": {
                  "label": "Ruler"
                },
                "options__36": {
                  "label": "Serving dish"
                },
                "options__37": {
                  "label": "Shirt"
                },
                "options__38": {
                  "label": "Shoe"
                },
                "options__39": {
                  "label": "Silhouette"
                },
                "options__40": {
                  "label": "Snowflake"
                },
                "options__41": {
                  "label": "Star"
                },
                "options__42": {
                  "label": "Stopwatch"
                },
                "options__43": {
                  "label": "Truck"
                },
                "options__44": {
                  "label": "Washing"
                }
              }
            }
          },
          "popup": {
            "name": "Pop-up",
            "settings": {
              "link_label": {
                "label": "Link label"
              },
              "page": {
                "label": "Page"
              }
            }
          },
          "rating": {
            "name": "Product rating",
            "settings": {
              "paragraph": {
                "content": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/page-types#product-rating-block)"
              }
            }
          },
          "complementary_products": {
            "name": "Complementary products",
            "settings": {
              "paragraph": {
                "content": "To select complementary products, add the Search & Discovery app. [Learn more](https://shopify.dev/themes/product-merchandising/recommendations)"
              },
              "heading": {
                "label": "Heading"
              },
              "make_collapsible_row": {
                "label": "Show as collapsible row"
              },
              "icon": {
                "info": "Visible when collapsible row is displayed."
              },
              "product_list_limit": {
                "label": "Maximum products to show"
              },
              "products_per_page": {
                "label": "Number of products per page"
              },
              "pagination_style": {
                "label": "Pagination style",
                "options": {
                  "option_1": "Dots",
                  "option_2": "Counter",
                  "option_3": "Numbers"
                }
              },
              "product_card": {
                "heading": "Product card"
              },
              "image_ratio": {
                "label": "Image ratio",
                "options": {
                  "option_1": "Portrait",
                  "option_2": "Square"
                }
              },
              "enable_quick_add": {
                "label": "Enable quick add button"
              }
            }
          }
        },
        "settings": {
          "header": {
            "content": "Media",
            "info": "Learn more about [media types.](https://help.shopify.com/manual/products/product-media)"
          },
          "enable_sticky_info": {
            "label": "Enable sticky content on desktop"
          },
          "gallery_layout": {
            "label": "Desktop layout",
            "options__1": {
              "label": "Stacked"
            },
            "options__2": {
              "label": "2 columns"
            },
            "options__3": {
              "label": "Thumbnails"
            },
            "options__4": {
              "label": "Thumbnail carousel"
            }
          },
          "media_size": {
            "label": "Desktop media size",
            "info": "Media is automatically optimized for mobile.",
            "options__1": {
              "label": "Small"
            },
            "options__2": {
              "label": "Medium"
            },
            "options__3": {
              "label": "Large"
            }
          },
          "media_position": {
            "label": "Desktop media position",
            "info": "Position is automatically optimized for mobile.",
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Right"
            }
          },
          "mobile_thumbnails": {
            "label": "Mobile layout",
            "options__1": {
              "label": "2 columns"
            },
            "options__2": {
              "label": "Show thumbnails"
            },
            "options__3": {
              "label": "Hide thumbnails"
            }
          },
          "hide_variants": {
            "label": "Hide other variants’ media after selecting a variant"
          },
          "enable_video_looping": {
            "label": "Enable video looping"
          }
        }
      },
      "main-register": {
        "name": "Registration"
      },
      "main-reset-password": {
        "name": "Password reset"
      },
      "main-search": {
        "name": "Search results",
        "settings": {
          "columns_desktop": {
            "label": "Number of columns on desktop"
          },
          "image_ratio": {
            "label": "Image ratio",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Portrait"
            },
            "options__3": {
              "label": "Square"
            }
          },
          "show_secondary_image": {
            "label": "Show second image on hover"
          },
          "show_vendor": {
            "label": "Show vendor"
          },
          "show_rating": {
            "label": "Show product rating",
            "info": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/page-types#search-results-section-settings)"
          },
          "header__1": {
            "content": "Product card"
          },
          "header__2": {
            "content": "Blog card",
            "info": "Blog card styles also apply to page cards in search results. To change card styles update your theme settings."
          },
          "article_show_date": {
            "label": "Show date"
          },
          "article_show_author": {
            "label": "Show author"
          },
          "header_mobile": {
            "content": "Mobile Layout"
          },
          "columns_mobile": {
            "label": "Number of columns on mobile",
            "options__1": {
              "label": "1 column"
            },
            "options__2": {
              "label": "2 columns"
            }
          }
        }
      },
      "multicolumn": {
        "name": "Multicolumn",
        "settings": {
          "title": {
            "label": "Heading"
          },
          "image_width": {
            "label": "Image width",
            "options__1": {
              "label": "One-third width of column"
            },
            "options__2": {
              "label": "Half width of column"
            },
            "options__3": {
              "label": "Full width of column"
            }
          },
          "image_ratio": {
            "label": "Image ratio",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Portrait"
            },
            "options__3": {
              "label": "Square"
            },
            "options__4": {
              "label": "Circle"
            }
          },
          "columns_desktop": {
            "label": "Number of columns on desktop"
          },
          "column_alignment": {
            "label": "Column alignment",
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            }
          },
          "background_style": {
            "label": "Secondary background",
            "options__1": {
              "label": "None"
            },
            "options__2": {
              "label": "Show as column background"
            }
          },
          "button_label": {
            "label": "Button label"
          },
          "button_link": {
            "label": "Button link"
          },
          "header_mobile": {
            "content": "Mobile Layout"
          },
          "columns_mobile": {
            "label": "Number of columns on mobile",
            "options__1": {
              "label": "1 column"
            },
            "options__2": {
              "label": "2 columns"
            }
          },
          "swipe_on_mobile": {
            "label": "Enable swipe on mobile"
          }
        },
        "blocks": {
          "column": {
            "name": "Column",
            "settings": {
              "image": {
                "label": "Image"
              },
              "title": {
                "label": "Heading"
              },
              "text": {
                "label": "Description"
              },
              "link_label": {
                "label": "Link label"
              },
              "link": {
                "label": "Link"
              }
            }
          }
        },
        "presets": {
          "name": "Multicolumn"
        }
      },
      "newsletter": {
        "name": "Email signup",
        "settings": {
          "full_width": {
            "label": "Make section full width"
          },
          "paragraph": {
            "content": "Each email subscription creates a customer account. [Learn more](https://help.shopify.com/manual/customers)"
          }
        },
        "blocks": {
          "heading": {
            "name": "Heading",
            "settings": {
              "heading": {
                "label": "Heading"
              }
            }
          },
          "paragraph": {
            "name": "Subheading",
            "settings": {
              "paragraph": {
                "label": "Description"
              }
            }
          },
          "email_form": {
            "name": "Email form"
          }
        },
        "presets": {
          "name": "Email signup"
        }
      },
      "email-signup-banner": {
        "name": "Email signup banner",
        "settings": {
          "paragraph": {
            "content": "Each email subscription creates a customer account. [Learn more](https://help.shopify.com/manual/customers)"
          },
          "image": {
            "label": "Background image"
          },
          "image_overlay_opacity": {
            "label": "Image overlay opacity"
          },
          "show_background_image": {
            "label": "Show background image"
          },
          "show_text_box": {
            "label": "Show container on desktop"
          },
          "desktop_content_position": {
            "options__1": {
              "label": "Top Left"
            },
            "options__2": {
              "label": "Top Center"
            },
            "options__3": {
              "label": "Top Right"
            },
            "options__4": {
              "label": "Middle Left"
            },
            "options__5": {
              "label": "Middle Center"
            },
            "options__6": {
              "label": "Middle Right"
            },
            "options__7": {
              "label": "Bottom Left"
            },
            "options__8": {
              "label": "Bottom Center"
            },
            "options__9": {
              "label": "Bottom Right"
            },
            "label": "Desktop content position"
          },
          "color_scheme": {
            "info": "Visible when container displayed."
          },
          "image_height": {
            "label": "Banner height",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Small"
            },
            "options__3": {
              "label": "Medium"
            },
            "options__4": {
              "label": "Large"
            },
            "info": "For best results, use an image with a 16:9 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
          },
          "desktop_content_alignment": {
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            },
            "label": "Desktop content alignment"
          },
          "header": {
            "content": "Mobile Layout"
          },
          "mobile_content_alignment": {
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            },
            "label": "Mobile content alignment"
          },
          "show_text_below": {
            "label": "Show content below image on mobile",
            "info": "For best results, use an image with a 16:9 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
          }
        },
        "blocks": {
          "heading": {
            "name": "Heading",
            "settings": {
              "heading": {
                "label": "Heading"
              }
            }
          },
          "paragraph": {
            "name": "Paragraph",
            "settings": {
              "paragraph": {
                "label": "Description"
              },
              "text_style": {
                "options__1": {
                  "label": "Body"
                },
                "options__2": {
                  "label": "Subtitle"
                },
                "label": "Text style"
              }
            }
          },
          "email_form": {
            "name": "Email form"
          }
        },
        "presets": {
          "name": "Email signup banner"
        }
      },
      "page": {
        "name": "Page",
        "settings": {
          "page": {
            "label": "Page"
          }
        },
        "presets": {
          "name": "Page"
        }
      },
      "product-recommendations": {
        "name": "Product recommendations",
        "settings": {
          "heading": {
            "label": "Heading"
          },
          "products_to_show": {
            "label": "Maximum products to show"
          },
          "columns_desktop": {
            "label": "Number of columns on desktop"
          },
          "paragraph__1": {
            "content": "Dynamic recommendations use order and product information to change and improve over time. [Learn more](https://help.shopify.com/themes/development/recommended-products)"
          },
          "header__2": {
            "content": "Product card"
          },
          "image_ratio": {
            "label": "Image ratio",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Portrait"
            },
            "options__3": {
              "label": "Square"
            }
          },
          "show_secondary_image": {
            "label": "Show second image on hover"
          },
          "show_vendor": {
            "label": "Show vendor"
          },
          "show_rating": {
            "label": "Show product rating",
            "info": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/page-types#product-recommendations-section-settings)"
          },
          "header_mobile": {
            "content": "Mobile Layout"
          },
          "columns_mobile": {
            "label": "Number of columns on mobile",
            "options__1": {
              "label": "1 column"
            },
            "options__2": {
              "label": "2 columns"
            }
          }
        }
      },
      "rich-text": {
        "name": "Rich text",
        "settings": {
          "desktop_content_position": {
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            },
            "label": "Desktop content position",
            "info": "Position is automatically optimized for mobile."
          },
          "content_alignment": {
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            },
            "label": "Content alignment"
          },
          "full_width": {
            "label": "Make section full width"
          }
        },
        "blocks": {
          "heading": {
            "name": "Heading",
            "settings": {
              "heading": {
                "label": "Heading"
              }
            }
          },
          "caption": {
            "name": "Caption",
            "settings": {
              "text": {
                "label": "Text"
              },
              "text_style": {
                "label": "Text style",
                "options__1": {
                  "label": "Subtitle"
                },
                "options__2": {
                  "label": "Uppercase"
                }
              },
              "caption_size": {
                "label": "Text size",
                "options__1": {
                  "label": "Small"
                },
                "options__2": {
                  "label": "Medium"
                },
                "options__3": {
                  "label": "Large"
                }
              }
            }
          },
          "text": {
            "name": "Text",
            "settings": {
              "text": {
                "label": "Description"
              }
            }
          },
          "buttons": {
            "name": "Buttons",
            "settings": {
              "button_label_1": {
                "label": "First button label",
                "info": "Leave the label blank to hide the button."
              },
              "button_link_1": {
                "label": "First button link"
              },
              "button_style_secondary_1": {
                "label": "Use outline button style"
              },
              "button_label_2": {
                "label": "Second button label",
                "info": "Leave the label blank to hide the button."
              },
              "button_link_2": {
                "label": "Second button link"
              },
              "button_style_secondary_2": {
                "label": "Use outline button style"
              }
            }
          }
        },
        "presets": {
          "name": "Rich text"
        }
      },
      "video": {
        "name": "Video",
        "settings": {
          "heading": {
            "label": "Heading"
          },
          "cover_image": {
            "label": "Cover image"
          },
          "video_url": {
            "label": "URL",
            "placeholder": "Use a YouTube or Vimeo URL",
            "info": "Video plays in the page."
          },
          "description": {
            "label": "Video alt text",
            "info": "Describe the video for customers using screen readers. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/theme-features#video)"
          },
          "image_padding": {
            "label": "Add image padding",
            "info": "Select image padding if you don't want your cover image to be cropped."
          },
          "full_width": {
            "label": "Make section full width"
          }
        },
        "presets": {
          "name": "Video"
        }
      },
      "slideshow": {
        "name": "Slideshow",
        "settings": {
          "layout": {
            "label": "Layout",
            "options__1": {
              "label": "Full width"
            },
            "options__2": {
              "label": "Grid"
            }
          },
          "slide_height": {
            "label": "Slide height",
            "options__1": {
              "label": "Adapt to first image"
            },
            "options__2": {
              "label": "Small"
            },
            "options__3": {
              "label": "Medium"
            },
            "options__4": {
              "label": "Large"
            }
          },
          "slider_visual": {
            "label": "Pagination style",
            "options__1": {
              "label": "Counter"
            },
            "options__2": {
              "label": "Dots"
            },
            "options__3": {
              "label": "Numbers"
            }
          },
          "auto_rotate": {
            "label": "Auto-rotate slides"
          },
          "change_slides_speed": {
            "label": "Change slides every"
          },
          "mobile": {
            "content": "Mobile layout"
          },
          "show_text_below": {
            "label": "Show content below images on mobile"
          },
          "accessibility": {
            "content": "Accessibility",
            "label": "Slideshow description",
            "info": "Describe the slideshow for customers using screen readers."
          }
        },
        "blocks": {
          "slide": {
            "name": "Slide",
            "settings": {
              "image": {
                "label": "Image"
              },
              "heading": {
                "label": "Heading"
              },
              "subheading": {
                "label": "Subheading"
              },
              "button_label": {
                "label": "Button label",
                "info": "Leave the label blank to hide the button."
              },
              "link": {
                "label": "Button link"
              },
              "secondary_style": {
                "label": "Use outline button style"
              },
              "box_align": {
                "label": "Desktop content position",
                "info": "Position is automatically optimized for mobile.",
                "options__1": {
                  "label": "Top left"
                },
                "options__2": {
                  "label": "Top center"
                },
                "options__3": {
                  "label": "Top right"
                },
                "options__4": {
                  "label": "Middle left"
                },
                "options__5": {
                  "label": "Middle center"
                },
                "options__6": {
                  "label": "Middle right"
                },
                "options__7": {
                  "label": "Bottom left"
                },
                "options__8": {
                  "label": "Bottom center"
                },
                "options__9": {
                  "label": "Bottom right"
                }
              },
              "show_text_box": {
                "label": "Show container on desktop"
              },
              "text_alignment": {
                "label": "Desktop content alignment",
                "option_1": {
                  "label": "Left"
                },
                "option_2": {
                  "label": "Center"
                },
                "option_3": {
                  "label": "Right"
                }
              },
              "image_overlay_opacity": {
                "label": "Image overlay opacity"
              },
              "color_scheme": {
                "info": "Visible when container displayed."
              },
              "text_alignment_mobile": {
                "label": "Mobile content alignment",
                "options__1": {
                  "label": "Left"
                },
                "options__2": {
                  "label": "Center"
                },
                "options__3": {
                  "label": "Right"
                }
              }
            }
          }
        },
        "presets": {
          "name": "Slideshow"
        }
      },
      "collapsible_content": {
        "name": "Collapsible content",
        "settings": {
          "caption": {
            "label": "Caption"
          },
          "heading": {
            "label": "Heading"
          },
          "heading_alignment": {
            "label": "Heading alignment",
            "options__1": {
              "label": "Left"
            },
            "options__2": {
              "label": "Center"
            },
            "options__3": {
              "label": "Right"
            }
          },
          "layout": {
            "label": "Layout",
            "options__1": {
              "label": "No container"
            },
            "options__2": {
              "label": "Row container"
            },
            "options__3": {
              "label": "Section container"
            }
          },
          "container_color_scheme": {
            "label": "Container color scheme",
            "info": "Visible when Layout is set to Row or Section container."
          },
          "open_first_collapsible_row": {
            "label": "Open first collapsible row"
          },
          "header": {
            "content": "Image layout"
          },
          "image": {
            "label": "Image"
          },
          "image_ratio": {
            "label": "Image ratio",
            "options__1": {
              "label": "Adapt to image"
            },
            "options__2": {
              "label": "Small"
            },
            "options__3": {
              "label": "Large"
            }
          },
          "desktop_layout": {
            "label": "Desktop layout",
            "options__1": {
              "label": "Image first"
            },
            "options__2": {
              "label": "Image second"
            },
            "info": "Image is always first on mobile."
          }
        },
        "blocks": {
          "collapsible_row": {
            "name": "Collapsible row",
            "settings": {
              "heading": {
                "info": "Include a heading that explains the content.",
                "label": "Heading"
              },
              "row_content": {
                "label": "Row content"
              },
              "page": {
                "label": "Row content from page"
              },
              "icon": {
                "label": "Icon",
                "options__1": {
                  "label": "None"
                },
                "options__2": {
                  "label": "Apple"
                },
                "options__3": {
                  "label": "Banana"
                },
                "options__4": {
                  "label": "Bottle"
                },
                "options__5": {
                  "label": "Box"
                },
                "options__6": {
                  "label": "Carrot"
                },
                "options__7": {
                  "label": "Chat bubble"
                },
                "options__8": {
                  "label": "Check mark"
                },
                "options__9": {
                  "label": "Clipboard"
                },
                "options__10": {
                  "label": "Dairy"
                },
                "options__11": {
                  "label": "Dairy free"
                },
                "options__12": {
                  "label": "Dryer"
                },
                "options__13": {
                  "label": "Eye"
                },
                "options__14": {
                  "label": "Fire"
                },
                "options__15": {
                  "label": "Gluten free"
                },
                "options__16": {
                  "label": "Heart"
                },
                "options__17": {
                  "label": "Iron"
                },
                "options__18": {
                  "label": "Leaf"
                },
                "options__19": {
                  "label": "Leather"
                },
                "options__20": {
                  "label": "Lightning bolt"
                },
                "options__21": {
                  "label": "Lipstick"
                },
                "options__22": {
                  "label": "Lock"
                },
                "options__23": {
                  "label": "Map pin"
                },
                "options__24": {
                  "label": "Nut free"
                },
                "options__25": {
                  "label": "Pants"
                },
                "options__26": {
                  "label": "Paw print"
                },
                "options__27": {
                  "label": "Pepper"
                },
                "options__28": {
                  "label": "Perfume"
                },
                "options__29": {
                  "label": "Plane"
                },
                "options__30": {
                  "label": "Plant"
                },
                "options__31": {
                  "label": "Price tag"
                },
                "options__32": {
                  "label": "Question mark"
                },
                "options__33": {
                  "label": "Recycle"
                },
                "options__34": {
                  "label": "Return"
                },
                "options__35": {
                  "label": "Ruler"
                },
                "options__36": {
                  "label": "Serving dish"
                },
                "options__37": {
                  "label": "Shirt"
                },
                "options__38": {
                  "label": "Shoe"
                },
                "options__39": {
                  "label": "Silhouette"
                },
                "options__40": {
                  "label": "Snowflake"
                },
                "options__41": {
                  "label": "Star"
                },
                "options__42": {
                  "label": "Stopwatch"
                },
                "options__43": {
                  "label": "Truck"
                },
                "options__44": {
                  "label": "Washing"
                }
              }
            }
          }
        },
        "presets": {
          "name": "Collapsible content"
        }
      }
    }
  }
  public static readonly addSectionDataEf420 = {
    "sections": {
      "areviews_section": {
        "type": "areviews-section",
        "settings":  {
          "type": "checkbox",
          "id": "enabled",
          "label": "Enable Areviews ‑ Reviews"
        },
      },
      "image_banner": {
        "type": "image-banner",
        "blocks": {
          "heading": {
            "type": "heading",
            "settings": {
              "heading": "Image banner",
              "heading_size": "h0"
            }
          },
          "text": {
            "type": "text",
            "settings": {
              "text": "Give customers details about the banner image(s) or content on the template.",
              "text_style": "body"
            }
          },
          "button": {
            "type": "buttons",
            "settings": {
              "button_label_1": "Shop all",
              "button_link_1": "shopify:\/\/collections\/all",
              "button_style_secondary_1": true,
              "button_label_2": "",
              "button_link_2": "",
              "button_style_secondary_2": false
            }
          }
        },
        "block_order": [
          "heading",
          "text",
          "button"
        ],
        "settings": {
          "image_overlay_opacity": 40,
          "image_height": "large",
          "adapt_height_first_image": false,
          "desktop_content_position": "bottom-center",
          "show_text_box": false,
          "desktop_content_alignment": "center",
          "color_scheme": "background-1",
          "mobile_content_alignment": "center",
          "stack_images_on_mobile": false,
          "show_text_below": false,
          "image" : "",
          "image2" : ""
        }
      },
      "rich_text": {
        "type": "rich-text",
        "blocks": {
          "heading": {
            "type": "heading",
            "settings": {
              "heading": "Talk about your brand",
              "heading_size": "h1"
            }
          },
          "text": {
            "type": "text",
            "settings": {
              "text": "Share information about your brand with your customers. Describe a product, make announcements, or welcome customers to your store."
            }
          },
          "button": {
            "type": "button",
            "settings": {
              "button_label": "Button label",
              "button_link": "",
              "button_style_secondary": false,
              "button_label_2": "",
              "button_link_2": "",
              "button_style_secondary_2": false
            }
          }
        },
        "block_order": [
          "heading",
          "text",
          "button"
        ],
        "settings": {
          "desktop_content_position": "center",
          "content_alignment": "center",
          "color_scheme": "background-1",
          "full_width": true,
          "padding_top": 40,
          "padding_bottom": 52
        }
      },
      "impact-text": {
        "type": "impact-text",
        "blocks": {
            "1664424181b38582d5-0": {
                "type": "item",
                "settings": {
                    "title": "97%",
                    "subheading": "with zero new stretchmarks",
                    "content": "<p>when using our OBGYN-approved, cocoa & shea Tummy Butter with as directed throughout pregnancy.<\/p>",
                    "button_text": "SHOP TUMMY BUTTER",
                    "button_url": "shopify:\/\/products\/tummy-butter-for-pregnancy-stretch-marks",
                    "animate_impact_text": true,
                    "animate_impact_text_duration": 2.5
                }
            },
            "48b7a481-1eb2-41ef-81b4-a1799d6020a5": {
                "type": "item",
                "settings": {
                    "title": "100%",
                    "subheading": "said they felt instant itch relief",
                    "content": "<p>and softer, more hydrated skin after using our non-greasy, “Perfect 10 Blend” Bump Gloss oil.<\/p>",
                    "button_text": "SHOP BUMP GLOSS",
                    "button_url": "shopify:\/\/products\/bump-gloss-pregnancy-stretch-mark-oil",
                    "animate_impact_text": true,
                    "animate_impact_text_duration": 3.7
                }
            }
        },
        "block_order": [
            "1664424181b38582d5-0",
            "48b7a481-1eb2-41ef-81b4-a1799d6020a5"
        ],

        "settings": {
            "full_width": true,
            "stack_mobile": true,
            "text_alignment": "center",
            "impact_text_style": "fill",
            "text_divider": "none",
            "impact_text_size_ratio": 1,
            "background": "",
            "background_gradient": "",
            "heading_text_color": "rgba(0,0,0,0)",
            "heading_gradient": "linear-gradient(180deg, rgba(244, 153, 167, 1), rgba(244, 158, 158, 1) 50%, rgba(244, 153, 167, 1) 80%, rgba(26, 26, 26, 0) 100%)",
            "text_color": "",
            "button_background": "#fafe54",
            "button_text_color": "#000000"
        }
    },
      "featured_collection": {
        "type": "featured-collection",
        "settings": {
          "title": "Featured products",
          "heading_size": "h2",
          "description": "",
          "show_description": false,
          "description_style": "body",
          "collection": "all",
          "products_to_show": 8,
          "columns_desktop": 4,
          "full_width": false,
          "show_view_all": false,
          "view_all_style": "solid",
          "enable_desktop_slider": false,
          "color_scheme": "background-1",
          "image_ratio": "adapt",
          "show_secondary_image": true,
          "show_vendor": false,
          "show_rating": false,
          "enable_quick_add": false,
          "columns_mobile": "2",
          "swipe_on_mobile": false,
          "padding_top": 28,
          "padding_bottom": 36
        }
      },
      "collage": {
        "type": "collage",
        "blocks": {
          "collection": {
            "type": "collection",
            "settings": {
              "collection": ""
            }
          },
          "product": {
            "type": "product",
            "settings": {
              "product": "",
              "second_image": false
            }
          },
          "image": {
            "type": "image",
            "settings": {
              "image": "",
              "image_padding": true
            }
          },
          "video": {
            "type": "video",
            "settings": {
              "video_url": "https:\/\/www.youtube.com\/watch?v=_9VUPq3SxOc",
              "description": "Describe the video",
              "image_padding": false
            }
          }
        },
        "block_order": [
          "collection",
          "product",
          "image",
          "video"
        ],
        "settings": {
          "heading": "Multimedia collage",
          "heading_size": "h2",
          "desktop_layout": "left",
          "mobile_layout": "collage",
          "color_scheme": "background-1",
          "padding_top": 36,
          "padding_bottom": 36
        }
      },
      "collapsible_content": {
        "type": "collapsible-content",
        "blocks": {
          "collapsible_row": {
            "type": "collapsible_row",
            "settings": {
              "heading": "Collapsible row",
              "icon": "check_mark",
              "row_content": "",
              "page": ""
            }
          },
        },
        "block_order": [
          "collapsible_row"
        ],
        "settings": {
          "caption": "",
          "heading": "Collapsible content",
          "heading_size": "h1",
          "heading_alignment": "center",
          "layout": "none",
          "color_scheme": "background-1",
          "container_color_scheme": "background-2",
          "open_first_collapsible_row": false,
          "image" : "",
          "image_ratio": "adapt",
          "desktop_layout": "image_second",
          "padding_top": 36,
          "padding_bottom": 36
        }
      },
      "video": {
        "type": "video",
        "settings": {
          "heading": "",
          "heading_size": "h1",
          "video_url": "https:\/\/www.youtube.com\/watch?v=_9VUPq3SxOc",
          "description": "",
          "full_width": false,
          "color_scheme": "background-1",
          "padding_top": 36,
          "padding_bottom": 36
        }
      },
      "multiple-images-with-text": {
        "type": "multiple-images-with-text",
        "blocks": {
            "template--15181414957117__7b312a7f-b338-42a1-bc95-1a90ecee3b71-16815074515b8bd574-1": {
                "type": "item",
                "settings": {
                    "image": "shopify:\/\/shop_images\/3.jpg",
                    "image_rotation_degrees": 1.5,
                    "subheading": "",
                    "title": "YOUR SAFETY IS OUR PRIORITY",
                    "content": "<p>Our products are safe for use during pregnancy and and while breastfeeding. They have been tested extensively without animal testing.<\/p><p> We understand that it is difficult for moms-to-be to find time to spoil themselves but thanks to the organic and non-toxic pregnancy skincare by The Spoiled Mama it is easy.\n<\/p><p> Our entire collection of products and pregnancy gifts is made to help soothe your mind and body.<\/p>",
                    "button_url": "",
                    "button_text": ""
                }
            },
            "3c7e4c2f-2879-446d-8ee2-82ff35d7a94f": {
                "type": "item",
                "settings": {
                    "image": "shopify:\/\/shop_images\/1.jpg",
                    "image_rotation_degrees": 0,
                    "subheading": "",
                    "title": "WHY CHOOSE US?",
                    "content": "<p> Our ingredients are so gentle that you won't need to worry about toxic ingredients or having dry, itchy skin and you can focus on that “glowing” pregnancy skin tone you always imagined! <\/p><p> From seabuckthorn oil to hyaluronic acid, the effective, non-toxic ingredients in each of our products are proven to improve the appearance of oily, hormonal acne, stretch marks, c-section scars, postpartum skin and help fade dark spots, caused by melasma (mask of pregnancy). <\/p>",
                    "button_url": "",
                    "button_text": ""
                }
            },
            "template--15181414957117__7b312a7f-b338-42a1-bc95-1a90ecee3b71-16815074515b8bd574-0": {
                "type": "item",
                "settings": {
                    "image": "shopify:\/\/shop_images\/4.jpg",
                    "image_rotation_degrees": -2,
                    "subheading": "",
                    "title": "NON-TOXIC, VEGAN & SAFE",
                    "content": "<p>Safe pregnancy skincare means clean beauty products for moms. Always safe for baby and you. <\/p><p> Let's face it. Growing a human isn't easy, and The Spoiled Mama is here for you so you can enjoy these precious months. Our products ensure you receive the safest pregnancy skincare available.<\/p>",
                    "button_url": "",
                    "button_text": ""
                }
            }
        },
        "block_order": [
            "template--15181414957117__7b312a7f-b338-42a1-bc95-1a90ecee3b71-16815074515b8bd574-1",
            "3c7e4c2f-2879-446d-8ee2-82ff35d7a94f",
            "template--15181414957117__7b312a7f-b338-42a1-bc95-1a90ecee3b71-16815074515b8bd574-0"
        ],
        "settings": {
            "full_width": true,
            "images_layout": "scattered",
            "collage_images_alignment": "shifted",
            "background": "",
            "background_gradient": "",
            "text_color": "",
            "button_background": "rgba(0,0,0,0)",
            "button_text_color": ""
        }
    },
      "multicolumn": {
        "type": "multicolumn",
        "blocks": {
          "column": {
            "type": "column",
            "settings": {
              "title": "Column",
              "text": "<p>Pair text with an image to focus on your chosen product, collection, or blog post. Add details on availability, style, or even provide a review.<\/p>",
              "link_label": "",
              "link": ""
            }
          }
        },
        "block_order": [
          "column"
        ],
        "settings": {
          "title": "",
          "heading_size": "h1",
          "image_width": "third",
          "image_ratio": "adapt",
          "columns_desktop": 3,
          "column_alignment": "center",
          "background_style": "none",
          "button_label": "",
          "button_link": "",
          "color_scheme": "background-1",
          "columns_mobile": "1",
          "swipe_on_mobile": false,
          "padding_top": 36,
          "padding_bottom": 36
        }
      },
      "reviews_importer_section_happyPage": {
        "type": "reviews-importer-section-happyPage",
        "settings": {
        }
      },
      "reviews_importer_section": {
        "type": "reviews-importer-section",
        "settings": {
          "title": "",
          "class_title": "",
          "class_wrap_title": ""
        }
      },
      "reviews_importer_product": {
        "type": "reviews-importer-product",
        "settings": {
          "lai_product": ""
        }
      },
      "featured_blog": {
        "type": "featured-blog",
        "settings": {
          "heading": "Blog posts",
          "heading_size": "h1",
          "blog": "news",
          "post_limit": 3,
          "columns_desktop": 3,
          "color_scheme": "background-1",
          "show_image": true,
          "show_date": true,
          "show_author": false,
          "show_view_all": true,
          "padding_top": 36,
          "padding_bottom": 36
        }
      },
      "collection_list": {
        "type": "collection-list",
        "blocks": {
          "featured_collection": {
            "type": "featured_collection",
            "settings": {
              "collection": ""
            }
          },
        },
        "block_order": [
          "featured_collection"
        ],
        "settings": {
          "title": "Collections",
          "heading_size": "h1",
          "image_ratio": "square",
          "columns_desktop": 3,
          "color_scheme": "background-1",
          "show_view_all": false,
          "columns_mobile": "1",
          "swipe_on_mobile": false,
          "padding_top": 36,
          "padding_bottom": 36
        }
      },
      "newsletter": {
        "type": "newsletter",
        "disabled": true,
        "blocks": {
          "heading": {
            "type": "heading",
            "settings": {
              "heading": "Subscribe to our emails",
              "heading_size": "h1"
            }
          },
          "paragraph": {
            "type": "paragraph",
            "settings": {
              "text": "<p>Be the first to know about new collections and exclusive offers.<\/p>"
            }
          },
          "email_form": {
            "type": "email_form",
            "settings": {
            }
          }
        },
        "block_order": [
          "template--14450283446336__e92418fe-37f6-4b72-9f13-d96ad6921f82-166600278861b7091c-0",
          "template--14450283446336__e92418fe-37f6-4b72-9f13-d96ad6921f82-166600278861b7091c-1",
          "template--14450283446336__e92418fe-37f6-4b72-9f13-d96ad6921f82-166600278861b7091c-2"
        ],
        "settings": {
          "color_scheme": "background-1",
          "full_width": true,
          "padding_top": 40,
          "padding_bottom": 52
        }
      },
      "featured_product": {
        "type": "featured-product",
        "blocks": {
          "vendor": {
            "type": "text",
            "settings": {
              "text": "{{ product.vendor }}",
              "text_style": "uppercase"
            }
          },
          "text": {
            "type": "text",
            "settings": {
              "text": "{{ section.settings.product.vendor }}",
              "text_style": "uppercase"
            }
          },

          "title": {
            "type": "title",
            "settings": {
            }
          },
          "price": {
            "type": "price",
            "settings": {
            }
          },
          "variant_picker": {
            "type": "variant_picker",
            "settings": {
              "picker_type": "button"
            }
          },
          "quantity_selector": {
            "type": "quantity_selector",
            "settings": {
            }
          },
          "buy_buttons": {
            "type": "buy_buttons",
            "settings": {
              "show_dynamic_checkout": true
            }
          },
          "share": {
            "type": "share",
            "settings": {
              "share_label": "Share"
            }
          }
        },
        "block_order": [
          "vendor",
          "title",
         "buy_buttons",
         "price",
         "quantity_selector",
         "share",
         "text",
         "variant_picker"
        ],
        "settings": {
          "product": "",
          "color_scheme": "background-1",
          "secondary_background": false,
          "hide_variants": false,
          "enable_video_looping": false,
          "padding_top": 36,
          "padding_bottom": 36
        }
      },
      "custom_html": {
        "type": "custom-html",
        "settings": {
          "html": ""
        }
      },
      "image_with_text": {
        "type": "image-with-text",
        "blocks": {
          "heading": {
            "type": "heading",
            "settings": {
              "heading": "Image with text",
              "heading_size": "h1"
            }
          },
          "text": {
            "type": "text",
            "settings": {
              "text": "<p>Pair text with an image to focus on your chosen product, collection, or blog post. Add details on availability, style, or even provide a review.<\/p>",
              "text_style": "body"
            }
          },
          "button": {
            "type": "button",
            "settings": {
              "button_label": "Button label",
              "button_link": ""
            }
          }
        },
        "block_order": [
          "heading",
          "text",
          "button"
        ],
        "settings": {
          "height": "adapt",
          "desktop_image_width": "medium",
          "layout": "image_first",
          "desktop_content_position": "top",
          "desktop_content_alignment": "left",
          "content_layout": "no-overlap",
          "color_scheme": "background-1",
          "mobile_content_alignment": "left",
          "padding_top": 36,
          "padding_bottom": 36
        }
      },
      "slideshow": {
        "type": "slideshow",
        "blocks": {
          "slide": {
            "type": "slide",
            "settings": {
              "heading": "Image slide",
              "heading_size": "h1",
              "subheading": "Tell your brand's story through images",
              "button_label": "Button label",
              "link": "",
              "button_style_secondary": false,
              "box_align": "middle-center",
              "show_text_box": true,
              "text_alignment": "center",
              "image_overlay_opacity": 0,
              "color_scheme": "background-1",
              "text_alignment_mobile": "center"
            }
          },

        },
        "block_order": [
          "slide",
        ],
        "settings": {
          "layout": "full_bleed",
          "slide_height": "adapt_image",
          "slider_visual": "counter",
          "auto_rotate": false,
          "change_slides_speed": 5,
          "show_text_below": true,
          "accessibility_info": "Slideshow about our brand"
        }
      },
      "main_product": {
        "type": "main-product",
        "blocks": {
          "vendor": {
            "type": "text",
            "settings": {
              "text": "{{ product.vendor }}",
              "text_style": "uppercase"
            }
          },
          "title": {
            "type": "title",
            "settings": {
            }
          },
          "text": {
            "type": "text",
            "settings": {
              "text": "{{ product.metafields.descriptors.subtitle.value }}",
              "text_style": "subtitle"
            }
          },
          "price": {
            "type": "price",
            "settings": {
            }
          },
          "variant_picker": {
            "type": "variant_picker",
            "settings": {
              "picker_type": "button"
            }
          },
          "quantity_selector": {
            "type": "quantity_selector",
            "settings": {
            }
          },
          "buy_buttons": {
            "type": "buy_buttons",
            "settings": {
              "show_dynamic_checkout": true
            }
          },
          "description": {
            "type": "description",
            "settings": {
            }
          },
          "collapsible-row": {
            "type": "collapsible_tab",
            "settings": {
              "heading": "Materials",
              "icon": "leather",
              "content": "",
              "page": ""
            }
          },
          "share": {
            "type": "share",
            "settings": {
              "share_label": "Share"
            }
          }
        },
        "block_order": [
          "vendor",
          "title",
          "caption",
          "price",
          "variant_picker",
          "quantity_selector",
          "buy_buttons",
          "description",
          "collapsible-row",
          "share"
        ],
        "settings": {
          "enable_sticky_info": true,
          "gallery_layout": "stacked",
          "media_size": "large",
          "mobile_thumbnails": "hide",
          "hide_variants": true,
          "enable_video_looping": false,
          "padding_top": 36,
          "padding_bottom": 12
        }
      },
      "product_recommendations": {
        "type": "product-recommendations",
        "settings": {
          "heading": "You may also like",
          "heading_size": "h2",
          "products_to_show": 4,
          "columns_desktop": 4,
          "color_scheme": "background-1",
          "image_ratio": "square",
          "show_secondary_image": true,
          "show_vendor": false,
          "show_rating": false,
          "columns_mobile": "2",
          "padding_top": 36,
          "padding_bottom": 28
        }
      },
      "main_collection_banner": {
        "type": "main-collection-banner",
        "settings": {
          "show_collection_description": true,
          "show_collection_image": false,
          "color_scheme": "background-1"
        }
      },
      "main_collection_product_grid": {
        "type": "main-collection-product-grid",
        "settings": {
          "products_per_page": 16,
          "columns_desktop": 4,
          "image_ratio": "adapt",
          "show_secondary_image": false,
          "show_vendor": false,
          "show_rating": false,
          "enable_filtering": true,
          "enable_sorting": true,
          "columns_mobile": "2",
          "padding_top": 36,
          "padding_bottom": 36
        }
      },
      "main_list_collections": {
        "type": "main-list-collections",
        "settings": {
          "title": "Collections",
          "sort": "alphabetical",
          "image_ratio": "square"
        }
      },

      "main_cart_items": {
        "type": "main-cart-items",
        "settings": {
          "padding_top": 36,
          "padding_bottom": 36
        }
      },
      "main_cart_footer": {
        "type": "main-cart-footer",
        "blocks": {
          "subtotal": {
            "type": "subtotal",
            "settings": {
            }
          },
          "buttons": {
            "type": "buttons",
            "settings": {
            }
          }
        },
        "block_order": [
          "subtotal",
          "buttons"
        ],
        "settings": {
        }
      },
      "integration-reviews-lai-ali-express-io": {
        "type": "integration-reviews-lai-ali-express-io",
        "settings":
          {
           'enabled' : true
          },


      },
      "integration-reviews-stamped-io": {
        "type": "integration-reviews-stamped-io",
        "settings":   {
          'enabled' : true
         },
      },
      "integration-reviews-areviews-aliexpress": {
        "type": "integration-reviews-areviews-aliexpress",
        "settings":   {
          'enabled' : true
         },
      },
      "integration-reviews-bazaarvoice" : {
        "type" : "integration-reviews-bazaarvoice",
        "settings": {
          'enabled' : true,
          'shop_id' : '',

        }
      },
      "integration-reviews-judge-me": {
        "type": "integration-reviews-judge-me",
        "settings":   {
          'enabled' : true
         },
      },
      "integration-reviews-loox": {
        "type": "integration-reviews-loox",
        "settings":   {
          'enabled' : true
         },
      },
      "integration-reviews-okendo": {
        "type": "integration-reviews-okendo",
        "settings":   {
          'enabled' : true
         },
      },
      "integration-reviews-opinew": {
        "type": "integration-reviews-opinew",
        "settings":   {
          'enabled' : true
         },
      },
      "integration-reviews-ryviu": {
        "type": "integration-reviews-ryviu",
        "settings":   {
          'enabled' : true
         },
      },
      "integration-reviews-shopify-product-reviews": {
        "type": "integration-reviews-shopify-product-reviews",
        "settings":   {
          'enabled' : true
         },
      },
      "integration-reviews-shopperapproved": {
        "type": "integration-reviews-shopperapproved",
        "settings":   {
          'enabled' : true
         },
      },
      "integration-reviews-review-io" : {
        "type" : "integration-reviews-review-io",
        "settings": {
          'enabled' : true,
          'shop_id' : '',

        }
      },
      "integration-reviews-yotpo" : {
        "type" : "integration-reviews-yotpo",
        "settings": {
          'enabled' : true,
          'api_key' : '',

        }
      },
      "integration-reviews-ssw": {
        "type": "integration-reviews-ssw",
        "settings":   {
          'enabled' : true
         },
      },
      "integration-reviews-helpfulcrowd" : {
        "type" : "integration-reviews-helpfulcrowd",
        "settings": {
          'enabled' : true,
          'client_id' : '',

        }
      },
      "integration-reviews-alireviews": {
        "type": "integration-reviews-alireviews",
        "settings":   {
          'enabled' : true
         },
      },
      "footer": {
        "name": "Footer",
        "blocks": {
          "link_list": {
            "type": "link_list",
            "settings": {
              "heading": "Quick links",
              "menu": "footer"
            }
          },

          "text": {
            "type": "text",
            "settings": {
              "heading": "Our mission",
              "subtext": "<p>Share contact information, store details, and brand content with your customers.<\/p>"
            }
          }
        },
        "block_order": [
          "link_list",
          "text"
        ],
        "settings": {
          "newsletter_enable": {
            "label": "Show email signup"
          },
          "newsletter_heading": {
            "label": "Heading"
          },
          "header__1": {
            "content": "Email Signup",
            "info": "Subscribers added automatically to your “accepted marketing” customer list. [Learn more](https://help.shopify.com/manual/customers/manage-customers)"
          },
          "header__2": {
            "content": "Social media icons",
            "info": "To display your social media accounts, link them in your theme settings."
          },
          "show_social": {
            "label": "Show social media icons"
          },
          "header__3": {
            "content": "Country/region selector"
          },
          "header__4": {
            "info": "To add a country/region, go to your [payment settings.](/admin/settings/payments)"
          },
          "enable_country_selector": {
            "label": "Enable country/region selector"
          },
          "header__5": {
            "content": "Language selector"
          },
          "header__6": {
            "info": "To add a language, go to your [language settings.](/admin/settings/languages)"
          },
          "enable_language_selector": {
            "label": "Enable language selector"
          },
          "header__7": {
            "content": "Payment methods"
          },
          "payment_enable": {
            "label": "Show payment icons"
          },
          "margin_top": {
            "label": "Top margin"
          }
        }
      },
      "integration-reviews-proviews-io": {
        "type": "integration-reviews-proviews-io",
        "settings":   {
          'enabled' : true
         },
      },
      "featured-collection-slider": {
        "type": "featured-collection-slider",
        "settings": {
            "title": "",
            "subtitle": "",
            "heading_size": "h1",
            "collection": "best-sellers",
            "products_to_show": 20,
            "columns_desktop": 5,
            "image_ratio": "adapt",
            "show_secondary_image": false,
            "columns_mobile": 3,
            "padding_top": 0
        }
    },


    }

  }

// Dawn 10.0 metadata

public static readonly globalSettingSchemaV10 = {

  "logo" :  {
    "name": "t:settings_schema.logo.name",
    "settings": [
      {
        "type": "image_picker",
        "id": "logo",
        "label": "t:settings_schema.logo.settings.logo_image.label"
      },
      {
        "type": "range",
        "id": "logo_width",
        "min": 50,
        "max": 300,
        "step": 10,
        "default": 100,
        "unit": "px",
        "label": "t:settings_schema.logo.settings.logo_width.label"
      },
      {
        "type": "image_picker",
        "id": "favicon",
        "label": "t:settings_schema.logo.settings.favicon.label",
        "info": "t:settings_schema.logo.settings.favicon.info"
      }
    ]
  },
  "typography":  {
    "name": "t:settings_schema.typography.name",
    "settings": [
      {
        "type": "checkbox",
        "id": "no_font",
        "default": false,
        "label": "t:settings_schema.typography.settings.nofont.label"
      },
      {
        "type": "header",
        "content": "t:settings_schema.typography.settings.header__1.content"
      },
      {
        "type": "font_picker",
        "id": "type_header_font",
        "default": "assistant_n4",
        "label": "t:settings_schema.typography.settings.type_header_font.label",
        "info": "t:settings_schema.typography.settings.type_header_font.info"
      },
      {
        "type": "range",
        "id": "heading_scale",
        "min": 100,
        "max": 150,
        "step": 5,
        "unit": "%",
        "label": "t:settings_schema.typography.settings.heading_scale.label",
        "default": 100
      },
      {
        "type": "header",
        "content": "t:settings_schema.typography.settings.header__2.content"
      },
      {
        "type": "font_picker",
        "id": "type_body_font",
        "default": "assistant_n4",
        "label": "t:settings_schema.typography.settings.type_body_font.label",
        "info": "t:settings_schema.typography.settings.type_body_font.info"
      },
      {
        "type": "range",
        "id": "body_scale",
        "min": 100,
        "max": 130,
        "step": 5,
        "unit": "%",
        "label": "t:settings_schema.typography.settings.body_scale.label",
        "default": 100
      }
    ]
  },
  "layout":  {
    "name": "t:settings_schema.layout.name",
    "settings": [
      {
        "type": "range",
        "id": "page_width",
        "min": 1000,
        "max": 1600,
        "step": 100,
        "default": 1200,
        "unit": "px",
        "label": "t:settings_schema.layout.settings.page_width.label"
      },
      {
        "type": "range",
        "id": "spacing_sections",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:settings_schema.layout.settings.spacing_sections.label",
        "default": 0
      },
      {
        "type": "header",
        "content": "t:settings_schema.layout.settings.header__grid.content"
      },
      {
        "type": "paragraph",
        "content": "t:settings_schema.layout.settings.paragraph__grid.content"
      },
      {
        "type": "range",
        "id": "spacing_grid_horizontal",
        "min": 4,
        "max": 40,
        "step": 4,
        "default": 8,
        "unit": "px",
        "label": "t:settings_schema.layout.settings.spacing_grid_horizontal.label"
      },
      {
        "type": "range",
        "id": "spacing_grid_vertical",
        "min": 4,
        "max": 40,
        "step": 4,
        "default": 8,
        "unit": "px",
        "label": "t:settings_schema.layout.settings.spacing_grid_vertical.label"
      }
    ]
  },
  "animations":  {
    "name": "t:settings_schema.animations.name",
    "settings": [
      {
        "type": "checkbox",
        "id": "animations_reveal_on_scroll",
        "label": "t:settings_schema.animations.settings.animations_reveal_on_scroll.label",
        "default": true
      },
      {
        "type": "select",
        "id": "animations_hover_elements",
        "options": [
          {
            "value": "default",
            "label": "t:settings_schema.animations.settings.animations_hover_elements.options__1.label"
          },
          {
            "value": "3d-lift",
            "label": "t:settings_schema.animations.settings.animations_hover_elements.options__2.label"
          }
        ],
        "default": "default",
        "label": "t:settings_schema.animations.settings.animations_hover_elements.label",
        "info": "t:settings_schema.animations.settings.animations_hover_elements.info"
      }
    ]
  },
  "buttons":  {
    "name": "t:settings_schema.buttons.name",
    "settings": [
      {
        "type": "header",
        "content": "t:settings_schema.global.settings.header__border.content"
      },
      {
        "type": "range",
        "id": "buttons_border_thickness",
        "min": 0,
        "max": 12,
        "step": 1,
        "unit": "px",
        "label": "t:settings_schema.global.settings.thickness.label",
        "default": 1
      },
      {
        "type": "range",
        "id": "buttons_border_opacity",
        "min": 0,
        "max": 100,
        "step": 5,
        "unit": "%",
        "label": "t:settings_schema.global.settings.opacity.label",
        "default": 100
      },
      {
        "type": "range",
        "id": "buttons_radius",
        "min": 0,
        "max": 40,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.corner_radius.label",
        "default": 0
      },
      {
        "type": "header",
        "content": "t:settings_schema.global.settings.header__shadow.content"
      },
      {
        "type": "range",
        "id": "buttons_shadow_opacity",
        "min": 0,
        "max": 100,
        "step": 5,
        "unit": "%",
        "label": "t:settings_schema.global.settings.opacity.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "buttons_shadow_horizontal_offset",
        "min": -12,
        "max": 12,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.horizontal_offset.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "buttons_shadow_vertical_offset",
        "min": -12,
        "max": 12,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.vertical_offset.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "buttons_shadow_blur",
        "min": 0,
        "max": 20,
        "step": 5,
        "unit": "px",
        "label": "t:settings_schema.global.settings.blur.label",
        "default": 0
      }
    ]
  },
  "variant_pills":  {
    "name": "t:settings_schema.variant_pills.name",
    "settings": [
      {
        "type": "paragraph",
        "content": "t:settings_schema.variant_pills.paragraph"
      },
      {
        "type": "header",
        "content": "t:settings_schema.global.settings.header__border.content"
      },
      {
        "type": "range",
        "id": "variant_pills_border_thickness",
        "min": 0,
        "max": 12,
        "step": 1,
        "unit": "px",
        "label": "t:settings_schema.global.settings.thickness.label",
        "default": 1
      },
      {
        "type": "range",
        "id": "variant_pills_border_opacity",
        "min": 0,
        "max": 100,
        "step": 5,
        "unit": "%",
        "label": "t:settings_schema.global.settings.opacity.label",
        "default": 55
      },
      {
        "type": "range",
        "id": "variant_pills_radius",
        "min": 0,
        "max": 40,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.corner_radius.label",
        "default": 40
      },
      {
        "type": "header",
        "content": "t:settings_schema.global.settings.header__shadow.content"
      },
      {
        "type": "range",
        "id": "variant_pills_shadow_opacity",
        "min": 0,
        "max": 100,
        "step": 5,
        "unit": "%",
        "label": "t:settings_schema.global.settings.opacity.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "variant_pills_shadow_horizontal_offset",
        "min": -12,
        "max": 12,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.horizontal_offset.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "variant_pills_shadow_vertical_offset",
        "min": -12,
        "max": 12,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.vertical_offset.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "variant_pills_shadow_blur",
        "min": 0,
        "max": 20,
        "step": 5,
        "unit": "px",
        "label": "t:settings_schema.global.settings.blur.label",
        "default": 0
      }
    ]
  },
  "inputs":  {
    "name": "t:settings_schema.inputs.name",
    "settings": [
      {
        "type": "header",
        "content": "t:settings_schema.global.settings.header__border.content"
      },
      {
        "type": "range",
        "id": "inputs_border_thickness",
        "min": 0,
        "max": 12,
        "step": 1,
        "unit": "px",
        "label": "t:settings_schema.global.settings.thickness.label",
        "default": 1
      },
      {
        "type": "range",
        "id": "inputs_border_opacity",
        "min": 0,
        "max": 100,
        "step": 5,
        "unit": "%",
        "label": "t:settings_schema.global.settings.opacity.label",
        "default": 55
      },
      {
        "type": "range",
        "id": "inputs_radius",
        "min": 0,
        "max": 40,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.corner_radius.label",
        "default": 0
      },
      {
        "type": "header",
        "content": "t:settings_schema.global.settings.header__shadow.content"
      },
      {
        "type": "range",
        "id": "inputs_shadow_opacity",
        "min": 0,
        "max": 100,
        "step": 5,
        "unit": "%",
        "label": "t:settings_schema.global.settings.opacity.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "inputs_shadow_horizontal_offset",
        "min": -12,
        "max": 12,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.horizontal_offset.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "inputs_shadow_vertical_offset",
        "min": -12,
        "max": 12,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.vertical_offset.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "inputs_shadow_blur",
        "min": 0,
        "max": 20,
        "step": 5,
        "unit": "px",
        "label": "t:settings_schema.global.settings.blur.label",
        "default": 0
      }
    ]
  },
  "cards":  {
    "name": "t:settings_schema.cards.name",
    "settings": [
      {
        "type": "select",
        "id": "card_style",
        "options": [
          {
            "value": "standard",
            "label": "t:settings_schema.cards.settings.style.options__1.label"
          },
          {
            "value": "card",
            "label": "t:settings_schema.cards.settings.style.options__2.label"
          }
        ],
        "default": "standard",
        "label": "t:settings_schema.cards.settings.style.label"
      },
      {
        "type": "range",
        "id": "card_image_padding",
        "min": 0,
        "max": 20,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.image_padding.label",
        "default": 0
      },
      {
        "type": "select",
        "id": "card_text_alignment",
        "options": [
          {
            "value": "left",
            "label": "t:settings_schema.global.settings.text_alignment.options__1.label"
          },
          {
            "value": "center",
            "label": "t:settings_schema.global.settings.text_alignment.options__2.label"
          },
          {
            "value": "right",
            "label": "t:settings_schema.global.settings.text_alignment.options__3.label"
          }
        ],
        "default": "left",
        "label": "t:settings_schema.global.settings.text_alignment.label"
      },
      {
        "type": "color_scheme",
        "id": "card_color_scheme",
        "label": "t:sections.all.colors.label",
        "default": "background-2"
      },
      {
        "type": "header",
        "content": "t:settings_schema.global.settings.header__border.content"
      },
      {
        "type": "range",
        "id": "card_border_thickness",
        "min": 0,
        "max": 24,
        "step": 1,
        "unit": "px",
        "label": "t:settings_schema.global.settings.thickness.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "card_border_opacity",
        "min": 0,
        "max": 100,
        "step": 5,
        "unit": "%",
        "label": "t:settings_schema.global.settings.opacity.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "card_corner_radius",
        "min": 0,
        "max": 40,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.corner_radius.label",
        "default": 0
      },
      {
        "type": "header",
        "content": "t:settings_schema.global.settings.header__shadow.content"
      },
      {
        "type": "range",
        "id": "card_shadow_opacity",
        "min": 0,
        "max": 100,
        "step": 5,
        "unit": "%",
        "label": "t:settings_schema.global.settings.opacity.label",
        "default": 10
      },
      {
        "type": "range",
        "id": "card_shadow_horizontal_offset",
        "min": -40,
        "max": 40,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.horizontal_offset.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "card_shadow_vertical_offset",
        "min": -40,
        "max": 40,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.vertical_offset.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "card_shadow_blur",
        "min": 0,
        "max": 40,
        "step": 5,
        "unit": "px",
        "label": "t:settings_schema.global.settings.blur.label",
        "default": 0
      }
    ]
  },
  "collection_cards":  {
    "name": "t:settings_schema.collection_cards.name",
    "settings": [
      {
        "type": "select",
        "id": "collection_card_style",
        "options": [
          {
            "value": "standard",
            "label": "t:settings_schema.collection_cards.settings.style.options__1.label"
          },
          {
            "value": "card",
            "label": "t:settings_schema.collection_cards.settings.style.options__2.label"
          }
        ],
        "default": "standard",
        "label": "t:settings_schema.collection_cards.settings.style.label"
      },
      {
        "type": "range",
        "id": "collection_card_image_padding",
        "min": 0,
        "max": 20,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.image_padding.label",
        "default": 0
      },
      {
        "type": "select",
        "id": "collection_card_text_alignment",
        "options": [
          {
            "value": "left",
            "label": "t:settings_schema.global.settings.text_alignment.options__1.label"
          },
          {
            "value": "center",
            "label": "t:settings_schema.global.settings.text_alignment.options__2.label"
          },
          {
            "value": "right",
            "label": "t:settings_schema.global.settings.text_alignment.options__3.label"
          }
        ],
        "default": "left",
        "label": "t:settings_schema.global.settings.text_alignment.label"
      },
      {
        "type": "color_scheme",
        "id": "collection_card_color_scheme",
        "label": "t:sections.all.colors.label",
        "default": "background-2"
      },
      {
        "type": "header",
        "content": "t:settings_schema.global.settings.header__border.content"
      },
      {
        "type": "range",
        "id": "collection_card_border_thickness",
        "min": 0,
        "max": 24,
        "step": 1,
        "unit": "px",
        "label": "t:settings_schema.global.settings.thickness.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "collection_card_border_opacity",
        "min": 0,
        "max": 100,
        "step": 5,
        "unit": "%",
        "label": "t:settings_schema.global.settings.opacity.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "collection_card_corner_radius",
        "min": 0,
        "max": 40,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.corner_radius.label",
        "default": 0
      },
      {
        "type": "header",
        "content": "t:settings_schema.global.settings.header__shadow.content"
      },
      {
        "type": "range",
        "id": "collection_card_shadow_opacity",
        "min": 0,
        "max": 100,
        "step": 5,
        "unit": "%",
        "label": "t:settings_schema.global.settings.opacity.label",
        "default": 10
      },
      {
        "type": "range",
        "id": "collection_card_shadow_horizontal_offset",
        "min": -40,
        "max": 40,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.horizontal_offset.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "collection_card_shadow_vertical_offset",
        "min": -40,
        "max": 40,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.vertical_offset.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "collection_card_shadow_blur",
        "min": 0,
        "max": 40,
        "step": 5,
        "unit": "px",
        "label": "t:settings_schema.global.settings.blur.label",
        "default": 0
      }
    ]
  },
  "blog_cards":  {
    "name": "t:settings_schema.blog_cards.name",
    "settings": [
      {
        "type": "select",
        "id": "blog_card_style",
        "options": [
          {
            "value": "standard",
            "label": "t:settings_schema.blog_cards.settings.style.options__1.label"
          },
          {
            "value": "card",
            "label": "t:settings_schema.blog_cards.settings.style.options__2.label"
          }
        ],
        "default": "standard",
        "label": "t:settings_schema.blog_cards.settings.style.label"
      },
      {
        "type": "range",
        "id": "blog_card_image_padding",
        "min": 0,
        "max": 20,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.image_padding.label",
        "default": 0
      },
      {
        "type": "select",
        "id": "blog_card_text_alignment",
        "options": [
          {
            "value": "left",
            "label": "t:settings_schema.global.settings.text_alignment.options__1.label"
          },
          {
            "value": "center",
            "label": "t:settings_schema.global.settings.text_alignment.options__2.label"
          },
          {
            "value": "right",
            "label": "t:settings_schema.global.settings.text_alignment.options__3.label"
          }
        ],
        "default": "left",
        "label": "t:settings_schema.global.settings.text_alignment.label"
      },
      {
        "type": "color_scheme",
        "id": "blog_card_color_scheme",
        "label": "t:sections.all.colors.label",
        "default": "background-2"
      },
      {
        "type": "header",
        "content": "t:settings_schema.global.settings.header__border.content"
      },
      {
        "type": "range",
        "id": "blog_card_border_thickness",
        "min": 0,
        "max": 24,
        "step": 1,
        "unit": "px",
        "label": "t:settings_schema.global.settings.thickness.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "blog_card_border_opacity",
        "min": 0,
        "max": 100,
        "step": 5,
        "unit": "%",
        "label": "t:settings_schema.global.settings.opacity.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "blog_card_corner_radius",
        "min": 0,
        "max": 40,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.corner_radius.label",
        "default": 0
      },
      {
        "type": "header",
        "content": "t:settings_schema.global.settings.header__shadow.content"
      },
      {
        "type": "range",
        "id": "blog_card_shadow_opacity",
        "min": 0,
        "max": 100,
        "step": 5,
        "unit": "%",
        "label": "t:settings_schema.global.settings.opacity.label",
        "default": 10
      },
      {
        "type": "range",
        "id": "blog_card_shadow_horizontal_offset",
        "min": -40,
        "max": 40,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.horizontal_offset.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "blog_card_shadow_vertical_offset",
        "min": -40,
        "max": 40,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.vertical_offset.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "blog_card_shadow_blur",
        "min": 0,
        "max": 40,
        "step": 5,
        "unit": "px",
        "label": "t:settings_schema.global.settings.blur.label",
        "default": 0
      }
    ]
  },
  "content_containers":  {
    "name": "t:settings_schema.content_containers.name",
    "settings": [
      {
        "type": "header",
        "content": "t:settings_schema.global.settings.header__border.content"
      },
      {
        "type": "range",
        "id": "text_boxes_border_thickness",
        "min": 0,
        "max": 24,
        "step": 1,
        "unit": "px",
        "label": "t:settings_schema.global.settings.thickness.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "text_boxes_border_opacity",
        "min": 0,
        "max": 100,
        "step": 5,
        "unit": "%",
        "label": "t:settings_schema.global.settings.opacity.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "text_boxes_radius",
        "min": 0,
        "max": 40,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.corner_radius.label",
        "default": 0
      },
      {
        "type": "header",
        "content": "t:settings_schema.global.settings.header__shadow.content"
      },
      {
        "type": "range",
        "id": "text_boxes_shadow_opacity",
        "min": 0,
        "max": 100,
        "step": 5,
        "unit": "%",
        "label": "t:settings_schema.global.settings.opacity.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "text_boxes_shadow_horizontal_offset",
        "min": -40,
        "max": 40,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.horizontal_offset.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "text_boxes_shadow_vertical_offset",
        "min": -40,
        "max": 40,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.vertical_offset.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "text_boxes_shadow_blur",
        "min": 0,
        "max": 40,
        "step": 5,
        "unit": "px",
        "label": "t:settings_schema.global.settings.blur.label",
        "default": 0
      }
    ]
  },
  "media":  {
    "name": "t:settings_schema.media.name",
    "settings": [
      {
        "type": "header",
        "content": "t:settings_schema.global.settings.header__border.content"
      },
      {
        "type": "range",
        "id": "media_border_thickness",
        "min": 0,
        "max": 24,
        "step": 1,
        "unit": "px",
        "label": "t:settings_schema.global.settings.thickness.label",
        "default": 1
      },
      {
        "type": "range",
        "id": "media_border_opacity",
        "min": 0,
        "max": 100,
        "step": 5,
        "unit": "%",
        "label": "t:settings_schema.global.settings.opacity.label",
        "default": 5
      },
      {
        "type": "range",
        "id": "media_radius",
        "min": 0,
        "max": 40,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.corner_radius.label",
        "default": 0
      },
      {
        "type": "header",
        "content": "t:settings_schema.global.settings.header__shadow.content"
      },
      {
        "type": "range",
        "id": "media_shadow_opacity",
        "min": 0,
        "max": 100,
        "step": 5,
        "unit": "%",
        "label": "t:settings_schema.global.settings.opacity.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "media_shadow_horizontal_offset",
        "min": -40,
        "max": 40,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.horizontal_offset.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "media_shadow_vertical_offset",
        "min": -40,
        "max": 40,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.vertical_offset.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "media_shadow_blur",
        "min": 0,
        "max": 40,
        "step": 5,
        "unit": "px",
        "label": "t:settings_schema.global.settings.blur.label",
        "default": 0
      }
    ]
  },
  "popups":  {
    "name": "t:settings_schema.popups.name",
    "settings": [
      {
        "type": "paragraph",
        "content": "t:settings_schema.popups.paragraph"
      },
      {
        "type": "header",
        "content": "t:settings_schema.global.settings.header__border.content"
      },
      {
        "type": "range",
        "id": "popup_border_thickness",
        "min": 0,
        "max": 24,
        "step": 1,
        "unit": "px",
        "label": "t:settings_schema.global.settings.thickness.label",
        "default": 1
      },
      {
        "type": "range",
        "id": "popup_border_opacity",
        "min": 0,
        "max": 100,
        "step": 5,
        "unit": "%",
        "label": "t:settings_schema.global.settings.opacity.label",
        "default": 10
      },
      {
        "type": "range",
        "id": "popup_corner_radius",
        "min": 0,
        "max": 40,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.corner_radius.label",
        "default": 0
      },
      {
        "type": "header",
        "content": "t:settings_schema.global.settings.header__shadow.content"
      },
      {
        "type": "range",
        "id": "popup_shadow_opacity",
        "min": 0,
        "max": 100,
        "step": 5,
        "unit": "%",
        "label": "t:settings_schema.global.settings.opacity.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "popup_shadow_horizontal_offset",
        "min": -40,
        "max": 40,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.horizontal_offset.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "popup_shadow_vertical_offset",
        "min": -40,
        "max": 40,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.vertical_offset.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "popup_shadow_blur",
        "min": 0,
        "max": 40,
        "step": 5,
        "unit": "px",
        "label": "t:settings_schema.global.settings.blur.label",
        "default": 0
      }
    ]
  },
  "drawers":  {
    "name": "t:settings_schema.drawers.name",
    "settings": [
      {
        "type": "header",
        "content": "t:settings_schema.global.settings.header__border.content"
      },
      {
        "type": "range",
        "id": "drawer_border_thickness",
        "min": 0,
        "max": 24,
        "step": 1,
        "unit": "px",
        "label": "t:settings_schema.global.settings.thickness.label",
        "default": 1
      },
      {
        "type": "range",
        "id": "drawer_border_opacity",
        "min": 0,
        "max": 100,
        "step": 5,
        "unit": "%",
        "label": "t:settings_schema.global.settings.opacity.label",
        "default": 10
      },
      {
        "type": "header",
        "content": "t:settings_schema.global.settings.header__shadow.content"
      },
      {
        "type": "range",
        "id": "drawer_shadow_opacity",
        "min": 0,
        "max": 100,
        "step": 5,
        "unit": "%",
        "label": "t:settings_schema.global.settings.opacity.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "drawer_shadow_horizontal_offset",
        "min": -40,
        "max": 40,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.horizontal_offset.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "drawer_shadow_vertical_offset",
        "min": -40,
        "max": 40,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.vertical_offset.label",
        "default": 0
      },
      {
        "type": "range",
        "id": "drawer_shadow_blur",
        "min": 0,
        "max": 40,
        "step": 5,
        "unit": "px",
        "label": "t:settings_schema.global.settings.blur.label",
        "default": 0
      }
    ]
  },
  "badges":  {
    "name": "t:settings_schema.badges.name",
    "settings": [
      {
        "type": "select",
        "id": "badge_position",
        "options": [
          {
            "value": "bottom left",
            "label": "t:settings_schema.badges.settings.position.options__1.label"
          },
          {
            "value": "bottom right",
            "label": "t:settings_schema.badges.settings.position.options__2.label"
          },
          {
            "value": "top left",
            "label": "t:settings_schema.badges.settings.position.options__3.label"
          },
          {
            "value": "top right",
            "label": "t:settings_schema.badges.settings.position.options__4.label"
          }
        ],
        "default": "bottom left",
        "label": "t:settings_schema.badges.settings.position.label"
      },
      {
        "type": "range",
        "id": "badge_corner_radius",
        "min": 0,
        "max": 40,
        "step": 2,
        "unit": "px",
        "label": "t:settings_schema.global.settings.corner_radius.label",
        "default": 40
      },
      {
        "type": "color_scheme",
        "id": "sale_badge_color_scheme",
        "label": "t:settings_schema.badges.settings.sale_badge_color_scheme.label",
        "default": "accent-2"
      },
      {
        "type": "color_scheme",
        "id": "sold_out_badge_color_scheme",
        "label": "t:settings_schema.badges.settings.sold_out_badge_color_scheme.label",
        "default": "inverse"
      }
    ]
  },
  "brand_information":  {
    "name": "t:settings_schema.brand_information.name",
    "settings": [
      {
        "type": "paragraph",
        "content": "t:settings_schema.brand_information.settings.paragraph.content"
      },
      {
        "type": "inline_richtext",
        "id": "brand_headline",
        "label": "t:settings_schema.brand_information.settings.brand_headline.label"
      },
      {
        "type": "richtext",
        "id": "brand_description",
        "label": "t:settings_schema.brand_information.settings.brand_description.label"
      },
      {
        "type": "image_picker",
        "id": "brand_image",
        "label": "t:settings_schema.brand_information.settings.brand_image.label"
      },
      {
        "type": "range",
        "id": "brand_image_width",
        "min": 50,
        "max": 550,
        "step": 5,
        "default": 100,
        "unit": "px",
        "label": "t:settings_schema.brand_information.settings.brand_image_width.label"
      }
    ]
  },
  "social-media":  {
    "name": "t:settings_schema.social-media.name",
    "settings": [
      {
        "type": "header",
        "content": "t:settings_schema.social-media.settings.header.content"
      },
      {
        "type": "text",
        "id": "social_facebook_link",
        "label": "t:settings_schema.social-media.settings.social_facebook_link.label",
        "info": "t:settings_schema.social-media.settings.social_facebook_link.info"
      },
      {
        "type": "text",
        "id": "social_instagram_link",
        "label": "t:settings_schema.social-media.settings.social_instagram_link.label",
        "info": "t:settings_schema.social-media.settings.social_instagram_link.info"
      },
      {
        "type": "text",
        "id": "social_youtube_link",
        "label": "t:settings_schema.social-media.settings.social_youtube_link.label",
        "info": "t:settings_schema.social-media.settings.social_youtube_link.info"
      },
      {
        "type": "text",
        "id": "social_tiktok_link",
        "label": "t:settings_schema.social-media.settings.social_tiktok_link.label",
        "info": "t:settings_schema.social-media.settings.social_tiktok_link.info"
      },
      {
        "type": "text",
        "id": "social_twitter_link",
        "label": "t:settings_schema.social-media.settings.social_twitter_link.label",
        "info": "t:settings_schema.social-media.settings.social_twitter_link.info"
      },
      {
        "type": "text",
        "id": "social_snapchat_link",
        "label": "t:settings_schema.social-media.settings.social_snapchat_link.label",
        "info": "t:settings_schema.social-media.settings.social_snapchat_link.info"
      },
      {
        "type": "text",
        "id": "social_pinterest_link",
        "label": "t:settings_schema.social-media.settings.social_pinterest_link.label",
        "info": "t:settings_schema.social-media.settings.social_pinterest_link.info"
      },
      {
        "type": "text",
        "id": "social_tumblr_link",
        "label": "t:settings_schema.social-media.settings.social_tumblr_link.label",
        "info": "t:settings_schema.social-media.settings.social_tumblr_link.info"
      },
      {
        "type": "text",
        "id": "social_vimeo_link",
        "label": "t:settings_schema.social-media.settings.social_vimeo_link.label",
        "info": "t:settings_schema.social-media.settings.social_vimeo_link.info"
      }
    ]
  },
  "search_input":  {
    "name": "t:settings_schema.search_input.name",
    "settings": [
      {
        "type": "header",
        "content": "t:settings_schema.search_input.settings.header.content"
      },
      {
        "type": "checkbox",
        "id": "predictive_search_enabled",
        "default": true,
        "label": "t:settings_schema.search_input.settings.predictive_search_enabled.label"
      },
      {
        "type": "checkbox",
        "id": "predictive_search_show_vendor",
        "default": false,
        "label": "t:settings_schema.search_input.settings.predictive_search_show_vendor.label",
        "info": "t:settings_schema.search_input.settings.predictive_search_show_vendor.info"
      },
      {
        "type": "checkbox",
        "id": "predictive_search_show_price",
        "default": false,
        "label": "t:settings_schema.search_input.settings.predictive_search_show_price.label",
        "info": "t:settings_schema.search_input.settings.predictive_search_show_price.info"
      }
    ]
  },
  "currency_format":  {
    "name": "t:settings_schema.currency_format.name",
    "settings": [
      {
        "type": "header",
        "content": "t:settings_schema.currency_format.settings.content"
      },
      {
        "type": "paragraph",
        "content": "t:settings_schema.currency_format.settings.paragraph"
      },
      {
        "type": "checkbox",
        "id": "currency_code_enabled",
        "label": "t:settings_schema.currency_format.settings.currency_code_enabled.label",
        "default": true
      }
    ]
  },
  "cart":  {
    "name": "t:settings_schema.cart.name",
    "settings": [
      {
        "type": "select",
        "id": "cart_type",
        "options": [
          {
            "value": "drawer",
            "label": "t:settings_schema.cart.settings.cart_type.drawer.label"
          },
          {
            "value": "page",
            "label": "t:settings_schema.cart.settings.cart_type.page.label"
          },
          {
            "value": "notification",
            "label": "t:settings_schema.cart.settings.cart_type.notification.label"
          }
        ],
        "default": "notification",
        "label": "t:settings_schema.cart.settings.cart_type.label"
      },
      {
        "type": "checkbox",
        "id": "show_vendor",
        "label": "t:settings_schema.cart.settings.show_vendor.label",
        "default": false
      },
      {
        "type": "checkbox",
        "id": "show_cart_note",
        "label": "t:settings_schema.cart.settings.show_cart_note.label",
        "default": false
      },
      {
        "type": "header",
        "content": "t:settings_schema.cart.settings.cart_drawer.header"
      },
      {
        "type": "collection",
        "id": "cart_drawer_collection",
        "label": "t:settings_schema.cart.settings.cart_drawer.collection.label",
        "info": "t:settings_schema.cart.settings.cart_drawer.collection.info"
      }
    ]
  },
  "colors":  {
    "name": "t:settings_schema.colors.name",
    "settings": [
          {
            "type": "color",
            "id": "background",
            "label": "t:settings_schema.colors.settings.background.label",
            "default": "#FFFFFF"
          },
          {
            "type": "color_background",
            "id": "background_gradient",
            "label": "t:settings_schema.colors.settings.background_gradient.label",
            "info": "t:settings_schema.colors.settings.background_gradient.info"
          },
          {
            "type": "color",
            "id": "text",
            "label": "t:settings_schema.colors.settings.text.label",
            "default": "#121212"
          },
          {
            "type": "color",
            "id": "button",
            "label": "t:settings_schema.colors.settings.button_background.label",
            "default": "#121212"
          },
          {
            "type": "color",
            "id": "button_label",
            "label": "t:settings_schema.colors.settings.button_label.label",
            "default": "#FFFFFF"
          },
          {
            "type": "color",
            "id": "secondary_button_label",
            "label": "t:settings_schema.colors.settings.secondary_button_label.label",
            "default": "#121212"
          },
          {
            "type": "color",
            "id": "shadow",
            "label": "t:settings_schema.colors.settings.shadow.label",
            "default": "#121212"
          }
        ]
  },
  "global_settings":{
    "name": "Global Settings",
    "settings": [
      // {
      //   "type": "header",
      //   "content": "Largest Contentful Paint"
      // },
      // {
      //   "type": "checkbox",
      //   "id": "display_inventory_left",
      //   "label": "Show left inventory",
      //   "default": true
      // },
      {
        "type": "header",
        "content": "Ticket image"
      },
      {
        "type": "image_picker",
        "id": "ticket_image",
        "label": "Ticket image"
      },
      // {
      //   "type": "header",
      //   "content": "Last Chance Collection"
      // },
      // {
      //   "type": "collection",
      //   "id": "selected_collection",
      //   "label": "Collection"
      // },
      // {
      //   "type": "paragraph",
      //   "content": "Display custom icon for Delta 8 products"
      // },
      // {
      //   "type": "richtext",
      //   "id": "delta_8_disclaimer_message",
      //   "label": "Delta 8 Disclaimer Message"
      // },
      // {
      //   "type": "text",
      //   "id": "delta_8_disclaimer_icon",
      //   "label": "Delta 8 Disclaimer Icon",
      //   "info": "File name of the icon in the theme assets folder"
      // },
      // {
      //   "type": "url",
      //   "id": "google_link",
      //   "label": "t:sections.footer.blocks.link_list.settings.show_app.google_label"
      // },
      // {
      //   "type": "url",
      //   "id": "apple_link",
      //   "label": "t:sections.footer.blocks.link_list.settings.show_app.apple_label"
      // }
    ]
  },
  "styles": {
    "name": "t:settings_schema.styles.name",
    "settings": [
      {
        "type": "select",
        "id": "accent_icons",
        "options": [
          {
            "value": "accent-1",
            "label": "t:sections.all.colors.accent_1.label"
          },
          {
            "value": "accent-2",
            "label": "t:sections.all.colors.accent_2.label"
          },
          {
            "value": "outline-button",
            "label": "t:settings_schema.styles.settings.accent_icons.options__3.label"
          },
          {
            "value": "text",
            "label": "t:settings_schema.styles.settings.accent_icons.options__4.label"
          }
        ],
        "default": "text",
        "label": "t:settings_schema.styles.settings.accent_icons.label"
      }
    ]
  },
  "favicon": {
    "name": "t:settings_schema.favicon.name",
    "settings": [
      {
        "type": "image_picker",
        "id": "favicon",
        "label": "t:settings_schema.favicon.settings.favicon.label",
        "info": "t:settings_schema.favicon.settings.favicon.info"
      }
    ]
  },
  "color_schemes-background-1" : {
    "name": "Background 1",
    "settings": [
      {
        "type": "color",
        "id": "background",
        "label": "t:settings_schema.colors.settings.background.label",
        "default": "#FFFFFF"
      },
      {
        "type": "color_background",
        "id": "background_gradient",
        "label": "t:settings_schema.colors.settings.background_gradient.label",
        "info": "t:settings_schema.colors.settings.background_gradient.info"
      },
      {
        "type": "color",
        "id": "text",
        "label": "t:settings_schema.colors.settings.text.label",
        "default": "#121212"
      },
      {
        "type": "color",
        "id": "button",
        "label": "t:settings_schema.colors.settings.button_background.label",
        "default": "#121212"
      },
      {
        "type": "color",
        "id": "button_label",
        "label": "t:settings_schema.colors.settings.button_label.label",
        "default": "#FFFFFF"
      },
      {
        "type": "color",
        "id": "secondary_button_label",
        "label": "t:settings_schema.colors.settings.secondary_button_label.label",
        "default": "#121212"
      },
      {
        "type": "color",
        "id": "shadow",
        "label": "t:settings_schema.colors.settings.shadow.label",
        "default": "#121212"
      }
    ]
  },
  "color_schemes-background-2" : {
    "name": "Background 2",
    "settings": [
      {
        "type": "color",
        "id": "background",
        "label": "t:settings_schema.colors.settings.background.label",
        "default": "#FFFFFF"
      },
      {
        "type": "color_background",
        "id": "background_gradient",
        "label": "t:settings_schema.colors.settings.background_gradient.label",
        "info": "t:settings_schema.colors.settings.background_gradient.info"
      },
      {
        "type": "color",
        "id": "text",
        "label": "t:settings_schema.colors.settings.text.label",
        "default": "#121212"
      },
      {
        "type": "color",
        "id": "button",
        "label": "t:settings_schema.colors.settings.button_background.label",
        "default": "#121212"
      },
      {
        "type": "color",
        "id": "button_label",
        "label": "t:settings_schema.colors.settings.button_label.label",
        "default": "#FFFFFF"
      },
      {
        "type": "color",
        "id": "secondary_button_label",
        "label": "t:settings_schema.colors.settings.secondary_button_label.label",
        "default": "#121212"
      },
      {
        "type": "color",
        "id": "shadow",
        "label": "t:settings_schema.colors.settings.shadow.label",
        "default": "#121212"
      }
    ]
  },
  "color_schemes-inverse" : {
    "name": "Inverse",
    "settings": [
      {
        "type": "color",
        "id": "background",
        "label": "t:settings_schema.colors.settings.background.label",
        "default": "#FFFFFF"
      },
      {
        "type": "color_background",
        "id": "background_gradient",
        "label": "t:settings_schema.colors.settings.background_gradient.label",
        "info": "t:settings_schema.colors.settings.background_gradient.info"
      },
      {
        "type": "color",
        "id": "text",
        "label": "t:settings_schema.colors.settings.text.label",
        "default": "#121212"
      },
      {
        "type": "color",
        "id": "button",
        "label": "t:settings_schema.colors.settings.button_background.label",
        "default": "#121212"
      },
      {
        "type": "color",
        "id": "button_label",
        "label": "t:settings_schema.colors.settings.button_label.label",
        "default": "#FFFFFF"
      },
      {
        "type": "color",
        "id": "secondary_button_label",
        "label": "t:settings_schema.colors.settings.secondary_button_label.label",
        "default": "#121212"
      },
      {
        "type": "color",
        "id": "shadow",
        "label": "t:settings_schema.colors.settings.shadow.label",
        "default": "#121212"
      }
    ]
  },
  "color_schemes-accent-1" : {
    "name": "Accent 1",
    "settings": [
      {
        "type": "color",
        "id": "background",
        "label": "t:settings_schema.colors.settings.background.label",
        "default": "#FFFFFF"
      },
      {
        "type": "color_background",
        "id": "background_gradient",
        "label": "t:settings_schema.colors.settings.background_gradient.label",
        "info": "t:settings_schema.colors.settings.background_gradient.info"
      },
      {
        "type": "color",
        "id": "text",
        "label": "t:settings_schema.colors.settings.text.label",
        "default": "#121212"
      },
      {
        "type": "color",
        "id": "button",
        "label": "t:settings_schema.colors.settings.button_background.label",
        "default": "#121212"
      },
      {
        "type": "color",
        "id": "button_label",
        "label": "t:settings_schema.colors.settings.button_label.label",
        "default": "#FFFFFF"
      },
      {
        "type": "color",
        "id": "secondary_button_label",
        "label": "t:settings_schema.colors.settings.secondary_button_label.label",
        "default": "#121212"
      },
      {
        "type": "color",
        "id": "shadow",
        "label": "t:settings_schema.colors.settings.shadow.label",
        "default": "#121212"
      }
    ]
  },
  "color_schemes-accent-2" : {
    "name": "Accent 2",
    "settings": [
      {
        "type": "color",
        "id": "background",
        "label": "t:settings_schema.colors.settings.background.label",
        "default": "#FFFFFF"
      },
      {
        "type": "color_background",
        "id": "background_gradient",
        "label": "t:settings_schema.colors.settings.background_gradient.label",
        "info": "t:settings_schema.colors.settings.background_gradient.info"
      },
      {
        "type": "color",
        "id": "text",
        "label": "t:settings_schema.colors.settings.text.label",
        "default": "#121212"
      },
      {
        "type": "color",
        "id": "button",
        "label": "t:settings_schema.colors.settings.button_background.label",
        "default": "#121212"
      },
      {
        "type": "color",
        "id": "button_label",
        "label": "t:settings_schema.colors.settings.button_label.label",
        "default": "#FFFFFF"
      },
      {
        "type": "color",
        "id": "secondary_button_label",
        "label": "t:settings_schema.colors.settings.secondary_button_label.label",
        "default": "#121212"
      },
      {
        "type": "color",
        "id": "shadow",
        "label": "t:settings_schema.colors.settings.shadow.label",
        "default": "#121212"
      }
    ]
  }
}
public static readonly globalSettingLabelsV10 ={
  "settings_schema": {
    "global": {
      "settings": {
        "header__border": {
          "content": "Border"
        },
        "header__shadow": {
          "content": "Shadow"
        },
        "blur": {
          "label": "Blur"
        },
        "corner_radius": {
          "label": "Corner radius"
        },
        "horizontal_offset": {
          "label": "Horizontal offset"
        },
        "vertical_offset": {
          "label": "Vertical offset"
        },
        "thickness": {
          "label": "Thickness"
        },
        "opacity": {
          "label": "Opacity"
        },
        "image_padding": {
          "label": "Image padding"
        },
        "text_alignment": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Text alignment"
        }
      }
    },
    "cards": {
      "name": "Product cards",
      "settings": {
        "style": {
          "options__1": {
            "label": "Standard"
          },
          "options__2": {
            "label": "Card"
          },
          "label": "Style"
        }
      }
    },
    "collection_cards": {
      "name": "Collection cards",
      "settings": {
        "style": {
          "options__1": {
            "label": "Standard"
          },
          "options__2": {
            "label": "Card"
          },
          "label": "Style"
        }
      }
    },
    "blog_cards": {
      "name": "Blog cards",
      "settings": {
        "style": {
          "options__1": {
            "label": "Standard"
          },
          "options__2": {
            "label": "Card"
          },
          "label": "Style"
        }
      }
    },
    "badges": {
      "name": "Badges",
      "settings": {
        "position": {
          "options__1": {
            "label": "Bottom left"
          },
          "options__2": {
            "label": "Bottom right"
          },
          "options__3": {
            "label": "Top left"
          },
          "options__4": {
            "label": "Top right"
          },
          "label": "Position on cards"
        },
        "sale_badge_color_scheme": {
          "label": "Sale badge color scheme"
        },
        "sold_out_badge_color_scheme": {
          "label": "Sold out badge color scheme"
        }
      }
    },
    "brand_information": {
      "name": "Brand information",
      "settings": {
        "paragraph": {
          "content": "Add a brand description to your store's footer."
        },
        "brand_headline": {
          "label": "Headline"
        },
        "brand_description": {
          "label": "Description"
        },
        "brand_image": {
          "label": "Image"
        },
        "brand_image_width": {
          "label": "Image width"
        }
      }
    },
    "colors": {
      "name": "Colors",
      "settings": {
        "background": {
          "label": "Background"
        },
        "background_gradient": {
          "label": "Background gradient",
          "info": "Background gradient replaces background where possible."
        },
        "text": {
          "label": "Text"
        },
        "button_background": {
          "label": "Solid button background"
        },
        "button_label": {
          "label": "Solid button label"
        },
        "secondary_button_label": {
          "label": "Outline button"
        },
        "shadow": {
          "label": "Shadow"
        }
      }
    },
    "logo": {
      "name": "Logo",
      "settings": {
        "logo_image": {
          "label": "Logo"
        },
        "logo_width": {
          "label": "Desktop logo width",
          "info": "Logo width is automatically optimized for mobile."
        },
        "favicon": {
          "label": "Favicon image",
          "info": "Will be scaled down to 32 x 32px"
        }
      }
    },

    "typography": {
      "name": "Typography",
      "settings": {
        "type_header_font": {
          "label": "Font",
          "info": "Selecting a different font can affect the speed of your store. [Learn more about system fonts.](https:\/\/help.shopify.com\/manual\/online-store\/os\/store-speed\/improving-speed#fonts)"
        },
        "heading_scale": {
          "label": "Font size scale"
        },
        "header__1": {
          "content": "Headings"
        },
        "header__2": {
          "content": "Body"
        },
        "type_body_font": {
          "label": "Font",
          "info": "Selecting a different font can affect the speed of your store. [Learn more about system fonts.](https:\/\/help.shopify.com\/manual\/online-store\/os\/store-speed\/improving-speed#fonts)"
        },
        "body_scale": {
          "label": "Font size scale"
        },
        "nofont": {
          "label": "Use system fonts"
        },
      }
    },
    "buttons": {
      "name": "Buttons"
    },
    "variant_pills": {
      "name": "Variant pills",
      "paragraph": "Variant pills are one way of displaying your product variants. [Learn more](https:\/\/help.shopify.com\/en\/manual\/online-store\/themes\/theme-structure\/page-types#variant-picker-block)"
    },
    "inputs": {
      "name": "Inputs"
    },
    "content_containers": {
      "name": "Content containers"
    },
    "popups": {
      "name": "Dropdowns and pop-ups",
      "paragraph": "Affects areas like navigation dropdowns, pop-up modals, and cart pop-ups."
    },
    "media": {
      "name": "Media"
    },
    "drawers": {
      "name": "Drawers"
    },
    "animations": {
      "name": "Animations",
      "settings": {
        "animations_reveal_on_scroll": {
          "label": "Reveal sections on scroll"
        },
        "animations_hover_elements": {
          "options__1": {
            "label": "None"
          },
          "options__2": {
            "label": "3D lift"
          },
          "label": "Hover effect",
          "info": "Affects cards and buttons."
        }
      }
    },
    "social-media": {
      "name": "Social media",
      "settings": {
        "header": {
          "content": "Social accounts"
        },
        "social_twitter_link": {
          "label": "Twitter",
          "info": "https:\/\/twitter.com\/shopify"
        },
        "social_facebook_link": {
          "label": "Facebook",
          "info": "https:\/\/facebook.com\/shopify"
        },
        "social_pinterest_link": {
          "label": "Pinterest",
          "info": "https:\/\/pinterest.com\/shopify"
        },
        "social_instagram_link": {
          "label": "Instagram",
          "info": "http:\/\/instagram.com\/shopify"
        },
        "social_tiktok_link": {
          "label": "TikTok",
          "info": "https:\/\/tiktok.com\/@shopify"
        },
        "social_tumblr_link": {
          "label": "Tumblr",
          "info": "https:\/\/shopify.tumblr.com"
        },
        "social_snapchat_link": {
          "label": "Snapchat",
          "info": "https:\/\/www.snapchat.com\/add\/shopify"
        },
        "social_youtube_link": {
          "label": "YouTube",
          "info": "https:\/\/www.youtube.com\/shopify"
        },
        "social_vimeo_link": {
          "label": "Vimeo",
          "info": "https:\/\/vimeo.com\/shopify"
        }
      }
    },
    "search_input": {
      "name": "Search behavior",
      "settings": {
        "header": {
          "content": "Search suggestions"
        },
        "predictive_search_enabled": {
          "label": "Enable search suggestions"
        },
        "predictive_search_show_vendor": {
          "label": "Show product vendor",
          "info": "Visible when search suggestions enabled."
        },
        "predictive_search_show_price": {
          "label": "Show product price",
          "info": "Visible when search suggestions enabled."
        }
      }
    },
    "currency_format": {
      "name": "Currency format",
      "settings": {
        "content": "Currency codes",
        "paragraph": "Cart and checkout prices always show currency codes. Example: $1.00 USD.",
        "currency_code_enabled": {
          "label": "Show currency codes"
        }
      }
    },
    "cart": {
      "name": "Cart",
      "settings": {
        "cart_type": {
          "label": "Cart type",
          "drawer": {
            "label": "Drawer"
          },
          "page": {
            "label": "Page"
          },
          "notification": {
            "label": "Popup notification"
          }
        },
        "show_vendor": {
          "label": "Show vendor"
        },
        "show_cart_note": {
          "label": "Enable cart note"
        },
        "cart_drawer": {
          "header": "Cart drawer",
          "collection": {
            "label": "Collection",
            "info": "Visible when cart drawer is empty."
          }
        }
      }
    },
    "layout": {
      "name": "Layout",
      "settings": {
        "page_width": {
          "label": "Page width"
        },
        "spacing_sections": {
          "label": "Space between template sections"
        },
        "header__grid": {
          "content": "Grid"
        },
        "paragraph__grid": {
          "content": "Affects areas with multiple columns or rows."
        },
        "spacing_grid_horizontal": {
          "label": "Horizontal space"
        },
        "spacing_grid_vertical": {
          "label": "Vertical space"
        }
      }
    }
  },
  "sections": {
    "all": {
      "animation": {
        "content": "Animations",
        "image_behavior": {
          "options__1": {
            "label": "None"
          },
          "options__2": {
            "label": "Ambient movement"
          },
          "options__3": {
            "label": "Fixed background position"
          },
          "label": "Image behavior"
        }
      },
      "padding": {
        "section_padding_heading": "Section padding",
        "padding_top": "Top padding",
        "padding_bottom": "Bottom padding"
      },
      "spacing": "Spacing",
      "colors": {
        "label": "Color scheme",
        "has_cards_info": "To change the card color scheme, update your theme settings."
      },
      "heading_size": {
        "label": "Heading size",
        "options__1": {
          "label": "Small"
        },
        "options__2": {
          "label": "Medium"
        },
        "options__3": {
          "label": "Large"
        },
        "options__4": {
          "label": "Extra large"
        }
      },
      "image_shape": {
        "options__1": {
          "label": "Default"
        },
        "options__2": {
          "label": "Arch"
        },
        "options__3": {
          "label": "Blob"
        },
        "options__4": {
          "label": "Chevron left"
        },
        "options__5": {
          "label": "Chevron right"
        },
        "options__6": {
          "label": "Diamond"
        },
        "options__7": {
          "label": "Parallelogram"
        },
        "options__8": {
          "label": "Round"
        },
        "label": "Image shape",
        "info": "Standard-styled cards have no borders when an image shape is active."
      }
    },
    "announcement-bar": {
      "name": "Announcement bar",
      "settings": {
        "auto_rotate": {
          "label": "Auto-rotate slides"
        },
        "change_slides_speed": {
          "label": "Change slides every"
        }
      },
      "blocks": {
        "announcement": {
          "name": "Announcement",
          "settings": {
            "text": {
              "label": "Text"
            },
            "text_alignment": {
              "label": "Text alignment",
              "options__1": {
                "label": "Left"
              },
              "options__2": {
                "label": "Center"
              },
              "options__3": {
                "label": "Right"
              }
            },
            "link": {
              "label": "Link"
            }
          }
        }
      }
    },
    "apps": {
      "name": "Apps",
      "settings": {
        "include_margins": {
          "label": "Make section margins the same as theme"
        }
      },
      "presets": {
        "name": "Apps"
      }
    },
    "collage": {
      "name": "Collage",
      "settings": {
        "heading": {
          "label": "Heading"
        },
        "desktop_layout": {
          "label": "Desktop layout",
          "options__1": {
            "label": "Left large block"
          },
          "options__2": {
            "label": "Right large block"
          }
        },
        "mobile_layout": {
          "label": "Mobile layout",
          "options__1": {
            "label": "Collage"
          },
          "options__2": {
            "label": "Column"
          }
        },
        "card_styles": {
          "label": "Card style",
          "info": "Product, collection, and blog card styles can be updated in theme settings.",
          "options__1": {
            "label": "Use individual card styles"
          },
          "options__2": {
            "label": "Style all as product cards"
          }
        }
      },
      "blocks": {
        "image": {
          "name": "Image",
          "settings": {
            "image": {
              "label": "Image"
            }
          }
        },
        "product": {
          "name": "Product",
          "settings": {
            "product": {
              "label": "Product"
            },
            "secondary_background": {
              "label": "Show secondary background"
            },
            "second_image": {
              "label": "Show second image on hover"
            }
          }
        },
        "collection": {
          "name": "Collection",
          "settings": {
            "collection": {
              "label": "Collection"
            }
          }
        },
        "video": {
          "name": "Video",
          "settings": {
            "cover_image": {
              "label": "Cover image"
            },
            "video_url": {
              "label": "URL",
              "info": "Video plays in a pop-up if the section contains other blocks.",
              "placeholder": "Use a YouTube or Vimeo URL"
            },
            "description": {
              "label": "Video alt text",
              "info": "Describe the video for customers using screen readers. [Learn more](https:\/\/help.shopify.com\/manual\/online-store\/themes\/theme-structure\/theme-features#video-block)"
            }
          }
        }
      },
      "presets": {
        "name": "Collage"
      }
    },
    "collection-list": {
      "name": "Collection list",
      "settings": {
        "title": {
          "label": "Heading"
        },
        "image_ratio": {
          "label": "Image ratio",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Portrait"
          },
          "options__3": {
            "label": "Square"
          },
          "info": "Add images by editing your collections. [Learn more](https:\/\/help.shopify.com\/manual\/products\/collections)"
        },
        "columns_desktop": {
          "label": "Number of columns on desktop"
        },
        "show_view_all": {
          "label": "Enable \"View all\" button if list includes more collections than shown"
        },
        "header_mobile": {
          "content": "Mobile Layout"
        },
        "columns_mobile": {
          "label": "Number of columns on mobile",
          "options__1": {
            "label": "1 column"
          },
          "options__2": {
            "label": "2 columns"
          }
        },
        "swipe_on_mobile": {
          "label": "Enable swipe on mobile"
        }
      },
      "blocks": {
        "featured_collection": {
          "name": "Collection",
          "settings": {
            "collection": {
              "label": "Collection"
            }
          }
        }
      },
      "presets": {
        "name": "Collection list"
      }
    },
    "contact-form": {
      "name": "Contact Form",
      "presets": {
        "name": "Contact form"
      }
    },
    "custom-liquid": {
      "name": "Custom Liquid",
      "settings": {
        "custom_liquid": {
          "label": "Custom Liquid",
          "info": "Add app snippets or other Liquid code to create advanced customizations."
        }
      },
      "presets": {
        "name": "Custom Liquid"
      }
    },
    "custom-section": {
      "name": "custom section",
      "presets": {
        "name": "Custom Section"
      }
    },
    "featured-blog": {
      "name": "Blog posts",
      "settings": {
        "heading": {
          "label": "Heading"
        },
        "blog": {
          "label": "Blog"
        },
        "post_limit": {
          "label": "Number of blog posts to show"
        },
        "columns_desktop": {
          "label": "Number of columns on desktop"
        },
        "show_view_all": {
          "label": "Enable \"View all\" button if blog includes more blog posts than shown"
        },
        "show_image": {
          "label": "Show featured image",
          "info": "For best results, use an image with a 3:2 aspect ratio. [Learn more](https:\/\/help.shopify.com\/manual\/shopify-admin\/productivity-tools\/image-editor#understanding-image-aspect-ratio)"
        },
        "show_date": {
          "label": "Show date"
        },
        "show_author": {
          "label": "Show author"
        }
      },
      "presets": {
        "name": "Blog posts"
      }
    },
    "featured-collection": {
      "name": "Featured collection",
      "settings": {
        "title": {
          "label": "Heading"
        },
        "description": {
          "label": "Description"
        },
        "show_description": {
          "label": "Show collection description from the admin"
        },
        "description_style": {
          "label": "Description style",
          "options__1": {
            "label": "Body"
          },
          "options__2": {
            "label": "Subtitle"
          },
          "options__3": {
            "label": "Uppercase"
          }
        },
        "collection": {
          "label": "Collection"
        },
        "products_to_show": {
          "label": "Maximum products to show"
        },
        "columns_desktop": {
          "label": "Number of columns on desktop"
        },
        "show_view_all": {
          "label": "Enable \"View all\" if collection has more products than shown"
        },
        "view_all_style": {
          "label": "\"View all\" style",
          "options__1": {
            "label": "Link"
          },
          "options__2": {
            "label": "Outline button"
          },
          "options__3": {
            "label": "Solid button"
          }
        },
        "enable_desktop_slider": {
          "label": "Enable carousel on desktop"
        },
        "full_width": {
          "label": "Make products full width"
        },
        "header": {
          "content": "Product card"
        },
        "image_ratio": {
          "label": "Image ratio",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Portrait"
          },
          "options__3": {
            "label": "Square"
          }
        },
        "show_secondary_image": {
          "label": "Show second image on hover"
        },
        "show_vendor": {
          "label": "Show vendor"
        },
        "show_rating": {
          "label": "Show product rating",
          "info": "To display a rating, add a product rating app. [Learn more](https:\/\/help.shopify.com\/manual\/online-store\/themes\/theme-structure\/theme-features#featured-collection-show-product-rating)"
        },
        "enable_quick_buy": {
          "label": "Enable quick add button",
          "info": "Optimal with popup or drawer cart type."
        },
        "header_mobile": {
          "content": "Mobile Layout"
        },
        "columns_mobile": {
          "label": "Number of columns on mobile",
          "options__1": {
            "label": "1 column"
          },
          "options__2": {
            "label": "2 columns"
          }
        },
        "swipe_on_mobile": {
          "label": "Enable swipe on mobile"
        }
      },
      "presets": {
        "name": "Featured collection"
      }
    },
    "featured-product": {
      "name": "Featured product",
      "blocks": {
        "text": {
          "name": "Text",
          "settings": {
            "text": {
              "label": "Text"
            },
            "text_style": {
              "label": "Text style",
              "options__1": {
                "label": "Body"
              },
              "options__2": {
                "label": "Subtitle"
              },
              "options__3": {
                "label": "Uppercase"
              }
            }
          }
        },
        "title": {
          "name": "Title"
        },
        "price": {
          "name": "Price"
        },
        "quantity_selector": {
          "name": "Quantity selector"
        },
        "variant_picker": {
          "name": "Variant picker",
          "settings": {
            "picker_type": {
              "label": "Type",
              "options__1": {
                "label": "Dropdown"
              },
              "options__2": {
                "label": "Pills"
              }
            }
          }
        },
        "buy_buttons": {
          "name": "Buy buttons",
          "settings": {
            "show_dynamic_checkout": {
              "label": "Show dynamic checkout buttons",
              "info": "Using the payment methods available on your store, customers see their preferred option, like PayPal or Apple Pay. [Learn more](https:\/\/help.shopify.com\/manual\/using-themes\/change-the-layout\/dynamic-checkout)"
            }
          }
        },
        "sku": {
          "name": "SKU",
          "settings": {
            "text_style": {
              "label": "Text style",
              "options__1": {
                "label": "Body"
              },
              "options__2": {
                "label": "Subtitle"
              },
              "options__3": {
                "label": "Uppercase"
              }
            }
          }
        },
        "description": {
          "name": "Description"
        },
        "share": {
          "name": "Share",
          "settings": {
            "text": {
              "label": "Text"
            },
            "featured_image_info": {
              "content": "If you include a link in social media posts, the page’s featured image will be shown as the preview image. [Learn more](https:\/\/help.shopify.com\/manual\/online-store\/images\/showing-social-media-thumbnail-images)"
            },
            "title_info": {
              "content": "A store title and description are included with the preview image. [Learn more](https:\/\/help.shopify.com\/manual\/promoting-marketing\/seo\/adding-keywords#set-a-title-and-description-for-your-online-store)"
            }
          }
        },
        "custom_liquid": {
          "name": "Custom liquid",
          "settings": {
            "custom_liquid": {
              "label": "Custom liquid"
            }
          }
        },
        "rating": {
          "name": "Product rating",
          "settings": {
            "paragraph": {
              "content": "To display a rating, add a product rating app. [Learn more](https:\/\/help.shopify.com\/manual\/online-store\/themes\/theme-structure\/theme-features#featured-product-rating)"
            }
          }
        }
      },
      "settings": {
        "product": {
          "label": "Product"
        },
        "secondary_background": {
          "label": "Show secondary background"
        },
        "header": {
          "content": "Media",
          "info": "Learn more about [media types](https:\/\/help.shopify.com\/manual\/products\/product-media)"
        },
        "media_position": {
          "label": "Desktop media position",
          "info": "Position is automatically optimized for mobile.",
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Right"
          }
        },
        "hide_variants": {
          "label": "Hide unselected variants’ media on desktop"
        },
        "enable_video_looping": {
          "label": "Enable video looping"
        }
      },
      "presets": {
        "name": "Featured product"
      }
    },
    "footer": {
      "name": "Footer",
      "blocks": {
        "link_list": {
          "name": "Menu",
          "settings": {
            "heading": {
              "label": "Heading"
            },
            "menu": {
              "label": "Menu",
              "info": "Displays only top-level menu items."
            }
          }
        },
        "brand_information": {
          "name": "Brand information",
          "settings": {
            "paragraph": {
              "content": "This block will display your brand information. [Edit brand information.](\/editor?context=theme&category=brand%20information)"
            },
            "header__1": {
              "content": "Social media icons"
            },
            "show_social": {
              "label": "Show social media icons",
              "info": "To display your social media accounts, link them in your [theme settings](\/editor?context=theme&category=social%20media)."
            }
          }
        },
        "text": {
          "name": "Text",
          "settings": {
            "heading": {
              "label": "Heading"
            },
            "subtext": {
              "label": "Subtext"
            }
          }
        }
      },
      "settings": {
        "newsletter_enable": {
          "label": "Show email signup"
        },
        "newsletter_heading": {
          "label": "Heading"
        },
        "header__1": {
          "content": "Email Signup",
          "info": "Subscribers added automatically to your “accepted marketing” customer list. [Learn more](https:\/\/help.shopify.com\/manual\/customers\/manage-customers)"
        },
        "header__2": {
          "content": "Social media icons",
          "info": "To display your social media accounts, link them in your [theme settings](\/editor?context=theme&category=social%20media)."
        },
        "show_social": {
          "label": "Show social media icons"
        },
        "header__3": {
          "content": "Country\/region selector"
        },
        "header__4": {
          "info": "To add a country\/region, go to your [market settings.](\/admin\/settings\/markets)"
        },
        "enable_country_selector": {
          "label": "Enable country\/region selector"
        },
        "header__5": {
          "content": "Language selector"
        },
        "header__6": {
          "info": "To add a language, go to your [language settings.](\/admin\/settings\/languages)"
        },
        "enable_language_selector": {
          "label": "Enable language selector"
        },
        "header__7": {
          "content": "Payment methods"
        },
        "payment_enable": {
          "label": "Show payment icons"
        },
        "header__8": {
          "content": "Policy links",
          "info": "To add store policies, go to your [policy settings](\/admin\/settings\/legal)."
        },
        "show_policy": {
          "label": "Show policy links"
        },
        "margin_top": {
          "label": "Top margin"
        },
        "header__9": {
          "content": "Follow on Shop",
          "info": "To allow customers to follow your store on the Shop app from your storefront, Shop Pay must be enabled. [Learn more](https:\/\/help.shopify.com\/manual\/online-store\/themes\/customizing-themes\/follow-on-shop)"
        },
        "enable_follow_on_shop": {
          "label": "Enable Follow on Shop"
        }
      }
    },
    "header": {
      "name": "Header",
      "settings": {
        "logo_help": {
          "content": "Edit your logo in [theme settings](\/editor?context=theme&category=logo)."
        },
        "logo_position": {
          "label": "Desktop logo position",
          "options__1": {
            "label": "Middle left"
          },
          "options__2": {
            "label": "Top left"
          },
          "options__3": {
            "label": "Top center"
          },
          "options__4": {
            "label": "Middle center"
          }
        },
        "menu": {
          "label": "Menu"
        },
        "menu_type_desktop": {
          "label": "Desktop menu type",
          "info": "Menu type is automatically optimized for mobile.",
          "options__1": {
            "label": "Dropdown"
          },
          "options__2": {
            "label": "Mega menu"
          },
          "options__3": {
            "label": "Drawer"
          }
        },
        "show_line_separator": {
          "label": "Show separator line"
        },
        "sticky_header_type": {
          "label": "Sticky header",
          "options__1": {
            "label": "None"
          },
          "options__2": {
            "label": "On scroll up"
          },
          "options__3": {
            "label": "Always"
          },
          "options__4": {
            "label": "Always, reduce logo size"
          }
        },
        "header__3": {
          "content": "Country\/region selector"
        },
        "header__4": {
          "info": "To add a country\/region, go to your [market settings.](\/admin\/settings\/markets)"
        },
        "enable_country_selector": {
          "label": "Enable country\/region selector"
        },
        "header__5": {
          "content": "Language selector"
        },
        "header__6": {
          "info": "To add a language, go to your [language settings.](\/admin\/settings\/languages)"
        },
        "enable_language_selector": {
          "label": "Enable language selector"
        },
        "margin_bottom": {
          "label": "Bottom margin"
        },
        "mobile_layout": {
          "content": "Mobile layout"
        },
        "mobile_logo_position": {
          "label": "Mobile logo position",
          "options__1": {
            "label": "Center"
          },
          "options__2": {
            "label": "Left"
          }
        }
      }
    },
    "image-banner": {
      "name": "Image banner",
      "settings": {
        "image": {
          "label": "First image"
        },
        "image_2": {
          "label": "Second image"
        },
        "image_overlay_opacity": {
          "label": "Image overlay opacity"
        },
        "image_height": {
          "label": "Banner height",
          "options__1": {
            "label": "Adapt to first image"
          },
          "options__2": {
            "label": "Small"
          },
          "options__3": {
            "label": "Medium"
          },
          "options__4": {
            "label": "Large"
          },
          "info": "For best results, use an image with a 3:2 aspect ratio. [Learn more](https:\/\/help.shopify.com\/manual\/shopify-admin\/productivity-tools\/image-editor#understanding-image-aspect-ratio)"
        },
        "desktop_content_position": {
          "options__1": {
            "label": "Top Left"
          },
          "options__2": {
            "label": "Top Center"
          },
          "options__3": {
            "label": "Top Right"
          },
          "options__4": {
            "label": "Middle Left"
          },
          "options__5": {
            "label": "Middle Center"
          },
          "options__6": {
            "label": "Middle Right"
          },
          "options__7": {
            "label": "Bottom Left"
          },
          "options__8": {
            "label": "Bottom Center"
          },
          "options__9": {
            "label": "Bottom Right"
          },
          "label": "Desktop content position"
        },
        "show_text_box": {
          "label": "Show container on desktop"
        },
        "desktop_content_alignment": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Desktop content alignment"
        },
        "mobile": {
          "content": "Mobile Layout"
        },
        "mobile_content_alignment": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Mobile content alignment"
        },
        "stack_images_on_mobile": {
          "label": "Stack images on mobile"
        },
        "show_text_below": {
          "label": "Show container on mobile"
        }
      },
      "blocks": {
        "heading": {
          "name": "Heading",
          "settings": {
            "heading": {
              "label": "Heading"
            }
          }
        },
        "text": {
          "name": "Text",
          "settings": {
            "text": {
              "label": "Description"
            },
            "text_style": {
              "options__1": {
                "label": "Body"
              },
              "options__2": {
                "label": "Subtitle"
              },
              "options__3": {
                "label": "Uppercase"
              },
              "label": "Text style"
            }
          }
        },
        "buttons": {
          "name": "Buttons",
          "settings": {
            "button_label_1": {
              "label": "First button label",
              "info": "Leave the label blank to hide the button."
            },
            "button_link_1": {
              "label": "First button link"
            },
            "button_style_secondary_1": {
              "label": "Use outline button style"
            },
            "button_label_2": {
              "label": "Second button label",
              "info": "Leave the label blank to hide the button."
            },
            "button_link_2": {
              "label": "Second button link"
            },
            "button_style_secondary_2": {
              "label": "Use outline button style"
            }
          }
        }
      },
      "presets": {
        "name": "Image banner"
      }
    },
    "image-with-text": {
      "name": "Image with text",
      "settings": {
        "image": {
          "label": "Image"
        },
        "height": {
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Small"
          },
          "options__3": {
            "label": "Medium"
          },
          "options__4": {
            "label": "Large"
          },
          "label": "Image height"
        },
        "desktop_image_width": {
          "options__1": {
            "label": "Small"
          },
          "options__2": {
            "label": "Medium"
          },
          "options__3": {
            "label": "Large"
          },
          "label": "Desktop image width",
          "info": "Image is automatically optimized for mobile."
        },
        "layout": {
          "options__1": {
            "label": "Image first"
          },
          "options__2": {
            "label": "Image second"
          },
          "label": "Desktop image placement",
          "info": "Image first is the default mobile layout."
        },
        "desktop_content_alignment": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Desktop content alignment"
        },
        "desktop_content_position": {
          "options__1": {
            "label": "Top"
          },
          "options__2": {
            "label": "Middle"
          },
          "options__3": {
            "label": "Bottom"
          },
          "label": "Desktop content position"
        },
        "content_layout": {
          "options__1": {
            "label": "No overlap"
          },
          "options__2": {
            "label": "Overlap"
          },
          "label": "Content layout"
        },
        "mobile_content_alignment": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Mobile content alignment"
        }
      },
      "blocks": {
        "heading": {
          "name": "Heading",
          "settings": {
            "heading": {
              "label": "Heading"
            }
          }
        },
        "caption": {
          "name": "Caption",
          "settings": {
            "text": {
              "label": "Text"
            },
            "text_style": {
              "label": "Text style",
              "options__1": {
                "label": "Subtitle"
              },
              "options__2": {
                "label": "Uppercase"
              }
            },
            "caption_size": {
              "label": "Text size",
              "options__1": {
                "label": "Small"
              },
              "options__2": {
                "label": "Medium"
              },
              "options__3": {
                "label": "Large"
              }
            }
          }
        },
        "text": {
          "name": "Text",
          "settings": {
            "text": {
              "label": "Content"
            },
            "text_style": {
              "label": "Text style",
              "options__1": {
                "label": "Body"
              },
              "options__2": {
                "label": "Subtitle"
              }
            }
          }
        },
        "button": {
          "name": "Button",
          "settings": {
            "button_label": {
              "label": "Button label",
              "info": "Leave the label blank to hide the button."
            },
            "button_link": {
              "label": "Button link"
            }
          }
        }
      },
      "presets": {
        "name": "Image with text"
      }
    },
    "multirow": {
      "name": "Multirow",
      "settings": {
        "image": {
          "label": "Image"
        },
        "image_height": {
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Small"
          },
          "options__3": {
            "label": "Medium"
          },
          "options__4": {
            "label": "Large"
          },
          "label": "Image height"
        },
        "desktop_image_width": {
          "options__1": {
            "label": "Small"
          },
          "options__2": {
            "label": "Medium"
          },
          "options__3": {
            "label": "Large"
          },
          "label": "Desktop image width",
          "info": "Image is automatically optimized for mobile."
        },
        "heading_size": {
          "options__1": {
            "label": "Small"
          },
          "options__2": {
            "label": "Medium"
          },
          "options__3": {
            "label": "Large"
          },
          "label": "Heading size"
        },
        "text_style": {
          "options__1": {
            "label": "Body"
          },
          "options__2": {
            "label": "Subtitle"
          },
          "label": "Text style"
        },
        "button_style": {
          "options__1": {
            "label": "Solid button"
          },
          "options__2": {
            "label": "Outline button"
          },
          "label": "Button style"
        },
        "desktop_content_alignment": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Desktop content alignment"
        },
        "desktop_content_position": {
          "options__1": {
            "label": "Top"
          },
          "options__2": {
            "label": "Middle"
          },
          "options__3": {
            "label": "Bottom"
          },
          "label": "Desktop content position",
          "info": "Position is automatically optimized for mobile."
        },
        "image_layout": {
          "options__1": {
            "label": "Alternate from left"
          },
          "options__2": {
            "label": "Alternate from right"
          },
          "options__3": {
            "label": "Aligned left"
          },
          "options__4": {
            "label": "Aligned right"
          },
          "label": "Desktop image placement",
          "info": "Placement is automatically optimized for mobile."
        },
        "container_color_scheme": {
          "label": "Container color scheme"
        },
        "mobile_content_alignment": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Mobile content alignment"
        },
        "header_mobile": {
          "content": "Mobile Layout"
        }
      },
      "blocks": {
        "row": {
          "name": "Row",
          "settings": {
            "image": {
              "label": "Image"
            },
            "caption": {
              "label": "Caption"
            },
            "heading": {
              "label": "Heading"
            },
            "text": {
              "label": "Text"
            },
            "button_label": {
              "label": "Button label"
            },
            "button_link": {
              "label": "Button link"
            }
          }
        }
      },
      "presets": {
        "name": "Multirow"
      }
    },
    "main-account": {
      "name": "Account"
    },
    "main-activate-account": {
      "name": "Account activation"
    },
    "main-addresses": {
      "name": "Addresses"
    },
    "main-article": {
      "name": "Blog post",
      "blocks": {
        "featured_image": {
          "name": "Featured image",
          "settings": {
            "image_height": {
              "label": "Featured image height",
              "options__1": {
                "label": "Adapt to image"
              },
              "options__2": {
                "label": "Small"
              },
              "options__3": {
                "label": "Medium"
              },
              "options__4": {
                "label": "Large"
              },
              "info": "For best results, use an image with a 16:9 aspect ratio. [Learn more](https:\/\/help.shopify.com\/manual\/shopify-admin\/productivity-tools\/image-editor#understanding-image-aspect-ratio)"
            }
          }
        },
        "title": {
          "name": "Title",
          "settings": {
            "blog_show_date": {
              "label": "Show date"
            },
            "blog_show_author": {
              "label": "Show author"
            }
          }
        },
        "content": {
          "name": "Content"
        },
        "share": {
          "name": "Share",
          "settings": {
            "text": {
              "label": "Text"
            },
            "featured_image_info": {
              "content": "If you include a link in social media posts, the page’s featured image will be shown as the preview image. [Learn more](https:\/\/help.shopify.com\/manual\/online-store\/images\/showing-social-media-thumbnail-images)."
            },
            "title_info": {
              "content": "A store title and description are included with the preview image. [Learn more](https:\/\/help.shopify.com\/manual\/promoting-marketing\/seo\/adding-keywords#set-a-title-and-description-for-your-online-store)."
            }
          }
        }
      }
    },
    "main-blog": {
      "name": "Blog posts",
      "settings": {
        "header": {
          "content": "Blog post card"
        },
        "show_image": {
          "label": "Show featured image"
        },
        "show_date": {
          "label": "Show date"
        },
        "show_author": {
          "label": "Show author"
        },
        "paragraph": {
          "content": "Change excerpts by editing your blog posts. [Learn more](https:\/\/help.shopify.com\/manual\/online-store\/blogs\/writing-blogs#display-an-excerpt-from-a-blog-post)"
        },
        "layout": {
          "label": "Desktop layout",
          "options__1": {
            "label": "Grid"
          },
          "options__2": {
            "label": "Collage"
          },
          "info": "Posts are stacked on mobile."
        },
        "image_height": {
          "label": "Featured image height",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Small"
          },
          "options__3": {
            "label": "Medium"
          },
          "options__4": {
            "label": "Large"
          },
          "info": "For best results, use an image with a 3:2 aspect ratio. [Learn more](https:\/\/help.shopify.com\/manual\/shopify-admin\/productivity-tools\/image-editor#understanding-image-aspect-ratio)"
        }
      }
    },
    "main-cart-footer": {
      "name": "Subtotal",
      "blocks": {
        "subtotal": {
          "name": "Subtotal price"
        },
        "buttons": {
          "name": "Checkout button"
        }
      }
    },
    "main-cart-items": {
      "name": "Items"
    },
    "main-collection-banner": {
      "name": "Collection banner",
      "settings": {
        "paragraph": {
          "content": "Add a description or image by editing your collection. [Learn more](https:\/\/help.shopify.com\/manual\/products\/collections\/collection-layout)"
        },
        "show_collection_description": {
          "label": "Show collection description"
        },
        "show_collection_image": {
          "label": "Show collection image",
          "info": "For best results, use an image with a 16:9 aspect ratio. [Learn more](https:\/\/help.shopify.com\/manual\/shopify-admin\/productivity-tools\/image-editor#understanding-image-aspect-ratio)"
        }
      }
    },
    "main-collection-product-grid": {
      "name": "Product grid",
      "settings": {
        "products_per_page": {
          "label": "Products per page"
        },
        "columns_desktop": {
          "label": "Number of columns on desktop"
        },
        "enable_filtering": {
          "label": "Enable filtering",
          "info": "Customize filters with the Search & Discovery app. [Learn more](https:\/\/help.shopify.com\/manual\/online-store\/search-and-discovery\/filters)"
        },
        "filter_type": {
          "label": "Desktop filter layout",
          "options__1": {
            "label": "Horizontal"
          },
          "options__2": {
            "label": "Vertical"
          },
          "options__3": {
            "label": "Drawer"
          },
          "info": "Drawer is the default mobile layout."
        },
        "enable_sorting": {
          "label": "Enable sorting"
        },
        "image_ratio": {
          "label": "Image ratio",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Portrait"
          },
          "options__3": {
            "label": "Square"
          }
        },
        "show_secondary_image": {
          "label": "Show second image on hover"
        },
        "show_vendor": {
          "label": "Show vendor"
        },
        "show_rating": {
          "label": "Show product rating",
          "info": "To display a rating, add a product rating app. [Learn more](https:\/\/help.shopify.com\/manual\/online-store\/themes\/theme-structure\/page-types#product-grid-section-settings)"
        },
        "header__1": {
          "content": "Filtering and sorting"
        },
        "header__3": {
          "content": "Product card"
        },
        "enable_tags": {
          "label": "Enable filtering",
          "info": "Customize filters with the Search & Discovery app. [Learn more](https:\/\/help.shopify.com\/manual\/online-store\/search-and-discovery\/filters)"
        },
        "enable_quick_buy": {
          "label": "Enable quick add button",
          "info": "Optimal with popup or drawer cart type."
        },
        "header_mobile": {
          "content": "Mobile Layout"
        },
        "columns_mobile": {
          "label": "Number of columns on mobile",
          "options__1": {
            "label": "1 column"
          },
          "options__2": {
            "label": "2 columns"
          }
        }
      }
    },
    "main-list-collections": {
      "name": "Collections list page",
      "settings": {
        "title": {
          "label": "Heading"
        },
        "sort": {
          "label": "Sort collections by:",
          "options__1": {
            "label": "Alphabetically, A-Z"
          },
          "options__2": {
            "label": "Alphabetically, Z-A"
          },
          "options__3": {
            "label": "Date, new to old"
          },
          "options__4": {
            "label": "Date, old to new"
          },
          "options__5": {
            "label": "Product count, high to low"
          },
          "options__6": {
            "label": "Product count, low to high"
          }
        },
        "image_ratio": {
          "label": "Image ratio",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Portrait"
          },
          "options__3": {
            "label": "Square"
          },
          "info": "Add images by editing your collections. [Learn more](https:\/\/help.shopify.com\/manual\/products\/collections)"
        },
        "columns_desktop": {
          "label": "Number of columns on desktop"
        },
        "header_mobile": {
          "content": "Mobile Layout"
        },
        "columns_mobile": {
          "label": "Number of columns on mobile",
          "options__1": {
            "label": "1 column"
          },
          "options__2": {
            "label": "2 columns"
          }
        }
      }
    },
    "main-login": {
      "name": "Login"
    },
    "main-order": {
      "name": "Order"
    },
    "main-page": {
      "name": "Page"
    },
    "main-password-footer": {
      "name": "Password footer"
    },
    "main-password-header": {
      "name": "Password header",
      "settings": {
        "logo_header": {
          "content": "Logo"
        },
        "logo_help": {
          "content": "Edit your logo in theme settings."
        }
      }
    },
    "main-product": {
      "name": "Product information",
      "blocks": {
        "text": {
          "name": "Text",
          "settings": {
            "text": {
              "label": "Text"
            },
            "text_style": {
              "label": "Text style",
              "options__1": {
                "label": "Body"
              },
              "options__2": {
                "label": "Subtitle"
              },
              "options__3": {
                "label": "Uppercase"
              }
            }
          }
        },
        "title": {
          "name": "Title"
        },
        "price": {
          "name": "Price"
        },
        "inventory": {
          "name": "Inventory status",
          "settings": {
            "text_style": {
              "label": "Text style",
              "options__1": {
                "label": "Body"
              },
              "options__2": {
                "label": "Subtitle"
              },
              "options__3": {
                "label": "Uppercase"
              }
            },
            "inventory_threshold": {
              "label": "Low inventory threshold",
              "info": "Choose 0 to always show in stock if available."
            },
            "show_inventory_quantity": {
              "label": "Show inventory count"
            }
          }
        },
        "quantity_selector": {
          "name": "Quantity selector"
        },
        "variant_picker": {
          "name": "Variant picker",
          "settings": {
            "picker_type": {
              "label": "Type",
              "options__1": {
                "label": "Dropdown"
              },
              "options__2": {
                "label": "Pills"
              }
            }
          }
        },
        "buy_buttons": {
          "name": "Buy buttons",
          "settings": {
            "show_dynamic_checkout": {
              "label": "Show dynamic checkout buttons",
              "info": "Using the payment methods available on your store, customers see their preferred option, like PayPal or Apple Pay. [Learn more](https:\/\/help.shopify.com\/manual\/using-themes\/change-the-layout\/dynamic-checkout)"
            },
            "show_gift_card_recipient": {
              "label": "Show recipient information form for gift card products",
              "info": "Gift card products can optionally be sent direct to a recipient on a scheduled date along with a personal message. [Learn more](https:\/\/help.shopify.com\/manual\/online-store\/themes\/customizing-themes\/add-gift-card-recipient-fields)"
            }
          }
        },
        "pickup_availability": {
          "name": "Pickup availability"
        },
        "description": {
          "name": "Description"
        },
        "sku": {
          "name": "SKU",
          "settings": {
            "text_style": {
              "label": "Text style",
              "options__1": {
                "label": "Body"
              },
              "options__2": {
                "label": "Subtitle"
              },
              "options__3": {
                "label": "Uppercase"
              }
            }
          }
        },
        "share": {
          "name": "Share",
          "settings": {
            "text": {
              "label": "Text"
            },
            "featured_image_info": {
              "content": "If you include a link in social media posts, the page’s featured image will be shown as the preview image. [Learn more](https:\/\/help.shopify.com\/manual\/online-store\/images\/showing-social-media-thumbnail-images)."
            },
            "title_info": {
              "content": "A store title and description are included with the preview image. [Learn more](https:\/\/help.shopify.com\/manual\/promoting-marketing\/seo\/adding-keywords#set-a-title-and-description-for-your-online-store)."
            }
          }
        },
        "custom_liquid": {
          "name": "Custom liquid",
          "settings": {
            "custom_liquid": {
              "label": "Custom liquid",
              "info": "Add app snippets or other Liquid code to create advanced customizations."
            }
          }
        },
        "collapsible_tab": {
          "name": "Collapsible row",
          "settings": {
            "heading": {
              "info": "Include a heading that explains the content.",
              "label": "Heading"
            },
            "content": {
              "label": "Row content"
            },
            "page": {
              "label": "Row content from page"
            },
            "icon": {
              "label": "Icon",
              "options__1": {
                "label": "None"
              },
              "options__2": {
                "label": "Apple"
              },
              "options__3": {
                "label": "Banana"
              },
              "options__4": {
                "label": "Bottle"
              },
              "options__5": {
                "label": "Box"
              },
              "options__6": {
                "label": "Carrot"
              },
              "options__7": {
                "label": "Chat bubble"
              },
              "options__8": {
                "label": "Check mark"
              },
              "options__9": {
                "label": "Clipboard"
              },
              "options__10": {
                "label": "Dairy"
              },
              "options__11": {
                "label": "Dairy free"
              },
              "options__12": {
                "label": "Dryer"
              },
              "options__13": {
                "label": "Eye"
              },
              "options__14": {
                "label": "Fire"
              },
              "options__15": {
                "label": "Gluten free"
              },
              "options__16": {
                "label": "Heart"
              },
              "options__17": {
                "label": "Iron"
              },
              "options__18": {
                "label": "Leaf"
              },
              "options__19": {
                "label": "Leather"
              },
              "options__20": {
                "label": "Lightning bolt"
              },
              "options__21": {
                "label": "Lipstick"
              },
              "options__22": {
                "label": "Lock"
              },
              "options__23": {
                "label": "Map pin"
              },
              "options__24": {
                "label": "Nut free"
              },
              "options__25": {
                "label": "Pants"
              },
              "options__26": {
                "label": "Paw print"
              },
              "options__27": {
                "label": "Pepper"
              },
              "options__28": {
                "label": "Perfume"
              },
              "options__29": {
                "label": "Plane"
              },
              "options__30": {
                "label": "Plant"
              },
              "options__31": {
                "label": "Price tag"
              },
              "options__32": {
                "label": "Question mark"
              },
              "options__33": {
                "label": "Recycle"
              },
              "options__34": {
                "label": "Return"
              },
              "options__35": {
                "label": "Ruler"
              },
              "options__36": {
                "label": "Serving dish"
              },
              "options__37": {
                "label": "Shirt"
              },
              "options__38": {
                "label": "Shoe"
              },
              "options__39": {
                "label": "Silhouette"
              },
              "options__40": {
                "label": "Snowflake"
              },
              "options__41": {
                "label": "Star"
              },
              "options__42": {
                "label": "Stopwatch"
              },
              "options__43": {
                "label": "Truck"
              },
              "options__44": {
                "label": "Washing"
              }
            }
          }
        },
        "popup": {
          "name": "Pop-up",
          "settings": {
            "link_label": {
              "label": "Link label"
            },
            "page": {
              "label": "Page"
            }
          }
        },
        "rating": {
          "name": "Product rating",
          "settings": {
            "paragraph": {
              "content": "To display a rating, add a product rating app. [Learn more](https:\/\/help.shopify.com\/manual\/online-store\/themes\/theme-structure\/page-types#product-rating-block)"
            }
          }
        },
        "complementary_products": {
          "name": "Complementary products",
          "settings": {
            "paragraph": {
              "content": "To select complementary products, add the Search & Discovery app. [Learn more](https:\/\/help.shopify.com\/manual\/online-store\/search-and-discovery\/product-recommendations)"
            },
            "heading": {
              "label": "Heading"
            },
            "make_collapsible_row": {
              "label": "Show as collapsible row"
            },
            "icon": {
              "info": "Visible when collapsible row is displayed."
            },
            "product_list_limit": {
              "label": "Maximum products to show"
            },
            "products_per_page": {
              "label": "Number of products per page"
            },
            "pagination_style": {
              "label": "Pagination style",
              "options": {
                "option_1": "Dots",
                "option_2": "Counter",
                "option_3": "Numbers"
              }
            },
            "product_card": {
              "heading": "Product card"
            },
            "image_ratio": {
              "label": "Image ratio",
              "options": {
                "option_1": "Portrait",
                "option_2": "Square"
              }
            },
            "enable_quick_add": {
              "label": "Enable quick add button"
            }
          }
        },
        "icon_with_text": {
          "name": "Icon with text",
          "settings": {
            "layout": {
              "label": "Layout",
              "options__1": {
                "label": "Horizontal"
              },
              "options__2": {
                "label": "Vertical"
              }
            },
            "content": {
              "label": "Content",
              "info": "Choose an icon or add an image for each column or row."
            },
            "heading": {
              "info": "Leave the heading label blank to hide the icon column."
            },
            "icon_1": {
              "label": "First icon"
            },
            "image_1": {
              "label": "First image"
            },
            "heading_1": {
              "label": "First heading"
            },
            "icon_2": {
              "label": "Second icon"
            },
            "image_2": {
              "label": "Second image"
            },
            "heading_2": {
              "label": "Second heading"
            },
            "icon_3": {
              "label": "Third icon"
            },
            "image_3": {
              "label": "Third image"
            },
            "heading_3": {
              "label": "Third heading"
            }
          }
        }
      },
      "settings": {
        "header": {
          "content": "Media",
          "info": "Learn more about [media types.](https:\/\/help.shopify.com\/manual\/products\/product-media)"
        },
        "enable_sticky_info": {
          "label": "Enable sticky content on desktop"
        },
        "gallery_layout": {
          "label": "Desktop layout",
          "options__1": {
            "label": "Stacked"
          },
          "options__2": {
            "label": "2 columns"
          },
          "options__3": {
            "label": "Thumbnails"
          },
          "options__4": {
            "label": "Thumbnail carousel"
          }
        },
        "constrain_to_viewport": {
          "label": "Constrain media to screen height"
        },
        "media_size": {
          "label": "Desktop media width",
          "info": "Media is automatically optimized for mobile.",
          "options__1": {
            "label": "Small"
          },
          "options__2": {
            "label": "Medium"
          },
          "options__3": {
            "label": "Large"
          }
        },
        "image_zoom": {
          "label": "Image zoom",
          "info": "Click and hover defaults to open lightbox on mobile.",
          "options__1": {
            "label": "Open lightbox"
          },
          "options__2": {
            "label": "Click and hover"
          },
          "options__3": {
            "label": "No zoom"
          }
        },
        "media_position": {
          "label": "Desktop media position",
          "info": "Position is automatically optimized for mobile.",
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Right"
          }
        },
        "media_fit": {
          "label": "Media fit",
          "options__1": {
            "label": "Original"
          },
          "options__2": {
            "label": "Fill"
          }
        },
        "mobile_thumbnails": {
          "label": "Mobile layout",
          "options__1": {
            "label": "2 columns"
          },
          "options__2": {
            "label": "Show thumbnails"
          },
          "options__3": {
            "label": "Hide thumbnails"
          }
        },
        "hide_variants": {
          "label": "Hide other variants’ media after selecting a variant"
        },
        "enable_video_looping": {
          "label": "Enable video looping"
        }
      }
    },
    "main-register": {
      "name": "Registration"
    },
    "main-reset-password": {
      "name": "Password reset"
    },
    "main-search": {
      "name": "Search results",
      "settings": {
        "columns_desktop": {
          "label": "Number of columns on desktop"
        },
        "image_ratio": {
          "label": "Image ratio",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Portrait"
          },
          "options__3": {
            "label": "Square"
          }
        },
        "show_secondary_image": {
          "label": "Show second image on hover"
        },
        "show_vendor": {
          "label": "Show vendor"
        },
        "show_rating": {
          "label": "Show product rating",
          "info": "To display a rating, add a product rating app. [Learn more](https:\/\/help.shopify.com\/manual\/online-store\/themes\/theme-structure\/page-types#search-results-section-settings)"
        },
        "header__1": {
          "content": "Product card"
        },
        "header__2": {
          "content": "Blog card",
          "info": "Blog card styles also apply to page cards in search results. To change card styles update your theme settings."
        },
        "article_show_date": {
          "label": "Show date"
        },
        "article_show_author": {
          "label": "Show author"
        },
        "header_mobile": {
          "content": "Mobile Layout"
        },
        "columns_mobile": {
          "label": "Number of columns on mobile",
          "options__1": {
            "label": "1 column"
          },
          "options__2": {
            "label": "2 columns"
          }
        }
      }
    },
    "multicolumn": {
      "name": "Multicolumn",
      "settings": {
        "title": {
          "label": "Heading"
        },
        "image_width": {
          "label": "Image width",
          "options__1": {
            "label": "One-third width of column"
          },
          "options__2": {
            "label": "Half width of column"
          },
          "options__3": {
            "label": "Full width of column"
          }
        },
        "image_ratio": {
          "label": "Image ratio",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Portrait"
          },
          "options__3": {
            "label": "Square"
          },
          "options__4": {
            "label": "Circle"
          }
        },
        "columns_desktop": {
          "label": "Number of columns on desktop"
        },
        "column_alignment": {
          "label": "Column alignment",
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          }
        },
        "background_style": {
          "label": "Secondary background",
          "options__1": {
            "label": "None"
          },
          "options__2": {
            "label": "Show as column background"
          }
        },
        "button_label": {
          "label": "Button label"
        },
        "button_link": {
          "label": "Button link"
        },
        "header_mobile": {
          "content": "Mobile Layout"
        },
        "columns_mobile": {
          "label": "Number of columns on mobile",
          "options__1": {
            "label": "1 column"
          },
          "options__2": {
            "label": "2 columns"
          }
        },
        "swipe_on_mobile": {
          "label": "Enable swipe on mobile"
        }
      },
      "blocks": {
        "column": {
          "name": "Column",
          "settings": {
            "image": {
              "label": "Image"
            },
            "title": {
              "label": "Heading"
            },
            "text": {
              "label": "Description"
            },
            "link_label": {
              "label": "Link label"
            },
            "link": {
              "label": "Link"
            }
          }
        }
      },
      "presets": {
        "name": "Multicolumn"
      }
    },
    "newsletter": {
      "name": "Email signup",
      "settings": {
        "full_width": {
          "label": "Make section full width"
        },
        "paragraph": {
          "content": "Each email subscription creates a customer account. [Learn more](https:\/\/help.shopify.com\/manual\/customers)"
        }
      },
      "blocks": {
        "heading": {
          "name": "Heading",
          "settings": {
            "heading": {
              "label": "Heading"
            }
          }
        },
        "paragraph": {
          "name": "Subheading",
          "settings": {
            "paragraph": {
              "label": "Description"
            }
          }
        },
        "email_form": {
          "name": "Email form"
        }
      },
      "presets": {
        "name": "Email signup"
      }
    },
    "email-signup-banner": {
      "name": "Email signup banner",
      "settings": {
        "paragraph": {
          "content": "Each email subscription creates a customer account. [Learn more](https:\/\/help.shopify.com\/manual\/customers)"
        },
        "image": {
          "label": "Background image"
        },
        "image_overlay_opacity": {
          "label": "Image overlay opacity"
        },
        "show_background_image": {
          "label": "Show background image"
        },
        "show_text_box": {
          "label": "Show container on desktop"
        },
        "desktop_content_position": {
          "options__1": {
            "label": "Top Left"
          },
          "options__2": {
            "label": "Top Center"
          },
          "options__3": {
            "label": "Top Right"
          },
          "options__4": {
            "label": "Middle Left"
          },
          "options__5": {
            "label": "Middle Center"
          },
          "options__6": {
            "label": "Middle Right"
          },
          "options__7": {
            "label": "Bottom Left"
          },
          "options__8": {
            "label": "Bottom Center"
          },
          "options__9": {
            "label": "Bottom Right"
          },
          "label": "Desktop content position"
        },
        "color_scheme": {
          "info": "Visible when container displayed."
        },
        "image_height": {
          "label": "Banner height",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Small"
          },
          "options__3": {
            "label": "Medium"
          },
          "options__4": {
            "label": "Large"
          },
          "info": "For best results, use an image with a 16:9 aspect ratio. [Learn more](https:\/\/help.shopify.com\/manual\/shopify-admin\/productivity-tools\/image-editor#understanding-image-aspect-ratio)"
        },
        "desktop_content_alignment": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Desktop content alignment"
        },
        "header": {
          "content": "Mobile Layout"
        },
        "mobile_content_alignment": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Mobile content alignment"
        },
        "show_text_below": {
          "label": "Show content below image on mobile",
          "info": "For best results, use an image with a 16:9 aspect ratio. [Learn more](https:\/\/help.shopify.com\/manual\/shopify-admin\/productivity-tools\/image-editor#understanding-image-aspect-ratio)"
        }
      },
      "blocks": {
        "heading": {
          "name": "Heading",
          "settings": {
            "heading": {
              "label": "Heading"
            }
          }
        },
        "paragraph": {
          "name": "Paragraph",
          "settings": {
            "paragraph": {
              "label": "Description"
            },
            "text_style": {
              "options__1": {
                "label": "Body"
              },
              "options__2": {
                "label": "Subtitle"
              },
              "label": "Text style"
            }
          }
        },
        "email_form": {
          "name": "Email form"
        }
      },
      "presets": {
        "name": "Email signup banner"
      }
    },
    "page": {
      "name": "Page",
      "settings": {
        "page": {
          "label": "Page"
        }
      },
      "presets": {
        "name": "Page"
      }
    },
    "related-products": {
      "name": "Related products",
      "settings": {
        "heading": {
          "label": "Heading"
        },
        "products_to_show": {
          "label": "Maximum products to show"
        },
        "columns_desktop": {
          "label": "Number of columns on desktop"
        },
        "paragraph__1": {
          "content": "Dynamic recommendations use order and product information to change and improve over time. [Learn more](https:\/\/help.shopify.com\/themes\/development\/recommended-products)"
        },
        "header__2": {
          "content": "Product card"
        },
        "image_ratio": {
          "label": "Image ratio",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Portrait"
          },
          "options__3": {
            "label": "Square"
          }
        },
        "show_secondary_image": {
          "label": "Show second image on hover"
        },
        "show_vendor": {
          "label": "Show vendor"
        },
        "show_rating": {
          "label": "Show product rating",
          "info": "To display a rating, add a product rating app. [Learn more](https:\/\/help.shopify.com\/manual\/online-store\/themes\/theme-structure\/page-types#product-recommendations-section-settings)"
        },
        "header_mobile": {
          "content": "Mobile Layout"
        },
        "columns_mobile": {
          "label": "Number of columns on mobile",
          "options__1": {
            "label": "1 column"
          },
          "options__2": {
            "label": "2 columns"
          }
        }
      }
    },
    "rich-text": {
      "name": "Rich text",
      "settings": {
        "desktop_content_position": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Desktop content position",
          "info": "Position is automatically optimized for mobile."
        },
        "content_alignment": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Content alignment"
        },
        "full_width": {
          "label": "Make section full width"
        }
      },
      "blocks": {
        "heading": {
          "name": "Heading",
          "settings": {
            "heading": {
              "label": "Heading"
            }
          }
        },
        "caption": {
          "name": "Caption",
          "settings": {
            "text": {
              "label": "Text"
            },
            "text_style": {
              "label": "Text style",
              "options__1": {
                "label": "Subtitle"
              },
              "options__2": {
                "label": "Uppercase"
              }
            },
            "caption_size": {
              "label": "Text size",
              "options__1": {
                "label": "Small"
              },
              "options__2": {
                "label": "Medium"
              },
              "options__3": {
                "label": "Large"
              }
            }
          }
        },
        "text": {
          "name": "Text",
          "settings": {
            "text": {
              "label": "Description"
            }
          }
        },
        "buttons": {
          "name": "Buttons",
          "settings": {
            "button_label_1": {
              "label": "First button label",
              "info": "Leave the label blank to hide the button."
            },
            "button_link_1": {
              "label": "First button link"
            },
            "button_style_secondary_1": {
              "label": "Use outline button style"
            },
            "button_label_2": {
              "label": "Second button label",
              "info": "Leave the label blank to hide the button."
            },
            "button_link_2": {
              "label": "Second button link"
            },
            "button_style_secondary_2": {
              "label": "Use outline button style"
            }
          }
        }
      },
      "presets": {
        "name": "Rich text"
      }
    },
    "video": {
      "name": "Video",
      "settings": {
        "heading": {
          "label": "Heading"
        },
        "cover_image": {
          "label": "Cover image"
        },
        "video": {
          "label": "Video"
        },
        "enable_video_looping": {
          "label": "Play video on loop"
        },
        "header__1": {
          "content": "Shopify-hosted video"
        },
        "header__2": {
          "content": "Or embed video from URL"
        },
        "header__3": {
          "content": "Style"
        },
        "paragraph": {
          "content": "Shows when no Shopify-hosted video is selected."
        },
        "video_url": {
          "label": "URL",
          "info": "Use a YouTube or Vimeo URL"
        },
        "description": {
          "label": "Video alt text",
          "info": "Describe the video for customers using screen readers. [Learn more](https:\/\/help.shopify.com\/manual\/online-store\/themes\/theme-structure\/theme-features#video)"
        },
        "image_padding": {
          "label": "Add image padding",
          "info": "Select image padding if you don't want your cover image to be cropped."
        },
        "full_width": {
          "label": "Make section full width"
        }
      },
      "presets": {
        "name": "Video"
      }
    },
    "slideshow": {
      "name": "Slideshow",
      "settings": {
        "layout": {
          "label": "Layout",
          "options__1": {
            "label": "Full width"
          },
          "options__2": {
            "label": "Grid"
          }
        },
        "slide_height": {
          "label": "Slide height",
          "options__1": {
            "label": "Adapt to first image"
          },
          "options__2": {
            "label": "Small"
          },
          "options__3": {
            "label": "Medium"
          },
          "options__4": {
            "label": "Large"
          }
        },
        "slider_visual": {
          "label": "Pagination style",
          "options__1": {
            "label": "Counter"
          },
          "options__2": {
            "label": "Dots"
          },
          "options__3": {
            "label": "Numbers"
          }
        },
        "auto_rotate": {
          "label": "Auto-rotate slides"
        },
        "change_slides_speed": {
          "label": "Change slides every"
        },
        "mobile": {
          "content": "Mobile layout"
        },
        "show_text_below": {
          "label": "Show content below images on mobile"
        },
        "accessibility": {
          "content": "Accessibility",
          "label": "Slideshow description",
          "info": "Describe the slideshow for customers using screen readers."
        }
      },
      "blocks": {
        "slide": {
          "name": "Slide",
          "settings": {
            "image": {
              "label": "Image"
            },
            "heading": {
              "label": "Heading"
            },
            "subheading": {
              "label": "Subheading"
            },
            "button_label": {
              "label": "Button label",
              "info": "Leave the label blank to hide the button."
            },
            "link": {
              "label": "Button link"
            },
            "secondary_style": {
              "label": "Use outline button style"
            },
            "box_align": {
              "label": "Desktop content position",
              "info": "Position is automatically optimized for mobile.",
              "options__1": {
                "label": "Top left"
              },
              "options__2": {
                "label": "Top center"
              },
              "options__3": {
                "label": "Top right"
              },
              "options__4": {
                "label": "Middle left"
              },
              "options__5": {
                "label": "Middle center"
              },
              "options__6": {
                "label": "Middle right"
              },
              "options__7": {
                "label": "Bottom left"
              },
              "options__8": {
                "label": "Bottom center"
              },
              "options__9": {
                "label": "Bottom right"
              }
            },
            "show_text_box": {
              "label": "Show container on desktop"
            },
            "text_alignment": {
              "label": "Desktop content alignment",
              "option_1": {
                "label": "Left"
              },
              "option_2": {
                "label": "Center"
              },
              "option_3": {
                "label": "Right"
              }
            },
            "image_overlay_opacity": {
              "label": "Image overlay opacity"
            },
            "text_alignment_mobile": {
              "label": "Mobile content alignment",
              "options__1": {
                "label": "Left"
              },
              "options__2": {
                "label": "Center"
              },
              "options__3": {
                "label": "Right"
              }
            }
          }
        }
      },
      "presets": {
        "name": "Slideshow"
      }
    },
    "collapsible_content": {
      "name": "Collapsible content",
      "settings": {
        "caption": {
          "label": "Caption"
        },
        "heading": {
          "label": "Heading"
        },
        "heading_alignment": {
          "label": "Heading alignment",
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          }
        },
        "layout": {
          "label": "Layout",
          "options__1": {
            "label": "No container"
          },
          "options__2": {
            "label": "Row container"
          },
          "options__3": {
            "label": "Section container"
          }
        },
        "container_color_scheme": {
          "label": "Container color scheme",
          "info": "Visible when Layout is set to Row or Section container."
        },
        "open_first_collapsible_row": {
          "label": "Open first collapsible row"
        },
        "header": {
          "content": "Image layout"
        },
        "image": {
          "label": "Image"
        },
        "image_ratio": {
          "label": "Image ratio",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Small"
          },
          "options__3": {
            "label": "Large"
          }
        },
        "desktop_layout": {
          "label": "Desktop layout",
          "options__1": {
            "label": "Image first"
          },
          "options__2": {
            "label": "Image second"
          },
          "info": "Image is always first on mobile."
        }
      },
      "blocks": {
        "collapsible_row": {
          "name": "Collapsible row",
          "settings": {
            "heading": {
              "info": "Include a heading that explains the content.",
              "label": "Heading"
            },
            "row_content": {
              "label": "Row content"
            },
            "page": {
              "label": "Row content from page"
            },
            "icon": {
              "label": "Icon",
              "options__1": {
                "label": "None"
              },
              "options__2": {
                "label": "Apple"
              },
              "options__3": {
                "label": "Banana"
              },
              "options__4": {
                "label": "Bottle"
              },
              "options__5": {
                "label": "Box"
              },
              "options__6": {
                "label": "Carrot"
              },
              "options__7": {
                "label": "Chat bubble"
              },
              "options__8": {
                "label": "Check mark"
              },
              "options__9": {
                "label": "Clipboard"
              },
              "options__10": {
                "label": "Dairy"
              },
              "options__11": {
                "label": "Dairy free"
              },
              "options__12": {
                "label": "Dryer"
              },
              "options__13": {
                "label": "Eye"
              },
              "options__14": {
                "label": "Fire"
              },
              "options__15": {
                "label": "Gluten free"
              },
              "options__16": {
                "label": "Heart"
              },
              "options__17": {
                "label": "Iron"
              },
              "options__18": {
                "label": "Leaf"
              },
              "options__19": {
                "label": "Leather"
              },
              "options__20": {
                "label": "Lightning bolt"
              },
              "options__21": {
                "label": "Lipstick"
              },
              "options__22": {
                "label": "Lock"
              },
              "options__23": {
                "label": "Map pin"
              },
              "options__24": {
                "label": "Nut free"
              },
              "options__25": {
                "label": "Pants"
              },
              "options__26": {
                "label": "Paw print"
              },
              "options__27": {
                "label": "Pepper"
              },
              "options__28": {
                "label": "Perfume"
              },
              "options__29": {
                "label": "Plane"
              },
              "options__30": {
                "label": "Plant"
              },
              "options__31": {
                "label": "Price tag"
              },
              "options__32": {
                "label": "Question mark"
              },
              "options__33": {
                "label": "Recycle"
              },
              "options__34": {
                "label": "Return"
              },
              "options__35": {
                "label": "Ruler"
              },
              "options__36": {
                "label": "Serving dish"
              },
              "options__37": {
                "label": "Shirt"
              },
              "options__38": {
                "label": "Shoe"
              },
              "options__39": {
                "label": "Silhouette"
              },
              "options__40": {
                "label": "Snowflake"
              },
              "options__41": {
                "label": "Star"
              },
              "options__42": {
                "label": "Stopwatch"
              },
              "options__43": {
                "label": "Truck"
              },
              "options__44": {
                "label": "Washing"
              }
            }
          }
        }
      },
      "presets": {
        "name": "Collapsible content"
      }
    }
  }
}

public static readonly labelDataV10 = {
  "sections": {
    "all": {
      "animation": {
        "content": "Animations",
        "image_behavior": {
          "options__1": {
            "label": "None"
          },
          "options__2": {
            "label": "Ambient movement"
          },
          "options__3": {
            "label": "Fixed background position"
          },
          "label": "Image behavior"
        }
      },
      "padding": {
        "section_padding_heading": "Section padding",
        "padding_top": "Top padding",
        "padding_bottom": "Bottom padding"
      },
      "spacing": "Spacing",
      "colors": {
        "label": "Color scheme",
        "has_cards_info": "To change the card color scheme, update your theme settings."
      },
      "heading_size": {
        "label": "Heading size",
        "options__1": {
          "label": "Small"
        },
        "options__2": {
          "label": "Medium"
        },
        "options__3": {
          "label": "Large"
        },
        "options__4": {
          "label": "Extra large"
        }
      },
      "image_shape": {
        "options__1": {
          "label": "Default"
        },
        "options__2": {
          "label": "Arch"
        },
        "options__3": {
          "label": "Blob"
        },
        "options__4": {
          "label": "Chevron left"
        },
        "options__5": {
          "label": "Chevron right"
        },
        "options__6": {
          "label": "Diamond"
        },
        "options__7": {
          "label": "Parallelogram"
        },
        "options__8": {
          "label": "Round"
        },
        "label": "Image shape",
        "info": "Standard-styled cards have no borders when an image shape is active."
      }
    },
    "announcement-bar": {
      "name": "Announcement bar",
      "settings": {
        "auto_rotate": {
          "label": "Auto-rotate slides"
        },
        "change_slides_speed": {
          "label": "Change slides every"
        }
      },
      "blocks": {
        "announcement": {
          "name": "Announcement",
          "settings": {
            "text": {
              "label": "Text"
            },
            "text_alignment": {
              "label": "Text alignment",
              "options__1": {
                "label": "Left"
              },
              "options__2": {
                "label": "Center"
              },
              "options__3": {
                "label": "Right"
              }
            },
            "link": {
              "label": "Link"
            }
          }
        }
      }
    },
    "apps": {
      "name": "Apps",
      "settings": {
        "include_margins": {
          "label": "Make section margins the same as theme"
        }
      },
      "presets": {
        "name": "Apps"
      }
    },
    "collage": {
      "name": "Collage",
      "settings": {
        "heading": {
          "label": "Heading"
        },
        "desktop_layout": {
          "label": "Desktop layout",
          "options__1": {
            "label": "Left large block"
          },
          "options__2": {
            "label": "Right large block"
          }
        },
        "mobile_layout": {
          "label": "Mobile layout",
          "options__1": {
            "label": "Collage"
          },
          "options__2": {
            "label": "Column"
          }
        },
        "card_styles": {
          "label": "Card style",
          "info": "Product, collection, and blog card styles can be updated in theme settings.",
          "options__1": {
            "label": "Use individual card styles"
          },
          "options__2": {
            "label": "Style all as product cards"
          }
        }
      },
      "blocks": {
        "image": {
          "name": "Image",
          "settings": {
            "image": {
              "label": "Image"
            }
          }
        },
        "product": {
          "name": "Product",
          "settings": {
            "product": {
              "label": "Product"
            },
            "secondary_background": {
              "label": "Show secondary background"
            },
            "second_image": {
              "label": "Show second image on hover"
            }
          }
        },
        "collection": {
          "name": "Collection",
          "settings": {
            "collection": {
              "label": "Collection"
            }
          }
        },
        "video": {
          "name": "Video",
          "settings": {
            "cover_image": {
              "label": "Cover image"
            },
            "video_url": {
              "label": "URL",
              "info": "Video plays in a pop-up if the section contains other blocks.",
              "placeholder": "Use a YouTube or Vimeo URL"
            },
            "description": {
              "label": "Video alt text",
              "info": "Describe the video for customers using screen readers. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/theme-features#video-block)"
            }
          }
        }
      },
      "presets": {
        "name": "Collage"
      }
    },
    "collection-list": {
      "name": "Collection list",
      "settings": {
        "title": {
          "label": "Heading"
        },
        "image_ratio": {
          "label": "Image ratio",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Portrait"
          },
          "options__3": {
            "label": "Square"
          },
          "info": "Add images by editing your collections. [Learn more](https://help.shopify.com/manual/products/collections)"
        },
        "columns_desktop": {
          "label": "Number of columns on desktop"
        },
        "show_view_all": {
          "label": "Enable \"View all\" button if list includes more collections than shown"
        },
        "header_mobile": {
          "content": "Mobile Layout"
        },
        "columns_mobile": {
          "label": "Number of columns on mobile",
          "options__1": {
            "label": "1 column"
          },
          "options__2": {
            "label": "2 columns"
          }
        },
        "swipe_on_mobile": {
          "label": "Enable swipe on mobile"
        }
      },
      "blocks": {
        "featured_collection": {
          "name": "Collection",
          "settings": {
            "collection": {
              "label": "Collection"
            }
          }
        }
      },
      "presets": {
        "name": "Collection list"
      }
    },
    "contact-form": {
      "name": "Contact Form",
      "presets": {
        "name": "Contact form"
      }
    },
    "custom-liquid": {
      "name": "Custom Liquid",
      "settings": {
        "custom_liquid": {
          "label": "Custom Liquid",
          "info": "Add app snippets or other Liquid code to create advanced customizations."
        }
      },
      "presets": {
        "name": "Custom Liquid"
      }
    },
    "custom-section": {
      "name": "Custom Section",
      "presets": {
        "name": "custom section"
      }
    },
    "featured-blog": {
      "name": "Blog posts",
      "settings": {
        "heading": {
          "label": "Heading"
        },
        "blog": {
          "label": "Blog"
        },
        "post_limit": {
          "label": "Number of blog posts to show"
        },
        "columns_desktop": {
          "label": "Number of columns on desktop"
        },
        "show_view_all": {
          "label": "Enable \"View all\" button if blog includes more blog posts than shown"
        },
        "show_image": {
          "label": "Show featured image",
          "info": "For best results, use an image with a 3:2 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
        },
        "show_date": {
          "label": "Show date"
        },
        "show_author": {
          "label": "Show author"
        }
      },
      "presets": {
        "name": "Blog posts"
      }
    },
    "featured-collection": {
      "name": "Featured collection",
      "settings": {
        "title": {
          "label": "Heading"
        },
        "description": {
          "label": "Description"
        },
        "show_description": {
          "label": "Show collection description from the admin"
        },
        "description_style": {
          "label": "Description style",
          "options__1": {
            "label": "Body"
          },
          "options__2": {
            "label": "Subtitle"
          },
          "options__3": {
            "label": "Uppercase"
          }
        },
        "collection": {
          "label": "Collection"
        },
        "products_to_show": {
          "label": "Maximum products to show"
        },
        "columns_desktop": {
          "label": "Number of columns on desktop"
        },
        "show_view_all": {
          "label": "Enable \"View all\" if collection has more products than shown"
        },
        "view_all_style": {
          "label": "\"View all\" style",
          "options__1": {
            "label": "Link"
          },
          "options__2": {
            "label": "Outline button"
          },
          "options__3": {
            "label": "Solid button"
          }
        },
        "enable_desktop_slider": {
          "label": "Enable carousel on desktop"
        },
        "full_width": {
          "label": "Make products full width"
        },
        "header": {
          "content": "Product card"
        },
        "image_ratio": {
          "label": "Image ratio",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Portrait"
          },
          "options__3": {
            "label": "Square"
          }
        },
        "show_secondary_image": {
          "label": "Show second image on hover"
        },
        "show_vendor": {
          "label": "Show vendor"
        },
        "show_rating": {
          "label": "Show product rating",
          "info": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/theme-features#featured-collection-show-product-rating)"
        },
        "enable_quick_buy": {
          "label": "Enable quick add button",
          "info": "Optimal with popup or drawer cart type."
        },
        "header_mobile": {
          "content": "Mobile Layout"
        },
        "columns_mobile": {
          "label": "Number of columns on mobile",
          "options__1": {
            "label": "1 column"
          },
          "options__2": {
            "label": "2 columns"
          }
        },
        "swipe_on_mobile": {
          "label": "Enable swipe on mobile"
        }
      },
      "presets": {
        "name": "Featured collection"
      }
    },
    "featured-product": {
      "name": "Featured product",
      "blocks": {
        "text": {
          "name": "Text",
          "settings": {
            "text": {
              "label": "Text"
            },
            "text_style": {
              "label": "Text style",
              "options__1": {
                "label": "Body"
              },
              "options__2": {
                "label": "Subtitle"
              },
              "options__3": {
                "label": "Uppercase"
              }
            }
          }
        },
        "title": {
          "name": "Title"
        },
        "price": {
          "name": "Price"
        },
        "quantity_selector": {
          "name": "Quantity selector"
        },
        "variant_picker": {
          "name": "Variant picker",
          "settings": {
            "picker_type": {
              "label": "Type",
              "options__1": {
                "label": "Dropdown"
              },
              "options__2": {
                "label": "Pills"
              }
            }
          }
        },
        "buy_buttons": {
          "name": "Buy buttons",
          "settings": {
            "show_dynamic_checkout": {
              "label": "Show dynamic checkout buttons",
              "info": "Using the payment methods available on your store, customers see their preferred option, like PayPal or Apple Pay. [Learn more](https://help.shopify.com/manual/using-themes/change-the-layout/dynamic-checkout)"
            }
          }
        },
        "sku": {
          "name": "SKU",
          "settings": {
            "text_style": {
              "label": "Text style",
              "options__1": {
                "label": "Body"
              },
              "options__2": {
                "label": "Subtitle"
              },
              "options__3": {
                "label": "Uppercase"
              }
            }
          }
        },
        "description": {
          "name": "Description"
        },
        "share": {
          "name": "Share",
          "settings": {
            "text": {
              "label": "Text"
            },
            "featured_image_info": {
              "content": "If you include a link in social media posts, the page’s featured image will be shown as the preview image. [Learn more](https://help.shopify.com/manual/online-store/images/showing-social-media-thumbnail-images)"
            },
            "title_info": {
              "content": "A store title and description are included with the preview image. [Learn more](https://help.shopify.com/manual/promoting-marketing/seo/adding-keywords#set-a-title-and-description-for-your-online-store)"
            }
          }
        },
        "custom_liquid": {
          "name": "Custom liquid",
          "settings": {
            "custom_liquid": {
              "label": "Custom liquid"
            }
          }
        },
        "rating": {
          "name": "Product rating",
          "settings": {
            "paragraph": {
              "content": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/theme-features#featured-product-rating)"
            }
          }
        }
      },
      "settings": {
        "product": {
          "label": "Product"
        },
        "secondary_background": {
          "label": "Show secondary background"
        },
        "header": {
          "content": "Media",
          "info": "Learn more about [media types](https://help.shopify.com/manual/products/product-media)"
        },
        "media_position": {
          "label": "Desktop media position",
          "info": "Position is automatically optimized for mobile.",
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Right"
          }
        },
        "hide_variants": {
          "label": "Hide unselected variants’ media on desktop"
        },
        "enable_video_looping": {
          "label": "Enable video looping"
        }
      },
      "presets": {
        "name": "Featured product"
      }
    },
    "footer": {
      "name": "Footer",
      "blocks": {
        "link_list": {
          "name": "Menu",
          "settings": {
            "heading": {
              "label": "Heading"
            },
            "menu": {
              "label": "Menu",
              "info": "Displays only top-level menu items."
            }
          }
        },
        "brand_information": {
          "name": "Brand information",
          "settings": {
            "paragraph": {
              "content": "This block will display your brand information. [Edit brand information.](/editor?context=theme&category=brand%20information)"
            },
            "header__1": {
              "content": "Social media icons"
            },
            "show_social": {
              "label": "Show social media icons",
              "info": "To display your social media accounts, link them in your [theme settings](/editor?context=theme&category=social%20media)."
            }
          }
        },
        "text": {
          "name": "Text",
          "settings": {
            "heading": {
              "label": "Heading"
            },
            "subtext": {
              "label": "Subtext"
            }
          }
        }
      },
      "settings": {
        "newsletter_enable": {
          "label": "Show email signup"
        },
        "newsletter_heading": {
          "label": "Heading"
        },
        "header__1": {
          "content": "Email Signup",
          "info": "Subscribers added automatically to your “accepted marketing” customer list. [Learn more](https://help.shopify.com/manual/customers/manage-customers)"
        },
        "header__2": {
          "content": "Social media icons",
          "info": "To display your social media accounts, link them in your [theme settings](/editor?context=theme&category=social%20media)."
        },
        "show_social": {
          "label": "Show social media icons"
        },
        "header__3": {
          "content": "Country/region selector"
        },
        "header__4": {
          "info": "To add a country/region, go to your [market settings.](/admin/settings/markets)"
        },
        "enable_country_selector": {
          "label": "Enable country/region selector"
        },
        "header__5": {
          "content": "Language selector"
        },
        "header__6": {
          "info": "To add a language, go to your [language settings.](/admin/settings/languages)"
        },
        "enable_language_selector": {
          "label": "Enable language selector"
        },
        "header__7": {
          "content": "Payment methods"
        },
        "payment_enable": {
          "label": "Show payment icons"
        },
        "header__8": {
          "content": "Policy links",
          "info": "To add store policies, go to your [policy settings](/admin/settings/legal)."
        },
        "show_policy": {
          "label": "Show policy links"
        },
        "margin_top": {
          "label": "Top margin"
        },
        "header__9": {
          "content": "Follow on Shop",
          "info": "To allow customers to follow your store on the Shop app from your storefront, Shop Pay must be enabled. [Learn more](https://help.shopify.com/manual/online-store/themes/customizing-themes/follow-on-shop)"
        },
        "enable_follow_on_shop": {
          "label": "Enable Follow on Shop"
        }
      }
    },
    "header": {
      "name": "Header",
      "settings": {
        "logo_help": {
          "content": "Edit your logo in [theme settings](/editor?context=theme&category=logo)."
        },
        "logo_position": {
          "label": "Desktop logo position",
          "options__1": {
            "label": "Middle left"
          },
          "options__2": {
            "label": "Top left"
          },
          "options__3": {
            "label": "Top center"
          },
          "options__4": {
            "label": "Middle center"
          }
        },
        "menu": {
          "label": "Menu"
        },
        "menu_type_desktop": {
          "label": "Desktop menu type",
          "info": "Menu type is automatically optimized for mobile.",
          "options__1": {
            "label": "Dropdown"
          },
          "options__2": {
            "label": "Mega menu"
          },
          "options__3": {
            "label": "Drawer"
          }
        },
        "show_line_separator": {
          "label": "Show separator line"
        },
        "sticky_header_type": {
          "label": "Sticky header",
          "options__1": {
            "label": "None"
          },
          "options__2": {
            "label": "On scroll up"
          },
          "options__3": {
            "label": "Always"
          },
          "options__4": {
            "label": "Always, reduce logo size"
          }
        },
        "header__3": {
          "content": "Country/region selector"
        },
        "header__4": {
          "info": "To add a country/region, go to your [market settings.](/admin/settings/markets)"
        },
        "enable_country_selector": {
          "label": "Enable country/region selector"
        },
        "header__5": {
          "content": "Language selector"
        },
        "header__6": {
          "info": "To add a language, go to your [language settings.](/admin/settings/languages)"
        },
        "enable_language_selector": {
          "label": "Enable language selector"
        },
        "margin_bottom": {
          "label": "Bottom margin"
        },
        "mobile_layout": {
          "content": "Mobile layout"
        },
        "mobile_logo_position": {
          "label": "Mobile logo position",
          "options__1": {
            "label": "Center"
          },
          "options__2": {
            "label": "Left"
          }
        }
      }
    },
    "image-banner": {
      "name": "Image banner",
      "settings": {
        "image": {
          "label": "First image"
        },
        "image_2": {
          "label": "Second image"
        },
        "image_overlay_opacity": {
          "label": "Image overlay opacity"
        },
        "image_height": {
          "label": "Banner height",
          "options__1": {
            "label": "Adapt to first image"
          },
          "options__2": {
            "label": "Small"
          },
          "options__3": {
            "label": "Medium"
          },
          "options__4": {
            "label": "Large"
          },
          "info": "For best results, use an image with a 3:2 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
        },
        "desktop_content_position": {
          "options__1": {
            "label": "Top Left"
          },
          "options__2": {
            "label": "Top Center"
          },
          "options__3": {
            "label": "Top Right"
          },
          "options__4": {
            "label": "Middle Left"
          },
          "options__5": {
            "label": "Middle Center"
          },
          "options__6": {
            "label": "Middle Right"
          },
          "options__7": {
            "label": "Bottom Left"
          },
          "options__8": {
            "label": "Bottom Center"
          },
          "options__9": {
            "label": "Bottom Right"
          },
          "label": "Desktop content position"
        },
        "show_text_box": {
          "label": "Show container on desktop"
        },
        "desktop_content_alignment": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Desktop content alignment"
        },
        "mobile": {
          "content": "Mobile Layout"
        },
        "mobile_content_alignment": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Mobile content alignment"
        },
        "stack_images_on_mobile": {
          "label": "Stack images on mobile"
        },
        "show_text_below": {
          "label": "Show container on mobile"
        }
      },
      "blocks": {
        "heading": {
          "name": "Heading",
          "settings": {
            "heading": {
              "label": "Heading"
            }
          }
        },
        "text": {
          "name": "Text",
          "settings": {
            "text": {
              "label": "Description"
            },
            "text_style": {
              "options__1": {
                "label": "Body"
              },
              "options__2": {
                "label": "Subtitle"
              },
              "options__3": {
                "label": "Uppercase"
              },
              "label": "Text style"
            }
          }
        },
        "buttons": {
          "name": "Buttons",
          "settings": {
            "button_label_1": {
              "label": "First button label",
              "info": "Leave the label blank to hide the button."
            },
            "button_link_1": {
              "label": "First button link"
            },
            "button_style_secondary_1": {
              "label": "Use outline button style"
            },
            "button_label_2": {
              "label": "Second button label",
              "info": "Leave the label blank to hide the button."
            },
            "button_link_2": {
              "label": "Second button link"
            },
            "button_style_secondary_2": {
              "label": "Use outline button style"
            }
          }
        }
      },
      "presets": {
        "name": "Image banner"
      }
    },
    "image-with-text": {
      "name": "Image with text",
      "settings": {
        "image": {
          "label": "Image"
        },
        "height": {
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Small"
          },
          "options__3": {
            "label": "Medium"
          },
          "options__4": {
            "label": "Large"
          },
          "label": "Image height"
        },
        "desktop_image_width": {
          "options__1": {
            "label": "Small"
          },
          "options__2": {
            "label": "Medium"
          },
          "options__3": {
            "label": "Large"
          },
          "label": "Desktop image width",
          "info": "Image is automatically optimized for mobile."
        },
        "layout": {
          "options__1": {
            "label": "Image first"
          },
          "options__2": {
            "label": "Image second"
          },
          "label": "Desktop image placement",
          "info": "Image first is the default mobile layout."
        },
        "desktop_content_alignment": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Desktop content alignment"
        },
        "desktop_content_position": {
          "options__1": {
            "label": "Top"
          },
          "options__2": {
            "label": "Middle"
          },
          "options__3": {
            "label": "Bottom"
          },
          "label": "Desktop content position"
        },
        "content_layout": {
          "options__1": {
            "label": "No overlap"
          },
          "options__2": {
            "label": "Overlap"
          },
          "label": "Content layout"
        },
        "mobile_content_alignment": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Mobile content alignment"
        }
      },
      "blocks": {
        "heading": {
          "name": "Heading",
          "settings": {
            "heading": {
              "label": "Heading"
            }
          }
        },
        "caption": {
          "name": "Caption",
          "settings": {
            "text": {
              "label": "Text"
            },
            "text_style": {
              "label": "Text style",
              "options__1": {
                "label": "Subtitle"
              },
              "options__2": {
                "label": "Uppercase"
              }
            },
            "caption_size": {
              "label": "Text size",
              "options__1": {
                "label": "Small"
              },
              "options__2": {
                "label": "Medium"
              },
              "options__3": {
                "label": "Large"
              }
            }
          }
        },
        "text": {
          "name": "Text",
          "settings": {
            "text": {
              "label": "Content"
            },
            "text_style": {
              "label": "Text style",
              "options__1": {
                "label": "Body"
              },
              "options__2": {
                "label": "Subtitle"
              }
            }
          }
        },
        "button": {
          "name": "Button",
          "settings": {
            "button_label": {
              "label": "Button label",
              "info": "Leave the label blank to hide the button."
            },
            "button_link": {
              "label": "Button link"
            }
          }
        }
      },
      "presets": {
        "name": "Image with text"
      }
    },
    "multirow": {
      "name": "Multirow",
      "settings": {
        "image": {
          "label": "Image"
        },
        "image_height": {
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Small"
          },
          "options__3": {
            "label": "Medium"
          },
          "options__4": {
            "label": "Large"
          },
          "label": "Image height"
        },
        "desktop_image_width": {
          "options__1": {
            "label": "Small"
          },
          "options__2": {
            "label": "Medium"
          },
          "options__3": {
            "label": "Large"
          },
          "label": "Desktop image width",
          "info": "Image is automatically optimized for mobile."
        },
        "heading_size": {
          "options__1": {
            "label": "Small"
          },
          "options__2": {
            "label": "Medium"
          },
          "options__3": {
            "label": "Large"
          },
          "label": "Heading size"
        },
        "text_style": {
          "options__1": {
            "label": "Body"
          },
          "options__2": {
            "label": "Subtitle"
          },
          "label": "Text style"
        },
        "button_style": {
          "options__1": {
            "label": "Solid button"
          },
          "options__2": {
            "label": "Outline button"
          },
          "label": "Button style"
        },
        "desktop_content_alignment": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Desktop content alignment"
        },
        "desktop_content_position": {
          "options__1": {
            "label": "Top"
          },
          "options__2": {
            "label": "Middle"
          },
          "options__3": {
            "label": "Bottom"
          },
          "label": "Desktop content position",
          "info": "Position is automatically optimized for mobile."
        },
        "image_layout": {
          "options__1": {
            "label": "Alternate from left"
          },
          "options__2": {
            "label": "Alternate from right"
          },
          "options__3": {
            "label": "Aligned left"
          },
          "options__4": {
            "label": "Aligned right"
          },
          "label": "Desktop image placement",
          "info": "Placement is automatically optimized for mobile."
        },
        "container_color_scheme": {
          "label": "Container color scheme"
        },
        "mobile_content_alignment": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Mobile content alignment"
        },
        "header_mobile": {
          "content": "Mobile Layout"
        }
      },
      "blocks": {
        "row": {
          "name": "Row",
          "settings": {
            "image": {
              "label": "Image"
            },
            "caption": {
              "label": "Caption"
            },
            "heading": {
              "label": "Heading"
            },
            "text": {
              "label": "Text"
            },
            "button_label": {
              "label": "Button label"
            },
            "button_link": {
              "label": "Button link"
            }
          }
        }
      },
      "presets": {
        "name": "Multirow"
      }
    },
    "main-account": {
      "name": "Account"
    },
    "main-activate-account": {
      "name": "Account activation"
    },
    "main-addresses": {
      "name": "Addresses"
    },
    "main-article": {
      "name": "Blog post",
      "blocks": {
        "featured_image": {
          "name": "Featured image",
          "settings": {
            "image_height": {
              "label": "Featured image height",
              "options__1": {
                "label": "Adapt to image"
              },
              "options__2": {
                "label": "Small"
              },
              "options__3": {
                "label": "Medium"
              },
              "options__4": {
                "label": "Large"
              },
              "info": "For best results, use an image with a 16:9 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
            }
          }
        },
        "title": {
          "name": "Title",
          "settings": {
            "blog_show_date": {
              "label": "Show date"
            },
            "blog_show_author": {
              "label": "Show author"
            }
          }
        },
        "content": {
          "name": "Content"
        },
        "share": {
          "name": "Share",
          "settings": {
            "text": {
              "label": "Text"
            },
            "featured_image_info": {
              "content": "If you include a link in social media posts, the page’s featured image will be shown as the preview image. [Learn more](https://help.shopify.com/manual/online-store/images/showing-social-media-thumbnail-images)."
            },
            "title_info": {
              "content": "A store title and description are included with the preview image. [Learn more](https://help.shopify.com/manual/promoting-marketing/seo/adding-keywords#set-a-title-and-description-for-your-online-store)."
            }
          }
        }
      }
    },
    "main-blog": {
      "name": "Blog posts",
      "settings": {
        "header": {
          "content": "Blog post card"
        },
        "show_image": {
          "label": "Show featured image"
        },
        "show_date": {
          "label": "Show date"
        },
        "show_author": {
          "label": "Show author"
        },
        "paragraph": {
          "content": "Change excerpts by editing your blog posts. [Learn more](https://help.shopify.com/manual/online-store/blogs/writing-blogs#display-an-excerpt-from-a-blog-post)"
        },
        "layout": {
          "label": "Desktop layout",
          "options__1": {
            "label": "Grid"
          },
          "options__2": {
            "label": "Collage"
          },
          "info": "Posts are stacked on mobile."
        },
        "image_height": {
          "label": "Featured image height",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Small"
          },
          "options__3": {
            "label": "Medium"
          },
          "options__4": {
            "label": "Large"
          },
          "info": "For best results, use an image with a 3:2 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
        }
      }
    },
    "main-cart-footer": {
      "name": "Subtotal",
      "blocks": {
        "subtotal": {
          "name": "Subtotal price"
        },
        "buttons": {
          "name": "Checkout button"
        }
      }
    },
    "main-cart-items": {
      "name": "Items"
    },
    "main-collection-banner": {
      "name": "Collection banner",
      "settings": {
        "paragraph": {
          "content": "Add a description or image by editing your collection. [Learn more](https://help.shopify.com/manual/products/collections/collection-layout)"
        },
        "show_collection_description": {
          "label": "Show collection description"
        },
        "show_collection_image": {
          "label": "Show collection image",
          "info": "For best results, use an image with a 16:9 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
        }
      }
    },
    "main-collection-product-grid": {
      "name": "Product grid",
      "settings": {
        "products_per_page": {
          "label": "Products per page"
        },
        "columns_desktop": {
          "label": "Number of columns on desktop"
        },
        "enable_filtering": {
          "label": "Enable filtering",
          "info": "Customize filters with the Search & Discovery app. [Learn more](https://help.shopify.com/manual/online-store/search-and-discovery/filters)"
        },
        "filter_type": {
          "label": "Desktop filter layout",
          "options__1": {
            "label": "Horizontal"
          },
          "options__2": {
            "label": "Vertical"
          },
          "options__3": {
            "label": "Drawer"
          },
          "info": "Drawer is the default mobile layout."
        },
        "enable_sorting": {
          "label": "Enable sorting"
        },
        "image_ratio": {
          "label": "Image ratio",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Portrait"
          },
          "options__3": {
            "label": "Square"
          }
        },
        "show_secondary_image": {
          "label": "Show second image on hover"
        },
        "show_vendor": {
          "label": "Show vendor"
        },
        "show_rating": {
          "label": "Show product rating",
          "info": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/page-types#product-grid-section-settings)"
        },
        "header__1": {
          "content": "Filtering and sorting"
        },
        "header__3": {
          "content": "Product card"
        },
        "enable_tags": {
          "label": "Enable filtering",
          "info": "Customize filters with the Search & Discovery app. [Learn more](https://help.shopify.com/manual/online-store/search-and-discovery/filters)"
        },
        "enable_quick_buy": {
          "label": "Enable quick add button",
          "info": "Optimal with popup or drawer cart type."
        },
        "header_mobile": {
          "content": "Mobile Layout"
        },
        "columns_mobile": {
          "label": "Number of columns on mobile",
          "options__1": {
            "label": "1 column"
          },
          "options__2": {
            "label": "2 columns"
          }
        }
      }
    },
    "main-list-collections": {
      "name": "Collections list page",
      "settings": {
        "title": {
          "label": "Heading"
        },
        "sort": {
          "label": "Sort collections by:",
          "options__1": {
            "label": "Alphabetically, A-Z"
          },
          "options__2": {
            "label": "Alphabetically, Z-A"
          },
          "options__3": {
            "label": "Date, new to old"
          },
          "options__4": {
            "label": "Date, old to new"
          },
          "options__5": {
            "label": "Product count, high to low"
          },
          "options__6": {
            "label": "Product count, low to high"
          }
        },
        "image_ratio": {
          "label": "Image ratio",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Portrait"
          },
          "options__3": {
            "label": "Square"
          },
          "info": "Add images by editing your collections. [Learn more](https://help.shopify.com/manual/products/collections)"
        },
        "columns_desktop": {
          "label": "Number of columns on desktop"
        },
        "header_mobile": {
          "content": "Mobile Layout"
        },
        "columns_mobile": {
          "label": "Number of columns on mobile",
          "options__1": {
            "label": "1 column"
          },
          "options__2": {
            "label": "2 columns"
          }
        }
      }
    },
    "main-login": {
      "name": "Login"
    },
    "main-order": {
      "name": "Order"
    },
    "main-page": {
      "name": "Page"
    },
    "main-password-footer": {
      "name": "Password footer"
    },
    "main-password-header": {
      "name": "Password header",
      "settings": {
        "logo_header": {
          "content": "Logo"
        },
        "logo_help": {
          "content": "Edit your logo in theme settings."
        }
      }
    },
    "main-product": {
      "name": "Product information",
      "blocks": {
        "text": {
          "name": "Text",
          "settings": {
            "text": {
              "label": "Text"
            },
            "text_style": {
              "label": "Text style",
              "options__1": {
                "label": "Body"
              },
              "options__2": {
                "label": "Subtitle"
              },
              "options__3": {
                "label": "Uppercase"
              }
            }
          }
        },
        "title": {
          "name": "Title"
        },
        "price": {
          "name": "Price"
        },
        "inventory": {
          "name": "Inventory status",
          "settings": {
            "text_style": {
              "label": "Text style",
              "options__1": {
                "label": "Body"
              },
              "options__2": {
                "label": "Subtitle"
              },
              "options__3": {
                "label": "Uppercase"
              }
            },
            "inventory_threshold": {
              "label": "Low inventory threshold",
              "info": "Choose 0 to always show in stock if available."
            },
            "show_inventory_quantity": {
              "label": "Show inventory count"
            }
          }
        },
        "quantity_selector": {
          "name": "Quantity selector"
        },
        "variant_picker": {
          "name": "Variant picker",
          "settings": {
            "picker_type": {
              "label": "Type",
              "options__1": {
                "label": "Dropdown"
              },
              "options__2": {
                "label": "Pills"
              }
            }
          }
        },
        "buy_buttons": {
          "name": "Buy buttons",
          "settings": {
            "show_dynamic_checkout": {
              "label": "Show dynamic checkout buttons",
              "info": "Using the payment methods available on your store, customers see their preferred option, like PayPal or Apple Pay. [Learn more](https://help.shopify.com/manual/using-themes/change-the-layout/dynamic-checkout)"
            },
            "show_gift_card_recipient": {
              "label": "Show recipient information form for gift card products",
              "info": "Gift card products can optionally be sent direct to a recipient on a scheduled date along with a personal message. [Learn more](https://help.shopify.com/manual/online-store/themes/customizing-themes/add-gift-card-recipient-fields)"
            }
          }
        },
        "pickup_availability": {
          "name": "Pickup availability"
        },
        "description": {
          "name": "Description"
        },
        "sku": {
          "name": "SKU",
          "settings": {
            "text_style": {
              "label": "Text style",
              "options__1": {
                "label": "Body"
              },
              "options__2": {
                "label": "Subtitle"
              },
              "options__3": {
                "label": "Uppercase"
              }
            }
          }
        },
        "share": {
          "name": "Share",
          "settings": {
            "text": {
              "label": "Text"
            },
            "featured_image_info": {
              "content": "If you include a link in social media posts, the page’s featured image will be shown as the preview image. [Learn more](https://help.shopify.com/manual/online-store/images/showing-social-media-thumbnail-images)."
            },
            "title_info": {
              "content": "A store title and description are included with the preview image. [Learn more](https://help.shopify.com/manual/promoting-marketing/seo/adding-keywords#set-a-title-and-description-for-your-online-store)."
            }
          }
        },
        "custom_liquid": {
          "name": "Custom liquid",
          "settings": {
            "custom_liquid": {
              "label": "Custom liquid",
              "info": "Add app snippets or other Liquid code to create advanced customizations."
            }
          }
        },
        "collapsible_tab": {
          "name": "Collapsible row",
          "settings": {
            "heading": {
              "info": "Include a heading that explains the content.",
              "label": "Heading"
            },
            "content": {
              "label": "Row content"
            },
            "page": {
              "label": "Row content from page"
            },
            "icon": {
              "label": "Icon",
              "options__1": {
                "label": "None"
              },
              "options__2": {
                "label": "Apple"
              },
              "options__3": {
                "label": "Banana"
              },
              "options__4": {
                "label": "Bottle"
              },
              "options__5": {
                "label": "Box"
              },
              "options__6": {
                "label": "Carrot"
              },
              "options__7": {
                "label": "Chat bubble"
              },
              "options__8": {
                "label": "Check mark"
              },
              "options__9": {
                "label": "Clipboard"
              },
              "options__10": {
                "label": "Dairy"
              },
              "options__11": {
                "label": "Dairy free"
              },
              "options__12": {
                "label": "Dryer"
              },
              "options__13": {
                "label": "Eye"
              },
              "options__14": {
                "label": "Fire"
              },
              "options__15": {
                "label": "Gluten free"
              },
              "options__16": {
                "label": "Heart"
              },
              "options__17": {
                "label": "Iron"
              },
              "options__18": {
                "label": "Leaf"
              },
              "options__19": {
                "label": "Leather"
              },
              "options__20": {
                "label": "Lightning bolt"
              },
              "options__21": {
                "label": "Lipstick"
              },
              "options__22": {
                "label": "Lock"
              },
              "options__23": {
                "label": "Map pin"
              },
              "options__24": {
                "label": "Nut free"
              },
              "options__25": {
                "label": "Pants"
              },
              "options__26": {
                "label": "Paw print"
              },
              "options__27": {
                "label": "Pepper"
              },
              "options__28": {
                "label": "Perfume"
              },
              "options__29": {
                "label": "Plane"
              },
              "options__30": {
                "label": "Plant"
              },
              "options__31": {
                "label": "Price tag"
              },
              "options__32": {
                "label": "Question mark"
              },
              "options__33": {
                "label": "Recycle"
              },
              "options__34": {
                "label": "Return"
              },
              "options__35": {
                "label": "Ruler"
              },
              "options__36": {
                "label": "Serving dish"
              },
              "options__37": {
                "label": "Shirt"
              },
              "options__38": {
                "label": "Shoe"
              },
              "options__39": {
                "label": "Silhouette"
              },
              "options__40": {
                "label": "Snowflake"
              },
              "options__41": {
                "label": "Star"
              },
              "options__42": {
                "label": "Stopwatch"
              },
              "options__43": {
                "label": "Truck"
              },
              "options__44": {
                "label": "Washing"
              }
            }
          }
        },
        "popup": {
          "name": "Pop-up",
          "settings": {
            "link_label": {
              "label": "Link label"
            },
            "page": {
              "label": "Page"
            }
          }
        },
        "rating": {
          "name": "Product rating",
          "settings": {
            "paragraph": {
              "content": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/page-types#product-rating-block)"
            }
          }
        },
        "complementary_products": {
          "name": "Complementary products",
          "settings": {
            "paragraph": {
              "content": "To select complementary products, add the Search & Discovery app. [Learn more](https://help.shopify.com/manual/online-store/search-and-discovery/product-recommendations)"
            },
            "heading": {
              "label": "Heading"
            },
            "make_collapsible_row": {
              "label": "Show as collapsible row"
            },
            "icon": {
              "info": "Visible when collapsible row is displayed."
            },
            "product_list_limit": {
              "label": "Maximum products to show"
            },
            "products_per_page": {
              "label": "Number of products per page"
            },
            "pagination_style": {
              "label": "Pagination style",
              "options": {
                "option_1": "Dots",
                "option_2": "Counter",
                "option_3": "Numbers"
              }
            },
            "product_card": {
              "heading": "Product card"
            },
            "image_ratio": {
              "label": "Image ratio",
              "options": {
                "option_1": "Portrait",
                "option_2": "Square"
              }
            },
            "enable_quick_add": {
              "label": "Enable quick add button"
            }
          }
        },
        "icon_with_text": {
          "name": "Icon with text",
          "settings": {
            "layout": {
              "label": "Layout",
              "options__1": {
                "label": "Horizontal"
              },
              "options__2": {
                "label": "Vertical"
              }
            },
            "content": {
              "label": "Content",
              "info": "Choose an icon or add an image for each column or row."
            },
            "heading": {
              "info": "Leave the heading label blank to hide the icon column."
            },
            "icon_1": {
              "label": "First icon"
            },
            "image_1": {
              "label": "First image"
            },
            "heading_1": {
              "label": "First heading"
            },
            "icon_2": {
              "label": "Second icon"
            },
            "image_2": {
              "label": "Second image"
            },
            "heading_2": {
              "label": "Second heading"
            },
            "icon_3": {
              "label": "Third icon"
            },
            "image_3": {
              "label": "Third image"
            },
            "heading_3": {
              "label": "Third heading"
            }
          }
        }
      },
      "settings": {
        "header": {
          "content": "Media",
          "info": "Learn more about [media types.](https://help.shopify.com/manual/products/product-media)"
        },
        "enable_sticky_info": {
          "label": "Enable sticky content on desktop"
        },
        "gallery_layout": {
          "label": "Desktop layout",
          "options__1": {
            "label": "Stacked"
          },
          "options__2": {
            "label": "2 columns"
          },
          "options__3": {
            "label": "Thumbnails"
          },
          "options__4": {
            "label": "Thumbnail carousel"
          }
        },
        "constrain_to_viewport": {
          "label": "Constrain media to screen height"
        },
        "media_size": {
          "label": "Desktop media width",
          "info": "Media is automatically optimized for mobile.",
          "options__1": {
            "label": "Small"
          },
          "options__2": {
            "label": "Medium"
          },
          "options__3": {
            "label": "Large"
          }
        },
        "image_zoom": {
          "label": "Image zoom",
          "info": "Click and hover defaults to open lightbox on mobile.",
          "options__1": {
            "label": "Open lightbox"
          },
          "options__2": {
            "label": "Click and hover"
          },
          "options__3": {
            "label": "No zoom"
          }
        },
        "media_position": {
          "label": "Desktop media position",
          "info": "Position is automatically optimized for mobile.",
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Right"
          }
        },
        "media_fit": {
          "label": "Media fit",
          "options__1": {
            "label": "Original"
          },
          "options__2": {
            "label": "Fill"
          }
        },
        "mobile_thumbnails": {
          "label": "Mobile layout",
          "options__1": {
            "label": "2 columns"
          },
          "options__2": {
            "label": "Show thumbnails"
          },
          "options__3": {
            "label": "Hide thumbnails"
          }
        },
        "hide_variants": {
          "label": "Hide other variants’ media after selecting a variant"
        },
        "enable_video_looping": {
          "label": "Enable video looping"
        }
      }
    },
    "main-register": {
      "name": "Registration"
    },
    "main-reset-password": {
      "name": "Password reset"
    },
    "main-search": {
      "name": "Search results",
      "settings": {
        "columns_desktop": {
          "label": "Number of columns on desktop"
        },
        "image_ratio": {
          "label": "Image ratio",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Portrait"
          },
          "options__3": {
            "label": "Square"
          }
        },
        "show_secondary_image": {
          "label": "Show second image on hover"
        },
        "show_vendor": {
          "label": "Show vendor"
        },
        "show_rating": {
          "label": "Show product rating",
          "info": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/page-types#search-results-section-settings)"
        },
        "header__1": {
          "content": "Product card"
        },
        "header__2": {
          "content": "Blog card",
          "info": "Blog card styles also apply to page cards in search results. To change card styles update your theme settings."
        },
        "article_show_date": {
          "label": "Show date"
        },
        "article_show_author": {
          "label": "Show author"
        },
        "header_mobile": {
          "content": "Mobile Layout"
        },
        "columns_mobile": {
          "label": "Number of columns on mobile",
          "options__1": {
            "label": "1 column"
          },
          "options__2": {
            "label": "2 columns"
          }
        }
      }
    },
    "multicolumn": {
      "name": "Multicolumn",
      "settings": {
        "title": {
          "label": "Heading"
        },
        "image_width": {
          "label": "Image width",
          "options__1": {
            "label": "One-third width of column"
          },
          "options__2": {
            "label": "Half width of column"
          },
          "options__3": {
            "label": "Full width of column"
          }
        },
        "image_ratio": {
          "label": "Image ratio",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Portrait"
          },
          "options__3": {
            "label": "Square"
          },
          "options__4": {
            "label": "Circle"
          }
        },
        "columns_desktop": {
          "label": "Number of columns on desktop"
        },
        "column_alignment": {
          "label": "Column alignment",
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          }
        },
        "background_style": {
          "label": "Secondary background",
          "options__1": {
            "label": "None"
          },
          "options__2": {
            "label": "Show as column background"
          }
        },
        "button_label": {
          "label": "Button label"
        },
        "button_link": {
          "label": "Button link"
        },
        "header_mobile": {
          "content": "Mobile Layout"
        },
        "columns_mobile": {
          "label": "Number of columns on mobile",
          "options__1": {
            "label": "1 column"
          },
          "options__2": {
            "label": "2 columns"
          }
        },
        "swipe_on_mobile": {
          "label": "Enable swipe on mobile"
        }
      },
      "blocks": {
        "column": {
          "name": "Column",
          "settings": {
            "image": {
              "label": "Image"
            },
            "title": {
              "label": "Heading"
            },
            "text": {
              "label": "Description"
            },
            "link_label": {
              "label": "Link label"
            },
            "link": {
              "label": "Link"
            }
          }
        }
      },
      "presets": {
        "name": "Multicolumn"
      }
    },
    "newsletter": {
      "name": "Email signup",
      "settings": {
        "full_width": {
          "label": "Make section full width"
        },
        "paragraph": {
          "content": "Each email subscription creates a customer account. [Learn more](https://help.shopify.com/manual/customers)"
        }
      },
      "blocks": {
        "heading": {
          "name": "Heading",
          "settings": {
            "heading": {
              "label": "Heading"
            }
          }
        },
        "paragraph": {
          "name": "Subheading",
          "settings": {
            "paragraph": {
              "label": "Description"
            }
          }
        },
        "email_form": {
          "name": "Email form"
        }
      },
      "presets": {
        "name": "Email signup"
      }
    },
    "email-signup-banner": {
      "name": "Email signup banner",
      "settings": {
        "paragraph": {
          "content": "Each email subscription creates a customer account. [Learn more](https://help.shopify.com/manual/customers)"
        },
        "image": {
          "label": "Background image"
        },
        "image_overlay_opacity": {
          "label": "Image overlay opacity"
        },
        "show_background_image": {
          "label": "Show background image"
        },
        "show_text_box": {
          "label": "Show container on desktop"
        },
        "desktop_content_position": {
          "options__1": {
            "label": "Top Left"
          },
          "options__2": {
            "label": "Top Center"
          },
          "options__3": {
            "label": "Top Right"
          },
          "options__4": {
            "label": "Middle Left"
          },
          "options__5": {
            "label": "Middle Center"
          },
          "options__6": {
            "label": "Middle Right"
          },
          "options__7": {
            "label": "Bottom Left"
          },
          "options__8": {
            "label": "Bottom Center"
          },
          "options__9": {
            "label": "Bottom Right"
          },
          "label": "Desktop content position"
        },
        "color_scheme": {
          "info": "Visible when container displayed."
        },
        "image_height": {
          "label": "Banner height",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Small"
          },
          "options__3": {
            "label": "Medium"
          },
          "options__4": {
            "label": "Large"
          },
          "info": "For best results, use an image with a 16:9 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
        },
        "desktop_content_alignment": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Desktop content alignment"
        },
        "header": {
          "content": "Mobile Layout"
        },
        "mobile_content_alignment": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Mobile content alignment"
        },
        "show_text_below": {
          "label": "Show content below image on mobile",
          "info": "For best results, use an image with a 16:9 aspect ratio. [Learn more](https://help.shopify.com/manual/shopify-admin/productivity-tools/image-editor#understanding-image-aspect-ratio)"
        }
      },
      "blocks": {
        "heading": {
          "name": "Heading",
          "settings": {
            "heading": {
              "label": "Heading"
            }
          }
        },
        "paragraph": {
          "name": "Paragraph",
          "settings": {
            "paragraph": {
              "label": "Description"
            },
            "text_style": {
              "options__1": {
                "label": "Body"
              },
              "options__2": {
                "label": "Subtitle"
              },
              "label": "Text style"
            }
          }
        },
        "email_form": {
          "name": "Email form"
        }
      },
      "presets": {
        "name": "Email signup banner"
      }
    },
    "page": {
      "name": "Page",
      "settings": {
        "page": {
          "label": "Page"
        }
      },
      "presets": {
        "name": "Page"
      }
    },
    "related-products": {
      "name": "Related products",
      "settings": {
        "heading": {
          "label": "Heading"
        },
        "products_to_show": {
          "label": "Maximum products to show"
        },
        "columns_desktop": {
          "label": "Number of columns on desktop"
        },
        "paragraph__1": {
          "content": "Dynamic recommendations use order and product information to change and improve over time. [Learn more](https://help.shopify.com/themes/development/recommended-products)"
        },
        "header__2": {
          "content": "Product card"
        },
        "image_ratio": {
          "label": "Image ratio",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Portrait"
          },
          "options__3": {
            "label": "Square"
          }
        },
        "show_secondary_image": {
          "label": "Show second image on hover"
        },
        "show_vendor": {
          "label": "Show vendor"
        },
        "show_rating": {
          "label": "Show product rating",
          "info": "To display a rating, add a product rating app. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/page-types#product-recommendations-section-settings)"
        },
        "header_mobile": {
          "content": "Mobile Layout"
        },
        "columns_mobile": {
          "label": "Number of columns on mobile",
          "options__1": {
            "label": "1 column"
          },
          "options__2": {
            "label": "2 columns"
          }
        }
      }
    },
    "rich-text": {
      "name": "Rich text",
      "settings": {
        "desktop_content_position": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Desktop content position",
          "info": "Position is automatically optimized for mobile."
        },
        "content_alignment": {
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          },
          "label": "Content alignment"
        },
        "full_width": {
          "label": "Make section full width"
        }
      },
      "blocks": {
        "heading": {
          "name": "Heading",
          "settings": {
            "heading": {
              "label": "Heading"
            }
          }
        },
        "caption": {
          "name": "Caption",
          "settings": {
            "text": {
              "label": "Text"
            },
            "text_style": {
              "label": "Text style",
              "options__1": {
                "label": "Subtitle"
              },
              "options__2": {
                "label": "Uppercase"
              }
            },
            "caption_size": {
              "label": "Text size",
              "options__1": {
                "label": "Small"
              },
              "options__2": {
                "label": "Medium"
              },
              "options__3": {
                "label": "Large"
              }
            }
          }
        },
        "text": {
          "name": "Text",
          "settings": {
            "text": {
              "label": "Description"
            }
          }
        },
        "buttons": {
          "name": "Buttons",
          "settings": {
            "button_label_1": {
              "label": "First button label",
              "info": "Leave the label blank to hide the button."
            },
            "button_link_1": {
              "label": "First button link"
            },
            "button_style_secondary_1": {
              "label": "Use outline button style"
            },
            "button_label_2": {
              "label": "Second button label",
              "info": "Leave the label blank to hide the button."
            },
            "button_link_2": {
              "label": "Second button link"
            },
            "button_style_secondary_2": {
              "label": "Use outline button style"
            }
          }
        }
      },
      "presets": {
        "name": "Rich text"
      }
    },
    "video": {
      "name": "Video",
      "settings": {
        "heading": {
          "label": "Heading"
        },
        "cover_image": {
          "label": "Cover image"
        },
        "video": {
          "label": "Video"
        },
        "enable_video_looping": {
          "label": "Play video on loop"
        },
        "header__1": {
          "content": "Shopify-hosted video"
        },
        "header__2": {
          "content": "Or embed video from URL"
        },
        "header__3": {
          "content": "Style"
        },
        "paragraph": {
          "content": "Shows when no Shopify-hosted video is selected."
        },
        "video_url": {
          "label": "URL",
          "info": "Use a YouTube or Vimeo URL"
        },
        "description": {
          "label": "Video alt text",
          "info": "Describe the video for customers using screen readers. [Learn more](https://help.shopify.com/manual/online-store/themes/theme-structure/theme-features#video)"
        },
        "image_padding": {
          "label": "Add image padding",
          "info": "Select image padding if you don't want your cover image to be cropped."
        },
        "full_width": {
          "label": "Make section full width"
        }
      },
      "presets": {
        "name": "Video"
      }
    },
    "slideshow": {
      "name": "Slideshow",
      "settings": {
        "layout": {
          "label": "Layout",
          "options__1": {
            "label": "Full width"
          },
          "options__2": {
            "label": "Grid"
          }
        },
        "slide_height": {
          "label": "Slide height",
          "options__1": {
            "label": "Adapt to first image"
          },
          "options__2": {
            "label": "Small"
          },
          "options__3": {
            "label": "Medium"
          },
          "options__4": {
            "label": "Large"
          }
        },
        "slider_visual": {
          "label": "Pagination style",
          "options__1": {
            "label": "Counter"
          },
          "options__2": {
            "label": "Dots"
          },
          "options__3": {
            "label": "Numbers"
          }
        },
        "auto_rotate": {
          "label": "Auto-rotate slides"
        },
        "change_slides_speed": {
          "label": "Change slides every"
        },
        "mobile": {
          "content": "Mobile layout"
        },
        "show_text_below": {
          "label": "Show content below images on mobile"
        },
        "accessibility": {
          "content": "Accessibility",
          "label": "Slideshow description",
          "info": "Describe the slideshow for customers using screen readers."
        }
      },
      "blocks": {
        "slide": {
          "name": "Slide",
          "settings": {
            "image": {
              "label": "Image"
            },
            "heading": {
              "label": "Heading"
            },
            "subheading": {
              "label": "Subheading"
            },
            "button_label": {
              "label": "Button label",
              "info": "Leave the label blank to hide the button."
            },
            "link": {
              "label": "Button link"
            },
            "secondary_style": {
              "label": "Use outline button style"
            },
            "box_align": {
              "label": "Desktop content position",
              "info": "Position is automatically optimized for mobile.",
              "options__1": {
                "label": "Top left"
              },
              "options__2": {
                "label": "Top center"
              },
              "options__3": {
                "label": "Top right"
              },
              "options__4": {
                "label": "Middle left"
              },
              "options__5": {
                "label": "Middle center"
              },
              "options__6": {
                "label": "Middle right"
              },
              "options__7": {
                "label": "Bottom left"
              },
              "options__8": {
                "label": "Bottom center"
              },
              "options__9": {
                "label": "Bottom right"
              }
            },
            "show_text_box": {
              "label": "Show container on desktop"
            },
            "text_alignment": {
              "label": "Desktop content alignment",
              "option_1": {
                "label": "Left"
              },
              "option_2": {
                "label": "Center"
              },
              "option_3": {
                "label": "Right"
              }
            },
            "image_overlay_opacity": {
              "label": "Image overlay opacity"
            },
            "text_alignment_mobile": {
              "label": "Mobile content alignment",
              "options__1": {
                "label": "Left"
              },
              "options__2": {
                "label": "Center"
              },
              "options__3": {
                "label": "Right"
              }
            }
          }
        }
      },
      "presets": {
        "name": "Slideshow"
      }
    },
    "collapsible_content": {
      "name": "Collapsible content",
      "settings": {
        "caption": {
          "label": "Caption"
        },
        "heading": {
          "label": "Heading"
        },
        "heading_alignment": {
          "label": "Heading alignment",
          "options__1": {
            "label": "Left"
          },
          "options__2": {
            "label": "Center"
          },
          "options__3": {
            "label": "Right"
          }
        },
        "layout": {
          "label": "Layout",
          "options__1": {
            "label": "No container"
          },
          "options__2": {
            "label": "Row container"
          },
          "options__3": {
            "label": "Section container"
          }
        },
        "container_color_scheme": {
          "label": "Container color scheme",
          "info": "Visible when Layout is set to Row or Section container."
        },
        "open_first_collapsible_row": {
          "label": "Open first collapsible row"
        },
        "header": {
          "content": "Image layout"
        },
        "image": {
          "label": "Image"
        },
        "image_ratio": {
          "label": "Image ratio",
          "options__1": {
            "label": "Adapt to image"
          },
          "options__2": {
            "label": "Small"
          },
          "options__3": {
            "label": "Large"
          }
        },
        "desktop_layout": {
          "label": "Desktop layout",
          "options__1": {
            "label": "Image first"
          },
          "options__2": {
            "label": "Image second"
          },
          "info": "Image is always first on mobile."
        }
      },
      "blocks": {
        "collapsible_row": {
          "name": "Collapsible row",
          "settings": {
            "heading": {
              "info": "Include a heading that explains the content.",
              "label": "Heading"
            },
            "row_content": {
              "label": "Row content"
            },
            "page": {
              "label": "Row content from page"
            },
            "icon": {
              "label": "Icon",
              "options__1": {
                "label": "None"
              },
              "options__2": {
                "label": "Apple"
              },
              "options__3": {
                "label": "Banana"
              },
              "options__4": {
                "label": "Bottle"
              },
              "options__5": {
                "label": "Box"
              },
              "options__6": {
                "label": "Carrot"
              },
              "options__7": {
                "label": "Chat bubble"
              },
              "options__8": {
                "label": "Check mark"
              },
              "options__9": {
                "label": "Clipboard"
              },
              "options__10": {
                "label": "Dairy"
              },
              "options__11": {
                "label": "Dairy free"
              },
              "options__12": {
                "label": "Dryer"
              },
              "options__13": {
                "label": "Eye"
              },
              "options__14": {
                "label": "Fire"
              },
              "options__15": {
                "label": "Gluten free"
              },
              "options__16": {
                "label": "Heart"
              },
              "options__17": {
                "label": "Iron"
              },
              "options__18": {
                "label": "Leaf"
              },
              "options__19": {
                "label": "Leather"
              },
              "options__20": {
                "label": "Lightning bolt"
              },
              "options__21": {
                "label": "Lipstick"
              },
              "options__22": {
                "label": "Lock"
              },
              "options__23": {
                "label": "Map pin"
              },
              "options__24": {
                "label": "Nut free"
              },
              "options__25": {
                "label": "Pants"
              },
              "options__26": {
                "label": "Paw print"
              },
              "options__27": {
                "label": "Pepper"
              },
              "options__28": {
                "label": "Perfume"
              },
              "options__29": {
                "label": "Plane"
              },
              "options__30": {
                "label": "Plant"
              },
              "options__31": {
                "label": "Price tag"
              },
              "options__32": {
                "label": "Question mark"
              },
              "options__33": {
                "label": "Recycle"
              },
              "options__34": {
                "label": "Return"
              },
              "options__35": {
                "label": "Ruler"
              },
              "options__36": {
                "label": "Serving dish"
              },
              "options__37": {
                "label": "Shirt"
              },
              "options__38": {
                "label": "Shoe"
              },
              "options__39": {
                "label": "Silhouette"
              },
              "options__40": {
                "label": "Snowflake"
              },
              "options__41": {
                "label": "Star"
              },
              "options__42": {
                "label": "Stopwatch"
              },
              "options__43": {
                "label": "Truck"
              },
              "options__44": {
                "label": "Washing"
              }
            }
          }
        }
      },
      "presets": {
        "name": "Collapsible content"
      }
    }
  }
}

public static readonly schemasV10 =  {
  "image-banner":{
    "name": "t:sections.image-banner.name",
    "tag": "section",
    "class": "section",
    "disabled_on": {
      "groups": ["header", "footer"]
    },
    "settings": [
      {
        "type": "image_picker",
        "id": "image",
        "label": "t:sections.image-banner.settings.image.label"
      },
      {
        "type": "image_picker",
        "id": "image_2",
        "label": "t:sections.image-banner.settings.image_2.label"
      },
      {
        "type": "range",
        "id": "image_overlay_opacity",
        "min": 0,
        "max": 100,
        "step": 10,
        "unit": "%",
        "label": "t:sections.image-banner.settings.image_overlay_opacity.label",
        "default": 0
      },
      {
        "type": "select",
        "id": "image_height",
        "options": [
          {
            "value": "adapt",
            "label": "t:sections.image-banner.settings.image_height.options__1.label"
          },
          {
            "value": "small",
            "label": "t:sections.image-banner.settings.image_height.options__2.label"
          },
          {
            "value": "medium",
            "label": "t:sections.image-banner.settings.image_height.options__3.label"
          },
          {
            "value": "large",
            "label": "t:sections.image-banner.settings.image_height.options__4.label"
          }
        ],
        "default": "medium",
        "label": "t:sections.image-banner.settings.image_height.label",
        "info": "t:sections.image-banner.settings.image_height.info"
      },
      {
        "type": "select",
        "id": "desktop_content_position",
        "options": [
          {
            "value": "top-left",
            "label": "t:sections.image-banner.settings.desktop_content_position.options__1.label"
          },
          {
            "value": "top-center",
            "label": "t:sections.image-banner.settings.desktop_content_position.options__2.label"
          },
          {
            "value": "top-right",
            "label": "t:sections.image-banner.settings.desktop_content_position.options__3.label"
          },
          {
            "value": "middle-left",
            "label": "t:sections.image-banner.settings.desktop_content_position.options__4.label"
          },
          {
            "value": "middle-center",
            "label": "t:sections.image-banner.settings.desktop_content_position.options__5.label"
          },
          {
            "value": "middle-right",
            "label": "t:sections.image-banner.settings.desktop_content_position.options__6.label"
          },
          {
            "value": "bottom-left",
            "label": "t:sections.image-banner.settings.desktop_content_position.options__7.label"
          },
          {
            "value": "bottom-center",
            "label": "t:sections.image-banner.settings.desktop_content_position.options__8.label"
          },
          {
            "value": "bottom-right",
            "label": "t:sections.image-banner.settings.desktop_content_position.options__9.label"
          }
        ],
        "default": "middle-center",
        "label": "t:sections.image-banner.settings.desktop_content_position.label"
      },
      {
        "type": "checkbox",
        "id": "show_text_box",
        "default": true,
        "label": "t:sections.image-banner.settings.show_text_box.label"
      },
      {
        "type": "select",
        "id": "desktop_content_alignment",
        "options": [
          {
            "value": "left",
            "label": "t:sections.image-banner.settings.desktop_content_alignment.options__1.label"
          },
          {
            "value": "center",
            "label": "t:sections.image-banner.settings.desktop_content_alignment.options__2.label"
          },
          {
            "value": "right",
            "label": "t:sections.image-banner.settings.desktop_content_alignment.options__3.label"
          }
        ],
        "default": "center",
        "label": "t:sections.image-banner.settings.desktop_content_alignment.label"
      },
      {
        "type": "color_scheme",
        "id": "color_scheme",
        "label": "t:sections.all.colors.label",
        "default": "background-1"
      },
      {
        "type": "header",
        "content": "t:sections.all.animation.content"
      },
      {
        "type": "select",
        "id": "image_behavior",
        "options": [
          {
            "value": "none",
            "label": "t:sections.all.animation.image_behavior.options__1.label"
          },
          {
            "value": "ambient",
            "label": "t:sections.all.animation.image_behavior.options__2.label"
          },
          {
            "value": "fixed",
            "label": "t:sections.all.animation.image_behavior.options__3.label"
          }
        ],
        "default": "none",
        "label": "t:sections.all.animation.image_behavior.label"
      },
      {
        "type": "header",
        "content": "t:sections.image-banner.settings.mobile.content"
      },
      {
        "type": "select",
        "id": "mobile_content_alignment",
        "options": [
          {
            "value": "left",
            "label": "t:sections.image-banner.settings.mobile_content_alignment.options__1.label"
          },
          {
            "value": "center",
            "label": "t:sections.image-banner.settings.mobile_content_alignment.options__2.label"
          },
          {
            "value": "right",
            "label": "t:sections.image-banner.settings.mobile_content_alignment.options__3.label"
          }
        ],
        "default": "center",
        "label": "t:sections.image-banner.settings.mobile_content_alignment.label"
      },
      {
        "type": "checkbox",
        "id": "stack_images_on_mobile",
        "default": true,
        "label": "t:sections.image-banner.settings.stack_images_on_mobile.label"
      },
      {
        "type": "checkbox",
        "id": "show_text_below",
        "default": true,
        "label": "t:sections.image-banner.settings.show_text_below.label"
      }
    ],
    "blocks": [
      {
        "type": "heading",
        "name": "t:sections.image-banner.blocks.heading.name",
        "limit": 1,
        "settings": [
          {
            "type": "inline_richtext",
            "id": "heading",
            "default": "Image banner",
            "label": "t:sections.image-banner.blocks.heading.settings.heading.label"
          },
          {
            "type": "select",
            "id": "heading_size",
            "options": [
              {
                "value": "h2",
                "label": "t:sections.all.heading_size.options__1.label"
              },
              {
                "value": "h1",
                "label": "t:sections.all.heading_size.options__2.label"
              },
              {
                "value": "h0",
                "label": "t:sections.all.heading_size.options__3.label"
              }
            ],
            "default": "h1",
            "label": "t:sections.all.heading_size.label"
          }
        ]
      },
      {
        "type": "text",
        "name": "t:sections.image-banner.blocks.text.name",
        "limit": 1,
        "settings": [
          {
            "type": "inline_richtext",
            "id": "text",
            "default": "Give customers details about the banner image(s) or content on the template.",
            "label": "t:sections.image-banner.blocks.text.settings.text.label"
          },
          {
            "type": "select",
            "id": "text_style",
            "options": [
              {
                "value": "body",
                "label": "t:sections.image-banner.blocks.text.settings.text_style.options__1.label"
              },
              {
                "value": "subtitle",
                "label": "t:sections.image-banner.blocks.text.settings.text_style.options__2.label"
              },
              {
                "value": "caption-with-letter-spacing",
                "label": "t:sections.image-banner.blocks.text.settings.text_style.options__3.label"
              }
            ],
            "default": "body",
            "label": "t:sections.image-banner.blocks.text.settings.text_style.label"
          }
        ]
      },
      {
        "type": "buttons",
        "name": "t:sections.image-banner.blocks.buttons.name",
        "limit": 1,
        "settings": [
          {
            "type": "text",
            "id": "button_label_1",
            "default": "Button label",
            "label": "t:sections.image-banner.blocks.buttons.settings.button_label_1.label",
            "info": "t:sections.image-banner.blocks.buttons.settings.button_label_1.info"
          },
          {
            "type": "url",
            "id": "button_link_1",
            "label": "t:sections.image-banner.blocks.buttons.settings.button_link_1.label"
          },
          {
            "type": "checkbox",
            "id": "button_style_secondary_1",
            "default": false,
            "label": "t:sections.image-banner.blocks.buttons.settings.button_style_secondary_1.label"
          },
          {
            "type": "text",
            "id": "button_label_2",
            "default": "Button label",
            "label": "t:sections.image-banner.blocks.buttons.settings.button_label_2.label",
            "info": "t:sections.image-banner.blocks.buttons.settings.button_label_2.info"
          },
          {
            "type": "url",
            "id": "button_link_2",
            "label": "t:sections.image-banner.blocks.buttons.settings.button_link_2.label"
          },
          {
            "type": "checkbox",
            "id": "button_style_secondary_2",
            "default": false,
            "label": "t:sections.image-banner.blocks.buttons.settings.button_style_secondary_2.label"
          }
        ]
      }
    ],
    "presets": [
      {
        "name": "t:sections.image-banner.presets.name",
        "blocks": [
          {
            "type": "heading"
          },
          {
            "type": "text"
          },
          {
            "type": "buttons"
          }
        ]
      }
    ]
  },
  "rich-text":{
    "name": "t:sections.rich-text.name",
    "tag": "section",
    "class": "section",
    "disabled_on": {
      "groups": ["header", "footer"]
    },
    "settings": [
      {
        "type": "select",
        "id": "desktop_content_position",
        "options": [
          {
            "value": "left",
            "label": "t:sections.rich-text.settings.desktop_content_position.options__1.label"
          },
          {
            "value": "center",
            "label": "t:sections.rich-text.settings.desktop_content_position.options__2.label"
          },
          {
            "value": "right",
            "label": "t:sections.rich-text.settings.desktop_content_position.options__3.label"
          }
        ],
        "default": "center",
        "label": "t:sections.rich-text.settings.desktop_content_position.label",
        "info": "t:sections.rich-text.settings.desktop_content_position.info"
      },
      {
        "type": "select",
        "id": "content_alignment",
        "options": [
          {
            "value": "left",
            "label": "t:sections.rich-text.settings.content_alignment.options__1.label"
          },
          {
            "value": "center",
            "label": "t:sections.rich-text.settings.content_alignment.options__2.label"
          },
          {
            "value": "right",
            "label": "t:sections.rich-text.settings.content_alignment.options__3.label"
          }
        ],
        "default": "center",
        "label": "t:sections.rich-text.settings.content_alignment.label"
      },
      {
        "type": "color_scheme",
        "id": "color_scheme",
        "label": "t:sections.all.colors.label",
        "default": "background-1"
      },
      {
        "type": "checkbox",
        "id": "full_width",
        "default": true,
        "label": "t:sections.rich-text.settings.full_width.label"
      },
      {
        "type": "header",
        "content": "t:sections.all.padding.section_padding_heading"
      },
      {
        "type": "range",
        "id": "padding_top",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_top",
        "default": 40
      },
      {
        "type": "range",
        "id": "padding_bottom",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_bottom",
        "default": 52
      }
    ],
    "blocks": [
      {
        "type": "heading",
        "name": "t:sections.rich-text.blocks.heading.name",
        "limit": 3,
        "settings": [
          {
            "type": "inline_richtext",
            "id": "heading",
            "default": "Talk about your brand",
            "label": "t:sections.rich-text.blocks.heading.settings.heading.label"
          },
          {
            "type": "select",
            "id": "heading_size",
            "options": [
              {
                "value": "h2",
                "label": "t:sections.all.heading_size.options__1.label"
              },
              {
                "value": "h1",
                "label": "t:sections.all.heading_size.options__2.label"
              },
              {
                "value": "h0",
                "label": "t:sections.all.heading_size.options__3.label"
              },
              {
                "value": "hxl",
                "label": "t:sections.all.heading_size.options__4.label"
              }
            ],
            "default": "h1",
            "label": "t:sections.all.heading_size.label"
          }
        ]
      },
      {
        "type": "caption",
        "name": "t:sections.rich-text.blocks.caption.name",
        "limit": 3,
        "settings": [
          {
            "type": "text",
            "id": "caption",
            "default": "Add a tagline",
            "label": "t:sections.rich-text.blocks.caption.settings.text.label"
          },
          {
            "type": "select",
            "id": "text_style",
            "options": [
              {
                "value": "subtitle",
                "label": "t:sections.rich-text.blocks.caption.settings.text_style.options__1.label"
              },
              {
                "value": "caption-with-letter-spacing",
                "label": "t:sections.rich-text.blocks.caption.settings.text_style.options__2.label"
              }
            ],
            "default": "caption-with-letter-spacing",
            "label": "t:sections.rich-text.blocks.caption.settings.text_style.label"
          },
          {
            "type": "select",
            "id": "text_size",
            "options": [
              {
                "value": "small",
                "label": "t:sections.rich-text.blocks.caption.settings.caption_size.options__1.label"
              },
              {
                "value": "medium",
                "label": "t:sections.rich-text.blocks.caption.settings.caption_size.options__2.label"
              },
              {
                "value": "large",
                "label": "t:sections.rich-text.blocks.caption.settings.caption_size.options__3.label"
              }
            ],
            "default": "medium",
            "label": "t:sections.rich-text.blocks.caption.settings.caption_size.label"
          }
        ]
      },
      {
        "type": "text",
        "name": "t:sections.rich-text.blocks.text.name",
        "limit": 3,
        "settings": [
          {
            "type": "richtext",
            "id": "text",
            "default": "<p>Share information about your brand with your customers. Describe a product, make announcements, or welcome customers to your store.</p>",
            "label": "t:sections.rich-text.blocks.text.settings.text.label"
          }
        ]
      },
      {
        "type": "button",
        "name": "t:sections.rich-text.blocks.buttons.name",
        "limit": 2,
        "settings": [
          {
            "type": "text",
            "id": "button_label",
            "default": "Button label",
            "label": "t:sections.rich-text.blocks.buttons.settings.button_label_1.label",
            "info": "t:sections.rich-text.blocks.buttons.settings.button_label_1.info"
          },
          {
            "type": "url",
            "id": "button_link",
            "label": "t:sections.rich-text.blocks.buttons.settings.button_link_1.label"
          },
          {
            "type": "checkbox",
            "id": "button_style_secondary",
            "default": false,
            "label": "t:sections.rich-text.blocks.buttons.settings.button_style_secondary_1.label"
          },
          {
            "type": "text",
            "id": "button_label_2",
            "label": "t:sections.rich-text.blocks.buttons.settings.button_label_2.label",
            "info": "t:sections.rich-text.blocks.buttons.settings.button_label_2.info"
          },
          {
            "type": "url",
            "id": "button_link_2",
            "label": "t:sections.rich-text.blocks.buttons.settings.button_link_2.label"
          },
          {
            "type": "checkbox",
            "id": "button_style_secondary_2",
            "default": false,
            "label": "t:sections.rich-text.blocks.buttons.settings.button_style_secondary_2.label"
          }
        ]
      },
      {
        "type": "custom-html",
        "name": "t:sections.rich-text.blocks.custom_html.name",
        "settings": [
          {
            "type": "html",
            "id": "html",
            "default": "Enter Html",
            "label": "Enter HTML"
          }
        ]
      }
    ],
    "presets": [
      {
        "name": "t:sections.rich-text.presets.name",
        "blocks": [
          {
            "type": "heading"
          },
          {
            "type": "text"
          },
          {
            "type": "button"
          },
          {
            "type": "caption"
          }
        ]
      }
    ]
  },
   "featured-collection":{
    "name": "t:sections.featured-collection.name",
    "tag": "section",
    "class": "section",
    "disabled_on": {
      "groups": ["header", "footer"]
    },
    "settings": [
      {
        "type": "inline_richtext",
        "id": "title",
        "default": "Featured collection",
        "label": "t:sections.featured-collection.settings.title.label"
      },
      {
        "type": "select",
        "id": "heading_size",
        "options": [
          {
            "value": "h2",
            "label": "t:sections.all.heading_size.options__1.label"
          },
          {
            "value": "h1",
            "label": "t:sections.all.heading_size.options__2.label"
          },
          {
            "value": "h0",
            "label": "t:sections.all.heading_size.options__3.label"
          }
        ],
        "default": "h1",
        "label": "t:sections.all.heading_size.label"
      },
      {
        "type": "richtext",
        "id": "description",
        "label": "t:sections.featured-collection.settings.description.label"
      },
      {
        "type": "checkbox",
        "id": "show_description",
        "label": "t:sections.featured-collection.settings.show_description.label",
        "default": false
      },
      {
        "type": "select",
        "id": "description_style",
        "label": "t:sections.featured-collection.settings.description_style.label",
        "options": [
          {
            "value": "body",
            "label": "t:sections.featured-collection.settings.description_style.options__1.label"
          },
          {
            "value": "subtitle",
            "label": "t:sections.featured-collection.settings.description_style.options__2.label"
          },
          {
            "value": "uppercase",
            "label": "t:sections.featured-collection.settings.description_style.options__3.label"
          }
        ],
        "default": "body"
      },
      {
        "type": "collection",
        "id": "collection",
        "label": "t:sections.featured-collection.settings.collection.label"
      },
      {
        "type": "range",
        "id": "products_to_show",
        "min": 2,
        "max": 25,
        "step": 1,
        "default": 4,
        "label": "t:sections.featured-collection.settings.products_to_show.label"
      },
      {
        "type": "range",
        "id": "columns_desktop",
        "min": 1,
        "max": 5,
        "step": 1,
        "default": 4,
        "label": "t:sections.featured-collection.settings.columns_desktop.label"
      },
      {
        "type": "checkbox",
        "id": "full_width",
        "label": "t:sections.featured-collection.settings.full_width.label",
        "default": false
      },
      {
        "type": "checkbox",
        "id": "show_view_all",
        "default": true,
        "label": "t:sections.featured-collection.settings.show_view_all.label"
      },
      {
        "type": "select",
        "id": "view_all_style",
        "label": "t:sections.featured-collection.settings.view_all_style.label",
        "options": [
          {
            "value": "link",
            "label": "t:sections.featured-collection.settings.view_all_style.options__1.label"
          },
          {
            "value": "outline",
            "label": "t:sections.featured-collection.settings.view_all_style.options__2.label"
          },
          {
            "value": "solid",
            "label": "t:sections.featured-collection.settings.view_all_style.options__3.label"
          }
        ],
        "default": "solid"
      },
      {
        "type": "checkbox",
        "id": "enable_desktop_slider",
        "label": "t:sections.featured-collection.settings.enable_desktop_slider.label",
        "default": false
      },
      {
        "type": "color_scheme",
        "id": "color_scheme",
        "label": "t:sections.all.colors.label",
        "info": "t:sections.all.colors.has_cards_info",
        "default": "background-1"
      },
      {
        "type": "header",
        "content": "t:sections.featured-collection.settings.header.content"
      },
      {
        "type": "select",
        "id": "image_ratio",
        "options": [
          {
            "value": "adapt",
            "label": "t:sections.featured-collection.settings.image_ratio.options__1.label"
          },
          {
            "value": "portrait",
            "label": "t:sections.featured-collection.settings.image_ratio.options__2.label"
          },
          {
            "value": "square",
            "label": "t:sections.featured-collection.settings.image_ratio.options__3.label"
          }
        ],
        "default": "adapt",
        "label": "t:sections.featured-collection.settings.image_ratio.label"
      },
      {
        "type": "select",
        "id": "image_shape",
        "options": [
          {
            "value": "default",
            "label": "t:sections.all.image_shape.options__1.label"
          },
          {
            "value": "arch",
            "label": "t:sections.all.image_shape.options__2.label"
          },
          {
            "value": "blob",
            "label": "t:sections.all.image_shape.options__3.label"
          },
          {
            "value": "chevronleft",
            "label": "t:sections.all.image_shape.options__4.label"
          },
          {
            "value": "chevronright",
            "label": "t:sections.all.image_shape.options__5.label"
          },
          {
            "value": "diamond",
            "label": "t:sections.all.image_shape.options__6.label"
          },
          {
            "value": "parallelogram",
            "label": "t:sections.all.image_shape.options__7.label"
          },
          {
            "value": "round",
            "label": "t:sections.all.image_shape.options__8.label"
          }
        ],
        "default": "default",
        "label": "t:sections.all.image_shape.label",
        "info": "t:sections.all.image_shape.info"
      },
      {
        "type": "checkbox",
        "id": "show_secondary_image",
        "default": false,
        "label": "t:sections.featured-collection.settings.show_secondary_image.label"
      },
      {
        "type": "checkbox",
        "id": "show_vendor",
        "default": false,
        "label": "t:sections.featured-collection.settings.show_vendor.label"
      },
      {
        "type": "checkbox",
        "id": "show_rating",
        "default": false,
        "label": "t:sections.featured-collection.settings.show_rating.label",
        "info": "t:sections.featured-collection.settings.show_rating.info"
      },
      {
        "type": "checkbox",
        "id": "enable_quick_add",
        "default": false,
        "label": "t:sections.featured-collection.settings.enable_quick_buy.label"
      },
      {
        "type": "header",
        "content": "t:sections.featured-collection.settings.header_mobile.content"
      },
      {
        "type": "select",
        "id": "columns_mobile",
        "default": "2",
        "label": "t:sections.featured-collection.settings.columns_mobile.label",
        "options": [
          {
            "value": "1",
            "label": "t:sections.featured-collection.settings.columns_mobile.options__1.label"
          },
          {
            "value": "2",
            "label": "t:sections.featured-collection.settings.columns_mobile.options__2.label"
          }
        ]
      },
      {
        "type": "checkbox",
        "id": "swipe_on_mobile",
        "default": false,
        "label": "t:sections.featured-collection.settings.swipe_on_mobile.label"
      },
      {
        "type": "header",
        "content": "t:sections.all.padding.section_padding_heading"
      },
      {
        "type": "range",
        "id": "padding_top",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_top",
        "default": 36
      },
      {
        "type": "range",
        "id": "padding_bottom",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_bottom",
        "default": 36
      }
    ],
    "presets": [
      {
        "name": "t:sections.featured-collection.presets.name"
      }
    ]
  },
 "collage": {
  "name": "t:sections.collage.name",
  "tag": "section",
  "class": "section",
  "disabled_on": {
    "groups": ["header", "footer"]
  },
  "settings": [
    {
      "type": "inline_richtext",
      "id": "heading",
      "default": "Multimedia collage",
      "label": "t:sections.collage.settings.heading.label"
    },
    {
      "type": "select",
      "id": "heading_size",
      "options": [
        {
          "value": "h2",
          "label": "t:sections.all.heading_size.options__1.label"
        },
        {
          "value": "h1",
          "label": "t:sections.all.heading_size.options__2.label"
        },
        {
          "value": "h0",
          "label": "t:sections.all.heading_size.options__3.label"
        }
      ],
      "default": "h1",
      "label": "t:sections.all.heading_size.label"
    },
    {
      "type": "select",
      "id": "desktop_layout",
      "options": [
        {
          "value": "left",
          "label": "t:sections.collage.settings.desktop_layout.options__1.label"
        },
        {
          "value": "right",
          "label": "t:sections.collage.settings.desktop_layout.options__2.label"
        }
      ],
      "default": "left",
      "label": "t:sections.collage.settings.desktop_layout.label"
    },
    {
      "type": "select",
      "id": "mobile_layout",
      "options": [
        {
          "value": "collage",
          "label": "t:sections.collage.settings.mobile_layout.options__1.label"
        },
        {
          "value": "column",
          "label": "t:sections.collage.settings.mobile_layout.options__2.label"
        }
      ],
      "default": "column",
      "label": "t:sections.collage.settings.mobile_layout.label"
    },
    {
      "type": "select",
      "id": "card_styles",
      "options": [
        {
          "value": "none",
          "label": "t:sections.collage.settings.card_styles.options__1.label"
        },
        {
          "value": "product-card-wrapper",
          "label": "t:sections.collage.settings.card_styles.options__2.label"
        }
      ],
      "default": "product-card-wrapper",
      "info": "t:sections.collage.settings.card_styles.info",
      "label": "t:sections.collage.settings.card_styles.label"
    },
    {
      "type": "color_scheme",
      "id": "color_scheme",
      "label": "t:sections.all.colors.label",
      "info": "t:sections.all.colors.has_cards_info",
      "default": "background-1"
    },
    {
      "type": "header",
      "content": "t:sections.all.padding.section_padding_heading"
    },
    {
      "type": "range",
      "id": "padding_top",
      "min": 0,
      "max": 100,
      "step": 4,
      "unit": "px",
      "label": "t:sections.all.padding.padding_top",
      "default": 36
    },
    {
      "type": "range",
      "id": "padding_bottom",
      "min": 0,
      "max": 100,
      "step": 4,
      "unit": "px",
      "label": "t:sections.all.padding.padding_bottom",
      "default": 36
    }
  ],
  "blocks": [
    {
      "type": "image",
      "name": "t:sections.collage.blocks.image.name",
      "settings": [
        {
          "type": "image_picker",
          "id": "image",
          "label": "t:sections.collage.blocks.image.settings.image.label"
        }
      ]
    },
    {
      "type": "product",
      "name": "t:sections.collage.blocks.product.name",
      "settings": [
        {
          "type": "product",
          "id": "product",
          "label": "t:sections.collage.blocks.product.settings.product.label"
        },
        {
          "type": "checkbox",
          "id": "second_image",
          "default": false,
          "label": "t:sections.collage.blocks.product.settings.second_image.label"
        }
      ]
    },
    {
      "type": "collection",
      "name": "t:sections.collage.blocks.collection.name",
      "settings": [
        {
          "type": "collection",
          "id": "collection",
          "label": "t:sections.collage.blocks.collection.settings.collection.label"
        }
      ]
    },
    {
      "type": "video",
      "name": "t:sections.collage.blocks.video.name",
      "settings": [
        {
          "type": "image_picker",
          "id": "cover_image",
          "label": "t:sections.collage.blocks.video.settings.cover_image.label"
        },
        {
          "type": "video_url",
          "id": "video_url",
          "accept": ["youtube", "vimeo"],
          "default": "https://www.youtube.com/watch?v=_9VUPq3SxOc",
          "label": "t:sections.collage.blocks.video.settings.video_url.label",
          "placeholder": "t:sections.collage.blocks.video.settings.video_url.placeholder",
          "info": "t:sections.collage.blocks.video.settings.video_url.info"
        },
        {
          "type": "text",
          "id": "description",
          "default": "Describe the video",
          "label": "t:sections.collage.blocks.video.settings.description.label",
          "info": "t:sections.collage.blocks.video.settings.description.info"
        }
      ]
    },
  ],
  "max_blocks": 3,
  "presets": [
    {
      "name": "t:sections.collage.presets.name",
      "blocks": [
        {
          "type": "image"
        },
        {
          "type": "product"
        },
        {
          "type": "collection"
        }
      ]
    }
  ]
  },
  "video":{
    "name": "t:sections.video.name",
    "tag": "section",
    "class": "section",
    "disabled_on": {
      "groups": ["header", "footer"]
    },
    "settings": [
      {
        "type": "inline_richtext",
        "id": "heading",
        "default": "Video",
        "label": "t:sections.video.settings.heading.label"
      },
      {
        "type": "select",
        "id": "heading_size",
        "options": [
          {
            "value": "h2",
            "label": "t:sections.all.heading_size.options__1.label"
          },
          {
            "value": "h1",
            "label": "t:sections.all.heading_size.options__2.label"
          },
          {
            "value": "h0",
            "label": "t:sections.all.heading_size.options__3.label"
          }
        ],
        "default": "h1",
        "label": "t:sections.all.heading_size.label"
      },
      {
        "type": "checkbox",
        "id": "enable_video_looping",
        "label": "t:sections.video.settings.enable_video_looping.label",
        "default": false
      },
      {
        "type": "header",
        "content": "t:sections.video.settings.header__1.content"
      },
      {
        "type": "video",
        "id": "video",
        "label": "t:sections.video.settings.video.label"
      },
      {
        "type": "header",
        "content": "t:sections.video.settings.header__2.content"
      },
      {
        "type": "paragraph",
        "content": "t:sections.video.settings.paragraph.content"
      },
      {
        "type": "video_url",
        "id": "video_url",
        "accept": ["youtube", "vimeo"],
        "default": "https://www.youtube.com/watch?v=_9VUPq3SxOc",
        "label": "t:sections.video.settings.video_url.label",
        "info": "t:sections.video.settings.video_url.info"
      },
      {
        "type": "image_picker",
        "id": "cover_image",
        "label": "t:sections.video.settings.cover_image.label"
      },
      {
        "type": "text",
        "id": "description",
        "label": "t:sections.video.settings.description.label",
        "info": "t:sections.video.settings.description.info"
      },
      {
        "type": "header",
        "content": "t:sections.video.settings.header__3.content"
      },
      {
        "type": "checkbox",
        "id": "full_width",
        "label": "t:sections.video.settings.full_width.label",
        "default": false
      },
      {
        "type": "color_scheme",
        "id": "color_scheme",
        "label": "t:sections.all.colors.label",
        "default": "background-1"
      },
      {
        "type": "header",
        "content": "t:sections.all.padding.section_padding_heading"
      },
      {
        "type": "range",
        "id": "padding_top",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_top",
        "default": 36
      },
      {
        "type": "range",
        "id": "padding_bottom",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_bottom",
        "default": 36
      }
    ],
    "presets": [
      {
        "name": "t:sections.video.presets.name"
      }
    ]
  },
  "multicolumn":{
    "name": "t:sections.multicolumn.name",
    "class": "section",
    "tag": "section",
    "disabled_on": {
      "groups": ["header", "footer"]
    },
    "settings": [
      {
        "type": "inline_richtext",
        "id": "title",
        "default": "Multicolumn",
        "label": "t:sections.multicolumn.settings.title.label"
      },
      {
        "type": "select",
        "id": "heading_size",
        "options": [
          {
            "value": "h2",
            "label": "t:sections.all.heading_size.options__1.label"
          },
          {
            "value": "h1",
            "label": "t:sections.all.heading_size.options__2.label"
          },
          {
            "value": "h0",
            "label": "t:sections.all.heading_size.options__3.label"
          }
        ],
        "default": "h1",
        "label": "t:sections.all.heading_size.label"
      },
      {
        "type": "select",
        "id": "image_width",
        "options": [
          {
            "value": "third",
            "label": "t:sections.multicolumn.settings.image_width.options__1.label"
          },
          {
            "value": "half",
            "label": "t:sections.multicolumn.settings.image_width.options__2.label"
          },
          {
            "value": "full",
            "label": "t:sections.multicolumn.settings.image_width.options__3.label"
          }
        ],
        "default": "full",
        "label": "t:sections.multicolumn.settings.image_width.label"
      },
      {
        "type": "select",
        "id": "image_ratio",
        "options": [
          {
            "value": "adapt",
            "label": "t:sections.multicolumn.settings.image_ratio.options__1.label"
          },
          {
            "value": "portrait",
            "label": "t:sections.multicolumn.settings.image_ratio.options__2.label"
          },
          {
            "value": "square",
            "label": "t:sections.multicolumn.settings.image_ratio.options__3.label"
          },
          {
            "value": "circle",
            "label": "t:sections.multicolumn.settings.image_ratio.options__4.label"
          }
        ],
        "default": "adapt",
        "label": "t:sections.multicolumn.settings.image_ratio.label"
      },
      {
        "type": "range",
        "id": "columns_desktop",
        "min": 1,
        "max": 6,
        "step": 1,
        "default": 3,
        "label": "t:sections.multicolumn.settings.columns_desktop.label"
      },
      {
        "type": "select",
        "id": "column_alignment",
        "options": [
          {
            "value": "left",
            "label": "t:sections.multicolumn.settings.column_alignment.options__1.label"
          },
          {
            "value": "center",
            "label": "t:sections.multicolumn.settings.column_alignment.options__2.label"
          }
        ],
        "default": "left",
        "label": "t:sections.multicolumn.settings.column_alignment.label"
      },
      {
        "type": "select",
        "id": "background_style",
        "options": [
          {
            "value": "none",
            "label": "t:sections.multicolumn.settings.background_style.options__1.label"
          },
          {
            "value": "primary",
            "label": "t:sections.multicolumn.settings.background_style.options__2.label"
          }
        ],
        "default": "primary",
        "label": "t:sections.multicolumn.settings.background_style.label"
      },
      {
        "type": "text",
        "id": "button_label",
        "default": "Button label",
        "label": "t:sections.multicolumn.settings.button_label.label"
      },
      {
        "type": "url",
        "id": "button_link",
        "label": "t:sections.multicolumn.settings.button_link.label"
      },
      {
        "type": "color_scheme",
        "id": "color_scheme",
        "label": "t:sections.all.colors.label",
        "default": "background-1"
      },
      {
        "type": "header",
        "content": "t:sections.multicolumn.settings.header_mobile.content"
      },
      {
        "type": "select",
        "id": "columns_mobile",
        "options": [
          {
            "value": "1",
            "label": "t:sections.multicolumn.settings.columns_mobile.options__1.label"
          },
          {
            "value": "2",
            "label": "t:sections.multicolumn.settings.columns_mobile.options__2.label"
          }
        ],
        "default": "1",
        "label": "t:sections.multicolumn.settings.columns_mobile.label"
      },
      {
        "type": "checkbox",
        "id": "swipe_on_mobile",
        "default": false,
        "label": "t:sections.multicolumn.settings.swipe_on_mobile.label"
      },
      {
        "type": "header",
        "content": "t:sections.all.padding.section_padding_heading"
      },
      {
        "type": "range",
        "id": "padding_top",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_top",
        "default": 36
      },
      {
        "type": "range",
        "id": "padding_bottom",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_bottom",
        "default": 36
      }
    ],
    "blocks": [
      {
        "type": "column",
        "name": "t:sections.multicolumn.blocks.column.name",
        "settings": [
          {
            "type": "image_picker",
            "id": "image",
            "label": "t:sections.multicolumn.blocks.column.settings.image.label"
          },
          {
            "type": "inline_richtext",
            "id": "title",
            "default": "Column",
            "label": "t:sections.multicolumn.blocks.column.settings.title.label"
          },
          {
            "type": "richtext",
            "id": "text",
            "default": "<p>Pair text with an image to focus on your chosen product, collection, or blog post. Add details on availability, style, or even provide a review.</p>",
            "label": "t:sections.multicolumn.blocks.column.settings.text.label"
          },
          {
            "type": "text",
            "id": "link_label",
            "label": "t:sections.multicolumn.blocks.column.settings.link_label.label"
          },
          {
            "type": "url",
            "id": "link",
            "label": "t:sections.multicolumn.blocks.column.settings.link.label"
          }
        ]
      },
      {
        "type": "custom-html",
        "name": "t:sections.rich-text.blocks.custom_html.name",
        "settings": [
          {
            "type": "html",
            "id": "html",
            "default": "Enter Html",
            "label": "Enter HTML"
          }
        ]
      }
    ],
    "presets": [
      {
        "name": "t:sections.multicolumn.presets.name",
        "blocks": [
          {
            "type": "column"
          },
          {
            "type": "column"
          },
          {
            "type": "column"
          }
        ]
      }
    ]
  },
  "main-product":{
    "name": "t:sections.main-product.name",
    "tag": "section",
    "class": "section",
    "blocks": [
      {
        "type": "@app"
      },
      {
        "type": "text",
        "name": "t:sections.main-product.blocks.text.name",
        "settings": [
          {
            "type": "inline_richtext",
            "id": "text",
            "default": "Text block",
            "label": "t:sections.main-product.blocks.text.settings.text.label"
          },
          {
            "type": "select",
            "id": "text_style",
            "options": [
              {
                "value": "body",
                "label": "t:sections.main-product.blocks.text.settings.text_style.options__1.label"
              },
              {
                "value": "subtitle",
                "label": "t:sections.main-product.blocks.text.settings.text_style.options__2.label"
              },
              {
                "value": "uppercase",
                "label": "t:sections.main-product.blocks.text.settings.text_style.options__3.label"
              }
            ],
            "default": "body",
            "label": "t:sections.main-product.blocks.text.settings.text_style.label"
          }
        ]
      },
      {
        "type": "title",
        "name": "t:sections.main-product.blocks.title.name",
        "limit": 1
      },
      {
        "type": "price",
        "name": "t:sections.main-product.blocks.price.name",
        "limit": 1
      },
      {
        "type": "sku",
        "name": "t:sections.main-product.blocks.sku.name",
        "limit": 1,
        "settings": [
          {
            "type": "select",
            "id": "text_style",
            "options": [
              {
                "value": "body",
                "label": "t:sections.main-product.blocks.sku.settings.text_style.options__1.label"
              },
              {
                "value": "subtitle",
                "label": "t:sections.main-product.blocks.sku.settings.text_style.options__2.label"
              },
              {
                "value": "uppercase",
                "label": "t:sections.main-product.blocks.sku.settings.text_style.options__3.label"
              }
            ],
            "default": "body",
            "label": "t:sections.main-product.blocks.sku.settings.text_style.label"
          }
        ]
      },
      {
        "type": "inventory",
        "name": "t:sections.main-product.blocks.inventory.name",
        "limit": 1,
        "settings": [
          {
            "type": "select",
            "id": "text_style",
            "options": [
              {
                "value": "body",
                "label": "t:sections.main-product.blocks.inventory.settings.text_style.options__1.label"
              },
              {
                "value": "subtitle",
                "label": "t:sections.main-product.blocks.inventory.settings.text_style.options__2.label"
              },
              {
                "value": "uppercase",
                "label": "t:sections.main-product.blocks.inventory.settings.text_style.options__3.label"
              }
            ],
            "default": "body",
            "label": "t:sections.main-product.blocks.inventory.settings.text_style.label"
          },
          {
            "type": "range",
            "id": "inventory_threshold",
            "label": "t:sections.main-product.blocks.inventory.settings.inventory_threshold.label",
            "min": 0,
            "max": 100,
            "step": 1,
            "info": "t:sections.main-product.blocks.inventory.settings.inventory_threshold.info",
            "default": 10
          },
          {
            "type": "checkbox",
            "id": "show_inventory_quantity",
            "label": "t:sections.main-product.blocks.inventory.settings.show_inventory_quantity.label",
            "default": true
          }
        ]
      },
      {
        "type": "quantity_selector",
        "name": "t:sections.main-product.blocks.quantity_selector.name",
        "limit": 1
      },
      {
        "type": "variant_picker",
        "name": "t:sections.main-product.blocks.variant_picker.name",
        "limit": 1,
        "settings": [
          {
            "type": "select",
            "id": "picker_type",
            "options": [
              {
                "value": "dropdown",
                "label": "t:sections.main-product.blocks.variant_picker.settings.picker_type.options__1.label"
              },
              {
                "value": "button",
                "label": "t:sections.main-product.blocks.variant_picker.settings.picker_type.options__2.label"
              }
            ],
            "default": "button",
            "label": "t:sections.main-product.blocks.variant_picker.settings.picker_type.label"
          }
        ]
      },
      {
        "type": "buy_buttons",
        "name": "t:sections.main-product.blocks.buy_buttons.name",
        "limit": 1,
        "settings": [
          {
            "type": "checkbox",
            "id": "show_dynamic_checkout",
            "default": true,
            "label": "t:sections.main-product.blocks.buy_buttons.settings.show_dynamic_checkout.label",
            "info": "t:sections.main-product.blocks.buy_buttons.settings.show_dynamic_checkout.info"
          },
          {
            "type": "checkbox",
            "id": "show_gift_card_recipient",
            "default": false,
            "label": "t:sections.main-product.blocks.buy_buttons.settings.show_gift_card_recipient.label",
            "info": "t:sections.main-product.blocks.buy_buttons.settings.show_gift_card_recipient.info"
          },
          {
            "type": "checkbox",
            "id": "show_redirect_page",
            "default": false,
            "label": "Redirect to actual page",
            "info": "Redirect product to actual page"
          },
          {
            "type": "text",
            "id": "show_redirect_button_text",
            "default": "Learn More",
            "info": "",
            "label": "Redirect button Text"
          },

        ]
      },
      {
        "type": "description",
        "name": "t:sections.main-product.blocks.description.name",
        "limit": 1
      },
      {
        "type": "share",
        "name": "t:sections.main-product.blocks.share.name",
        "limit": 1,
        "settings": [
          {
            "type": "text",
            "id": "share_label",
            "label": "t:sections.main-product.blocks.share.settings.text.label",
            "default": "Share"
          },
          {
            "type": "paragraph",
            "content": "t:sections.main-product.blocks.share.settings.featured_image_info.content"
          },
          {
            "type": "paragraph",
            "content": "t:sections.main-product.blocks.share.settings.title_info.content"
          }
        ]
      },
      {
        "type": "custom_liquid",
        "name": "t:sections.main-product.blocks.custom_liquid.name",
        "settings": [
          {
            "type": "liquid",
            "id": "custom_liquid",
            "label": "t:sections.main-product.blocks.custom_liquid.settings.custom_liquid.label",
            "info": "t:sections.main-product.blocks.custom_liquid.settings.custom_liquid.info"
          }
        ]
      },
      {
        "type": "collapsible_tab",
        "name": "t:sections.main-product.blocks.collapsible_tab.name",
        "settings": [
          {
            "type": "text",
            "id": "heading",
            "default": "Collapsible row",
            "info": "t:sections.main-product.blocks.collapsible_tab.settings.heading.info",
            "label": "t:sections.main-product.blocks.collapsible_tab.settings.heading.label"
          },
          {
            "type": "select",
            "id": "icon",
            "options": [
              {
                "value": "none",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__1.label"
              },
              {
                "value": "apple",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__2.label"
              },
              {
                "value": "banana",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__3.label"
              },
              {
                "value": "bottle",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__4.label"
              },
              {
                "value": "box",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__5.label"
              },
              {
                "value": "carrot",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__6.label"
              },
              {
                "value": "chat_bubble",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__7.label"
              },
              {
                "value": "check_mark",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__8.label"
              },
              {
                "value": "clipboard",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__9.label"
              },
              {
                "value": "dairy",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__10.label"
              },
              {
                "value": "dairy_free",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__11.label"
              },
              {
                "value": "dryer",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__12.label"
              },
              {
                "value": "eye",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__13.label"
              },
              {
                "value": "fire",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__14.label"
              },
              {
                "value": "gluten_free",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__15.label"
              },
              {
                "value": "heart",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__16.label"
              },
              {
                "value": "iron",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__17.label"
              },
              {
                "value": "leaf",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__18.label"
              },
              {
                "value": "leather",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__19.label"
              },
              {
                "value": "lightning_bolt",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__20.label"
              },
              {
                "value": "lipstick",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__21.label"
              },
              {
                "value": "lock",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__22.label"
              },
              {
                "value": "map_pin",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__23.label"
              },
              {
                "value": "nut_free",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__24.label"
              },
              {
                "value": "pants",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__25.label"
              },
              {
                "value": "paw_print",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__26.label"
              },
              {
                "value": "pepper",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__27.label"
              },
              {
                "value": "perfume",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__28.label"
              },
              {
                "value": "plane",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__29.label"
              },
              {
                "value": "plant",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__30.label"
              },
              {
                "value": "price_tag",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__31.label"
              },
              {
                "value": "question_mark",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__32.label"
              },
              {
                "value": "recycle",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__33.label"
              },
              {
                "value": "return",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__34.label"
              },
              {
                "value": "ruler",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__35.label"
              },
              {
                "value": "serving_dish",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__36.label"
              },
              {
                "value": "shirt",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__37.label"
              },
              {
                "value": "shoe",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__38.label"
              },
              {
                "value": "silhouette",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__39.label"
              },
              {
                "value": "snowflake",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__40.label"
              },
              {
                "value": "star",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__41.label"
              },
              {
                "value": "stopwatch",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__42.label"
              },
              {
                "value": "truck",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__43.label"
              },
              {
                "value": "washing",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__44.label"
              }
            ],
            "default": "check_mark",
            "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.label"
          },
          {
            "type": "richtext",
            "id": "content",
            "label": "t:sections.main-product.blocks.collapsible_tab.settings.content.label"
          },
          {
            "type": "page",
            "id": "page",
            "label": "t:sections.main-product.blocks.collapsible_tab.settings.page.label"
          }
        ]
      },
      {
        "type": "popup",
        "name": "t:sections.main-product.blocks.popup.name",
        "settings": [
          {
            "type": "text",
            "id": "text",
            "default": "Pop-up link text",
            "label": "t:sections.main-product.blocks.popup.settings.link_label.label"
          },
          {
            "id": "page",
            "type": "page",
            "label": "t:sections.main-product.blocks.popup.settings.page.label"
          }
        ]
      },
      {
        "type": "rating",
        "name": "t:sections.main-product.blocks.rating.name",
        "limit": 1,
        "settings": [
          {
            "type": "paragraph",
            "content": "t:sections.main-product.blocks.rating.settings.paragraph.content"
          }
        ]
      },
      {
        "type": "complementary",
        "name": "t:sections.main-product.blocks.complementary_products.name",
        "limit": 1,
        "settings": [
          {
            "type": "paragraph",
            "content": "t:sections.main-product.blocks.complementary_products.settings.paragraph.content"
          },
          {
            "type": "text",
            "id": "block_heading",
            "default": "Pairs well with",
            "label": "t:sections.main-product.blocks.complementary_products.settings.heading.label"
          },
          {
            "type": "checkbox",
            "id": "make_collapsible_row",
            "default": false,
            "label": "t:sections.main-product.blocks.complementary_products.settings.make_collapsible_row.label"
          },
          {
            "type": "select",
            "id": "icon",
            "options": [
              {
                "value": "none",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__1.label"
              },
              {
                "value": "apple",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__2.label"
              },
              {
                "value": "banana",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__3.label"
              },
              {
                "value": "bottle",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__4.label"
              },
              {
                "value": "box",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__5.label"
              },
              {
                "value": "carrot",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__6.label"
              },
              {
                "value": "chat_bubble",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__7.label"
              },
              {
                "value": "check_mark",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__8.label"
              },
              {
                "value": "clipboard",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__9.label"
              },
              {
                "value": "dairy",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__10.label"
              },
              {
                "value": "dairy_free",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__11.label"
              },
              {
                "value": "dryer",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__12.label"
              },
              {
                "value": "eye",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__13.label"
              },
              {
                "value": "fire",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__14.label"
              },
              {
                "value": "gluten_free",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__15.label"
              },
              {
                "value": "heart",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__16.label"
              },
              {
                "value": "iron",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__17.label"
              },
              {
                "value": "leaf",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__18.label"
              },
              {
                "value": "leather",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__19.label"
              },
              {
                "value": "lightning_bolt",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__20.label"
              },
              {
                "value": "lipstick",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__21.label"
              },
              {
                "value": "lock",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__22.label"
              },
              {
                "value": "map_pin",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__23.label"
              },
              {
                "value": "nut_free",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__24.label"
              },
              {
                "value": "pants",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__25.label"
              },
              {
                "value": "paw_print",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__26.label"
              },
              {
                "value": "pepper",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__27.label"
              },
              {
                "value": "perfume",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__28.label"
              },
              {
                "value": "plane",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__29.label"
              },
              {
                "value": "plant",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__30.label"
              },
              {
                "value": "price_tag",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__31.label"
              },
              {
                "value": "question_mark",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__32.label"
              },
              {
                "value": "recycle",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__33.label"
              },
              {
                "value": "return",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__34.label"
              },
              {
                "value": "ruler",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__35.label"
              },
              {
                "value": "serving_dish",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__36.label"
              },
              {
                "value": "shirt",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__37.label"
              },
              {
                "value": "shoe",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__38.label"
              },
              {
                "value": "silhouette",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__39.label"
              },
              {
                "value": "snowflake",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__40.label"
              },
              {
                "value": "star",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__41.label"
              },
              {
                "value": "stopwatch",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__42.label"
              },
              {
                "value": "truck",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__43.label"
              },
              {
                "value": "washing",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__44.label"
              }
            ],
            "default": "price_tag",
            "info": "t:sections.main-product.blocks.complementary_products.settings.icon.info",
            "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.label"
          },
          {
            "type": "range",
            "id": "product_list_limit",
            "min": 1,
            "max": 10,
            "step": 1,
            "default": 10,
            "label": "t:sections.main-product.blocks.complementary_products.settings.product_list_limit.label"
          },
          {
            "type": "range",
            "id": "products_per_page",
            "min": 1,
            "max": 4,
            "step": 1,
            "default": 3,
            "label": "t:sections.main-product.blocks.complementary_products.settings.products_per_page.label"
          },
          {
            "type": "select",
            "id": "pagination_style",
            "options": [
              {
                "value": "dots",
                "label": "t:sections.main-product.blocks.complementary_products.settings.pagination_style.options.option_1"
              },
              {
                "value": "counter",
                "label": "t:sections.main-product.blocks.complementary_products.settings.pagination_style.options.option_2"
              },
              {
                "value": "numbers",
                "label": "t:sections.main-product.blocks.complementary_products.settings.pagination_style.options.option_3"
              }
            ],
            "label": "t:sections.main-product.blocks.complementary_products.settings.pagination_style.label",
            "default": "counter"
          },
          {
            "type": "header",
            "content": "t:sections.main-product.blocks.complementary_products.settings.product_card.heading"
          },
          {
            "type": "select",
            "id": "image_ratio",
            "options": [
              {
                "value": "portrait",
                "label": "t:sections.main-product.blocks.complementary_products.settings.image_ratio.options.option_1"
              },
              {
                "value": "square",
                "label": "t:sections.main-product.blocks.complementary_products.settings.image_ratio.options.option_2"
              }
            ],
            "label": "t:sections.main-product.blocks.complementary_products.settings.image_ratio.label",
            "default": "square"
          },
          {
            "type": "checkbox",
            "id": "enable_quick_add",
            "label": "t:sections.main-product.blocks.complementary_products.settings.enable_quick_add.label",
            "default": false
          }
        ]
      },
      {
        "type": "icon-with-text",
        "name": "t:sections.main-product.blocks.icon_with_text.name",
        "settings": [
          {
            "type": "select",
            "id": "layout",
            "options": [
              {
                "value": "horizontal",
                "label": "t:sections.main-product.blocks.icon_with_text.settings.layout.options__1.label"
              },
              {
                "value": "vertical",
                "label": "t:sections.main-product.blocks.icon_with_text.settings.layout.options__2.label"
              }
            ],
            "default": "horizontal",
            "label": "t:sections.main-product.blocks.icon_with_text.settings.layout.label"
          },
          {
            "type": "header",
            "content": "t:sections.main-product.blocks.icon_with_text.settings.content.label",
            "info": "t:sections.main-product.blocks.icon_with_text.settings.content.info"
          },
          {
            "type": "select",
            "id": "icon_1",
            "options": [
              {
                "value": "none",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__1.label"
              },
              {
                "value": "apple",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__2.label"
              },
              {
                "value": "banana",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__3.label"
              },
              {
                "value": "bottle",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__4.label"
              },
              {
                "value": "box",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__5.label"
              },
              {
                "value": "carrot",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__6.label"
              },
              {
                "value": "chat_bubble",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__7.label"
              },
              {
                "value": "check_mark",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__8.label"
              },
              {
                "value": "clipboard",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__9.label"
              },
              {
                "value": "dairy",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__10.label"
              },
              {
                "value": "dairy_free",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__11.label"
              },
              {
                "value": "dryer",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__12.label"
              },
              {
                "value": "eye",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__13.label"
              },
              {
                "value": "fire",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__14.label"
              },
              {
                "value": "gluten_free",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__15.label"
              },
              {
                "value": "heart",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__16.label"
              },
              {
                "value": "iron",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__17.label"
              },
              {
                "value": "leaf",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__18.label"
              },
              {
                "value": "leather",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__19.label"
              },
              {
                "value": "lightning_bolt",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__20.label"
              },
              {
                "value": "lipstick",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__21.label"
              },
              {
                "value": "lock",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__22.label"
              },
              {
                "value": "map_pin",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__23.label"
              },
              {
                "value": "nut_free",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__24.label"
              },
              {
                "value": "pants",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__25.label"
              },
              {
                "value": "paw_print",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__26.label"
              },
              {
                "value": "pepper",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__27.label"
              },
              {
                "value": "perfume",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__28.label"
              },
              {
                "value": "plane",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__29.label"
              },
              {
                "value": "plant",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__30.label"
              },
              {
                "value": "price_tag",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__31.label"
              },
              {
                "value": "question_mark",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__32.label"
              },
              {
                "value": "recycle",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__33.label"
              },
              {
                "value": "return",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__34.label"
              },
              {
                "value": "ruler",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__35.label"
              },
              {
                "value": "serving_dish",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__36.label"
              },
              {
                "value": "shirt",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__37.label"
              },
              {
                "value": "shoe",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__38.label"
              },
              {
                "value": "silhouette",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__39.label"
              },
              {
                "value": "snowflake",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__40.label"
              },
              {
                "value": "star",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__41.label"
              },
              {
                "value": "stopwatch",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__42.label"
              },
              {
                "value": "truck",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__43.label"
              },
              {
                "value": "washing",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__44.label"
              }
            ],
            "default": "heart",
            "label": "t:sections.main-product.blocks.icon_with_text.settings.icon_1.label"
          },
          {
            "type": "image_picker",
            "id": "image_1",
            "label": "t:sections.main-product.blocks.icon_with_text.settings.image_1.label"
          },
          {
            "type": "inline_richtext",
            "id": "heading_1",
            "default": "Heading",
            "label": "t:sections.main-product.blocks.icon_with_text.settings.heading_1.label",
            "info": "t:sections.main-product.blocks.icon_with_text.settings.heading.info"
          },
          {
            "type": "select",
            "id": "icon_2",
            "options": [
              {
                "value": "none",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__1.label"
              },
              {
                "value": "apple",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__2.label"
              },
              {
                "value": "banana",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__3.label"
              },
              {
                "value": "bottle",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__4.label"
              },
              {
                "value": "box",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__5.label"
              },
              {
                "value": "carrot",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__6.label"
              },
              {
                "value": "chat_bubble",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__7.label"
              },
              {
                "value": "check_mark",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__8.label"
              },
              {
                "value": "clipboard",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__9.label"
              },
              {
                "value": "dairy",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__10.label"
              },
              {
                "value": "dairy_free",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__11.label"
              },
              {
                "value": "dryer",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__12.label"
              },
              {
                "value": "eye",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__13.label"
              },
              {
                "value": "fire",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__14.label"
              },
              {
                "value": "gluten_free",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__15.label"
              },
              {
                "value": "heart",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__16.label"
              },
              {
                "value": "iron",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__17.label"
              },
              {
                "value": "leaf",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__18.label"
              },
              {
                "value": "leather",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__19.label"
              },
              {
                "value": "lightning_bolt",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__20.label"
              },
              {
                "value": "lipstick",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__21.label"
              },
              {
                "value": "lock",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__22.label"
              },
              {
                "value": "map_pin",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__23.label"
              },
              {
                "value": "nut_free",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__24.label"
              },
              {
                "value": "pants",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__25.label"
              },
              {
                "value": "paw_print",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__26.label"
              },
              {
                "value": "pepper",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__27.label"
              },
              {
                "value": "perfume",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__28.label"
              },
              {
                "value": "plane",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__29.label"
              },
              {
                "value": "plant",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__30.label"
              },
              {
                "value": "price_tag",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__31.label"
              },
              {
                "value": "question_mark",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__32.label"
              },
              {
                "value": "recycle",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__33.label"
              },
              {
                "value": "return",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__34.label"
              },
              {
                "value": "ruler",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__35.label"
              },
              {
                "value": "serving_dish",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__36.label"
              },
              {
                "value": "shirt",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__37.label"
              },
              {
                "value": "shoe",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__38.label"
              },
              {
                "value": "silhouette",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__39.label"
              },
              {
                "value": "snowflake",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__40.label"
              },
              {
                "value": "star",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__41.label"
              },
              {
                "value": "stopwatch",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__42.label"
              },
              {
                "value": "truck",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__43.label"
              },
              {
                "value": "washing",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__44.label"
              }
            ],
            "default": "return",
            "label": "t:sections.main-product.blocks.icon_with_text.settings.icon_2.label"
          },
          {
            "type": "image_picker",
            "id": "image_2",
            "label": "t:sections.main-product.blocks.icon_with_text.settings.image_2.label"
          },
          {
            "type": "inline_richtext",
            "id": "heading_2",
            "default": "Heading",
            "label": "t:sections.main-product.blocks.icon_with_text.settings.heading_2.label",
            "info": "t:sections.main-product.blocks.icon_with_text.settings.heading.info"
          },
          {
            "type": "select",
            "id": "icon_3",
            "options": [
              {
                "value": "none",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__1.label"
              },
              {
                "value": "apple",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__2.label"
              },
              {
                "value": "banana",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__3.label"
              },
              {
                "value": "bottle",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__4.label"
              },
              {
                "value": "box",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__5.label"
              },
              {
                "value": "carrot",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__6.label"
              },
              {
                "value": "chat_bubble",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__7.label"
              },
              {
                "value": "check_mark",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__8.label"
              },
              {
                "value": "clipboard",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__9.label"
              },
              {
                "value": "dairy",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__10.label"
              },
              {
                "value": "dairy_free",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__11.label"
              },
              {
                "value": "dryer",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__12.label"
              },
              {
                "value": "eye",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__13.label"
              },
              {
                "value": "fire",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__14.label"
              },
              {
                "value": "gluten_free",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__15.label"
              },
              {
                "value": "heart",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__16.label"
              },
              {
                "value": "iron",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__17.label"
              },
              {
                "value": "leaf",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__18.label"
              },
              {
                "value": "leather",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__19.label"
              },
              {
                "value": "lightning_bolt",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__20.label"
              },
              {
                "value": "lipstick",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__21.label"
              },
              {
                "value": "lock",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__22.label"
              },
              {
                "value": "map_pin",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__23.label"
              },
              {
                "value": "nut_free",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__24.label"
              },
              {
                "value": "pants",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__25.label"
              },
              {
                "value": "paw_print",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__26.label"
              },
              {
                "value": "pepper",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__27.label"
              },
              {
                "value": "perfume",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__28.label"
              },
              {
                "value": "plane",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__29.label"
              },
              {
                "value": "plant",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__30.label"
              },
              {
                "value": "price_tag",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__31.label"
              },
              {
                "value": "question_mark",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__32.label"
              },
              {
                "value": "recycle",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__33.label"
              },
              {
                "value": "return",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__34.label"
              },
              {
                "value": "ruler",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__35.label"
              },
              {
                "value": "serving_dish",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__36.label"
              },
              {
                "value": "shirt",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__37.label"
              },
              {
                "value": "shoe",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__38.label"
              },
              {
                "value": "silhouette",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__39.label"
              },
              {
                "value": "snowflake",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__40.label"
              },
              {
                "value": "star",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__41.label"
              },
              {
                "value": "stopwatch",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__42.label"
              },
              {
                "value": "truck",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__43.label"
              },
              {
                "value": "washing",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__44.label"
              }
            ],
            "default": "truck",
            "label": "t:sections.main-product.blocks.icon_with_text.settings.icon_3.label"
          },
          {
            "type": "image_picker",
            "id": "image_3",
            "label": "t:sections.main-product.blocks.icon_with_text.settings.image_3.label"
          },
          {
            "type": "inline_richtext",
            "id": "heading_3",
            "default": "Heading",
            "label": "t:sections.main-product.blocks.icon_with_text.settings.heading_3.label",
            "info": "t:sections.main-product.blocks.icon_with_text.settings.heading.info"
          }
        ]
      },
      {
        "type": "custom_liquid",
        "name": "t:sections.main-product.blocks.custom_liquid.name",
        "settings": [
          {
            "type": "liquid",
            "id": "custom_liquid",
            "default": "custom liquid",
            "label": "custom liquid"
          }
        ]
      }
    ],
    "settings": [
      {
        "type": "checkbox",
        "id": "enable_sticky_info",
        "default": true,
        "label": "t:sections.main-product.settings.enable_sticky_info.label"
      },
      {
        "type": "header",
        "content": "t:sections.main-product.settings.header.content",
        "info": "t:sections.main-product.settings.header.info"
      },
      {
        "type": "select",
        "id": "media_size",
        "options": [
          {
            "value": "small",
            "label": "t:sections.main-product.settings.media_size.options__1.label"
          },
          {
            "value": "medium",
            "label": "t:sections.main-product.settings.media_size.options__2.label"
          },
          {
            "value": "large",
            "label": "t:sections.main-product.settings.media_size.options__3.label"
          }
        ],
        "default": "large",
        "label": "t:sections.main-product.settings.media_size.label",
        "info": "t:sections.main-product.settings.media_size.info"
      },
      {
        "type": "checkbox",
        "id": "constrain_to_viewport",
        "default": true,
        "label": "t:sections.main-product.settings.constrain_to_viewport.label"
      },
      {
        "type": "select",
        "id": "media_fit",
        "options": [
          {
            "value": "contain",
            "label": "t:sections.main-product.settings.media_fit.options__1.label"
          },
          {
            "value": "cover",
            "label": "t:sections.main-product.settings.media_fit.options__2.label"
          }
        ],
        "default": "contain",
        "label": "t:sections.main-product.settings.media_fit.label"
      },
      {
        "type": "select",
        "id": "gallery_layout",
        "options": [
          {
            "value": "stacked",
            "label": "t:sections.main-product.settings.gallery_layout.options__1.label"
          },
          {
            "value": "columns",
            "label": "t:sections.main-product.settings.gallery_layout.options__2.label"
          },
          {
            "value": "thumbnail",
            "label": "t:sections.main-product.settings.gallery_layout.options__3.label"
          },
          {
            "value": "thumbnail_slider",
            "label": "t:sections.main-product.settings.gallery_layout.options__4.label"
          }
        ],
        "default": "stacked",
        "label": "t:sections.main-product.settings.gallery_layout.label"
      },
      {
        "type": "select",
        "id": "media_position",
        "options": [
          {
            "value": "left",
            "label": "t:sections.main-product.settings.media_position.options__1.label"
          },
          {
            "value": "right",
            "label": "t:sections.main-product.settings.media_position.options__2.label"
          }
        ],
        "default": "left",
        "label": "t:sections.main-product.settings.media_position.label",
        "info": "t:sections.main-product.settings.media_position.info"
      },
      {
        "type": "select",
        "id": "image_zoom",
        "options": [
          {
            "value": "lightbox",
            "label": "t:sections.main-product.settings.image_zoom.options__1.label"
          },
          {
            "value": "hover",
            "label": "t:sections.main-product.settings.image_zoom.options__2.label"
          },
          {
            "value": "none",
            "label": "t:sections.main-product.settings.image_zoom.options__3.label"
          }
        ],
        "default": "lightbox",
        "label": "t:sections.main-product.settings.image_zoom.label",
        "info": "t:sections.main-product.settings.image_zoom.info"
      },
      {
        "type": "select",
        "id": "mobile_thumbnails",
        "options": [
          {
            "value": "columns",
            "label": "t:sections.main-product.settings.mobile_thumbnails.options__1.label"
          },
          {
            "value": "show",
            "label": "t:sections.main-product.settings.mobile_thumbnails.options__2.label"
          },
          {
            "value": "hide",
            "label": "t:sections.main-product.settings.mobile_thumbnails.options__3.label"
          }
        ],
        "default": "hide",
        "label": "t:sections.main-product.settings.mobile_thumbnails.label"
      },
      {
        "type": "checkbox",
        "id": "hide_variants",
        "default": false,
        "label": "t:sections.main-product.settings.hide_variants.label"
      },
      {
        "type": "checkbox",
        "id": "enable_video_looping",
        "default": false,
        "label": "t:sections.main-product.settings.enable_video_looping.label"
      },
      {
        "type": "header",
        "content": "t:sections.all.padding.section_padding_heading"
      },
      {
        "type": "range",
        "id": "padding_top",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_top",
        "default": 36
      },
      {
        "type": "range",
        "id": "padding_bottom",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_bottom",
        "default": 36
      }
    ],
    "presets": [
      {
        "name": "t:sections.main-product.presets.name",
        "blocks": [
          {
            "type": "text"
          },
          {
            "type": "collapsible-row"
          },
          {
            "type": "star-ratings"
          },
          {
            "type": "custom-liquid"
          }
        ]
      }
    ]
  },
  "related-products":{
    "name": "t:sections.related-products.name",
    "tag": "section",
    "class": "section",
    "settings": [
      {
        "type": "paragraph",
        "content": "t:sections.related-products.settings.paragraph__1.content"
      },
      {
        "type": "inline_richtext",
        "id": "heading",
        "default": "You may also like",
        "label": "t:sections.related-products.settings.heading.label"
      },
      {
        "type": "select",
        "id": "heading_size",
        "options": [
          {
            "value": "h2",
            "label": "t:sections.all.heading_size.options__1.label"
          },
          {
            "value": "h1",
            "label": "t:sections.all.heading_size.options__2.label"
          },
          {
            "value": "h0",
            "label": "t:sections.all.heading_size.options__3.label"
          }
        ],
        "default": "h1",
        "label": "t:sections.all.heading_size.label"
      },
      {
        "type": "range",
        "id": "products_to_show",
        "min": 2,
        "max": 10,
        "step": 1,
        "default": 4,
        "label": "t:sections.related-products.settings.products_to_show.label"
      },
      {
        "type": "range",
        "id": "columns_desktop",
        "min": 1,
        "max": 5,
        "step": 1,
        "default": 4,
        "label": "t:sections.related-products.settings.columns_desktop.label"
      },
      {
        "type": "color_scheme",
        "id": "color_scheme",
        "label": "t:sections.all.colors.label",
        "info": "t:sections.image-banner.settings.color_scheme.info",
        "default": "background-1"
      },
      {
        "type": "header",
        "content": "t:sections.related-products.settings.header__2.content"
      },
      {
        "type": "select",
        "id": "image_ratio",
        "options": [
          {
            "value": "adapt",
            "label": "t:sections.related-products.settings.image_ratio.options__1.label"
          },
          {
            "value": "portrait",
            "label": "t:sections.related-products.settings.image_ratio.options__2.label"
          },
          {
            "value": "square",
            "label": "t:sections.related-products.settings.image_ratio.options__3.label"
          }
        ],
        "default": "adapt",
        "label": "t:sections.related-products.settings.image_ratio.label"
      },
      {
        "type": "select",
        "id": "image_shape",
        "options": [
          {
            "value": "default",
            "label": "t:sections.all.image_shape.options__1.label"
          },
          {
            "value": "arch",
            "label": "t:sections.all.image_shape.options__2.label"
          },
          {
            "value": "blob",
            "label": "t:sections.all.image_shape.options__3.label"
          },
          {
            "value": "chevronleft",
            "label": "t:sections.all.image_shape.options__4.label"
          },
          {
            "value": "chevronright",
            "label": "t:sections.all.image_shape.options__5.label"
          },
          {
            "value": "diamond",
            "label": "t:sections.all.image_shape.options__6.label"
          },
          {
            "value": "parallelogram",
            "label": "t:sections.all.image_shape.options__7.label"
          },
          {
            "value": "round",
            "label": "t:sections.all.image_shape.options__8.label"
          }
        ],
        "default": "default",
        "label": "t:sections.all.image_shape.label",
        "info": "t:sections.all.image_shape.info"
      },
      {
        "type": "checkbox",
        "id": "show_secondary_image",
        "default": false,
        "label": "t:sections.related-products.settings.show_secondary_image.label"
      },
      {
        "type": "checkbox",
        "id": "show_vendor",
        "default": false,
        "label": "t:sections.related-products.settings.show_vendor.label"
      },
      {
        "type": "checkbox",
        "id": "show_rating",
        "default": false,
        "label": "t:sections.related-products.settings.show_rating.label",
        "info": "t:sections.related-products.settings.show_rating.info"
      },
      {
        "type": "header",
        "content": "t:sections.related-products.settings.header_mobile.content"
      },
      {
        "type": "select",
        "id": "columns_mobile",
        "default": "2",
        "label": "t:sections.related-products.settings.columns_mobile.label",
        "options": [
          {
            "value": "1",
            "label": "t:sections.related-products.settings.columns_mobile.options__1.label"
          },
          {
            "value": "2",
            "label": "t:sections.related-products.settings.columns_mobile.options__2.label"
          }
        ]
      },
      {
        "type": "header",
        "content": "t:sections.all.padding.section_padding_heading"
      },
      {
        "type": "range",
        "id": "padding_top",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_top",
        "default": 36
      },
      {
        "type": "range",
        "id": "padding_bottom",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_bottom",
        "default": 36
      }
    ]
  },
  "main-collection-product-grid":{
    "name": "t:sections.main-collection-product-grid.name",
    "class": "section",
    "settings": [
      {
        "type": "range",
        "id": "products_per_page",
        "min": 8,
        "max": 24,
        "step": 4,
        "default": 16,
        "label": "t:sections.main-collection-product-grid.settings.products_per_page.label"
      },
      {
        "type": "range",
        "id": "columns_desktop",
        "min": 1,
        "max": 5,
        "step": 1,
        "default": 4,
        "label": "t:sections.main-collection-product-grid.settings.columns_desktop.label"
      },
      {
        "type": "header",
        "content": "t:sections.main-collection-product-grid.settings.header__3.content"
      },
      {
        "type": "select",
        "id": "image_ratio",
        "options": [
          {
            "value": "adapt",
            "label": "t:sections.main-collection-product-grid.settings.image_ratio.options__1.label"
          },
          {
            "value": "portrait",
            "label": "t:sections.main-collection-product-grid.settings.image_ratio.options__2.label"
          },
          {
            "value": "square",
            "label": "t:sections.main-collection-product-grid.settings.image_ratio.options__3.label"
          }
        ],
        "default": "adapt",
        "label": "t:sections.main-collection-product-grid.settings.image_ratio.label"
      },
      {
        "type": "select",
        "id": "image_shape",
        "options": [
          {
            "value": "default",
            "label": "t:sections.all.image_shape.options__1.label"
          },
          {
            "value": "arch",
            "label": "t:sections.all.image_shape.options__2.label"
          },
          {
            "value": "blob",
            "label": "t:sections.all.image_shape.options__3.label"
          },
          {
            "value": "chevronleft",
            "label": "t:sections.all.image_shape.options__4.label"
          },
          {
            "value": "chevronright",
            "label": "t:sections.all.image_shape.options__5.label"
          },
          {
            "value": "diamond",
            "label": "t:sections.all.image_shape.options__6.label"
          },
          {
            "value": "parallelogram",
            "label": "t:sections.all.image_shape.options__7.label"
          },
          {
            "value": "round",
            "label": "t:sections.all.image_shape.options__8.label"
          }
        ],
        "default": "default",
        "label": "t:sections.all.image_shape.label",
        "info": "t:sections.all.image_shape.info"
      },
      {
        "type": "checkbox",
        "id": "show_secondary_image",
        "default": false,
        "label": "t:sections.main-collection-product-grid.settings.show_secondary_image.label"
      },
      {
        "type": "checkbox",
        "id": "show_vendor",
        "default": false,
        "label": "t:sections.main-collection-product-grid.settings.show_vendor.label"
      },
      {
        "type": "checkbox",
        "id": "show_rating",
        "default": false,
        "label": "t:sections.main-collection-product-grid.settings.show_rating.label",
        "info": "t:sections.main-collection-product-grid.settings.show_rating.info"
      },
      {
        "type": "checkbox",
        "id": "enable_quick_add",
        "default": false,
        "label": "t:sections.main-collection-product-grid.settings.enable_quick_buy.label"
      },
      {
        "type": "header",
        "content": "t:sections.main-collection-product-grid.settings.header__1.content"
      },
      {
        "type": "checkbox",
        "id": "enable_filtering",
        "default": true,
        "label": "t:sections.main-collection-product-grid.settings.enable_filtering.label",
        "info": "t:sections.main-collection-product-grid.settings.enable_filtering.info"
      },
      {
        "type": "select",
        "id": "filter_type",
        "options": [
          {
            "value": "horizontal",
            "label": "t:sections.main-collection-product-grid.settings.filter_type.options__1.label"
          },
          {
            "value": "vertical",
            "label": "t:sections.main-collection-product-grid.settings.filter_type.options__2.label"
          },
          {
            "value": "drawer",
            "label": "t:sections.main-collection-product-grid.settings.filter_type.options__3.label"
          }
        ],
        "default": "horizontal",
        "label": "t:sections.main-collection-product-grid.settings.filter_type.label",
        "info": "t:sections.main-collection-product-grid.settings.filter_type.info"
      },
      {
        "type": "checkbox",
        "id": "enable_sorting",
        "default": true,
        "label": "t:sections.main-collection-product-grid.settings.enable_sorting.label"
      },
      {
        "type": "header",
        "content": "t:sections.main-collection-product-grid.settings.header_mobile.content"
      },
      {
        "type": "select",
        "id": "columns_mobile",
        "default": "2",
        "label": "t:sections.main-collection-product-grid.settings.columns_mobile.label",
        "options": [
          {
            "value": "1",
            "label": "t:sections.main-collection-product-grid.settings.columns_mobile.options__1.label"
          },
          {
            "value": "2",
            "label": "t:sections.main-collection-product-grid.settings.columns_mobile.options__2.label"
          }
        ]
      },
      {
        "type": "header",
        "content": "t:sections.all.padding.section_padding_heading"
      },
      {
        "type": "range",
        "id": "padding_top",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_top",
        "default": 36
      },
      {
        "type": "range",
        "id": "padding_bottom",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_bottom",
        "default": 36
      }
    ]
  },
  "main-collection-banner":{
    "name": "t:sections.main-collection-banner.name",
    "class": "section",
    "settings": [
      {
        "type": "paragraph",
        "content": "t:sections.main-collection-banner.settings.paragraph.content"
      },
      {
        "type": "checkbox",
        "id": "show_collection_description",
        "default": true,
        "label": "t:sections.main-collection-banner.settings.show_collection_description.label"
      },
      {
        "type": "checkbox",
        "id": "show_collection_image",
        "default": false,
        "label": "t:sections.main-collection-banner.settings.show_collection_image.label",
        "info": "t:sections.main-collection-banner.settings.show_collection_image.info"
      },
      {
        "type": "color_scheme",
        "id": "color_scheme",
        "label": "t:sections.all.colors.label",
        "default": "background-1"
      }
    ]
  },
  "main-list-collections":{
    "name": "t:sections.main-list-collections.name",
    "class": "section",
    "settings": [
      {
        "type": "inline_richtext",
        "id": "title",
        "label": "t:sections.main-list-collections.settings.title.label",
        "default": "Collections"
      },
      {
        "type": "select",
        "id": "sort",
        "options": [
          {
            "value": "alphabetical",
            "label": "t:sections.main-list-collections.settings.sort.options__1.label"
          },
          {
            "value": "alphabetical_reversed",
            "label": "t:sections.main-list-collections.settings.sort.options__2.label"
          },
          {
            "value": "date_reversed",
            "label": "t:sections.main-list-collections.settings.sort.options__3.label"
          },
          {
            "value": "date",
            "label": "t:sections.main-list-collections.settings.sort.options__4.label"
          },
          {
            "value": "products_high",
            "label": "t:sections.main-list-collections.settings.sort.options__5.label"
          },
          {
            "value": "products_low",
            "label": "t:sections.main-list-collections.settings.sort.options__6.label"
          }
        ],
        "default": "alphabetical",
        "label": "t:sections.main-list-collections.settings.sort.label"
      },
      {
        "type": "select",
        "id": "image_ratio",
        "options": [
          {
            "value": "adapt",
            "label": "t:sections.main-list-collections.settings.image_ratio.options__1.label"
          },
          {
            "value": "portrait",
            "label": "t:sections.main-list-collections.settings.image_ratio.options__2.label"
          },
          {
            "value": "square",
            "label": "t:sections.main-list-collections.settings.image_ratio.options__3.label"
          }
        ],
        "default": "adapt",
        "label": "t:sections.main-list-collections.settings.image_ratio.label",
        "info": "t:sections.main-list-collections.settings.image_ratio.info"
      },
      {
        "type": "range",
        "id": "columns_desktop",
        "min": 1,
        "max": 5,
        "step": 1,
        "default": 3,
        "label": "t:sections.main-list-collections.settings.columns_desktop.label"
      },
      {
        "type": "header",
        "content": "t:sections.main-list-collections.settings.header_mobile.content"
      },
      {
        "type": "select",
        "id": "columns_mobile",
        "options": [
          {
            "value": "1",
            "label": "t:sections.main-list-collections.settings.columns_mobile.options__1.label"
          },
          {
            "value": "2",
            "label": "t:sections.main-list-collections.settings.columns_mobile.options__2.label"
          }
        ],
        "default": "2",
        "label": "t:sections.main-list-collections.settings.columns_mobile.label"
      }
    ]
  },
  "main-blog":{
    "name": "t:sections.main-blog.name",
    "tag": "section",
    "class": "section",
    "settings": [
      {
        "type": "header",
        "content": "t:sections.main-blog.settings.header.content"
      },
      {
        "type": "select",
        "id": "layout",
        "options": [
          {
            "value": "grid",
            "label": "t:sections.main-blog.settings.layout.options__1.label"
          },
          {
            "value": "collage",
            "label": "t:sections.main-blog.settings.layout.options__2.label"
          }
        ],
        "default": "collage",
        "label": "t:sections.main-blog.settings.layout.label",
        "info": "t:sections.main-blog.settings.layout.info"
      },
      {
        "type": "checkbox",
        "id": "show_image",
        "default": true,
        "label": "t:sections.main-blog.settings.show_image.label"
      },
      {
        "type": "select",
        "id": "image_height",
        "options": [
          {
            "value": "adapt",
            "label": "t:sections.main-blog.settings.image_height.options__1.label"
          },
          {
            "value": "small",
            "label": "t:sections.main-blog.settings.image_height.options__2.label"
          },
          {
            "value": "medium",
            "label": "t:sections.main-blog.settings.image_height.options__3.label"
          },
          {
            "value": "large",
            "label": "t:sections.main-blog.settings.image_height.options__4.label"
          }
        ],
        "default": "medium",
        "label": "t:sections.main-blog.settings.image_height.label",
        "info": "t:sections.main-blog.settings.image_height.info"
      },
      {
        "type": "checkbox",
        "id": "show_date",
        "default": true,
        "label": "t:sections.main-blog.settings.show_date.label"
      },
      {
        "type": "checkbox",
        "id": "show_author",
        "default": false,
        "label": "t:sections.main-blog.settings.show_author.label"
      },
      {
        "type": "paragraph",
        "content": "t:sections.main-blog.settings.paragraph.content"
      },
      {
        "type": "header",
        "content": "t:sections.all.padding.section_padding_heading"
      },
      {
        "type": "range",
        "id": "padding_top",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_top",
        "default": 36
      },
      {
        "type": "range",
        "id": "padding_bottom",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_bottom",
        "default": 36
      }
    ]
  },
  "main-article":{
    "name": "t:sections.main-article.name",
    "tag": "section",
    "class": "section",
    "blocks": [
      {
        "type": "@app"
      },
      {
        "type": "featured_image",
        "name": "t:sections.main-article.blocks.featured_image.name",
        "limit": 1,
        "settings": [
          {
            "type": "select",
            "id": "image_height",
            "options": [
              {
                "value": "adapt",
                "label": "t:sections.main-article.blocks.featured_image.settings.image_height.options__1.label"
              },
              {
                "value": "small",
                "label": "t:sections.main-article.blocks.featured_image.settings.image_height.options__2.label"
              },
              {
                "value": "medium",
                "label": "t:sections.main-article.blocks.featured_image.settings.image_height.options__3.label"
              },
              {
                "value": "large",
                "label": "t:sections.main-article.blocks.featured_image.settings.image_height.options__4.label"
              }
            ],
            "default": "adapt",
            "label": "t:sections.main-article.blocks.featured_image.settings.image_height.label",
            "info": "t:sections.main-article.blocks.featured_image.settings.image_height.info"
          }
        ]
      },
      {
        "type": "title",
        "name": "t:sections.main-article.blocks.title.name",
        "limit": 1,
        "settings": [
          {
            "type": "checkbox",
            "id": "blog_show_date",
            "default": true,
            "label": "t:sections.main-article.blocks.title.settings.blog_show_date.label"
          },
          {
            "type": "checkbox",
            "id": "blog_show_author",
            "default": false,
            "label": "t:sections.main-article.blocks.title.settings.blog_show_author.label"
          }
        ]
      },
      {
        "type": "content",
        "name": "t:sections.main-article.blocks.content.name",
        "limit": 1
      },
      {
        "type": "share",
        "name": "t:sections.main-article.blocks.share.name",
        "limit": 2,
        "settings": [
          {
            "type": "text",
            "id": "share_label",
            "label": "t:sections.main-article.blocks.share.settings.text.label",
            "default": "Share"
          },
          {
            "type": "paragraph",
            "content": "t:sections.main-article.blocks.share.settings.featured_image_info.content"
          },
          {
            "type": "paragraph",
            "content": "t:sections.main-article.blocks.share.settings.title_info.content"
          }
        ]
      }
    ]
  },
  "featured-product":{
    "name": "t:sections.featured-product.name",
    "tag": "section",
    "class": "section section-featured-product",
    "disabled_on": {
      "groups": ["header", "footer"]
    },
    "blocks": [
      {
        "type": "@app"
      },
      {
        "type": "text",
        "name": "t:sections.featured-product.blocks.text.name",
        "settings": [
          {
            "type": "inline_richtext",
            "id": "text",
            "default": "Text block",
            "label": "t:sections.featured-product.blocks.text.settings.text.label"
          },
          {
            "type": "select",
            "id": "text_style",
            "options": [
              {
                "value": "body",
                "label": "t:sections.featured-product.blocks.text.settings.text_style.options__1.label"
              },
              {
                "value": "subtitle",
                "label": "t:sections.featured-product.blocks.text.settings.text_style.options__2.label"
              },
              {
                "value": "uppercase",
                "label": "t:sections.featured-product.blocks.text.settings.text_style.options__3.label"
              }
            ],
            "default": "body",
            "label": "t:sections.featured-product.blocks.text.settings.text_style.label"
          }
        ]
      },
      {
        "type": "title",
        "name": "t:sections.featured-product.blocks.title.name",
        "limit": 1,
        "settings": [
          {
            "type": "select",
            "id": "heading_size",
            "options": [
              {
                "value": "h2",
                "label": "t:sections.all.heading_size.options__1.label"
              },
              {
                "value": "h1",
                "label": "t:sections.all.heading_size.options__2.label"
              },
              {
                "value": "h0",
                "label": "t:sections.all.heading_size.options__3.label"
              }
            ],
            "default": "h1",
            "label": "t:sections.all.heading_size.label"
          }
        ]
      },
      {
        "type": "price",
        "name": "t:sections.featured-product.blocks.price.name",
        "limit": 1
      },
      {
        "type": "sku",
        "name": "t:sections.featured-product.blocks.sku.name",
        "limit": 1,
        "settings": [
          {
            "type": "select",
            "id": "text_style",
            "options": [
              {
                "value": "body",
                "label": "t:sections.featured-product.blocks.sku.settings.text_style.options__1.label"
              },
              {
                "value": "subtitle",
                "label": "t:sections.featured-product.blocks.sku.settings.text_style.options__2.label"
              },
              {
                "value": "uppercase",
                "label": "t:sections.featured-product.blocks.sku.settings.text_style.options__3.label"
              }
            ],
            "default": "body",
            "label": "t:sections.featured-product.blocks.sku.settings.text_style.label"
          }
        ]
      },
      {
        "type": "quantity_selector",
        "name": "t:sections.featured-product.blocks.quantity_selector.name",
        "limit": 1
      },
      {
        "type": "variant_picker",
        "name": "t:sections.featured-product.blocks.variant_picker.name",
        "limit": 1,
        "settings": [
          {
            "type": "select",
            "id": "picker_type",
            "options": [
              {
                "value": "dropdown",
                "label": "t:sections.featured-product.blocks.variant_picker.settings.picker_type.options__1.label"
              },
              {
                "value": "button",
                "label": "t:sections.featured-product.blocks.variant_picker.settings.picker_type.options__2.label"
              }
            ],
            "default": "button",
            "label": "t:sections.featured-product.blocks.variant_picker.settings.picker_type.label"
          }
        ]
      },
      {
        "type": "buy_buttons",
        "name": "t:sections.featured-product.blocks.buy_buttons.name",
        "limit": 1,
        "settings": [
          {
            "type": "checkbox",
            "id": "show_dynamic_checkout",
            "default": true,
            "label": "t:sections.featured-product.blocks.buy_buttons.settings.show_dynamic_checkout.label",
            "info": "t:sections.featured-product.blocks.buy_buttons.settings.show_dynamic_checkout.info"
          }
        ]
      },
      {
        "type": "share",
        "name": "t:sections.featured-product.blocks.share.name",
        "limit": 1,
        "settings": [
          {
            "type": "text",
            "id": "share_label",
            "label": "t:sections.featured-product.blocks.share.settings.text.label",
            "default": "Share"
          },
          {
            "type": "paragraph",
            "content": "t:sections.featured-product.blocks.share.settings.featured_image_info.content"
          },
          {
            "type": "paragraph",
            "content": "t:sections.featured-product.blocks.share.settings.title_info.content"
          }
        ]
      },
      {
        "type": "custom_liquid",
        "name": "t:sections.featured-product.blocks.custom_liquid.name",
        "settings": [
          {
            "type": "liquid",
            "id": "custom_liquid",
            "label": "t:sections.featured-product.blocks.custom_liquid.settings.custom_liquid.label"
          }
        ]
      },
      {
        "type": "rating",
        "name": "t:sections.featured-product.blocks.rating.name",
        "limit": 1,
        "settings": [
          {
            "type": "paragraph",
            "content": "t:sections.featured-product.blocks.rating.settings.paragraph.content"
          }
        ]
      },
      {
        "type": "icon-with-text",
        "name": "t:sections.main-product.blocks.icon_with_text.name",
        "settings": [
          {
            "type": "select",
            "id": "layout",
            "options": [
              {
                "value": "horizontal",
                "label": "t:sections.main-product.blocks.icon_with_text.settings.layout.options__1.label"
              },
              {
                "value": "vertical",
                "label": "t:sections.main-product.blocks.icon_with_text.settings.layout.options__2.label"
              }
            ],
            "default": "horizontal",
            "label": "t:sections.main-product.blocks.icon_with_text.settings.layout.label"
          },
          {
            "type": "header",
            "content": "t:sections.main-product.blocks.icon_with_text.settings.content.label",
            "info": "t:sections.main-product.blocks.icon_with_text.settings.content.info"
          },
          {
            "type": "select",
            "id": "icon_1",
            "options": [
              {
                "value": "none",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__1.label"
              },
              {
                "value": "apple",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__2.label"
              },
              {
                "value": "banana",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__3.label"
              },
              {
                "value": "bottle",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__4.label"
              },
              {
                "value": "box",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__5.label"
              },
              {
                "value": "carrot",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__6.label"
              },
              {
                "value": "chat_bubble",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__7.label"
              },
              {
                "value": "check_mark",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__8.label"
              },
              {
                "value": "clipboard",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__9.label"
              },
              {
                "value": "dairy",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__10.label"
              },
              {
                "value": "dairy_free",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__11.label"
              },
              {
                "value": "dryer",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__12.label"
              },
              {
                "value": "eye",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__13.label"
              },
              {
                "value": "fire",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__14.label"
              },
              {
                "value": "gluten_free",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__15.label"
              },
              {
                "value": "heart",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__16.label"
              },
              {
                "value": "iron",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__17.label"
              },
              {
                "value": "leaf",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__18.label"
              },
              {
                "value": "leather",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__19.label"
              },
              {
                "value": "lightning_bolt",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__20.label"
              },
              {
                "value": "lipstick",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__21.label"
              },
              {
                "value": "lock",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__22.label"
              },
              {
                "value": "map_pin",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__23.label"
              },
              {
                "value": "nut_free",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__24.label"
              },
              {
                "value": "pants",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__25.label"
              },
              {
                "value": "paw_print",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__26.label"
              },
              {
                "value": "pepper",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__27.label"
              },
              {
                "value": "perfume",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__28.label"
              },
              {
                "value": "plane",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__29.label"
              },
              {
                "value": "plant",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__30.label"
              },
              {
                "value": "price_tag",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__31.label"
              },
              {
                "value": "question_mark",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__32.label"
              },
              {
                "value": "recycle",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__33.label"
              },
              {
                "value": "return",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__34.label"
              },
              {
                "value": "ruler",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__35.label"
              },
              {
                "value": "serving_dish",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__36.label"
              },
              {
                "value": "shirt",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__37.label"
              },
              {
                "value": "shoe",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__38.label"
              },
              {
                "value": "silhouette",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__39.label"
              },
              {
                "value": "snowflake",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__40.label"
              },
              {
                "value": "star",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__41.label"
              },
              {
                "value": "stopwatch",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__42.label"
              },
              {
                "value": "truck",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__43.label"
              },
              {
                "value": "washing",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__44.label"
              }
            ],
            "default": "heart",
            "label": "t:sections.main-product.blocks.icon_with_text.settings.icon_1.label"
          },
          {
            "type": "image_picker",
            "id": "image_1",
            "label": "t:sections.main-product.blocks.icon_with_text.settings.image_1.label"
          },
          {
            "type": "inline_richtext",
            "id": "heading_1",
            "default": "Heading",
            "label": "t:sections.main-product.blocks.icon_with_text.settings.heading_1.label",
            "info": "t:sections.main-product.blocks.icon_with_text.settings.heading.info"
          },
          {
            "type": "select",
            "id": "icon_2",
            "options": [
              {
                "value": "none",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__1.label"
              },
              {
                "value": "apple",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__2.label"
              },
              {
                "value": "banana",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__3.label"
              },
              {
                "value": "bottle",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__4.label"
              },
              {
                "value": "box",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__5.label"
              },
              {
                "value": "carrot",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__6.label"
              },
              {
                "value": "chat_bubble",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__7.label"
              },
              {
                "value": "check_mark",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__8.label"
              },
              {
                "value": "clipboard",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__9.label"
              },
              {
                "value": "dairy",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__10.label"
              },
              {
                "value": "dairy_free",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__11.label"
              },
              {
                "value": "dryer",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__12.label"
              },
              {
                "value": "eye",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__13.label"
              },
              {
                "value": "fire",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__14.label"
              },
              {
                "value": "gluten_free",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__15.label"
              },
              {
                "value": "heart",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__16.label"
              },
              {
                "value": "iron",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__17.label"
              },
              {
                "value": "leaf",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__18.label"
              },
              {
                "value": "leather",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__19.label"
              },
              {
                "value": "lightning_bolt",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__20.label"
              },
              {
                "value": "lipstick",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__21.label"
              },
              {
                "value": "lock",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__22.label"
              },
              {
                "value": "map_pin",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__23.label"
              },
              {
                "value": "nut_free",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__24.label"
              },
              {
                "value": "pants",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__25.label"
              },
              {
                "value": "paw_print",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__26.label"
              },
              {
                "value": "pepper",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__27.label"
              },
              {
                "value": "perfume",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__28.label"
              },
              {
                "value": "plane",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__29.label"
              },
              {
                "value": "plant",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__30.label"
              },
              {
                "value": "price_tag",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__31.label"
              },
              {
                "value": "question_mark",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__32.label"
              },
              {
                "value": "recycle",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__33.label"
              },
              {
                "value": "return",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__34.label"
              },
              {
                "value": "ruler",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__35.label"
              },
              {
                "value": "serving_dish",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__36.label"
              },
              {
                "value": "shirt",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__37.label"
              },
              {
                "value": "shoe",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__38.label"
              },
              {
                "value": "silhouette",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__39.label"
              },
              {
                "value": "snowflake",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__40.label"
              },
              {
                "value": "star",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__41.label"
              },
              {
                "value": "stopwatch",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__42.label"
              },
              {
                "value": "truck",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__43.label"
              },
              {
                "value": "washing",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__44.label"
              }
            ],
            "default": "return",
            "label": "t:sections.main-product.blocks.icon_with_text.settings.icon_2.label"
          },
          {
            "type": "image_picker",
            "id": "image_2",
            "label": "t:sections.main-product.blocks.icon_with_text.settings.image_2.label"
          },
          {
            "type": "inline_richtext",
            "id": "heading_2",
            "default": "Heading",
            "label": "t:sections.main-product.blocks.icon_with_text.settings.heading_2.label",
            "info": "t:sections.main-product.blocks.icon_with_text.settings.heading.info"
          },
          {
            "type": "select",
            "id": "icon_3",
            "options": [
              {
                "value": "none",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__1.label"
              },
              {
                "value": "apple",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__2.label"
              },
              {
                "value": "banana",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__3.label"
              },
              {
                "value": "bottle",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__4.label"
              },
              {
                "value": "box",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__5.label"
              },
              {
                "value": "carrot",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__6.label"
              },
              {
                "value": "chat_bubble",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__7.label"
              },
              {
                "value": "check_mark",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__8.label"
              },
              {
                "value": "clipboard",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__9.label"
              },
              {
                "value": "dairy",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__10.label"
              },
              {
                "value": "dairy_free",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__11.label"
              },
              {
                "value": "dryer",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__12.label"
              },
              {
                "value": "eye",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__13.label"
              },
              {
                "value": "fire",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__14.label"
              },
              {
                "value": "gluten_free",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__15.label"
              },
              {
                "value": "heart",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__16.label"
              },
              {
                "value": "iron",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__17.label"
              },
              {
                "value": "leaf",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__18.label"
              },
              {
                "value": "leather",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__19.label"
              },
              {
                "value": "lightning_bolt",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__20.label"
              },
              {
                "value": "lipstick",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__21.label"
              },
              {
                "value": "lock",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__22.label"
              },
              {
                "value": "map_pin",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__23.label"
              },
              {
                "value": "nut_free",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__24.label"
              },
              {
                "value": "pants",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__25.label"
              },
              {
                "value": "paw_print",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__26.label"
              },
              {
                "value": "pepper",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__27.label"
              },
              {
                "value": "perfume",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__28.label"
              },
              {
                "value": "plane",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__29.label"
              },
              {
                "value": "plant",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__30.label"
              },
              {
                "value": "price_tag",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__31.label"
              },
              {
                "value": "question_mark",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__32.label"
              },
              {
                "value": "recycle",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__33.label"
              },
              {
                "value": "return",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__34.label"
              },
              {
                "value": "ruler",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__35.label"
              },
              {
                "value": "serving_dish",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__36.label"
              },
              {
                "value": "shirt",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__37.label"
              },
              {
                "value": "shoe",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__38.label"
              },
              {
                "value": "silhouette",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__39.label"
              },
              {
                "value": "snowflake",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__40.label"
              },
              {
                "value": "star",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__41.label"
              },
              {
                "value": "stopwatch",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__42.label"
              },
              {
                "value": "truck",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__43.label"
              },
              {
                "value": "washing",
                "label": "t:sections.main-product.blocks.collapsible_tab.settings.icon.options__44.label"
              }
            ],
            "default": "truck",
            "label": "t:sections.main-product.blocks.icon_with_text.settings.icon_3.label"
          },
          {
            "type": "image_picker",
            "id": "image_3",
            "label": "t:sections.main-product.blocks.icon_with_text.settings.image_3.label"
          },
          {
            "type": "inline_richtext",
            "id": "heading_3",
            "default": "Heading",
            "label": "t:sections.main-product.blocks.icon_with_text.settings.heading_3.label",
            "info": "t:sections.main-product.blocks.icon_with_text.settings.heading.info"
          }
        ]
      }
    ],
    "settings": [
      {
        "type": "product",
        "id": "product",
        "label": "t:sections.featured-product.settings.product.label"
      },
      {
        "type": "color_scheme",
        "id": "color_scheme",
        "label": "t:sections.all.colors.label",
        "default": "background-1"
      },
      {
        "type": "checkbox",
        "id": "secondary_background",
        "default": false,
        "label": "t:sections.featured-product.settings.secondary_background.label"
      },
      {
        "type": "header",
        "content": "t:sections.featured-product.settings.header.content",
        "info": "t:sections.featured-product.settings.header.info"
      },
      {
        "type": "select",
        "id": "media_size",
        "options": [
          {
            "value": "small",
            "label": "t:sections.main-product.settings.media_size.options__1.label"
          },
          {
            "value": "medium",
            "label": "t:sections.main-product.settings.media_size.options__2.label"
          },
          {
            "value": "large",
            "label": "t:sections.main-product.settings.media_size.options__3.label"
          }
        ],
        "default": "medium",
        "label": "t:sections.main-product.settings.media_size.label",
        "info": "t:sections.main-product.settings.media_size.info"
      },
      {
        "type": "checkbox",
        "id": "constrain_to_viewport",
        "default": true,
        "label": "t:sections.main-product.settings.constrain_to_viewport.label"
      },
      {
        "type": "select",
        "id": "media_fit",
        "options": [
          {
            "value": "contain",
            "label": "t:sections.main-product.settings.media_fit.options__1.label"
          },
          {
            "value": "cover",
            "label": "t:sections.main-product.settings.media_fit.options__2.label"
          }
        ],
        "default": "contain",
        "label": "t:sections.main-product.settings.media_fit.label"
      },
      {
        "type": "select",
        "id": "media_position",
        "options": [
          {
            "value": "left",
            "label": "t:sections.featured-product.settings.media_position.options__1.label"
          },
          {
            "value": "right",
            "label": "t:sections.featured-product.settings.media_position.options__2.label"
          }
        ],
        "default": "left",
        "label": "t:sections.featured-product.settings.media_position.label",
        "info": "t:sections.featured-product.settings.media_position.info"
      },
      {
        "type": "select",
        "id": "image_zoom",
        "options": [
          {
            "value": "lightbox",
            "label": "t:sections.main-product.settings.image_zoom.options__1.label"
          },
          {
            "value": "hover",
            "label": "t:sections.main-product.settings.image_zoom.options__2.label"
          },
          {
            "value": "none",
            "label": "t:sections.main-product.settings.image_zoom.options__3.label"
          }
        ],
        "default": "lightbox",
        "label": "t:sections.main-product.settings.image_zoom.label",
        "info": "t:sections.main-product.settings.image_zoom.info"
      },
      {
        "type": "checkbox",
        "id": "hide_variants",
        "default": false,
        "label": "t:sections.main-product.settings.hide_variants.label"
      },
      {
        "type": "checkbox",
        "id": "enable_video_looping",
        "default": false,
        "label": "t:sections.featured-product.settings.enable_video_looping.label"
      },
      {
        "type": "header",
        "content": "t:sections.all.padding.section_padding_heading"
      },
      {
        "type": "range",
        "id": "padding_top",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_top",
        "default": 36
      },
      {
        "type": "range",
        "id": "padding_bottom",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_bottom",
        "default": 36
      }
    ],
    "presets": [
      {
        "name": "t:sections.featured-product.presets.name",
        "blocks": [
          {
            "type": "text",
            "settings": {
              "text": "{{ section.settings.product.vendor }}",
              "text_style": "uppercase"
            }
          },
          {
            "type": "title"
          },
          {
            "type": "price"
          },
          {
            "type": "variant_picker"
          },
          {
            "type": "quantity_selector"
          },
          {
            "type": "buy_buttons"
          },
          {
            "type": "share"
          }
        ]
      }
    ]
  },
  "image-with-text":{
    "name": "t:sections.image-with-text.name",
    "class": "section",
    "disabled_on": {
      "groups": ["header", "footer"]
    },
    "settings": [
      {
        "type": "image_picker",
        "id": "image",
        "label": "t:sections.image-with-text.settings.image.label"
      },
      {
        "type": "select",
        "id": "height",
        "options": [
          {
            "value": "adapt",
            "label": "t:sections.image-with-text.settings.height.options__1.label"
          },
          {
            "value": "small",
            "label": "t:sections.image-with-text.settings.height.options__2.label"
          },
          {
            "value": "medium",
            "label": "t:sections.image-with-text.settings.height.options__3.label"
          },
          {
            "value": "large",
            "label": "t:sections.image-with-text.settings.height.options__4.label"
          }
        ],
        "default": "adapt",
        "label": "t:sections.image-with-text.settings.height.label"
      },
      {
        "type": "select",
        "id": "desktop_image_width",
        "options": [
          {
            "value": "small",
            "label": "t:sections.image-with-text.settings.desktop_image_width.options__1.label"
          },
          {
            "value": "medium",
            "label": "t:sections.image-with-text.settings.desktop_image_width.options__2.label"
          },
          {
            "value": "large",
            "label": "t:sections.image-with-text.settings.desktop_image_width.options__3.label"
          }
        ],
        "default": "medium",
        "label": "t:sections.image-with-text.settings.desktop_image_width.label",
        "info": "t:sections.image-with-text.settings.desktop_image_width.info"
      },
      {
        "type": "select",
        "id": "layout",
        "options": [
          {
            "value": "image_first",
            "label": "t:sections.image-with-text.settings.layout.options__1.label"
          },
          {
            "value": "text_first",
            "label": "t:sections.image-with-text.settings.layout.options__2.label"
          }
        ],
        "default": "image_first",
        "label": "t:sections.image-with-text.settings.layout.label",
        "info": "t:sections.image-with-text.settings.layout.info"
      },
      {
        "type": "select",
        "id": "desktop_content_position",
        "options": [
          {
            "value": "top",
            "label": "t:sections.image-with-text.settings.desktop_content_position.options__1.label"
          },
          {
            "value": "middle",
            "label": "t:sections.image-with-text.settings.desktop_content_position.options__2.label"
          },
          {
            "value": "bottom",
            "label": "t:sections.image-with-text.settings.desktop_content_position.options__3.label"
          }
        ],
        "default": "top",
        "label": "t:sections.image-with-text.settings.desktop_content_position.label"
      },
      {
        "type": "select",
        "id": "desktop_content_alignment",
        "options": [
          {
            "value": "left",
            "label": "t:sections.image-with-text.settings.desktop_content_alignment.options__1.label"
          },
          {
            "value": "center",
            "label": "t:sections.image-with-text.settings.desktop_content_alignment.options__2.label"
          },
          {
            "value": "right",
            "label": "t:sections.image-with-text.settings.desktop_content_alignment.options__3.label"
          }
        ],
        "default": "left",
        "label": "t:sections.image-with-text.settings.desktop_content_alignment.label"
      },
      {
        "type": "select",
        "id": "content_layout",
        "options": [
          {
            "value": "no-overlap",
            "label": "t:sections.image-with-text.settings.content_layout.options__1.label"
          },
          {
            "value": "overlap",
            "label": "t:sections.image-with-text.settings.content_layout.options__2.label"
          }
        ],
        "default": "no-overlap",
        "label": "t:sections.image-with-text.settings.content_layout.label"
      },
      {
        "type": "color_scheme",
        "id": "color_scheme",
        "label": "t:sections.all.colors.label",
        "default": "background-1"
      },
      {
        "type": "header",
        "content": "t:sections.all.animation.content"
      },
      {
        "type": "select",
        "id": "image_behavior",
        "options": [
          {
            "value": "none",
            "label": "t:sections.all.animation.image_behavior.options__1.label"
          },
          {
            "value": "ambient",
            "label": "t:sections.all.animation.image_behavior.options__2.label"
          }
        ],
        "default": "none",
        "label": "t:sections.all.animation.image_behavior.label"
      },
      {
        "type": "header",
        "content": "Mobile layout"
      },
      {
        "type": "select",
        "id": "mobile_content_alignment",
        "options": [
          {
            "value": "left",
            "label": "t:sections.image-with-text.settings.mobile_content_alignment.options__1.label"
          },
          {
            "value": "center",
            "label": "t:sections.image-with-text.settings.mobile_content_alignment.options__2.label"
          },
          {
            "value": "right",
            "label": "t:sections.image-with-text.settings.mobile_content_alignment.options__3.label"
          }
        ],
        "default": "left",
        "label": "t:sections.image-with-text.settings.mobile_content_alignment.label"
      },
      {
        "type": "header",
        "content": "t:sections.all.padding.section_padding_heading"
      },
      {
        "type": "range",
        "id": "padding_top",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_top",
        "default": 36
      },
      {
        "type": "range",
        "id": "padding_bottom",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_bottom",
        "default": 36
      }
    ],
    "blocks": [
      {
        "type": "heading",
        "name": "t:sections.image-with-text.blocks.heading.name",
        "limit": 1,
        "settings": [
          {
            "type": "inline_richtext",
            "id": "heading",
            "default": "Image with text",
            "label": "t:sections.image-with-text.blocks.heading.settings.heading.label"
          },
          {
            "type": "select",
            "id": "heading_size",
            "options": [
              {
                "value": "h2",
                "label": "t:sections.all.heading_size.options__1.label"
              },
              {
                "value": "h1",
                "label": "t:sections.all.heading_size.options__2.label"
              },
              {
                "value": "h0",
                "label": "t:sections.all.heading_size.options__3.label"
              }
            ],
            "default": "h1",
            "label": "t:sections.all.heading_size.label"
          }
        ]
      },
      {
        "type": "caption",
        "name": "t:sections.image-with-text.blocks.caption.name",
        "limit": 1,
        "settings": [
          {
            "type": "text",
            "id": "caption",
            "default": "Add a tagline",
            "label": "t:sections.image-with-text.blocks.caption.settings.text.label"
          },
          {
            "type": "select",
            "id": "text_style",
            "options": [
              {
                "value": "subtitle",
                "label": "t:sections.image-with-text.blocks.caption.settings.text_style.options__1.label"
              },
              {
                "value": "caption-with-letter-spacing",
                "label": "t:sections.image-with-text.blocks.caption.settings.text_style.options__2.label"
              }
            ],
            "default": "caption-with-letter-spacing",
            "label": "t:sections.image-with-text.blocks.caption.settings.text_style.label"
          },
          {
            "type": "select",
            "id": "text_size",
            "options": [
              {
                "value": "small",
                "label": "t:sections.image-with-text.blocks.caption.settings.caption_size.options__1.label"
              },
              {
                "value": "medium",
                "label": "t:sections.image-with-text.blocks.caption.settings.caption_size.options__2.label"
              },
              {
                "value": "large",
                "label": "t:sections.image-with-text.blocks.caption.settings.caption_size.options__3.label"
              }
            ],
            "default": "medium",
            "label": "t:sections.image-with-text.blocks.caption.settings.caption_size.label"
          }
        ]
      },
      {
        "type": "text",
        "name": "t:sections.image-with-text.blocks.text.name",
        "limit": 1,
        "settings": [
          {
            "type": "richtext",
            "id": "text",
            "default": "<p>Pair text with an image to focus on your chosen product, collection, or blog post. Add details on availability, style, or even provide a review.</p>",
            "label": "t:sections.image-with-text.blocks.text.settings.text.label"
          },
          {
            "type": "select",
            "id": "text_style",
            "options": [
              {
                "value": "body",
                "label": "t:sections.image-with-text.blocks.text.settings.text_style.options__1.label"
              },
              {
                "value": "subtitle",
                "label": "t:sections.image-with-text.blocks.text.settings.text_style.options__2.label"
              }
            ],
            "default": "body",
            "label": "t:sections.image-with-text.blocks.text.settings.text_style.label"
          }
        ]
      },
      {
        "type": "button",
        "name": "t:sections.image-with-text.blocks.button.name",
        "limit": 1,
        "settings": [
          {
            "type": "text",
            "id": "button_label",
            "default": "Button label",
            "label": "t:sections.image-with-text.blocks.button.settings.button_label.label",
            "info": "t:sections.image-with-text.blocks.button.settings.button_label.info"
          },
          {
            "type": "url",
            "id": "button_link",
            "label": "t:sections.image-with-text.blocks.button.settings.button_link.label"
          }
        ]
      }
    ],
    "presets": [
      {
        "name": "t:sections.image-with-text.presets.name",
        "blocks": [
          {
            "type": "heading"
          },
          {
            "type": "text"
          },
          {
            "type": "button"
          },
          {
            "type": "caption"
          }
        ]
      }
    ]
  },
  "slideshow":{
    "name": "t:sections.slideshow.name",
    "tag": "section",
    "class": "section",
    "disabled_on": {
      "groups": ["header", "footer"]
    },
    "settings": [
      {
        "type": "select",
        "id": "layout",
        "options": [
          {
            "value": "full_bleed",
            "label": "t:sections.slideshow.settings.layout.options__1.label"
          },
          {
            "value": "grid",
            "label": "t:sections.slideshow.settings.layout.options__2.label"
          }
        ],
        "default": "full_bleed",
        "label": "t:sections.slideshow.settings.layout.label"
      },
      {
        "type": "select",
        "id": "slide_height",
        "options": [
          {
            "value": "adapt_image",
            "label": "t:sections.slideshow.settings.slide_height.options__1.label"
          },
          {
            "value": "small",
            "label": "t:sections.slideshow.settings.slide_height.options__2.label"
          },
          {
            "value": "medium",
            "label": "t:sections.slideshow.settings.slide_height.options__3.label"
          },
          {
            "value": "large",
            "label": "t:sections.slideshow.settings.slide_height.options__4.label"
          }
        ],
        "default": "medium",
        "label": "t:sections.slideshow.settings.slide_height.label"
      },
      {
        "type": "select",
        "id": "slider_visual",
        "options": [
          {
            "value": "dots",
            "label": "t:sections.slideshow.settings.slider_visual.options__2.label"
          },
          {
            "value": "counter",
            "label": "t:sections.slideshow.settings.slider_visual.options__1.label"
          },
          {
            "value": "numbers",
            "label": "t:sections.slideshow.settings.slider_visual.options__3.label"
          }
        ],
        "default": "counter",
        "label": "t:sections.slideshow.settings.slider_visual.label"
      },
      {
        "type": "checkbox",
        "id": "auto_rotate",
        "label": "t:sections.slideshow.settings.auto_rotate.label",
        "default": false
      },
      {
        "type": "range",
        "id": "change_slides_speed",
        "min": 3,
        "max": 9,
        "step": 2,
        "unit": "s",
        "label": "t:sections.slideshow.settings.change_slides_speed.label",
        "default": 5
      },
      {
        "type": "header",
        "content": "t:sections.all.animation.content"
      },
      {
        "type": "select",
        "id": "image_behavior",
        "options": [
          {
            "value": "none",
            "label": "t:sections.all.animation.image_behavior.options__1.label"
          },
          {
            "value": "ambient",
            "label": "t:sections.all.animation.image_behavior.options__2.label"
          }
        ],
        "default": "none",
        "label": "t:sections.all.animation.image_behavior.label"
      },
      {
        "type": "header",
        "content": "t:sections.slideshow.settings.mobile.content"
      },
      {
        "type": "checkbox",
        "id": "show_text_below",
        "label": "t:sections.slideshow.settings.show_text_below.label",
        "default": true
      },
      {
        "type": "header",
        "content": "t:sections.slideshow.settings.accessibility.content"
      },
      {
        "type": "text",
        "id": "accessibility_info",
        "label": "t:sections.slideshow.settings.accessibility.label",
        "info": "t:sections.slideshow.settings.accessibility.info",
        "default": "Slideshow about our brand"
      }
    ],
    "blocks": [
      {
        "type": "slide",
        "name": "t:sections.slideshow.blocks.slide.name",
        "limit": 5,
        "settings": [
          {
            "type": "image_picker",
            "id": "image",
            "label": "t:sections.slideshow.blocks.slide.settings.image.label"
          },
          {
            "type": "inline_richtext",
            "id": "heading",
            "default": "Image slide",
            "label": "t:sections.slideshow.blocks.slide.settings.heading.label"
          },
          {
            "type": "select",
            "id": "heading_size",
            "options": [
              {
                "value": "h2",
                "label": "t:sections.all.heading_size.options__1.label"
              },
              {
                "value": "h1",
                "label": "t:sections.all.heading_size.options__2.label"
              },
              {
                "value": "h0",
                "label": "t:sections.all.heading_size.options__3.label"
              }
            ],
            "default": "h1",
            "label": "t:sections.all.heading_size.label"
          },
          {
            "type": "inline_richtext",
            "id": "subheading",
            "default": "Tell your brand's story through images",
            "label": "t:sections.slideshow.blocks.slide.settings.subheading.label"
          },
          {
            "type": "text",
            "id": "button_label",
            "default": "Button label",
            "label": "t:sections.slideshow.blocks.slide.settings.button_label.label",
            "info": "t:sections.slideshow.blocks.slide.settings.button_label.info"
          },
          {
            "type": "url",
            "id": "link",
            "label": "t:sections.slideshow.blocks.slide.settings.link.label"
          },
          {
            "type": "checkbox",
            "id": "button_style_secondary",
            "label": "t:sections.slideshow.blocks.slide.settings.secondary_style.label",
            "default": false
          },
          {
            "type": "select",
            "id": "box_align",
            "options": [
              {
                "value": "top-left",
                "label": "t:sections.slideshow.blocks.slide.settings.box_align.options__1.label"
              },
              {
                "value": "top-center",
                "label": "t:sections.slideshow.blocks.slide.settings.box_align.options__2.label"
              },
              {
                "value": "top-right",
                "label": "t:sections.slideshow.blocks.slide.settings.box_align.options__3.label"
              },
              {
                "value": "middle-left",
                "label": "t:sections.slideshow.blocks.slide.settings.box_align.options__4.label"
              },
              {
                "value": "middle-center",
                "label": "t:sections.slideshow.blocks.slide.settings.box_align.options__5.label"
              },
              {
                "value": "middle-right",
                "label": "t:sections.slideshow.blocks.slide.settings.box_align.options__6.label"
              },
              {
                "value": "bottom-left",
                "label": "t:sections.slideshow.blocks.slide.settings.box_align.options__7.label"
              },
              {
                "value": "bottom-center",
                "label": "t:sections.slideshow.blocks.slide.settings.box_align.options__8.label"
              },
              {
                "value": "bottom-right",
                "label": "t:sections.slideshow.blocks.slide.settings.box_align.options__9.label"
              }
            ],
            "default": "middle-center",
            "label": "t:sections.slideshow.blocks.slide.settings.box_align.label",
            "info": "t:sections.slideshow.blocks.slide.settings.box_align.info"
          },
          {
            "type": "checkbox",
            "id": "show_text_box",
            "label": "t:sections.slideshow.blocks.slide.settings.show_text_box.label",
            "default": true
          },
          {
            "type": "select",
            "id": "text_alignment",
            "options": [
              {
                "value": "left",
                "label": "t:sections.slideshow.blocks.slide.settings.text_alignment.option_1.label"
              },
              {
                "value": "center",
                "label": "t:sections.slideshow.blocks.slide.settings.text_alignment.option_2.label"
              },
              {
                "value": "right",
                "label": "t:sections.slideshow.blocks.slide.settings.text_alignment.option_3.label"
              }
            ],
            "default": "center",
            "label": "t:sections.slideshow.blocks.slide.settings.text_alignment.label"
          },
          {
            "type": "range",
            "id": "image_overlay_opacity",
            "min": 0,
            "max": 100,
            "step": 10,
            "unit": "%",
            "label": "t:sections.slideshow.blocks.slide.settings.image_overlay_opacity.label",
            "default": 0
          },
          {
            "type": "color_scheme",
            "id": "color_scheme",
            "label": "t:sections.all.colors.label",
            "default": "background-1"
          },
          {
            "type": "header",
            "content": "t:sections.slideshow.settings.mobile.content"
          },
          {
            "type": "select",
            "id": "text_alignment_mobile",
            "options": [
              {
                "value": "left",
                "label": "t:sections.slideshow.blocks.slide.settings.text_alignment_mobile.options__1.label"
              },
              {
                "value": "center",
                "label": "t:sections.slideshow.blocks.slide.settings.text_alignment_mobile.options__2.label"
              },
              {
                "value": "right",
                "label": "t:sections.slideshow.blocks.slide.settings.text_alignment_mobile.options__3.label"
              }
            ],
            "default": "center",
            "label": "t:sections.slideshow.blocks.slide.settings.text_alignment_mobile.label"
          }
        ]
      }
    ],
    "presets": [
      {
        "name": "t:sections.slideshow.presets.name",
        "blocks": [
          {
            "type": "slide"
          },
          {
            "type": "slide"
          }
        ]
      }
    ]
  },
  "multirow":{
    "name": "t:sections.multirow.name",
    "class": "section",
    "disabled_on": {
      "groups": ["header", "footer"]
    },
    "settings": [
      {
        "type": "select",
        "id": "image_height",
        "options": [
          {
            "value": "adapt",
            "label": "t:sections.multirow.settings.image_height.options__1.label"
          },
          {
            "value": "small",
            "label": "t:sections.multirow.settings.image_height.options__2.label"
          },
          {
            "value": "medium",
            "label": "t:sections.multirow.settings.image_height.options__3.label"
          },
          {
            "value": "large",
            "label": "t:sections.multirow.settings.image_height.options__4.label"
          }
        ],
        "default": "medium",
        "label": "t:sections.multirow.settings.image_height.label"
      },
      {
        "type": "select",
        "id": "desktop_image_width",
        "options": [
          {
            "value": "small",
            "label": "t:sections.multirow.settings.desktop_image_width.options__1.label"
          },
          {
            "value": "medium",
            "label": "t:sections.multirow.settings.desktop_image_width.options__2.label"
          },
          {
            "value": "large",
            "label": "t:sections.multirow.settings.desktop_image_width.options__3.label"
          }
        ],
        "default": "medium",
        "label": "t:sections.multirow.settings.desktop_image_width.label",
        "info": "t:sections.multirow.settings.desktop_image_width.info"
      },
      {
        "type": "select",
        "id": "heading_size",
        "options": [
          {
            "value": "h2",
            "label": "t:sections.multirow.settings.heading_size.options__1.label"
          },
          {
            "value": "h1",
            "label": "t:sections.multirow.settings.heading_size.options__2.label"
          },
          {
            "value": "h0",
            "label": "t:sections.multirow.settings.heading_size.options__3.label"
          }
        ],
        "default": "h1",
        "label": "t:sections.multirow.settings.heading_size.label"
      },
      {
        "type": "select",
        "id": "text_style",
        "options": [
          {
            "value": "body",
            "label": "t:sections.multirow.settings.text_style.options__1.label"
          },
          {
            "value": "subtitle",
            "label": "t:sections.multirow.settings.text_style.options__2.label"
          }
        ],
        "default": "body",
        "label": "t:sections.multirow.settings.text_style.label"
      },
      {
        "type": "select",
        "id": "button_style",
        "options": [
          {
            "value": "primary",
            "label": "t:sections.multirow.settings.button_style.options__1.label"
          },
          {
            "value": "secondary",
            "label": "t:sections.multirow.settings.button_style.options__2.label"
          }
        ],
        "default": "secondary",
        "label": "t:sections.multirow.settings.button_style.label"
      },
      {
        "type": "select",
        "id": "desktop_content_position",
        "options": [
          {
            "value": "top",
            "label": "t:sections.multirow.settings.desktop_content_position.options__1.label"
          },
          {
            "value": "middle",
            "label": "t:sections.multirow.settings.desktop_content_position.options__2.label"
          },
          {
            "value": "bottom",
            "label": "t:sections.multirow.settings.desktop_content_position.options__3.label"
          }
        ],
        "default": "middle",
        "label": "t:sections.multirow.settings.desktop_content_position.label",
        "info": "t:sections.multirow.settings.desktop_content_position.info"
      },
      {
        "type": "select",
        "id": "desktop_content_alignment",
        "options": [
          {
            "value": "left",
            "label": "t:sections.multirow.settings.desktop_content_alignment.options__1.label"
          },
          {
            "value": "center",
            "label": "t:sections.multirow.settings.desktop_content_alignment.options__2.label"
          },
          {
            "value": "right",
            "label": "t:sections.multirow.settings.desktop_content_alignment.options__3.label"
          }
        ],
        "default": "left",
        "label": "t:sections.multirow.settings.desktop_content_alignment.label"
      },
      {
        "type": "select",
        "id": "image_layout",
        "options": [
          {
            "value": "alternate-left",
            "label": "t:sections.multirow.settings.image_layout.options__1.label"
          },
          {
            "value": "alternate-right",
            "label": "t:sections.multirow.settings.image_layout.options__2.label"
          },
          {
            "value": "align-left",
            "label": "t:sections.multirow.settings.image_layout.options__3.label"
          },
          {
            "value": "align-right",
            "label": "t:sections.multirow.settings.image_layout.options__4.label"
          }
        ],
        "default": "alternate-left",
        "label": "t:sections.multirow.settings.image_layout.label",
        "info": "t:sections.multirow.settings.image_layout.info"
      },
      {
        "type": "color_scheme",
        "id": "section_color_scheme",
        "label": "t:sections.all.colors.label",
        "default": "background-1"
      },
      {
        "type": "color_scheme",
        "id": "row_color_scheme",
        "label": "t:sections.multirow.settings.container_color_scheme.label",
        "default": "background-1"
      },
      {
        "type": "header",
        "content": "t:sections.multirow.settings.header_mobile.content"
      },
      {
        "type": "select",
        "id": "mobile_content_alignment",
        "options": [
          {
            "value": "left",
            "label": "t:sections.multirow.settings.mobile_content_alignment.options__1.label"
          },
          {
            "value": "center",
            "label": "t:sections.multirow.settings.mobile_content_alignment.options__2.label"
          },
          {
            "value": "right",
            "label": "t:sections.multirow.settings.mobile_content_alignment.options__3.label"
          }
        ],
        "default": "left",
        "label": "t:sections.multirow.settings.mobile_content_alignment.label"
      },
      {
        "type": "header",
        "content": "t:sections.all.padding.section_padding_heading"
      },
      {
        "type": "range",
        "id": "padding_top",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_top",
        "default": 36
      },
      {
        "type": "range",
        "id": "padding_bottom",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_bottom",
        "default": 36
      }
    ],
    "blocks": [
      {
        "type": "row",
        "name": "t:sections.multirow.blocks.row.name",
        "settings": [
          {
            "type": "image_picker",
            "id": "image",
            "label": "t:sections.multirow.blocks.row.settings.image.label"
          },
          {
            "type": "text",
            "id": "caption",
            "default": "Caption",
            "label": "t:sections.multirow.blocks.row.settings.caption.label"
          },
          {
            "type": "inline_richtext",
            "id": "heading",
            "default": "Row",
            "label": "t:sections.multirow.blocks.row.settings.heading.label"
          },
          {
            "type": "richtext",
            "id": "text",
            "default": "<p>Pair text with an image to focus on your chosen product, collection, or blog post. Add details on availability, style, or even provide a review.</p>",
            "label": "t:sections.multirow.blocks.row.settings.text.label"
          },
          {
            "type": "text",
            "id": "button_label",
            "default": "Button label",
            "label": "t:sections.multirow.blocks.row.settings.button_label.label"
          },
          {
            "type": "url",
            "id": "button_link",
            "label": "t:sections.multirow.blocks.row.settings.button_link.label"
          }
        ]
      },
      {
        "type": "custom-html",
        "name": "t:sections.multirow.blocks.custom_html.name",
        "settings": [
          {
            "type": "html",
            "id": "html",
            "default": "Enter Html",
            "label": "Enter HTML"
          }
        ]
      }
    ],
    "presets": [
      {
        "name": "t:sections.multirow.presets.name",
        "blocks": [
          {
            "type": "row"
          },
          {
            "type": "row"
          },
          {
            "type": "row"
          }
        ]
      }
    ]
  },
  "collapsible-content":{
    "name": "t:sections.collapsible_content.name",
    "tag": "section",
    "class": "section",
    "disabled_on": {
      "groups": ["header", "footer"]
    },
    "settings": [
      {
        "type": "text",
        "id": "caption",
        "label": "t:sections.collapsible_content.settings.caption.label"
      },
      {
        "type": "inline_richtext",
        "id": "heading",
        "label": "t:sections.collapsible_content.settings.heading.label",
        "default": "Collapsible content"
      },
      {
        "type": "select",
        "id": "heading_size",
        "options": [
          {
            "value": "h2",
            "label": "t:sections.all.heading_size.options__1.label"
          },
          {
            "value": "h1",
            "label": "t:sections.all.heading_size.options__2.label"
          },
          {
            "value": "h0",
            "label": "t:sections.all.heading_size.options__3.label"
          }
        ],
        "default": "h1",
        "label": "t:sections.all.heading_size.label"
      },
      {
        "type": "select",
        "id": "heading_alignment",
        "label": "t:sections.collapsible_content.settings.heading_alignment.label",
        "options": [
          {
            "value": "left",
            "label": "t:sections.collapsible_content.settings.heading_alignment.options__1.label"
          },
          {
            "value": "center",
            "label": "t:sections.collapsible_content.settings.heading_alignment.options__2.label"
          },
          {
            "value": "right",
            "label": "t:sections.collapsible_content.settings.heading_alignment.options__3.label"
          }
        ],
        "default": "center"
      },
      {
        "type": "select",
        "id": "layout",
        "label": "t:sections.collapsible_content.settings.layout.label",
        "options": [
          {
            "value": "none",
            "label": "t:sections.collapsible_content.settings.layout.options__1.label"
          },
          {
            "value": "row",
            "label": "t:sections.collapsible_content.settings.layout.options__2.label"
          },
          {
            "value": "section",
            "label": "t:sections.collapsible_content.settings.layout.options__3.label"
          }
        ],
        "default": "none"
      },
      {
        "type": "color_scheme",
        "id": "color_scheme",
        "label": "t:sections.all.colors.label",
        "default": "background-1"
      },
      {
        "type": "color_scheme",
        "id": "container_color_scheme",
        "label": "t:sections.collapsible_content.settings.container_color_scheme.label",
        "info": "t:sections.collapsible_content.settings.container_color_scheme.info",
        "default": "background-2"
      },
      {
        "type": "checkbox",
        "id": "open_first_collapsible_row",
        "default": false,
        "label": "t:sections.collapsible_content.settings.open_first_collapsible_row.label"
      },
      {
        "type": "header",
        "content": "t:sections.collapsible_content.settings.header.content"
      },
      {
        "type": "image_picker",
        "id": "image",
        "label": "t:sections.collapsible_content.settings.image.label"
      },
      {
        "type": "select",
        "id": "image_ratio",
        "options": [
          {
            "value": "adapt",
            "label": "t:sections.collapsible_content.settings.image_ratio.options__1.label"
          },
          {
            "value": "small",
            "label": "t:sections.collapsible_content.settings.image_ratio.options__2.label"
          },
          {
            "value": "large",
            "label": "t:sections.collapsible_content.settings.image_ratio.options__3.label"
          }
        ],
        "default": "adapt",
        "label": "t:sections.collapsible_content.settings.image_ratio.label"
      },
      {
        "type": "select",
        "id": "desktop_layout",
        "options": [
          {
            "value": "image_first",
            "label": "t:sections.collapsible_content.settings.desktop_layout.options__1.label"
          },
          {
            "value": "image_second",
            "label": "t:sections.collapsible_content.settings.desktop_layout.options__2.label"
          }
        ],
        "default": "image_second",
        "label": "t:sections.collapsible_content.settings.desktop_layout.label",
        "info": "t:sections.collapsible_content.settings.desktop_layout.info"
      },
      {
        "type": "header",
        "content": "t:sections.all.padding.section_padding_heading"
      },
      {
        "type": "range",
        "id": "padding_top",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_top",
        "default": 36
      },
      {
        "type": "range",
        "id": "padding_bottom",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_bottom",
        "default": 36
      }
    ],
    "blocks": [
      {
        "type": "collapsible_row",
        "name": "t:sections.collapsible_content.blocks.collapsible_row.name",
        "settings": [
          {
            "type": "text",
            "id": "heading",
            "default": "Collapsible row",
            "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.heading.label",
            "info": "t:sections.collapsible_content.blocks.collapsible_row.settings.heading.info"
          },
          {
            "type": "select",
            "id": "icon",
            "options": [
              {
                "value": "none",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__1.label"
              },
              {
                "value": "apple",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__2.label"
              },
              {
                "value": "banana",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__3.label"
              },
              {
                "value": "bottle",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__4.label"
              },
              {
                "value": "box",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__5.label"
              },
              {
                "value": "carrot",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__6.label"
              },
              {
                "value": "chat_bubble",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__7.label"
              },
              {
                "value": "check_mark",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__8.label"
              },
              {
                "value": "clipboard",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__9.label"
              },
              {
                "value": "dairy",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__10.label"
              },
              {
                "value": "dairy_free",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__11.label"
              },
              {
                "value": "dryer",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__12.label"
              },
              {
                "value": "eye",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__13.label"
              },
              {
                "value": "fire",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__14.label"
              },
              {
                "value": "gluten_free",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__15.label"
              },
              {
                "value": "heart",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__16.label"
              },
              {
                "value": "iron",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__17.label"
              },
              {
                "value": "leaf",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__18.label"
              },
              {
                "value": "leather",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__19.label"
              },
              {
                "value": "lightning_bolt",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__20.label"
              },
              {
                "value": "lipstick",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__21.label"
              },
              {
                "value": "lock",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__22.label"
              },
              {
                "value": "map_pin",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__23.label"
              },
              {
                "value": "nut_free",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__24.label"
              },
              {
                "value": "pants",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__25.label"
              },
              {
                "value": "paw_print",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__26.label"
              },
              {
                "value": "pepper",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__27.label"
              },
              {
                "value": "perfume",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__28.label"
              },
              {
                "value": "plane",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__29.label"
              },
              {
                "value": "plant",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__30.label"
              },
              {
                "value": "price_tag",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__31.label"
              },
              {
                "value": "question_mark",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__32.label"
              },
              {
                "value": "recycle",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__33.label"
              },
              {
                "value": "return",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__34.label"
              },
              {
                "value": "ruler",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__35.label"
              },
              {
                "value": "serving_dish",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__36.label"
              },
              {
                "value": "shirt",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__37.label"
              },
              {
                "value": "shoe",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__38.label"
              },
              {
                "value": "silhouette",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__39.label"
              },
              {
                "value": "snowflake",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__40.label"
              },
              {
                "value": "star",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__41.label"
              },
              {
                "value": "stopwatch",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__42.label"
              },
              {
                "value": "truck",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__43.label"
              },
              {
                "value": "washing",
                "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.options__44.label"
              }
            ],
            "default": "check_mark",
            "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.icon.label"
          },
          {
            "type": "richtext",
            "id": "row_content",
            "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.row_content.label"
          },
          {
            "type": "page",
            "id": "page",
            "label": "t:sections.collapsible_content.blocks.collapsible_row.settings.page.label"
          }
        ]
      }
    ],
    "presets": [
      {
        "name": "t:sections.collapsible_content.presets.name",
        "blocks": [
          {
            "type": "collapsible_row"
          },
          {
            "type": "collapsible_row"
          },
          {
            "type": "collapsible_row"
          },
          {
            "type": "collapsible_row"
          }
        ]
      }
    ]
  },
  "email-signup-banner":{
    "name": "t:sections.email-signup-banner.name",
    "tag": "section",
    "class": "section",
    "settings": [
      {
        "type": "paragraph",
        "content": "t:sections.email-signup-banner.settings.paragraph.content"
      },
      {
        "type": "image_picker",
        "id": "image",
        "label": "t:sections.email-signup-banner.settings.image.label"
      },
      {
        "type": "range",
        "id": "image_overlay_opacity",
        "min": 0,
        "max": 100,
        "step": 10,
        "unit": "%",
        "label": "t:sections.email-signup-banner.settings.image_overlay_opacity.label",
        "default": 0
      },
      {
        "type": "checkbox",
        "id": "show_background_image",
        "label": "t:sections.email-signup-banner.settings.show_background_image.label",
        "default": true
      },
      {
        "type": "select",
        "id": "image_height",
        "options": [
          {
            "value": "adapt-image",
            "label": "t:sections.email-signup-banner.settings.image_height.options__1.label"
          },
          {
            "value": "small",
            "label": "t:sections.email-signup-banner.settings.image_height.options__2.label"
          },
          {
            "value": "medium",
            "label": "t:sections.email-signup-banner.settings.image_height.options__3.label"
          },
          {
            "value": "large",
            "label": "t:sections.email-signup-banner.settings.image_height.options__4.label"
          }
        ],
        "default": "medium",
        "label": "t:sections.email-signup-banner.settings.image_height.label",
        "info": "t:sections.email-signup-banner.settings.image_height.info"
      },
      {
        "type": "select",
        "id": "desktop_content_position",
        "options": [
          {
            "value": "top-left",
            "label": "t:sections.email-signup-banner.settings.desktop_content_position.options__1.label"
          },
          {
            "value": "top-center",
            "label": "t:sections.email-signup-banner.settings.desktop_content_position.options__2.label"
          },
          {
            "value": "top-right",
            "label": "t:sections.email-signup-banner.settings.desktop_content_position.options__3.label"
          },
          {
            "value": "middle-left",
            "label": "t:sections.email-signup-banner.settings.desktop_content_position.options__4.label"
          },
          {
            "value": "middle-center",
            "label": "t:sections.email-signup-banner.settings.desktop_content_position.options__5.label"
          },
          {
            "value": "middle-right",
            "label": "t:sections.email-signup-banner.settings.desktop_content_position.options__6.label"
          },
          {
            "value": "bottom-left",
            "label": "t:sections.email-signup-banner.settings.desktop_content_position.options__7.label"
          },
          {
            "value": "bottom-center",
            "label": "t:sections.email-signup-banner.settings.desktop_content_position.options__8.label"
          },
          {
            "value": "bottom-right",
            "label": "t:sections.email-signup-banner.settings.desktop_content_position.options__9.label"
          }
        ],
        "default": "middle-center",
        "label": "t:sections.email-signup-banner.settings.desktop_content_position.label"
      },
      {
        "type": "checkbox",
        "id": "show_text_box",
        "default": true,
        "label": "t:sections.email-signup-banner.settings.show_text_box.label"
      },
      {
        "type": "select",
        "id": "desktop_content_alignment",
        "options": [
          {
            "value": "left",
            "label": "t:sections.email-signup-banner.settings.desktop_content_alignment.options__1.label"
          },
          {
            "value": "center",
            "label": "t:sections.email-signup-banner.settings.desktop_content_alignment.options__2.label"
          },
          {
            "value": "right",
            "label": "t:sections.email-signup-banner.settings.desktop_content_alignment.options__3.label"
          }
        ],
        "default": "center",
        "label": "t:sections.email-signup-banner.settings.desktop_content_alignment.label"
      },
      {
        "type": "color_scheme",
        "id": "color_scheme",
        "label": "t:sections.all.colors.label",
        "info": "t:sections.email-signup-banner.settings.color_scheme.info",
        "default": "background-1"
      },
      {
        "type": "header",
        "content": "t:sections.email-signup-banner.settings.header.content"
      },
      {
        "type": "select",
        "id": "mobile_content_alignment",
        "options": [
          {
            "value": "left",
            "label": "t:sections.email-signup-banner.settings.mobile_content_alignment.options__1.label"
          },
          {
            "value": "center",
            "label": "t:sections.email-signup-banner.settings.mobile_content_alignment.options__2.label"
          },
          {
            "value": "right",
            "label": "t:sections.email-signup-banner.settings.mobile_content_alignment.options__3.label"
          }
        ],
        "default": "center",
        "label": "t:sections.email-signup-banner.settings.mobile_content_alignment.label"
      },
      {
        "type": "checkbox",
        "id": "show_text_below",
        "default": true,
        "label": "t:sections.email-signup-banner.settings.show_text_below.label"
      }
    ],
    "blocks": [
      {
        "type": "heading",
        "name": "t:sections.email-signup-banner.blocks.heading.name",
        "limit": 1,
        "settings": [
          {
            "type": "text",
            "id": "heading",
            "default": "Opening soon",
            "label": "t:sections.email-signup-banner.blocks.heading.settings.heading.label"
          },
          {
            "type": "select",
            "id": "heading_size",
            "options": [
              {
                "value": "h2",
                "label": "t:sections.all.heading_size.options__1.label"
              },
              {
                "value": "h1",
                "label": "t:sections.all.heading_size.options__2.label"
              },
              {
                "value": "h0",
                "label": "t:sections.all.heading_size.options__3.label"
              }
            ],
            "default": "h1",
            "label": "t:sections.all.heading_size.label"
          }
        ]
      },
      {
        "type": "paragraph",
        "name": "t:sections.email-signup-banner.blocks.paragraph.name",
        "limit": 1,
        "settings": [
          {
            "type": "richtext",
            "id": "text",
            "default": "<p>Be the first to know when we launch.</p>",
            "label": "t:sections.email-signup-banner.blocks.paragraph.settings.paragraph.label"
          },
          {
            "type": "select",
            "id": "text_style",
            "options": [
              {
                "value": "body",
                "label": "t:sections.email-signup-banner.blocks.paragraph.settings.text_style.options__1.label"
              },
              {
                "value": "subtitle",
                "label": "t:sections.email-signup-banner.blocks.paragraph.settings.text_style.options__2.label"
              }
            ],
            "default": "body",
            "label": "t:sections.email-signup-banner.blocks.paragraph.settings.text_style.label"
          }
        ]
      },
      {
        "type": "email_form",
        "name": "t:sections.email-signup-banner.blocks.email_form.name",
        "limit": 1
      }
    ],
    "presets": [
      {
        "name": "t:sections.email-signup-banner.presets.name",
        "blocks": [
          {
            "type": "heading"
          },
          {
            "type": "paragraph"
          },
          {
            "type": "email_form"
          }
        ]
      }
    ],
    "templates": ["password"]
  },
  "contact-form":{
    "name": "t:sections.contact-form.name",
    "tag": "section",
    "class": "section",
    "disabled_on": {
      "groups": ["header", "footer"]
    },
    "settings": [
      {
        "type": "inline_richtext",
        "id": "heading",
        "default": "Contact form",
        "label": "Heading"
      },
      {
        "type": "select",
        "id": "heading_size",
        "options": [
          {
            "value": "h2",
            "label": "t:sections.all.heading_size.options__1.label"
          },
          {
            "value": "h1",
            "label": "t:sections.all.heading_size.options__2.label"
          },
          {
            "value": "h0",
            "label": "t:sections.all.heading_size.options__3.label"
          }
        ],
        "default": "h1",
        "label": "t:sections.all.heading_size.label"
      },
      {
        "type": "color_scheme",
        "id": "color_scheme",
        "label": "t:sections.all.colors.label",
        "default": "background-1"
      },
      {
        "type": "header",
        "content": "t:sections.all.padding.section_padding_heading"
      },
      {
        "type": "range",
        "id": "padding_top",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_top",
        "default": 36
      },
      {
        "type": "range",
        "id": "padding_bottom",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_bottom",
        "default": 36
      }
    ],
    "presets": [
      {
        "name": "t:sections.contact-form.presets.name"
      }
    ]
  },
  "custom-liquid":{
    "name": "t:sections.custom-liquid.name",
    "tag": "section",
    "class": "section",
    "settings": [
      {
        "type": "liquid",
        "id": "custom_liquid",
        "label": "t:sections.custom-liquid.settings.custom_liquid.label",
        "info": "t:sections.custom-liquid.settings.custom_liquid.info"
      },
      {
        "type": "color_scheme",
        "id": "color_scheme",
        "label": "t:sections.all.colors.label",
        "default": "background-1"
      },
      {
        "type": "header",
        "content": "t:sections.all.padding.section_padding_heading"
      },
      {
        "type": "range",
        "id": "padding_top",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_top",
        "default": 40
      },
      {
        "type": "range",
        "id": "padding_bottom",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_bottom",
        "default": 52
      }
    ],
    "presets": [
      {
        "name": "t:sections.custom-liquid.presets.name"
      }
    ]
  },
  "custom-section":{
    "name": "t:sections.custom-section.name",
    "tag": "section",
    "class": "section",
    "settings": [
      {
        "type": "text",
        "id": "text",
        "default": "",
        "label": "Initial Text"
      },
      {
        "type": "text",
        "id": "text_special",
        "default": "",
        "label": "Special Text"
      },
      {
        "type": "text",
        "id": "text_secondary",
        "default": "",
        "label": "Secondary Text"
      },
      {
        "type": "text",
        "id": "color_text",
        "label": "Initial text color",
        "default": "",
        "info": "Please set hex color code",
      },
      {
        "type": "text",
        "id": "color_special",
        "label": "Special text color",
        "default": "",
        "info": "Please set hex color code",
      },
      {
        "type": "text",
        "id": "color_back",
        "label": "Background color",
        "default": "",
       "info": "Please set hex color code",
      },
      {
        "type": "header",
        "content": "t:sections.all.padding.section_padding_heading"
      },
      {
        "type": "range",
        "id": "padding_top",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_top",
        "default": 40
      },
      {
        "type": "range",
        "id": "padding_bottom",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_bottom",
        "default": 52
      }
    ],
    "presets": [
      {
        "name": "t:sections.custom-section.presets.name"
      }
    ]
  },
  "header" : {
    "name": "t:sections.header.name",
    "class": "section-header",
    "max_blocks": 3,
    "settings": [
      {
        "type": "select",
        "id": "logo_position",
        "options": [
          {
            "value": "top-left",
            "label": "t:sections.header.settings.logo_position.options__2.label"
          },
          {
            "value": "top-center",
            "label": "t:sections.header.settings.logo_position.options__3.label"
          },
          {
            "value": "middle-left",
            "label": "t:sections.header.settings.logo_position.options__1.label"
          },
          {
            "value": "middle-center",
            "label": "t:sections.header.settings.logo_position.options__4.label"
          }
        ],
        "default": "middle-left",
        "label": "t:sections.header.settings.logo_position.label",
        "info": "t:sections.header.settings.logo_help.content"
      },
      {
        "type": "link_list",
        "id": "menu",
        "default": "main-menu",
        "label": "t:sections.header.settings.menu.label"
      },
      {
        "type": "select",
        "id": "menu_type_desktop",
        "options": [
          {
            "value": "dropdown",
            "label": "t:sections.header.settings.menu_type_desktop.options__1.label"
          },
          {
            "value": "mega",
            "label": "t:sections.header.settings.menu_type_desktop.options__2.label"
          },
          {
            "value": "drawer",
            "label": "t:sections.header.settings.menu_type_desktop.options__3.label"
          }
        ],
        "default": "dropdown",
        "label": "t:sections.header.settings.menu_type_desktop.label",
        "info": "t:sections.header.settings.menu_type_desktop.info"
      },
      {
        "type": "select",
        "id": "sticky_header_type",
        "options": [
          {
            "value": "none",
            "label": "t:sections.header.settings.sticky_header_type.options__1.label"
          },
          {
            "value": "on-scroll-up",
            "label": "t:sections.header.settings.sticky_header_type.options__2.label"
          },
          {
            "value": "always",
            "label": "t:sections.header.settings.sticky_header_type.options__3.label"
          },
          {
            "value": "reduce-logo-size",
            "label": "t:sections.header.settings.sticky_header_type.options__4.label"
          }
        ],
        "default": "on-scroll-up",
        "label": "t:sections.header.settings.sticky_header_type.label"
      },
      {
        "type": "checkbox",
        "id": "show_line_separator",
        "default": true,
        "label": "t:sections.header.settings.show_line_separator.label"
      },
      {
        "type": "color_scheme",
        "id": "color_scheme",
        "label": "t:sections.all.colors.label",
        "default": "background-1"
      },
      {
        "type": "header",
        "content": "t:sections.header.settings.header__3.content",
        "info": "t:sections.header.settings.header__4.info"
      },
      {
        "type": "checkbox",
        "id": "enable_country_selector",
        "default": true,
        "label": "t:sections.header.settings.enable_country_selector.label"
      },
      {
        "type": "header",
        "content": "t:sections.header.settings.header__5.content",
        "info": "t:sections.header.settings.header__6.info"
      },
      {
        "type": "checkbox",
        "id": "enable_language_selector",
        "default": true,
        "label": "t:sections.header.settings.enable_language_selector.label"
      },
      {
        "type": "header",
        "content": "t:sections.header.settings.mobile_layout.content"
      },
      {
        "type": "select",
        "id": "mobile_logo_position",
        "options": [
          {
            "value": "center",
            "label": "t:sections.header.settings.mobile_logo_position.options__1.label"
          },
          {
            "value": "left",
            "label": "t:sections.header.settings.mobile_logo_position.options__2.label"
          }
        ],
        "default": "center",
        "label": "t:sections.header.settings.mobile_logo_position.label"
      },
      {
        "type": "header",
        "content": "t:sections.all.spacing"
      },
      {
        "type": "range",
        "id": "margin_bottom",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.header.settings.margin_bottom.label",
        "default": 0
      },
      {
        "type": "header",
        "content": "t:sections.all.padding.section_padding_heading"
      },
      {
        "type": "range",
        "id": "padding_top",
        "min": 0,
        "max": 36,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_top",
        "default": 20
      },
      {
        "type": "range",
        "id": "padding_bottom",
        "min": 0,
        "max": 36,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_bottom",
        "default": 20
      }
    ],
    "blocks": [
      {
        "type": "@app"
      }
    ]
  },
  "announcement-bar" : {
    "name": "t:sections.announcement-bar.name",
    "max_blocks": 12,
    "class": "announcement-bar-section",
    "settings": [
      {
        "type": "color_scheme",
        "id": "color_scheme",
        "label": "t:sections.all.colors.label",
        "default": "accent-1"
      },
      {
        "type": "checkbox",
        "id": "show_line_separator",
        "default": true,
        "label": "t:sections.header.settings.show_line_separator.label"
      },
      {
        "type": "checkbox",
        "id": "auto_rotate",
        "label": "t:sections.announcement-bar.settings.auto_rotate.label",
        "default": false
      },
      {
        "type": "range",
        "id": "change_slides_speed",
        "min": 5,
        "max": 10,
        "step": 1,
        "unit": "s",
        "label": "t:sections.announcement-bar.settings.change_slides_speed.label",
        "default": 5
      }
    ],
    "blocks": [
      {
        "type": "announcement",
        "name": "t:sections.announcement-bar.blocks.announcement.name",
        "settings": [
          {
            "type": "text",
            "id": "text",
            "default": "Welcome to our store",
            "label": "t:sections.announcement-bar.blocks.announcement.settings.text.label"
          },
          {
            "type": "url",
            "id": "link",
            "label": "t:sections.announcement-bar.blocks.announcement.settings.link.label"
          }
        ]
      }
    ],
    "default": {
      "blocks": [
        {
          "type": "announcement"
        }
      ]
    }
  },
  "footer" : {
    "name": "t:sections.footer.name",
    "blocks": [
      {
        "type": "@app"
      },
      {
        "type": "link_list",
        "name": "t:sections.footer.blocks.link_list.name",
        "settings": [
          {
            "type": "inline_richtext",
            "id": "heading",
            "default": "Quick links",
            "label": "t:sections.footer.blocks.link_list.settings.heading.label"
          },
          {
            "type": "link_list",
            "id": "menu",
            "default": "footer",
            "label": "t:sections.footer.blocks.link_list.settings.menu.label",
            "info": "t:sections.footer.blocks.link_list.settings.menu.info"
          }
        ]
      },
      {
        "type": "brand_information",
        "name": "t:sections.footer.blocks.brand_information.name",
        "settings": [
          {
            "type": "paragraph",
            "content": "t:sections.footer.blocks.brand_information.settings.paragraph.content"
          },
          {
            "type": "header",
            "content": "t:sections.footer.blocks.brand_information.settings.header__1.content"
          },
          {
            "type": "checkbox",
            "id": "show_social",
            "default": true,
            "label": "t:sections.footer.blocks.brand_information.settings.show_social.label",
            "info": "t:sections.footer.blocks.brand_information.settings.show_social.info"
          }
        ]
      },
      {
        "type": "text",
        "name": "t:sections.footer.blocks.text.name",
        "settings": [
          {
            "type": "inline_richtext",
            "id": "heading",
            "default": "Heading",
            "label": "t:sections.footer.blocks.text.settings.heading.label"
          },
          {
            "type": "richtext",
            "id": "subtext",
            "default": "<p>Share contact information, store details, and brand content with your customers.</p>",
            "label": "t:sections.footer.blocks.text.settings.subtext.label"
          }
        ]
      },
      {
        "type": "image",
        "name": "Image",
        "settings": [
          {
            "type": "image_picker",
            "id": "image",
            "label": "Image"
          },
          {
            "type": "range",
            "id": "image_width",
            "min": 50,
            "max": 550,
            "step": 5,
            "unit": "px",
            "label": "Image width",
            "default": 100
          },
          {
            "type": "select",
            "id": "alignment",
            "label": "Image alignment on large screen",
            "options": [
              {
                "value": "",
                "label": "Left"
              },
              {
                "value": "center",
                "label": "Center"
              },
              {
                "value": "right",
                "label": "Right"
              }
            ],
            "default": "center"
          }
        ]
      }
    ],
    "settings": [
      {
        "type": "color_scheme",
        "id": "color_scheme",
        "label": "t:sections.all.colors.label",
        "default": "background-1"
      },
      {
        "type": "header",
        "content": "t:sections.footer.settings.header__1.content",
        "info": "t:sections.footer.settings.header__1.info"
      },
      {
        "type": "checkbox",
        "id": "newsletter_enable",
        "default": true,
        "label": "t:sections.footer.settings.newsletter_enable.label"
      },
      {
        "type": "inline_richtext",
        "id": "newsletter_heading",
        "default": "Subscribe to our emails",
        "label": "t:sections.footer.settings.newsletter_heading.label"
      },
      {
        "type": "header",
        "content": "t:sections.footer.settings.header__9.content",
        "info": "t:sections.footer.settings.header__9.info"
      },
      {
        "type": "checkbox",
        "id": "enable_follow_on_shop",
        "default": true,
        "label": "t:sections.footer.settings.enable_follow_on_shop.label"
      },
      {
        "type": "header",
        "content": "t:sections.footer.settings.header__2.content",
        "info": "t:sections.footer.settings.header__2.info"
      },
      {
        "type": "checkbox",
        "id": "show_social",
        "default": true,
        "label": "t:sections.footer.settings.show_social.label"
      },
      {
        "type": "header",
        "content": "t:sections.footer.settings.header__3.content",
        "info": "t:sections.footer.settings.header__4.info"
      },
      {
        "type": "checkbox",
        "id": "enable_country_selector",
        "default": true,
        "label": "t:sections.footer.settings.enable_country_selector.label"
      },
      {
        "type": "header",
        "content": "t:sections.footer.settings.header__5.content",
        "info": "t:sections.footer.settings.header__6.info"
      },
      {
        "type": "checkbox",
        "id": "enable_language_selector",
        "default": true,
        "label": "t:sections.footer.settings.enable_language_selector.label"
      },
      {
        "type": "header",
        "content": "t:sections.footer.settings.header__7.content"
      },
      {
        "type": "checkbox",
        "id": "payment_enable",
        "default": true,
        "label": "t:sections.footer.settings.payment_enable.label"
      },
      {
        "type": "header",
        "content": "t:sections.footer.settings.header__8.content",
        "info": "t:sections.footer.settings.header__8.info"
      },
      {
        "type": "checkbox",
        "id": "show_policy",
        "default": false,
        "label": "t:sections.footer.settings.show_policy.label"
      },
      {
        "type": "header",
        "content": "t:sections.all.spacing"
      },
      {
        "type": "range",
        "id": "margin_top",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.footer.settings.margin_top.label",
        "default": 0
      },
      {
        "type": "header",
        "content": "t:sections.all.padding.section_padding_heading"
      },
      {
        "type": "range",
        "id": "padding_top",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_top",
        "default": 36
      },
      {
        "type": "range",
        "id": "padding_bottom",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_bottom",
        "default": 36
      }
    ],
    "presets": [
      {
        "blocks": [
          {
            "type": "link_list"
          },
          {
            "type": "text"
          },
          {

          "type": "image"

          },
          {

            "type": "brand_information"

            }
        ]
      }
    ],
    "default": {
      "blocks": [
        {
          "type": "link_list"
        },
        {
          "type": "text"
        }
      ]
    }
  },
  "featured-blog" : {
    "name": "t:sections.featured-blog.name",
    "tag": "section",
    "disabled_on": {
      "groups": ["header", "footer"]
    },
    "settings": [
      {
        "type": "inline_richtext",
        "id": "heading",
        "default": "Blog posts",
        "label": "t:sections.featured-blog.settings.heading.label"
      },
      {
        "type": "select",
        "id": "heading_size",
        "options": [
          {
            "value": "h2",
            "label": "t:sections.all.heading_size.options__1.label"
          },
          {
            "value": "h1",
            "label": "t:sections.all.heading_size.options__2.label"
          },
          {
            "value": "h0",
            "label": "t:sections.all.heading_size.options__3.label"
          }
        ],
        "default": "h1",
        "label": "t:sections.all.heading_size.label"
      },
      {
        "type": "blog",
        "id": "blog",
        "label": "t:sections.featured-blog.settings.blog.label"
      },
      {
        "type": "range",
        "id": "post_limit",
        "min": 2,
        "max": 4,
        "step": 1,
        "default": 3,
        "label": "t:sections.featured-blog.settings.post_limit.label"
      },
      {
        "type": "range",
        "id": "columns_desktop",
        "min": 1,
        "max": 4,
        "step": 1,
        "default": 3,
        "label": "t:sections.featured-blog.settings.columns_desktop.label"
      },
      {
        "type": "color_scheme",
        "id": "color_scheme",
        "label": "t:sections.all.colors.label",
        "info": "t:sections.all.colors.has_cards_info",
        "default": "background-1"
      },
      {
        "type": "checkbox",
        "id": "show_image",
        "default": true,
        "label": "t:sections.featured-blog.settings.show_image.label",
        "info": "t:sections.featured-blog.settings.show_image.info"
      },
      {
        "type": "checkbox",
        "id": "show_date",
        "default": true,
        "label": "t:sections.featured-blog.settings.show_date.label"
      },
      {
        "type": "checkbox",
        "id": "show_author",
        "default": false,
        "label": "t:sections.featured-blog.settings.show_author.label"
      },
      {
        "type": "checkbox",
        "id": "show_view_all",
        "default": true,
        "label": "t:sections.featured-blog.settings.show_view_all.label"
      },
      {
        "type": "header",
        "content": "t:sections.all.padding.section_padding_heading"
      },
      {
        "type": "range",
        "id": "padding_top",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_top",
        "default": 36
      },
      {
        "type": "range",
        "id": "padding_bottom",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_bottom",
        "default": 36
      }
    ],
    "presets": [
      {
        "name": "t:sections.featured-blog.presets.name",
        "settings": {
          "blog": "News"
        }
      }
    ]
  },
  "collection-list" : {
    "name": "t:sections.collection-list.name",
    "tag": "section",
    "class": "section section-collection-list",
    "max_blocks": 15,
    "disabled_on": {
      "groups": ["header", "footer"]
    },
    "settings": [
      {
        "type": "inline_richtext",
        "id": "title",
        "default": "Collections",
        "label": "t:sections.collection-list.settings.title.label"
      },
      {
        "type": "select",
        "id": "heading_size",
        "options": [
          {
            "value": "h2",
            "label": "t:sections.all.heading_size.options__1.label"
          },
          {
            "value": "h1",
            "label": "t:sections.all.heading_size.options__2.label"
          },
          {
            "value": "h0",
            "label": "t:sections.all.heading_size.options__3.label"
          }
        ],
        "default": "h1",
        "label": "t:sections.all.heading_size.label"
      },
      {
        "type": "select",
        "id": "image_ratio",
        "options": [
          {
            "value": "adapt",
            "label": "t:sections.collection-list.settings.image_ratio.options__1.label"
          },
          {
            "value": "portrait",
            "label": "t:sections.collection-list.settings.image_ratio.options__2.label"
          },
          {
            "value": "square",
            "label": "t:sections.collection-list.settings.image_ratio.options__3.label"
          }
        ],
        "default": "square",
        "label": "t:sections.collection-list.settings.image_ratio.label",
        "info": "t:sections.collection-list.settings.image_ratio.info"
      },
      {
        "type": "range",
        "id": "columns_desktop",
        "min": 1,
        "max": 5,
        "step": 1,
        "default": 3,
        "label": "t:sections.collection-list.settings.columns_desktop.label"
      },
      {
        "type": "color_scheme",
        "id": "color_scheme",
        "label": "t:sections.all.colors.label",
        "info": "t:sections.all.colors.has_cards_info",
        "default": "background-1"
      },
      {
        "type": "checkbox",
        "id": "show_view_all",
        "default": false,
        "label": "t:sections.collection-list.settings.show_view_all.label"
      },
      {
        "type": "header",
        "content": "t:sections.collection-list.settings.header_mobile.content"
      },
      {
        "type": "select",
        "id": "columns_mobile",
        "options": [
          {
            "value": "1",
            "label": "t:sections.collection-list.settings.columns_mobile.options__1.label"
          },
          {
            "value": "2",
            "label": "t:sections.collection-list.settings.columns_mobile.options__2.label"
          }
        ],
        "default": "1",
        "label": "t:sections.collection-list.settings.columns_mobile.label"
      },
      {
        "type": "checkbox",
        "id": "swipe_on_mobile",
        "default": false,
        "label": "t:sections.collection-list.settings.swipe_on_mobile.label"
      },
      {
        "type": "header",
        "content": "t:sections.all.padding.section_padding_heading"
      },
      {
        "type": "range",
        "id": "padding_top",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_top",
        "default": 36
      },
      {
        "type": "range",
        "id": "padding_bottom",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_bottom",
        "default": 36
      }
    ],
    "blocks": [
      {
        "type": "featured_collection",
        "name": "t:sections.collection-list.blocks.featured_collection.name",
        "settings": [
          {
            "type": "collection",
            "id": "collection",
            "label": "t:sections.collection-list.blocks.featured_collection.settings.collection.label"
          }
        ]
      }
    ],
    "presets": [
      {
        "name": "t:sections.collection-list.presets.name",
        "blocks": [
          {
            "type": "featured_collection"
          },
          {
            "type": "featured_collection"
          },
          {
            "type": "featured_collection"
          }
        ]
      }
    ]
  },
  "custom-html": {
    "name": "t:sections.featured-collection.name",
    "tag": "section",
    "class": "section",
    "settings": [
      {
        "type": "html",
        "id": "html",
        "default": "Enter Html",
        "label": "Enter HTML"
      }
    ]

  },
  "integration-reviews-lai-ali-express-io": {
    "name": "LAI homepage reviews",
    "settings": [
      {
        "type": "checkbox",
        "id": "enabled",
        "label": "Enabled LAI Review"
      },

    ],
  },

  "integration-reviews-stamped-io": {
    "name": "LAI homepage reviews",
    "settings": [
      {
        "type": "checkbox",
        "id": "enabled",
        "label": "Enabled StampedIO Review"
      },

    ],
  },

  "integration-reviews-areviews-aliexpress": {
    "name": "LAI homepage reviews",
    "settings": [
      {
        "type": "checkbox",
        "id": "enabled",
        "label": "Enable Areviews ‑ Reviews"
      },

    ],
  },

  "integration-reviews-bazaarvoice": {
    "name": "Bazaarvoice - Reviews",
    "settings": [
      {
        "type": "checkbox",
        "id": "enabled",
        "label": "Enable Bazaarvoice"
      },
      {
        "type": "text",
        "id": "shop_id",
        "label": "Bazaarvoice Shop ID"
      },

    ]
  },

  "integration-reviews-judge-me": {
    "name": "Judge me reviews",
    "settings": [
      {
        "type": "checkbox",
        "id": "enabled",
        "label": "Enable Judge.me Reviews"
      },

    ],
  },

  "integration-reviews-loox": {
    "name": "Judge me reviews",
    "settings": [
      {
        "type": "checkbox",
        "id": "enabled",
        "label": "Enable Loox Reviews"
      },

    ],
  },

  "integration-reviews-okendo": {
    "name": "Judge me reviews",
    "settings": [
      {
        "type": "checkbox",
        "id": "enabled",
        "label": "Enable Okendo Reviews"
      },

    ],
  },

  "integration-reviews-opinew": {
    "name": "Judge me reviews",
    "settings": [
      {
        "type": "checkbox",
        "id": "enabled",
        "label": "Enable Opinew Product Reviews"
      },

    ],
  },

  "integration-reviews-ryviu": {
    "name": "Judge me reviews",
    "settings": [
      {
        "type": "checkbox",
        "id": "enabled",
        "label": "Enable Ryviu Reviews"
      },

    ],
  },

  "integration-reviews-shopify-product-reviews": {
    "name": "Judge me reviews",
    "settings": [
      {
        "type": "checkbox",
        "id": "enabled",
        "label": "Enable Shopify Product Reviews"
      },

    ],
  },

  "integration-reviews-shopperapproved": {
    "name": "Judge me reviews",
    "settings": [
      {
        "type": "checkbox",
        "id": "enabled",
        "label": "Enable Shopper Approved Reviews"
      },

    ],
  },

  "integration-reviews-review-io": {
    "name": "Bazaarvoice - Reviews",
    "settings": [
      {
        "type": "checkbox",
        "id": "enabled",
        "label": "Enable ReviewIO Reviews"
      },
      {
        "type": "text",
        "id": "shop_id",
        "label": "ReviewIO Shop ID"
      },

    ]
  },

  "integration-reviews-yotpo": {
    "name": "Bazaarvoice - Reviews",
    "settings": [
      {
        "type": "checkbox",
        "id": "enabled",
        "label": "Enable Yotpo Reviews"
      },
      {
        "type": "text",
        "id": "api_key",
        "label": "Yotpo API Key"
      },

    ]
  },

  "integration-reviews-ssw": {
    "name": "Judge me reviews",
    "settings": [
      {
        "type": "checkbox",
        "id": "enabled",
        "label": "Enable Growave Reviews"
      },

    ],
  },

  "integration-reviews-helpfulcrowd": {
    "name": "Bazaarvoice - Reviews",
    "settings": [
      {
        "type": "checkbox",
        "id": "enabled",
        "label": "Enable Helpful Crowd Reviews"
      },
      {
        "type": "text",
        "id": "client_id",
        "label": "Helpful Crowd Client ID"
      },

    ]
  },

  "integration-reviews-alireviews": {
    "name": "Judge me reviews",
    "settings": [
      {
        "type": "checkbox",
        "id": "enabled",
        "label": "Enable Ali Express"
      },

    ],
  },

  "divider" :{
  "name": "Divider",
  "class": "shopify-section--divider",
  "settings": [
    {
      "type": "paragraph",
      "content": "Add spacing in between your sections by using a divider."
    },
    {
      "type": "range",
      "id": "divider_spacing",
      "min": 0,
      "max": 30,
      "step": 5,
      "unit": "px",
      "label": "Space",
      "default": 20
    },
    {
      "type": "checkbox",
      "id": "divider_border",
      "label": "Show border",
      "default": false
    },
    {
      "type": "header",
      "content": "Advanced"
    },
    {
      "type": "paragraph",
      "content": "[Learn more](https://help.outofthesandbox.com/hc/en-us/articles/360022329373)"
    },
    {
      "type": "text",
      "id": "css_class",
      "label": "CSS Class"
    },
    {
      "type": "textarea",
      "id": "custom_css",
      "label": "Custom CSS"
    }
  ],
  "presets": [
    {
      "name": "Divider",
      "category": "Layout"
    }
  ]
  },

  "integration-reviews-proviews-io": {
    "name": "Proviews",
    "settings": [
      {
        "type": "checkbox",
        "id": "enabled",
        "label": "Enable Proviews"
      },

    ],
  },

  "amp-review-fallback": {
    "name": "Amp Reviews Fallback",
    "settings": [
      {
        "type": "checkbox",
        "id": "enabled",
        "label": "Enable Amp Reviews Fallback"
      },

    ],
  },

  "main-page" : {
    "name": "Page",
    "tag": "section",
    "class": "section",
    "settings": [
      {
        "type": "header",
        "content": "t:sections.all.padding.section_padding_heading"
      },
      {
        "type": "range",
        "id": "padding_top",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_top",
        "default": 36
      },
      {
        "type": "range",
        "id": "padding_bottom",
        "min": 0,
        "max": 100,
        "step": 4,
        "unit": "px",
        "label": "t:sections.all.padding.padding_bottom",
        "default": 36
      }
    ]
  }
}

public static readonly addSectionDataV10 = {
  "sections": {
    "contact_form": {
      "type": "contact-form",
      "settings": {
        "heading": "Contact form",
        "heading_size": "h1",
        "color_scheme": "background-1",
        "padding_top": 36,
        "padding_bottom": 36
      }
    },
    "custom_liquid": {
      "type": "custom-liquid",
      "settings": {
        "custom_liquid": "",
        "color_scheme": "background-1",
        "padding_top": 36,
        "padding_bottom": 36

      }
    },
    "custom_section": {
      "type": "custom-section",
      "settings": {
        "text": "",
        "text_special": "",
        "text_secondary": "",
        "color_text": "",
        "color_special": "",
        "color_scheme": "",
        "padding_top": 36,
        "padding_bottom": 36

      }
    },
    "areviews_section": {
      "type": "areviews-section",
      "settings":  {
        "type": "checkbox",
        "id": "enabled",
        "label": "Enable Areviews ‑ Reviews"
      },
    },
    "image_banner": {
      "type": "image-banner",
      "blocks": {
        "heading": {
          "type": "heading",
          "settings": {
            "heading": "Image banner",
            "heading_size": "h0"
          }
        },
        "text": {
          "type": "text",
          "settings": {
            "text": "Give customers details about the banner image(s) or content on the template.",
            "text_style": "body"
          }
        },
        "button": {
          "type": "buttons",
          "settings": {
            "button_label_1": "Shop all",
            "button_link_1": "shopify:\/\/collections\/all",
            "button_style_secondary_1": true,
            "button_label_2": "",
            "button_link_2": "",
            "button_style_secondary_2": false
          }
        }
      },
      "block_order": [
        "heading",
        "text",
        "button",
      ],
      "settings": {
        "image_overlay_opacity": 40,
        "image_height": "large",
        "adapt_height_first_image": false,
        "desktop_content_position": "bottom-center",
        "show_text_box": false,
        "desktop_content_alignment": "center",
        "color_scheme": "background-1",
        "mobile_content_alignment": "center",
        "stack_images_on_mobile": false,
        "show_text_below": false,
        "image" : "",
        "image2" : ""
      }
    },
    "rich_text": {
      "type": "rich-text",
      "blocks": {
        "heading": {
          "type": "heading",
          "settings": {
            "heading": "Talk about your brand",
            "heading_size": "h1"
          }
        },
        "text": {
          "type": "text",
          "settings": {
            "text": "Share information about your brand with your customers. Describe a product, make announcements, or welcome customers to your store."
          }
        },
        "button": {
          "type": "button",
          "settings": {
            "button_label": "Button label",
            "button_link": "",
            "button_style_secondary": false,
            "button_label_2": "",
            "button_link_2": "",
            "button_style_secondary_2": false
          }
        },
        "caption": {
          "type": "caption",
          "settings": {
            "caption": "Add a tagline",
            "text_style": "caption-with-letter-spacing",
            "text_size": "medium"
          }
        },
        "custom_html": {
          "type": "custom-html",
          "settings": {
            "html": ""
          }
        }
      },
      "block_order": [
        "heading",
        "text",
        "button",
        "caption"
      ],
      "settings": {
        "desktop_content_position": "center",
        "content_alignment": "center",
        "color_scheme": "background-1",
        "full_width": true,
        "padding_top": 40,
        "padding_bottom": 52
      }
    },
    "impact-text": {
      "type": "impact-text",
      "blocks": {
          "1664424181b38582d5-0": {
              "type": "item",
              "settings": {
                  "title": "97%",
                  "subheading": "with zero new stretchmarks",
                  "content": "<p>when using our OBGYN-approved, cocoa & shea Tummy Butter with as directed throughout pregnancy.<\/p>",
                  "button_text": "SHOP TUMMY BUTTER",
                  "button_url": "shopify:\/\/products\/tummy-butter-for-pregnancy-stretch-marks",
                  "animate_impact_text": true,
                  "animate_impact_text_duration": 2.5
              }
          },
          "48b7a481-1eb2-41ef-81b4-a1799d6020a5": {
              "type": "item",
              "settings": {
                  "title": "100%",
                  "subheading": "said they felt instant itch relief",
                  "content": "<p>and softer, more hydrated skin after using our non-greasy, “Perfect 10 Blend” Bump Gloss oil.<\/p>",
                  "button_text": "SHOP BUMP GLOSS",
                  "button_url": "shopify:\/\/products\/bump-gloss-pregnancy-stretch-mark-oil",
                  "animate_impact_text": true,
                  "animate_impact_text_duration": 3.7
              }
          }
      },
      "block_order": [
          "1664424181b38582d5-0",
          "48b7a481-1eb2-41ef-81b4-a1799d6020a5"
      ],

      "settings": {
          "full_width": true,
          "stack_mobile": true,
          "text_alignment": "center",
          "impact_text_style": "fill",
          "text_divider": "none",
          "impact_text_size_ratio": 1,
          "background": "",
          "background_gradient": "",
          "heading_text_color": "rgba(0,0,0,0)",
          "heading_gradient": "linear-gradient(180deg, rgba(244, 153, 167, 1), rgba(244, 158, 158, 1) 50%, rgba(244, 153, 167, 1) 80%, rgba(26, 26, 26, 0) 100%)",
          "text_color": "",
          "button_background": "#fafe54",
          "button_text_color": "#000000"
      }
    },
    "featured_collection": {
      "type": "featured-collection",
      "settings": {
        "title": "Featured products",
        "heading_size": "h2",
        "description": "",
        "show_description": false,
        "description_style": "body",
        "collection": "all",
        "products_to_show": 8,
        "columns_desktop": 4,
        "full_width": false,
        "show_view_all": false,
        "view_all_style": "solid",
        "enable_desktop_slider": false,
        "color_scheme": "background-1",
        "image_ratio": "adapt",
        "show_secondary_image": true,
        "show_vendor": false,
        "show_rating": false,
        "enable_quick_add": false,
        "columns_mobile": "2",
        "swipe_on_mobile": false,
        "padding_top": 28,
        "padding_bottom": 36
      }
    },
    "collage": {
      "type": "collage",
      "blocks": {
        "collection": {
          "type": "collection",
          "settings": {
            "collection": "all"
          }
        },
        "product": {
          "type": "product",
          "settings": {
            "product": "",
            "second_image": false
          }
        },
        "image": {
          "type": "image",
          "settings": {
            "image": "",
            "image_padding": true
          }
        },
        "video": {
          "type": "video",
          "settings": {
            "video_url": "https:\/\/www.youtube.com\/watch?v=_9VUPq3SxOc",
            "description": "Describe the video",
            "image_padding": false
          }
        }
      },
      "block_order": [
        "collection",
        "product",
        "image",
        "video"
      ],
      "settings": {
        "heading": "Multimedia collage",
        "heading_size": "h2",
        "desktop_layout": "left",
        "mobile_layout": "collage",
        "color_scheme": "background-1",
        "padding_top": 36,
        "padding_bottom": 36
      }
    },
    "collapsible_content": {
      "type": "collapsible-content",
      "blocks": {
        "collapsible_row": {
          "type": "collapsible_row",
          "settings": {
            "heading": "Collapsible row",
            "icon": "check_mark",
            "row_content": "",
            "page": ""
          }
        },
      },
      "block_order": [
        "collapsible_row"
      ],
      "settings": {
        "caption": "",
        "heading": "Collapsible content",
        "heading_size": "h1",
        "heading_alignment": "center",
        "layout": "none",
        "color_scheme": "background-1",
        "container_color_scheme": "background-2",
        "open_first_collapsible_row": false,
        "image" : "",
        "image_ratio": "adapt",
        "desktop_layout": "image_second",
        "padding_top": 36,
        "padding_bottom": 36
      }
    },
    "video": {
      "type": "video",
      "settings": {
        "heading": "",
        "heading_size": "h1",
        "video_url": "https:\/\/www.youtube.com\/watch?v=_9VUPq3SxOc",
        "description": "",
        "full_width": false,
        "color_scheme": "background-1",
        "padding_top": 36,
        "padding_bottom": 36
      }
    },
    "multiple-images-with-text": {
      "type": "multiple-images-with-text",
      "blocks": {
          "template--15181414957117__7b312a7f-b338-42a1-bc95-1a90ecee3b71-16815074515b8bd574-1": {
              "type": "item",
              "settings": {
                  "image": "shopify:\/\/shop_images\/3.jpg",
                  "image_rotation_degrees": 1.5,
                  "subheading": "",
                  "title": "YOUR SAFETY IS OUR PRIORITY",
                  "content": "<p>Our products are safe for use during pregnancy and and while breastfeeding. They have been tested extensively without animal testing.<\/p><p> We understand that it is difficult for moms-to-be to find time to spoil themselves but thanks to the organic and non-toxic pregnancy skincare by The Spoiled Mama it is easy.\n<\/p><p> Our entire collection of products and pregnancy gifts is made to help soothe your mind and body.<\/p>",
                  "button_url": "",
                  "button_text": ""
              }
          },
          "3c7e4c2f-2879-446d-8ee2-82ff35d7a94f": {
              "type": "item",
              "settings": {
                  "image": "shopify:\/\/shop_images\/1.jpg",
                  "image_rotation_degrees": 0,
                  "subheading": "",
                  "title": "WHY CHOOSE US?",
                  "content": "<p> Our ingredients are so gentle that you won't need to worry about toxic ingredients or having dry, itchy skin and you can focus on that “glowing” pregnancy skin tone you always imagined! <\/p><p> From seabuckthorn oil to hyaluronic acid, the effective, non-toxic ingredients in each of our products are proven to improve the appearance of oily, hormonal acne, stretch marks, c-section scars, postpartum skin and help fade dark spots, caused by melasma (mask of pregnancy). <\/p>",
                  "button_url": "",
                  "button_text": ""
              }
          },
          "template--15181414957117__7b312a7f-b338-42a1-bc95-1a90ecee3b71-16815074515b8bd574-0": {
              "type": "item",
              "settings": {
                  "image": "shopify:\/\/shop_images\/4.jpg",
                  "image_rotation_degrees": -2,
                  "subheading": "",
                  "title": "NON-TOXIC, VEGAN & SAFE",
                  "content": "<p>Safe pregnancy skincare means clean beauty products for moms. Always safe for baby and you. <\/p><p> Let's face it. Growing a human isn't easy, and The Spoiled Mama is here for you so you can enjoy these precious months. Our products ensure you receive the safest pregnancy skincare available.<\/p>",
                  "button_url": "",
                  "button_text": ""
              }
          }
      },
      "block_order": [
          "template--15181414957117__7b312a7f-b338-42a1-bc95-1a90ecee3b71-16815074515b8bd574-1",
          "3c7e4c2f-2879-446d-8ee2-82ff35d7a94f",
          "template--15181414957117__7b312a7f-b338-42a1-bc95-1a90ecee3b71-16815074515b8bd574-0"
      ],
      "settings": {
          "full_width": true,
          "images_layout": "scattered",
          "collage_images_alignment": "shifted",
          "background": "",
          "background_gradient": "",
          "text_color": "",
          "button_background": "rgba(0,0,0,0)",
          "button_text_color": ""
      }
    },
    "multicolumn": {
      "type": "multicolumn",
      "blocks": {
        "column": {
          "type": "column",
          "settings": {
            "title": "Column",
            "text": "<p>Pair text with an image to focus on your chosen product, collection, or blog post. Add details on availability, style, or even provide a review.<\/p>",
            "link_label": "",
            "link": ""
          }
        }
      },
      "block_order": [
        "column"
      ],
      "settings": {
        "title": "",
        "heading_size": "h1",
        "image_width": "third",
        "image_ratio": "adapt",
        "columns_desktop": 3,
        "column_alignment": "center",
        "background_style": "none",
        "button_label": "",
        "button_link": "",
        "color_scheme": "background-1",
        "columns_mobile": "1",
        "swipe_on_mobile": false,
        "padding_top": 36,
        "padding_bottom": 36
      }
    },
    "reviews_importer_section_happyPage": {
      "type": "reviews-importer-section-happyPage",
      "settings": {
      }
    },
    "reviews_importer_section": {
      "type": "reviews-importer-section",
      "settings": {
        "title": "",
        "class_title": "",
        "class_wrap_title": ""
      }
    },
    "reviews_importer_product": {
      "type": "reviews-importer-product",
      "settings": {
        "lai_product": ""
      }
    },
    "featured_blog": {
      "type": "featured-blog",
      "settings": {
        "heading": "Blog posts",
        "heading_size": "h1",
        "blog": "news",
        "post_limit": 3,
        "columns_desktop": 3,
        "color_scheme": "background-1",
        "show_image": true,
        "show_date": true,
        "show_author": false,
        "show_view_all": true,
        "padding_top": 36,
        "padding_bottom": 36
      }
    },
    "collection_list": {
      "type": "collection-list",
      "blocks": {
        "featured_collection": {
          "type": "featured_collection",
          "settings": {
            "collection": "all"
          }
        },
      },
      "block_order": [
        "featured_collection"
      ],
      "settings": {
        "title": "Collections",
        "heading_size": "h1",
        "image_ratio": "square",
        "columns_desktop": 3,
        "color_scheme": "background-1",
        "show_view_all": false,
        "columns_mobile": "1",
        "swipe_on_mobile": false,
        "padding_top": 36,
        "padding_bottom": 36
      }
    },
    "newsletter": {
      "type": "newsletter",
      "disabled": true,
      "blocks": {
        "heading": {
          "type": "heading",
          "settings": {
            "heading": "Subscribe to our emails",
            "heading_size": "h1"
          }
        },
        "paragraph": {
          "type": "paragraph",
          "settings": {
            "text": "<p>Be the first to know about new collections and exclusive offers.<\/p>"
          }
        },
        "email_form": {
          "type": "email_form",
          "settings": {
          }
        }
      },
      "block_order": [
        "template--14450283446336__e92418fe-37f6-4b72-9f13-d96ad6921f82-166600278861b7091c-0",
        "template--14450283446336__e92418fe-37f6-4b72-9f13-d96ad6921f82-166600278861b7091c-1",
        "template--14450283446336__e92418fe-37f6-4b72-9f13-d96ad6921f82-166600278861b7091c-2"
      ],
      "settings": {
        "color_scheme": "background-1",
        "full_width": true,
        "padding_top": 40,
        "padding_bottom": 52
      }
    },
    "featured_product": {
      "type": "featured-product",
      "blocks": {
        "vendor": {
          "type": "text",
          "settings": {
            "text": "{{ product.vendor }}",
            "text_style": "uppercase"
          }
        },
        "text": {
          "type": "text",
          "settings": {
            "text": "{{ section.settings.product.vendor }}",
            "text_style": "uppercase"
          }
        },

        "title": {
          "type": "title",
          "settings": {
          }
        },
        "price": {
          "type": "price",
          "settings": {
          }
        },
        "variant_picker": {
          "type": "variant_picker",
          "settings": {
            "picker_type": "button"
          }
        },
        "quantity_selector": {
          "type": "quantity_selector",
          "settings": {
          }
        },
        "buy_buttons": {
          "type": "buy_buttons",
          "settings": {
            "show_dynamic_checkout": true
          }
        },
        "share": {
          "type": "share",
          "settings": {
            "share_label": "Share"
          }
        }
      },
      "block_order": [
        "vendor",
        "title",
       "buy_buttons",
       "price",
       "quantity_selector",
       "share",
       "text",
       "variant_picker"
      ],
      "settings": {
        "product": "",
        "color_scheme": "background-1",
        "secondary_background": false,
        "hide_variants": false,
        "enable_video_looping": false,
        "padding_top": 36,
        "padding_bottom": 36
      }
    },
    "custom_html": {
      "type": "custom-html",
      "settings": {
        "html": ""
      }
    },
    "image_with_text": {
      "type": "image-with-text",
      "blocks": {
        "heading": {
          "type": "heading",
          "settings": {
            "heading": "Image with text",
            "heading_size": "h1"
          }
        },
        "text": {
          "type": "text",
          "settings": {
            "text": "<p>Pair text with an image to focus on your chosen product, collection, or blog post. Add details on availability, style, or even provide a review.<\/p>",
            "text_style": "body"
          }
        },
        "button": {
          "type": "button",
          "settings": {
            "button_label": "Button label",
            "button_link": ""
          }
        },
        "caption": {
          "type": "caption",
          "settings": {
            "caption": "Add a tagline",
            "text_style": "caption-with-letter-spacing",
            "text_size": "small"
          }
        },

      },
      "block_order": [
        "heading",
        "text",
        "button",
        "caption",

      ],
      "settings": {
        "height": "adapt",
        "desktop_image_width": "medium",
        "layout": "image_first",
        "desktop_content_position": "top",
        "desktop_content_alignment": "left",
        "content_layout": "no-overlap",
        "color_scheme": "background-1",
        "mobile_content_alignment": "left",
        "padding_top": 36,
        "padding_bottom": 36
      }
    },
    "slideshow": {
      "type": "slideshow",
      "blocks": {
        "slide": {
          "type": "slide",
          "settings": {
            "heading": "Image slide",
            "heading_size": "h1",
            "subheading": "Tell your brand's story through images",
            "button_label": "Button label",
            "link": "",
            "button_style_secondary": false,
            "box_align": "middle-center",
            "show_text_box": true,
            "text_alignment": "center",
            "image_overlay_opacity": 0,
            "color_scheme": "background-1",
            "text_alignment_mobile": "center"
          }
        },

      },
      "block_order": [
        "slide",
      ],
      "settings": {
        "layout": "full_bleed",
        "slide_height": "adapt_image",
        "slider_visual": "counter",
        "auto_rotate": false,
        "change_slides_speed": 5,
        "show_text_below": true,
        "accessibility_info": "Slideshow about our brand"
      }
    },
    "main_product": {
      "type": "main-product",
      "blocks": {
        "vendor": {
          "type": "text",
          "settings": {
            "text": "",
            "text_style": "uppercase"
          }
        },
        "title": {
          "type": "title",
          "settings": {
          }
        },
        "text": {
          "type": "text",
          "settings": {
            "text": "",
            "text_style": "subtitle"
          }
        },
        "price": {
          "type": "price",
          "settings": {
          }
        },
        "variant_picker": {
          "type": "variant_picker",
          "settings": {
            "picker_type": "button"
          }
        },
        "quantity_selector": {
          "type": "quantity_selector",
          "settings": {
          }
        },
        "buy_buttons": {
          "type": "buy_buttons",
          "settings": {
            "show_dynamic_checkout": true
          }
        },
        "description": {
          "type": "description",
          "settings": {
          }
        },
        "collapsible-row": {
          "type": "collapsible_tab",
          "settings": {
            "heading": "Materials",
            "icon": "leather",
            "content": "",
            "page": ""
          }
        },
        "share": {
          "type": "share",
          "settings": {
            "share_label": "Share"
          }
        },
        "star-ratings": {
          "type": "star_ratings",
          "settings": {
          }
        },
        "custom-liquid": {
          "type": "custom_liquid",
          "settings": {
            "custom_liquid": "",
          }
        }
      },
      "block_order": [
        "vendor",
        "title",
        "caption",
        "price",
        "variant_picker",
        "quantity_selector",
        "buy_buttons",
        "description",
        "collapsible-row",
        "share",
        "star-ratings",
        "custom-liquid"
      ],
      "settings": {
        "enable_sticky_info": true,
        "gallery_layout": "stacked",
        "media_size": "large",
        "mobile_thumbnails": "hide",
        "hide_variants": true,
        "enable_video_looping": false,
        "padding_top": 36,
        "padding_bottom": 12
      }
    },
    "product_recommendations": {
      "type": "product-recommendations",
      "settings": {
        "heading": "You may also like",
        "heading_size": "h2",
        "products_to_show": 4,
        "columns_desktop": 4,
        "color_scheme": "background-1",
        "image_ratio": "square",
        "show_secondary_image": true,
        "show_vendor": false,
        "show_rating": false,
        "columns_mobile": "2",
        "padding_top": 36,
        "padding_bottom": 28
      }
    },
    "main_collection_banner": {
      "type": "main-collection-banner",
      "settings": {
        "show_collection_description": true,
        "show_collection_image": false,
        "color_scheme": "background-1"
      }
    },
    "main_collection_product_grid": {
      "type": "main-collection-product-grid",
      "settings": {
        "products_per_page": 16,
        "columns_desktop": 4,
        "image_ratio": "adapt",
        "show_secondary_image": false,
        "show_vendor": false,
        "show_rating": false,
        "enable_filtering": true,
        "enable_sorting": true,
        "columns_mobile": "2",
        "padding_top": 36,
        "padding_bottom": 36
      }
    },
    "main_list_collections": {
      "type": "main-list-collections",
      "settings": {
        "title": "Collections",
        "sort": "alphabetical",
        "image_ratio": "square"
      }
    },

    "main_cart_items": {
      "type": "main-cart-items",
      "settings": {
        "padding_top": 36,
        "padding_bottom": 36
      }
    },
    "main_cart_footer": {
      "type": "main-cart-footer",
      "blocks": {
        "subtotal": {
          "type": "subtotal",
          "settings": {
          }
        },
        "buttons": {
          "type": "buttons",
          "settings": {
          }
        }
      },
      "block_order": [
        "subtotal",
        "buttons"
      ],
      "settings": {
      }
    },
    "integration-reviews-lai-ali-express-io": {
      "type": "integration-reviews-lai-ali-express-io",
      "settings":
        {
         'enabled' : true
        },


    },
    "integration-reviews-stamped-io": {
      "type": "integration-reviews-stamped-io",
      "settings":   {
        'enabled' : true
       },
    },
    "integration-reviews-areviews-aliexpress": {
      "type": "integration-reviews-areviews-aliexpress",
      "settings":   {
        'enabled' : true
       },
    },
    "integration-reviews-bazaarvoice" : {
      "type" : "integration-reviews-bazaarvoice",
      "settings": {
        'enabled' : true,
        'shop_id' : '',

      }
    },
    "integration-reviews-judge-me": {
      "type": "integration-reviews-judge-me",
      "settings":   {
        'enabled' : true
       },
    },
    "integration-reviews-loox": {
      "type": "integration-reviews-loox",
      "settings":   {
        'enabled' : true
       },
    },
    "integration-reviews-okendo": {
      "type": "integration-reviews-okendo",
      "settings":   {
        'enabled' : true
       },
    },
    "integration-reviews-opinew": {
      "type": "integration-reviews-opinew",
      "settings":   {
        'enabled' : true
       },
    },
    "integration-reviews-ryviu": {
      "type": "integration-reviews-ryviu",
      "settings":   {
        'enabled' : true
       },
    },
    "integration-reviews-shopify-product-reviews": {
      "type": "integration-reviews-shopify-product-reviews",
      "settings":   {
        'enabled' : true
       },
    },
    "integration-reviews-shopperapproved": {
      "type": "integration-reviews-shopperapproved",
      "settings":   {
        'enabled' : true
       },
    },
    "integration-reviews-review-io" : {
      "type" : "integration-reviews-review-io",
      "settings": {
        'enabled' : true,
        'shop_id' : '',

      }
    },
    "integration-reviews-yotpo" : {
      "type" : "integration-reviews-yotpo",
      "settings": {
        'enabled' : true,
        'api_key' : '',

      }
    },
    "integration-reviews-ssw": {
      "type": "integration-reviews-ssw",
      "settings":   {
        'enabled' : true
       },
    },
    "integration-reviews-helpfulcrowd" : {
      "type" : "integration-reviews-helpfulcrowd",
      "settings": {
        'enabled' : true,
        'client_id' : '',

      }
    },
    "integration-reviews-alireviews": {
      "type": "integration-reviews-alireviews",
      "settings":   {
        'enabled' : true
       },
    },
    "footer": {
      "name": "Footer",
      "blocks": {
        "link_list": {
          "type": "link_list",
          "settings": {
            "heading": "Quick links",
            "menu": "footer"
          }
        },

        "text": {
          "type": "text",
          "settings": {
            "heading": "Our mission",
            "subtext": "<p>Share contact information, store details, and brand content with your customers.<\/p>"
          }
        },
        "image" :{
          "type": "image",
          "settings": {
            "image_width": 100,
            "alignment": "center"
          }
        },
        "brand_information" :{
          "type": "brand_information",
          "settings": {
            "show_social": true
          }
        }
      },
      "block_order": [
        "link_list",
        "text",
        "image",
        "brand_information"
      ],
      "settings": {
        "newsletter_enable": {
          "label": "Show email signup"
        },
        "newsletter_heading": {
          "label": "Heading"
        },
        "header__1": {
          "content": "Email Signup",
          "info": "Subscribers added automatically to your “accepted marketing” customer list. [Learn more](https://help.shopify.com/manual/customers/manage-customers)"
        },
        "header__2": {
          "content": "Social media icons",
          "info": "To display your social media accounts, link them in your theme settings."
        },
        "show_social": {
          "label": "Show social media icons"
        },
        "header__3": {
          "content": "Country/region selector"
        },
        "header__4": {
          "info": "To add a country/region, go to your [payment settings.](/admin/settings/payments)"
        },
        "enable_country_selector": {
          "label": "Enable country/region selector"
        },
        "header__5": {
          "content": "Language selector"
        },
        "header__6": {
          "info": "To add a language, go to your [language settings.](/admin/settings/languages)"
        },
        "enable_language_selector": {
          "label": "Enable language selector"
        },
        "header__7": {
          "content": "Payment methods"
        },
        "payment_enable": {
          "label": "Show payment icons"
        },
        "margin_top": {
          "label": "Top margin"
        }
      }
    },
    "integration-reviews-proviews-io": {
      "type": "integration-reviews-proviews-io",
      "settings":   {
        'enabled' : true
       },
    },
    "featured-collection-slider": {
      "type": "featured-collection-slider",
      "settings": {
          "title": "",
          "subtitle": "",
          "heading_size": "h1",
          "collection": "best-sellers",
          "products_to_show": 20,
          "columns_desktop": 5,
          "image_ratio": "adapt",
          "show_secondary_image": false,
          "columns_mobile": 3,
          "padding_top": 0
      }
    },
    "multirow": {
      "type": "multirow",
      "blocks": {
        "row-1": {
          "type": "row",
          "settings": {
            "caption": "Caption",
            "heading": "Row",
            "text": "<p>Pair text with an image to focus on your chosen product, collection, or blog post. Add details on availability, style, or even provide a review.<\/p>",
            "button_label": "Button label",
            "button_link": ""
          }
        },
        "row-2": {
          "type": "row",
          "settings": {
            "caption": "Caption",
            "heading": "Row",
            "text": "<p>Pair text with an image to focus on your chosen product, collection, or blog post. Add details on availability, style, or even provide a review.<\/p>",
            "button_label": "Button label",
            "button_link": ""
          }
        },
        "row-3": {
          "type": "row",
          "settings": {
            "caption": "Caption",
            "heading": "Row",
            "text": "<p>Pair text with an image to focus on your chosen product, collection, or blog post. Add details on availability, style, or even provide a review.<\/p>",
            "button_label": "Button label",
            "button_link": ""
          }
        },
        "custom-html": {
          "type": "custom-html",
          "settings": {
            "html": ""
          }
        }
      },
      "block_order": [
        "row-1",
        "row-2",
        "row-3"
       ],
      "settings": {
        "image_height": "medium",
        "desktop_image_width": "medium",
        "heading_size": "h1",
        "text_style": "body",
        "button_style": "secondary",
        "desktop_content_position": "middle",
        "desktop_content_alignment": "left",
        "image_layout": "alternate-left",
        "section_color_scheme": "",
        "row_color_scheme": "",
        "mobile_content_alignment": "left",
        "padding_top": 36,
        "padding_bottom": 36
      }
    },
    "featured-blog" : {
      "type": "featured-blog",
      "settings": {
        "heading": "Blog posts",
        "heading_size": "h1",
        "blog": "news",
        "post_limit": 3,
        "columns_desktop": 3,
        "color_scheme": "",
        "show_image": true,
        "show_date": true,
        "show_author": false,
        "show_view_all": true,
        "padding_top": 36,
        "padding_bottom": 36
      }
    },
    "amp-review-fallback": {
      "type": "amp-review-fallback",
      "settings":   {
        'enabled' : true
      },
    },
    "main-page" : {
      "name": "Page",
      "tag": "section",
      "class": "section",
      "settings": [
        {
          "type": "header",
          "content": "t:sections.all.padding.section_padding_heading"
        },
        {
          "type": "range",
          "id": "padding_top",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_top",
          "default": 36
        },
        {
          "type": "range",
          "id": "padding_bottom",
          "min": 0,
          "max": 100,
          "step": 4,
          "unit": "px",
          "label": "t:sections.all.padding.padding_bottom",
          "default": 36
        }
      ]
    },

  }

}






  constructor() {

  }

  static getSchemaObject(section) {

    let version : any = localStorage.getItem('dawnVersion');
    // version = Number(version);
    console.log("is theme 2.0",typeof version,section);

    //  console.log(section);
     let tmp = JSON.parse(localStorage.getItem('ampify-user'));
     if(version == '2'){

      if(Constants.schemasV7[section] && Constants.schemasV7[section].settings){

         return Constants.schemasV7[section].settings;
      }

    } else if(version == '3'){

      if(Constants.schemasV10[section] && Constants.schemasV10[section].settings){

        return Constants.schemasV10[section].settings;
      }
    } else if(version == 'ef420'){

      if(Constants.schemasEf420[section] && Constants.schemasEf420[section].settings){
        return Constants.schemasEf420[section].settings;
      }

    }
    //  if(section == 'header' || section == "main-product"  || section == 'image-banner'){
//      if(tmp.shopDomain == 'ef420.myshopify.com'){
      // if(Constants.schemasEf420[section] && Constants.schemasEf420[section].settings){
      //   return Constants.schemasEf420[section].settings;
      // } else {
//         return false;
//       }
//     }
//     else {
//       if(Constants.schemasV7[section] && Constants.schemasV7[section].settings){

//      return Constants.schemasV7[section].settings;

//    } else {
//      return false;
//    }
//  }
//      } else {
//       if(Constants.schemasV7[section] && Constants.schemasV7[section].settings){
//      return Constants.schemasV7[section].settings;
//    } else {
//      return false;
//    }
//  }

  }

  static getBlockSchemaObject(section, subsection) {

    let version : any = localStorage.getItem('dawnVersion');
    // version = Number(version);
    console.log("is theme 2.0",version);
    let tmp = JSON.parse(localStorage.getItem('ampify-user'));
    let blocks = []
    // if(section == 'header' || section == "main-product" || section == "collection-list"){
    //   if(tmp.shopDomain == 'ef420.myshopify.com'){

    //  blocks = Constants.schemasEf420[section].blocks;

    //   } else {
    //  blocks = Constants.schemasV7[section].blocks;

    //   }
    // } else {
    //  blocks = Constants.schemasV7[section].blocks;

    // }
    // let blocks = Constants.schemas[section].blocks;

    if(version == '2'){

      blocks = Constants.schemasV7[section].blocks;

    } else if(version == '3'){

      blocks = Constants.schemasV10[section].blocks;

    } else if(version == 'ef420'){

      blocks = Constants.schemasEf420[section].blocks;

    }

    for (let i = 0; i < blocks.length; i++) {

      if (blocks[i]['type'] == subsection) {

        return blocks[i].settings
      }

    }


  }

  static getSchemaThemeSettObject(section) {

    let version : any = localStorage.getItem('dawnVersion');
    // version = Number(version);
    // console.log("///////////////////////////is theme 2.0",section);

    if(version == '2'){

      return Constants.globeSettingSchemaV7[section].settings;

    } else if(version == '3'){

      return Constants.globalSettingSchemaV10[section].settings;

    } else if(version == 'ef420'){

      return Constants.globalSettingSchemaEf420[section].settings;
    }
  }

  static getColorSchemesSettingObject(){

    return Constants.globalSettingSchemaV10['colors'].settings;

  }

  static getNewSectionSetting(section) {

    let version : any = localStorage.getItem('dawnVersion');
    // version = Number(version);
    console.log("is theme 2.0",version);

    if(version == '2'){

      return Constants.addSectionDataV7.sections[section];

    } else if(version == '3'){

      return Constants.addSectionDataV10.sections[section];

    } else if(version == 'ef420'){

      return Constants.addSectionDataEf420.sections[section];

    }


  }

  static getSchemaPresets(section) {

    let version : any = localStorage.getItem('dawnVersion');
    // version = Number(version);
    console.log("is theme 2.0",version);

    if(version == '2'){

      return Constants.schemasV7[section].presets[0].blocks;

    } else if(version == '3'){

      return Constants.schemasV10[section].presets[0].blocks;

    } else if(version == 'ef420'){

      return Constants.schemasEf420[section].presets[0].blocks;
    }


  }
  static getNewSectionBlockSetting(section, field) {

    let version : any = localStorage.getItem('dawnVersion');
    // version = Number(version);
    console.log("is theme 2.0",version);
    //console.log("presets", section, field)

    let tmp = section.split("-").join("_")
    //console.log("presets", tmp, field)

    if(version == '2'){

      return Constants.addSectionDataV7.sections[tmp].blocks[field]

    } else if(version == '3'){

      return Constants.addSectionDataV10.sections[tmp].blocks[field]

    } else if(version == 'ef420'){

      return Constants.addSectionDataEf420.sections[tmp].blocks[field]
    }


  }


}