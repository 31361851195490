import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../../auth/auth.service';
import { AuthUser } from '../../../auth/auth.model';
import { PwaOnboardingService } from '../pwa-onboarding.service';

@Component({
  selector: 'am-pwa-onboarding-welcome',
  templateUrl: './pwa-onboarding-welcome.component.html',
  styleUrls: ['./pwa-onboarding-welcome.component.scss']
})
export class PwaOnboardingWelcomeComponent implements OnInit {

  user: AuthUser;

  constructor(
    private authService: AuthService,
    private pwaOnboardingService: PwaOnboardingService
  ) {
    this.pwaOnboardingService.setCurrentPage(1);
  }

  ngOnInit() {
    this.user = this.authService.getUser();

    this.pwaOnboardingService.setOnboardingStatus('DONE')
      .subscribe(res => { }, err => { });
  }

  getFirstName(name: string): string {
    try {
      return name.split(' ')[0];
    } catch (e) {
      return name;
    }
  }

}
