import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../../auth/auth.service';
import { AuthUser } from '../../../auth/auth.model';
import { OnboardingService } from '../onboarding.service';

@Component({
  selector: 'am-onboarding-welcome',
  templateUrl: './onboarding-welcome.component.html',
  styleUrls: ['./onboarding-welcome.component.scss']
})
export class OnboardingWelcomeComponent implements OnInit {

  user: AuthUser;

  constructor(
    private authService: AuthService,
    private onboardingService: OnboardingService
  ) {
    this.onboardingService.setCurrentPage(1);
  }

  ngOnInit() {
    this.user = this.authService.getUser();

    this.onboardingService.setOnboardingStatus('DONE')
      .subscribe(res => {

      }, err => { });
  }

  getFirstName(name: string): string {
    try {
      return name.split(' ')[0];
    } catch (e) {
      return name;
    }
  }

}
