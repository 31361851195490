// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,

  baseUrl: 'https://ap-amp.arraypointer.co.in',
  // basePWAUrl: 'https://ap-pwa.ap.ngrok.io',
  // baseUrl: 'https://ampifyme.com',
  // basePWAUrl: 'https://pwa.ampifyme.com',

  basePWAUrl : 'https://ap-dev.progressifyme.com',
  PriceAndBackInStockId: `d8085696-e81d-434c-9e5d-61f73772fa67`
};
