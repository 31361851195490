import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { PwaOnboardingService } from '../pwa-onboarding.service';

@Component({
  selector: 'am-pwa-onboarding-layout',
  templateUrl: './pwa-onboarding-layout.component.html',
  styleUrls: ['./pwa-onboarding-layout.component.scss']
})
export class PwaOnboardingLayoutComponent implements OnInit {

  onboardingConfiguration: any;

  constructor(
    private onboardingService: PwaOnboardingService,
    private titleService: Title
  ) {
    this.onboardingConfiguration = this.onboardingService.onboardingConfiguration;
  }

  ngOnInit() {
    this.titleService.setTitle('PWA by AmpifyMe');
  }

  getProgressPercentage(): number {
    return this.onboardingService.getProgressPercentage();
  }

}
