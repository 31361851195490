import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'idToLabel'
})
export class IdToLabelPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    value = value.charAt(0).toUpperCase() + value.slice(1);
    return value.replace(/_/g, ' ');
  }

}
