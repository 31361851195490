import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { AuthService } from '../../auth/auth.service';
import { AuthUser } from '../../auth/auth.model';
import { PwaConfiguration } from '../pwa-configuration/pwa-configuration.model';

@Component({
  selector: 'am-pwa-layout',
  templateUrl: './pwa-layout.component.html',
  styleUrls: ['./pwa-layout.component.scss']
})
export class PwaLayoutComponent implements OnInit {

  loading = true;
 

  planName: string = 'free';
  config: PwaConfiguration;
  sidebar = {
    expanded: false,
    showFullApp: false
  };
  user: AuthUser;

  constructor(
    private router: Router,
    private authService: AuthService,
    private titleService: Title
  ) { }

  ngOnInit() {

    this.titleService.setTitle('PWA by AmpifyMe');

    // Get user data
    this.authService.getUserDetails('pwa')
      .subscribe(res => {
        this.authService.setUser(res);
        this.user = res;

        this.loading = false;

        try {
          window['Beacon']('config', {
            color: '#f3786d'
          });
          window['Beacon']('identify', {
            name: this.user.name,
            email: this.user.supportEmail,
            'tier': this.user.planName,
            'shopDomain': this.user.shopDomain,
            'install date': this.user.installDate
          });
        } catch (e) {

        }
      }, err => {
        // Send to error page
        this.router.navigate(['/error?app=pwa']);
        return;
      });
      
  }

  sidebarExpandedChanged(sidebarExpanded): void {
    this.sidebar.expanded = sidebarExpanded;
  }
  

}
