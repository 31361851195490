import { Component, OnInit } from '@angular/core';

import { PwaOnboardingService } from '../pwa-onboarding.service';

@Component({
  selector: 'am-pwa-onboarding-explanation',
  templateUrl: './pwa-onboarding-explanation.component.html',
  styleUrls: ['./pwa-onboarding-explanation.component.scss']
})
export class PwaOnboardingExplanationComponent implements OnInit {

  constructor(
    private pwaOnboardingService: PwaOnboardingService
  ) {
    this.pwaOnboardingService.setCurrentPage(2);
  }

  ngOnInit() {
    this.pwaOnboardingService.setOnboardingStatus('DONE')
      .subscribe(res => {

      }, err => { });
  }

}
