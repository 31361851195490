<div class="Polaris-SkeletonPage__Page" role="status" aria-label="Page loading">
  <div class="Polaris-SkeletonPage__Header Polaris-SkeletonPage__Header--hasSecondaryActions">
    <div class="Polaris-SkeletonPage__TitleAndPrimaryAction">
      <div class="Polaris-SkeletonPage__Title">
        <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeLarge"></div>
      </div>
      <div class="Polaris-SkeletonPage__PrimaryAction">
        <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeLarge"></div>
      </div>
    </div>
    <div class="Polaris-SkeletonPage__Actions">
      <div class="Polaris-SkeletonPage__Action" style="width: 92px;">
        <div class="Polaris-SkeletonBodyText__SkeletonBodyTextContainer">
          <div class="Polaris-SkeletonBodyText"></div>
        </div>
      </div>
      <div class="Polaris-SkeletonPage__Action" style="width: 99px;">
        <div class="Polaris-SkeletonBodyText__SkeletonBodyTextContainer">
          <div class="Polaris-SkeletonBodyText"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="Polaris-SkeletonPage__Content">
    <div class="Polaris-Layout">
      <div class="Polaris-Layout__Section">
        <div class="Polaris-Card">
          <div class="Polaris-Card__Section">
            <div class="Polaris-SkeletonBodyText__SkeletonBodyTextContainer">
              <div class="Polaris-SkeletonBodyText"></div>
              <div class="Polaris-SkeletonBodyText"></div>
              <div class="Polaris-SkeletonBodyText"></div>
            </div>
          </div>
        </div>
        <div class="Polaris-Card">
          <div class="Polaris-Card__Section">
            <div class="Polaris-TextContainer">
              <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeSmall"></div>
              <div class="Polaris-SkeletonBodyText__SkeletonBodyTextContainer">
                <div class="Polaris-SkeletonBodyText"></div>
                <div class="Polaris-SkeletonBodyText"></div>
                <div class="Polaris-SkeletonBodyText"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="Polaris-Card">
          <div class="Polaris-Card__Section">
            <div class="Polaris-TextContainer">
              <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeSmall"></div>
              <div class="Polaris-SkeletonBodyText__SkeletonBodyTextContainer">
                <div class="Polaris-SkeletonBodyText"></div>
                <div class="Polaris-SkeletonBodyText"></div>
                <div class="Polaris-SkeletonBodyText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="Polaris-Layout__Section Polaris-Layout__Section--secondary">
        <div class="Polaris-Card">
          <div class="Polaris-Card__Section">
            <div class="Polaris-TextContainer">
              <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeSmall"></div>
              <div class="Polaris-SkeletonBodyText__SkeletonBodyTextContainer">
                <div class="Polaris-SkeletonBodyText"></div>
                <div class="Polaris-SkeletonBodyText"></div>
              </div>
            </div>
          </div>
          <div class="Polaris-Card__Section">
            <div class="Polaris-SkeletonBodyText__SkeletonBodyTextContainer">
              <div class="Polaris-SkeletonBodyText"></div>
            </div>
          </div>
        </div>
        <div class="Polaris-Card Polaris-Card--subdued">
          <div class="Polaris-Card__Section">
            <div class="Polaris-TextContainer">
              <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeSmall"></div>
              <div class="Polaris-SkeletonBodyText__SkeletonBodyTextContainer">
                <div class="Polaris-SkeletonBodyText"></div>
                <div class="Polaris-SkeletonBodyText"></div>
              </div>
            </div>
          </div>
          <div class="Polaris-Card__Section">
            <div class="Polaris-SkeletonBodyText__SkeletonBodyTextContainer">
              <div class="Polaris-SkeletonBodyText"></div>
              <div class="Polaris-SkeletonBodyText"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>