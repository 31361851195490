import { Component, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'am-amp-layout-left-sidebar',
  templateUrl: './amp-layout-left-sidebar.component.html',
  styleUrls: ['./amp-layout-left-sidebar.component.scss']
})
export class AmpLayoutLeftSidebarComponent implements OnInit {

  @Input()
  sidebar: any;

  constructor() { }

  ngOnInit() {
  }

  closeSidebar(): void {
    this.sidebar.expanded = false;
  }

}
