import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getLocalizedName'
})
export class GetLocalizedNamePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value && value.en) {
      return value.en;
    }
    return value;
  }

}
