import { Component, OnInit, Input } from '@angular/core';

import { NgxPubSubService } from '@pscoped/ngx-pub-sub';

import { SectionSetting, EditComponentDetails } from '../../../am-builder.model';
import { AmBuilderService } from '../../am-builder.service';

@Component({
  selector: 'am-edit-field',
  templateUrl: './edit-field.component.html',
  styleUrls: ['./edit-field.component.scss']
})
export class EditFieldComponent implements OnInit {

  @Input()
  component: EditComponentDetails;

  @Input()
  item: SectionSetting;

  @Input()
  values: any;

  globalSettings: any;
  sections: any;

  tmpObj : any = {};

  constructor(
    private pubsub: NgxPubSubService,
    private amBuilderService: AmBuilderService
  ) {
    this.globalSettings = amBuilderService.theme.global_settings;
    this.sections = amBuilderService.theme.sections;
  }

  ngOnInit() {
    if (!this.values) {
      this.values = {};
    }
    // Set default values to values if they do not exist
    if (!this.values[this.item.id]) {
      // Try to set the default field
      // this.values[this.item.id] = this.item.default;
      // Maybe it's the object for localization
      if (this.item.default && this.item.default['en']) {
        // this.values[this.item.id] = this.item.default['en'];
        this.item.placeholder = this.item.default
        // this.tmpObj[this.item.id] = 1;
      }
    }
    // console.log("values",this.values,this.item);

  }

  onValueChangedChild(event) {
    this.valueChanged(event.id, event.value);
  }

  valueChanged(id: string, newValue: any) {
    const change = {
      component_id: this.component.id,
      value: newValue,
      field_id: id
    };
    this.pubsub.publishEvent('FIELD_CHANGE', change);
  }

  onValueUpdatedChild(event) {
    this.valueUpdated(event.id, event.value);
  }

  valueUpdated(id: string, newValue: any) {
    // console.log("id",id);
    // console.log("new value",newValue);

    const conf = {
      global_settings: this.globalSettings,
      sections: this.sections
    };
    this.pubsub.publishEvent('CONFIGURATION_CHANGE', conf);
  }

  onConfigurationChange() {
    const conf = {
      global_settings: this.globalSettings,
      sections: this.sections
    };
    this.pubsub.publishEvent('CONFIGURATION_CHANGE', conf);
  }

  checkValueisDefault(id,value){



  }
  ngOnDestroy(){

    console.log("ngOnDestroy calling")
  }
}
