<li *ngIf="sectionHasContent(type, id)" (click)="openDetails()"
  class="theme-editor-action-list-item component-item component-item-sortable" data-type="product-template">

  <div class="theme-editor-action-list-item-content">
    <div class="theme-editor-action-list-item-icon" [ngSwitch]="type">


      <svg *ngSwitchCase="'ADD_SECTION_ITEM'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <title>add-section</title>
        <path
          d="M2 0H1C.448 0 0 .448 0 1v1c0 .552.448 1 1 1s1-.448 1-1c.552 0 1-.448 1-1s-.448-1-1-1zm4 2h2c.552 0 1-.448 1-1s-.448-1-1-1H6c-.552 0-1 .448-1 1s.448 1 1 1zm8-2h-2c-.552 0-1 .448-1 1s.448 1 1 1h2c.552 0 1-.448 1-1s-.448-1-1-1zM8 18H6c-.552 0-1 .448-1 1s.448 1 1 1h2c.552 0 1-.448 1-1s-.448-1-1-1zm6 0h-2c-.552 0-1 .448-1 1s.448 1 1 1h2c.552 0 1-.448 1-1s-.448-1-1-1zm5-18h-1c-.552 0-1 .448-1 1s.448 1 1 1c0 .552.448 1 1 1s1-.448 1-1V1c0-.552-.448-1-1-1zm0 17c-.552 0-1 .448-1 1-.552 0-1 .448-1 1s.448 1 1 1h1c.552 0 1-.448 1-1v-1c0-.552-.448-1-1-1zM2 18c0-.552-.448-1-1-1s-1 .448-1 1v1c0 .552.448 1 1 1h1c.552 0 1-.448 1-1s-.448-1-1-1zm-1-3c.552 0 1-.448 1-1v-2c0-.552-.448-1-1-1s-1 .448-1 1v2c0 .552.448 1 1 1zm0-6c.552 0 1-.448 1-1V6c0-.552-.448-1-1-1s-1 .448-1 1v2c0 .552.448 1 1 1zm18 2c-.552 0-1 .448-1 1v2c0 .552.448 1 1 1s1-.448 1-1v-2c0-.552-.448-1-1-1zm0-6c-.552 0-1 .448-1 1v2c0 .552.448 1 1 1s1-.448 1-1V6c0-.552-.448-1-1-1zm-5 4h-3V6c0-.552-.448-1-1-1s-1 .448-1 1v3H6c-.552 0-1 .448-1 1s.448 1 1 1h3v3c0 .552.448 1 1 1s1-.448 1-1v-3h3c.552 0 1-.448 1-1s-.448-1-1-1z">
        </path>
      </svg>

      <svg *ngSwitchCase="'header'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <title>header</title>
        <path
          d="M19 0c.552 0 1 .448 1 1v7c0 .552-.448 1-1 1H1c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1h18zM2 7h16V2H2v5zm6 11c.552 0 1 .448 1 1s-.448 1-1 1H6c-.552 0-1-.448-1-1s.448-1 1-1h2zm6 0h-2c-.552 0-1 .448-1 1s.448 1 1 1h2c.552 0 1-.448 1-1s-.448-1-1-1zm5-1c-.552 0-1 .448-1 1-.552 0-1 .448-1 1s.448 1 1 1h1c.552 0 1-.448 1-1v-1c0-.552-.448-1-1-1zM2 18c0-.552-.448-1-1-1s-1 .448-1 1v1c0 .552.448 1 1 1h1c.552 0 1-.448 1-1s-.448-1-1-1zm-1-3c.552 0 1-.448 1-1v-2c0-.552-.448-1-1-1s-1 .448-1 1v2c0 .552.448 1 1 1zm18-4c-.552 0-1 .448-1 1v2c0 .552.448 1 1 1s1-.448 1-1v-2c0-.552-.448-1-1-1z">
        </path>
      </svg>

      <svg *ngSwitchCase="'announcement-bar'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <title>header</title>
        <path
          d="M19 0c.552 0 1 .448 1 1v7c0 .552-.448 1-1 1H1c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1h18zM2 7h16V2H2v5zm6 11c.552 0 1 .448 1 1s-.448 1-1 1H6c-.552 0-1-.448-1-1s.448-1 1-1h2zm6 0h-2c-.552 0-1 .448-1 1s.448 1 1 1h2c.552 0 1-.448 1-1s-.448-1-1-1zm5-1c-.552 0-1 .448-1 1-.552 0-1 .448-1 1s.448 1 1 1h1c.552 0 1-.448 1-1v-1c0-.552-.448-1-1-1zM2 18c0-.552-.448-1-1-1s-1 .448-1 1v1c0 .552.448 1 1 1h1c.552 0 1-.448 1-1s-.448-1-1-1zm-1-3c.552 0 1-.448 1-1v-2c0-.552-.448-1-1-1s-1 .448-1 1v2c0 .552.448 1 1 1zm18-4c-.552 0-1 .448-1 1v2c0 .552.448 1 1 1s1-.448 1-1v-2c0-.552-.448-1-1-1z">
        </path>
      </svg>

      <svg *ngSwitchCase="'footer'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <title>footer</title>
        <path
          d="M19 11c.552 0 1 .448 1 1v7c0 .552-.448 1-1 1H1c-.552 0-1-.448-1-1v-7c0-.552.448-1 1-1h18zM2 18h16v-5H2v5zM6 2c-.552 0-1-.448-1-1s.448-1 1-1h2c.552 0 1 .448 1 1s-.448 1-1 1H6zm6 0h2c.552 0 1-.448 1-1s-.448-1-1-1h-2c-.552 0-1 .448-1 1s.448 1 1 1zm7-2h-1c-.552 0-1 .448-1 1s.448 1 1 1c0 .552.448 1 1 1s1-.448 1-1V1c0-.552-.448-1-1-1zM1 3c.552 0 1-.448 1-1 .552 0 1-.448 1-1s-.448-1-1-1H1C.448 0 0 .448 0 1v1c0 .552.448 1 1 1zm0 6c.552 0 1-.448 1-1V6c0-.552-.448-1-1-1s-1 .448-1 1v2c0 .552.448 1 1 1zm18-4c-.552 0-1 .448-1 1v2c0 .552.448 1 1 1s1-.448 1-1V6c0-.552-.448-1-1-1z">
        </path>
      </svg>
      <svg *ngSwitchCase="'sidebar'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <title>sidebar</title>
        <g>
          <path
            d="M20,19 L20,1 C20,0.448 19.553,0 19,0 L12,0 C11.447,0 11,0.448 11,1 L11,19 C11,19.553 11.447,20 12,20 L19,20 C19.553,20 20,19.553 20,19 L20,19 Z M2,8 C2,8.552 1.553,9 1,9 C0.447,9 0,8.552 0,8 L0,6 C0,5.448 0.447,5 1,5 C1.553,5 2,5.448 2,6 L2,8 Z M2,14 L2,12 C2,11.447 1.553,11 1,11 C0.447,11 0,11.447 0,12 L0,14 C0,14.553 0.447,15 1,15 C1.553,15 2,14.553 2,14 M3,19 C3,18.447 2.553,18 2,18 C2,17.447 1.553,17 1,17 C0.447,17 0,17.447 0,18 L0,19 C0,19.553 0.447,20 1,20 L2,20 C2.553,20 3,19.553 3,19 M2,2 C2.553,2 3,1.552 3,1 C3,0.448 2.553,0 2,0 L1,0 C0.447,0 0,0.448 0,1 L0,2 C0,2.552 0.447,3 1,3 C1.553,3 2,2.552 2,2 M5,1 C5,1.552 5.447,2 6,2 L8,2 C8.553,2 9,1.552 9,1 C9,0.448 8.553,0 8,0 L6,0 C5.447,0 5,0.448 5,1 M9,19 C9,18.447 8.553,18 8,18 L6,18 C5.447,18 5,18.447 5,19 C5,19.553 5.447,20 6,20 L8,20 C8.553,20 9,19.553 9,19 M13,18 L18,18 L18,2 L13,2 L13,18 Z"
            transform="translate(10.000000, 10.000000) scale(-1, 1) translate(-10.000000, -10.000000)"></path>
        </g>
      </svg>


      <svg *ngSwitchCase="'action-bar'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <title>action-bar</title>
        <path fill="currentColor" d="M1 6h18v8h-18v-8z"></path>
        <path
          d="M19 5v0c0.553 0 1 0.448 1 1v8c0 0.553-0.447 1-1 1h-18c-0.553 0-1-0.447-1-1v-8c0-0.552 0.447-1 1-1h18zM6 2c-0.553 0-1-0.448-1-1s0.447-1 1-1h2c0.553 0 1 0.448 1 1s-0.447 1-1 1h-2zM12 2h2c0.553 0 1-0.448 1-1s-0.447-1-1-1h-2c-0.553 0-1 0.448-1 1s0.447 1 1 1zM19 0h-1c-0.553 0-1 0.448-1 1s0.447 1 1 1c0 0.552 0.447 1 1 1s1-0.448 1-1v-1c0-0.552-0.447-1-1-1zM1 3c0.553 0 1-0.448 1-1 0.553 0 1-0.448 1-1s-0.447-1-1-1h-1c-0.553 0-1 0.448-1 1v1c0 0.552 0.447 1 1 1zM14 18h-2c-0.553 0-1 0.447-1 1s0.447 1 1 1h2c0.553 0 1-0.447 1-1s-0.447-1-1-1zM8 18h-2c-0.553 0-1 0.447-1 1s0.447 1 1 1h2c0.553 0 1-0.447 1-1s-0.447-1-1-1zM2 18c0-0.553-0.447-1-1-1s-1 0.447-1 1v1c0 0.553 0.447 1 1 1h1c0.553 0 1-0.447 1-1s-0.447-1-1-1zM19 17c-0.553 0-1 0.447-1 1-0.553 0-1 0.447-1 1s0.447 1 1 1h1c0.553 0 1-0.447 1-1v-1c0-0.553-0.447-1-1-1zM2 13h16v-6h-16v6z">
        </path>
      </svg>

      <svg *ngSwitchCase="'product-template'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <title>products</title>
        <path
          d="M19 0h-9c-.265 0-.52.106-.707.293l-9 9c-.39.39-.39 1.023 0 1.414l9 9c.195.195.45.293.707.293s.512-.098.707-.293l9-9c.188-.187.293-.442.293-.707V1c0-.552-.448-1-1-1zm-9 17.586L2.414 10 4 8.414 11.586 16 10 17.586zm8-8l-5 5L5.414 7l5-5H18v7.586zM15 6c.552 0 1-.448 1-1s-.448-1-1-1-1 .448-1 1 .448 1 1 1z">
        </path>
      </svg>

      <svg *ngSwitchCase="'featured-product'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <title>products</title>
        <path
          d="M19 0h-9c-.265 0-.52.106-.707.293l-9 9c-.39.39-.39 1.023 0 1.414l9 9c.195.195.45.293.707.293s.512-.098.707-.293l9-9c.188-.187.293-.442.293-.707V1c0-.552-.448-1-1-1zm-9 17.586L2.414 10 4 8.414 11.586 16 10 17.586zm8-8l-5 5L5.414 7l5-5H18v7.586zM15 6c.552 0 1-.448 1-1s-.448-1-1-1-1 .448-1 1 .448 1 1 1z">
        </path>
      </svg>

      <svg *ngSwitchCase="'hero'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <title>image-with-text</title>
        <path
          d="M19 4h-6c-.553 0-1 .448-1 1s.447 1 1 1h5v4.586l-1.293-1.293c-.39-.39-1.023-.39-1.414 0l-4.42 4.42-2.318-1.545c-.336-.225-.774-.225-1.11 0L6 13.132V13c0-.553-.447-1-1-1s-1 .447-1 1v6c0 .553.447 1 1 1h14c.553 0 1-.447 1-1V5c0-.552-.447-1-1-1zM6 18v-2.465l2-1.333 2.445 1.63c.395.266.925.213 1.262-.125L16 11.414l2 2V18H6zm3-8.5c0 .83.672 1.5 1.5 1.5s1.5-.67 1.5-1.5S11.328 8 10.5 8 9 8.67 9 9.5zM1 2h8c.553 0 1-.448 1-1s-.447-1-1-1H1C.447 0 0 .448 0 1s.447 1 1 1zm0 4h8c.553 0 1-.448 1-1s-.447-1-1-1H1c-.553 0-1 .448-1 1s.447 1 1 1zm6 3c0-.552-.447-1-1-1H1c-.553 0-1 .448-1 1s.447 1 1 1h5c.553 0 1-.448 1-1z">
        </path>
      </svg>

      <svg *ngSwitchCase="'blog'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <title>blog-posts</title>
        <path fill="currentColor" d="M11 13l6-6-4-4-6 6z"></path>
        <path
          d="M18.878 1.122c-1.445-1.446-3.967-1.446-5.414 0L13 1.586 11.707.293C11.52.106 11.265 0 11 0H1C.447 0 0 .448 0 1v18c0 .553.447 1 1 1h14c.552 0 1-.447 1-1V9.414l2.878-2.878C19.6 5.813 20 4.85 20 3.83c0-1.024-.4-1.985-1.122-2.708zm-1.414 4L17 5.586 14.414 3l.464-.464c.69-.69 1.895-.69 2.586 0 .346.346.536.805.536 1.293s-.19.944-.536 1.29zM14 18H2V2h8.586l1 1-5.293 5.293c-.107.107-.18.233-.227.367-.003.01-.012.015-.015.024l-2 6c-.12.36-.023.756.245 1.023.19.19.446.293.707.293.107 0 .213-.016.317-.05l6-2c.01-.004.014-.013.023-.016.135-.048.26-.12.368-.227L14 11.414V18zm-6.563-7.15l1.712 1.713-2.57.856.855-2.57zm6.856-2.557L11 11.586 8.414 9 13 4.414 15.586 7l-1.293 1.293z">
        </path>
      </svg>

      <svg *ngSwitchCase="'featured-blog'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <title>blog-posts</title>
        <path fill="currentColor" d="M11 13l6-6-4-4-6 6z"></path>
        <path
          d="M18.878 1.122c-1.445-1.446-3.967-1.446-5.414 0L13 1.586 11.707.293C11.52.106 11.265 0 11 0H1C.447 0 0 .448 0 1v18c0 .553.447 1 1 1h14c.552 0 1-.447 1-1V9.414l2.878-2.878C19.6 5.813 20 4.85 20 3.83c0-1.024-.4-1.985-1.122-2.708zm-1.414 4L17 5.586 14.414 3l.464-.464c.69-.69 1.895-.69 2.586 0 .346.346.536.805.536 1.293s-.19.944-.536 1.29zM14 18H2V2h8.586l1 1-5.293 5.293c-.107.107-.18.233-.227.367-.003.01-.012.015-.015.024l-2 6c-.12.36-.023.756.245 1.023.19.19.446.293.707.293.107 0 .213-.016.317-.05l6-2c.01-.004.014-.013.023-.016.135-.048.26-.12.368-.227L14 11.414V18zm-6.563-7.15l1.712 1.713-2.57.856.855-2.57zm6.856-2.557L11 11.586 8.414 9 13 4.414 15.586 7l-1.293 1.293z">
        </path>
      </svg>

      <svg *ngSwitchCase="'article'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <title>blog-posts</title>
        <path fill="currentColor" d="M11 13l6-6-4-4-6 6z"></path>
        <path
          d="M18.878 1.122c-1.445-1.446-3.967-1.446-5.414 0L13 1.586 11.707.293C11.52.106 11.265 0 11 0H1C.447 0 0 .448 0 1v18c0 .553.447 1 1 1h14c.552 0 1-.447 1-1V9.414l2.878-2.878C19.6 5.813 20 4.85 20 3.83c0-1.024-.4-1.985-1.122-2.708zm-1.414 4L17 5.586 14.414 3l.464-.464c.69-.69 1.895-.69 2.586 0 .346.346.536.805.536 1.293s-.19.944-.536 1.29zM14 18H2V2h8.586l1 1-5.293 5.293c-.107.107-.18.233-.227.367-.003.01-.012.015-.015.024l-2 6c-.12.36-.023.756.245 1.023.19.19.446.293.707.293.107 0 .213-.016.317-.05l6-2c.01-.004.014-.013.023-.016.135-.048.26-.12.368-.227L14 11.414V18zm-6.563-7.15l1.712 1.713-2.57.856.855-2.57zm6.856-2.557L11 11.586 8.414 9 13 4.414 15.586 7l-1.293 1.293z">
        </path>
      </svg>

      <svg *ngSwitchCase="'collection'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <title>collections-list</title>
        <path fill="currentColor" d="M7 15h12v4H7v-4z"></path>
        <path
          d="M19 20c.553 0 1-.447 1-1V6c0-.297-.132-.578-.36-.77l-6-5c-.37-.307-.91-.307-1.28 0L10 2.198 7.64.23c-.37-.307-.91-.307-1.28 0l-6 5C.13 5.423 0 5.704 0 6v13c0 .553.447 1 1 1h18zM8 18v-2h10v2H8zm-6 0v-2h4v2H2zM7 2.302L8.438 3.5l-2.08 1.73C6.133 5.423 6 5.704 6 6v8H2V6.47L7 2.3zm6 0l5 4.167V14H8V6.47l5-4.17zM13 7c.553 0 1-.447 1-1s-.447-1-1-1c-.553 0-1 .447-1 1s.447 1 1 1z">
        </path>
      </svg>

      <svg *ngSwitchCase="'collection-list'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <title>collections-list</title>
        <path fill="currentColor" d="M7 15h12v4H7v-4z"></path>
        <path
          d="M19 20c.553 0 1-.447 1-1V6c0-.297-.132-.578-.36-.77l-6-5c-.37-.307-.91-.307-1.28 0L10 2.198 7.64.23c-.37-.307-.91-.307-1.28 0l-6 5C.13 5.423 0 5.704 0 6v13c0 .553.447 1 1 1h18zM8 18v-2h10v2H8zm-6 0v-2h4v2H2zM7 2.302L8.438 3.5l-2.08 1.73C6.133 5.423 6 5.704 6 6v8H2V6.47L7 2.3zm6 0l5 4.167V14H8V6.47l5-4.17zM13 7c.553 0 1-.447 1-1s-.447-1-1-1c-.553 0-1 .447-1 1s.447 1 1 1z">
        </path>
      </svg>

      <svg *ngSwitchCase="'list-collections-template'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <title>collections-list</title>
        <path fill="currentColor" d="M7 15h12v4H7v-4z"></path>
        <path
          d="M19 20c.553 0 1-.447 1-1V6c0-.297-.132-.578-.36-.77l-6-5c-.37-.307-.91-.307-1.28 0L10 2.198 7.64.23c-.37-.307-.91-.307-1.28 0l-6 5C.13 5.423 0 5.704 0 6v13c0 .553.447 1 1 1h18zM8 18v-2h10v2H8zm-6 0v-2h4v2H2zM7 2.302L8.438 3.5l-2.08 1.73C6.133 5.423 6 5.704 6 6v8H2V6.47L7 2.3zm6 0l5 4.167V14H8V6.47l5-4.17zM13 7c.553 0 1-.447 1-1s-.447-1-1-1c-.553 0-1 .447-1 1s.447 1 1 1z">
        </path>
      </svg>

      <svg *ngSwitchCase="'collection-template'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <title>collections-list</title>
        <path fill="currentColor" d="M7 15h12v4H7v-4z"></path>
        <path
          d="M19 20c.553 0 1-.447 1-1V6c0-.297-.132-.578-.36-.77l-6-5c-.37-.307-.91-.307-1.28 0L10 2.198 7.64.23c-.37-.307-.91-.307-1.28 0l-6 5C.13 5.423 0 5.704 0 6v13c0 .553.447 1 1 1h18zM8 18v-2h10v2H8zm-6 0v-2h4v2H2zM7 2.302L8.438 3.5l-2.08 1.73C6.133 5.423 6 5.704 6 6v8H2V6.47L7 2.3zm6 0l5 4.167V14H8V6.47l5-4.17zM13 7c.553 0 1-.447 1-1s-.447-1-1-1c-.553 0-1 .447-1 1s.447 1 1 1z">
        </path>
      </svg>

      <svg *ngSwitchCase="'featured-products'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <title>collections-list</title>
        <path fill="currentColor" d="M7 15h12v4H7v-4z"></path>
        <path
          d="M19 20c.553 0 1-.447 1-1V6c0-.297-.132-.578-.36-.77l-6-5c-.37-.307-.91-.307-1.28 0L10 2.198 7.64.23c-.37-.307-.91-.307-1.28 0l-6 5C.13 5.423 0 5.704 0 6v13c0 .553.447 1 1 1h18zM8 18v-2h10v2H8zm-6 0v-2h4v2H2zM7 2.302L8.438 3.5l-2.08 1.73C6.133 5.423 6 5.704 6 6v8H2V6.47L7 2.3zm6 0l5 4.167V14H8V6.47l5-4.17zM13 7c.553 0 1-.447 1-1s-.447-1-1-1c-.553 0-1 .447-1 1s.447 1 1 1z">
        </path>
      </svg>

      <svg *ngSwitchCase="'slideshow'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <title>slideshow</title>
        <path
          d="M19 0H1C.447 0 0 .448 0 1v14c0 .552.447 1 1 1h18c.553 0 1-.448 1-1V1c0-.552-.447-1-1-1zm-1 2v4.92l-3.375-2.7c-.397-.32-.972-.288-1.332.073l-4.42 4.42-2.318-1.545c-.324-.216-.738-.225-1.07-.025L2 9.233V2h16zM2 14v-2.434l3.972-2.383 2.473 1.65c.396.263.924.212 1.262-.126l4.367-4.367L18 9.48V14H2zm4 4c-.553 0-1 .448-1 1s.447 1 1 1c.553 0 1-.448 1-1s-.447-1-1-1zm4 0c-.553 0-1 .448-1 1s.447 1 1 1c.553 0 1-.448 1-1s-.447-1-1-1zm4 0c-.553 0-1 .448-1 1s.447 1 1 1c.553 0 1-.448 1-1s-.447-1-1-1z">
        </path>
      </svg>

      <svg *ngSwitchCase="'featured-content'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path fill="currentColor" d="M1 1h18v18H1V1z"></path>
        <path
          d="M19 0c.552 0 1 .448 1 1v18c0 .552-.448 1-1 1H1c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1h18zM2 18h16V2H2v16zm3-7h10c.552 0 1-.448 1-1s-.448-1-1-1H5c-.552 0-1 .448-1 1s.448 1 1 1zm0-4h10c.552 0 1-.448 1-1s-.448-1-1-1H5c-.552 0-1 .448-1 1s.448 1 1 1zm5 6H5c-.552 0-1 .448-1 1s.448 1 1 1h4c.552 0 1-.448 1-1s-.448-1-1-1z">
        </path>
      </svg>

      <svg *ngSwitchCase="'featured-video'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path
          d="M10 0c-5.514 0-10 4.486-10 10s4.486 10 10 10c5.514 0 10-4.486 10-10s-4.486-10-10-10zM10 18c-4.411 0-8-3.589-8-8s3.589-8 8-8c4.411 0 8 3.589 8 8s-3.589 8-8 8zM13.515 9.142l-5-3c-0.309-0.185-0.694-0.191-1.008-0.013s-0.507 0.51-0.507 0.871v6c0 0.36 0.194 0.692 0.507 0.87 0.153 0.087 0.323 0.13 0.493 0.13 0.178 0 0.356-0.048 0.515-0.143l5-3c0.3-0.181 0.485-0.506 0.485-0.857s-0.185-0.677-0.485-0.858v0zM9 11.233v-2.467l2.057 1.234-2.057 1.233z">
        </path>
      </svg>

      <svg *ngSwitchCase="'newsletter-section'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path fill="currentColor" d="M1 3l9 9 9-9z"></path>
        <path
          d="M2 16V5.414l7.293 7.293c.195.195.45.293.707.293s.512-.098.707-.293L18 5.414V16H2zM16.586 4L10 10.586 3.414 4h13.172zM19 2H1c-.552 0-1 .447-1 1v14c0 .553.448 1 1 1h18c.552 0 1-.447 1-1V3c0-.553-.448-1-1-1z">
        </path>
      </svg>

      <svg *ngSwitchCase="'map'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <title>map</title>
        <path fill="currentColor" d="M13 8c0 1.657-1.343 3-3 3S7 9.657 7 8s1.343-3 3-3 3 1.343 3 3z"></path>
        <path
          d="M10 0C5.59 0 2 3.59 2 8c0 7.495 7.197 11.694 7.504 11.87.153.087.325.13.496.13s.343-.043.496-.13C10.803 19.693 18 15.494 18 8c0-4.41-3.59-8-8-8zm0 17.813C8.477 16.783 4 13.295 4 8c0-3.31 2.69-6 6-6s6 2.69 6 6c0 5.276-4.482 8.778-6 9.813zM10 10c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-6C7.794 4 6 5.794 6 8s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4z">
        </path>
      </svg>

      <svg *ngSwitchCase="'advanced-content'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path fill="currentColor" d="M1 1h18v18H1V1z"></path>
        <path
          d="M19 0c.552 0 1 .448 1 1v18c0 .552-.448 1-1 1H1c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1h18zM2 18h16V2H2v16zm3-7h10c.552 0 1-.448 1-1s-.448-1-1-1H5c-.552 0-1 .448-1 1s.448 1 1 1zm0-4h10c.552 0 1-.448 1-1s-.448-1-1-1H5c-.552 0-1 .448-1 1s.448 1 1 1zm5 6H5c-.552 0-1 .448-1 1s.448 1 1 1h4c.552 0 1-.448 1-1s-.448-1-1-1z">
        </path>
      </svg>

      <svg *ngSwitchCase="'featured-banner'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path fill="currentColor" d="M1 6h18v8h-18v-8z"></path>
        <path
          d="M19 5v0c0.553 0 1 0.448 1 1v8c0 0.553-0.447 1-1 1h-18c-0.553 0-1-0.447-1-1v-8c0-0.552 0.447-1 1-1h18zM6 2c-0.553 0-1-0.448-1-1s0.447-1 1-1h2c0.553 0 1 0.448 1 1s-0.447 1-1 1h-2zM12 2h2c0.553 0 1-0.448 1-1s-0.447-1-1-1h-2c-0.553 0-1 0.448-1 1s0.447 1 1 1zM19 0h-1c-0.553 0-1 0.448-1 1s0.447 1 1 1c0 0.552 0.447 1 1 1s1-0.448 1-1v-1c0-0.552-0.447-1-1-1zM1 3c0.553 0 1-0.448 1-1 0.553 0 1-0.448 1-1s-0.447-1-1-1h-1c-0.553 0-1 0.448-1 1v1c0 0.552 0.447 1 1 1zM14 18h-2c-0.553 0-1 0.447-1 1s0.447 1 1 1h2c0.553 0 1-0.447 1-1s-0.447-1-1-1zM8 18h-2c-0.553 0-1 0.447-1 1s0.447 1 1 1h2c0.553 0 1-0.447 1-1s-0.447-1-1-1zM2 18c0-0.553-0.447-1-1-1s-1 0.447-1 1v1c0 0.553 0.447 1 1 1h1c0.553 0 1-0.447 1-1s-0.447-1-1-1zM19 17c-0.553 0-1 0.447-1 1-0.553 0-1 0.447-1 1s0.447 1 1 1h1c0.553 0 1-0.447 1-1v-1c0-0.553-0.447-1-1-1zM2 13h16v-6h-16v6z">
        </path>
      </svg>

      <svg *ngSwitchCase="'featured-row'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <title>image-with-text</title>
        <path
          d="M9 8H1c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1h8c.552 0 1 .448 1 1v6c0 .552-.448 1-1 1zM2 6h6V2H2v4zm7 14H1c-.552 0-1-.448-1-1s.448-1 1-1h8c.552 0 1 .448 1 1s-.448 1-1 1zm10-4H1c-.552 0-1-.448-1-1s.448-1 1-1h18c.552 0 1 .448 1 1s-.448 1-1 1zm0-4H1c-.552 0-1-.448-1-1s.448-1 1-1h18c.552 0 1 .448 1 1s-.448 1-1 1zm0-4h-6c-.552 0-1-.448-1-1s.448-1 1-1h6c.552 0 1 .448 1 1s-.448 1-1 1zm0-4h-6c-.552 0-1-.448-1-1s.448-1 1-1h6c.552 0 1 .448 1 1s-.448 1-1 1z">
        </path>
      </svg>

      <span *ngSwitchCase="'custom'" [ngSwitch]="name">

        <svg *ngSwitchCase="'Colors'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <title>colors</title>
          <path
            d="M19 10h-2.586l3.293-3.293c.39-.39.39-1.023 0-1.414l-5-5c-.39-.39-1.023-.39-1.414 0L10 3.586V1c0-.552-.447-1-1-1H1C.447 0 0 .448 0 1v14c0 2.757 2.243 5 5 5h14c.553 0 1-.448 1-1v-8c0-.552-.447-1-1-1zM5 16c.553 0 1-.448 1-1s-.447-1-1-1c-.553 0-1 .448-1 1s.447 1 1 1zm11 2v-6h2v6h-2zm-2 0H8.975c.288-.38.522-.8.693-1.254L14 12.414V18zM2 4V2h6v2H2zm6 11c0 1.654-1.346 3-3 3s-3-1.346-3-3V6h6v9zm2-8.586l1-1L14.586 9 10 13.586V6.414zm4-4L17.586 6 16 7.586 12.414 4 14 2.414z">
          </path>
        </svg>

        <svg *ngSwitchCase="'Typography'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <title>typography</title>
          <path
            d="M10.458 12C9.7 10.023 8.848 7.854 8.19 6.19 7.528 8.02 6.765 10.102 6.055 12h4.404zm3.49 3.684c.3.9.37 1.664.263 2.316h2.2c-.102-.17-.203-.354-.303-.553-.9-1.8-4.79-12.608-5.81-15.447h-.59L9.22 3.354c.89 2.236 3.88 9.778 4.73 12.33h-.002zM1 20c-.552 0-1-.448-1-1 0-.52.398-.948.906-.996.082-.028.598-.248 1.2-1.45.77-1.54 4.23-11.068 5.952-15.89C8.2.266 8.578 0 9 0h2c.423 0 .8.266.942.664 1.722 4.822 5.183 14.35 5.953 15.89.6 1.202 1.117 1.422 1.2 1.45.505.048.905.476.905.996 0 .552-.448 1-1 1h-7c-.538 0-.958-.427-.978-.964-.02-.537.41-.996.948-1.036.006 0 .156-.012.224-.115.042-.063.235-.437-.143-1.57-.187-.565-.49-1.397-.85-2.356-.065.013-.13.04-.2.04H5.3c-.2.525-.392 1.02-.566 1.46-.088 1.687.922 2.775.98 2.836.388.39.386 1.02-.003 1.41C5.513 19.9 5.26 20 5 20H1z">
          </path>
        </svg>

        <svg *ngSwitchCase="'Social media'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <title>social-media</title>
          <path
            d="M16.153 18.002h-5.96L6 16.325V8.002h1c.38 0 .725-.214.895-.553l2.712-5.425.393.015c.55 0 1 .45 1 1v2.962h-1c-.552 0-1 .448-1 1s.448 1 1 1h6.82l-1.667 10zM2 16.002v-8h2v8H2zm17.763-9.646c-.19-.225-.47-.354-.763-.354h-5V3.04c0-1.654-1.345-3-2.96-3h-.002l-1-.038c-.39-.025-.757.202-.932.553L6.382 6.002H1c-.552 0-1 .448-1 1v10c0 .552.448 1 1 1h3.807l4.822 1.93c.117.046.243.07.37.07h7c.49 0 .906-.354.986-.835l2-12c.05-.29-.033-.587-.223-.81z">
          </path>
        </svg>

        <svg *ngSwitchCase="'Favicon'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <title>favicon</title>
          <path
            d="M15 0H1C.448 0 0 .447 0 1v10c0 .553.448 1 1 1h14c.552 0 1-.447 1-1V1c0-.553-.448-1-1-1zM7 16c.552 0 1-.447 1-1s-.448-1-1-1c-1.654 0-3 1.346-3 3s1.346 3 3 3c.552 0 1-.447 1-1s-.448-1-1-1c-.55 0-1-.448-1-1s.45-1 1-1zm6 2c-.55 0-1-.448-1-1s.45-1 1-1 1 .448 1 1-.45 1-1 1zm0-4c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3zM1 14c-.552 0-1 .447-1 1v4c0 .553.448 1 1 1s1-.447 1-1v-4c0-.553-.448-1-1-1zm1-4V7.535l2-1.333 2.445 1.63c.274.184.62.22.927.097l4.66-1.866L14 6.72V10H2zm12-8v2.613l-1.684-.56c-.225-.077-.468-.07-.688.018l-4.51 1.806-2.563-1.708c-.336-.225-.774-.225-1.11 0L2 5.132V2h12z">
          </path>
        </svg>

        <svg *ngSwitchCase="'Analytics'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <title>analytics</title>
          <path
            d="M19 18a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2h18zm0-18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h5V5a1 1 0 0 1 1-1h5V1a1 1 0 0 1 1-1h6zm-5 14h4V2h-4v12zm-6 0h4V6H8v8zm-6 0h4v-4H2v4z">
          </path>
        </svg>

        <svg *ngSwitchDefault xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path fill="currentColor"
            d="M10 13c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3zm7-3c0-.53-.064-1.044-.176-1.54L19 7.23l-2.047-3.464-2.106 1.188c-.794-.763-1.764-1.34-2.847-1.662V1H8v2.294c-1.083.322-2.053.9-2.847 1.662L3.047 3.768 1 7.232 3.176 8.46C3.064 8.955 3 9.47 3 10s.064 1.044.176 1.54L1 12.77l2.047 3.464 2.106-1.188c.794.762 1.764 1.34 2.847 1.662V19h4v-2.294c1.083-.322 2.053-.9 2.847-1.662l2.106 1.188L19 12.768l-2.176-1.227c.112-.49.176-1.01.176-1.54z">
          </path>
          <path
            d="M19.492 11.897l-1.56-.88c.046-.342.068-.682.068-1.017s-.022-.675-.067-1.018l1.56-.88c.233-.132.404-.352.473-.61.07-.26.032-.538-.104-.77L17.815 3.26c-.277-.47-.875-.63-1.353-.363l-1.49.84c-.6-.475-1.267-.864-1.972-1.15V1c0-.552-.447-1-1-1H8c-.552 0-1 .448-1 1v1.586c-.705.287-1.37.676-1.97 1.152l-1.492-.84c-.475-.268-1.075-.107-1.352.36L.14 6.724c-.138.23-.175.508-.105.768s.24.48.474.613l1.55.88C2.02 9.325 2 9.665 2 10s.023.675.068 1.017l-1.56.88c-.233.132-.404.353-.473.612-.07.26-.033.53.104.76l2.04 3.46c.27.47.87.63 1.35.36l1.49-.844c.6.48 1.26.87 1.97 1.154V19c0 .552.443 1 1 1h4c.55 0 1-.448 1-1v-1.587c.7-.286 1.37-.675 1.97-1.152l1.49.85c.48.27 1.073.11 1.35-.36l2.047-3.46c.136-.23.174-.51.104-.77s-.24-.48-.473-.61zm-3.643-3.22c.1.45.15.894.15 1.323s-.05.873-.15 1.322c-.1.43.1.873.48 1.09l1.28.725-1.03 1.742-1.257-.71c-.383-.22-.866-.16-1.183.15-.69.66-1.534 1.15-2.44 1.42-.423.12-.714.51-.714.96V18H9v-1.294c0-.443-.29-.833-.714-.96-.906-.268-1.75-.76-2.44-1.424-.317-.306-.8-.366-1.184-.15l-1.252.707-1.03-1.75 1.287-.73c.385-.22.58-.66.485-1.09C4.052 10.87 4 10.43 4 10c0-.43.05-.874.152-1.322.096-.43-.1-.873-.485-1.09L2.38 6.862 3.41 5.12l1.252.707c.384.217.867.157 1.184-.15.69-.663 1.534-1.155 2.44-1.425.423-.126.714-.516.714-.958V2h2v1.294c0 .442.29.832.715.958.905.27 1.75.762 2.44 1.426.317.306.8.365 1.183.15l1.253-.708 1.03 1.742-1.28.726c-.38.217-.58.66-.48 1.09zM10 6c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z">
          </path>
        </svg>
      </span>

      <svg *ngSwitchDefault xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path fill="currentColor"
          d="M10 13c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3zm7-3c0-.53-.064-1.044-.176-1.54L19 7.23l-2.047-3.464-2.106 1.188c-.794-.763-1.764-1.34-2.847-1.662V1H8v2.294c-1.083.322-2.053.9-2.847 1.662L3.047 3.768 1 7.232 3.176 8.46C3.064 8.955 3 9.47 3 10s.064 1.044.176 1.54L1 12.77l2.047 3.464 2.106-1.188c.794.762 1.764 1.34 2.847 1.662V19h4v-2.294c1.083-.322 2.053-.9 2.847-1.662l2.106 1.188L19 12.768l-2.176-1.227c.112-.49.176-1.01.176-1.54z">
        </path>
        <path
          d="M19.492 11.897l-1.56-.88c.046-.342.068-.682.068-1.017s-.022-.675-.067-1.018l1.56-.88c.233-.132.404-.352.473-.61.07-.26.032-.538-.104-.77L17.815 3.26c-.277-.47-.875-.63-1.353-.363l-1.49.84c-.6-.475-1.267-.864-1.972-1.15V1c0-.552-.447-1-1-1H8c-.552 0-1 .448-1 1v1.586c-.705.287-1.37.676-1.97 1.152l-1.492-.84c-.475-.268-1.075-.107-1.352.36L.14 6.724c-.138.23-.175.508-.105.768s.24.48.474.613l1.55.88C2.02 9.325 2 9.665 2 10s.023.675.068 1.017l-1.56.88c-.233.132-.404.353-.473.612-.07.26-.033.53.104.76l2.04 3.46c.27.47.87.63 1.35.36l1.49-.844c.6.48 1.26.87 1.97 1.154V19c0 .552.443 1 1 1h4c.55 0 1-.448 1-1v-1.587c.7-.286 1.37-.675 1.97-1.152l1.49.85c.48.27 1.073.11 1.35-.36l2.047-3.46c.136-.23.174-.51.104-.77s-.24-.48-.473-.61zm-3.643-3.22c.1.45.15.894.15 1.323s-.05.873-.15 1.322c-.1.43.1.873.48 1.09l1.28.725-1.03 1.742-1.257-.71c-.383-.22-.866-.16-1.183.15-.69.66-1.534 1.15-2.44 1.42-.423.12-.714.51-.714.96V18H9v-1.294c0-.443-.29-.833-.714-.96-.906-.268-1.75-.76-2.44-1.424-.317-.306-.8-.366-1.184-.15l-1.252.707-1.03-1.75 1.287-.73c.385-.22.58-.66.485-1.09C4.052 10.87 4 10.43 4 10c0-.43.05-.874.152-1.322.096-.43-.1-.873-.485-1.09L2.38 6.862 3.41 5.12l1.252.707c.384.217.867.157 1.184-.15.69-.663 1.534-1.155 2.44-1.425.423-.126.714-.516.714-.958V2h2v1.294c0 .442.29.832.715.958.905.27 1.75.762 2.44 1.426.317.306.8.365 1.183.15l1.253-.708 1.03 1.742-1.28.726c-.38.217-.58.66-.48 1.09zM10 6c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z">
        </path>
      </svg>

    </div>
    <div class="theme-editor-action-list-item-text">
      {{itemDetails.name | getLocalizedName}}
    </div>
  </div>



  <!-- USED FOR DRAG AND DROP HANDLE -->
  <span *ngIf="itemDetails.draggable">
    <ng-content></ng-content>
  </span>
  <!-- USED FOR DRAG AND DROP HANDLE -->


</li>

<p *ngIf="!sectionHasContent(type, id)" class="theme-editor__template-content">
  {{itemDetails.name | getLocalizedName}} content
</p>