<div class="font-picker">
  <div class="font-preview">
    <div class="font-name">
      <p>{{getFontName(selectedFont)}}</p>
    </div>
  </div>

  <div class="font-actions">
    <button (click)="openFontPicker()">Change</button>
  </div>
</div>