import { Component, OnChanges, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';
import {
    NgbDate,
    NgbTimeStruct,
    NgbDateStruct,
    NgbCalendar,
    NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';

import { DateRange } from './date-range-picker.model';

@Component({
    selector: 'am-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnChanges {

    hoveredDate: NgbDate;

    @Input()
    range: DateRange;

    @Input()
    maxDate: Date;

    @Input()
    readonly: boolean = false;

    @Output()
    rangeChange = new EventEmitter<DateRange>();

    internalDate: {
        from: NgbDate,
        to: NgbDate
    };
    /*
        If we pick a date using DatePicker we need to include the last day in our date range.
        For example: 01-01-2020 - 05-01-2020 <=> 01-01-2020 00:00:00 - 05-01-2020 23:59:59
     */
    private readonly internalTime: {
        from: NgbTimeStruct;
        to: NgbTimeStruct;
    };

    internalMaxDate: { year: number, month: number, day: number };

    get fullValue(): string {
        if (this.internalDate.to) {
            return `${this.formatter.format(this.internalDate.from)}${this.SEPARATOR}${this.formatter.format(this.internalDate.to)}`;
        }
        return `${this.formatter.format(this.internalDate.from)}`;
    }

    private readonly SEPARATOR: string = ' - ';

    constructor(
        private calendar: NgbCalendar,
        private formatter: NgbDateParserFormatter
    ) {
        this.internalTime = {
            from: { hour: 0, minute: 0, second: 0 },
            to: { hour: 23, minute: 59, second: 59 }
        };
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.range && changes.range.currentValue) {
            this.internalDate = {
                from: this.toDateModel(this.range.from),
                to: this.toDateModel(this.range.to)
            };
        }
        if (changes.maxDate && changes.maxDate.currentValue) {
            this.internalMaxDate = {
                year: this.maxDate.getFullYear(),
                month: this.maxDate.getMonth() + 1,
                day: this.maxDate.getDate()
            };
        }
    }

    onDateSelection(date: NgbDate) {
        if (!this.internalDate.from && !this.internalDate.to) {
            this.internalDate.from = date;
        } else if (
            this.internalDate.from &&
            !this.internalDate.to &&
            date.after(this.internalDate.from)
        ) {
            this.internalDate.to = date;
        } else {
            this.internalDate.to = null;
            this.internalDate.from = date;
        }
    }

    /**
     * @param dateRangeInputValue  i.e. 2020-03-02 - 2020-03-05
     */
    onBlur(dateRangeInputValue: string): void {
        const split = dateRangeInputValue.split(this.SEPARATOR);
        if (split.length === 2) {
            // Both "fromDate" and "toDate" are typed
            const fromDate = this.formatter.parse(split[0]);
            this.internalDate.from = this.validateInput(this.internalDate.from, split[0]);

            const toDate = this.formatter.parse(split[1]);
            if (!this.isValid(fromDate) || !this.isValid(toDate)) {
                this.internalDate.to = null;
            } else {
                this.internalDate.to = NgbDate.from(toDate);
            }
        } else {
            // Only fromDate is typed or more then 2 separators are provided
            this.internalDate.from = this.validateInput(this.internalDate.from, split[0]);
            this.internalDate.to = null;
        }
    }

    onApply(): void {
        this.emitDateRange();
    }

    isHovered(date: NgbDate) {
        return (
            this.internalDate.from &&
            !this.internalDate.to &&
            this.hoveredDate &&
            date.after(this.internalDate.from) &&
            date.before(this.hoveredDate)
        );
    }

    isInside(date: NgbDate) {
        return date.after(this.internalDate.from) && date.before(this.internalDate.to);
    }

    isRange(date: NgbDate) {
        return (
            date.equals(this.internalDate.from) ||
            date.equals(this.internalDate.to) ||
            this.isInside(date) ||
            this.isHovered(date)
        );
    }

    isDisabled(date: NgbDate) {
        return date.after(this.internalMaxDate);
    }

    private isValid(date: NgbDateStruct): boolean {
        return date && this.calendar.isValid(NgbDate.from(date));
    }

    private validateInput(currentValue: NgbDate, input: string): NgbDate {
        const parsed = this.formatter.parse(input);
        return this.isValid(parsed) ? NgbDate.from(parsed) : currentValue;
    }

    private toDateModel(date: Date): NgbDate {
        return date ? NgbDate.from({
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate()
        }) : null;
    }

    private fromDateModel(date: NgbDate, time: NgbTimeStruct): Date {
        return date ? new Date(date.year, date.month - 1, date.day, time.hour, time.minute, time.second) : null;
    }

    private emitDateRange(): void {
        this.rangeChange.emit({
            from: this.fromDateModel(this.internalDate.from, this.internalTime.from),
            to: this.fromDateModel(this.internalDate.to, this.internalTime.to)
        });
    }

}
