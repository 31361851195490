import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { FormsModule } from '@angular/forms';
import { NgxPubSubModule } from '@pscoped/ngx-pub-sub';
import { CookieService } from 'ngx-cookie-service';
import { NotifierModule } from 'angular-notifier';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { AmBuilderService } from './amp/am-builder.service';
import { InterceptService } from './auth/intercept.service';

import { AppComponent } from './app.component';

import { PolarisModule } from './polaris/polaris.module';

import { ChartsModule } from 'ng2-charts';

import { SharedModule } from './shared/shared.module';

import { AuthModule } from './auth/auth.module';

import { AppRoutingModule } from './app-routing.module';
import { AmpModule } from './amp/amp.module';
import { EditorModule } from './amp/editor/editor.module';
import { OnboardingModule } from './amp/onboarding/onboarding.module';

import { PwaModule } from './pwa/pwa.module';
import { PwaOnboardingModule } from './pwa/pwa-onboarding/pwa-onboarding.module';
import { EditorV2Module } from './amp/editorv2/editorv2.module';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPubSubModule,
    PolarisModule,
    ChartsModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'middle',
          distance: 12
        },
        vertical: {
          position: 'bottom',
          distance: 12,
          gap: 10
        }
      },
      behaviour: {
        autoHide: 5000,
        onClick: 'hide',
        onMouseover: 'pauseAutoHide',
        showDismissButton: true,
        stacking: 4
      },
    }),
    SharedModule,
    BrowserAnimationsModule,
    DragDropModule,

    AuthModule,
    AmpModule,
    EditorModule,
    OnboardingModule,

    PwaModule,
    PwaOnboardingModule,
    EditorV2Module
  ],
  providers: [
    CookieService,
    AmBuilderService,
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
