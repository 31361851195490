import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { EditorService } from '../../editor.service';

@Component({
  selector: 'am-font-field',
  templateUrl: './font-field.component.html',
  styleUrls: ['./font-field.component.scss']
})
export class FontFieldComponent implements OnInit {

  @Input()
  item: any;

  @Input()
  values: any;

  @Output() valueUpdated = new EventEmitter<any>();

  selectedFont = '';

  constructor(
    private editorService: EditorService
  ) { }

  ngOnInit() {
    this.selectedFont = this.values[this.item.id];
  }

  valueUpdatedEvent(id, value) {
    this.valueUpdated.emit({
      id: id,
      value: value
    });
  }

  getFontName(font: string): string {
    font = font || '';
    return font.split('+').join(' ');
  }

  openFontPicker(): void {
    this.editorService.showFontPicker({
      id: this.item.id,
      font: this.selectedFont
    });

    // Subscribe to preview updates
    const subscription = this.editorService.fontSelected.subscribe(res => {
      if (res.id === this.item.id) {
        this.selectedFont = res.font;
        this.values[this.item.id] = res.font;
        this.valueUpdatedEvent(res.id, res.font);
      }
    });

    // Subscribe to close picker updates
    const subscriptionClosed = this.editorService.fontPickerClosed.subscribe(res => {
      if (res.id === this.item.id) {
        this.selectedFont = res.font;
        this.values[this.item.id] = res.font;
        this.valueUpdatedEvent(res.id, res.font);
      }
    }, err => {
      console.log(err);
    }, () => {
      // After we get the event unsubscribe
      subscription.unsubscribe();
      subscriptionClosed.unsubscribe();
    });
  }
}
