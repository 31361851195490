<div *ngIf="!isLoaded">
    <am-editor-loading></am-editor-loading>
  </div>
  
<div *ngIf="isLoading">
  <am-refresh-loading></am-refresh-loading>
</div>

  <div *ngIf="isLoaded && isError">
    <am-editor-error-message [shopDomain]="shopDomain"></am-editor-error-message>
  </div>
  
  <div *ngIf="themeNotSupported">
    <div class="error-message">
      <h1>Theme not suported</h1>
      <p>Oops!
        This is a legacy theme, please use the old theme editor or create a new theme from our themes list.
      </p>
      <!-- href="https://{{shopDomain}}/admin/apps/ampify-me?shop={{shopDomain}}" -->
      <p>
        <a class="button button-primary" [routerLink]="['/amp/themes']">Back to
           Admin</a>
      </p>
    </div>
  </div>
  
  <div *ngIf="isLoaded && !isError && !themeNotSupported && !isLoading" class="wrapper" [ngClass]="viewportSize">
  
    <am-top-navbar-v2  (pageChangeEvent)="pageChange($event)" (pageRefreshEvent)="refreshData($event)"></am-top-navbar-v2>
    <mat-progress-bar *ngIf="apiSuccess" mode="indeterminate"></mat-progress-bar>

    <div id="builder-wrapper">
      <div class="wrapper-columns">

        <div class="menu-options">
          <ul class="options">
            <li class="sub-options" [ngClass]="{'activeMenu' : activeMenuIndex == ix}" *ngFor="let op of menus; let ix = index"  (click)="changeMenu(ix);child.showThemeSettingForm(op.key)">
            <span title="{{op.title}}">
              <img *ngIf="activeMenuIndex != ix" [src]="op.icon" width="20px" height="20px">
              <img *ngIf="activeMenuIndex == ix" [src]="op.activeIcon" width="20px" height="20px">
            </span>
            </li>
          </ul>
        </div>
  
        <am-sidebar-sections-v2 #child [pageType]="pageName" [mainMenu]="sidemenuData" [headerConfig]="headerConf" [footerConfig]="footerConf" [currentConfig]="currentConf"></am-sidebar-sections-v2>


        <!-- <am-left-sidebar-v2 [pageTitle]="pageName" [themeId]="themeId" [sidemenuData]="jsonData" [headerConf]="headerConfig" [footerConf]="footerConfig" [currentConf]="currentConfig"></am-left-sidebar-v2> -->
  
        <am-builder-preview-v2></am-builder-preview-v2>
  
      </div>
    </div>
  
  </div>