<div class="Polaris-TopBar">
  <button (click)="openSidebar()" type="button" class="Polaris-TopBar__NavigationIcon" aria-label="Toggle menu">
    <span class="Polaris-Icon Polaris-Icon--colorWhite">
      <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
        <path
          d="M19 11H1a1 1 0 1 1 0-2h18a1 1 0 1 1 0 2zm0-7H1a1 1 0 0 1 0-2h18a1 1 0 1 1 0 2zm0 14H1a1 1 0 0 1 0-2h18a1 1 0 1 1 0 2z">
        </path>
      </svg>
    </span>
  </button>

  <div class="Polaris-TopBar__LogoContainer navbar-brand">
    <a class="Polaris-TopBar__LogoLink" [routerLink]="['/pwa']" data-polaris-unstyled="true">
      <img src="/assets/amp/images/amp-logo-white.png" alt="PWA by AmpifyMe" class="Polaris-TopBar__Logo">

      PWA
    </a>
  </div>

  <div class="Polaris-TopBar__Contents">

    <div class="Polaris-TopBar__SecondaryMenu"></div>

    <div>
      <div class="Polaris-TopBar-Menu__ActivatorWrapper">
        <a [routerLink]="['/pwa/settings/account']" *ngIf="user" class="Polaris-TopBar-Menu__Activator" tabindex="0">

          <div class="Polaris-MessageIndicator__MessageIndicatorWrapper">
            <span aria-label="Avatar with initials D" role="img"
              class="Polaris-Avatar Polaris-Avatar--styleOne Polaris-Avatar--sizeSmall">
              <span class="Polaris-Avatar__Initials">
                <svg class="Polaris-Avatar__Svg" viewBox="0 0 48 48">
                  <text x="50%" y="50%" dy="0.35em" fill="currentColor" font-size="26" text-anchor="middle">
                    {{user.shopOwner | slice: 0: 1}}
                  </text>
                </svg>
              </span>
            </span>
          </div>

          <span class="Polaris-TopBar-UserMenu__Details">
            <p class="Polaris-TopBar-UserMenu__Name">{{ user.name }}</p>
            <!-- <p class="Polaris-TopBar-UserMenu__Detail">{{ user.planName | titlecase }}</p> -->
          </span>
        </a>
      </div>
    </div>
  </div>
</div>