import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'am-range-field',
  templateUrl: './range-field.component.html',
  styleUrls: ['./range-field.component.scss']
})
export class RangeFieldComponent implements OnInit {

  @Input()
  item: any;

  @Input()
  values: any;

  @Output() valueChanged = new EventEmitter<any>();
  @Output() valueUpdated = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  valueUpdateEvent(id, value) {
    this.valueUpdated.emit({
      id: id,
      value: value
    });
  }

  valueChangeEvent(id, value) {
    this.valueChanged.emit({
      id: id,
      value: value
    });
  }
}
