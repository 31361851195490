import { Component, OnInit } from '@angular/core';

import { OnboardingService } from '../onboarding.service';

@Component({
  selector: 'am-onboarding-benefit-performance',
  templateUrl: './onboarding-benefit-performance.component.html',
  styleUrls: ['./onboarding-benefit-performance.component.scss']
})
export class OnboardingBenefitPerformanceComponent implements OnInit {

  constructor(
    private onboardingService: OnboardingService
  ) {
    this.onboardingService.setCurrentPage(3);
  }

  ngOnInit() {
  }

}
