import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'am-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

  app: string = 'amp';

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.app = params['app'] || 'amp';
    });
  }

  tryAgain(): void {
    window.location.href = this.app || 'amp';
  }

  contactUs(): void {
    try {
      if (!window['Beacon']) {
        return;
      } else {
        window['Beacon']('open');
      }

    } catch (e) { }
  }

}
