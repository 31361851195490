import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceName'
})
export class ReplaceNamePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {

    // console.log(args)
    let sectionData: any = args[0];
    let sectionType = '';

    if (sectionData) {
      // console.log(sectionData,"this is section data")
      if(sectionData && sectionData.route && sectionData.route.length) {

        if( sectionData.route[0] == 'color_schemes' && sectionData.name.includes('scheme') ){

          return 'Custom Color Scheme';
        }
      }

      if( (sectionData && sectionData.name && sectionData.name.includes('scheme') && sectionData.schemaKey == 'colors') ){

        return 'Custom Color Scheme'
      }

      if (sectionData.type == 'parent') {
        sectionType = sectionData.parent;
      } else {
        sectionType = sectionData.headingText;
      }
      //console.log("switch case", sectionType);

      switch (sectionType) {
        // header and footer starts
        case 'announcement-bar':
          return 'Announcement bar';
        case 'announcement':
          return 'Announcement bar';
        case 'header':
          return 'Header';
        case 'footer':
          return 'Footer'

        // section blocks starts
        case 'featured-blog':
          return 'Blog post';
        case 'collage':
          return 'Collage';
        case 'collapsible-content':
          return 'Collapsible content';
        case 'collection-list':
          return 'Collection list';
        case 'contact-form':
          return 'Contact form';
        case 'custom-liquid':
          return 'Custom liquid';
        case 'newsletter':
          return 'Email signup';
        case 'featured-collection':
          return 'Featured collection';
        case 'featured-product':
          return 'Featured product';
        case 'featured_collection':
          return 'Featured collection';
        case 'featured_product':
          return 'Featured product';
        case 'image-banner':
          return 'Image banner';
        case 'image_banner':
          return 'Image banner';
        case 'image-with-text':
          return 'Image with text';
        case 'multicolumn':
          return 'Multicolumn';
        case 'rich-text':
          return 'Rich text';
        case 'rich_text':
          return 'Rich text';
        case 'slideshow':
          return 'Slideshow';
        case 'video':
          return 'Video';
        case 'pages':
          return 'Pages';

        // product block starts
        case 'main-product':
          return 'Main Product';
        case 'product-recommendations':
          return 'Product recommendations';

        //collection page block starts
        case 'main-collection-banner':
          return 'Collection banner';
        case 'main-collection-product-grid':
          return 'Product grid';

        // collection list page block starts
        case 'main-list-collections':
          return 'Collection list page';

        // blog page block starts
        case 'main-blog':
          return 'Blog posts';

        // article page block starts
        case 'main-article':
          return 'Blog post';

        // sub blocks starts
        case 'product':
          return 'Product';
        case 'collection':
          return 'Collection';
        case 'image':
          return 'Image';

        case 'collapsible_row':
          return 'Collapsible row';

        case 'heading':
          return 'Heading';
        case 'paragraph':
          return 'Paragraph';
        case 'email_form':
          return 'Email form';

        case 'text':
          return 'Text';
        case 'title':
          return 'Title';
        case 'price':
          return 'Price';
        case 'variant_picker':
          return 'Variant picker';
        case 'quantity_selector':
          return 'Quantity selector';
        case 'buy_buttons':
          return 'Buy buttons';
        case 'share':
          return 'Share';
        case 'custom_liquid':
          return 'Custom liquid';
        case 'product_rating':
          return 'Product rating';
        case 'description':
          return 'Description';
        case 'collapsible_tab':
          return 'Collapsible tab';

        case 'buttons':
          return 'Buttons';
        case 'button':
          return 'Button';
        case 'column':
          return 'Column';
        case 'slide':
          return 'Image slide';

        case 'link_list':
          return 'Link list';


        case 'featured_image':
          return 'Featured image';
        case 'content':
          return 'Content';

        case 'apps':
          return 'Apps';

        case 'integration-reviews-lai-ali-express-io':
          return 'LAI product reviews';

        case 'integration-reviews-stamped-io':
          return 'StampedIO reviews';

        case 'integration-reviews-areviews-aliexpress':
          return 'Areviews - Section';

        case 'integration-reviews-bazaarvoice':
          return 'Bazaarvoice - reviews';

        case 'integration-reviews-judge-me':
          return 'Judge.me - reviews';

        case 'integration-reviews-loox':
          return 'Loox reviews';

        case 'integration-reviews-okendo':
          return 'Okendo reviews';

        case 'integration-reviews-opinew':
          return 'Opinew Product reviews';

        case 'integration-reviews-ryviu':
          return 'Ryviu reviews';

        case 'integration-reviews-shopify-product-reviews':
          return 'Shopify Product reviews';

        case 'integration-reviews-shopperapproved':
          return 'Shopper Approved reviews';

        case 'integration-reviews-review-io':
          return 'ReviewIO reviews';

        case 'integration-reviews-yotpo':
          return 'Yotpo reviews';

        case 'integration-reviews-ssw':
          return 'Growave reviews';

        case 'integration-reviews-helpfulcrowd':
          return 'Helpful Crowd reviews';

        case 'integration-reviews-alireviews':
          return 'Ali Express'

        case 'featured-collection-slider':
          return 'Featured Collection Slider'

        case 'featured-collection-text':
          return 'Featured Collection Text'

        case 'online-smoke-shop':
          return 'Online smoke shop'

        case 'guarante_image':
          return 'Guarante image'

        case 'guarante-image':
          return 'Guarante image'

        case 'breadcrumbs':
          return 'Breadcrumbs'

        case 'divider':
          return 'Divider'

        case 'product-information':
          return 'Product description'

        case 'collection-partial-description':
          return 'Collection Partial Description'


        case 'integration-reviews-proviews-io':
          return 'Proviews';

          case 'multiple-images-with-text':
            return 'Multiple images with text';

            case 'impact-text':
              return 'Impact text';

              case 'custom-html':
                return 'Custom HTML';

                case 'related-products':
                return 'Related products'

                case 'caption':
                return 'Caption'

                case 'multirow':
                return 'Multirow'
                case 'amp-review-fallback':
                  return 'Amp Review Fallback'

                  case 'main-page':
                    return 'Page'

                    case 'contact-form':
                    return 'Contact form'
                   case 'custom-section':
                    return 'Custom shipping bar'
                    case 'star_ratings':
                      return 'Star ratings'

        default:
          return value;


      }
    }
    return null;
  }
}
